import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import { append, isEmpty, pathOr, remove } from 'ramda'
import React from 'react'
import { CallWindowDay, CallWindowItem } from '@/app/module/campaigns/types'

type Props = {
  index: number
  day: CallWindowDay
  slot: CallWindowItem

  onChange: (updatedDay: CallWindowDay) => void
}

const SingleWindowSlot: React.FC<Props> = (props) => {
  const { index, day, onChange = () => {} } = props

  const [startError, setStartError] = React.useState(false)
  const [endError, setEndError] = React.useState(false)

  const updateDay = (updatedValue: Partial<CallWindowItem>) => {
    const updatedDay = (day || []).map((slot, i) => {
      if (i === index) {
        return {
          ...slot,
          ...updatedValue,
        }
      }
      return slot
    })
    onChange(updatedDay)
  }

  const addSlot = () => {
    onChange(append({ start: '08:00', end: '20:00' }, day))
  }

  const removeSlot = () => {
    onChange(remove(index, 1, day))
  }

  const [start, setStart] = React.useState('08:00')
  const [end, setEnd] = React.useState('20:00')

  React.useEffect(() => {
    setStart(pathOr('08:00', ['start'], props.slot))
    setEnd(pathOr('20:00', ['end'], props.slot))
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (start !== pathOr('08:00', ['start'], props.slot)) {
      setStart(pathOr('08:00', ['start'], props.slot))
    }
    if (end !== pathOr('08:00', ['end'], props.slot)) {
      setEnd(pathOr('08:00', ['end'], props.slot))
    }
    // eslint-disable-next-line
  }, [props.slot])

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <form noValidate>
          <TextField
            variant="standard"
            id="start"
            label="Start Time"
            type="time"
            error={startError}
            defaultValue="08:00"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 3000, // 30 minutes
            }}
            value={start}
            onChange={(e) => {
              const momentStart = moment(e.currentTarget.value, 'hh:mm')
              const momentEnd = moment(end, 'hh:mm')
              setStartError(isEmpty(e.currentTarget.value) || momentEnd.isBefore(momentStart))
              setEndError(momentEnd.isBefore(momentStart))
              setStart(e.currentTarget.value)
              updateDay({
                start: e.currentTarget.value,
              })
            }}
          />
        </form>
      </Grid>
      <Grid item xs={2}>
        <form noValidate>
          <TextField
            variant="standard"
            id="time"
            label="End Time"
            type="time"
            error={endError}
            defaultValue="18:00"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 3000, // 30 minutes
            }}
            value={end}
            onChange={(e) => {
              const momentStart = moment(start, 'hh:mm')
              const momentEnd = moment(e.currentTarget.value, 'hh:mm')
              setStartError(momentEnd.isBefore(momentStart))
              setEndError(isEmpty(e.currentTarget.value) || momentEnd.isBefore(momentStart))
              setEnd(e.currentTarget.value)
              updateDay({
                end: e.currentTarget.value,
              })
            }}
          />
        </form>
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          paddingTop: '15px',
        }}
      >
        <IconButton aria-label="add" color="primary" onClick={addSlot}>
          <Icon>add</Icon>
        </IconButton>
        <IconButton aria-label="delete" color="error" onClick={removeSlot}>
          <Icon>delete</Icon>
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default SingleWindowSlot
