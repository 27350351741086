import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Loading from '@/app/component/guard/loading'
import { useExportOrgReport } from '@/app/module/reports/store/hooks'
import { OrgReportFilter, OrgReportGrouping } from '@/app/module/reports/types'
import { useRequirePermission } from '@/app/module/user/store/ts/hooks'
import { selectUserLoading } from '@/app/module/user/store/ts/selectors'
import { captureError } from '@/app/service/util/error'
import { ZOrgReportQuery } from '@/app/module/campaigns/types/schemas/reports/org-report'
import { selectCampaignListLoading } from '@/app/module/campaigns/store/selectors'
import OrganizationReport from './organization-report'
import NoReportPermission from './no-report-permission'

type Props = {}

const OrganizationReportContainer: React.FC<Props> = () => {
  const [exportOrgReport, { isLoading }] = useExportOrgReport()
  const location = useLocation()
  const hasPermission = useRequirePermission('downloadReport')
  const campaignsLoading = useSelector(selectCampaignListLoading)
  const userLoading = useSelector(selectUserLoading)

  const query = React.useMemo(() => {
    const params = new URLSearchParams(location.search)
    const q = params.get('query')

    if (!q) {
      return undefined
    }

    try {
      const jsonQuery = JSON.parse(q)
      return ZOrgReportQuery.parse(jsonQuery)
    } catch (error) {
      captureError(error)
      return undefined
    }
  }, [location.search])

  const handleSubmit = React.useCallback(
    async (grouping: OrgReportGrouping, filter?: OrgReportFilter) => {
      await exportOrgReport(grouping, filter)
    },
    [exportOrgReport],
  )

  const loading = isLoading || campaignsLoading

  return (
    <Loading isLoading={userLoading}>
      {!userLoading && !hasPermission && <NoReportPermission />}
      {hasPermission && <OrganizationReport isLoading={loading} query={query} onSubmit={handleSubmit} />}
    </Loading>
  )
}

export default OrganizationReportContainer
