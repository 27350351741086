import { CampaignTemplateType } from '@/app/module/campaigns/types'
import createCampaignGuard from '@/app/module/campaigns/component/item/steps/guard'
import MultiMessage from './types/multimessage'
import SMSBlast from './types/smsblast'

const mapping = {
  [CampaignTemplateType.CATI]: MultiMessage,
  [CampaignTemplateType.Drip]: MultiMessage,
  [CampaignTemplateType.Reminder]: MultiMessage,
  [CampaignTemplateType.SMSBlast]: SMSBlast,
  [CampaignTemplateType.SMSSurvey]: MultiMessage,
  [CampaignTemplateType.VoiceBlast]: MultiMessage,
  [CampaignTemplateType.VoiceSurvey]: MultiMessage,
  [CampaignTemplateType.WhatsAppSurvey]: MultiMessage,
}

export default createCampaignGuard(mapping)
