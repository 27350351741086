import { ItemType } from '@/app/module/campaigns/helpers'
import { DelayState } from '@/app/module/campaigns/types'

export const hasDaysOfWeek = (delta?: DelayState) =>
  delta?.unit === 'week' && (!!delta.dayOfWeek || !!(delta.daysOfWeek && Object.keys(delta.daysOfWeek).length))

export const getSchedule = (delta?: DelayState) => {
  if (delta?.relative === 'previous') {
    return 'previous'
  }

  const hasDay = delta?.unit === 'day'
  if (delta?.time && (hasDay || hasDaysOfWeek(delta))) {
    return 'specific'
  }
  return 'same'
}

export const processSchedule = (item: ItemType, value: string, unit: string, schedule?: boolean) => {
  const isRelative = value === 'same' || value === 'previous'
  const canSetDaysOfWeek = !isRelative && unit === 'week'
  let relative = null
  if (isRelative) {
    if (!schedule) {
      relative = item.delta.relative
    } else {
      relative = value === 'same' ? 'initial' : value
    }
  }

  return {
    isRelative,
    canSetDaysOfWeek,
    relative,
  }
}

export const daysOfTheWeek = [
  {
    label: 'Monday',
    value: 'monday',
  },
  {
    label: 'Tuesday',
    value: 'tuesday',
  },
  {
    label: 'Wednesday',
    value: 'wednesday',
  },
  {
    label: 'Thursday',
    value: 'thursday',
  },
  {
    label: 'Friday',
    value: 'friday',
  },
  {
    label: 'Saturday',
    value: 'saturday',
  },
  {
    label: 'Sunday',
    value: 'sunday',
  },
]

export const daysOfTheWeekOrder = daysOfTheWeek.reduce<Record<string, number>>(
  (obj, item, index) => ({ ...obj, [item.value]: index }),
  {},
)
