import { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { filter, find, map, omit, prop, propEq } from 'ramda'
import * as React from 'react'
import { makeStyles } from 'tss-react/mui'
import { SearchableSelect, Select } from '@/app/component/atom/form'
import Tooltip from '@/app/component/atom/tooltip'
import IconText from '@/app/component/layout/icon-text'
import {
  LanguageListItem,
  LanguageType,
  capitalizeFirstLetter,
  capitalizeTheFirstLetterOfEachWord,
  reduceIndexed,
} from '@/app/module/campaigns/helpers'
import { getDefaultLanguageSpeech, hasSpeechLanguage } from '@/app/module/campaigns/language-helpers'
import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import { Card, CardContent, FilterOptionsState } from '@mui/material'

type Props = {
  defaultLanguage: string
  languages: LanguageType[]
  languageList: LanguageListItem[]
  speechEnabled: boolean
  speechLanguageList: LanguageListItem[]
  speechSupported?: boolean

  onSave: (languages: LanguageType[], defaultLanguage: string) => void
}

const keyPropFn = prop('id')
const valuePropFn = prop('label')
const getOpionLabelFn = (option: LanguageListItem | string) => {
  if (typeof option === 'string') {
    return option
  }
  return option.label
}

export default function LanguageConfiguration({ languageList, onSave, ...props }: Props) {
  const [confirmationDialog, setConfirmationDialog] = React.useState(false)
  const [defaultLanguage, setDefaultLanguage] = React.useState(props.defaultLanguage)
  const [languages, setLanguages] = React.useState<LanguageType[]>(props.languages)
  const [speechEnabled, setSpeechEnabled] = React.useState(props.speechEnabled || false)
  const [warning, setWarning] = React.useState<React.ReactNode | null>(null)
  const { classes, cx } = useStyles()

  const [tempLanguages, setTempLanguages] = React.useState<LanguageType[]>(languages)

  const handleSave = React.useCallback(
    (newLanguages: LanguageType[], defLang?: string) => {
      const newDefaultLanguage = getDefaultLanguage(newLanguages, defLang || defaultLanguage)

      onSave(processLanguageList(newLanguages), newDefaultLanguage)
      setSpeechEnabled(hasSpeechLanguage(newLanguages))
    },
    [defaultLanguage, onSave],
  )

  const addLanguage = React.useCallback(
    (value: string) => {
      const alreadyExists = find(propEq('value', value.toLocaleLowerCase()), languages)
      if (!alreadyExists) {
        const selectedLanguage = find(propEq('value', value), languageList)
        const newLanguage = selectedLanguage
          ? {
              value: selectedLanguage.value,
              name: selectedLanguage.label,
            }
          : {
              value: value.toLocaleLowerCase(),
              name: capitalizeFirstLetter(value),
            }

        handleSave([...languages, newLanguage])
      }
    },
    [languages, languageList, handleSave],
  )

  const removeLanguage = (value: string) => {
    const notLanguage = (n: LanguageType) => n.value !== value
    const newLanguages = filter(notLanguage, languages)
    const defaultLanguageSpeech = getDefaultLanguageSpeech(newLanguages)
    if (!defaultLanguageSpeech && speechEnabled) {
      const languageWithSpeech = find((l) => !!l.speechLanguage, newLanguages)
      if (languageWithSpeech && languageWithSpeech.speechLanguage) {
        languageWithSpeech.speechLanguage.default = true
      }
    }
    const w = checkForWarning(newLanguages, props.languages)
    if (w) {
      setTempLanguages(newLanguages)
      setConfirmationDialog(true)
      setWarning(w)
    } else {
      handleSave(newLanguages)
    }
  }

  const setDefaultLang = (value: string) => {
    handleSave(languages, value)
  }

  const changeSpeechLanguage = (index: number, value: string) => {
    const defaultLanguageSpeech = find((l) => !!l.speechLanguage?.default, languages)

    const speechLanguage = find(propEq('value', value), props.speechLanguageList)
    if (!speechLanguage) {
      return
    }

    const newLanguages = languages.map((l, i) => {
      if (i !== index) {
        return l
      }

      return {
        ...l,
        speechLanguage: {
          name: speechLanguage.label,
          value: speechLanguage.value,
          default: !defaultLanguageSpeech || l.speechLanguage?.default,
        },
      }
    })

    handleSave(newLanguages)
  }

  const setDefaultSpeechLanguage = (index: number) => {
    const newLanguage = languages[index]
    if (!newLanguage.speechLanguage) {
      return
    }
    const newLanguages = languages.map((l, i) => {
      if (!l.speechLanguage) {
        return l
      }
      return {
        ...l,
        speechLanguage: {
          ...l.speechLanguage,
          default: i === index,
        },
      }
    })

    handleSave(newLanguages)
  }

  const setSpeechLangEnabled = (value: boolean) => {
    const newLanguages = !value ? languages.map((l) => omit(['speechLanguage'], l)) : languages
    const w = checkForWarning(newLanguages, props.languages)
    if (w) {
      setTempLanguages(newLanguages)
      setConfirmationDialog(true)
      setWarning(w)
    } else {
      setSpeechEnabled(value)
    }
  }

  React.useEffect(() => {
    setLanguages(props.languages)
  }, [props.languages])

  React.useEffect(() => {
    setSpeechEnabled(props.speechEnabled)
  }, [props.speechEnabled])

  React.useEffect(() => {
    setDefaultLanguage(getDefaultLanguage(languages, props.defaultLanguage))
  }, [languages, props.defaultLanguage])

  const defaultLanguageSpeech = React.useMemo(() => find((l) => !!l.speechLanguage?.default, languages), [languages])

  const speechLanguageList = React.useMemo(
    () =>
      map(
        (l) => ({
          ...l,
          disabled: l.value === defaultLanguageSpeech?.speechLanguage?.value,
        }),
        props.speechLanguageList,
      ),
    [defaultLanguageSpeech, props.speechLanguageList],
  )

  const languageOptions = React.useMemo<LanguageOption[]>(
    () =>
      languageList.map((l) => ({
        id: l.value,
        label: l.label,
      })),
    [languageList],
  )

  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardContent>
        <Box className={classes.header}>
          <Typography variant="h6">Language settings</Typography>
        </Box>
        <Box className={classes.content}>
          <Typography>Add all the languages supported in this campaign</Typography>
          <>
            <div className={classes.form}>
              <FormControl variant="standard" style={{ marginBottom: 16, width: 300 }}>
                <SearchableSelect
                  clearOnBlur
                  freeSolo
                  hideRemoveSelection
                  id="language-select"
                  options={languageOptions}
                  showAll
                  value=""
                  filterOptions={filterOptionsFn}
                  getOptionLabel={getOpionLabelFn}
                  keyPropFn={keyPropFn}
                  onChange={addLanguage}
                  valuePropFn={valuePropFn}
                />
              </FormControl>
              {props.speechSupported && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={speechEnabled}
                      color="primary"
                      disabled={languages.length === 0}
                      name="speechEnabled"
                      onChange={(e) => setSpeechLangEnabled(e.target.checked)}
                    />
                  }
                  label="Enable speech recognition"
                />
              )}
              {speechEnabled && (
                <IconText>
                  <Icon>info</Icon>
                  <Typography variant="caption" color="textSecondary">
                    For every speech recognition question that a call reaches, $0.03 is charged. Please read more about
                    speech recognition{' '}
                    <Link
                      href="https://www.engagespark.com/support/how-much-does-speech-recognition-cost/"
                      target="_blank"
                    >
                      here
                    </Link>
                    .
                  </Typography>
                </IconText>
              )}
            </div>
            {languages.length > 0 && (
              <div style={{ marginTop: '30px' }}>
                {
                  <div className={cx(classes.languageListHeader)}>
                    <div className={classes.languageName}>Language</div>
                    {speechEnabled && <div className={classes.speech}>Speech Recognition Language</div>}
                    <div className={classes.actions}></div>
                  </div>
                }
                {languages.map((language, i) => {
                  const isDefault = language.value === defaultLanguage
                  const isDefaultSpeech =
                    defaultLanguageSpeech?.speechLanguage && language.speechLanguage
                      ? defaultLanguageSpeech.speechLanguage.value === language.speechLanguage.value
                      : i === 0
                  const hasSpeech = !!language.speechLanguage

                  let defaultSpeechText = isDefaultSpeech
                    ? 'Default speech language'
                    : 'Set this language as the default speech langauge'
                  if (!hasSpeech) {
                    defaultSpeechText = 'You have to select a speech language before you can set it as default'
                  }
                  const currentSpeechLanguageList = map((l) => {
                    if (l.value !== 'default') {
                      return l
                    }
                    return {
                      ...l,
                      disabled: isDefaultSpeech || !defaultLanguageSpeech,
                    }
                  }, speechLanguageList)

                  /**
                   * can only set default speech language if all of the below conditions are met:
                   * 1. The current language has a speech language
                   * 2. The current language's speech language value is not 'default'
                   */
                  const setDefaultSpeechDisabled =
                    !language.speechLanguage || language.speechLanguage.value === 'default'

                  return (
                    <div
                      className={cx(classes.languageListItem, { default: isDefault })}
                      id={`selected-language-${language.value}`}
                      key={language.value}
                    >
                      <div className={classes.languageName}>
                        <Typography variant="body1">{`${language.name}${
                          language.value === defaultLanguage ? ' (Default)' : ''
                        }`}</Typography>
                        <Tooltip
                          title={isDefault ? 'Default language' : "Set this language as campaign's default language"}
                        >
                          <span>
                            <FormControlLabel
                              control={<Checkbox />}
                              checked={isDefault}
                              data-testid="set-default-language"
                              label="Default"
                              name="language-default"
                              value={language.value}
                              onChange={() => {
                                setDefaultLang(language.value)
                              }}
                            />
                          </span>
                        </Tooltip>
                      </div>
                      {speechEnabled && (
                        <div className={classes.speech}>
                          <Select
                            id={`speech-language-${language.value}`}
                            name={`speech-language-${language.value}`}
                            style={{ marginBottom: '-0.5rem', width: '100%' }}
                            value={language.speechLanguage?.value || ''}
                            values={currentSpeechLanguageList}
                            onChange={(selected: { value: string }) => {
                              changeSpeechLanguage(i, selected.value)
                            }}
                          />
                          <Tooltip title={defaultSpeechText}>
                            <FormControlLabel
                              control={<Checkbox />}
                              checked={isDefaultSpeech}
                              disabled={setDefaultSpeechDisabled}
                              id={`speech-language-default-${language.value}`}
                              label="Default"
                              name="speech-language-default"
                              value={language.value}
                              onChange={() => {
                                setDefaultSpeechLanguage(i)
                              }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      <div className={classes.actions}>
                        <Tooltip title="Remove language">
                          <IconButton
                            color="secondary"
                            data-testid="remove-language-button"
                            onClick={() => removeLanguage(language.value)}
                          >
                            <Icon>remove_circle</Icon>
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </>
        </Box>
      </CardContent>
      <ConfirmationDialog
        deleteButtonText="Confirm"
        text={warning}
        icon="language"
        isOpen={confirmationDialog}
        title="Update language settings"
        onClose={() => setConfirmationDialog(false)}
        onConfirm={() => {
          handleSave(tempLanguages)
          setConfirmationDialog(false)
        }}
      />
    </Card>
  )
}

type LanguageOption = {
  label: string
  id: string
}

const filterOption = createFilterOptions<LanguageOption>()
const filterOptionsFn = (options: LanguageOption[], params: FilterOptionsState<LanguageOption>) => {
  const filtered = filterOption(options, params)

  const isExisting = find(propEq('id', params.inputValue.toLowerCase()), options)

  if (params.inputValue !== '' && !isExisting) {
    filtered.push({
      id: params.inputValue,
      label: `"${capitalizeFirstLetter(
        params.inputValue,
      )}" is not in our list of languages. That's fine! To add it as a new language, click here or press Enter.`,
    })
  }

  return filtered
}

export const getDefaultLanguage = (languages: LanguageType[], defaultLanguage: string) => {
  if (languages.length > 0) {
    const found = find(propEq('value', defaultLanguage), languages)
    if (!found) {
      return languages[0].value
    }
    return defaultLanguage
  }
  return ''
}

export const processLanguageList = (languages: LanguageType[]): LanguageType[] =>
  map(
    (language: LanguageType) => ({
      ...language,
      name: capitalizeTheFirstLetterOfEachWord(language.name),
    }),
    languages,
  )

export const checkForWarning = (languages: LanguageType[], oldLanguages: LanguageType[]) => {
  const hasLanguage = (n: LanguageType) => !find(propEq('value', n.value), languages)
  const removedLanguages = filter(hasLanguage, oldLanguages)
  const disabledSpeech = hasSpeechLanguage(oldLanguages) && !hasSpeechLanguage(languages)
  const warnings: React.ReactNode[] = []
  if (removedLanguages.length && languages?.length) {
    const listOfRemovedLanguages: string = reduceIndexed(
      (accum: string, elem: LanguageType, index: number) => {
        const separtor = removedLanguages.length - 1 > index ? ', ' : ' and '

        return `${accum}${index === 0 ? '' : separtor}${elem.name}`
      },
      '',
      removedLanguages,
    )
    warnings.push(
      <span data-testid="removal-warning">
        All <strong>{listOfRemovedLanguages}</strong> messages will be removed from this campaign. Proceed with caution.
      </span>,
    )
  }
  if (disabledSpeech) {
    warnings.push(
      <span data-testid="disable-speech-warning">
        When deactivating Speech Recognition,{' '}
        <strong>we'll remove all speech recognition configuration from all questions</strong> in this campaign. This
        means removing all choices from these questions, including any actions you may have configured for these
        choices. All these will be converted to empty Keypress Response questions and need to be updated by you. Please
        confirm you want this.
      </span>,
    )
  }

  if (!warnings.length) {
    return null
  }

  return (
    <>
      <Typography variant="h6">Warning:</Typography>
      <ul>
        {warnings.map((warning, i) => (
          <li key={i}>{warning}</li>
        ))}
      </ul>
    </>
  )
}

const useStyles = makeStyles()((theme) => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  content: {
    paddingBottom: theme.spacing(3),
  },
  actions: {
    display: 'flex',
    flexBasis: '20%',
    justifyContent: 'flex-end',
  },
  languageListHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  languageListItem: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingLeft: theme.spacing(),
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    '&.default': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  languageName: {
    flex: 1,
  },
  makeDefaultIcon: {
    transform: 'rotateX(180deg)',
  },
  speech: {
    width: 200,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
