import React from 'react'
import { makeStyles } from 'tss-react/mui'
import InputField from '@/app/component/atom/form/input'
import validate, { hasMaxError, hasRequiredError } from '@/app/service/validate'
import { ChangeEvent } from '@/app/types'
import { WA_BUTTON_TITLE_LENGTH } from '@/app/module/campaigns/definitions'
import { LocalWhatsAppButtonItem } from '@/app/module/campaigns/types'
import { trimContent } from '@/app/module/campaigns/component/item/steps/content/types/multimessage/content'

type Props = {
  disabled?: boolean
  tagList: LocalWhatsAppButtonItem
  onChange: (value: Partial<LocalWhatsAppButtonItem>) => void
}

const validators = {
  title: validate(hasRequiredError, hasMaxError(WA_BUTTON_TITLE_LENGTH)),
}

const WhatsappButtonChoice: React.FC<Props> = ({ disabled, tagList, onChange }) => {
  const { title } = tagList

  const [error, setError] = React.useState<Record<string, string>>({})
  const { classes } = useStyles()

  const handleTitleBlur = (value: string) => {
    const trimmed = trimContent(value)

    setError((s) => ({
      ...s,
      title: validators.title(trimmed),
    }))

    if (trimmed !== title) {
      onChange({ ...tagList, title: trimmed })
    }
  }

  React.useEffect(() => {
    if (tagList.title) {
      setError((s) => ({
        ...s,
        title: '',
      }))
    }
  }, [tagList.title])

  return (
    <div className={classes.container}>
      <InputField
        data-testid={`wa-buttons-title-${tagList.id}`}
        editable={!disabled}
        error={error.title}
        label="Text"
        name={`title-${tagList.id}`}
        style={{
          height: 'auto',
          maxHeight: 'none',
          minHeight: '42px',
          whiteSpace: 'unset',
          marginRight: '5px',
          flex: 1,
        }}
        value={title || ''}
        variant="standard"
        onBlur={(({ value }: ChangeEvent) => handleTitleBlur(value)) as any}
        onChange={({ value }: ChangeEvent) => {
          onChange({ ...tagList, title: value })
        }}
      />
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 5,
  },
}))

export default WhatsappButtonChoice
