import React from 'react'
import { Box, Icon, Container, Button, ButtonGroup, MenuItem } from '@mui/material'

import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'
import { ContentTypeIcon } from '@/app/module/campaigns/component/item/steps/content/types/multimessage/content-type-icon'
import { CampaignTemplateType, PartFamily } from '@/app/module/campaigns/types'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { messageTypeTextMap } from '@/app/module/campaigns/definitions'
import { getCampaignType } from '@/app/module/campaigns/helpers'
import { getValidActionsForCampaignType } from '@/app/module/campaigns/utils/actions/list'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { getEmptyMessage } from '@/app/module/campaigns/component/item/steps/content/types/multimessage/utils/empty-message'

const partActions = [
  ActionContext.OnAnswer,
  ActionContext.OnTimeout,
  ActionContext.OnInvalid,
  ActionContext.OnFailure,
  ActionContext.OnButtons,
  ActionContext.OnList,
  ActionContext.OpenEnded,
  ActionContext.OpenEndedCategory,
  ActionContext.ApiSuccess,
  ActionContext.ApiFailure,
  ActionContext.TopupSuccess,
  ActionContext.TopupFailure,
  ActionContext.Default,
]

// const campaignActions = [
//   ActionContext.CallError,
//   ActionContext.CallAnswered,
//   ActionContext.CallNotAnswered,
//   ActionContext.Reconnect,
//   ActionContext.OnTransferFailed,
// ]

function getCampaignTypes() {
  const keys = Object.keys(CampaignTemplateType)
  return keys.reduce<Array<CampaignTemplateType>>((acc, key) => {
    if (!(Number(key) >= 0)) {
      // @ts-ignore
      acc.push(CampaignTemplateType[key])
    }
    return acc
  }, [])
}

function getPartTypes(campaignType: CampaignTemplateType) {
  const typeList = Object.keys(messageTypeTextMap)

  return typeList.reduce<string[]>((acc, key) => {
    try {
      getEmptyMessage(campaignType, key, '', 0)
      acc.push(key)
      return acc
    } catch (err) {
      return acc
    }
  }, [])
}

function getPartFamily(type: string) {
  switch (type) {
    case 'sms-m':
    case 'sms-q':
      return PartFamily.SMS
    case 'voice-m':
    case 'voice-q':
      return PartFamily.Voice
    case 'cati-m':
    case 'cati-q':
      return PartFamily.CATI
    case 'whatsapp-m':
    case 'whatsapp-q':
      return PartFamily.WhatsApp
    case 'api-q':
      return PartFamily.API
    case 'topup-q':
      return PartFamily.Topup
    case 'action-q':
      return PartFamily.Action
    default:
      throw new Error('Not a valid part type')
  }
}

type ActionDisplayProps = {
  campaignType: CampaignTemplateType
  partType: string
  activeNamespace: ActionContext | undefined
  onClick: (actionType: ActionContext) => void
}

function ActionDisplay({ campaignType, partType, activeNamespace, onClick }: ActionDisplayProps) {
  const namespaces = React.useMemo(() => {
    return partActions.filter((context) => {
      const list = getValidActionsForCampaignType({
        campaignType,
        partType: getPartFamily(partType),
        // @ts-ignore
        actionNamespace: context,
      })

      return !!list.length
    })
  }, [campaignType, partType])

  const options = activeNamespace
    ? getActionOptionsForCampaign({
        campaignType,
        partType: getPartFamily(partType),
        // @ts-ignore
        actionNamespace: activeNamespace,
      })
    : []

  return (
    <Box sx={{ my: 2 }}>
      <h4>Types of actions on this part:</h4>
      <Box sx={{ display: 'flex' }}>
        <ButtonGroup orientation="vertical">
          {namespaces.length === 0 && <p>No actions available</p>}
          {namespaces.map((namespace) => (
            <Button
              key={namespace}
              size="large"
              variant={activeNamespace === namespace ? 'contained' : 'outlined'}
              onClick={() => onClick(namespace)}
            >
              {namespace}
            </Button>
          ))}
        </ButtonGroup>
        <Box sx={{ px: 4, ml: 2, bgcolor: '#e2e2e2', flex: 1 }}>
          {options.length === 0 && <p>No options available. Select a namespace from the left.</p>}
          {options.length > 0 && <p>The available actions:</p>}
          {options.map((option) => (
            <MenuItem
              key={option.value}
              sx={{ userSelect: 'auto', display: 'flex', alignItems: 'center', my: 1, gap: 1 }}
            >
              {typeof option.icon === 'string' && <Icon>{option.icon}</Icon>}
              {typeof option.icon !== 'string' && <>{option.icon()}</>}
              {option.label}
            </MenuItem>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

type PartDisplayProps = {
  campaignType: CampaignTemplateType
  activePart: string | undefined
  onClick: (partType: string) => void
}

function PartDisplay({ campaignType, activePart, onClick }: PartDisplayProps) {
  const parts = React.useMemo(() => getPartTypes(campaignType), [campaignType])

  return (
    <Box>
      <h4>Part types in this campaign:</h4>
      <ButtonGroup>
        {parts.map((partType) => (
          <Button
            key={partType}
            size="large"
            sx={{ gap: 1 }}
            disabled={!partType.endsWith('-q')}
            variant={activePart === partType ? 'contained' : 'outlined'}
            onClick={() => onClick(partType)}
          >
            <ContentTypeIcon type={partType} />
            {partType}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  )
}

function RuleDisplay() {
  const [selectedCampaignType, setCampaignType] = React.useState<CampaignTemplateType | undefined>()
  const [selectedPartType, setPartType] = React.useState<string | undefined>()
  const [selectedNamespace, setNamespace] = React.useState<ActionContext | undefined>()
  const campaignTypes = React.useMemo(() => getCampaignTypes(), [])

  return (
    <Box>
      <h3>Campaign Part and Action rules</h3>
      <ButtonGroup sx={{ my: 2 }}>
        {campaignTypes.map((campaignType) => (
          <Button
            key={campaignType}
            size="large"
            variant={selectedCampaignType === campaignType ? 'contained' : 'outlined'}
            onClick={() => {
              setCampaignType(campaignType)
              setPartType(undefined)
              setNamespace(undefined)
            }}
          >
            {getCampaignType({ type: campaignType })}
          </Button>
        ))}
      </ButtonGroup>
      <Box sx={{ m: 1 }}>
        {selectedCampaignType && (
          <PartDisplay
            campaignType={selectedCampaignType}
            activePart={selectedPartType}
            onClick={(partType) => {
              setPartType(partType)
              setNamespace(undefined)
            }}
          />
        )}
      </Box>
      <Box sx={{ m: 1 }}>
        {selectedCampaignType && selectedPartType && (
          <ActionDisplay
            campaignType={selectedCampaignType}
            partType={selectedPartType}
            activeNamespace={selectedNamespace}
            onClick={(namespace) => setNamespace(namespace)}
          />
        )}
      </Box>
    </Box>
  )
}

type CampaignsRulesPageProps = {
  match: {
    url: string
    path: string
  }
}

export default function CampaignsRulesPage({ match }: CampaignsRulesPageProps) {
  return (
    <AuthGuard url={match.url} path={match.path}>
      <Layout title="Campaigns - engageSPARK">
        <AuthHeader currentPage={PAGE.CAMPAIGNS.INDEX} />
        <Container>
          <RuleDisplay />
        </Container>
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}
