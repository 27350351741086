import { Dispatch } from 'redux'
import { createAsyncActions } from '@/app/service/util'
import { createNotification } from '@/app/module/notifications/store/actions'
import baseRequest from '@/app/module/request'
import {
  ACTION_DELETE_EXPORT_FAILURE,
  ACTION_DELETE_EXPORT_REQUEST,
  ACTION_DELETE_EXPORT_SUCCESS,
  ACTION_GET_EXPORTS_FAILURE,
  ACTION_GET_EXPORTS_REQUEST,
  ACTION_GET_EXPORTS_SUCCESS,
  ERROR_DELETE_EXPORT_SERVER,
  ERROR_GET_EXPORTS_SERVER,
} from '@/app/module/exports/definitions'
import { ExportQuery } from '@/app/module/exports/types'

const {
  request: getExportsRequest,
  success: getExportsSuccess,
  failure: getExportsFailure,
} = createAsyncActions({
  request: ACTION_GET_EXPORTS_REQUEST,
  success: ACTION_GET_EXPORTS_SUCCESS,
  failure: ACTION_GET_EXPORTS_FAILURE,
})

const {
  request: deleteExportRequest,
  success: deleteExportSuccess,
  failure: deleteExportFailure,
} = createAsyncActions({
  request: ACTION_DELETE_EXPORT_REQUEST,
  success: ACTION_DELETE_EXPORT_SUCCESS,
  failure: ACTION_DELETE_EXPORT_FAILURE,
})

const request = baseRequest as any

export const getExports =
  ({ token, orgId, query = {} }: { token: string; orgId: number; query: ExportQuery }) =>
  (dispatch: Dispatch) => {
    dispatch(getExportsRequest(query))

    return request.exports
      .getList({ token, orgId, query })
      .then((res: unknown) => {
        if (res instanceof Error) {
          throw res
        }

        dispatch(getExportsSuccess(res))
      })
      .catch((err: Error) => {
        dispatch(getExportsFailure({}))
        dispatch(
          createNotification({
            'get-exports-error': {
              type: 'error',
              message: ERROR_GET_EXPORTS_SERVER,
            },
          }),
        )

        return err
      })
  }

export const deleteExport =
  ({ token, orgId, exportId }: { token: string; orgId: number; exportId: number }) =>
  (dispatch: Dispatch) => {
    dispatch(deleteExportRequest(exportId))
    return request.exports
      .delete({ token, orgId, exportId })
      .then((res: unknown) => {
        if (res instanceof Error) {
          throw res
        }

        dispatch(deleteExportSuccess(exportId))
      })
      .catch((err: Error) => {
        dispatch(deleteExportFailure({}))
        dispatch(
          createNotification({
            'delete-export-error': {
              type: 'error',
              message: ERROR_DELETE_EXPORT_SERVER,
            },
          }),
        )

        return err
      })
  }
