import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import { FunctionType } from '@/config/types'

const useStyle = makeStyles()({
  pageCont: {
    textAlign: 'center',
    marginTop: '70px',
  },
  boxCont: {
    width: '30vw',
    textAlign: 'center',
    margin: 'auto',
    boxShadow: '0px 0px 0px 1px rgb(224 224 224)',
    padding: '30px',
  },
  titleText: {
    marginTop: '20px',
  },
  subtitleText: {},
  linkButton: {
    marginTop: '30px',
  },
  icon: {
    fontSize: '6rem !important',
  },
})

type Props = {
  icon?: string
  title?: string
  subtitle?: string
  linkText?: string
  link?: string
  callback?: FunctionType
}

export default function Error(props: Props) {
  const { classes } = useStyle()
  const { icon, title, subtitle, link, linkText } = props
  return (
    <div className={classes.pageCont}>
      <div className={classes.boxCont}>
        <Icon className={classes.icon} color="primary">
          {icon}
        </Icon>
        <Typography className={classes.titleText} variant="h4">
          {title}
        </Typography>
        {subtitle && (
          <Typography className={classes.subtitleText} color="textSecondary" variant="h6">
            {subtitle}
          </Typography>
        )}
        {props.callback && (
          <Button className={classes.linkButton} variant="contained" color="primary" onClick={props.callback}>
            {linkText}
          </Button>
        )}
        {link && (
          <Button className={classes.linkButton} variant="contained" color="primary" href={link}>
            {linkText}
          </Button>
        )}
      </div>
    </div>
  )
}
