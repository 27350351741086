import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { pathOr, values } from 'ramda'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import ErrorLayout from '../../../../../component/layout/error'
import IconText from '../../../../../component/layout/icon-text'
import { TYPES } from '../../../definitions'

export default function createCampaignGuard(componentMap) {
  return class CampaignGuard extends Component {
    static validate(props) {
      return pathOr(() => true, [props.item.type, 'validate'], componentMap)(props)
    }

    static transform(props) {
      return pathOr((p) => p, [props.item.type, 'transform'], componentMap)(props)
    }

    render() {
      const { type, item, itemId, history } = this.props
      const itemType = type || item.type
      const Content = componentMap[itemType]
      const loading = this.props.loading || (itemId && !item.id)

      if (!Content && loading) {
        return <div style={{ height: '150px' }} />
      }

      if (!Content) {
        return (
          <ErrorLayout status={itemType ? 404 : ''} style={{ maxWidth: '100%' }}>
            <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
              {itemType && (
                <div>
                  <Typography gutterBottom={true}>{`The type of ${itemType} campaign does not exist.`}</Typography>
                  <Button color="primary" variant="contained" component={Link} to="/campaigns/create">
                    Start over
                  </Button>
                </div>
              )}
              {!itemType && (
                <div>
                  <Typography gutterBottom={true}>
                    Before proceeding you need to choose a campaign type from the following available ones:
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {values(TYPES).map(({ name, title, icon, url }) => (
                      <Button
                        key={name}
                        color="primary"
                        variant="outlined"
                        style={{ margin: '2rem 1rem' }}
                        onClick={() => {
                          history.push(`/campaigns/create/${url}`)
                        }}
                      >
                        <IconText>
                          <Icon style={{ fontSize: '3rem' }}>{icon}</Icon>
                          <br />
                          <Typography>{title}</Typography>
                        </IconText>
                      </Button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </ErrorLayout>
        )
      }

      return <Content {...this.props} />
    }
  }
}
