import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { formatDate } from '@/app/service/util'
import FormControl from './form-control'
import Input from './input'

const dateFormat = 'YYYY-MMM-DD'

type DateFieldProps = {
  name: string
  label?: string
  editable?: boolean
  value?: string
  error?: string
  info?: string
  onChange: (p: { name: string; value: string }) => void
  onOpen: () => void
  onClose: () => void
  timezone?: string
  disablePast?: boolean
}

export default function DateField({
  name,
  label,
  editable = true,
  value = '',
  error = '',
  onChange = () => {},
  onOpen = () => {},
  onClose = () => {},
  timezone,
}: DateFieldProps) {
  if (!editable) {
    return (
      <Input
        type="text"
        name={name}
        label={label}
        editable={false}
        value={formatDate(value, timezone)}
        onChange={() => {}}
      />
    )
  }
  return (
    <FormControl error={error}>
      <DatePicker
        label={label}
        value={value ? moment(value) : null}
        format={dateFormat}
        onOpen={onOpen}
        onClose={onClose}
        onChange={(nextValue) => {
          onChange({
            name,
            value: nextValue ? nextValue.format(dateFormat) : '',
          })
        }}
      />
    </FormControl>
  )
}
