import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { OrgReportResult } from '@/app/module/reports/types'

type Props = {
  data: OrgReportResult
}

type CellData = Record<string, string>

const ReportTable: React.FC<Props> = ({ data }) => {
  const { headers, cells } = data

  const cellsData = React.useMemo<CellData[]>(() => {
    return cells.map((row) => {
      return row.reduce<CellData>((acc, cell, index) => {
        if (!headers[index]) {
          return acc
        }
        const { id } = headers[index]
        acc[id] = cell

        return acc
      }, {})
    })
  }, [cells, headers])

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(({ id, title, numeric }) => (
              <TableCell key={id} align={numeric ? 'right' : 'center'}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {cellsData.map((row, index) => (
            <TableRow key={index}>
              {headers.map(({ id, numeric }) => (
                <TableCell key={`${id}-${index}`} align={numeric ? 'right' : 'center'}>
                  {row[id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReportTable
