import { Callback, Props, Step } from 'react-joyride'

export type Tour = Partial<Props> & {
  stepIndex: number
  steps: TourStep[]
  callback: Callback
}

export type TourStep = Step & {
  onMounted?: () => Promise<void> // after the current step is shown
  onUnmounting?: () => Promise<void> // before the next step is shown
}

export enum TourType {
  Drip = 'drip',
}
