import Badge from '@mui/material/Badge'
import { withStyles } from 'tss-react/mui'

const NewMessageBadge = withStyles(Badge, () => ({
  badge: {
    top: 5,
    right: 35,
    fontSize: 11,
    height: 17,
    minWidth: 17,
  },
}))

export default NewMessageBadge
