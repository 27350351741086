import Button from '@mui/material/Button'
import { append, map, remove } from 'ramda'
import React from 'react'
import { v1 as uuid } from 'uuid'
import { Condition } from '@/app/types'
import { ContactGroup } from '@/app/module/contacts/types'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { OperandsList } from './definitions'
import SingleCondition from './single-condition'

type Props = {
  conditionRow: Condition[]
  error?: boolean
  groupedOperators?: boolean
  groups: {
    data?: Record<string, Pick<ContactGroup, 'name' | 'createdAt' | 'numberOfContacts'>>
    ordered?: string[]
  }
  isFirst: boolean
  isLast: boolean
  operands: OperandsList
  personalizationList: PersonalizationType
  rowIndex: number
  segments: {
    data?: Record<string, Pick<ContactGroup, 'name' | 'createdAt' | 'numberOfContacts'>>
    ordered?: string[]
  }
  onAddConditionGroup: () => void
  onChangeCondition: (data: { updatedConditionGroup: Condition }) => void
}

const ConditionGroup: React.FC<Props> = ({
  conditionRow,
  error = false,
  groupedOperators = false,
  groups,
  isLast,
  isFirst,
  operands,
  personalizationList,
  rowIndex,
  segments,
  onAddConditionGroup,
  onChangeCondition,
}) => {
  const [idArray, setIdArray] = React.useState<string[]>(map(() => uuid(), remove(0, 1, conditionRow)))
  const conditionGroup = React.useMemo(() => remove(0, 1, conditionRow), [conditionRow]) // conditionRow = ['and', ...whatever conditions] so we need to remove the and

  return (
    <div data-testid={`condition-group-${rowIndex}`}>
      {conditionGroup.map((condition, index) => (
        <SingleCondition
          key={`conditions-group-${idArray[index]}`}
          error={error}
          index={index}
          groupedOperators={groupedOperators}
          isFirst={isFirst && index === 0}
          operands={operands}
          condition={condition}
          personalizationList={personalizationList}
          groups={groups || []}
          segments={segments || []}
          onConditionChange={(updatedCondition: Condition) => {
            const updatedConditionGroup = [...conditionGroup]
            updatedConditionGroup[index] = updatedCondition
            onChangeCondition({
              updatedConditionGroup: ['and', ...updatedConditionGroup],
            })
          }}
          onDelete={() => {
            setIdArray(remove(index, 1, idArray))
            onChangeCondition({
              updatedConditionGroup: ['and', ...remove(index, 1, conditionGroup)],
            })
          }}
        />
      ))}
      <div
        style={{
          display: 'flex',
          paddingTop: '10px',
          paddingBottom: '10px',
          justifyContent: 'flex-end',
        }}
      >
        <React.Fragment>
          <Button
            data-testid={`add-and-condition-${rowIndex}`}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setIdArray(append(uuid(), idArray))
              onChangeCondition({
                updatedConditionGroup: ['and', ...append(['', '', ''], conditionGroup)],
              })
            }}
          >
            <span>
              Add another <strong>(AND)</strong> condition
            </span>
          </Button>
          {isLast && (
            <Button
              data-testid={`add-or-condition-${rowIndex}`}
              variant="contained"
              color="primary"
              size="small"
              style={{
                marginLeft: 12,
              }}
              onClick={() => {
                onAddConditionGroup()
              }}
            >
              <span>
                Add an alternative <strong>(OR)</strong> condition
              </span>
            </Button>
          )}
        </React.Fragment>
      </div>
    </div>
  )
}

export default ConditionGroup
