import { History } from 'history'
import { CampaignTemplate } from './types'

export const getCampaignTemplate = (history: History) => {
  const params = new URLSearchParams(history.location.search)

  return params.get('template')
}

export const isSkippingLanguage = (type: string, template: CampaignTemplate) =>
  type === 'drip' && template === CampaignTemplate.AirtimeTopup
