import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import React from 'react'

type Props = {
  context: ActionContext.OnAnswer | ActionContext.OpenEnded
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const openEndedContent =
  'Allow contacts to reply with a voice clip. Use Artificial Intelligence (AI) to then automatically detect the spoken language, if any, and transcribe it to text. This text is then used as answer to the open-ended question.'
const specificContent =
  'Allow contacts to reply with a voice clip. Use Artificial Intelligence (AI) to then automatically detect the spoken language, if any, and transcribe it to text. This text is then used to find the matching answer.'

const WhatsappTranscribeDialog: React.FC<Props> = ({ context, open, onClose, onConfirm }) => {
  return (
    <ConfirmationDialog
      id="whatsapp-transcribe-dialog"
      deleteButtonText="Enable Transcription"
      isOpen={open}
      title="Transcribe voice clips replies with AI"
      icon="assistant"
      text={
        <>
          <br />
          <span>{context === ActionContext.OpenEnded ? openEndedContent : specificContent}</span>
          <br />
          <br />
          <span>
            <b>NOT PERFECT:</b> AI is far from perfect and can get things (very) wrong, both the spoken language and
            what is being said. In particular, single-word clips or other very short responses are often misunderstood.
            We highly recommend you test how well AI does for your use-case.
          </span>
          <br />
          <br />
          <span>
            <b>DATA SHARING:</b> This means we will send data to a large language model like ChatGPT. The ONLY thing we
            send is the question as you gave it above, and the audio file—nothing else. In particular, we will NOT send
            the phone number, first or last name or any other contact data—unless it's included in the question or the
            reply.
          </span>
          <br />
          <br />
          <b>
            By enabling this feature you confirm that you understand this and consent to the data sharing as described
            above.
          </b>
        </>
      }
      onClose={onClose}
      onConfirm={onConfirm}
    />
  )
}
export default WhatsappTranscribeDialog
