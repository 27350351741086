import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { Component } from 'react'
import { withStyles } from 'tss-react/mui'
import Loading from '../../../component/guard/loading'
import ContentLayout from '../../../component/layout/content'
import TableToolbar from '../../../component/layout/table/toolbar'
import Table from './table'

const styles = () => ({
  toolbar: {
    justifyContent: 'flex-end',
    padding: '0.5rem 24px',
  },
  cardSize: {
    margin: 'auto',
    left: '0',
    right: '0',
  },
})

class PhoneNumbersList extends Component {
  state = {
    loading: true,
    webhookModal: false,
    windowHeight: 0,
    windowWidth: 0,
    phoneNumberId: '',
    phoneNumber: '',
    webhookValue: {},
  }

  componentDidMount() {
    let ref = null
    this.updateWindowDimensions()
    window.addEventListener('resize', () => {
      if (ref === null) {
        ref = requestAnimationFrame(() => {
          this.updateWindowDimensions()
          ref = null
        })
      }
    })

    const { token, orgId } = this.props
    if (token && orgId) {
      this.props
        .hydrate({
          token,
          orgId,
        })
        .then(() => this.setState({ loading: false }))
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })
  }

  componentDidUpdate(prevProps) {
    const { hydrate, token, orgId } = this.props
    if (token && orgId && (!prevProps.token || orgId !== prevProps.orgId)) {
      this.setState({ loading: true })
      hydrate({
        token,
        orgId,
      }).then(() => this.setState({ loading: false }))
    }
  }

  reloadPhoneNumbers = () => {
    const { token, orgId } = this.props
    this.setState({ loading: true })
    this.props.reloadPhoneNumbers()
    if (token && orgId) {
      this.props
        .getPhoneNumbers({
          token,
          orgId,
        })
        .then(() => this.setState({ loading: false }, () => this.forceUpdate()))
    }
  }

  openWebHookModal = ({ id, bccWebhook, phoneNumber }) => {
    this.setState({
      webhookModal: true,
      phoneNumberId: id,
      phoneNumber,
      webhookValue: {
        makeAPIRequest: bccWebhook,
      },
    })
  }

  configurePhoneNumber = (phoneNumberId) => {
    this.props.history.push(`/phonenumbers/${phoneNumberId}`)
  }

  render() {
    const classes = withStyles.getClasses(this.props)
    const { error, phoneNumbers } = this.props
    return (
      <Loading isLoading={this.state.loading && !phoneNumbers.length} error={error}>
        <ContentLayout>
          <TableToolbar className={classes.toolbar}>
            <div>
              <Tooltip id="reload-phonenumbers" title="Reload Phone Numbers">
                <IconButton onClick={this.reloadPhoneNumbers}>
                  <Icon>refresh</Icon>
                </IconButton>
              </Tooltip>
            </div>
          </TableToolbar>
        </ContentLayout>
        <div
          style={{
            margin: 'auto',
          }}
        >
          <Table
            phoneNumbers={phoneNumbers}
            loading={this.state.loading}
            configurePhoneNumber={this.configurePhoneNumber}
            paging={false}
            style={{
              border: '1px solid rgba(224, 224, 224, 1)',
            }}
          />
        </div>
      </Loading>
    )
  }
}

export default withStyles(PhoneNumbersList, styles)
