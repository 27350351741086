import CopyBox from '@/app/component/atom/copy-box'

export default function RecoveryCodes({ recoveryCodes }: { recoveryCodes: string[] }) {
  return (
    <CopyBox
      style={{
        maxWidth: '200px',
      }}
      multiline={true}
      title="Recovery codes"
      text={recoveryCodes.join('\n')}
    />
  )
}
