import { MESSAGE_INBOUND } from './definitions'

export const isInbound = (item) => item.direction === MESSAGE_INBOUND

export const getContactNumber = (item) => (isInbound(item) ? item.sender : item.recipient)

export const getSenderReceiverNumber = (item) => (isInbound(item) ? item.recipient : item.sender)

export const getStatus = (item) => {
  const { deliveredToPhone, deliveredToTelco, done } = item
  if (isInbound(item)) return 'Received'
  if (done) {
    if (!deliveredToTelco || deliveredToPhone === 'failed') return 'Failed'
    if (deliveredToPhone === 'confirmed') return 'Delivered'
    return 'Sent'
  }
  return 'Pending'
}

export const getCallerNumber = (item) => (isInbound(item) ? item.callerId : item.to)

export const getCallerReceiverNumber = (item) => (isInbound(item) ? item.to : item.callerId)
