import { isEmpty, omit, pick, uniq } from 'ramda'
import moment from 'moment'
import { v1 as uuid } from 'uuid'
import { noTimezoneDateTimeFormat } from '@/app/service/util/format'
import { cannot } from '@/app/helpers'
import {
  BackendCampaignTemplate,
  BaseCampaignItem,
  CATICampaignItem,
  LegacyCampaignItem,
  CampaignTemplate,
  CampaignTemplateType,
  DripCampaignItem,
  DripPart,
  IVRCampaignItem,
  SMSBlastCampaignItem,
  SMSSurveyCampaignItem,
  WhatsAppSurveyCampaignItem,
  PartTypes,
  CATIPart,
  SMSSurveyPart,
  IVRPart,
  WhatsAppSurveyPart,
} from '@/app/module/campaigns/types'
import { DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import {
  CATICampaignDTO,
  DripCampaignDTO,
  IVRCampaignDTO,
  SMSBlastCampaignDTO,
  SMSSurveyCampaignDTO,
  WhatsAppSurveyCampaignDTO,
} from '@/app/module/campaigns/types/dtos'
import { generateCampaignName } from './transform'

const processCallerId = (callerId: string) => (!callerId || callerId === DEFAULT_SENDER_ID_VALUE ? '' : callerId)

const processCallerIds = (callerIds: string[] | null) => (callerIds ? uniq(callerIds.map(processCallerId)) : callerIds)

const getLaunch = (launch: BaseCampaignItem['launch']) => {
  if (!launch || launch.immediate || !launch.at) {
    return {
      immediate: true,
    }
  }

  return {
    ...launch,
    at: moment.tz(launch.at, noTimezoneDateTimeFormat, launch.timezone || '').format(),
  }
}

export function ensureDefaultAnswersCati(part: CATIPart): CATIPart {
  if (!(PartTypes.CATIQuestion in part)) {
    return part
  }

  if (part.catiQuestion.openEnded || !isEmpty(part.catiQuestion.onAnswer)) {
    // if has openEnded or onAnswer defined, return as is
    return part
  }

  // if no openEnded or onAnswer defined, set default onAnswer
  return {
    ...part,
    catiQuestion: {
      ...part.catiQuestion,
      onAnswer: [
        {
          reply: 'yes',
        },
        {
          reply: 'no',
        },
      ],
    },
  }
}

export function ensureDefaultAnswersSMS(part: SMSSurveyPart): SMSSurveyPart {
  if (!(PartTypes.SMSQuestion in part)) {
    return part
  }

  if (part.smsQuestion.openEnded || !isEmpty(part.smsQuestion.onAnswer)) {
    // if has openEnded or onAnswer defined, return as is
    return part
  }

  // if no openEnded or onAnswer defined, set default onAnswer
  return {
    ...part,
    smsQuestion: {
      ...part.smsQuestion,
      onAnswer: [
        {
          replies: ['yes'],
        },
        {
          replies: ['no'],
        },
      ],
    },
  }
}

export function ensureDefaultAnswersVoice(part: IVRPart): IVRPart {
  if (!(PartTypes.VoiceQuestion in part)) {
    return part
  }

  if (part.voiceQuestion.speechSettings || part.voiceQuestion.spoken || !isEmpty(part.voiceQuestion.onAnswer)) {
    // if has speechSettings, spoken or onAnswer defined, return as is
    return part
  }

  // if no speechSettings, spoken or onAnswer defined, set default onAnswer
  return {
    ...part,
    voiceQuestion: {
      ...part.voiceQuestion,
      onAnswer: [
        {
          replies: ['1'],
        },
        {
          replies: ['2'],
        },
      ],
    },
  }
}

export function ensureDefaultAnswersWhatsApp(part: WhatsAppSurveyPart): WhatsAppSurveyPart {
  if (!(PartTypes.WhatsAppQuestion in part)) {
    return part
  }

  const hasButtons = part.whatsappQuestion.onButtons && !isEmpty(part.whatsappQuestion.onButtons.buttons)
  const hasList = part.whatsappQuestion.onList && !isEmpty(part.whatsappQuestion.onList.items)

  if (hasButtons || hasList || part.whatsappQuestion.openEnded || !isEmpty(part.whatsappQuestion.onAnswer)) {
    // if has onButtons, onList, openEnded or onAnswer defined, return as is
    return part
  }

  if (part.whatsappQuestion.onButtons) {
    // if onButtons defined and empty, set default onButtons
    return {
      ...part,
      whatsappQuestion: {
        ...part.whatsappQuestion,
        onButtons: {
          buttons: [
            {
              id: uuid(),
              actions: [],
              title: 'yes',
            },
            {
              id: uuid(),
              actions: [],
              title: 'no',
            },
          ],
        },
      },
    }
  }

  if (part.whatsappQuestion.onList) {
    // if onList defined and empty, set default onList
    return {
      ...part,
      whatsappQuestion: {
        ...part.whatsappQuestion,
        onList: {
          button: '',
          items: [
            {
              id: uuid(),
              actions: [],
              description: '',
              title: 'yes',
            },
            {
              id: uuid(),
              actions: [],
              description: '',
              title: 'no',
            },
          ],
        },
      },
    }
  }

  // if no onButtons, onList, openEnded or onAnswer defined, set default onAnswer
  return {
    ...part,
    whatsappQuestion: {
      ...part.whatsappQuestion,
      onAnswer: [
        {
          replies: ['yes'],
        },
        {
          replies: ['no'],
        },
      ],
    },
  }
}

export function ensureDefaultAnswersDrip(part: DripPart): DripPart {
  if (!(PartTypes.SMSQuestion in part) && !(PartTypes.VoiceQuestion in part) && !(PartTypes.WhatsAppQuestion in part)) {
    return part
  }

  if (PartTypes.SMSQuestion in part) {
    return ensureDefaultAnswersSMS(part)
  }

  if (PartTypes.VoiceQuestion in part) {
    return ensureDefaultAnswersVoice(part)
  }

  return ensureDefaultAnswersWhatsApp(part)
}

export function processDripParts(parts: DripPart[]): DripPart[] {
  return parts.map((part) => {
    const deltaUnit = part.delta?.unit
    const deltaValue = part.delta?.value ? parseInt(part.delta.value.toString(), 10) : 0
    const delta = {
      unit: !deltaUnit || deltaUnit === 'immediate' ? ('' as const) : deltaUnit,
      value: Number.isNaN(deltaValue) ? 0 : deltaValue,
      ...pick(['country', 'dayOfWeek', 'daysOfWeek', 'time', 'timezone'], part.delta || {}),
    }

    return {
      ...ensureDefaultAnswersDrip(part),
      delta,
    }
  }, parts)
}

/**
 * Converts campaign model to dto
 */
export function fromCampaign(item: CATICampaignItem, template: CampaignTemplate): CATICampaignDTO
export function fromCampaign(item: DripCampaignItem, template: CampaignTemplate): DripCampaignDTO
export function fromCampaign(item: IVRCampaignItem, template: CampaignTemplate): IVRCampaignDTO
export function fromCampaign(item: SMSBlastCampaignItem, template: CampaignTemplate): SMSBlastCampaignDTO
export function fromCampaign(item: SMSSurveyCampaignItem, template: CampaignTemplate): SMSSurveyCampaignDTO
export function fromCampaign(item: WhatsAppSurveyCampaignItem, template: CampaignTemplate): WhatsAppSurveyCampaignDTO
export function fromCampaign(item: LegacyCampaignItem, template: CampaignTemplate) {
  const name = generateCampaignName(item, template)
  const baseCampaign: BaseCampaignItem = {
    ...omit(['type'], item),
    callWindow: item.callWindow || {},
    launch: getLaunch(item.launch),
    launchOpen: item.launchOpen === undefined ? true : item.launchOpen,
    name,
    published: item.published || false,
    reconnectMax: typeof item.reconnectMax === 'number' ? item.reconnectMax : -1,
    reconnectOnProgress: item.reconnectOnProgress === undefined ? true : item.reconnectOnProgress,
  }
  switch (item.type) {
    case CampaignTemplateType.SMSBlast: {
      const campaign: SMSBlastCampaignDTO = {
        ...baseCampaign,
        template: BackendCampaignTemplate.SMSBlast,
        variables: {
          message: item.variables.message,
          defaultSenderId: item.variables.defaultSenderId,
          senderId: item.variables.senderId || '',
        },
      }
      return campaign
    }
    case CampaignTemplateType.CATI: {
      const campaign: CATICampaignDTO = {
        ...baseCampaign,
        template: BackendCampaignTemplate.CATI,
        variables: {
          defaultSenderId: item.variables.defaultSenderId,
          parts: item.variables.parts.map(ensureDefaultAnswersCati),
          senderId: item.variables.senderId || '',
        },
      }
      return campaign
    }
    case CampaignTemplateType.Drip: {
      const campaign: DripCampaignDTO = {
        ...baseCampaign,
        template: BackendCampaignTemplate.Drip,
        variables: {
          callerId: processCallerId(item.variables.callerId),
          callerIds: processCallerIds(item.variables.callerIds),
          defaultSenderId: item.variables.defaultSenderId,
          onTransferFailed: item.variables.onTransferFailed,
          parts: processDripParts(item.variables.parts),
          senderId: item.variables.senderId || '',
          senderIdQuestions: item.variables.senderIdQuestions || '',
          senderIdReplies: item.variables.senderIdReplies || '',
          senderIdWhatsapp: item.variables.senderIdWhatsapp || '',
        },
      }
      return campaign
    }
    case CampaignTemplateType.SMSSurvey: {
      const campaign: SMSSurveyCampaignDTO = {
        ...baseCampaign,
        template: BackendCampaignTemplate.SMSSurvey,
        variables: {
          defaultSenderId: item.variables.defaultSenderId,
          parts: item.variables.parts.map(ensureDefaultAnswersSMS),
          senderId: item.variables.senderId || '',
          senderIdQuestions: item.variables.senderIdQuestions,
          senderIdReplies: item.variables.senderIdReplies,
          welcome: item.variables.welcome,
        },
      }
      return campaign
    }
    case CampaignTemplateType.VoiceSurvey: {
      const campaign: IVRCampaignDTO = {
        ...baseCampaign,
        template: BackendCampaignTemplate.VoiceSurvey,
        variables: {
          callResultActions: item.variables.callResultActions,
          callerId: processCallerId(item.variables.callerId),
          callerIds: processCallerIds(item.variables.callerIds),
          defaultSenderId: item.variables.defaultSenderId,
          onTransferFailed: item.variables.onTransferFailed,
          parts: item.variables.parts.map(ensureDefaultAnswersVoice),
          reconnectActions: item.variables.reconnectActions,
          senderId: item.variables.senderId || '',
          welcome: item.variables.welcome,
        },
      }
      return campaign
    }
    case CampaignTemplateType.WhatsAppSurvey: {
      const campaign: WhatsAppSurveyCampaignDTO = {
        ...baseCampaign,
        template: BackendCampaignTemplate.WhatsAppSurvey,
        variables: {
          defaultSenderId: item.variables.defaultSenderId,
          parts: item.variables.parts.map(ensureDefaultAnswersWhatsApp),
          senderId: item.variables.senderId || '',
          senderIdWhatsapp: item.variables.senderIdWhatsapp || '',
        },
      }
      return campaign
    }

    default:
      return cannot(item)
  }
}
