import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_EXPORT_ORG_REPORT, EXPORT_ORG_REPORT_PENDING } from '@/app/module/reports/definitions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { captureError } from '@/app/service/util/error'
import { esApi } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'
import React from 'react'
import { useSelector } from 'react-redux'
import { OrgReportFilter, OrgReportFunction, OrgReportGrouping } from '@/app/module/reports/types'

export const useExportOrgReport = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [createReport, createReportRes] = esApi.endpoints.createOrgReport.useMutation()

  const exportOrgReport = React.useCallback(
    async (grouping: OrgReportGrouping, filter?: OrgReportFilter) => {
      try {
        const res = await createReport({
          orgId: String(orgId),
          body: {
            function: OrgReportFunction.Spend,
            orgreport: {
              grouping,
              filter: {
                ...filter,
                campaignIds: filter?.campaignIds || undefined,
              },
            },
          },
        }).unwrap()
        dispatch(
          createNotification({
            'export-org-report-pending': {
              type: 'success',
              message: EXPORT_ORG_REPORT_PENDING,
            },
          }),
        )
        return res
      } catch (error) {
        captureError(error)
        dispatch(
          createNotification({
            'export-org-report-error': {
              type: 'error',
              message: ERROR_EXPORT_ORG_REPORT,
            },
          }),
        )
        return undefined
      }
    },
    [orgId, createReport, dispatch],
  )

  return [exportOrgReport, createReportRes] as const
}
