import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import metadata from 'libphonenumber-js/metadata.full.json'
import { once, pathOr } from 'ramda'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { parsePhoneNumber } from '@/app/service/util'
import IconText from '@/app/component/layout/icon-text'
import CountryFlag from '@/app/component/atom/flag'
import FormControl from './form-control'

const CountrySelect = styled(Select<string>)({
  position: 'absolute',
  height: '30px',
  zIndex: '1',
  width: '2.75rem',
  left: '0',
})

const StyledInput = styled(TextField)({
  width: '100%',
  paddingLeft: '2.75rem',
})

const getPrefix = (value: string) => pathOr('', ['countries', value, 0], metadata)

const getCountryItems = once((countries: Array<{ label: string; value: string }>) =>
  countries.map(({ label, value }, index) => (
    <MenuItem key={index} value={value}>
      <IconText>
        <CountryFlag code={value} />
        <Typography>{label}</Typography>
      </IconText>
    </MenuItem>
  )),
)

const getStateProps = ({ value, defaultCountry }: Pick<PhoneProps, 'value' | 'defaultCountry'>) => {
  const { country, phone } = parsePhoneNumber(value)
  return {
    country: country || defaultCountry || '',
    phone: phone || getPrefix(defaultCountry || ''),
  }
}

type PhoneProps = {
  'data-testid'?: string
  name: string
  label?: string
  value: string
  defaultCountry?: string
  countries: Array<{ label: string; value: string }>
  editable?: boolean
  error?: string
  onChange: (data: { name: string; value: string }) => void
  onBlur?: (data: { name: string; value: string }) => void
  onOpen?: () => void
  onClose?: () => void
}

function Phone({
  'data-testid': dataTestId,
  name,
  label,
  value,
  defaultCountry,
  countries = [],
  editable = true,
  error = '',
  onChange = () => {},
  onBlur = () => {},
  onOpen = () => {},
  onClose = () => {},
}: PhoneProps) {
  const [isCountrySelectOpen, setCountrySelectionOpen] = useState(false)
  const [localCountry, setLocalCountry] = useState(getStateProps({ value, defaultCountry }).country)

  const { country, phone } = getStateProps({ value, defaultCountry: localCountry })

  return (
    <FormControl error={error}>
      <StyledInput
        variant="standard"
        id="phone-number-input"
        type="tel"
        name={name}
        label={label}
        disabled={!editable}
        error={!!error}
        value={phone}
        onChange={(e) => {
          e.persist?.()
          onChange({ name, value: e.target.value })
        }}
        onBlur={(e) => {
          e.persist?.()
          onBlur({ name, value: e.target.value })
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">+</InputAdornment>,
        }}
        InputLabelProps={{
          shrink: !!phone,
        }}
        inputProps={{
          'data-testid': dataTestId,
        }}
        sx={{
          '& label': {
            transform: 'translate(64px, 20px) scale(1)',
          },
          '& label.MuiInputLabel-shrink': {
            transform: 'translate(0, 1.5px) scale(0.75)',
            transformOrigin: 'top left',
          },
        }}
      />
      <CountrySelect
        variant="standard"
        value={country}
        style={{ marginTop: label ? '15px' : '2px' }}
        open={isCountrySelectOpen}
        onOpen={() => {
          setCountrySelectionOpen(true)
          onOpen()
        }}
        onClose={() => {
          setCountrySelectionOpen(false)
          onClose()
        }}
        onChange={(e) => {
          const prefix = getPrefix(e.target.value)
          setLocalCountry(e.target.value)
          onChange({
            name,
            value: prefix,
          })
        }}
        onBlur={() => {
          onBlur({
            name,
            value: phone,
          })
        }}
        renderValue={(selected) =>
          selected ? (
            <div>
              <CountryFlag code={selected} />
            </div>
          ) : (
            ''
          )
        }
        input={<Input name={`countries-${name}`} id={`select-countries-${name}`} readOnly={!editable} />}
      >
        {getCountryItems(countries)}
      </CountrySelect>
    </FormControl>
  )
}

export default Phone
