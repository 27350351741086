import { Box, List, Typography } from '@mui/material'
import { indexBy } from 'ramda'
import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { reduceIndexed } from '@/app/module/campaigns/helpers'
import { formatMessageLabel, getMessagePlaceholderLabel } from '@/app/module/campaigns/label-helpers'
import { Part } from '@/app/module/campaigns/types'
import { Action } from '@/app/module/campaigns/types/schemas/actions/all-actions'
import { isPartQuestion, getPartFamily } from '@/app/module/campaigns/utils/part-content'
import CampaignNavigatorItem, { JumpInfo } from './campaign-navigator-item'
import { getActions } from './item/steps/content/types/multimessage/content'

type Props = {
  activePart: string
  canReorder: boolean
  parts: Part[]

  onPartClick: (partId: string) => void
}

const CampaignNavigator: React.FC<Props> = ({ activePart, canReorder = true, parts, onPartClick }) => {
  const jumpsInfo = React.useMemo(() => toJumpsInfo(parts), [parts])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography color="primary" sx={{ padding: (theme) => theme.spacing(0, 1) }} variant="subtitle1">
        Navigator
      </Typography>
      {parts.length === 0 && (
        <Box sx={{ p: 1 }}>
          <Typography>No content yet. Click on the "Content" tab on the left to get started.</Typography>
        </Box>
      )}
      <Droppable droppableId="campaign-drawer-droppable">
        {(provided) => (
          <List
            id="campaign-navigator"
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              flex: 1,
            }}
          >
            {parts.map((part: Part, index) => (
              <CampaignNavigatorItem
                active={part.id === activePart}
                canReorder={canReorder}
                dragDisabled={parts.length < 2}
                index={index}
                key={part.id}
                jumps={jumpsInfo[part.id]}
                part={part}
                onClick={() => onPartClick(part.id)}
              />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </Box>
  )
}

/**
 * get jump info from action and add it to jumpsInfo array
 */
export const getJumpInfoFromAction = (
  currentPartId: string,
  jumpsInfo: JumpInfo[],
  action: Action,
  partsIndexMap: Record<string, number>,
  partsMap: Record<string, Part>,
) => {
  if ('jump' in action) {
    const toId = action.jump.nextPart
    const toIndex = partsIndexMap[toId]
    const toPart = partsMap[toId]
    if (toPart && typeof toIndex === 'number') {
      const hasQuestion = isPartQuestion(toPart)
      const family = getPartFamily(toPart)
      const { label: placeholderLabel } = getMessagePlaceholderLabel(family, hasQuestion)

      jumpsInfo.push({
        id: toId,
        label: formatMessageLabel(toIndex, toPart.label || placeholderLabel),
        valid: partsIndexMap[currentPartId] < toIndex,
      })
    }
  }
}

/**
 * collect jump infos from all question parts and return a map of partId -> JumpInfo[]
 */
export const toJumpsInfo = (parts: Part[]) => {
  const partsMap = indexBy((part) => part.id, parts) as Record<string, Part>
  const partsIndexMap = reduceIndexed(
    (acc, part, index: number) => ({
      ...acc,
      [part.id]: index,
    }),
    {} as Record<string, number>,
    parts,
  )

  return parts.reduce<Record<string, JumpInfo[]>>((acc, part) => {
    if (!isPartQuestion(part)) {
      return acc
    }
    if (!acc[part.id]) {
      acc[part.id] = []
    }

    const actions = getActions(part)

    actions.forEach((action) => {
      getJumpInfoFromAction(part.id, acc[part.id], action, partsIndexMap, partsMap)
    })

    return acc
  }, {})
}

export default CampaignNavigator
