import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import { pathOr } from 'ramda'
import React from 'react'
import { Select } from '@/app/component/atom/form'
import { DEFAULT_SENDER_ID_NAME, DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import { SenderIdsForDripType } from '@/app/module/campaigns/helpers'
import { SMSBlastCampaignItem, SenderIdOption } from '@/app/module/campaigns/types'

type Messages = {
  senderIds: SenderIdOption[]
  senderIdsForDrip: SenderIdsForDripType
  whatsappSenderIds: SenderIdOption[]
}

type Props = {
  item: SMSBlastCampaignItem
  messages: Messages
  onChange: (item: Partial<SMSBlastCampaignItem>) => void
}

const PhoneNumberSMSBlast: React.FC<Props> = ({ item, messages, onChange }) => {
  const senderIDInfo =
    "Contact support to register your own branded sender ID so SMS come from your organization's name"

  const senderIds = pathOr([], ['senderIds'], messages)
  const senderIdValues = [
    {
      label: DEFAULT_SENDER_ID_NAME,
      value: DEFAULT_SENDER_ID_VALUE,
      type: 'sender',
    },
    ...senderIds,
  ]

  const handleChange = ({ value }: { value: string }) => {
    const isDefaultSenderId = value === DEFAULT_SENDER_ID_VALUE

    onChange({
      ...item,
      variables: {
        ...item.variables,
        defaultSenderId: isDefaultSenderId,
        senderId: isDefaultSenderId ? '' : value,
      },
    })
  }

  const defaultSenderId = pathOr(false, ['variables', 'defaultSenderId'], item)
  const senderId = React.useMemo(() => {
    if (defaultSenderId) {
      return DEFAULT_SENDER_ID_VALUE
    }
    return pathOr('', ['variables', 'senderId'], item)
  }, [defaultSenderId, item])

  return (
    <Card style={{ marginBottom: '2rem' }}>
      <CardHeader title="Sender ID" style={{ padding: '12px 24px' }} />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom={true}>
              Select what should appear on Contacts phones as whom the SMS Message is from (e.g., MyOrg or 12125551212)
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography variant="body1" gutterBottom={false} style={{ padding: '6px 10 7px' }}>
                  Sender ID
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Select
                  name="senderid"
                  id="campaign-item-senderid"
                  data-testid="campaign-item-senderid"
                  editable
                  error={senderId ? '' : 'You must select a sender ID.'}
                  info={!senderIds.length ? senderIDInfo : undefined}
                  label={undefined}
                  value={senderId}
                  values={senderIdValues}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PhoneNumberSMSBlast
