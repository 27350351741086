import { CampaignTemplateType } from '@/app/module/campaigns/types'

export function isValidPart(campaignType: CampaignTemplateType, messageType: string) {
  switch (campaignType) {
    case CampaignTemplateType.CATI: {
      return ['cati-m', 'cati-q', 'action-q'].includes(messageType)
    }
    case CampaignTemplateType.Drip: {
      return [
        'sms-m',
        'sms-q',
        'voice-m',
        'voice-q',
        'whatsapp-m',
        'whatsapp-q',
        'api-q',
        'action-q',
        'topup-q',
      ].includes(messageType)
    }
    case CampaignTemplateType.Reminder: {
      return ['sms-m', 'sms-q', 'action-q'].includes(messageType)
    }
    case CampaignTemplateType.SMSBlast: {
      return ['sms-m'].includes(messageType)
    }
    case CampaignTemplateType.SMSSurvey: {
      return ['sms-m', 'sms-q', 'action-q', 'api-q'].includes(messageType)
    }
    case CampaignTemplateType.VoiceBlast: {
      return ['voice-m', 'voice-q', 'action-q'].includes(messageType)
    }
    case CampaignTemplateType.VoiceSurvey: {
      return ['voice-m', 'voice-q', 'action-q'].includes(messageType)
    }
    case CampaignTemplateType.WhatsAppSurvey: {
      return ['whatsapp-m', 'whatsapp-q', 'action-q', 'api-q'].includes(messageType)
    }
    default: {
      return false
    }
  }
}
