import { useDebouncedState, useDeepState } from '@/app/helpers'
import { CAMPAIGN_CHANGE_DELAY } from '@/app/module/campaigns/definitions'

export const useCampaignDebouncedState = <LocalState>(
  initialState: LocalState,
  onChange: (value: LocalState) => void,
) => {
  const [state, setState] = useDeepState<LocalState>(initialState)
  useDebouncedState(state, onChange, CAMPAIGN_CHANGE_DELAY)

  return [state, setState] as const
}
