import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { append, find, includes, innerJoin, map, pipe, propEq, without } from 'ramda'
import React from 'react'
import { withStyles } from 'tss-react/mui'
import CustomTooltip from '@/app/component/atom/tooltip'
import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { displayMap } from '@/app/module/campaigns/definitions'
import ActionPreview from '@/app/module/campaigns/component/item/steps/content/question/action/preview'
import { SnippetType, ActionSnippetType, PersonalizationType } from '@/app/module/campaigns/types'
import { SelectOption } from '@/app/types'
import { GetSnippets, Snippets } from '@/app/module/campaigns/component/item/steps/content/part-config-props'

type Props = {
  orgId: number
  personalizationList: PersonalizationType
  snippets: Snippets
  token: string
  valuesList: SelectOption<string>[]

  getSnippets: GetSnippets
  onClose: () => void
  onSave: (snippets: ActionSnippetType['snippet'][]) => void
}

const getSelectedSnippets = (snippets: Snippets, selected: number[]) =>
  pipe(
    innerJoin((snippet: SnippetType, id: number) => snippet.id === id, snippets.data),
    map((snippet: SnippetType) => snippet.snippet),
  )(selected)

const CampaignContentQuestionDialogAddFromLibrary = (props: Props) => {
  const { onClose, getSnippets, orgId, token, snippets, personalizationList, onSave, valuesList } = props

  const [selected, setSelected] = React.useState<number[]>([])
  React.useEffect(() => {
    getSnippets({
      orgId,
      token,
    })
  }, [orgId, token, getSnippets])

  const actionSnippets = React.useMemo(() => {
    return snippets?.data?.filter((snippet: SnippetType): snippet is ActionSnippetType => snippet.category === 'action')
  }, [snippets])

  return (
    <CustomDialog open={true} onClose={onClose}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>library_add</Icon>
            <Typography variant="h6">Add from library</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '24px' }}>
        {actionSnippets.length === 0 && (
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            You didn't add anything to your library yet. Copy any action to reuse them anywhere in the application.
          </Typography>
        )}
        {actionSnippets.map((snippet) => {
          const validAction = find(propEq('value', snippet.snippet.type), valuesList)
          return (
            <div
              key={snippet.id}
              style={{
                width: '540px',
                display: 'flex',
              }}
            >
              <CustomTooltip title={validAction ? '' : 'This action is not valid for this question type.'}>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      disabled={!validAction}
                      checked={includes(snippet.id, selected)}
                      onChange={() => {
                        if (includes(snippet.id, selected)) {
                          setSelected(without([snippet.id], selected))
                        } else {
                          setSelected(append(snippet.id, selected))
                        }
                      }}
                    />
                  }
                  label={
                    <ActionPreview
                      action={snippet.snippet}
                      personalizationList={personalizationList}
                      displayMap={displayMap}
                    />
                  }
                  style={{ display: 'flex', flex: 1 }}
                />
              </CustomTooltip>
            </div>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={selected.length === 0}
          onClick={() => onSave(getSelectedSnippets(snippets, selected))}
        >
          Insert
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}

const StyledFormControlLabel = withStyles(FormControlLabel, () => ({
  label: {
    flex: 1,
  },
}))

export default CampaignContentQuestionDialogAddFromLibrary
