import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import CardLayout from '@/app/component/layout/card'
import ErrorLayout from '@/app/component/layout/error'
import { LoadingError } from '@/app/types'

const Container = styled('div')({
  position: 'relative',
})

const ErrorContainer = styled('div')({
  // 1px here means 71px in the layout <3 css
  paddingBottom: '1px',
})

const Content = styled('div')(({ isLoading }: { isLoading: boolean }) => ({
  display: isLoading ? 'block' : 'initial',
}))

const Overlay = styled('div')(({ isLoading }: { isLoading: boolean }) => ({
  display: isLoading ? 'flex' : 'none',
  justifyContent: 'center',
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100vw',
  maxWidth: '100%',
  height: '100%',
  paddingTop: '150px',
  backgroundColor: 'rgba(255,255,255,0.65)',
  zIndex: '999',
}))

type LoadingProps = {
  isLoading: boolean
  error?: LoadingError
  children: React.ReactNode
  style?: React.CSSProperties
  testId?: string
}

function Loading({ isLoading, error, children, style = {}, testId }: LoadingProps) {
  if (error?.message) {
    return (
      <ErrorContainer>
        <CardLayout>
          <ErrorLayout status={error.status}>
            <Typography>{error.message}</Typography>
          </ErrorLayout>
        </CardLayout>
      </ErrorContainer>
    )
  }

  return (
    <Container style={style}>
      <Content isLoading={isLoading}>{children}</Content>
      <Overlay isLoading={isLoading}>
        <CircularProgress color="primary" data-testid={testId} />
      </Overlay>
    </Container>
  )
}

export default Loading
