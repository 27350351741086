import { cannot } from '@/app/helpers'
import { ContentAudio, PartTypes, VoiceMessagePart, VoiceQuestionPart } from '@/app/module/campaigns/types'

export const getVoicePartContent = (part: VoiceMessagePart | VoiceQuestionPart): ContentAudio => {
  if (PartTypes.VoiceMessage in part) {
    return part.voiceMessage.audio
  }
  if (PartTypes.VoiceQuestion in part) {
    return part.voiceQuestion.audio
  }
  return cannot(part)
}

export const getVoicePartTranslation = (
  part: VoiceMessagePart | VoiceQuestionPart,
  language: string,
): ContentAudio | undefined => {
  const content = getVoicePartContent(part)
  return content.translations?.[language]
}
