import Typography from '@mui/material/Typography'
import { PureComponent } from 'react'

type Props = {
  current: string
}

export default class AudioCounter extends PureComponent<Props> {
  render() {
    const { current } = this.props

    return (
      <Typography
        style={{
          position: 'absolute',
          bottom: '8px',
          right: '24px',
          fontSize: '1.25rem',
        }}
      >
        {current}
      </Typography>
    )
  }
}
