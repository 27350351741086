import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { styled } from '@mui/material/styles'
import { pathOr } from 'ramda'
import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import CopyBox from '@/app/component/atom/copy-box'
import Loading from '@/app/component/guard/loading'
import PageTitle from '@/app/component/layout/page-title'
import createHydrated from '@/app/component/wrapper/hydratable'
import { getCampaignType } from '@/app/module/campaigns/helpers'
import SubscriptionTab from '@/app/module/campaigns/component/item/manage/subscriptions'
import { useOrgSwitchGuard } from '@/app/module/campaigns/utils/campaign'
import AnalyticsTab from './analyticsTab'
import OverviewTab from './overviewTab'

const StyledStep = styled(Step)({
  '& > *': {
    cursor: 'pointer !important',
  },
})

type StepComponentType = React.ComponentType<SummaryProps>
type StepType = {
  title: string
  TabComponent: StepComponentType
}

const STEPS: StepType[] = [
  {
    title: 'Overview',
    TabComponent: OverviewTab,
  },
  {
    title: 'Analytics',
    TabComponent: AnalyticsTab,
  },
  {
    title: 'Subscriptions',
    TabComponent: SubscriptionTab,
  },
]

const ICONSMAP = ['assessment', 'pie_chart', 'people_alt']

type StepIconComponentProps = {
  step: number
  active: boolean
}

const StepIconComponent = ({ step, active }: StepIconComponentProps) => {
  return (
    <Icon
      color={active ? 'primary' : 'disabled'}
      style={{
        fontSize: '1.8rem',
      }}
    >
      {ICONSMAP[step]}
    </Icon>
  )
}

const STEP_MAP = ['overview', 'analytics', 'subscriptions']

type SummaryProps = {
  error?: {
    status?: number
    message: string
  }
  step: string
  loading: boolean
  itemId: string
  item: any
  [key: string]: any
}

function Summary({ error, step, loading, itemId, item, ...rest }: SummaryProps) {
  const history = useHistory()
  const location = useLocation()
  const [stateStep, setStateStep] = React.useState(Math.max(STEP_MAP.indexOf(step), 0))

  useOrgSwitchGuard(rest.orgId)

  const goToStep = React.useCallback(
    (index: number) => {
      if (itemId) {
        history.push(`/campaigns/summary/${itemId}/${STEP_MAP[index]}`)
      }
      setStateStep(index)
    },
    [itemId, history, setStateStep],
  )

  React.useEffect(() => {
    if (itemId && !location.pathname.includes(`/${itemId}/`)) {
      history.push(`/campaigns/summary/${itemId}/${STEP_MAP[0]}`)
    }
  }, [itemId, history, location])

  const campaignType = getCampaignType(item)
  const campaignName = pathOr('', ['name'], item)
  const { TabComponent } = STEPS[stateStep]

  return (
    <Loading isLoading={loading} error={error}>
      <div
        style={{
          maxWidth: '1120px',
          width: '100%',
          margin: 'auto',
          padding: '0 1rem',
        }}
      >
        {itemId && (
          <CopyBox
            style={{ float: 'right', marginTop: '-20px', paddingLeft: '15px' }}
            text={itemId}
            title="Campaign ID"
          />
        )}
        <div style={{ paddingBottom: '1px' }}>
          <PageTitle>{`View ${campaignType} "${campaignName}"`}</PageTitle>
        </div>
        <Card style={{ margin: '2rem 0' }}>
          <CardContent>
            <Stepper nonLinear activeStep={stateStep}>
              {STEPS.map(({ title }, i) => {
                return (
                  <StyledStep
                    key={i}
                    onClick={() => {
                      goToStep(i)
                    }}
                  >
                    <StepLabel icon={<StepIconComponent step={i} active={i === stateStep} />}>{title}</StepLabel>
                  </StyledStep>
                )
              })}
            </Stepper>
          </CardContent>
        </Card>
        <TabComponent error={error} step={step} loading={loading} item={item} itemId={itemId} {...rest} />
        <div style={{ display: 'flex', padding: '12px 0 100px' }}></div>
      </div>
    </Loading>
  )
}

export default createHydrated(Summary)
