import Typography from '@mui/material/Typography'
import { path } from 'ramda'
import React from 'react'
import Loading from '@/app/component/guard/loading'
import createHydrated from '@/app/component/wrapper/hydratable'
import { CampaignItem } from '@/app/module/campaigns/types'
import ReminderCampaignClass from '@/app/module/campaigns/models/classes/reminder-campaign.class'
import { useOrgSwitchGuard } from '@/app/module/campaigns/utils/campaign'
import Subscriptions from './subscriptions'

type Props = {
  loading: boolean
  orgId: number
  item: CampaignItem | ReminderCampaignClass

  clearItem: () => void
}

const CampaignManage: React.FC<Props> = ({ loading, ...props }) => {
  const { item, clearItem } = props
  const itemTitle = path(['name'], item) ? `for "${item.name}"` : ''

  useOrgSwitchGuard(props.orgId)

  React.useEffect(
    () => () => {
      clearItem()
    },
    [clearItem],
  )

  return (
    <Loading isLoading={loading}>
      <Typography sx={{ padding: 2 }} variant="h6">
        Manage subscriptions {itemTitle}
      </Typography>
      <Subscriptions {...props} />
    </Loading>
  )
}

export default React.memo(createHydrated(CampaignManage))
