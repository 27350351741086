import { styled } from '@mui/material/styles'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { compose, concat, find, indexBy, keys, map, propEq, propOr, reduce } from 'ramda'
import { Component, KeyboardEvent, MouseEvent, ReactNode } from 'react'
import InputHighlight from '@/app/component/atom/form/input-highlight'
import InputSMS from '@/app/component/atom/form/input-sms'
import PzMenu from '@/app/component/atom/pz-menu'
import Tooltip from '@/app/component/atom/tooltip'
import IconText from '@/app/component/layout/icon-text'
import { BaseLanguageType } from '@/app/module/campaigns/helpers'
import { transformFromPersonalization, transformToPersonalization } from '@/app/module/campaigns/pz-helpers'
import { decodeText } from '@/app/module/campaigns/store/selectors'
import { PersonalizationType, SinglePersonalizationType } from '@/app/module/campaigns/types'
import { generatePattern } from '@/app/module/utils/personalization'
import PzPill from '@/app/component/atom/pz-pill'

const InfoText = styled(IconText)({
  margin: '12px 0',
  color: 'rgba(0, 0, 0, 0.54)',
})

const PersonalizationMenuButton = styled(Button)({
  minWidth: '0',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
})

type Classes = {
  infoText?: string
  personalizationMenu?: string
  pzHighlight?: string
  pzHighlightMatch?: string
  pzHighlightWrap?: string
}

type Props = {
  additionalButtons?: ReactNode
  autoFocus?: boolean
  classes?: Classes
  editable?: boolean
  error?: string
  hideInfo?: boolean
  label?: string
  message: string
  personalizationList: PersonalizationType
  whatsapp?: boolean
  voices?: BaseLanguageType[]

  onBlur?: (value: any) => void
  onChange: (value: any) => void
}

type State = {
  pzMenuAnchor: Element | null
  selection: number[]
  showEmoji: Element | null
  textDirection: string
  regExpMatcher: string
}

type Chunk = {
  index: number
  startIndex: number
  value: string
}

type Selection = {
  start: number
  end: number
}

export default class Message extends Component<Props, State> {
  focusedOnCreate = false

  messageInput?: HTMLInputElement | HTMLTextAreaElement

  pzMapByLabel: Record<string, SinglePersonalizationType> = {}

  pzMapByValue: Record<string, SinglePersonalizationType> = {}

  state = {
    pzMenuAnchor: null,
    selection: [0, 0],
    textDirection: 'ltr',
    showEmoji: null,
    regExpMatcher: '',
  }

  KEYCODEMAP: Record<
    number,
    (args: {
      e: KeyboardEvent
      chunks: Chunk[]
      isMatch: (value: string) => boolean
      getSelection: () => Selection
      setSelection: (selection: Selection) => void
    }) => void
  > = {
    // backspace (delete on mac)
    8: ({ e, chunks, isMatch, getSelection, setSelection }) => {
      const { start } = getSelection()
      const prevChunk = compose(
        (index: number) => chunks[index - 1],
        propOr(-1, 'index'),
        find(propEq('startIndex', start)),
      )(chunks)
      if (prevChunk && isMatch(prevChunk.value)) {
        e.preventDefault()
        const newPos = prevChunk.startIndex
        const newVal = chunks
          .filter(({ index }: Chunk) => index !== prevChunk.index)
          .map(({ value }: Chunk) => value)
          .join('')
        this.changeHandler(newVal, [newPos, newPos])
        setSelection({ start: newPos, end: newPos })
      }
    },
    // left arrow
    37: ({ e, chunks, isMatch, getSelection, setSelection }) => {
      const { start, end } = getSelection()
      const prevChunk = compose(
        (index: number) => chunks[index - 1],
        propOr(-1, 'index'),
        find(propEq('startIndex', start)),
      )(chunks)
      if (prevChunk && isMatch(prevChunk.value)) {
        e.preventDefault()
        const newPos = prevChunk.startIndex
        setSelection({
          start: newPos,
          end: start === end ? newPos : end,
        })
      }
    },
    // right arrow
    39: ({ e, chunks, isMatch, getSelection, setSelection }) => {
      const { start, end } = getSelection()
      const currentChunk = chunks.find(({ startIndex }) => startIndex === end)
      if (currentChunk && isMatch(currentChunk.value)) {
        e.preventDefault()
        const newPos = end + currentChunk.value.length
        setSelection({
          start: start === end ? newPos : start,
          end: newPos,
        })
      }
    },
  }

  constructor(props: Props) {
    super(props)
    this.focusedOnCreate = false

    this.updatePZMAP(false)
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { additionalButtons, editable, label, error, message } = this.props

    return (
      additionalButtons !== nextProps.additionalButtons ||
      editable !== nextProps.editable ||
      label !== nextProps.label ||
      message !== nextProps.message ||
      error !== nextProps.error ||
      this.state.pzMenuAnchor !== nextState.pzMenuAnchor ||
      this.state.showEmoji !== nextState.showEmoji ||
      this.state.selection[0] !== nextState.selection[0] ||
      this.state.selection[1] !== nextState.selection[1] ||
      this.state.textDirection !== nextState.textDirection ||
      this.props.personalizationList !== nextProps.personalizationList
    )
  }

  componentDidMount() {
    this.setState({
      textDirection: checkTextDirection(this.props.message),
    })

    this.focusInput()
  }

  componentDidUpdate(prevProps: Props) {
    const { message } = this.props

    // only when message gets modified from the outside - adding / removing pz tags
    if (prevProps.message !== message && document.activeElement !== this.messageInput) {
      // hack to get around menu closing and setting focus on modal paper by default
      setTimeout(() => {
        // only execute if message input is still not focused
        if (!!this.messageInput && document.activeElement !== this.messageInput) {
          const [start, end] = this.state.selection
          this.messageInput.selectionStart = start
          this.messageInput.selectionEnd = end
          this.messageInput.focus()
        }
      }, 400)
    }

    // only when message gets modified from the outside (props changed by parent) and message input is focused
    if (prevProps.message !== message && document.activeElement === this.messageInput) {
      const [start, end] = this.state.selection
      this.messageInput.setSelectionRange(start, end)
    }

    this.updatePZMAP()
  }

  focusInput = () => {
    if (this.props.autoFocus && this.messageInput && !this.focusedOnCreate) {
      this.focusedOnCreate = true
      setTimeout(() => {
        if (!this.messageInput) {
          return
        }
        this.messageInput.focus({
          preventScroll: true,
        })
        this.messageInput.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }, 0) // set timeout 0 to push focus to the end of the event loop
    }
  }

  changeHandler = (pzMessage: any, newSelection?: number[]) => {
    const { editable = true, onChange, whatsapp } = this.props

    if (!editable) {
      return
    }

    this.setState({
      pzMenuAnchor: null,
      selection: newSelection || this.state.selection,
    })
    this.setState({
      textDirection: checkTextDirection(pzMessage),
    })

    const message = transformToPersonalization(pzMessage, this.pzMapByLabel, (match) => `{{${match}}}`)

    onChange({
      message: whatsapp ? message : decodeText(message),
    })
  }

  blurHandler = (message: any) => {
    const { onBlur = () => {} } = this.props

    onBlur({
      message,
    })
  }

  keyDownHandler = ({ e, chunks }: { e: KeyboardEvent; chunks: Chunk[] }) => {
    if (this.KEYCODEMAP[e.keyCode]) {
      this.KEYCODEMAP[e.keyCode]({
        e,
        chunks,
        isMatch: (value: string) => !!this.pzMapByLabel[value],
        getSelection: () => ({
          start: this.messageInput?.selectionStart || 0,
          end: this.messageInput?.selectionEnd || 0,
        }),
        setSelection: ({ start, end }) => {
          setTimeout(() => {
            if (!this.messageInput) {
              return
            }

            this.messageInput.setSelectionRange(start, end)
          }, 0)
        },
      })
    }
  }

  togglePzMenu = (e?: MouseEvent) => {
    if (!this.messageInput) {
      return
    }
    const currentTarget = e?.currentTarget || null
    this.setState({
      pzMenuAnchor: currentTarget,
      selection: [this.messageInput.selectionStart || 0, this.messageInput.selectionEnd || 0],
    })
  }

  toggleEmoji = (e?: MouseEvent) => {
    if (!this.messageInput) {
      return
    }
    const currentTarget = e?.currentTarget || null
    this.setState({
      showEmoji: currentTarget,
      selection: [this.messageInput.selectionStart || 0, this.messageInput.selectionEnd || 0],
    })
  }

  handleEmojiSelect = (message: string, selectedEmoji: any) => {
    const { selection } = this.state
    const newSelectionIndex = selection[0]
    const newMessage = message.slice(0, selection[0]) + selectedEmoji.native + message.slice(selection[1])
    this.changeHandler(newMessage, [newSelectionIndex, newSelectionIndex])
    this.setState({
      showEmoji: null,
    })
  }

  updatePZMAP = (canSetState = true) => {
    const { personalizationList = {} } = this.props
    const PZMAP = compose(
      map(({ label, value }) => ({
        label,
        value,
      })),
      reduce((acc: SinglePersonalizationType[], key) => concat(acc, personalizationList[key]), []),
      keys,
    )(personalizationList) as SinglePersonalizationType[]

    this.pzMapByValue = indexBy(({ value }) => `{{${value}}}`, PZMAP)
    this.pzMapByLabel = indexBy(({ label }) => `{{${label}}}`, PZMAP)
    if (canSetState) {
      this.setState({
        regExpMatcher: generatePattern(PZMAP),
      })
    } else {
      this.state.regExpMatcher = generatePattern(PZMAP)
    }
  }

  handlePzSelect = (message: string, item: SinglePersonalizationType) => {
    const startIndex = this.messageInput?.selectionStart || 0
    const endIndex = this.messageInput?.selectionEnd || 0

    const newSelectionIndex = startIndex + item.label.length + 4
    const newMessage = `${message.slice(0, startIndex)}{{${item.label}}}${message.slice(endIndex)}`
    this.changeHandler(newMessage, [newSelectionIndex, newSelectionIndex])
  }

  render() {
    const {
      additionalButtons,
      editable = true,
      hideInfo,
      message = '',
      label = 'Message',
      error = '',
      personalizationList = {},
      whatsapp = false,
    } = this.props
    const { regExpMatcher } = this.state

    // replace all pz tags with 20 char length placeholder for length determination
    const messageCountable = regExpMatcher ? message.replace(new RegExp(regExpMatcher, 'g'), pzPlaceholder) : message

    const hasPz = regExpMatcher && new RegExp(`(${regExpMatcher})`, 'g').test(message)

    const pzMessage = transformFromPersonalization(message, this.pzMapByValue, (match) => `{{${match}}}`)

    const handleChange = ({ value }: any) => {
      if (
        typeof this.messageInput?.selectionStart === 'number' &&
        typeof this.messageInput?.selectionEnd === 'number'
      ) {
        this.changeHandler(value, [this.messageInput.selectionStart, this.messageInput.selectionEnd])
      } else {
        this.changeHandler(value)
      }
    }

    return (
      <div>
        <div className="tour-sms">
          <InputSMS name="send" message={message} messageCountable={messageCountable} hideInfo={hideInfo || whatsapp}>
            <InputHighlight
              className="tour-sms-input"
              id="send-message-text"
              editable={editable}
              error={error}
              highlightPattern={regExpMatcher}
              label={label}
              name="send-message-text"
              multiline={true}
              inputRef={{
                current: this.messageInput,
              }}
              textDirection={this.state.textDirection}
              value={pzMessage}
              variant="standard"
              onChange={handleChange}
              onBlur={({ value }: any) => this.blurHandler(value)}
              onKeyDown={(e: KeyboardEvent, chunks: Chunk[]) => this.keyDownHandler({ e, chunks })}
              isMatch={(value: string) => !!this.pzMapByLabel[value]}
              getInputRef={(input: HTMLInputElement | HTMLTextAreaElement) => {
                this.messageInput = input
              }}
              HighlightComponent={({ match }) => <PzPill label={this.pzMapByLabel[match]?.label || ''} />}
            />
          </InputSMS>
          {hasPz && !whatsapp && !hideInfo && (
            <InfoText>
              <Icon>info</Icon>
              <div>
                <Typography variant="caption" color="inherit">
                  The character counter assumes a length of 20 for each personalized attribute.
                </Typography>
                <Typography variant="caption" color="inherit">
                  Billing will be based on the actual length of each message.
                </Typography>
              </div>
            </InfoText>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <PzMenu
            id="messages-send"
            color="primary"
            containerSx={{
              marginRight: '0.5rem',
            }}
            disabled={!editable}
            personalizationList={personalizationList}
            variant="outlined"
            onSelect={(item) => this.handlePzSelect(pzMessage, item)}
          />
          {whatsapp && (
            <>
              <div onClick={this.toggleEmoji} style={{ width: '40px', marginRight: '0.5rem' }}>
                <Tooltip title="Emojis">
                  <div>
                    <PersonalizationMenuButton
                      color="primary"
                      data-testid="emoji-menu-toggle"
                      variant="outlined"
                      disabled={!editable}
                    >
                      <Icon>insert_emoticon</Icon>
                    </PersonalizationMenuButton>
                  </div>
                </Tooltip>
              </div>
            </>
          )}
          {additionalButtons}
        </div>
        {this.state.showEmoji && editable && (
          <Popover
            id="messages-send-emoji-menu"
            open={true}
            anchorEl={this.state.showEmoji}
            onClose={() =>
              this.setState({
                showEmoji: null,
              })
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Picker
              data={data}
              showPreview={false}
              title=""
              onEmojiSelect={(emoji: any) => this.handleEmojiSelect(pzMessage, emoji)}
            />
          </Popover>
        )}
      </div>
    )
  }
}

// defaults to 20 characters to count for estimating price for field values
const pzPlaceholder = '                    '

const checkTextDirection = (text: string) => {
  const trimmedInput = text.replace(/\s/g, '')
  const arabicTest = /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g
  if (arabicTest.test(trimmedInput)) {
    return 'rtl'
  }
  return 'ltr'
}
