import { GlobalStyles } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import RecaptchaLayout from '@/app/component/layout/recaptcha-page'

type Props = PropsWithChildren<{
  disableScrolling?: boolean
  title?: string
}>

/**
 * This layout will hide the google recaptcha badge.
 */
const Layout: React.FC<Props> = ({ children, disableScrolling, title }) => (
  <RecaptchaLayout title={title} waitRecaptcha={false}>
    <GlobalStyles styles={globalStyles} />
    {disableScrolling && <GlobalStyles styles={noScrollStyles} />}
    {children}
  </RecaptchaLayout>
)

const globalStyles = {
  '.grecaptcha-badge': {
    display: 'none !important',
  },
  body: {
    overflow: 'auto !important',
  },
}

const noScrollStyles = {
  body: {
    overflow: 'hidden !important',
  },
}

export default Layout
