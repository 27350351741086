import Typography from '@mui/material/Typography'
import { values } from 'ramda'
import { Link } from 'react-router-dom'
import CardLayout from '../../component/layout/card'
import ErrorLayout from '../../component/layout/error'
import Layout from '../../component/layout/page'
import { PAGE } from '../../definitions'
import { AuthGuard, AuthHeader } from '../../module/auth'
import Notifications from '../../module/notifications'
import { Billing } from '../../module/payments'
import { TABS } from '../../module/payments/component'

export default function BillingPage({ name, routes, match, history }) {
  if (values(TABS).indexOf(match.params.tabName) === -1) {
    return (
      <Layout title="Error - engageSPARK">
        <AuthHeader currentPage={PAGE.ACCOUNT.BILLING.INDEX} />
        <CardLayout>
          <ErrorLayout status={404}>
            <Typography>This page does not exist.</Typography>
            <Typography>
              We can help find your way back <Link to="/">home</Link>
            </Typography>
          </ErrorLayout>
        </CardLayout>
      </Layout>
    )
  }

  return (
    <AuthGuard
      fallback={
        <Layout title="Billing - engageSPARK">
          <AuthHeader currentPage={PAGE.ACCOUNT.BILLING.INDEX} />
          <div style={{ padding: 24, textAlign: 'center' }}>
            <Typography variant="h6">
              You don't have permission to access billing information. Contact your organization's manager or owner to
              gain access.
            </Typography>
          </div>
          <Notifications />
        </Layout>
      }
      name={name}
      path={match.path}
      requiredRoles={['owner', 'manager', 'customer_service']}
      routes={routes}
      url={match.url}
    >
      <Layout title="Billing - engageSPARK">
        <AuthHeader currentPage={PAGE.ACCOUNT.BILLING.INDEX} />
        <Billing history={history} tabName={match.params.tabName} />
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}
