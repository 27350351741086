import { Icon, Link as MuiLink } from '@mui/material'
import Card from '@mui/material/Card'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { compose, pick, values } from 'ramda'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../../../component/guard/loading'
import ContentLayout from '../../../component/layout/content'
import ErrorLayout from '../../../component/layout/error'
import FormLayout from '../../../component/layout/form'
import IconText from '../../../component/layout/icon-text'
import PageTitle from '../../../component/layout/page-title'
import createHydrated from '../../../component/wrapper/hydratable'
import validate, { hasPhoneError, hasRequiredError } from '../../../service/validate'
import ApiKeysContainer from './api-keys.container'
import Security from './security'

export const TABS = {
  INFO: 'info',
  PREFERENCES: 'preferences',
  SECURITY: 'security',
  API_KEYS: 'api-tokens',
}

class Profile extends Component {
  state = {
    init: false,
    isPwrModalOpen: false,
  }

  tabNames = values(TABS)

  tabs = {
    [TABS.INFO]: {
      name: TABS.INFO,
      title: 'Info',
      Content: ({ props }) => (
        <FormLayout
          id="edit-profile"
          CustomElement={
            <IconText>
              <Icon>info</Icon>
              <Typography color="textSecondary">
                Your API key has been replaced by Personal Access Tokens (PAT)!
                <br />
                Why are PATs better? Because you can name them, they expire, and best of all: you can have multiple!{' '}
                <MuiLink component={Link} to="/account/profile/api-tokens">
                  Find them here.
                </MuiLink>{' '}
                Don't worry about your existing API keys—they have been upgraded to PATs and will keep working.
              </Typography>
            </IconText>
          }
          loading={props.loading}
          config={props.specs}
          headers={props.infoHeaders}
          values={{
            ...props.details,
          }}
          validation={{
            phone: validate(hasRequiredError, hasPhoneError),
          }}
          onSubmit={({ item }) =>
            props.saveUser({
              token: props.token,
              item: pick(['firstName', 'lastName', 'country', 'phone'], item),
            })
          }
        />
      ),
    },
    [TABS.PREFERENCES]: {
      name: TABS.PREFERENCES,
      title: 'Preferences',
      Content: ({ props }) => (
        <FormLayout
          id="edit-preferences"
          loading={props.loading}
          config={props.specs}
          headers={props.preferenceHeaders}
          values={{
            language: props.details.currentLanguage,
            timezone: props.timezone,
          }}
          onSubmit={() => {}}
        />
      ),
    },
    [TABS.SECURITY]: {
      name: TABS.SECURITY,
      title: 'Security',
      Content: ({ props }) => <Security {...props} />,
    },
    [TABS.API_KEYS]: {
      name: TABS.API_KEYS,
      title: 'API Tokens',
      Content: () => <ApiKeysContainer />,
    },
  }

  render() {
    const { classes = {}, loading, error, tabName, history } = this.props

    const { Content } = this.tabs[tabName] || {}

    return (
      <Loading isLoading={loading} error={error}>
        <div
          style={{
            paddingBottom: '1px',
          }}
        >
          <ContentLayout>
            <PageTitle>Profile</PageTitle>
            <Card className={classes.card} style={{ marginBottom: '90px' }}>
              <Toolbar style={{ backgroundColor: '#f5f5f5', minHeight: '0' }}>
                <Tabs
                  value={tabName}
                  indicatorColor="primary"
                  onChange={(e, tab) => {
                    history.push(`/account/profile/${tab}`)
                  }}
                >
                  {this.tabNames.map((name) => (
                    <Tab key={name} id={`profile-tab-${name}`} value={name} label={this.tabs[name].title} />
                  ))}
                </Tabs>
              </Toolbar>
              {this.tabNames.indexOf(tabName) > -1 && (
                <div id={`profile-content-${tabName}`}>
                  <Content props={this.props} state={this.state} />
                </div>
              )}
              {this.tabNames.indexOf(this.props.tabName) === -1 && (
                <div id="profile-content-error">
                  <ErrorLayout status={404}>
                    <Typography>Page not found</Typography>
                  </ErrorLayout>
                </div>
              )}
            </Card>
          </ContentLayout>
        </div>
      </Loading>
    )
  }
}

export default compose(createHydrated)(Profile)
