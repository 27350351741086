import Layout from '@/app/component/layout/page'
import { AuthGuard, AuthHeader, VerifyRecoveryCodeForm } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'

export default function VerifyRecoveryCodePage(props: any) {
  const { name, match, history } = props
  return (
    <AuthGuard url={match.url} path={match.path}>
      <Layout title="Two-factor recovery - engageSPARK">
        <AuthHeader currentPage={name} />
        <VerifyRecoveryCodeForm history={history} />
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}
