import TimezonePicker from '@/app/component/atom/timezone-picker'
import { CountryTimezone, TimezoneWithCountryCodeType } from '@/app/types'
import { styled } from '@mui/material'
import React from 'react'

type Props = {
  countriesTimezones: CountryTimezone[]
  countryCode: string
  t0timezone: string
  timezones: TimezoneWithCountryCodeType
  onChange: (t0timezone: string) => void
}

const EventTimezonePicker: React.FC<Props> = ({ t0timezone, ...props }) => {
  return (
    <Container>
      <TimezonePicker name="t0" timezone={t0timezone} {...props} />
    </Container>
  )
}

const Container = styled('div')(() => ({
  maxWidth: 400,
}))

export default React.memo(EventTimezonePicker)
