import { DialogContent, DialogTitle, Icon, IconButton } from '@mui/material'
import { isEmpty } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import Loader from '@/app/component/atom/loader'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import validate from '@/app/service/validate'
import { FormSpecs, Organization } from '@/app/types'
import { selectToken } from '@/app/module/auth/store/selectors'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { getOrganization, saveOrganization } from '@/app/module/user/store/actions'
import {
  selectNotificationsHeaders,
  selectNotificationsSpecs,
  selectOrganization,
  selectSelectedOrgId,
} from '@/app/module/user/store/selectors'
import SpendNotifications from './spend-notifications'

type Props = {
  open: boolean
  onClose: () => void
}

const SpendNotificationsContainer: React.FC<Props> = ({ open, onClose }) => {
  const dispatch = useAppDispatch()

  const headers = useSelector(selectNotificationsHeaders)
  const loading = useSelector(({ user }) => user.details.loading || user.organization.loading)
  const orgId = useSelector(selectSelectedOrgId)
  const organization = useSelector(selectOrganization)
  const specs = useSelector(selectNotificationsSpecs)
  const token = useSelector(selectToken)

  const handleSubmit = React.useCallback(
    async (payload: { item: Organization }) => {
      dispatch(
        saveOrganization({
          token,
          item: payload.item,
        }),
      )
    },
    [token, dispatch],
  )

  React.useEffect(() => {
    // only fetch organization if it's not already fetched
    if (isEmpty(organization)) {
      dispatch(getOrganization({ token, orgId }))
    }
  }, [orgId, organization, token, dispatch])

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle id="limits-title">
        <span>Notifications and Spend Limits</span>
        <IconButton style={{ float: 'right' }} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SpendNotifications
          headers={headers}
          loading={loading}
          organization={organization}
          specs={specs as FormSpecs}
          validation={validation}
          onSubmit={handleSubmit}
        />
        {loading && <Loader />}
      </DialogContent>
    </CustomDialog>
  )
}

export const hasNumberError = (value: string) => {
  if (!value) {
    return ''
  }
  const number = Number(value)
  if (Number.isNaN(number)) {
    return 'Must be a number'
  }
  if (number < 0) {
    return 'Must be a non-negative number'
  }
  return ''
}

const validation = {
  balanceNotification: validate(hasNumberError),
  maxDailySpend: validate(hasNumberError),
  spendNotification: validate(hasNumberError),
}

export default SpendNotificationsContainer
