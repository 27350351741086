import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import { compose, find, pathOr, propEq } from 'ramda'
import React from 'react'
import { CheckboxProps, DateTimeRangeProps, FilterOption, RadioProps } from './types'

const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  flex: '1',
  alignItems: 'center',
})

const StyledChip = styled(Chip)({
  margin: '0.25rem',
})

const Text = styled(Typography)({
  alignSelf: 'center',
})

const getLabel = ({ name, value, values }: RadioProps | CheckboxProps) => {
  return compose(
    (label: string) => (name ? `${name}: ${label}` : label),
    pathOr('', ['label']),
    find(propEq('value', value)),
  )(values)
}

const DateTimeRangeTag: React.FC<DateTimeRangeProps> = ({ name, value, onChange }) => (
  <>
    {value?.from && (
      <StyledChip
        key={`${name}-from`}
        variant="outlined"
        label={`From: ${moment(value.from).format('MMM Do, YYYY - HH:mm')}`}
        onDelete={() =>
          onChange({
            name,
            value: {
              to: value.to,
            },
          })
        }
      />
    )}
    {value?.to && (
      <StyledChip
        key={`${name}-to`}
        variant="outlined"
        label={`To: ${moment(value.to).format('MMM Do, YYYY - HH:mm')}`}
        onDelete={() =>
          onChange({
            name,
            value: {
              from: value.from,
            },
          })
        }
      />
    )}
  </>
)

const TagComponent: React.FC<FilterOption> = (props) => {
  switch (props.type) {
    case 'radio':
    case 'checkbox':
      return props.value ? (
        <StyledChip
          key={props.name}
          variant="outlined"
          label={getLabel(props)}
          onDelete={() =>
            props.onChange({
              name: props.name,
              value: '',
            })
          }
        />
      ) : null
    case 'datetimerange':
      return <DateTimeRangeTag {...props} />
    default:
      return null
  }
}

const hasValue = (filter: FilterOption) => {
  switch (filter.type) {
    case 'radio':
    case 'checkbox':
      return !!filter.value
    case 'datetimerange': {
      return !!filter.value?.from || !!filter.value?.to
    }
    default:
      return false
  }
}

type FilterTagsProps = {
  id?: string
  filters: Record<string, FilterOption>
  onChange: (filter: { name: string; value: any }) => void
}

export default function FilterTags({ id, filters, onChange }: FilterTagsProps) {
  const filterKeys = Object.keys(filters)
  const isFiltersActive = filterKeys.reduce((acc, key) => acc || hasValue(filters[key]), false)

  if (!isFiltersActive) {
    return <div />
  }

  return (
    <Container id={id}>
      <Text color="textSecondary" noWrap>
        Filters applied:
      </Text>
      {filterKeys.map((key) => (
        <div key={key}>
          <TagComponent {...filters[key]} onChange={onChange} />
        </div>
      ))}
    </Container>
  )
}
