import { FixMeLater } from '@/config/types'
import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import { CATISubs } from '@/app/module/cati'
import Notifications from '@/app/module/notifications'

export default function CATISubsPage(props: FixMeLater) {
  const { match, history } = props
  const params = new URLSearchParams(window.location.search)
  const page = params.get('page') && params.get('page') !== '0' ? parseInt(params.get('page') as any, 10) : 1
  const search = params.get('search') || ''

  return (
    <AuthGuard url={match.url} path={match.path}>
      <Layout title="CATI Survey - engageSPARK">
        <AuthHeader currentPage={PAGE.CATI.SUBS} />
        {/* @ts-ignore */}
        <CATISubs history={history} page={page} searchTerm={search} />
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}
