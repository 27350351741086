import { cannot } from '@/app/helpers'
import {
  TWhatsappButtonsContent,
  TWhatsappContent,
  TWhatsappListContent,
  TWhatsappMediaContent,
  TWhatsappMessageContent,
} from '@/app/types'
import { WhatsAppLog } from '@/app/types/log'

type Content =
  | {
      type: 'message'
      content: TWhatsappMessageContent
    }
  | {
      type: 'image'
      content: TWhatsappMediaContent
    }
  | {
      type: 'audio'
      content: TWhatsappMediaContent
    }
  | {
      type: 'video'
      content: TWhatsappMediaContent
    }
  | {
      type: 'document'
      content: TWhatsappMediaContent
    }
  | {
      type: 'buttons'
      content: TWhatsappButtonsContent
    }
  | {
      type: 'list'
      content: TWhatsappListContent
    }

const getFilename = (fileUrl: string) => {
  const url = new URL(fileUrl)
  return url.pathname.split('/').pop() || ''
}

const convertFiles = (content: TWhatsappMediaContent): TWhatsappMediaContent => ({
  ...content,
  files: content.files.map(getFilename),
})

export const extractWhatsappContent = (content: TWhatsappContent): Content => {
  if ('message' in content) {
    return {
      type: 'message',
      content: content.message,
    }
  }

  if ('buttons' in content) {
    return {
      type: 'buttons',
      content: content.buttons,
    }
  }

  if ('list' in content) {
    return {
      type: 'list',
      content: content.list,
    }
  }

  if ('image' in content) {
    return {
      type: 'image',
      content: convertFiles(content.image),
    }
  }

  if ('audio' in content) {
    return {
      type: 'audio',
      content: convertFiles(content.audio),
    }
  }

  if ('video' in content) {
    return {
      type: 'video',
      content: convertFiles(content.video),
    }
  }

  if ('document' in content) {
    return {
      type: 'document',
      content: convertFiles(content.document),
    }
  }

  return cannot(content)
}

export const extractWhatsappContentFromPart = (part: WhatsAppLog): Content => {
  if (part.content) {
    return extractWhatsappContent(part.content)
  }

  const { message, audioFiles, imageFiles, videoFiles, documentFiles } = part
  if (audioFiles?.length) {
    return {
      type: 'audio',
      content: {
        files: audioFiles,
        message,
      },
    }
  }
  if (imageFiles?.length) {
    return {
      type: 'image',
      content: {
        files: imageFiles,
        message,
      },
    }
  }
  if (videoFiles?.length) {
    return {
      type: 'video',
      content: {
        files: videoFiles,
        message,
      },
    }
  }
  if (documentFiles?.length) {
    return {
      type: 'document',
      content: {
        files: documentFiles,
        message,
      },
    }
  }
  return {
    type: 'message',
    content: {
      message,
    },
  }
}
