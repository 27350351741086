import React from 'react'
import { BaseLanguageType } from '@/app/module/campaigns/helpers'
import {
  CampaignTemplateType,
  Language,
  Message,
  PersonalizationType,
  TVoicePreview,
  VoiceMessage,
} from '@/app/module/campaigns/types'
import { cannot } from '@/app/helpers'
import CampaignContentSMS from '@/app/module/campaigns/component/item/steps/content/sms'
import CampaignContentVoice from '@/app/module/campaigns/component/item/steps/content/voice'
import { FilesType, FileType, FileUploadType } from '@/app/types'
import Accordion from './components/accordion'

type Props = {
  campaignType: CampaignTemplateType
  content?: string
  defaultLanguage: string
  defaultUpload: number
  files: {
    data: FilesType
    loadingItems: string[]
  }
  hasQuestion?: boolean
  id: string
  languages: Language[]
  loading: boolean
  personalizationList: PersonalizationType
  voiceList: BaseLanguageType[]
  voicePlayingId: string
  voicePreview: TVoicePreview
  welcome: Message

  getFile: (id: string, file: string) => Promise<FileType>
  getVoicePreview: (say: string, voice: string) => void
  onChange: (value: Partial<Message>) => void
  onMicAccessError: () => void
  saveFile: (id: string, file: FileUploadType) => Promise<FileType>
  setDefaultUpload: (defaultUpload: number) => void
  setPlayingId: (playId?: string) => void
  setPreviewLanguage: (language: string) => void
}

const emptyObject = {}

const WelcomeMessage: React.FC<Props> = ({
  campaignType,
  content,
  defaultLanguage,
  defaultUpload,
  files,
  hasQuestion,
  id,
  languages,
  loading,
  personalizationList,
  voiceList,
  voicePlayingId,
  voicePreview,
  welcome,
  getFile,
  getVoicePreview,
  onChange,
  onMicAccessError,
  saveFile,
  setDefaultUpload,
  setPlayingId,
  setPreviewLanguage,
}) => {
  const getFileHandler = React.useCallback((file: string) => getFile(id, file), [id, getFile])
  const saveFileHandler = React.useCallback((item: FileUploadType) => saveFile(id, item), [id, saveFile])

  switch (campaignType) {
    case CampaignTemplateType.CATI:
    case CampaignTemplateType.Drip:
    case CampaignTemplateType.Reminder:
    case CampaignTemplateType.SMSBlast:
    case CampaignTemplateType.WhatsAppSurvey:
      return null

    case CampaignTemplateType.SMSSurvey: {
      const info = hasQuestion
        ? 'Include the choices in your question, e.g. "What is your gender? Reply 1 for female, 2 for male"'
        : 'Add your message'
      return (
        <WelcomeContainer content={content}>
          <CampaignContentSMS
            defaultLanguage={defaultLanguage}
            info={info}
            item={welcome}
            label="Message"
            languages={languages}
            personalizationList={personalizationList}
            changeHandler={onChange}
          />
        </WelcomeContainer>
      )
    }

    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey: {
      const info = hasQuestion
        ? 'Include the answer choices in your question, e.g. “What is your gender? Press the number 1 on your phone for female, press the number 2 for male."'
        : ''
      return (
        <WelcomeContainer content={content}>
          <CampaignContentVoice
            debounceChange
            defaultLanguage={defaultLanguage}
            defaultUpload={defaultUpload}
            enableTTS
            files={files.data || emptyObject}
            filterType={campaignType}
            info={info}
            item={welcome as VoiceMessage}
            languages={languages}
            loading={loading}
            personalizationList={personalizationList}
            playId={voicePlayingId}
            voiceList={voiceList}
            voicePreview={voicePreview}
            changeHandler={onChange}
            getFile={getFileHandler}
            onMicAccessError={onMicAccessError}
            onVoicePreviewRequest={getVoicePreview}
            saveFileHandler={saveFileHandler}
            setPlaying={setPlayingId}
            setDefaultUpload={setDefaultUpload}
            setPreviewLanguage={setPreviewLanguage}
          />
        </WelcomeContainer>
      )
    }

    default:
      return cannot(campaignType)
  }
}

const WelcomeContainer: React.FC<React.PropsWithChildren<{ content?: string }>> = ({ children, content }) => {
  return (
    <div
      style={{
        marginBottom: '18px',
      }}
    >
      <Accordion
        data-testid="welcome-accordion"
        display={!!content}
        placeholder="Welcome Message"
        title={`Click here to ${content ? 'edit the' : 'add a'} welcome message.`}
      >
        {children}
      </Accordion>
    </div>
  )
}

export default React.memo(WelcomeMessage)
