import { equals } from 'ramda'
import React from 'react'
import Topup from '@/app/component/atom/topup'
import { PersonalizationType, TopupMessage } from '@/app/module/campaigns/types'

type Props = {
  item: TopupMessage
  personalizationList: PersonalizationType

  changeHandler: (item: Partial<TopupMessage>, changed?: boolean) => void
}

export default function CampaignContentTopup(props: Props) {
  const { personalizationList = {}, changeHandler, item } = props
  const { contact = [], customfields = [] } = personalizationList
  const { topup: propTopup = {} } = item
  const [topup, setTopup] = React.useState<{
    desiredAmountTemplated?: string
    maxAmount: string
  }>({
    desiredAmountTemplated: propTopup.desiredAmount,
    maxAmount: propTopup.maxAmount || '',
  })
  const [topupType, setTopupType] = React.useState<string>('fixed')

  React.useEffect(() => {
    const newTopup = {
      ...item.topup,
      maxAmount: topup.maxAmount,
      desiredAmount: topup?.desiredAmountTemplated,
    }

    if (!equals(newTopup, item.topup)) {
      changeHandler({
        ...item,
        topup: newTopup,
      })
    }
  }, [item, topup, changeHandler])

  return (
    <Topup
      contact={contact}
      customfields={customfields}
      desiredAmountTemplated={topup.desiredAmountTemplated}
      isAction={false}
      maxAmount={topup.maxAmount}
      maxAmountTemplated={''}
      topupType={topupType}
      setTopup={setTopup}
      setTopupType={setTopupType}
    />
  )
}
