import { equals } from 'ramda'
import { PureComponent } from 'react'

export default function createSearchableTable(Wrapped) {
  return class SearchableTable extends PureComponent {
    state = {
      orgChanged: false,
      isVisible: !!(this.props.query.searchTerm || this.props.pageSearchTerm),
    }

    componentDidUpdate(prevProps) {
      const { token, orgId, query, getData, navigate, history } = this.props

      if (this.state.orgChanged) {
        this.setState({
          orgChanged: false,
        })
        return
      }

      if (!equals(query, prevProps.query)) {
        const orgChanged = orgId !== prevProps.orgId && query.page === 1

        this.setState({
          isVisible: !!query.searchTerm,
          term: query.searchTerm || '',
          orgChanged,
        })

        if (orgChanged) {
          navigate({ history, page: 1, searchTerm: '' })
        }

        getData({
          token,
          orgId,
          query,
        })
      }
    }

    submitHandler(term) {
      const { history, query, navigate } = this.props

      navigate({ ...query, history, page: 1, searchTerm: term })
    }

    render() {
      const { query, pageSearchTerm } = this.props

      return (
        <Wrapped
          {...this.props}
          search={{
            term: query.searchTerm || pageSearchTerm,
            onSubmit: (searchTerm) => this.submitHandler(searchTerm),
          }}
        />
      )
    }
  }
}
