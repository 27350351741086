import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import EditIcon from '@mui/icons-material/Edit'
import { ListItemButton, ListItemSecondaryAction } from '@mui/material'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import React from 'react'
import { AutoSizer, List } from 'react-virtualized'
import { makeStyles, withStyles } from 'tss-react/mui'
import StyledBadge from '../../../../../component/atom/styled-badge'
import Loading from '../../../../../component/guard/loading'
import { numberWithCommas } from '../../../../../service/util/format'
import SearchBar from './search-bar'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 225px)',
    maxWidth: 360,
    backgroundColor: theme.palette.highlight.light,
    overflow: 'hidden',
  },
  groupSearch: {
    padding: '10px 10px 5px',
  },
  groupSearchInput: {
    width: '100%',
  },
}))

const ROW_HEIGHT = 70

const StyledListItem = withStyles(ListItem, {
  root: {
    backgroundColor: '#fff',
    padding: 0,
  },
  selected: {},
})

const StyledListItemButton = withStyles(ListItemButton, (theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(0.5, 2),
  },
}))

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const goToEdit = ({ event, type, id, history }) => {
  event.stopPropagation()
  history.push(`/${type}/edit/${id}`)
}

const GroupList = ({ groups, onClick, history, tab, selected, classes, searchTerm, setSearchTerm }) => {
  const renderedGroups = React.useMemo(() => {
    if (!groups?.length) {
      return (
        <div
          style={{
            textAlign: 'center',
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <Typography>No {tab} found.</Typography>
        </div>
      )
    }

    const renderRow = ({ index, key, style }) => {
      const { label, value, numberOfContacts, recounting = false } = groups[index]
      const labelId = `checkbox-list-label-${value}`

      return (
        <StyledListItem disableGutters key={key} style={style} title={label}>
          <StyledListItemButton disabled={recounting} selected={selected === value} onClick={onClick(value)}>
            <ListItemIcon>
              <IconButton aria-label="edit" onClick={(event) => goToEdit({ event, type: tab, id: value, history })}>
                <EditIcon fontSize="small" />
              </IconButton>
            </ListItemIcon>
            <ListItemText
              id={labelId}
              primary={label}
              primaryTypographyProps={{
                style: {
                  display: '-webkit-box',
                  lineClamp: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                },
                sx: {
                  paddingRight: (theme) => theme.spacing(2),
                },
              }}
              secondary={`${numberWithCommas(numberOfContacts)} contact${numberOfContacts !== 1 ? 's' : ''}`}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="chevronright" onClick={onClick(value)}>
                {recounting ? (
                  <div>
                    <CircularProgress size={20} />
                  </div>
                ) : (
                  <ChevronRightIcon className="open-segment" />
                )}
              </IconButton>
            </ListItemSecondaryAction>
          </StyledListItemButton>
        </StyledListItem>
      )
    }

    return (
      <div className={`${classes.root} ${tab}-list`}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              overscanRowCount={10}
              rowCount={groups.length}
              rowHeight={ROW_HEIGHT}
              style={{
                paddingBottom: '8px',
                paddingTop: '8px',
                outline: 'none',
              }}
              width={width}
              rowRenderer={renderRow}
            />
          )}
        </AutoSizer>
      </div>
    )
  }, [classes.root, groups, history, selected, tab, onClick])

  return (
    <>
      <div className={classes.groupSearch}>
        <SearchBar
          id={`${tab}-search-input`}
          searchTerm={searchTerm[tab] || ''}
          onChange={(value) =>
            setSearchTerm({
              ...searchTerm,
              [tab]: value,
            })
          }
        />
      </div>
      {renderedGroups}
    </>
  )
}

export default function GroupFilter({
  groups,
  segments,
  onFilterChange,
  history,
  selected = 0,
  searchGroups,
  loading,
}) {
  const { classes } = useStyles()
  const [tab, setTab] = React.useState('groups')
  const [timeout, addTimeout] = React.useState(null)
  const [searchTerm, setSearchTerm] = React.useState({
    groups: null,
    segments: null,
  })

  React.useEffect(() => {
    addTimeout((s) => {
      if (s) {
        clearTimeout(s)
      }

      if (searchTerm[tab] === null) {
        // skip if the search term is null, which means the search term is not entered yet
        // groups/segments should be loaded initially by the parent component
        return s
      }

      return setTimeout(() => {
        searchGroups(searchTerm[tab], tab)
      }, 500)
    })
  }, [searchTerm, tab, searchGroups])

  const handleChange = (_, newTab) => {
    setTab(newTab)
  }

  const tabContent = {
    segments: {
      groups: segments,
      label: {
        singular: 'segment',
        plural: 'segments',
      },
    },
    groups: {
      groups,
      label: {
        singular: 'group',
        plural: 'groups',
      },
    },
  }

  const onClick = React.useCallback(
    (value) => () => {
      onFilterChange({
        name: tab,
        value,
      })
    },
    [tab, onFilterChange],
  )

  return (
    <Loading isLoading={loading}>
      <Tabs
        value={tab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="groups segments drawer"
      >
        <Tab
          className="groups-tab-btn"
          label={
            <StyledBadge color="primary" badgeContent={groups.length} showZero>
              Groups
            </StyledBadge>
          }
          value="groups"
          {...a11yProps(0)}
        />
        <Tab
          className="segments-tab-btn"
          label={
            <StyledBadge color="primary" badgeContent={segments.length} showZero>
              Segments
            </StyledBadge>
          }
          value="segments"
          {...a11yProps(1)}
        />
      </Tabs>
      {tabContent[tab].groups.length > 0 || searchTerm[tab] || timeout || loading ? (
        <GroupList
          classes={classes}
          groups={tabContent[tab].groups}
          history={history}
          searchTerm={searchTerm}
          selected={selected}
          tab={tab}
          onClick={onClick}
          setSearchTerm={setSearchTerm}
        />
      ) : (
        <div
          style={{
            textAlign: 'center',
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <Button
            id="add-group-button"
            color="primary"
            variant="contained"
            size="large"
            style={{
              marginRight: '15px',
            }}
            onClick={() => history.push(`/${tab}/add`)}
            startIcon={<Icon>add</Icon>}
          >
            Add your first {tabContent[tab].label.singular}
          </Button>
        </div>
      )}
    </Loading>
  )
}
