import { FormControl, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import { concat } from 'ramda'
import React from 'react'
import { Radio, SearchableSelect } from './form'

const radioValues = [
  {
    label: 'Send same amount to all contacts',
    value: 'fixed',
  },
  {
    label: 'Send amount based on contact field',
    value: 'dynamic',
  },
]

const numberValidationCheck = (value: string) => !value.match(/^-?\d*(\.\d+)?$/) || Number(value) <= 0

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'setMaxAmount':
      return {
        maxAmount: action.payload,
        maxAmountTemplated: '',
        desiredAmountTemplated: state.desiredAmountTemplated,
        maxAmountError: numberValidationCheck(action.payload),
        maxAmountTemplatedError: false,
        desiredAmountTemplatedError:
          Number(action.payload) > 0 && Number(action.payload) < Number(state.desiredAmountTemplated),
      }
    case 'setRequestedAmountTemplated':
      return {
        maxAmount: state.maxAmount,
        maxAmountTemplated: '',
        desiredAmountTemplated: action.payload,
        maxAmountError: state.maxAmountError,
        maxAmountTemplatedError: false,
        desiredAmountTemplatedError: Number(state.maxAmount) > 0 && Number(state.maxAmount) < Number(action.payload),
      }
    case 'setMaxAmountTemplated':
      return {
        maxAmount: '',
        maxAmountTemplated: action.payload,
        desiredAmountTemplated: '',
        maxAmountError: false,
        maxAmountTemplatedError: !action.payload,
        desiredAmountTemplatedError: false,
      }
    default:
      return {
        error: true,
      }
  }
}

export default function Topup(props: any) {
  const {
    contact,
    customfields,
    setDisabled,
    maxAmount = '',
    maxAmountTemplated = '',
    desiredAmountTemplated = '',
    topupType,
    setTopupType,
    setTopup,
    isAction = true,
  } = props
  const initialState = {
    maxAmount,
    maxAmountTemplated,
    desiredAmountTemplated,
    maxAmountError: maxAmount ? numberValidationCheck(maxAmount) : false,
    maxAmountTemplatedError: false,
    desiredAmountTemplatedError: Number(maxAmount) > 0 && Number(maxAmount) < Number(desiredAmountTemplated),
  }

  const [state, dispatch] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    setDisabled?.(
      topupType === 'fixed'
        ? state.maxAmountError || !state.maxAmount || state.desiredAmountTemplatedError
        : state.maxAmountTemplatedError || !state.maxAmountTemplated,
    )
    setTopup({
      ...(topupType === 'fixed' && {
        maxAmount: state.maxAmount,
        desiredAmountTemplated: state.desiredAmountTemplated,
      }),
      ...(topupType === 'dynamic' && {
        maxAmountTemplated: `{{${state.maxAmountTemplated}}}`,
      }),
    })
  }, [state, topupType, setDisabled, setTopup])

  return (
    <>
      <form
        id="airtime-topup-form"
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        {isAction && (
          <Radio
            id="topup-type"
            error=""
            name="topup-type"
            value={topupType}
            values={radioValues}
            row={true}
            onChange={({ value: radioValue }: any) => {
              setTopupType(radioValue)
            }}
          />
        )}
        {topupType === 'fixed' ? (
          <>
            <FormControl
              variant="standard"
              fullWidth
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <TextField
                variant="standard"
                id="desired-amount-input"
                inputProps={{
                  type: 'number',
                }}
                label="Desired Amount"
                helperText={
                  state.desiredAmountTemplatedError
                    ? "The desired amount can't be more than the max amount."
                    : 'Specify the amount you would like to send to each contact.'
                }
                error={state.desiredAmountTemplatedError}
                value={state.desiredAmountTemplated}
                onChange={(e) => dispatch({ type: 'setRequestedAmountTemplated', payload: e.target.value })}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <TextField
                variant="standard"
                id="max-amount-input"
                inputProps={{
                  type: 'number',
                }}
                label="Max Amount *"
                helperText="Specify the maximum amount we can send to each contact."
                error={state.maxAmountError}
                value={state.maxAmount}
                onChange={(e) => dispatch({ type: 'setMaxAmount', payload: e.target.value })}
              />
            </FormControl>
          </>
        ) : (
          <SearchableSelect
            formControlProps={{
              style: {
                margin: 'none',
                width: '100%',
              },
            }}
            labelId="select-contact-field-label"
            value={state.maxAmountTemplated}
            error={state.maxAmountTemplatedError}
            keyPropFn={(option: any) => option.value}
            valuePropFn={(option: any) => option.label}
            onChange={(selectedId: any) => {
              dispatch({ type: 'setMaxAmountTemplated', payload: selectedId })
            }}
            id="select-contact-field"
            label="Select Field"
            options={concat(contact, customfields)}
            showAll
          />
        )}
        <div
          style={{
            marginBottom: 10,
          }}
        >
          <div>
            <Typography variant="caption" color="textSecondary">
              The amount sent will be in the currency specified in our{' '}
              <a
                href="https://www.engagespark.com/support/airtime-topup-transfer-api-global-pricing/"
                target="_blank"
                rel="noopener noreferrer"
              >
                pricing table
              </a>
              , where you can find the available amounts per country and telco.
              <br />
            </Typography>
          </div>
          {topupType === 'fixed' && (
            <div>
              <Typography variant="caption" color="textSecondary">
                Because the denominations that we can send vary by country and telco, we ask you for a{' '}
                <strong>desired amount</strong> and a <strong>maximum amount</strong>. We will send the{' '}
                <strong>desired amount</strong> if it's available for the contact. If it's not available, we'll send the
                lowest amount available above the <strong>desired amount</strong>, but no higher than the{' '}
                <strong>maximum amount</strong> you set. <br />
                If you do not specify a <strong>desired amount</strong>, we will send the{' '}
                <strong>maximum amount</strong> you specify if it's available for the contact. If it's not available,
                we'll send the next lowest amount available.
              </Typography>
            </div>
          )}
        </div>
      </form>
    </>
  )
}
