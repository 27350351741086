import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import WhatsappIcon from '@mui/icons-material/WhatsApp'
import { has, pathOr, reduce } from 'ramda'
import React from 'react'
import { getPartContentPreview } from '@/app/module/campaigns/utils/part-content'
import Select from '@/app/component/atom/form/select'
import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { MESSAGE_TYPES, Q_ACTIONS, cropText, displayMap, iconMap } from '@/app/module/campaigns/definitions'
import { mapIndexed } from '@/app/module/campaigns/helpers'

const unwrapValue = (value) =>
  pathOr(
    {
      nextPart: '',
    },
    ['jump'],
    value,
  )

const JumpQuestionMenuItem = ({ index, message, icon, type, label }) => (
  <div
    style={{
      display: 'flex',
    }}
  >
    <span
      style={{
        marginRight: 5,
      }}
    >
      {icon === 'whatsapp' ? (
        <WhatsappIcon sx={{ width: '20px', height: '20px' }} />
      ) : (
        <Icon
          style={{
            color: 'rgba(0, 0, 0, 0.54)',
          }}
          fontSize="small"
        >
          {icon}
        </Icon>
      )}
    </span>
    {label ? (
      <Typography
        variant="subtitle2"
        color="textPrimary"
        style={{
          marginRight: 5,
        }}
      >
        {index}. {label}
      </Typography>
    ) : (
      <React.Fragment>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{
            marginRight: 5,
          }}
        >
          {index}. {type}
          {message && ':'}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {cropText({
            text: message,
            limit: 60,
            firstCutOff: 30,
            secondCutOff: 25,
          })}
        </Typography>
      </React.Fragment>
    )}
  </div>
)

const selectContent = ({ message, index, disabled }) =>
  reduce(
    (accum, type) => {
      if (has(type.key, message)) {
        return {
          label: (
            <JumpQuestionMenuItem
              message={getPartContentPreview(message)}
              index={index + 1}
              icon={iconMap[type.key]}
              label={message.label}
              type={type.label}
            />
          ),
          value: message.id,
          disabled,
        }
      }
      return accum
    },
    {},
    MESSAGE_TYPES,
  )

const selectParts = (parts, currentIndex) =>
  mapIndexed(
    (part, index) =>
      selectContent({
        message: part,
        index,
        disabled: index <= currentIndex,
      }),
    parts,
  )

const JumpToQuestion = (props) => {
  const { nextPart } = unwrapValue(props.value)
  const [partId, setPartId] = React.useState(nextPart)
  const { onClose, onSave } = props
  const parts = selectParts(props.parts, props.index)
  return (
    <CustomDialog open={true} onClose={onClose}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>{displayMap.jump.icon}</Icon>
            <Typography variant="h6">{Q_ACTIONS.jump.label}</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '24px' }}>
        <div
          style={{
            width: '80vw',
            minWidth: '300px',
            maxWidth: '100%',
          }}
        >
          <form
            id="send-message-form"
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <CardContent>
              <Typography variant="caption" color="textSecondary">
                We'll automatically ask the next question in line. But if you don't want that you can choose to jump to
                a later question in the campaign. The contact will be asked that question next, skipping any questions
                between this one and the one you select.
              </Typography>
              <Select
                className="select-jumpto-question"
                label="Select Question"
                value={partId}
                values={parts}
                editable={true}
                onChange={({ value }) => {
                  setPartId(value)
                }}
              />
            </CardContent>
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          id="jumpto-question-save-btn"
          disabled={!partId}
          onClick={() =>
            onSave({
              jump: {
                nextPart: partId,
              },
            })
          }
        >
          Save
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}

export default JumpToQuestion
