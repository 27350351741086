import { useMemo } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { useCombobox } from 'downshift'
import { matchSorter } from 'match-sorter'
import { AutoSizer, List } from 'react-virtualized'
import { styled } from '@mui/material/styles'
import FormControl from './form-control'
import Input from './input'

const Root = styled('div')({
  flexGrow: 1,
  position: 'relative',
})

const Container = styled('div')({
  flexGrow: 1,
})

const StyledPaper = styled(Paper)({
  position: 'absolute',
  zIndex: 99,
  marginTop: '-8px',
  left: 0,
  right: 0,
  maxHeight: '250px',
  overflow: 'hidden',
})

type Value = { label: string; value: string; component?: () => JSX.Element }

type AutocompleteProps = {
  'data-testid'?: string
  name: string
  label?: string
  editable?: boolean
  restricted?: boolean
  value?: string
  values?: Value[]
  error?: string
  onChange: (p: { name: string; value: string; error?: string }) => void
  onBlur: (p: { name: string; value: string; error?: string }) => void
  onOpen: () => void
  onClose: () => void
}

function Autocomplete({
  'data-testid': dataTestId,
  name,
  label,
  editable = true,
  restricted = false,
  value = undefined,
  values = [],
  error = '',
  onChange = () => {},
  onBlur = () => {},
}: AutocompleteProps) {
  const { inputValue, isOpen, getInputProps, getItemProps } = useCombobox<Value>({
    onInputValueChange(change) {
      return matchSorter(values, change.inputValue || '', { keys: ['label'] })
    },
    items: values,
    itemToString(item) {
      return item?.label || ''
    },
    onIsOpenChange(change) {
      if (!change.isOpen) {
        onChange({
          name,
          value: change.inputValue || '',
          error:
            restricted &&
            change.inputValue !== '' &&
            !values.find(({ label: matchName }) => matchName === change.inputValue)
              ? 'No matches found'
              : '',
        })
      } else {
        onChange({
          name,
          value: change.inputValue || '',
          error: undefined,
        })
      }
    },
  })

  const matches = useMemo(() => matchSorter(values, inputValue, { keys: ['label'] }), [values, inputValue])

  if (!editable) {
    return <Input name={name} label={label} value={value} editable={editable} />
  }

  return (
    <Root>
      <Container>
        <FormControl error={error}>
          <TextField
            variant="standard"
            id="autocomplete-input"
            fullWidth={true}
            label={label}
            error={!!error}
            inputProps={getInputProps({
              'data-testid': dataTestId,
              autoComplete: 'off',
              name,
              id: `autocomplete-input-${name}`,
              style: {
                flexWrap: 'wrap',
              },
              onChange: (e) => {
                e.persist?.()
                onChange({
                  name,
                  value: (e.target as HTMLInputElement).value,
                  error: restricted && inputValue !== '' && matches.length === 0 ? 'No matches found' : '',
                })
              },
              onBlur: (e) => {
                e.persist?.()
                onBlur({
                  name,
                  value: inputValue || '',
                  error:
                    restricted && inputValue !== '' && !matches.find(({ label: matchName }) => matchName === inputValue)
                      ? 'No matches found'
                      : '',
                })
              },
            })}
          />
        </FormControl>
        {isOpen && matches.length > 0 && (
          <StyledPaper square>
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  id="autocomplete-list"
                  width={width}
                  height={Math.min(49 * 5, matches.length * 49)}
                  rowCount={matches.length}
                  rowHeight={49}
                  rowRenderer={({ key, index, style }) => (
                    <MenuItem
                      {...getItemProps({ item: matches[index] })}
                      key={key}
                      style={{ ...style, boxSizing: 'border-box' }}
                      component="div"
                    >
                      {matches[index]?.component?.() || matches[index].label}
                    </MenuItem>
                  )}
                />
              )}
            </AutoSizer>
          </StyledPaper>
        )}
      </Container>
    </Root>
  )
}

export default Autocomplete
