import { reverse } from 'ramda'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { SMSLog, WhatsAppLog } from '@/app/types/log'
import { ContactType, TGetFileFunction } from '@/app/module/conversations/types'
import { Button } from '@mui/material'
import Part from './parts'

const useStyle = makeStyles()({
  historyWrapper: {
    padding: '10px 10px 0px',
    textAlign: 'center',
    overflowY: 'scroll',
    background: 'rgb(245, 245, 245)',
    transition: 'height 0.1s',
    position: 'relative',
  },
})

type Props = {
  activeContact: ContactType
  height: number
  scrolled: boolean
  wrapper: React.RefObject<HTMLDivElement>

  getFile: TGetFileFunction
  onLoadMore: () => void
  readAllContactMessages: (contactId: number) => void
  setScrolled: (scrolled: boolean) => void
  scrollToBottom: () => void
}

let globalContact: ContactType

export default function History(props: Props) {
  const { classes } = useStyle()
  const { scrolled, activeContact, height, getFile, wrapper, scrollToBottom, setScrolled, readAllContactMessages } =
    props
  globalContact = activeContact

  React.useEffect(() => {
    scrollToBottom()
  }, [activeContact.contactId, scrollToBottom])

  React.useEffect(() => {
    if (!scrolled) {
      scrollToBottom()
    }
  }, [activeContact.history, scrolled, scrollToBottom])

  const handleScroll = React.useCallback(() => {
    if (wrapper && wrapper.current) {
      const bottom =
        Math.ceil(wrapper.current.offsetHeight + wrapper.current.scrollTop) >= wrapper.current.scrollHeight - 10
      setScrolled(!bottom)
      if (bottom && globalContact.contactId && globalContact.unreadCount > 0) {
        readAllContactMessages(globalContact.contactId)
      }
    }
  }, [wrapper, setScrolled, readAllContactMessages])

  React.useEffect(() => {
    const { current } = wrapper
    current?.addEventListener('scroll', handleScroll)
    return () => {
      current?.removeEventListener('scroll', handleScroll)
    }
  }, [wrapper, handleScroll])

  const renderedHistory = React.useMemo(() => {
    if (!activeContact.history) {
      return null
    }
    return reverse(activeContact.history).map((part: WhatsAppLog | SMSLog) => (
      <Part getFile={getFile} type={part.type} key={part.id} part={part} />
    ))
  }, [activeContact.history, getFile])

  return (
    <div
      className={classes.historyWrapper}
      ref={wrapper}
      style={{
        height: `calc(100vh - ${120 + height}px)`,
      }}
    >
      {!!activeContact.nextPage && (
        <Button variant="text" onClick={props.onLoadMore}>
          Load older messages
        </Button>
      )}
      {renderedHistory}
    </div>
  )
}
