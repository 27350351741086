import { PartType } from '@/app/module/conversations/types'

type Props = {
  part: PartType
}

export default function SMS({ part }: Props) {
  return (
    <div
      style={{
        whiteSpace: 'pre-line',
      }}
    >
      {part.message}
    </div>
  )
}
