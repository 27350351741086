import { WhatsAppOverview } from '@/app/module/campaigns/types'
import React from 'react'
import { getPercentage } from '@/app/service/util/format'
import OverviewTable from '@/app/component/atom/overview-table'

type Props = {
  data: WhatsAppOverview
}

const WhatsappOverviewTable: React.FC<Props> = ({ data }) => {
  const tableData = React.useMemo(
    () => [
      {
        name: 'Messages sent',
        value: data.totalEgress,
      },
      {
        name: 'Messages delivered (% of sent)',
        value: `${data.success} (${getPercentage(data.success, data.totalEgress)})`,
      },
      {
        name: 'Messages failed (% of sent)',
        value: `${data.failed} (${getPercentage(data.failed, data.totalEgress)})`,
      },
    ],
    [data],
  )

  return <OverviewTable data={tableData} title="WhatsApp" />
}

export default WhatsappOverviewTable
