import { Typography } from '@mui/material'
import React from 'react'
import { match as Match } from 'react-router-dom'
import Layout from '@/app/component/layout/page'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'

type Props = {
  match: Match
}

const NoOrgPage: React.FC<Props> = ({ match }) => (
  <AuthGuard path={match.path} url={match.url}>
    <Layout>
      <AuthHeader />
      <div style={{ padding: 24, textAlign: 'center' }}>
        <Typography variant="h5">
          To use engageSPARK you must be part of an organization. Currently, you are not.
          <br />
          Please ask a colleague to invite you into an existing engageSPARK organization, or contact our support to
          create one for you.
        </Typography>
      </div>
    </Layout>
    <Notifications />
  </AuthGuard>
)

export default NoOrgPage
