import {
  Button,
  ButtonProps,
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  SxProps,
  Theme,
  styled,
} from '@mui/material'
import { isEmpty } from 'ramda'
import React from 'react'
import { PersonalizationType, SinglePersonalizationType } from '@/app/module/campaigns/types'
import CustomTooltip from './tooltip'

type Props = {
  color?: ButtonProps['color']
  containerSx?: SxProps<Theme>
  disabled?: boolean
  id: string
  personalizationList: PersonalizationType
  testId?: {
    button: string
    menu: string
  }
  variant?: ButtonProps['variant']

  getTitle?: (item: SinglePersonalizationType) => string
  isItemDisabled?: (item: SinglePersonalizationType) => boolean
  onClose?: () => void
  onOpen?: () => void
  onSelect: (pz: SinglePersonalizationType) => void
}

export type PzMenuRef = {
  close: () => void
  open: () => void
}

const PzMenu = React.forwardRef<PzMenuRef, Props>(
  (
    {
      color,
      containerSx,
      disabled,
      id,
      personalizationList,
      testId,
      variant,
      getTitle,
      isItemDisabled,
      onClose,
      onOpen,
      onSelect,
    },
    ref,
  ) => {
    const [pzOpen, setPzOpen] = React.useState(false)
    const anchorEl = React.useRef<HTMLDivElement | null>(null)

    React.useImperativeHandle(ref, () => ({
      close: () => {
        setPzOpen(false)
      },
      open: () => {
        setPzOpen(true)
      },
    }))

    const onMenuOpen = React.useCallback(() => {
      if (disabled) {
        return
      }
      setPzOpen(true)
      onOpen?.()
    }, [disabled, onOpen])
    const onMenuClose = React.useCallback(() => {
      setPzOpen(false)
      onClose?.()
    }, [onClose])

    const pzKeys = React.useMemo(() => Object.keys(personalizationList), [personalizationList])
    return (
      <>
        <Container sx={containerSx}>
          <div ref={anchorEl} onClick={onMenuOpen}>
            <CustomTooltip title={isEmpty(personalizationList) ? 'Personalization not available' : 'Personalization'}>
              <div>
                {variant && (
                  <PZMenuButton
                    color={color}
                    data-testid={testId?.button || `${id}-pz-menu-toggle`}
                    id={`${id}-pz-menu-toggle`}
                    variant={variant}
                    disabled={disabled || isEmpty(personalizationList)}
                  >
                    <Icon>code</Icon>
                  </PZMenuButton>
                )}
                {!variant && (
                  <PZMenuIconButton
                    color={color}
                    data-testid={testId?.button || `${id}-pz-menu-toggle`}
                    id={`${id}-pz-menu-toggle`}
                    disabled={disabled || isEmpty(personalizationList)}
                  >
                    <Icon>code</Icon>
                  </PZMenuIconButton>
                )}
              </div>
            </CustomTooltip>
          </div>
        </Container>
        <Menu
          data-testid={testId?.menu || `${id}-pz-menu`}
          id={`${id}-pz-menu`}
          open={pzOpen}
          onClose={onMenuClose}
          anchorEl={anchorEl.current}
          disableAutoFocusItem={true}
          style={{
            maxHeight: 'calc(100% - 35px)',
          }}
        >
          <MenuList>
            {pzKeys.reduce((acc: React.ReactNode[], key: string, index: number) => {
              const newAcc = [
                ...acc,
                ...personalizationList[key].map((pz) => (
                  <MenuItem
                    id={`pz_${pz.value.split('.').join('_')}`}
                    key={pz.value}
                    disabled={isItemDisabled?.(pz)}
                    title={getTitle?.(pz)}
                    onClick={() => {
                      if (isItemDisabled?.(pz)) {
                        return
                      }
                      onSelect(pz)
                      onMenuClose()
                    }}
                  >
                    {pz.label}
                  </MenuItem>
                )),
              ]

              if (index + 1 < pzKeys.length) {
                return [...newAcc, <Divider key={`divide-${key}`} />]
              }

              return newAcc
            }, [])}
          </MenuList>
        </Menu>
      </>
    )
  },
)

const Container = styled('div')(() => ({
  width: 40,
}))

const PZMenuIconButton = styled(IconButton)(() => ({
  borderRadius: '50%',
  height: 40,
  width: 40,
  minWidth: 40,
}))

const PZMenuButton = styled(Button)(() => ({
  borderRadius: '50%',
  height: 40,
  width: 40,
  minWidth: 40,
}))

export default PzMenu
