import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import MenuItem from '@mui/material/MenuItem'
import MuiSelect from '@mui/material/Select'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { append, equals, find, isEmpty, pathOr, propEq, reduce, remove, update } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { FunctionType } from '@/config/types'
import { Checkbox, Select } from '@/app/component/atom/form'
import CustomTooltip from '@/app/component/atom/tooltip'
import { useDebouncedFn, usePrevious } from '@/app/helpers'
import { CountryTimezone, FilesType, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'
import { canTranscribe } from '@/app/module/campaigns/ai-helpers'
import {
  CAMPAIGN_CHANGE_DELAY,
  CAMPAIGN_RETRIES,
  DRAGTYPE,
  SMS_ACTION_DEFAULT_SENDERID,
  TIMEOUT_DEFAULT,
  repeatQuestionOptions,
} from '@/app/module/campaigns/definitions'
import { ItemType, LanguageType } from '@/app/module/campaigns/helpers'
import { unwrapActions, wrapActions, emptyAction } from '@/app/module/campaigns/utils/actions/wrap'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import {
  generateDefaultSpeechReplies,
  generateSpeechSettings,
  hasSpeechLanguage,
} from '@/app/module/campaigns/language-helpers'
import {
  CampaignTemplateType,
  ChoiceListType,
  ImageProps,
  Language,
  OnAnswerType,
  PartFamily,
  PersonalizationType,
  SenderIdOption,
  SnippetType,
  SpeechRepliesType,
  SpokenType,
  TabTypes,
  VoiceProps,
} from '@/app/module/campaigns/types'
import { LocalAction } from '@/app/module/campaigns/types/actions'
import { CampaignAddButton } from '@/app/module/campaigns/component/helpers'
import QuestionBox from './question'
import QuestionChoiceTag from './question/choice/input-choice'
import QuestionNumberInput from './question/number'
import ResponseVoiceSpecific from './response-voice-specific'

type Props = {
  item: ItemType
  changeHandler: FunctionType
  id: string
  getImageProps: (qid: string) => ImageProps
  getVoiceProps: (qid: string) => VoiceProps
  personalizationList: PersonalizationType
  senderIds: SenderIdOption[]
  getCampaigns: FunctionType
  orgId: number
  token: string
  campaignIds: SelectOption<number>[]
  files: FilesType
  timezone: string
  timezones: TimezoneWithCountryCodeType
  countryCode: string
  countriesTimezones: CountryTimezone[]
  getSnippets: FunctionType
  saveSnippet: FunctionType
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  languages: LanguageType[]
  defaultLanguage: string
  type: CampaignTemplateType
  index: number
  whatsappSenderIds: SenderIdOption[]
}

export default function CampaignContentQuestionVoice(props: Props) {
  const {
    item,
    id,
    getImageProps,
    getVoiceProps,
    personalizationList,
    senderIds,
    getCampaigns,
    orgId,
    token,
    campaignIds,
    files,
    timezone,
    timezones,
    countryCode,
    countriesTimezones,
    getSnippets,
    saveSnippet,
    snippets,
    languages,
    defaultLanguage,
    changeHandler,
    whatsappSenderIds,
  } = props
  const { classes } = useStyles()
  const [state, setState] = React.useState(fromProps(props.item))

  const previousState = usePrevious(state)
  const itemRef = React.useRef<typeof state | null>(null)
  const changeHandlerRef = React.useRef(changeHandler)

  useEffect(
    () => () => {
      if (itemRef.current) {
        changeHandlerRef.current(backToProps(itemRef.current))
      }
    },
    [],
  )

  useEffect(() => {
    if (previousState && !equals(previousState, state)) {
      const returnToProps = backToProps(state)
      changeHandler(returnToProps)
    }
  }, [previousState, state, changeHandler])

  useEffect(() => {
    setState(fromProps(props.item))
  }, [props.item])

  const hasSpeechSettings = useMemo(() => !!item.speechSettings, [item])
  const canEnableSpeech = useMemo(() => hasSpeechLanguage(languages), [languages])

  const speechLanguages = useMemo<Language[]>(
    () =>
      reduce(
        (acc: Language[], l: LanguageType) => {
          if (!l.speechLanguage) {
            return acc
          }

          return [
            ...acc,
            {
              name: l.speechLanguage.name,
              value: l.speechLanguage.value,
            },
          ]
        },
        [],
        languages,
      ),
    [languages],
  )

  const defaultSpeechLang = useMemo(() => {
    if (!hasSpeechSettings) {
      return undefined
    }

    return item.speechSettings.languages?.['']
  }, [hasSpeechSettings, item.speechSettings])

  const { answerActions, timeoutActions, invalidActions, failureActions } = React.useMemo(
    () => getPartActions({ campaignType: props.type, partType: PartFamily.Voice }),
    [props.type],
  )

  const [activeSpeechLang, setActiveSpeechLang] = React.useState<string>('')

  // set active speech language as the default one if it's not set or the value is not in the speech language list
  useEffect(() => {
    setActiveSpeechLang((s) => {
      if (!s || !speechLanguages.find((l) => l.value === s)) {
        return defaultSpeechLang
      }

      return s
    })
  }, [defaultSpeechLang, speechLanguages])

  const handleChange = React.useCallback(
    (newItem: ItemType) => {
      setState((s) => ({
        ...s,
        ...newItem,
      }))
    },
    [setState],
  )

  const debounced = useDebouncedFn((newItem: ItemType) => {
    handleChange(newItem)
    itemRef.current = null
  }, CAMPAIGN_CHANGE_DELAY)
  const debouncedHandleChange = (newItem: ItemType) => {
    itemRef.current = {
      ...state,
      ...newItem,
    }
    debounced(newItem)
  }
  const hasTranscribe = canTranscribe(props.type)

  const actionsProps = React.useMemo(
    () => ({
      campaignIds,
      countriesTimezones,
      countryCode,
      defaultLanguage,
      files,
      hasTranscribe: false,
      index: props.index,
      languages,
      personalizationList,
      senderIds,
      timezone,
      timezones,
      transcribeEnabled: false,
      whatsappSenderIds,
      getCampaigns,
      getImageProps,
      getVoiceProps,
    }),
    [
      campaignIds,
      countriesTimezones,
      countryCode,
      defaultLanguage,
      files,
      languages,
      personalizationList,
      props.index,
      senderIds,
      timezone,
      timezones,
      whatsappSenderIds,
      getCampaigns,
      getImageProps,
      getVoiceProps,
    ],
  )
  const apiProps = React.useMemo(() => ({ orgId, token }), [orgId, token])
  const snippetProps = React.useMemo(
    () => ({ snippets, getSnippets, saveSnippet }),
    [snippets, getSnippets, saveSnippet],
  )

  const handleVoiceSpecificChange = React.useCallback(
    (value: OnAnswerType[]) => changeHandler({ ...item, onAnswer: value }),
    [item, changeHandler],
  )

  return (
    <div
      className="voice-q-choice-block"
      style={{
        marginTop: '2rem',
      }}
    >
      <Divider
        style={{
          margin: '1rem -24px',
        }}
      />
      <Tabs
        value={state.answerType}
        indicatorColor="primary"
        onChange={(e, val: TabTypes) => {
          switch (val) {
            case 'spoken':
              handleChange({
                answerType: val,
                onAnswer: [],
                spoken: {
                  actions: unwrapActions(pathOr([], ['actions'], state.spoken)),
                  silence: 3,
                  maxSeconds: 10,
                  stopKey: '#',
                },
                speechSettings: undefined,
              })
              break

            case 'speech': {
              handleChange({
                answerType: val,
                spoken: undefined,
                onAnswer: [
                  {
                    speechReplies: generateDefaultSpeechReplies(speechLanguages),
                    ranges: [],
                    actions: [emptyAction],
                    label: '',
                  },
                  {
                    speechReplies: generateDefaultSpeechReplies(speechLanguages),
                    ranges: [],
                    actions: [emptyAction],
                    label: '',
                  },
                ],
                speechSettings: generateSpeechSettings(languages),
              })
              break
            }
            default:
              handleChange({
                answerType: val,
                spoken: undefined,
                onAnswer: [
                  {
                    replies: ['1'],
                    ranges: [],
                    actions: [emptyAction],
                    label: '',
                  },
                  {
                    replies: ['2'],
                    ranges: [],
                    actions: [emptyAction],
                    label: '',
                  },
                ],
                speechSettings: undefined,
              })
              break
          }
        }}
      >
        <Tab
          value="keypress"
          label="Keypress Response"
          style={{ zIndex: 2 }}
          icon={<Icon>dialpad</Icon>}
          wrapped
          className="campaign-keypress-tab"
        />
        {canEnableSpeech && (
          <Tab
            value="speech"
            label="Speech Recognition"
            style={{ zIndex: 2 }}
            icon={<Icon>mic</Icon>}
            wrapped
            className="campaign-speech-tab"
          />
        )}
        {!canEnableSpeech && (
          <CustomTooltip title="You have to setup a speech language before you can start using speech recognition">
            <div>
              <Tab
                disabled
                value="speech"
                label="Speech Recognition"
                style={{ zIndex: 2 }}
                icon={<Icon>mic</Icon>}
                wrapped
                className="campaign-speech-tab"
              />
            </div>
          </CustomTooltip>
        )}
        <Tab
          value="spoken"
          label="Spoken Response"
          style={{ zIndex: 2 }}
          icon={<Icon>voicemail</Icon>}
          wrapped
          className="campaign-spoken-tab"
        />
      </Tabs>
      <Divider
        style={{
          margin: '-1px -24px 1rem',
        }}
      />
      {state.answerType === 'keypress' && (
        <div className="campaign-keypress-tab-content">
          <ResponseVoiceSpecific
            actionProps={actionsProps}
            apiProps={apiProps}
            campaignType={props.type}
            id={props.id}
            onAnswer={item.onAnswer}
            snippetProps={snippetProps}
            onChange={handleVoiceSpecificChange}
          />
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <div className="campaign-question-keypress-box">
            <Checkbox
              name="keypress-after-audio"
              label="Allow replies during playback"
              value={(!state.keypressAfterAudio).toString()}
              editable={true}
              onChange={({ value }: { value: string }) => {
                handleChange({
                  keypressAfterAudio: value !== 'true',
                })
              }}
            />
            <div
              style={{
                marginLeft: '24px',
              }}
            >
              <Typography color="textSecondary" variant="caption" gutterBottom={true}>
                {!state.keypressAfterAudio
                  ? 'If disabled, a contact must listen to the complete question before being able to reply. (Any keys pressed while the question is playing will be ignored.)'
                  : 'If enabled, a contact will be able to reply while the question is playing. If they press a key during the question, the question will stop playing, and the reply will be registered.'}
              </Typography>
            </div>
          </div>
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <div className="campaign-question-repeat-box">
            <Checkbox
              name="repeat-question"
              label="Repeat question"
              value={state.checkRepeatQuestion.toString()}
              editable={true}
              onChange={({ value }: { value: string }) => {
                handleChange({
                  checkRepeatQuestion: value === 'true',
                  repeatQuestion: {
                    reply: state.repeatQuestion?.reply || '0',
                  },
                })
              }}
            />
            <div
              style={{
                marginLeft: '24px',
              }}
            >
              <Typography color="textSecondary" variant="caption" gutterBottom={true}>
                {
                  'Specify a key to repeat the question. When the contact presses this key, the question will be repeated once. The keypress will not be counted as a valid or invalid reply.'
                }
              </Typography>
              {state.checkRepeatQuestion && (
                <div
                  style={{
                    backgroundColor: 'rgb(245, 245, 245)',
                    padding: '12px',
                    margin: '12px 0',
                  }}
                >
                  <Select
                    id="campaign-item-repeatQuestion"
                    label="Select key"
                    name="repeat-question"
                    editable={true}
                    value={pathOr('0', ['reply'], state.repeatQuestion)}
                    values={repeatQuestionOptions}
                    style={{
                      width: '10%',
                    }}
                    onChange={({ value }: { value: string }) => {
                      handleChange({
                        repeatQuestion: {
                          reply: value,
                        },
                      })
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <div className="campaign-question-invalid-box">
            <Checkbox
              name="invalid-keypress"
              label="Invalid keypress"
              value={state.checkInvalid.toString()}
              editable={true}
              onChange={({ value }: { value: string }) => {
                const changeRetries = (value !== 'true' && !state.checkTimeout) || !state.retries
                handleChange({
                  checkInvalid: value === 'true',
                  ...(changeRetries && {
                    retries: value === 'true' || state.checkTimeout ? CAMPAIGN_RETRIES : 0,
                  }),
                })
              }}
            />
            <div
              style={{
                marginLeft: '24px',
              }}
            >
              <Typography color="textSecondary" variant="caption" gutterBottom={true}>
                Specify what we should do if the contact presses an invalid key. Afterwards, we will wait for the
                contact to press a key again—until they run out of chances.
              </Typography>
              {state.checkInvalid && (
                <QuestionBox
                  files={files}
                  dragContext={`${DRAGTYPE}-invalid`}
                  actionsList={state.invalidActions}
                  actionsValues={invalidActions}
                  defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
                  changeHandler={({ actionsList }: { actionsList: LocalAction[] }) =>
                    handleChange({
                      invalidActions: actionsList,
                    })
                  }
                  personalizationList={personalizationList}
                  senderIds={senderIds}
                  voiceId={`${id}-invalid`}
                  getImageProps={getImageProps}
                  getVoiceProps={getVoiceProps}
                  getCampaigns={getCampaigns}
                  question={false}
                  invalid={true}
                  orgId={orgId}
                  token={token}
                  campaignIds={campaignIds}
                  timezones={timezones}
                  timezone={timezone}
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  getSnippets={getSnippets}
                  snippets={snippets}
                  saveSnippet={saveSnippet}
                  languages={languages}
                  defaultLanguage={defaultLanguage}
                />
              )}
            </div>
          </div>
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <div className="campaign-question-timeout-box">
            <Checkbox
              name="time-out-no-reply"
              label="Timeout / no reply"
              value={state.checkTimeout.toString()}
              editable={true}
              onChange={({ value }: { value: string }) => {
                const changeRetries = (value !== 'true' && !state.checkInvalid) || !state.retries
                handleChange({
                  checkTimeout: value === 'true',
                  ...(changeRetries && {
                    retries: value === 'true' || state.checkInvalid ? CAMPAIGN_RETRIES : 0,
                  }),
                })
              }}
            />
            <div
              style={{
                marginLeft: '24px',
              }}
            >
              <Typography color="textSecondary" variant="caption" gutterBottom={true}>
                Specify what we should do if the contact does not press a key within a period of time that you specify.
                Afterwards, we will wait for the contact to press a key—until they run out of chances.
              </Typography>
              {state.checkTimeout && (
                <QuestionBox
                  files={files}
                  dragContext={`${DRAGTYPE}-timeout`}
                  actionsList={state.timeoutActions}
                  actionsValues={timeoutActions}
                  defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
                  changeHandler={({ actionsList }: { actionsList: LocalAction[] }) =>
                    handleChange({
                      timeoutActions: actionsList,
                    })
                  }
                  personalizationList={personalizationList}
                  senderIds={senderIds}
                  voiceId={`${id}-timeout`}
                  question={false}
                  getImageProps={getImageProps}
                  getVoiceProps={getVoiceProps}
                  getCampaigns={getCampaigns}
                  orgId={orgId}
                  token={token}
                  campaignIds={campaignIds}
                  timezones={timezones}
                  timezone={timezone}
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  getSnippets={getSnippets}
                  snippets={snippets}
                  saveSnippet={saveSnippet}
                  languages={languages}
                  defaultLanguage={defaultLanguage}
                  info={
                    <>
                      <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
                        <QuestionNumberInput
                          data-testid="timeout-input"
                          beforeText="Take these actions if the contact doesn't press a key after:"
                          afterText="seconds"
                          min={0}
                          value={`${state.timeoutDelay}`}
                          onChange={({ value }: { value: string }) => {
                            debouncedHandleChange({
                              timeoutDelay: Number(value),
                            })
                          }}
                        />
                      </Typography>
                    </>
                  }
                />
              )}
            </div>
          </div>
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <React.Fragment>
            <div>
              <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
                <QuestionNumberInput
                  data-testid="retries-input"
                  min={0}
                  beforeText="Specify the number of times that we should allow the Contact to reply invalidly or time out before we take the Failure actions you specify below:"
                  afterText="additional chances."
                  editable={state.checkInvalid || state.checkTimeout}
                  value={`${state.retries}`}
                  onChange={({ value }: { value: string }) => {
                    debouncedHandleChange({
                      retries: Number(value),
                    })
                  }}
                />
              </Typography>
              {!(state.checkInvalid || state.checkTimeout) && (
                <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
                  Note: To set the number of chances, please first select and specify Actions for Invalid Keypress or
                  Timeout.
                </Typography>
              )}
              {(state.checkInvalid || state.checkTimeout) && (
                <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
                  Note: after we take the Failure actions you specify, the survey will continue, unless you add a
                  "Hangup" action in the Failure section.
                </Typography>
              )}
            </div>
          </React.Fragment>
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <div className="campaign-question-failure-box">
            <Checkbox
              name="failure"
              label="Failure"
              value={state.checkFailure.toString()}
              editable={true}
              onChange={({ value }: { value: string }) => {
                handleChange({
                  checkFailure: value === 'true',
                })
              }}
            />
            <div
              style={{
                marginLeft: '24px',
              }}
            >
              <Typography color="textSecondary" variant="caption" gutterBottom={true}>
                Specify what we should do after the contact runs out of chances.
              </Typography>
              {state.checkFailure && (
                <QuestionBox
                  files={files}
                  dragContext={`${DRAGTYPE}-failure`}
                  actionsList={state.failureActions}
                  actionsValues={failureActions}
                  defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
                  changeHandler={({ actionsList }: { actionsList: LocalAction[] }) =>
                    handleChange({
                      failureActions: actionsList,
                    })
                  }
                  personalizationList={personalizationList}
                  senderIds={senderIds}
                  voiceId={`${id}-failure`}
                  getImageProps={getImageProps}
                  getVoiceProps={getVoiceProps}
                  question={false}
                  getCampaigns={getCampaigns}
                  orgId={orgId}
                  token={token}
                  campaignIds={campaignIds}
                  index={props.index}
                  timezones={timezones}
                  timezone={timezone}
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  getSnippets={getSnippets}
                  snippets={snippets}
                  saveSnippet={saveSnippet}
                  languages={languages}
                  defaultLanguage={defaultLanguage}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {state.answerType === 'speech' && (
        <div className="campaign-speech-tab-content">
          <div className={classes.choiceHeader}>
            <Typography>Choices</Typography>
            {hasSpeechSettings && !!speechLanguages.length && (
              <div className={classes.selectContainer}>
                <CustomTooltip title="Select speech recognition language">
                  <Icon color="primary">interpreter_mode</Icon>
                </CustomTooltip>
                <MuiSelect
                  variant="standard"
                  id="speech-language-select"
                  style={{
                    marginLeft: '10px',
                    width: '200px',
                  }}
                  value={activeSpeechLang}
                  renderValue={(selected) => {
                    const found = find(propEq<string>('value', selected), speechLanguages)
                    if (found) {
                      return (
                        <Typography>
                          {found.name}
                          {found.value === defaultSpeechLang && ' (default)'}
                        </Typography>
                      )
                    }
                    return <Typography>Select a language</Typography>
                  }}
                  onChange={(e: any) => {
                    setActiveSpeechLang(e.target.value as string)
                  }}
                >
                  {speechLanguages.map(({ name, value }) => (
                    <MenuItem
                      className="speech-language-select-item"
                      key={value}
                      value={value}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <Typography
                        style={{
                          marginRight: '5px',
                        }}
                      >
                        {name}
                        {defaultSpeechLang === value && ' (default)'}
                      </Typography>
                    </MenuItem>
                  ))}
                </MuiSelect>
              </div>
            )}
          </div>
          <div className="campaign-speech-choices">
            <Typography color="textSecondary" variant="caption" gutterBottom={true}>
              {
                'Specify the words that the contact can say to answer the question and what actions we should take for each choice.'
              }
            </Typography>
            {state.onAnswer.map(
              (
                {
                  speechReplies = {},
                  actions,
                  label = '',
                }: { speechReplies: SpeechRepliesType; actions: string[]; label: string },
                i: number,
              ) => (
                <QuestionBox
                  key={i}
                  files={files}
                  dragContext={`${DRAGTYPE}-choice-${i}`}
                  choiceList={{
                    replies: activeSpeechLang ? speechReplies[activeSpeechLang] || [] : [],
                    speech: true,
                  }}
                  label={label}
                  ChoiceTag={QuestionChoiceTag}
                  actionsList={actions}
                  actionsValues={answerActions}
                  defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
                  changeHandler={({
                    actionsList,
                    choiceList,
                    label: choiceLabel,
                  }: {
                    actionsList: LocalAction[]
                    choiceList: ChoiceListType
                    label: string
                  }) => {
                    if (!activeSpeechLang) {
                      return
                    }

                    handleChange({
                      spoken: undefined,
                      onAnswer: update(
                        i,
                        {
                          speechReplies: {
                            ...speechReplies,
                            [activeSpeechLang]: [...(choiceList?.replies || speechReplies[activeSpeechLang] || [])],
                          },
                          actions: actionsList || pathOr([emptyAction], [i, 'actions'], state.onAnswer),
                          label:
                            choiceLabel === '' || choiceLabel ? choiceLabel : pathOr('', [i, 'label'], state.onAnswer),
                        },
                        state.onAnswer,
                      ),
                    })
                  }}
                  personalizationList={personalizationList}
                  senderIds={senderIds}
                  voiceId={`${id}-${i}`}
                  getImageProps={getImageProps}
                  getVoiceProps={getVoiceProps}
                  getCampaigns={getCampaigns}
                  orgId={orgId}
                  token={token}
                  campaignIds={campaignIds}
                  question={true}
                  index={props.index}
                  onClose={() => {
                    handleChange({
                      onAnswer: remove(i, 1, state.onAnswer),
                    })
                  }}
                  timezones={timezones}
                  timezone={timezone}
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  getSnippets={getSnippets}
                  snippets={snippets}
                  saveSnippet={saveSnippet}
                  languages={languages}
                  defaultLanguage={defaultLanguage}
                />
              ),
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CampaignAddButton
                tooltip="Add choice"
                onClick={() => {
                  handleChange({
                    onAnswer: append(
                      {
                        speechReplies: reduce(
                          (acc, language) => ({
                            ...acc,
                            [language.value]: [],
                          }),
                          {},
                          speechLanguages,
                        ),
                        actions: [emptyAction],
                        label: '',
                      },
                      state.onAnswer,
                    ),
                  })
                }}
                text="Add choice"
                icon="add"
              />
            </div>
          </div>
          <div>
            <Checkbox
              name="time-out-no-reply"
              label="Timeout / no reply"
              value={state.checkTimeout.toString()}
              editable={true}
              onChange={({ value }: { value: string }) => {
                const changeRetries = (value !== 'true' && !state.checkInvalid) || !state.retries
                handleChange({
                  checkTimeout: value === 'true',
                  ...(changeRetries && {
                    retries: value === 'true' || state.checkInvalid ? CAMPAIGN_RETRIES : 0,
                  }),
                })
              }}
            />
            <div
              style={{
                marginLeft: '24px',
              }}
            >
              <Typography color="textSecondary" variant="caption" gutterBottom={true}>
                Specify what we should do if the contact does not speak anything which contains a choice within a period
                of time that you specify. Afterwards, we will wait for the contact to speak until they run out of
                chances.
              </Typography>
              {state.checkTimeout && (
                <QuestionBox
                  files={files}
                  dragContext={`${DRAGTYPE}-timeout`}
                  actionsList={state.timeoutActions}
                  actionsValues={timeoutActions}
                  defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
                  changeHandler={({ actionsList }: { actionsList: LocalAction[] }) =>
                    handleChange({
                      timeoutActions: actionsList,
                    })
                  }
                  personalizationList={personalizationList}
                  senderIds={senderIds}
                  voiceId={`${id}-timeout`}
                  question={false}
                  getImageProps={getImageProps}
                  getVoiceProps={getVoiceProps}
                  getCampaigns={getCampaigns}
                  orgId={orgId}
                  token={token}
                  campaignIds={campaignIds}
                  timezones={timezones}
                  timezone={timezone}
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  getSnippets={getSnippets}
                  snippets={snippets}
                  saveSnippet={saveSnippet}
                  languages={languages}
                  defaultLanguage={defaultLanguage}
                  info={
                    <>
                      <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
                        <QuestionNumberInput
                          data-testid="timeout-input"
                          beforeText="Take these actions if the contact doesn't speak anything which contains a choice: "
                          afterText="seconds"
                          min={0}
                          value={`${state.timeoutDelay}`}
                          onChange={({ value }: { value: string }) => {
                            debouncedHandleChange({
                              timeoutDelay: Number(value),
                            })
                          }}
                        />
                      </Typography>
                    </>
                  }
                />
              )}
            </div>
          </div>
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <React.Fragment>
            <div>
              <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
                {
                  'Specify the number of times that we should allow the Contact to reply invalidly or time out before we take the Failure actions you specify below: '
                }
                <CustomTooltip
                  title={
                    state.checkInvalid || state.checkTimeout
                      ? 'To set the number of chances, please first select and specify Actions for Invalid Keypress or Timeout.'
                      : undefined
                  }
                >
                  <QuestionNumberInput
                    data-testid="retries-input"
                    min={0}
                    editable={state.checkInvalid || state.checkTimeout}
                    value={`${state.retries}`}
                    onChange={({ value }: { value: string }) => {
                      debouncedHandleChange({
                        retries: Number(value),
                      })
                    }}
                  />
                </CustomTooltip>
                {' additional chances.'}
              </Typography>
              {!(state.checkInvalid || state.checkTimeout) && (
                <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
                  Note: To set the number of chances, please first select and specify Actions for Invalid Keypress or
                  Timeout.
                </Typography>
              )}
              {(state.checkInvalid || state.checkTimeout) && (
                <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
                  Note: after we take the Failure actions you specify, the survey will continue, unless you add a
                  "Hangup" action in the Failure section.
                </Typography>
              )}
            </div>
          </React.Fragment>
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <div>
            <Checkbox
              name="failure"
              label="Failure"
              value={state.checkFailure.toString()}
              editable={true}
              onChange={({ value }: { value: string }) => {
                handleChange({
                  checkFailure: value === 'true',
                })
              }}
            />
            <div
              style={{
                marginLeft: '24px',
              }}
            >
              <Typography color="textSecondary" variant="caption" gutterBottom={true}>
                Specify what we should do after the contact runs out of chances.
              </Typography>
              {state.checkFailure && (
                <QuestionBox
                  files={files}
                  dragContext={`${DRAGTYPE}-failure`}
                  actionsList={state.failureActions}
                  actionsValues={failureActions}
                  defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
                  changeHandler={({ actionsList }: { actionsList: LocalAction[] }) =>
                    handleChange({
                      failureActions: actionsList,
                    })
                  }
                  personalizationList={personalizationList}
                  senderIds={senderIds}
                  voiceId={`${id}-failure`}
                  getImageProps={getImageProps}
                  getVoiceProps={getVoiceProps}
                  question={false}
                  getCampaigns={getCampaigns}
                  orgId={orgId}
                  token={token}
                  campaignIds={campaignIds}
                  index={props.index}
                  timezones={timezones}
                  timezone={timezone}
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  getSnippets={getSnippets}
                  snippets={snippets}
                  saveSnippet={saveSnippet}
                  languages={languages}
                  defaultLanguage={defaultLanguage}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {state.answerType === 'spoken' && (
        <div className="campaign-spoken-tab-content">
          <Typography>Spoken Response</Typography>
          <div>
            <Typography color="textSecondary" variant="caption" gutterBottom={true}>
              Specify what actions we should take after recording
            </Typography>
            <QuestionBox
              files={files}
              dragContext={`${DRAGTYPE}-choice-spoken`}
              hasTranscribe={hasTranscribe}
              spoken={state.spoken}
              repeatQuestionOptions={repeatQuestionOptions}
              actionsList={pathOr([], ['actions'], state.spoken)}
              actionsValues={answerActions}
              defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
              changeHandler={({
                spoken: newSpoken,
                actionsList,
              }: {
                spoken?: SpokenType
                actionsList: LocalAction[]
              }) => {
                const currentActionsList = pathOr([emptyAction], ['actions'], state.spoken)
                const actions = actionsList || currentActionsList
                const currentMaxSeconds = pathOr(3, ['maxSeconds'], state.spoken)
                const maxSeconds = pathOr(currentMaxSeconds, ['maxSeconds'], newSpoken || {})
                const currentSilence = pathOr(3, ['silence'], state.spoken)
                const silence = pathOr(currentSilence, ['silence'], newSpoken || {})
                const currentStopKey = pathOr('#', ['stopKey'], state.spoken)
                const stopKey = pathOr(currentStopKey, ['stopKey'], newSpoken || {})
                const currentTranscribe = pathOr(false, ['transcribe'], state.spoken)
                const transcribe = pathOr(currentTranscribe, ['transcribe'], newSpoken || {})
                handleChange({
                  spoken: {
                    actions,
                    maxSeconds,
                    silence,
                    stopKey,
                    transcribe,
                  },
                })
              }}
              personalizationList={personalizationList}
              senderIds={senderIds}
              transcribeEnabled={state.spoken.transcribe}
              voiceId={`${id}-0`}
              getImageProps={getImageProps}
              getVoiceProps={getVoiceProps}
              getCampaigns={getCampaigns}
              orgId={orgId}
              token={token}
              campaignIds={campaignIds}
              isCloseDisabled={true}
              index={props.index}
              timezones={timezones}
              timezone={timezone}
              countriesTimezones={countriesTimezones}
              countryCode={countryCode}
              getSnippets={getSnippets}
              snippets={snippets}
              saveSnippet={saveSnippet}
              languages={languages}
              defaultLanguage={defaultLanguage}
            />
          </div>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  choiceHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const getPartActions = ({ campaignType, partType }: { campaignType: CampaignTemplateType; partType: PartFamily }) => {
  const answerActions = getActionOptionsForCampaign({
    campaignType,
    partType,
    actionNamespace: ActionContext.OnAnswer,
  })

  const timeoutActions = getActionOptionsForCampaign({
    campaignType,
    partType,
    actionNamespace: ActionContext.OnTimeout,
  })

  const invalidActions = getActionOptionsForCampaign({
    campaignType,
    partType,
    actionNamespace: ActionContext.OnInvalid,
  })

  const failureActions = getActionOptionsForCampaign({
    campaignType,
    partType,
    actionNamespace: ActionContext.OnFailure,
  })

  return {
    answerActions,
    timeoutActions,
    invalidActions,
    failureActions,
  }
}

const fromProps = ({
  keypressAfterAudio = false,
  retries,
  onAnswer = [],
  onInvalidReply,
  onTimeout,
  repeatQuestion,
  onRetriesExhausted,
  spoken,
  speechSettings,
  ...restItem
}: ItemType) => {
  const checkFailure = !!(onRetriesExhausted?.enabled || onRetriesExhausted?.actions?.length)
  const checkInvalid = !!(onInvalidReply?.enabled || onInvalidReply?.actions?.length)
  const checkTimeout = !!(
    onTimeout?.enabled ||
    onTimeout?.actions?.length ||
    onTimeout?.timeoutSeconds ||
    speechSettings?.timeoutSeconds
  )
  const checkRepeatQuestion = !!(repeatQuestion?.enabled || repeatQuestion?.reply)
  const defaultRetries = retries === undefined ? CAMPAIGN_RETRIES : retries
  const timeoutDelay =
    speechSettings?.timeoutSeconds || (pathOr(TIMEOUT_DEFAULT, ['timeoutSeconds'], onTimeout) as number)
  const showSpoken = !!spoken

  let answerType = 'keypress'
  if (speechSettings) {
    answerType = 'speech'
  } else if (spoken) {
    answerType = 'spoken'
  }

  return {
    ...restItem,
    keypressAfterAudio,
    retries: checkInvalid || checkTimeout ? defaultRetries : 0,
    checkFailure,
    checkInvalid,
    checkTimeout,
    checkRepeatQuestion,
    showSpoken,
    answerType,
    ...(showSpoken
      ? {
          spoken: {
            ...(spoken || {}),
            actions: unwrapActions(pathOr([], ['actions'], spoken)),
          },
        }
      : {
          spoken: null,
        }),
    onAnswer:
      onAnswer !== null && !isEmpty(onAnswer)
        ? onAnswer.map((item: ItemType) => ({
            ...item,
            actions: unwrapActions(pathOr([], ['actions'], item)),
          }))
        : [
            {
              replies: ['1'],
              ranges: [],
              actions: [emptyAction],
              label: '',
            },
            {
              replies: ['2'],
              ranges: [],
              actions: [emptyAction],
              label: '',
            },
          ],
    repeatQuestion,
    invalidActions: unwrapActions(pathOr([], ['actions'], onInvalidReply)),
    timeoutActions: unwrapActions(pathOr([], ['actions'], onTimeout)),
    failureActions: unwrapActions(pathOr([], ['actions'], onRetriesExhausted)),
    timeoutDelay: timeoutDelay === 0 ? TIMEOUT_DEFAULT : timeoutDelay,
    speechSettings,
  }
}

const backToProps = ({
  keypressAfterAudio,
  retries = 0,
  checkInvalid,
  checkTimeout,
  repeatQuestion,
  checkRepeatQuestion,
  checkFailure,
  onAnswer,
  invalidActions,
  timeoutActions,
  failureActions,
  timeoutDelay,
  spoken,
  speechSettings,
  ...restItem
}: ItemType) => ({
  ...restItem,
  keypressAfterAudio,
  retries,
  ...(spoken
    ? {
        spoken: {
          ...spoken,
          actions: wrapActions(pathOr([], ['actions'], spoken)),
        },
      }
    : {
        spoken: undefined,
      }),
  onAnswer: spoken
    ? []
    : onAnswer.map((item: ItemType) => ({
        ...item,
        actions: wrapActions(pathOr([], ['actions'], item)),
      })),
  onInvalidReply: {
    enabled: checkInvalid,
    actions: checkInvalid ? wrapActions(invalidActions) : [],
  },
  onTimeout: {
    enabled: checkTimeout,
    timeoutSeconds: checkTimeout ? timeoutDelay : undefined,
    actions: checkTimeout ? wrapActions(timeoutActions) : [],
  },
  repeatQuestion: {
    enabled: checkRepeatQuestion,
    ...(checkRepeatQuestion && repeatQuestion),
  },
  onRetriesExhausted: {
    enabled: checkFailure,
    actions: checkFailure ? wrapActions(failureActions) : [],
  },
  speechSettings: speechSettings
    ? {
        ...speechSettings,
        timeoutSeconds: checkTimeout ? timeoutDelay : undefined,
      }
    : undefined,
})
