import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Icon from '@mui/material/Icon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { find, pathOr, propEq } from 'ramda'
import React, { useMemo } from 'react'
import { numberWithCommas } from '@/app/service/util/format'
import Tooltip from '@/app/component/atom/tooltip'

type FilterData = {
  label: string
  value: string
}

type SelectAllProps = {
  activeFilter?: {
    name: string
    value: string | number
  }
  filters: Record<
    string,
    {
      values: Record<
        string,
        {
          data: FilterData[]
        }
      >
    }
  >
  search: {
    term: string
  }
  selection: {
    groups: number[]
    search: string
    list: number[]
    isAll: boolean
    addGroup: (groups: number[], total: number) => void
    addSearch: (search: string, total: number) => void
    setAll: (isAll: boolean) => void
    add: (list: string[]) => void
    clearSelection: () => void
    count: number
    headerIcon: string
  }
  pagination: {
    total: number
  }
  ordered: string[]
  orgTotal: number
}

const getFilter = (activeFilter: SelectAllProps['activeFilter'], filters: SelectAllProps['filters']) => {
  if (activeFilter) {
    return find(
      propEq('value', activeFilter.value),
      pathOr<FilterData[]>([], ['data', activeFilter.name || '', 'values'], filters),
    )
  }
  return undefined
}

export default function SelectAll({
  activeFilter,
  filters,
  search: searchFilter,
  selection,
  pagination,
  ordered,
  orgTotal,
}: SelectAllProps) {
  const filter = getFilter(activeFilter, filters)
  const { groups, search, list, isAll, addGroup, addSearch, setAll, add, clearSelection } = selection
  const { total = 0 } = pagination
  const { term = '' } = searchFilter
  const [menu, setMenu] = React.useState<HTMLDivElement | null>(null)

  const selectFilteredLabel = useMemo(() => {
    if (!filter && !term) {
      return ''
    }

    if (filter && term) {
      return 'Select filtered contacts'
    }

    return `Select all ${filter ? `in "${filter.label}"` : `for "${term}"`}`
  }, [filter, term])

  return (
    <div>
      <div style={{ display: 'flex', maxWidth: '96px' }}>
        <div onClick={(e) => setMenu(e.currentTarget)}>
          <Tooltip title="Select Multiple">
            <Checkbox
              color="primary"
              checked={selection.count > 0}
              checkedIcon={<Icon>{selection.headerIcon}</Icon>}
              data-testid="select-multiple-checkbox"
              onClick={() => {}}
            />
          </Tooltip>
        </div>
      </div>
      <Menu open={!!menu} anchorEl={menu} onClose={() => setMenu(null)} style={{ marginLeft: '30px' }}>
        <MenuItem
          disabled={!menu}
          onClick={() => {
            setMenu(null)
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!isAll && groups.length === 0 && search === '' && list.length > 0}
                onChange={(e) => {
                  if (e.target.checked) {
                    add(ordered)
                  } else {
                    clearSelection()
                  }
                }}
                name="shown"
                indeterminate={
                  !isAll && groups.length === 0 && search === '' && ordered.length !== list.length && list.length > 0
                }
              />
            }
            label={`Select shown (${ordered.length})`}
          />
        </MenuItem>
        {(filter || term !== '') && (
          <MenuItem
            disabled={!menu}
            onClick={() => {
              setMenu(null)
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={groups.length > 0 || search !== ''}
                  onChange={(e) => {
                    if (e.target.checked) {
                      if (activeFilter?.value && activeFilter.value !== 0) {
                        addGroup([Number(activeFilter.value)], total)
                        if (term) {
                          addSearch(term, total)
                        }
                      } else {
                        addSearch(term, total)
                      }
                    } else {
                      clearSelection()
                    }
                  }}
                  name="group"
                  indeterminate={(groups.length > 0 || search !== '') && list.length > 0}
                />
              }
              label={`${selectFilteredLabel} (${numberWithCommas(total)})`}
            />
          </MenuItem>
        )}
        <MenuItem
          disabled={!menu}
          onClick={() => {
            setMenu(null)
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={isAll}
                onChange={(e) => {
                  setAll(e.target.checked)
                }}
                name="all"
                indeterminate={isAll && list.length > 0}
              />
            }
            label={`Select all Contacts (${numberWithCommas(orgTotal)})`}
          />
        </MenuItem>
      </Menu>
    </div>
  )
}
