import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { selectToken } from '@/app/module/auth/store/selectors'
import { getCampaigns } from '@/app/module/campaigns/store/actions'
import { selectCampaignIds, selectSMSSenderIdValues } from '@/app/module/campaigns/store/selectors'
import { getCustomFields } from '@/app/module/custom-fields/store/actions'
import { getSenderIdList } from '@/app/module/logs/store/actions'
import { selectPersonalizationList } from '@/app/module/logs/store/selectors'
import { getSnippets, saveSnippet } from '@/app/module/user/store/actions'
import { selectOrgId, selectUserId, selectUserTimezone } from '@/app/module/user/store/selectors'
import { selectCountriesTimezones, selectDefaultCountryCode } from '@/app/module/utils/store/selectors'
import PhoneNumbers from './component'
import Item from './component/item'
import {
  addCallHook,
  addHook,
  addWhatsappHook,
  deleteCallHook,
  deleteHook,
  deleteWhatsappHook,
  getPhoneNumber,
  getPhoneNumbers,
  getPhonenumbersFile,
  onError,
  reloadPhoneNumbers,
  savePhonenumbersFile,
  updateCallHook,
  updateHook,
  updatePhoneNumber,
  updateWhatsappHook,
} from './store/actions'
import {
  selectCallHook,
  selectHooks,
  selectHooksAsObject,
  selectLoading,
  selectPhoneNumber,
  selectWhatsappHooks,
  selectWhatsappHooksAsObject,
} from './store/selectors'

const selector = ({ auth, user, phoneNumbers }) => ({
  error: phoneNumbers.error,
  phoneNumbers: pathOr('', ['data', 'phoneNumbers'], phoneNumbers),
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  personalizationList: {},
  userId: selectUserId({ user }),
  hydrateProps: ['token', 'orgId'],
})

const dispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId, query }) =>
        () =>
          Promise.all([dispatch(getPhoneNumbers({ token, orgId, query }))]),
      getPhoneNumbers,
      reloadPhoneNumbers,
    },
    dispatch,
  )

export default connect(selector, dispatcher)(withRouter(PhoneNumbers))

const itemSelector = ({ auth, user, phoneNumbers, customFields, logs, campaigns, utils }) => ({
  hydrateProps: ['token', 'orgId', 'phoneNumberId'],
  token: selectToken({ auth }),
  error: pathOr({}, ['item', 'error'], phoneNumbers),
  orgId: selectOrgId({ user }),
  personalizationList: selectPersonalizationList({ customFields }),
  callHook: selectCallHook(phoneNumbers),
  hooks: selectHooksAsObject(phoneNumbers),
  hooksOrdered: selectHooks(phoneNumbers),
  whatsappHooks: selectWhatsappHooksAsObject(phoneNumbers),
  whatsappHooksOrdered: selectWhatsappHooks(phoneNumbers),
  userId: selectUserId({ user }),
  isLoading: selectLoading({ phoneNumbers }),
  phoneNumber: selectPhoneNumber(phoneNumbers),
  senderIds: selectSMSSenderIdValues({ logs }),
  campaignIds: selectCampaignIds({ campaigns }),
  files: {
    loadingItems: pathOr([], ['files', 'loadingItems'], phoneNumbers),
    data: pathOr({}, ['files', 'data'], phoneNumbers),
  },
  timezone: selectUserTimezone({ user, utils }),
  timezones: pathOr({}, ['countriesTimezonesList', 'data'], utils),
  countriesTimezones: selectCountriesTimezones({ utils }),
  countryCode: selectDefaultCountryCode({ utils }),
  snippets: pathOr({}, ['snippets'], user),
})

const itemDispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId, phoneNumberId }) =>
        () =>
          Promise.all([
            dispatch(getSenderIdList({ token, orgId })),
            dispatch(getPhoneNumber({ token, orgId, phoneNumberId })),
            dispatch(getCustomFields({ token, orgId, phoneNumberId })),
            dispatch(
              getCampaigns({
                token,
                orgId,
                query: {
                  templates: 'ivr',
                  page: 1,
                  size: 100,
                },
                aborted: 'false',
                archived: 'false',
              }),
            ),
          ]),
      getSenderIdList,
      getCampaigns,
      updatePhoneNumber,
      getPhoneNumber,
      addHook,
      updateHook,
      deleteHook,
      addWhatsappHook,
      updateWhatsappHook,
      deleteWhatsappHook,
      addCallHook,
      updateCallHook,
      deleteCallHook,
      onError,
      getFile: getPhonenumbersFile,
      saveFile: savePhonenumbersFile,
      getSnippets,
      saveSnippet,
    },
    dispatch,
  )

export const PhoneNumberItem = connect(itemSelector, itemDispatcher)(withRouter(Item))
