import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { path } from 'ramda'
import { Component } from 'react'
import { withStyles } from 'tss-react/mui'
import AudioAnalyser from './analyser'
import AudioCounter from './counter'
import AudioPlayerAction from './player-action'
import AudioWrapper from './wrapper'

type Classes = {
  sliderThumb?: string
  sliderThumbDisabled?: string
  sliderTrackBefore?: string
  sliderTrackAfter?: string
}

type Props = {
  className?: string
  classes?: Classes
  id: string
  loading: boolean
  name: string
  playId?: string
  source?: {
    name?: string
    url?: string
  }

  onPlayToggle: (isPlaying: boolean) => void
  onStop: () => void
}

type State = {
  current: moment.Duration
  length: moment.Duration
  isDragging: boolean
  isLoading: boolean
  isPlaying: boolean
  progress: number
}

class AudioPlayer extends Component<Props, State> {
  analyser: AnalyserNode | undefined = undefined

  audio: HTMLAudioElement | undefined = undefined

  playPromise: Promise<void> | undefined = undefined

  rafId = 0

  state = {
    current: moment.duration(0, 'seconds'),
    length: moment.duration(0, 'seconds'),
    isDragging: false,
    isLoading: false,
    isPlaying: false,
    progress: 0,
  }

  componentWillUnmount() {
    if (this.audio) {
      this.audio.pause()
    }
    if (this.rafId) {
      cancelAnimationFrame(this.rafId)
    }
    if (this.analyser) {
      this.analyser.disconnect()
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { id, playId } = this.props

    if (playId && id !== playId) {
      this.pause()
    }

    if (path(['source', 'url'], prevProps) !== path(['source', 'url'], this.props)) {
      const cb = () => {
        this.pause()
      }
      if (this.playPromise === undefined) {
        cb()
      } else {
        this.playPromise.then(cb)
      }

      this.setState({
        current: moment.duration(0, 'seconds'),
        progress: 0,
      })
      this.setAudio()
    }
  }

  setAudio() {
    const { source = {}, onStop = () => {} } = this.props

    if (source.url) {
      this.setState({
        isLoading: true,
      })

      this.audio = new Audio(source.url)
      this.audio.crossOrigin = 'anonymous'
      this.audio.volume = 1
      const context = new AudioContext()
      this.analyser = context.createAnalyser()
      const src = context.createMediaElementSource(this.audio)
      src.connect(this.analyser)
      src.connect(context.destination)

      this.audio.addEventListener('loadedmetadata', () => {
        this.setState({
          isLoading: false,
        })
        if (!this.audio) {
          return
        }
        this.setState({
          // due to chromium bug:
          // https://bugs.chromium.org/p/chromium/issues/detail?id=642012
          // status sadly is 'WontFix'
          length:
            this.audio.duration === Infinity ? this.state.length : moment.duration(this.audio.duration, 'seconds'),
        })
      })

      this.audio.addEventListener('timeupdate', () => {
        this.rafId = requestAnimationFrame(() => {
          if (!this.audio) {
            return
          }
          const { isDragging, length } = this.state
          if (!isDragging) {
            this.setState({
              current: moment.duration(this.audio.currentTime, 'seconds'),
              progress: (100 * this.audio.currentTime) / this.audio.duration,
              length: length || moment.duration(this.audio.duration, 'seconds'),
            })
          }
        })
      })

      this.audio.addEventListener('ended', () => {
        onStop()
        this.setState({
          isPlaying: false,
        })
      })
    }

    if (this.state.isPlaying) {
      this.playPromise = this.audio?.play()
    }
  }

  play() {
    // only load audio onPlay if there is none
    if (!this.audio) {
      this.setAudio()
    }

    const { onPlayToggle } = this.props

    const hasEnded = this.audio && this.audio.currentTime === this.audio.duration

    onPlayToggle(true)

    this.setState({
      isPlaying: true,
      current: hasEnded ? moment.duration(0, 'seconds') : this.state.current,
      progress: hasEnded ? 0 : this.state.progress,
    })

    if (this.audio) {
      this.playPromise = this.audio.play()
    }
  }

  pause() {
    const { onPlayToggle } = this.props

    onPlayToggle(false)
    const cb = () => {
      if (this.audio) {
        this.audio.pause()
        this.setState({
          isPlaying: false,
        })
      }
    }
    if (this.playPromise === undefined) {
      cb()
    } else {
      this.playPromise.then(cb)
    }
  }

  render() {
    const classes = withStyles.getClasses(this.props)
    const { name, source = {}, loading } = this.props

    return (
      <AudioWrapper loading={loading || (!this.audio && this.state.isPlaying) || this.state.isLoading}>
        <div>
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
            }}
          >
            <AudioAnalyser isPlaying={!!this.audio && this.state.isPlaying} analyser={this.analyser} />
          </div>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              color: '#2f80ac',
            }}
          >
            <Slider
              classes={{
                thumb: !this.audio ? classes.sliderThumbDisabled : classes.sliderThumb,
                track: classes.sliderTrackBefore,
                rail: classes.sliderTrackAfter,
              }}
              min={0}
              max={100}
              step={1}
              value={this.state.progress}
              disabled={!this.audio}
              onChange={(e, v) => {
                const value = v as number // we know it's a number since the value props is a number

                this.setState({
                  current: moment.duration(Math.floor((value * this.state.length.asSeconds()) / 100), 'seconds'),
                  isDragging: true,
                  progress: value,
                })
              }}
              onChangeCommitted={() => {
                if (this.audio) {
                  this.audio.currentTime = (this.state.progress * this.audio.duration) / 100
                  this.setState({
                    isDragging: false,
                  })
                }
              }}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '18px',
              left: '24px',
              width: '40px',
              zIndex: '1',
              transition: 'transform 0.2s, margin 0.2s',
              transform: this.state.isPlaying ? 'scale(0.8)' : 'scale(1)',
              marginTop: this.state.isPlaying ? '18px' : '0',
            }}
          >
            <AudioPlayerAction
              isPlaying={this.state.isPlaying}
              isDisabled={!source}
              onAction={() => {
                if (this.state.isPlaying) {
                  this.pause()
                  return
                }
                this.play()
              }}
            />
          </div>
          <Typography
            className="audio-file-name"
            style={{
              position: 'absolute',
              bottom: '8px',
              left: '72px',
            }}
          >
            {name || source.name}
          </Typography>
          <AudioCounter current={moment.utc(this.state.current.asMilliseconds()).format('mm:ss')} />
        </div>
      </AudioWrapper>
    )
  }
}

export default withStyles(AudioPlayer, (theme) => ({
  sliderThumb: {
    width: '3px !important',
    height: '76px !important',
    border: 'none',
    borderRadius: 0,
    cursor: 'col-resize',
    backgroundColor: theme.palette.primary.main,
    marginLeft: 0,
  },
  sliderThumbDisabled: {
    opacity: '0',
  },
  sliderTrackBefore: {
    borderRadius: 0,
    height: '75px',
    opacity: '0.1',
  },
  sliderTrackAfter: {
    height: '75px',
    opacity: '0',
  },
}))
