import { reduce, trim } from 'ramda'
import {
  WA_BUTTON_TITLE_LENGTH,
  WA_LIST_BUTTON_LENGTH,
  WA_LIST_DESCRIPTION_LENGTH,
  WA_LIST_TITLE_LENGTH,
} from './definitions'
import { TWhatsAppButtonItem, TWhatsAppButtons, TWhatsAppList, TWhatsAppListItem } from './types'

export const validateWhatsAppButtons = (onButtons: TWhatsAppButtons) => {
  if (!onButtons.buttons?.length) {
    return '- has no buttons'
  }
  const hasTitle = reduce(
    (accum: boolean, { title }: TWhatsAppButtonItem) => accum && !!trim(title),
    true,
    onButtons.buttons,
  )
  if (!hasTitle) {
    return '- all buttons must have a title'
  }
  const hasLongTitle = reduce(
    (accum: boolean, { title }: TWhatsAppButtonItem) => accum && title.length > WA_BUTTON_TITLE_LENGTH,
    true,
    onButtons.buttons,
  )
  if (hasLongTitle) {
    return `- buttons title can only be ${WA_BUTTON_TITLE_LENGTH} characters maximum`
  }

  return ''
}

export const validateWhatsAppList = (onList: TWhatsAppList) => {
  if (!trim(onList.button)) {
    return '- has no button text'
  }
  if (onList.button.length > WA_LIST_BUTTON_LENGTH) {
    return `- button text can only be ${WA_LIST_BUTTON_LENGTH} characters maximum`
  }
  if (!onList.items?.length) {
    return '- has no list options'
  }
  const hasTitle = reduce((accum: boolean, { title }: TWhatsAppListItem) => accum && !!trim(title), true, onList.items)
  if (!hasTitle) {
    return '- all options must have a title'
  }
  const hasLongTitle = reduce(
    (accum: boolean, { title }: TWhatsAppListItem) => accum && title.length > WA_LIST_TITLE_LENGTH,
    true,
    onList.items,
  )
  if (hasLongTitle) {
    return `- options title can only be ${WA_LIST_TITLE_LENGTH} characters maximum`
  }
  const hasLongDescription = reduce(
    (accum: boolean, { description }: TWhatsAppListItem) => accum && description?.length > WA_LIST_DESCRIPTION_LENGTH,
    true,
    onList.items,
  )

  if (hasLongDescription) {
    return `- options description can only be ${WA_LIST_DESCRIPTION_LENGTH} characters maximum`
  }

  return ''
}
