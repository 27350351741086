import FormControl from '@mui/material/FormControl'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

type Props = {
  'data-testid': string
  id?: string
  searchTerm: string

  onChange: (value: string) => void
}

const SearchBar: React.FC<Props> = ({ searchTerm, onChange, ...props }) => {
  const { classes } = useStyles()

  return (
    <FormControl size="small" variant="outlined" className={classes.groupSearchInput}>
      <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
      <OutlinedInput
        endAdornment={
          <InputAdornment position="end">
            {searchTerm && (
              <IconButton
                aria-label="clear search"
                onClick={() => {
                  onChange('')
                }}
                edge="end"
              >
                <Icon>close</Icon>
              </IconButton>
            )}
          </InputAdornment>
        }
        label="Search"
        size="small"
        value={searchTerm}
        onChange={(e) => {
          onChange(e.currentTarget.value)
        }}
        {...props}
      />
    </FormControl>
  )
}

const useStyles = makeStyles()(() => ({
  groupSearchInput: {
    width: '100%',
  },
}))

export default SearchBar
