import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { append, isEmpty, map, omit, path, pathOr, remove, update } from 'ramda'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Radio } from '@/app/component/atom/form'
import { CountryTimezone, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'
import { ContactGroup } from '@/app/module/contacts/types'
import { SMS_ACTION_DEFAULT_SENDERID } from '@/app/module/campaigns/definitions'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { unwrapActions } from '@/app/module/campaigns/utils/actions/wrap'
import { processConditions } from '@/app/module/campaigns/store/selectors'
import {
  CampaignQuery,
  CampaignTemplateType,
  ConditionActions,
  ImageProps,
  Language,
  PersonalizationType,
  SenderIdOption,
  SnippetType,
  VoiceProps,
} from '@/app/module/campaigns/types'
import QuestionBox from '@/app/module/campaigns/component/item/steps/content/question'
import Conditions from '@/app/module/campaigns/component/item/steps/content/types/multimessage/conditions'

const DEFAULT_ATTEMPTS = 5
const MAX_ATTEMPTS_ON_PROGESS = 15
const MAX_ATTEMPTS = 5

const useStyle = makeStyles()({
  radioCont: {
    marginTop: '20px',
  },
  protip: {
    color: '#2f80ac',
  },
})

type keyValue = {
  label: string
  value: any
}

type RadioValues = keyValue[]

const reconnectValues: RadioValues = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
]

const reconnectOnProgressValues: RadioValues = [
  {
    label: 'on survey progress',
    value: 'true',
  },
  {
    label: 'on calls longer than 10 seconds',
    value: 'false',
  },
]

const reconnectDefaultValues: RadioValues = [
  {
    label: `Use the default (${DEFAULT_ATTEMPTS})`,
    value: 'true',
  },
  {
    label: 'Let me decide',
    value: 'false',
  },
]

type Props = {
  actions: ConditionActions[]
  campaignIds: SelectOption<number>[]
  campaignType: CampaignTemplateType
  countriesTimezones: CountryTimezone[]
  countryCode: string
  defaultLanguage: string
  groups: {
    data: Record<string, Pick<ContactGroup, 'name' | 'createdAt' | 'numberOfContacts'>>
    ordered: string[]
  }
  languages: Language[]
  orgId: number
  personalizationList: PersonalizationType
  reconnectMax: number
  reconnectOnProgress: boolean
  segments: {
    data: Record<string, Pick<ContactGroup, 'name' | 'createdAt' | 'numberOfContacts'>>
    ordered: string[]
  }
  senderIds: SenderIdOption[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  timezone: string
  timezones: TimezoneWithCountryCodeType
  token: string

  changeHandler: (payload: { value: ConditionActions[] }) => void
  getCampaigns: (payload: { orgId: number; token: string; query: CampaignQuery }) => Promise<void>
  getImageProps: (qid: string) => ImageProps
  getSnippets: (payload: { token: string; orgId: number }) => Promise<void>
  getVoiceProps: (qid: string) => VoiceProps
  onChange: (value: { reconnectMax: number; reconnectOnProgress: boolean }) => void
  saveSnippet: (payload: {
    orgId: number
    token: string
    item: Pick<SnippetType, 'category' | 'snippet'>
  }) => Promise<SnippetType>
}

type ReconnectState = {
  reconnect: boolean
  reconnectOnProgress: boolean
  reconnectMax: number
  reconnectDefault: boolean
  reconnectValue: number
}

const reconnectInitialState: ReconnectState = {
  reconnect: true,
  reconnectOnProgress: true,
  reconnectMax: -1,
  reconnectDefault: true,
  reconnectValue: DEFAULT_ATTEMPTS,
}

type Action =
  | { type: 'SELECT_RECONNECT'; payload: boolean }
  | { type: 'SELECT_RECONNECT_ON_PROGESS'; payload: boolean }
  | { type: 'SELECT_RECONNECT_DEFAULT'; payload: boolean }
  | { type: 'SELECT_RECONNECT_VALUE'; payload: number }

type reducerFunc = (state: ReconnectState, action: Action) => ReconnectState

const reducer: reducerFunc = (state, action) => {
  switch (action.type) {
    case 'SELECT_RECONNECT':
      if (action.payload) {
        return {
          ...state,
          reconnect: true,
          reconnectMax: state.reconnectDefault ? -1 : state.reconnectValue,
        }
      }
      return {
        ...state,
        reconnect: false,
        reconnectMax: 0,
      }
    case 'SELECT_RECONNECT_ON_PROGESS':
      if (action.payload) {
        return {
          ...state,
          reconnectOnProgress: true,
        }
      }
      return {
        ...state,
        reconnectOnProgress: false,
        ...(state.reconnectMax > DEFAULT_ATTEMPTS && {
          reconnectMax: DEFAULT_ATTEMPTS,
          reconnectValue: DEFAULT_ATTEMPTS,
        }),
      }
    case 'SELECT_RECONNECT_DEFAULT':
      if (action.payload) {
        return {
          ...state,
          reconnectMax: -1,
          reconnectDefault: true,
        }
      }
      return {
        ...state,
        reconnectMax: DEFAULT_ATTEMPTS,
        reconnectValue: DEFAULT_ATTEMPTS,
        reconnectDefault: false,
      }
    case 'SELECT_RECONNECT_VALUE':
      return {
        ...state,
        reconnectMax: action.payload,
        reconnectValue: action.payload,
      }
    default:
      throw new Error('Unexpected reconnect action')
  }
}

const getInitialState = (reconnectMax: number, reconnectOnProgress: boolean) => {
  let initialState = { ...reconnectInitialState }
  if (reconnectOnProgress === false) {
    initialState.reconnectOnProgress = false
  }
  if (reconnectMax === 0) {
    initialState.reconnect = false
    initialState.reconnectMax = 0
  } else if (reconnectMax > 0) {
    initialState = {
      ...initialState,
      reconnectMax,
      reconnectDefault: false,
      reconnectValue: reconnectMax,
    }
  }
  return initialState
}

const wrapAction = (accum: any, value: any) => {
  if (isEmpty(omit(['type'], value))) {
    return accum
  }
  return append(value, accum)
}

export default function Reconnect(props: Props) {
  const {
    getVoiceProps,
    getImageProps,
    getCampaigns,
    campaignIds,
    senderIds,
    personalizationList,
    changeHandler,
    orgId,
    token,
    campaignType,
    getSnippets,
    snippets,
    saveSnippet,
    timezones,
    timezone,
    countriesTimezones,
    countryCode,
    onChange,
    defaultLanguage,
    languages,
  } = props
  const [state, dispatch] = React.useReducer(reducer, getInitialState(props.reconnectMax, props.reconnectOnProgress))
  const [conditionsDisplay, setConditionsDisplay] = React.useState(-1)

  const actions = map(
    (action: any) => ({
      ...action,
      actions: unwrapActions(action.actions),
    }),
    pathOr([{ when: null, actions: [] }], ['actions'], props),
  )

  const stateReconnectMax = state.reconnectMax
  const stateReconnectOnProgress = state.reconnectOnProgress
  React.useEffect(() => {
    onChange({
      reconnectMax: stateReconnectMax,
      reconnectOnProgress: stateReconnectOnProgress,
    })
  }, [stateReconnectMax, stateReconnectOnProgress, onChange])

  const { classes } = useStyle()

  const actionValues = React.useMemo(() => {
    return getActionOptionsForCampaign({
      campaignType,
      actionNamespace: ActionContext.Reconnect,
    })
  }, [campaignType])

  return (
    <Card style={{ marginBottom: '2rem' }} className="reconnect-cont">
      <CardHeader title="What if a call is disconnected during the survey?" style={{ padding: '12px 24px' }} />
      <CardContent>
        <div
          style={{
            marginBottom: '15px',
          }}
        >
          <Typography color="textSecondary" variant="body1">
            Sometimes a call drops while the survey is ongoing, for example because the phone has a bad signal. <br />
            Do you want us to call back the contact? We will continue the survey where they left off.
            <br />
            <strong>Note:</strong> We will not resume the survey if the survey decides to end the call, for example with
            a “hang up” action.
            <br />
            <span className={classes.protip}>
              <strong>Protip:</strong> Enabling this feature will very likely lead to a{' '}
              <strong>higher survey completion rate</strong>, and a lower overall{' '}
              <strong>cost per completed survey</strong>.
            </span>
          </Typography>
          <div className={classes.radioCont}>
            <Radio
              id="reconnect-select"
              name="reconnect-select"
              label="Call back and resume the survey"
              value={String(state.reconnect)}
              values={reconnectValues}
              row={true}
              onChange={({ value }) => {
                dispatch({ type: 'SELECT_RECONNECT', payload: value === 'true' })
              }}
              error=""
            />
          </div>
          {state.reconnect && (
            <div>
              <div>
                <FormControl variant="standard">
                  <Tooltip
                    title={
                      <>
                        We only call back if contacts indicate they wish to continue the survey. <br />
                        If you select “on survey progress”, then we will call back as long as the contact keeps moving
                        along with the survey, for example by answering questions. <br />
                        If you choose “on calls longer than 10 seconds”, we will call back only if the latest call was
                        longer than 10 seconds.
                      </>
                    }
                  >
                    <FormLabel>When to call back</FormLabel>
                  </Tooltip>
                  <Radio
                    id="reconnect-progress-select"
                    name="reconnect-progress-select"
                    value={String(state.reconnectOnProgress)}
                    values={reconnectOnProgressValues}
                    row={true}
                    onChange={({ value }: any) => {
                      dispatch({ type: 'SELECT_RECONNECT_ON_PROGESS', payload: value === 'true' })
                    }}
                    error=""
                  />
                </FormControl>
              </div>
              <div>
                <FormControl variant="standard">
                  <Tooltip
                    title={
                      <>
                        After how many times do you want to stop calling back? This is an additional safe guard: no
                        matter the other configuration, if you hit the maximum number of attempts, we will stop calling
                        back.
                        <br />
                        The default is {DEFAULT_ATTEMPTS}.
                      </>
                    }
                  >
                    <FormLabel>How often to call back at most?</FormLabel>
                  </Tooltip>
                  <Radio
                    id="reconnect-maximum-attempts-defaults"
                    name="reconnect-maximum-attempts-defaults"
                    value={String(state.reconnectDefault)}
                    values={reconnectDefaultValues}
                    row={true}
                    onChange={({ value }: any) => {
                      dispatch({ type: 'SELECT_RECONNECT_DEFAULT', payload: value === 'true' })
                    }}
                    error=""
                  />
                </FormControl>
              </div>
              {!state.reconnectDefault && (
                <FormControl variant="standard">
                  <Tooltip title="The highest number you can choose here depends on the other configuration. Reconnecting only on survey progress allows you to make more calls.">
                    <FormLabel>Define the maximum number of reconnect attempts</FormLabel>
                  </Tooltip>
                  <TextField
                    variant="standard"
                    id="reconnect-maximum-attempts-value"
                    error={state.reconnectValue < 1}
                    helperText={state.reconnectValue < 1 ? 'Maximum reconnect attempts cannot be less than 1' : ''}
                    value={`${state.reconnectValue}`}
                    inputProps={{
                      type: 'number',
                      min: 1,
                      max: state.reconnectOnProgress ? MAX_ATTEMPTS_ON_PROGESS : MAX_ATTEMPTS,
                    }}
                    onChange={(e) => {
                      dispatch({ type: 'SELECT_RECONNECT_VALUE', payload: Number(e.currentTarget.value) })
                    }}
                  />
                </FormControl>
              )}
            </div>
          )}
          {state.reconnect && (
            <div
              style={{
                marginTop: '15px',
              }}
            >
              <Typography variant="h6">What should we do when the call reconnects?</Typography>
              {actions.map((action: any, index: number) => (
                <div
                  key={index}
                  style={{
                    marginTop: '15px',
                  }}
                >
                  {conditionsDisplay === index && (
                    <Conditions
                      groups={pathOr({}, ['groups'], props)}
                      segments={pathOr({}, ['segments'], props)}
                      data={processConditions(pathOr(['or', ['and', ['', '', '']]], ['when'], action))}
                      personalizationList={props.personalizationList}
                      title="Run these actions"
                      onSaveConditions={({ when }: any) => {
                        setConditionsDisplay(-1)
                        changeHandler({
                          value: update(
                            index,
                            {
                              ...props.actions[index],
                              when,
                            },
                            props.actions,
                          ),
                        })
                      }}
                      onClose={() => setConditionsDisplay(-1)}
                    />
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'end',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        style={{
                          paddingTop: 4,
                        }}
                      >
                        Run these actions
                      </Typography>
                      <Button
                        color="primary"
                        onClick={() => setConditionsDisplay(index)}
                        size="small"
                        endIcon={<Icon style={{ fontSize: '13px' }}>launch</Icon>}
                      >{`${path(['when'], action) ? 'sometimes' : 'always'}`}</Button>
                    </div>
                    {index !== 0 && (
                      <div>
                        <IconButton
                          onClick={() => {
                            changeHandler({
                              value: remove(index, 1, props.actions),
                            })
                          }}
                        >
                          <Icon>clear</Icon>
                        </IconButton>
                      </div>
                    )}
                  </div>
                  <QuestionBox
                    dragContext="reconnect-actions"
                    actionsList={action.actions}
                    actionsValues={actionValues}
                    changeHandler={({ actionsList }: any) => {
                      changeHandler({
                        value: update(
                          index,
                          {
                            ...props.actions[index],
                            actions: actionsList.reduce(wrapAction, []),
                          },
                          props.actions,
                        ),
                      })
                    }}
                    defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
                    personalizationList={personalizationList}
                    senderIds={senderIds}
                    getCampaigns={getCampaigns}
                    orgId={orgId}
                    token={token}
                    campaignIds={campaignIds}
                    getVoiceProps={getVoiceProps}
                    getImageProps={getImageProps}
                    getSnippets={getSnippets}
                    snippets={snippets}
                    saveSnippet={saveSnippet}
                    timezones={timezones}
                    timezone={timezone}
                    countriesTimezones={countriesTimezones}
                    countryCode={countryCode}
                    defaultLanguage={defaultLanguage}
                    languages={languages}
                  />
                  {index !== actions.length - 1 && <Divider />}
                </div>
              ))}
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    changeHandler({
                      value: append({ when: null, actions: [] }, props.actions),
                    })
                  }}
                >
                  Add a set of actions
                </Button>
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}
