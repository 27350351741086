import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import { Component } from 'react'
import Tooltip from '@/app/component/atom/tooltip'

type Props = {
  isDisabled: boolean
  isPlaying: boolean
  onAction: () => void
}

export default class AudioPlayerAction extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return this.props.isDisabled !== nextProps.isDisabled || this.props.isPlaying !== nextProps.isPlaying
  }

  render() {
    const { isDisabled, isPlaying, onAction } = this.props
    return (
      <Tooltip title={isPlaying ? 'Pause' : 'Play'}>
        <div style={{ display: 'flex' }}>
          <Button
            color="primary"
            variant="outlined"
            disabled={isDisabled}
            onClick={onAction}
            style={{
              backgroundColor: '#fff',
              height: '40px',
              width: '40px',
              borderRadius: '50%',
              minWidth: '0',
            }}
          >
            <Icon>{isPlaying ? 'pause' : 'play_arrow'}</Icon>
          </Button>
        </div>
      </Tooltip>
    )
  }
}
