import Button from '@mui/material/Button'
import { Fragment } from 'react'

export default function ContactsUploadCountriesActions({ backHandler, submitHandler, isSubmitDisabled }) {
  return (
    <Fragment>
      <Button variant="outlined" onClick={() => backHandler()}>
        Back
      </Button>
      <Button
        id="contacts-upload-country-confirm"
        variant="contained"
        color="primary"
        disabled={isSubmitDisabled}
        onClick={() => submitHandler()}
      >
        Confirm
      </Button>
    </Fragment>
  )
}
