import { Component } from 'react'

export default class Terms extends Component {
  // eslint-disable-next-line
  componentDidMount() {
    window.location.href = 'https://www.engagespark.com/terms-of-service'
  }

  // eslint-disable-next-line
  render() {
    return null
  }
}
