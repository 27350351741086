import Box from '@mui/material/Box'
import { getMessageContentPreview } from '@/app/module/campaigns/utils/part-content'
import { Message, ReminderMessage, PartFamily } from '@/app/module/campaigns/types'
import { ContentTypeIcon } from './content-type-icon'

export const SUPPORTED_LIBRARY_MESSAGE_TYPES = ['sms-m', 'sms-q', 'cati-m', 'cati-q', 'api-q']

type MessageSnippetProps = {
  item: Message | ReminderMessage
}

function TextSnippet({ type, title, text }: { type: string; title: string; text?: string }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem', minWidth: '200px', width: 'calc(100% - 42px)' }}>
      <ContentTypeIcon type={type} />
      <div style={{ flexShrink: 1, minWidth: 0 }}>
        <p
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          <span>{title}</span>
          <span>{text && `: ${text}`}</span>
        </p>
      </div>
    </Box>
  )
}

export function MessageSnippet({ item }: MessageSnippetProps) {
  switch (item.type) {
    case PartFamily.SMS: {
      const type = item.hasQuestion ? 'sms-q' : 'sms-m'
      const title = item.hasQuestion ? 'SMS Question' : 'SMS Message'
      const text = getMessageContentPreview(item)
      return <TextSnippet type={type} title={item.label || title} text={text} />
    }
    case PartFamily.CATI: {
      const type = item.hasQuestion ? 'cati-q' : 'cati-m'
      const title = item.hasQuestion ? 'CATI Question' : 'CATI Message'
      const text = getMessageContentPreview(item)
      return <TextSnippet type={type} title={item.label || title} text={text} />
    }
    case PartFamily.Topup: {
      const text = getMessageContentPreview(item)
      return <TextSnippet type="topup-q" title={item.label || 'Airtime Topup'} text={text} />
    }
    case PartFamily.API: {
      const text = getMessageContentPreview(item)
      return <TextSnippet type="api-q" title={item.label || 'API Request'} text={text} />
    }
    case PartFamily.Action: {
      const text = getMessageContentPreview(item)
      return <TextSnippet type="action-q" title={item.label || 'Actions Only'} text={text} />
    }
    case PartFamily.Voice: {
      const type = item.hasQuestion ? 'voice-q' : 'voice-m'
      const text = getMessageContentPreview(item)
      const title = item.hasQuestion ? 'Voice Question' : 'Voice Message'
      return <TextSnippet type={type} title={item.label || title} text={text} />
    }
    case PartFamily.WhatsApp: {
      const type = item.hasQuestion ? 'whatsapp-q' : 'whatsapp-m'
      const text = getMessageContentPreview(item)
      const title = item.hasQuestion ? 'WhatsApp Question' : 'WhatsApp Message'
      return <TextSnippet type={type} title={item.label || title} text={text} />
    }
    default: {
      throw new Error('Invalid snippet type.')
    }
  }
}
