/* eslint-disable max-len */
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import { path } from 'ramda'

function DemoItem(props) {
  const { item, selectedDemo, setSelected } = props

  const selected = path(['id'], item) === path(['id'], selectedDemo)

  const { name = '' } = item

  return (
    <ListItem
      style={{
        paddingTop: '10px',
        paddingBottom: '10px',
      }}
    >
      <ListItemText
        style={{
          maxWidth: '80%',
        }}
      >
        {name}
      </ListItemText>
      <ListItemSecondaryAction>
        <Button
          id={`try-it-now-btn-${path(['id'], item)}`}
          size="small"
          variant="contained"
          color={'primary'}
          style={{
            width: '80px',
            padding: '8px',
            margin: 'auto',
          }}
          onClick={() => {
            if (selected) {
              setSelected(null)
            } else {
              setSelected(item)
            }
          }}
        >
          Try it out
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default DemoItem
