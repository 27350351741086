import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import { isEmpty } from 'ramda'
import React from 'react'
import { capitalizeFirstLetter } from '@/app/module/campaigns/helpers'
import { CallWindowDay, CallWindowSlots } from '@/app/module/campaigns/types'
import SingleWindowSlot from './single-window-slot'

type Props = {
  label: string
  day: CallWindowDay

  duplicateDay: (params: { label: string; day: CallWindowDay }) => void
  updateSlots: (slots: CallWindowSlots) => void
}

const SingleWindowDay: React.FC<Props> = ({ label, duplicateDay, updateSlots, ...props }) => {
  const [active, setActive] = React.useState(false)
  const [day, setSlot] = React.useState(props.day)

  React.useEffect(() => {
    setSlot(props.day)
    setActive(!isEmpty(props.day))
  }, [props.day])

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <FormControlLabel
          style={{
            paddingTop: '10px',
          }}
          control={
            <Checkbox
              checked={active}
              onChange={() => {
                if (active) {
                  setActive(false)
                  updateSlots({
                    [label]: [],
                  })
                } else {
                  setActive(true)
                  updateSlots({
                    [label]: [
                      {
                        start: '08:00',
                        end: '20:00',
                      },
                    ],
                  })
                }
              }}
              value="active"
              color="primary"
            />
          }
          label={capitalizeFirstLetter(label)}
        />
        {day.length > 0 && (
          <Button
            style={{
              marginTop: '-18px',
              fontSize: '11px',
              marginLeft: '25px',
              color: 'rgba(0, 0, 0, 0.54)',
              textDecoration: 'underline',
            }}
            size="small"
            onClick={() =>
              duplicateDay({
                day,
                label,
              })
            }
          >
            Copy to rest of days
          </Button>
        )}
      </Grid>
      <Grid item xs={8}>
        {day.map((item, index) => (
          <SingleWindowSlot
            key={index}
            index={index}
            slot={item}
            day={day}
            onChange={(updatedDay: CallWindowDay) => {
              updateSlots({
                [label]: updatedDay,
              })
            }}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default SingleWindowDay
