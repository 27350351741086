import { styled } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'
import { AutoSizer, List } from 'react-virtualized'
import { SelectOption } from '@/app/types'
import SearchBar from '@/app/module/contacts/component/list/table/search-bar'
import Loader from './loader'

type Props = {
  disabled?: boolean
  loading?: boolean
  name: string
  options: SelectOption<string>[]
  selectedOptions: string[]

  onChange: (selected: string[]) => void
  onSearch?: (search: string) => void
}

/**
 * A select component that allows users to select multiple options from a list of checkboxes
 */
const ListSelect: React.FC<Props> = ({
  disabled,
  loading = false,
  name,
  options,
  selectedOptions,
  onChange,
  onSearch,
}) => {
  const [search, setSearch] = React.useState<string>('')

  const canUnselectAll = selectedOptions.length > 0
  const selectedAll = !!selectedOptions.length && selectedOptions.length === options.length
  const selectedOptionsMap = React.useMemo<Record<string, number>>(
    () => selectedOptions.reduce((acc, curr) => ({ ...acc, [curr]: 1 }), {}),
    [selectedOptions],
  )

  const selectedCount = selectedOptions.length ? `(${selectedOptions.length} selected)` : ''

  return (
    <div>
      <Header>
        <SelectAll
          control={<Checkbox indeterminate={canUnselectAll && !selectedAll} />}
          checked={selectedAll}
          data-testid={`${name}-select-all`}
          disabled={disabled || !options.length}
          label={canUnselectAll ? `Unselect All ${selectedCount}` : 'Select All'}
          name={`${name}-select-all`}
          onChange={() => onChange(canUnselectAll ? [] : options.map((f) => f.value))}
        />
        {onSearch && (
          <SearchBarContainer>
            <SearchBar
              data-testid={`${name}-search-bar`}
              searchTerm={search}
              onChange={(value) => {
                setSearch(value)
                onSearch(value)
              }}
            />
          </SearchBarContainer>
        )}
      </Header>
      <Divider />
      <Options>
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              id={`${name}-select-list`}
              height={Math.min(49 * 5, options.length * 49)}
              rowCount={options.length}
              rowHeight={49}
              width={width}
              rowRenderer={({ key, index, style }) => {
                const option = options[index]
                const selected = !!selectedOptionsMap[option.value]

                return (
                  <StyledFormControlLabel
                    control={<Checkbox />}
                    checked={selected}
                    data-testid={`${name}-select-${option.value}`}
                    disabled={disabled}
                    key={key}
                    label={option.label}
                    name={`${name}-select-${option.value}`}
                    style={style}
                    title={option.label}
                    onChange={() => {
                      onChange(
                        selected
                          ? selectedOptions.filter((f) => f !== option.value)
                          : [...selectedOptions, option.value],
                      )
                    }}
                  />
                )
              }}
            />
          )}
        </AutoSizer>
      </Options>
      {loading && <Loader />}
    </div>
  )
}

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(),
  paddingTop: theme.spacing(),
}))

const Options = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const SearchBarContainer = styled('div')(() => ({
  flex: 1,
}))

const SelectAll = styled(FormControlLabel)(() => ({
  width: 250,
}))

export default ListSelect
