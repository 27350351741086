import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import IconText from '../../../../component/layout/icon-text'
import CustomDialog from '../../../../component/wrapper/custom-dialog'

export default function AbortConfirmDialog({ isOpen, onConfirm, onClose, name }) {
  return (
    <CustomDialog id="campaign-abort-dialog" open={!!isOpen} onClose={onClose}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>warning</Icon>
            <Typography variant="h6">Warning - Stopping "{name}"</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Stopping this campaign cannot be undone.
            All existing subscriptions will be stopped and any message that has
            not been sent yet will be canceled. Please confirm that's what you want to do.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id="campaigns-abort-confirm" variant="contained" color="primary" onClick={onConfirm}>
          Stop the campaign
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}
