import { cannot } from '@/app/helpers'
import { CampaignTemplateType, PartFamily } from './types'

export const canUseAi = (type: CampaignTemplateType) => {
  switch (type) {
    case CampaignTemplateType.Drip:
    case CampaignTemplateType.Reminder:
    case CampaignTemplateType.SMSSurvey:
    case CampaignTemplateType.WhatsAppSurvey:
      return true

    case CampaignTemplateType.CATI:
    case CampaignTemplateType.SMSBlast:
    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey:
      return false
    default:
      return cannot(type)
  }
}

export const canTranscribe = (type: CampaignTemplateType) => {
  switch (type) {
    case CampaignTemplateType.Drip:
    case CampaignTemplateType.VoiceSurvey:
      return true
    case CampaignTemplateType.CATI:
    case CampaignTemplateType.Reminder:
    case CampaignTemplateType.SMSBlast:
    case CampaignTemplateType.SMSSurvey:
    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.WhatsAppSurvey:
      return false

    default:
      return cannot(type)
  }
}

export const canVoiceTranscribe = (partType: PartFamily) => {
  return partType === PartFamily.WhatsApp
}

export const canCategorize = (type: CampaignTemplateType) => {
  switch (type) {
    case CampaignTemplateType.Drip:
    case CampaignTemplateType.Reminder:
    case CampaignTemplateType.SMSSurvey:
    case CampaignTemplateType.WhatsAppSurvey:
      return true
    case CampaignTemplateType.CATI:
    case CampaignTemplateType.SMSBlast:
    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey:
      return false

    default:
      return cannot(type)
  }
}
