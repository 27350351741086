import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import React from 'react'
import { FunctionType } from '@/config/types'
import IconText from '@/app/component/layout/icon-text'

type Props = {
  onConfirm: FunctionType
  userStatuses?: string[]
  userStatus: string
  onClose: FunctionType
  onCancel: FunctionType
  isClose: boolean
}

export default function StatusModal(props: Props) {
  const { userStatuses, onClose, onCancel, onConfirm, isClose } = props

  const [userStatus, setUserStatus] = React.useState('')
  return (
    <Dialog id="cati-status-dialog" open={true} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>computer</Icon>
            <Typography variant="h6">Survey status</Typography>
          </IconText>
          {!isClose && (
            <IconButton id="cati-status-close-btn" onClick={onCancel}>
              <Icon>close</Icon>
            </IconButton>
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard">
          <FormLabel id="cati-status-choices-label">
            {props.userStatus ? (
              <>
                The current status is <strong id="cati-status-modal-current-status">{props.userStatus}</strong>.{' '}
              </>
            ) : (
              <></>
            )}
            Please select a new status
          </FormLabel>
          <RadioGroup
            aria-labelledby="cati-user-state"
            defaultValue=""
            value={userStatus}
            name="userStatus"
            onChange={(e) => setUserStatus(e.currentTarget.value)}
          >
            {userStatuses &&
              userStatuses.map((status) => (
                <FormControlLabel
                  className="cati-user-status"
                  key={`cati-user-status-${status}`}
                  value={status}
                  control={<Radio />}
                  label={status}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        {(!isClose || props.userStatus) && (
          <Button variant="outlined" id="cati-status-dialog-close" onClick={onClose}>
            Keep Current Status
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          id="cati-status-dialog-confirm"
          onClick={() => onConfirm(userStatus)}
          disabled={!userStatus}
        >
          Update status
        </Button>
      </DialogActions>
    </Dialog>
  )
}
