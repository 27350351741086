import { PureComponent } from 'react'

export default function createPaginatedTable(Wrapped) {
  return class PaginatedTable extends PureComponent {
    componentDidUpdate(prevProps) {
      const { token, orgId, query, getData } = this.props
      if (token) {
        if (query.page !== prevProps.query.page || query.size !== prevProps.query.size) {
          getData({ token, orgId, query })
        }
      }
    }

    render() {
      const { root, query, total, pageSizeValues, history, navigate, setPageSize } = this.props

      return (
        <Wrapped
          {...this.props}
          pagination={{
            namespace: root,
            page: query.page,
            size: query.size,
            total,
            pageSizeValues,
            onPageChange: (pageTo) => navigate({ ...query, history, page: pageTo }),
            onPageSizeChange: (pageSize) => setPageSize(pageSize),
          }}
        />
      )
    }
  }
}
