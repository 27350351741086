import { TextFieldProps, styled } from '@mui/material'
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React from 'react'

type Value = {
  from?: string
  until?: string
}

type Props = {
  disableFuture?: boolean
  disablePast?: boolean
  disabled?: boolean
  format?: string
  name: string
  value?: Value
  variant?: TextFieldProps['variant']

  onChange: (value: Value) => void
}

const DateTimeRangePicker: React.FC<Props> = ({
  disableFuture,
  disablePast,
  disabled,
  format,
  name,
  value,
  variant = 'standard',
  onChange,
}) => {
  const handleChange = (newValue: Partial<Value>) => {
    onChange({
      ...value,
      ...newValue,
    })
  }

  const from = value?.from ? moment(value.from) : null
  const until = value?.until ? moment(value.until) : null

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Container>
        <DesktopDateTimePicker
          className={`${name}-from`}
          disableFuture={disableFuture}
          disablePast={disablePast}
          disabled={disabled}
          format={format}
          label="From"
          slotProps={{
            textField: {
              variant,
            },
          }}
          value={from}
          onChange={(val) => {
            handleChange({ from: val && moment.isMoment(val) ? val.format(format) : undefined })
          }}
        />
        <DesktopDateTimePicker
          className={`${name}-until`}
          disableFuture={disableFuture}
          disablePast={disablePast}
          disabled={disabled}
          format={format}
          label="To"
          minDateTime={from || undefined}
          slotProps={{
            textField: {
              variant,
            },
          }}
          value={until}
          onChange={(val) => {
            handleChange({ until: val && moment.isMoment(val) ? val.format(format) : undefined })
          }}
        />
      </Container>
    </LocalizationProvider>
  )
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}))

export default DateTimeRangePicker
