import { Link } from 'react-router-dom'
import Logo from '@/app/component/atom/logo'
import { styled } from '@mui/material'
import { Balance, Organization, SelectOption } from '@/app/types'
import React from 'react'
import { linkCss } from '.'
import AccountMenu from './account-menu'

type Props = {
  activeOrg?: Organization
  balance: Balance
  currentPage: string
  email: string
  token: string
  organizations?: SelectOption<number>[]

  logout: () => void
  switchOrganization: (args: {
    token: string
    item: {
      label: string
      value: number
    }
  }) => Promise<void>
}

const CATIHeader: React.FC<Props> = ({ organizations, token, logout, switchOrganization, ...props }) => {
  const isAuthorized = !!token
  const hasOrg = isAuthorized && !!props.activeOrg

  const [accountMenuOpen, setAccountMenuOpen] = React.useState(false)
  const [panelExpanded, setPanelExpanded] = React.useState(false)

  const handleAccountMenuToggle = React.useCallback(() => setAccountMenuOpen((s) => !s), [])
  const handleOrganizationChange = React.useCallback(
    async ({ label, value }: SelectOption<number>) => {
      handleAccountMenuToggle()
      await switchOrganization({ token, item: { label, value } })
      // hacky. Need to wait on all other updates.
      setTimeout(() => {
        window.location.reload()
      }, 500)
    },
    [token, switchOrganization, handleAccountMenuToggle],
  )
  const handlePanelToggle = React.useCallback(() => setPanelExpanded((s) => !s), [])

  return (
    <>
      <StyledLink to="/">
        <Logo />
      </StyledLink>
      <Content>
        <Right>
          <AccountMenu
            {...props}
            hasOrg={hasOrg}
            menuOpen={accountMenuOpen}
            navItems={[]}
            organizations={organizations || []}
            panelExpanded={panelExpanded}
            onLogoutClick={logout}
            onMenuToggle={handleAccountMenuToggle}
            onOrganizationChange={handleOrganizationChange}
            onPanelToggle={handlePanelToggle}
          />
        </Right>
      </Content>
    </>
  )
}

const StyledLink = styled(Link)({
  ...linkCss,
})

const Content = styled('div')({
  display: 'flex',
  width: '100%',
  maxWidth: '100%',
})

const Right = styled('div')({
  display: 'flex',
  marginLeft: 'auto',
  textAlign: 'right',
})

export default CATIHeader
