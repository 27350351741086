import { SUPPORTED_ACTION_TYPES } from '@/app/module/campaigns/component/helpers'
import { LocalAction } from '@/app/module/campaigns/types'
import { Icon, IconButton, Tooltip } from '@mui/material'
import { includes } from 'ramda'
import React from 'react'

type Props = {
  action: LocalAction

  onCopy: (action: LocalAction) => Promise<unknown>
}

const AddToLibraryButton: React.FC<Props> = ({ action, onCopy }) => {
  const [inLibrary, setInLibrary] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const supported = includes(action.type, SUPPORTED_ACTION_TYPES)
  const editable = action.type && editableActions.indexOf(action.type) !== -1
  const disabled = !editable || !supported || inLibrary || isLoading
  const handleClick = async () => {
    try {
      setIsLoading(true)
      const res = await onCopy(action)
      if (!(res instanceof Error)) {
        setInLibrary(true)
      }
    } finally {
      setIsLoading(false)
    }
  }

  let title = 'Add action to library for later use.'
  if (inLibrary) {
    title = 'Action already added to library.'
  } else if (!supported) {
    title = "This action type can't be saved."
  }

  return (
    <Tooltip title={title}>
      <span>
        <IconButton disabled={disabled} onClick={handleClick}>
          <Icon>{inLibrary ? 'library_add_check' : 'library_add'}</Icon>
        </IconButton>
      </span>
    </Tooltip>
  )
}

export const editableActions = [
  'sms',
  'voice',
  'api',
  'sendSMSToPhoneNumbers',
  'subscribeToCampaign',
  'jump',
  'topup',
  'updateContact',
  'sendWhatsapp',
  'sendEmail',
  'transfer',
  'resumeSubscription',
]

export default AddToLibraryButton
