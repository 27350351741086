import { Icon, Link, LinkProps, styled } from '@mui/material'
import React from 'react'

type Props = LinkProps

const ExternalLink: React.FC<Props> = ({ children, ...props }) => {
  return (
    <StyledLink {...props}>
      {children} <Icon style={{ fontSize: '0.9rem', verticalAlign: 'middle' }}>open_in_new</Icon>
    </StyledLink>
  )
}

const StyledLink = styled(Link)(({ theme }) => ({
  gap: theme.spacing(0.25),
  lineHeight: '0.9rem',
}))

export default ExternalLink
