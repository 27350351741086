import React from 'react'

type Props = {
  children: React.ReactNode
}

export default function ErrorMessage({ children }: Props) {
  return (
    <div
      style={{
        fontWeight: 'bold',
        color: '#ff5252',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  )
}
