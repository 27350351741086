import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React from 'react'
import CountryFlag from '@/app/component/atom/flag'
import Tooltip from '@/app/component/atom/tooltip'
import { dateTime, formatPhoneNumber, parsePhoneNumber } from '@/app/service/util'
import { STATUS_TOOLTIP } from '@/app/module/logs/definitions'
import { getContactNumber, getSenderReceiverNumber, getStatus, isInbound } from '@/app/module/logs/utils'
import { DirectionType } from '@/app/types'
import { styled } from '@mui/material'
import { formatCost } from '@/app/module/logs/helpers'

type Props = {
  chargedMicro: number
  contactId: number
  created: string
  direction: DirectionType
  message: string
  pricePerPart?: number
  successPartCount?: number
  windowWidth: number

  openSendMessageModalHandler: () => void
  setSendMessage: (data: { recipient: string; contactId: number; senderId: string }) => void
  viewContact: (contactId: number) => void
}

export default class MessagesRow extends React.PureComponent<Props> {
  state = {
    popperOpen: false,
  }

  handlePopperOpen = () => {
    this.setState({ popperOpen: true })
  }

  handlePopperClose = () => {
    this.setState({ popperOpen: false })
  }

  handleSendMessage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const { contactId, setSendMessage } = this.props
    const recipient = getContactNumber(this.props)
    const senderId = getSenderReceiverNumber(this.props)
    setSendMessage({ recipient, contactId, senderId })
    this.props.openSendMessageModalHandler()
  }

  truncateMessageLength = () => {
    const { windowWidth } = this.props
    if (windowWidth > 1080) {
      return 80
    }
    return (windowWidth - 100) / 12
  }

  render() {
    const { popperOpen } = this.state
    const { chargedMicro, contactId, created, direction, message, viewContact } = this.props

    const isInboundType = isInbound(this.props)
    const contactNumber = getContactNumber(this.props)
    const messageStatus = getStatus(this.props)
    return (
      <StyledCard>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={5} md={4}>
              <Typography variant="subtitle1" color="textPrimary">
                <CountryFlag code={parsePhoneNumber(contactNumber).country || ''} />
                &nbsp;
                {formatPhoneNumber(`+${contactNumber}`)}
                &nbsp;
                {direction && (
                  <Tooltip title={isInboundType ? 'Inbound' : 'Outbound'}>
                    <MessageTypeIcon color={isInboundType ? 'primary' : 'secondary'}>
                      {isInboundType ? 'call_received' : 'call_made'}
                    </MessageTypeIcon>
                  </Tooltip>
                )}
              </Typography>
            </Grid>
            <DataCell item xs={4} sm={2} md={2}>
              <Typography color="textSecondary" align="center">
                <Tooltip title={STATUS_TOOLTIP[messageStatus]}>
                  <span>{messageStatus}</span>
                </Tooltip>
              </Typography>
              <Typography color="primary" align="center" variant="body1">
                <b>({formatCost(chargedMicro)})</b>
              </Typography>
            </DataCell>
            <DataCell item xs={3} sm={2} md={3}>
              <Typography color="textSecondary" align="center">
                <Tooltip title={'Date and Time'}>
                  <span>&nbsp;{dateTime(created)}</span>
                </Tooltip>
              </Typography>
            </DataCell>
            <DataCell item xs={3} sm={2} md={2}>
              <Typography color="textSecondary" align="center">
                <Tooltip title={'From/To'}>
                  <span>{getSenderReceiverNumber(this.props)}</span>
                </Tooltip>
              </Typography>
            </DataCell>
            <Grid item xs={2} sm={1} md={1}>
              <Tooltip title={'Send Message'}>
                <IconButton aria-label="Send Message" onClick={this.handleSendMessage}>
                  <Icon>message</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title={'View Contact'}>
                <IconButton
                  aria-label="View Contact"
                  onClick={() => {
                    viewContact(contactId)
                  }}
                >
                  <Icon>person</Icon>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} onMouseOver={this.handlePopperOpen} onMouseOut={this.handlePopperClose}>
              <Truncate variant="subtitle1" color="textPrimary">
                {message}
              </Truncate>
              {message && message.length > this.truncateMessageLength() && (
                <Popover
                  style={{ display: popperOpen ? 'block' : 'none' }}
                  id="react-popper-tooltip"
                  role="tooltip"
                  aria-hidden={!popperOpen}
                  elevation={8}
                >
                  <StyledCardContent>
                    <Typography variant="subtitle1" color="textPrimary">
                      {message}
                    </Typography>
                  </StyledCardContent>
                </Popover>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    )
  }
}

const StyledCard = styled(Card)(() => ({
  padding: '5px',
}))

const MessageTypeIcon = styled(Icon)(() => ({
  paddingTop: '3px',
}))

const DataCell = styled(Grid)(() => ({
  paddingTop: '8px',
}))

const Popover = styled(Card)(() => ({
  position: 'absolute',
  marginRight: '20px',
  top: '50px',
  left: '27px',
  zIndex: '1',
}))

const StyledCardContent = styled(CardContent)(() => ({
  padding: '15px',
}))

const Truncate = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))
