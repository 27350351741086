import { styled, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
  label?: string
}>

const ChoiceContainer: React.FC<Props> = ({ children, label }) => {
  return (
    <Container>
      <LabelContainer>
        <Typography>{label || 'List of choices'}</Typography>
      </LabelContainer>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
})

const LabelContainer = styled('div')({
  width: '140px',
  marginTop: '15px',
})

const ContentContainer = styled('div')({
  width: '100%',
})

export default ChoiceContainer
