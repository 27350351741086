import React from 'react'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

const editableInputStyle = {
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '18px',
  fontWeight: '500',
  lineHeight: '1.6',
  padding: 0,
  marginTop: '3px',
  paddingBottom: '2px',
}

type EditableTextProps = {
  displayText?: string
  value: string
  maxLength?: number
  textInputStyle?: React.CSSProperties
  padding?: string
  clickableLabel?: boolean
  editable?: boolean
  'data-testid'?: string

  onChange?: (arg: string) => void
  onEditClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onInputClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export default function EditableText({
  displayText,
  value,
  maxLength = 50,
  textInputStyle = {},
  padding = '40%',
  clickableLabel = false,
  editable = true,
  'data-testid': dataTestId = '',
  onChange,
  onEditClick,
  onInputClick,
}: EditableTextProps) {
  const textStyle = React.useMemo(
    () => ({
      ...editableInputStyle,
      ...textInputStyle,
    }),
    [textInputStyle],
  )
  const [isTitleEditable, setIsTitleEditable] = React.useState(false)
  const [text, setText] = React.useState(value)
  const submitText = () => {
    onChange?.(text)
    setIsTitleEditable(false)
  }

  React.useEffect(() => {
    setText(value)
  }, [value])
  return (
    <React.Fragment>
      {isTitleEditable ? (
        <TextField
          variant="standard"
          autoFocus
          id="outlined-helperText"
          margin="normal"
          sx={{ m: 0 }}
          onKeyDown={(e) => e.key === 'Enter' && submitText()}
          fullWidth
          inputProps={{
            'data-testid': dataTestId,
            maxLength,
            style: textStyle,
          }}
          style={{
            ...textInputStyle,
            paddingRight: padding,
          }}
          onBlur={() => {
            submitText()
          }}
          InputProps={{
            style: textStyle,
            readOnly: !isTitleEditable,
            endAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={() => submitText()} edge="start">
                  <Icon fontSize="small">check</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setText(e.target.value)
          }}
          onClick={onInputClick}
          value={text}
        />
      ) : (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              ...(clickableLabel && {
                cursor: 'pointer',
                textDecoration: 'underline',
              }),
            }}
          >
            <span
              className="campaignTitle"
              data-testid={dataTestId ? `${dataTestId}-toggle` : 'editable-text-toggle'}
              onClick={(e) => {
                if (!editable) {
                  return
                }
                onEditClick?.(e)
                setIsTitleEditable(true)
              }}
            >
              {displayText}
            </span>
            {!clickableLabel && editable && (
              <span>
                <IconButton
                  onClick={(e) => {
                    onEditClick?.(e)
                    setIsTitleEditable(true)
                  }}
                  color="primary"
                >
                  <Icon
                    style={{
                      fontSize: '16px',
                    }}
                  >
                    edit
                  </Icon>
                </IconButton>
              </span>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
