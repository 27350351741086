import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import { FixMeLater } from '@/config/types'
import { formatDate } from '@/app/service/util'
import { formatPhoneNumber } from '@/app/module/conversations/format-helpers'
import { ContactType } from '@/app/module/conversations/types'

const useStyle = makeStyles()((theme) => ({
  contactWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px',
    borderBottom: `1px solid ${theme.palette.border.main}`,
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.highlight.light,
    },
  },
  activeWrapper: {
    backgroundColor: theme.palette.highlight.light,
  },
  contactInfo: {
    alignItems: 'center',
    maxWidth: '200px',
  },
  contactRightSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '2px',
    paddingTop: '6px',
  },
  unreadCountCont: {
    textAlign: 'right',
    width: '100%',
  },
  unreadCount: {
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: '#fff',
    width: '16px',
    height: '16px',
    padding: '.2em 0px',
    fontSize: '12px',
    lineHeight: '1em',
    fontWeight: 500,
    textAlign: 'center',
    float: 'right',
  },
}))

type Props = {
  isActive: boolean
  contact: ContactType
  setActiveContact: FixMeLater
}

export default function Contact(props: Props) {
  const { classes } = useStyle()
  const { isActive, contact, setActiveContact } = props
  return (
    <div
      className={`${classes.contactWrapper} ${isActive && classes.activeWrapper} conversations-contact-wrapper`}
      onClick={() => {
        setActiveContact(contact.contactId)
      }}
    >
      <div className={classes.contactInfo}>
        {(contact.firstName || contact.lastName) && (
          <div>
            <Typography variant="subtitle1">
              {contact.firstName && `${contact.firstName} `}
              {contact.lastName}
            </Typography>
          </div>
        )}
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            {formatPhoneNumber(contact.contactId, contact.countryCode, contact.fullPhoneNumber)}
          </Typography>
        </div>
      </div>
      <div className={classes.contactRightSide}>
        <div
          className={classes.unreadCountCont}
          style={{
            visibility: contact.unreadCount > 0 ? 'visible' : 'hidden',
          }}
        >
          <div className={classes.unreadCount}>
            <strong>{contact.unreadCount}</strong>
          </div>
        </div>
        <Typography variant="caption" color="textSecondary">
          {formatDate(contact.created)}
        </Typography>
      </div>
    </div>
  )
}
