import { ExportFunction, ExportStatus } from './types'

export const formatExportFunction = (func: ExportFunction): string => {
  switch (func) {
    case 'campreport':
      return 'Campaign Report'
    case 'cxupload':
      return 'Customer Support Upload'
    case 'orgspend':
      return 'Organization Spend Report'
    case 'smsreport':
      return 'SMS Deliverability Report'
    default:
      return 'Unknown'
  }
}

export const formatExportStatus = (status: ExportStatus): string => {
  switch (status) {
    case 'ready':
      return 'Ready'
    case 'failed':
      return 'Failed'
    default:
      return 'Pending'
  }
}
