import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

export default function PlanBlock({
  children,
  style = {},
  isLoading = false,
  title,
  action,
  subtitle = '',
  className = '',
}) {
  return (
    <Paper
      className={className}
      style={{
        height: '100%',
        minHeight: '200px',
        width: '100%',
        ...style,
      }}
    >
      {title && (
        <div
          style={{
            padding: '10px 15px 0px 15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography variant="h5" color="primary">
                <strong>{title}</strong>
              </Typography>
            </div>
            {action}
          </div>
          <div style={{ height: '20px' }}>
            {subtitle && (
              <Typography variant="caption" color="textSecondary">
                {subtitle}
              </Typography>
            )}
          </div>
        </div>
      )}
      {isLoading ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>{children}</>
      )}
    </Paper>
  )
}
