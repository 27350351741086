import { ContentWhatsApp } from '@/app/module/campaigns/types'

export enum WhatsAppTab {
  Text = 'text',
  Image = 'image',
  Audio = 'audio',
  Video = 'video',
  Document = 'document',
}

export const loadDefaultTab = ({ audio, document, image, video }: ContentWhatsApp): WhatsAppTab => {
  if (audio) {
    return WhatsAppTab.Audio
  }
  if (document) {
    return WhatsAppTab.Document
  }
  if (image) {
    return WhatsAppTab.Image
  }
  if (video) {
    return WhatsAppTab.Video
  }
  return WhatsAppTab.Text
}

export const shouldDisplayConfirmation = (
  targetTab: WhatsAppTab,
  content: ContentWhatsApp,
  hasButtonOrList: boolean,
): boolean => {
  const { audio, document, image, text, video } = content
  if (!audio && !document && !image && !text && !video) {
    return false
  }

  if (content.text && (targetTab === WhatsAppTab.Audio || (targetTab !== WhatsAppTab.Text && hasButtonOrList))) {
    return true
  }

  if (content.audio && targetTab !== WhatsAppTab.Audio) {
    return true
  }
  if (content.document && targetTab !== WhatsAppTab.Document) {
    return true
  }
  if (content.image && targetTab !== WhatsAppTab.Image) {
    return true
  }
  if (content.video && targetTab !== WhatsAppTab.Video) {
    return true
  }

  return false
}
