import React, { PropsWithChildren } from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Loading from '@/app/component/guard/loading'

type Props = PropsWithChildren<{
  loading: boolean
}>

const Note: React.FC<Props> = ({ children, loading }) => (
  <Loading isLoading={loading}>
    <StyledCard style={{ marginBottom: '2rem' }}>
      <CardContent>
        <Text>{children}</Text>
      </CardContent>
    </StyledCard>
  </Loading>
)

const StyledCard = styled(Card)(() => ({
  marginBottom: '2rem',
}))

const Text = styled(Typography)(() => ({
  fontSize: '1.2rem',
  textAlign: 'center',
}))

export default Note
