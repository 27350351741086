import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import WhatsappTranscribeDialog from './whatsapp-transcribe-dialog'

type Props = {
  context: ActionContext.OnAnswer | ActionContext.OpenEnded
  transcribe: boolean
  onChange: (value: boolean) => void
}

const WhatsAppTranscribeToggle: React.FC<Props> = ({ context, transcribe, onChange }) => {
  const [transcribeOpen, setUseAiOpen] = React.useState(false)

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target
      if (checked) {
        setUseAiOpen(true)
      } else {
        onChange(e.target.checked)
      }
    },
    [onChange],
  )
  const handleConfirm = React.useCallback(() => {
    setUseAiOpen(false)
    onChange(true)
  }, [onChange])
  const handleClose = React.useCallback(() => {
    setUseAiOpen(false)
  }, [])

  return (
    <div>
      <FormControlLabel
        className="whatsapp-transcribe-toggle"
        control={<Switch checked={!!transcribe} onChange={handleChange} />}
        label="Transcribe voice clips replies with AI"
      />
      <WhatsappTranscribeDialog
        context={context}
        open={transcribeOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </div>
  )
}

export default WhatsAppTranscribeToggle
