import React from 'react'
import { useSelector } from 'react-redux'
import { selectMessagesState } from '@/app/module/campaigns/store/selectors'
import { CampaignItem, CampaignTemplateType, MessagesState } from '@/app/module/campaigns/types'
import PhoneNumber from './phone-number'
import PhoneNumberSMSBlast from './phone-number-sms-blast'

type Props = {
  item: CampaignItem
  onChange: (item: Partial<CampaignItem>) => void
}

const PhoneNumberContainer: React.FC<Props> = ({ item, onChange }) => {
  const messages = useSelector(selectMessagesState) as unknown as MessagesState

  if (item.type === CampaignTemplateType.SMSBlast) {
    return <PhoneNumberSMSBlast item={item} messages={messages} onChange={onChange} />
  }

  return <PhoneNumber item={item} messages={messages} onChange={onChange} />
}

export default PhoneNumberContainer
