import { Typography } from '@mui/material'
import { path } from 'ramda'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_EXPORT_SMS_REPORT, EXPORT_SMS_REPORT_PENDING } from '@/app/module/user/definitions'
import { esApi } from '@/app/store/api'
import { Permissions } from '@/app/types'
import { AppState } from '@/app/types/store'
import { selectOrgId, selectUserPermissions } from '@/app/module/user/store/selectors'
import SmsReport from './sms-report'

type Props = {}

const SMSReportContainer: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const orgId = useSelector(selectOrgId)
  const userPermissions = useSelector<AppState, Permissions | {}>(selectUserPermissions)
  const [createReport, { isLoading }] = esApi.endpoints.createOrgReport.useMutation()
  const handleCreate = async (month: string) => {
    try {
      await createReport({ orgId: String(orgId), body: { function: 'smsreport', month } }).unwrap()
      dispatch(
        createNotification({
          'export-sms-report-pending': {
            type: 'success',
            message: EXPORT_SMS_REPORT_PENDING,
          },
        }),
      )
      return true
    } catch (err) {
      dispatch(
        createNotification({
          'export-sms-report-error': {
            type: 'error',
            message: ERROR_EXPORT_SMS_REPORT,
          },
        }),
      )
      return false
    }
  }

  if (!path(['downloadReport'], userPermissions)) {
    return (
      <Typography
        sx={{
          padding: (theme) => theme.spacing(2),
        }}
        variant="h6"
      >
        You don't have permission to create reports.
      </Typography>
    )
  }

  return <SmsReport isLoading={isLoading} onCreate={handleCreate} />
}

export default SMSReportContainer
