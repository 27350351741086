import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import React from 'react'

export default function ContentOverview(props) {
  const { data = [] } = props
  return (
    <Paper>
      <Typography
        variant="h5"
        color="textSecondary"
        style={{
          textAlign: 'center',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        Message Content Overview
      </Typography>
      <List
        dense={true}
        style={{
          height: '371px',
          overflowY: 'scroll',
        }}
      >
        {data.map(({ name, message, icon }, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <ListItemIcon>
                <Icon>{icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={name} secondary={typeof message === 'object' ? message.text : message} />
            </ListItem>
            {index < data.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  )
}
