import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { selectToken } from '@/app/module/auth/store/selectors'
import { getCustomFields } from '@/app/module/custom-fields/store/actions'
import { getGroups } from '@/app/module/groups/store/actions'
import { getSegments } from '@/app/module/segments/store/actions'
import { selectCountries } from '@/app/module/utils/store/selectors'
import Organization from './component/organization'
import Profile from './component/profile'
import {
  addOrganization,
  deleteInvitation,
  deleteUser,
  disableTotp,
  downloadAuditLog,
  enableTotp,
  getInvitations,
  getOrganization,
  getRecoveryCodes,
  getUsers,
  initTotp,
  inviteUser,
  removeRecoveryCodes,
  resetRecoveryCodes,
  saveOrganization,
  savePassword,
  saveUser,
  setSelectedOrgId,
  switchOrganization,
} from './store/actions'
import {
  selectFormattedInvitations,
  selectFormattedUsers,
  selectNewOrganizationSpecs,
  selectOrgId,
  selectOrganization,
  selectOrganizationHeaders,
  selectOrganizationSpecs,
  selectPasswordResetHeaders,
  selectProfileInfoHeaders,
  selectProfilePreferenceHeaders,
  selectProfileSpecs,
  selectSelectedOrgId,
  selectUserId,
  selectUserOrganizations,
  selectUserPermissions,
  selectUserRole,
  selectUserTOTP,
  selectUserTimezone,
} from './store/selectors'

const profileSelector = ({ auth, user, utils }) => ({
  hydrateProps: ['token'],
  error: {
    ...user.details.error,
  },
  token: selectToken({ auth }),
  loading: user.details.loading || !user.details.init,
  passwordResetLoading: user.password.loading,
  countries: selectCountries({ utils }),
  languages: [{ label: 'English', value: 'English' }],
  details: pathOr({}, ['details', 'data'], user),
  specs: selectProfileSpecs({ user }),
  infoHeaders: selectProfileInfoHeaders(),
  preferenceHeaders: selectProfilePreferenceHeaders(),
  passwordResetHeaders: selectPasswordResetHeaders(),
  timezone: selectUserTimezone({ utils, user }),
  totpQRCode: pathOr('', ['totp', 'data', 'png'], user),
  totpSecret: pathOr('', ['totp', 'data', 'secret'], user),
  totpLoading: user.totp.loading,
  recoveryCodes: pathOr('', ['recoveryCodes', 'data'], user),
  recoveryCodesLoading: user.recoveryCodes.loading,
  totpEnabled: selectUserTOTP(user),
})

const profileDispatcher = (dispatch) =>
  bindActionCreators(
    {
      saveUser,
      savePassword,
      initTotp,
      enableTotp,
      disableTotp,
      getRecoveryCodes,
      resetRecoveryCodes,
      removeRecoveryCodes,
    },
    dispatch,
  )

export const UserProfile = connect(profileSelector, profileDispatcher)(Profile)

const orgSelector = ({ auth, user, utils }) => ({
  error: {
    ...user.details.error,
    ...user.organization.error,
  },
  hydrateProps: ['token', 'orgId'],
  token: selectToken({ auth }),
  orgId: selectSelectedOrgId({ user }),
  loading: user.details.loading || user.organization.loading,
  details: pathOr({}, ['details', 'data'], user),
  organization: selectOrganization({ user }),
  userOrganizations: selectUserOrganizations({ user }),
  headers: selectOrganizationHeaders(),
  specs: selectOrganizationSpecs({ user, utils }),
  addNewSpecs: selectNewOrganizationSpecs({ user, utils }),
  activeOrgId: selectOrgId({ user }),
  role: selectUserRole({ user }),
  invitations: pathOr({}, ['invitations'], user),
  users: pathOr({}, ['users'], user),
  formattedInvitations: selectFormattedInvitations({ user }),
  formattedUsers: selectFormattedUsers({ user }),
  permissions: selectUserPermissions({ user }),
  userId: selectUserId({ user }),
})

const orgDispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId }) =>
        () =>
          Promise.all([
            dispatch(getOrganization({ token, orgId })),
            dispatch(getCustomFields({ token, orgId })),
            dispatch(
              getGroups({
                token,
                orgId,
                query: {
                  page: 1,
                  size: 1000,
                },
              }),
            ),
            dispatch(
              getSegments({
                token,
                orgId,
                query: {
                  page: 1,
                  size: 1000,
                },
              }),
            ),
          ]),
      getOrganization:
        ({ token, orgId }) =>
        () =>
          Promise.all([dispatch(getOrganization({ token, orgId }))]),
      selectOrg: setSelectedOrgId,
      addOrganization,
      saveOrganization,
      switchOrganization,
      downloadAuditLog,
      inviteUser,
      getInvitations,
      deleteInvitation,
      getUsers,
      deleteUser,
    },
    dispatch,
  )

export const UserOrganization = connect(orgSelector, orgDispatcher)(Organization)
