import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import { CampaignManager } from '@/app/module/campaigns'
import Notifications from '@/app/module/notifications'
import { MatchProps } from '@/app/types'
import React from 'react'

type Props = {
  history: History
  match: MatchProps
}

const ManagePage: React.FC<Props> = ({ history, match }) => {
  return (
    <AuthGuard url={match.url} path={match.path}>
      <Layout title="Campaigns - engageSPARK">
        <AuthHeader currentPage={PAGE.CAMPAIGNS.MANAGE} />
        <CampaignManager history={history} itemId={match.params.campaignsId} />
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}

export default React.memo(ManagePage)
