import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  welcomeExpansionSummary: {
    backgroundColor: 'rgb(245, 245, 245)',
  },
  welcomeExpansionDetails: {
    width: '100%',
    display: 'block',
  },
})

export default ({ children, title, placeholder, display, className = '', ...restProps }) => {
  const { classes } = useStyles()

  const [expanded, setExpandeed] = React.useState(display)

  React.useEffect(() => {
    setExpandeed(display)
    // eslint-disable-next-line
  }, [])
  return (
    <Accordion
      className={className}
      data-testid={restProps['data-testid']}
      expanded={expanded}
      onChange={(e, panel) => {
        setExpandeed(panel)
      }}
      style={{
        borderRadius: '2px',
        margin: '0px',
        transform: 'translate3d(0, 0, 0)',
      }}
    >
      <AccordionSummary className={classes.welcomeExpansionSummary} expandIcon={<ExpandMoreIcon />}>
        <Typography>{expanded ? placeholder : title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.welcomeExpansionDetails}>{children}</AccordionDetails>
    </Accordion>
  )
}
