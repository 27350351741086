import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { Component } from 'react'
import { withStyles } from 'tss-react/mui'
import Select from '../../../component/atom/form/select'
import SelectMultiple from '../../../component/atom/form/select-multiple'
import Loading from '../../../component/guard/loading'
import { DEFAULT_SENDER_ID_VALUE } from '../../../definitions'
import { formatPhoneNumber } from '../../../service/util'
import { DEFAULT_SENDER_ID_MESSAGE, SENDER_ID_PLACEHOLDER } from '../definitions'
import Message from './send/message'

const styles = () => ({
  actions: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    padding: '24px',
  },
})

const getDefaultSenderId = (props) => {
  const { senderId, senderIds } = props
  if (senderId) {
    return {
      senderId: senderId.value,
      senderIds,
    }
  }
  let defaultSenderId = DEFAULT_SENDER_ID_VALUE
  if (senderIds.length > 1) {
    defaultSenderId = '0'
    senderIds.unshift({
      label: SENDER_ID_PLACEHOLDER,
      value: '0',
      disabled: true,
    })
  }
  return {
    senderId: defaultSenderId,
    senderIds,
  }
}

class MessageSend extends Component {
  state = {
    loading: true,
    message: this.props.message || '',
    senderId: getDefaultSenderId(this.props),
    senderIds: this.props.senderIds,
  }

  async componentDidMount() {
    return this.props.hydrate().then(() => {
      this.setState({
        loading: false,
        ...getDefaultSenderId(this.props),
      })
    })
  }

  sendHandler = () => {
    const { contactIds, excludeIds, isAllContacts, includedGroupIds, search } = this.props

    const { senderId, message } = this.state

    this.setState({
      loading: true,
    })

    this.props
      .sendMessage({
        senderId: senderId === DEFAULT_SENDER_ID_VALUE ? '' : senderId,
        isAllContacts,
        contactIds,
        excludeIds,
        message,
        includedGroupIds,
        search,
      })
      .then(() => {
        this.setState({
          loading: false,
        })
        this.props.closeHandler()
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
        this.props.closeHandler()
      })
  }

  render() {
    const { recipients, senderIds, totalRecipients, personalizationList = {} } = this.props

    const recipientsDisplay = recipients.slice(0, 4).map(({ label, value }) => ({
      label: formatPhoneNumber(`+${label}`),
      value,
    }))

    if (totalRecipients > 4) {
      recipientsDisplay.push({
        label: '...',
        value: 'more',
      })
    }

    return (
      <Loading isLoading={this.state.loading}>
        <form
          id="send-message-form"
          onSubmit={(e) => {
            e.preventDefault()
            if (!this.state.loading || !!this.state.message || !!this.state.senderId || this.props.recipients.length) {
              this.sendHandler()
            }
          }}
        >
          <CardContent>
            <SelectMultiple
              label="To"
              value={recipientsDisplay.map(({ value }) => value)}
              values={recipientsDisplay}
              editable={false}
              message={
                totalRecipients !== 1
                  ? `This message will be sent to ${totalRecipients} contacts`
                  : `This message will be sent to ${totalRecipients} contact`
              }
            />
            <Select
              id="send-message-sender"
              label="From"
              value={this.state.senderId}
              values={senderIds}
              editable={true}
              onChange={({ value }) => {
                this.setState({
                  senderId: value,
                })
              }}
              info={
                this.state.senderId === DEFAULT_SENDER_ID_VALUE && senderIds.length === 1
                  ? DEFAULT_SENDER_ID_MESSAGE
                  : ''
              }
            />
            <Message
              message={this.state.message}
              personalizationList={personalizationList}
              onChange={({ message }) => {
                this.setState({
                  message,
                })
              }}
            />
          </CardContent>
          <CardActions>
            <Grid container direction="row" alignItems="center" justifyContent="flex-end">
              <Button
                id="send-message-action"
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  this.state.loading ||
                  !this.state.message ||
                  !this.state.senderId ||
                  this.state.senderId === '0' ||
                  !this.props.recipients.length
                }
              >
                Send
              </Button>
            </Grid>
          </CardActions>
        </form>
      </Loading>
    )
  }
}

export default withStyles(MessageSend, styles)
