import Button from '@mui/material/Button'
import { replace } from 'ramda'
import React, { KeyboardEvent, useCallback, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { InputTag } from '@/app/component/atom/form'
import Loading from '@/app/component/guard/loading'

type Props = {
  error?: {
    message: string
    status: number
  }
  loading: boolean
  orgId: number
  token: string

  onExit: () => void
  submit: (payload: { token: string; orgId: number; item: any }) => Promise<any>
}

const helperText =
  'Press Enter or a comma to save each choice. You can also paste in a list of custom fields with each one on a separate line or separated by commas. Then press Enter.'

const CustomFieldsItem: React.FC<Props> = ({ error, loading, orgId, token, onExit, submit }) => {
  const [blurTimeout, setBlurTimeout] = useState<any>(null)
  const [inputError, setInputError] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [items, setItems] = useState<string[]>([])
  const { classes } = useStyles()

  const itemsMap = useMemo<{ [key: string]: number }>(
    () => items.reduce((obj, item) => ({ ...obj, [item.toLowerCase()]: 1 }), {}),
    [items],
  )

  const handleBlur = useCallback(() => {
    setBlurTimeout((s: any) => {
      if (s) {
        clearTimeout(s)
      }
      return setTimeout(() => {
        if (inputValue) {
          setInputError(helperText)
        }
      }, 500)
    })
  }, [inputValue])
  const handleChange = useCallback((val: string) => {
    setInputValue(val)
  }, [])
  const handleDeleteFieldClick = useCallback((index: number) => {
    setItems((s) => [...s.slice(0, index), ...s.slice(index + 1)])
  }, [])
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === ',') {
        e.preventDefault()
        if (inputValue) {
          if (blurTimeout) {
            clearTimeout(blurTimeout)
          }
          const cleansedInput = replace(/(?:\r\n|\r|\n)/g, ',', inputValue)
          const itemsValue = cleansedInput
            .split(',')
            .map((v) => v.trim())
            .filter((v) => !!v)

          for (let i = 0; i < itemsValue.length; i += 1) {
            const v = itemsValue[i].toLowerCase()
            if (v.length > 30) {
              setInputError('Custom field name cannot exceed 30 characters')
              return
            }

            if (itemsMap[v]) {
              setInputError('You cannot enter the same custom field twice.')
              return
            }
          }

          if (itemsValue.length > 0) {
            setItems((s) => [...s, ...itemsValue])
            setInputValue('')
            setInputError('')
          } else {
            setInputError('Please enter at least one custom field')
          }
        }
      }
    },
    [blurTimeout, inputValue, itemsMap],
  )
  const handleSubmitClick = useCallback(() => {
    if (!items.length) {
      setInputError('Please enter at least one custom field')
      return
    }

    const filteredItems: Record<string, string> = {}

    for (let i = 0; i < items.length; i += 1) {
      const item = items[i]
      if (item) {
        filteredItems[item.toLowerCase()] = item
      }
    }

    submit({
      token,
      orgId,
      item: {
        names: Object.values(filteredItems),
      },
    }).then((res) => {
      if (!(res instanceof Error)) {
        onExit()
      }
    })
  }, [items, orgId, token, onExit, submit])

  return (
    <Loading isLoading={loading} error={error}>
      <div className={classes.container}>
        <InputTag
          autoFocus
          error={!!inputError}
          helperText={<span>{inputError || helperText}</span>}
          label="Custom Fields"
          tags={items}
          value={inputValue}
          onBlur={handleBlur}
          onChange={handleChange}
          onDelete={handleDeleteFieldClick}
          onKeyPress={handleKeyPress}
        />
        <div className={classes.footer}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={loading || !!inputError || !items.length}
            onClick={handleSubmitClick}
          >
            Save
          </Button>
        </div>
      </div>
    </Loading>
  )
}

const useStyles = makeStyles()({
  container: {
    padding: 16,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 16,
  },
})

export default CustomFieldsItem
