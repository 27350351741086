import React from 'react'
import { match as Match, useLocation } from 'react-router-dom'
import RecaptchaLayout from '@/app/component/layout/recaptcha-page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader, SignupEmailForm, SignupForm } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'

type Props = {
  history: History
  match: Match
  name: string
}

const SignupPage: React.FC<Props> = ({ match, history }) => {
  const location = useLocation()
  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  const email = query.get('email')
  const emailToken = query.get('emailtoken')

  const showSignUp = email && emailToken

  return (
    <AuthGuard path={match.path} url={match.url}>
      <RecaptchaLayout title="Signup - engageSPARK">
        <AuthHeader currentPage={PAGE.ACCOUNT.SIGNUP} />
        {showSignUp ? <SignupForm email={email} emailToken={emailToken} history={history} /> : <SignupEmailForm />}
        <Notifications />
      </RecaptchaLayout>
    </AuthGuard>
  )
}

export default SignupPage
