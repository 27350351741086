import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { concat, map } from 'ramda'
import React, { FormEvent } from 'react'
import SearchableSelect from '@/app/component/atom/form/searchable-select'
import PzMenu from '@/app/component/atom/pz-menu'
import IconText from '@/app/component/layout/icon-text'
import Modal from '@/app/component/layout/modal'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { SelectOption } from '@/app/types'
import { CustomFieldsItem } from '@/app/module/custom-fields'
import { apiPz } from '@/app/module/logs/store/selectors'
import { addCurlyBrackets } from '@/app/module/utils/personalization'
import { fromUpdateContact, toUpdateContact } from '@/app/module/campaigns/contact-helpers'
import { Q_ACTIONS, displayMap } from '@/app/module/campaigns/definitions'
import { LocalUpdateContactAction, PersonalizationType } from '@/app/module/campaigns/types'

type Props = {
  invalid: boolean
  openResponse: boolean
  personalizationList: PersonalizationType
  question: boolean
  transcribeAvailable: boolean
  transcribeEnabled: boolean
  type?: string
  value: LocalUpdateContactAction

  onClose: () => void
  onSave: (action: Partial<LocalUpdateContactAction>) => void
}

const UpdateContact: React.FC<Props> = ({
  invalid = false,
  openResponse = false,
  personalizationList,
  question = false,
  transcribeAvailable,
  transcribeEnabled,
  type,
  onClose,
  onSave,
  ...props
}) => {
  const updateContact = React.useMemo(() => {
    if ('updateContact' in props.value) {
      return fromUpdateContact(props.value.updateContact)
    }

    return {
      field: '',
      value: '',
    }
  }, [props.value])

  const {
    field: propsField = '',
    value: propsValue = question || invalid || openResponse ? '' : 'constant',
    constant: propsConstant = '',
  } = updateContact
  const { contact = [], customfields = [] } = personalizationList
  const [field, setField] = React.useState(propsField)
  const [value, setValue] = React.useState(propsValue)
  const [constant, setConstant] = React.useState(propsConstant)
  const [addCFVisible, setAddCFVisible] = React.useState(false)

  const closeAddCFModal = () => setAddCFVisible(false)

  const processedContactFields = contact.filter((contactField) => contactField.value !== 'contact.id')
  const inputRef = React.useRef<HTMLInputElement | null>(null)

  const submitDisabled = isDisabled({
    field,
    value,
  })

  const submit = () => {
    if (submitDisabled) {
      return
    }
    onSave({
      updateContact: toUpdateContact({
        field,
        value,
        ...(value === 'constant' && {
          constant,
        }),
      }),
    })
  }
  const formSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <>
      <CustomDialog open={true} onClose={onClose}>
        <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <IconText>
              <Icon>{displayMap.updateContact.icon}</Icon>
              <Typography variant="h6">{Q_ACTIONS.updateContact.label}</Typography>
            </IconText>
            <IconButton onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ paddingTop: '24px' }}>
          <div
            style={{
              width: '80vw',
              minWidth: '300px',
              maxWidth: '100%',
            }}
          >
            <form id="update-contact-form" onSubmit={formSubmit}>
              <CardContent>
                <Section>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        style={{
                          marginTop: '16px',
                        }}
                      >
                        Choose the contact field you want to update.
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <SearchableSelect
                        formControlProps={{
                          margin: 'none',
                        }}
                        labelId="contact-field-label"
                        id="contact-field-select"
                        value={field}
                        error={!field}
                        label="Select field"
                        hideRemoveSelection={true}
                        keyPropFn={(option: SelectOption<string>) => option.value}
                        valuePropFn={(option: SelectOption<string>) => option.label}
                        onChange={(selectedId: string) => {
                          setField(selectedId)
                        }}
                        options={concat(processedContactFields, customfields)}
                        showAll={true}
                      />
                    </Grid>
                  </Grid>
                </Section>
                <Section>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        style={{
                          marginTop: '16px',
                        }}
                      >
                        What do you want to update the contact field with?
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <SearchableSelect
                        labelId="option-field-label"
                        formControlProps={{
                          margin: 'none',
                        }}
                        hideRemoveSelection={true}
                        id="option-field-select"
                        label="Select value"
                        value={value}
                        error={!value}
                        keyPropFn={(option: SelectOption<string>) => option.value}
                        valuePropFn={(option: SelectOption<string>) => option.label}
                        options={getOptions({
                          invalid,
                          openResponse,
                          question,
                          transcribeAvailable,
                          transcribeEnabled,
                          type,
                        })}
                        showAll={true}
                        onChange={(selectedId: string) => {
                          setValue(selectedId)
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: '-25px',
                      }}
                    >
                      {question && (
                        <Typography variant="caption" color="textSecondary">
                          The <strong>answer</strong> is the answer Choice that triggers this action (if you specify a
                          range like 1-3 and the contact replies with 2, then 2 will be saved in the contact field).{' '}
                          <br />
                        </Typography>
                      )}
                      {(question || invalid) && (
                        <Typography variant="caption" color="textSecondary">
                          The <strong>exact response</strong> is exactly what the contact replied with, so long as it is
                          a valid reply. (If you specify a range like 1-3 and the contact replies 2, then 2 will be
                          saved in the contact field. If the contact replies with 'yes!!' and the matching answer is
                          'yes', then we save 'yes!!' )
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Section>
                {value === 'constant' && (
                  <Section>
                    <TextField
                      label="Enter text"
                      inputRef={inputRef}
                      style={{
                        width: '100%',
                      }}
                      value={constant}
                      variant="standard"
                      onChange={(e) => setConstant(e.target.value)}
                    />

                    <PzMenu
                      id="update-contact"
                      color="primary"
                      containerSx={{
                        padding: (theme) => theme.spacing(1, 0),
                      }}
                      personalizationList={personalizationList}
                      variant="outlined"
                      onSelect={(pz) => {
                        if (inputRef.current) {
                          const { selectionStart, selectionEnd } = inputRef.current
                          if (typeof selectionStart === 'number' && typeof selectionEnd === 'number') {
                            setConstant((s) => `${s.slice(0, selectionStart)}{{${pz.value}}}${s.slice(selectionEnd)}`)
                            return
                          }
                        }

                        setConstant((s) => `${s}{{${pz.value}}}`)
                      }}
                    />
                  </Section>
                )}
              </CardContent>
              <input type="submit" style={{ display: 'none' }} />
            </form>
            <AddCFButton>
              <Button
                variant="text"
                color="primary"
                startIcon={<Icon>bookmark</Icon>}
                onClick={() => setAddCFVisible(true)}
              >
                Add custom fields
              </Button>
            </AddCFButton>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" disabled={submitDisabled} onClick={submit}>
            Save
          </Button>
        </DialogActions>
      </CustomDialog>
      <Modal icon="" title="Add custom fields" isOpen={addCFVisible} onClose={closeAddCFModal}>
        <CustomFieldsItem onExit={closeAddCFModal} />
      </Modal>
    </>
  )
}

const isDisabled = ({ field, value }: { field?: string; value?: string }) => {
  return !field || !value
}

const getOptions = ({
  invalid,
  openResponse,
  question,
  transcribeAvailable,
  transcribeEnabled,
  type,
}: {
  invalid: boolean
  openResponse: boolean
  question: boolean
  transcribeAvailable: boolean
  transcribeEnabled: boolean
  type?: string
}) => [
  {
    label: 'Specific text I type below',
    value: 'constant',
  },
  ...(question || invalid || openResponse
    ? [
        {
          label: 'The exact response',
          value: 'response',
        },
      ]
    : []),
  ...(type === 'sms' || type === 'whatsapp'
    ? [
        {
          label: 'The message received',
          value: 'message',
        },
      ]
    : []),
  ...(question
    ? [
        {
          label: 'The answer',
          value: 'answer',
        },
      ]
    : []),
  ...(type === 'api'
    ? map(
        (pz) => ({
          label: pz.label,
          value: addCurlyBrackets(pz.value),
        }),
        apiPz,
      )
    : []),
  ...(transcribeAvailable
    ? [
        {
          label: 'Transcript',
          value: 'transcript',
          disabled: !transcribeEnabled,
        },
      ]
    : []),
]

const Section = styled('div')(() => ({
  marginBottom: '5px',
}))

const AddCFButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}))

export default UpdateContact
