import qs from 'qs'
import { pathOr } from 'ramda'
import React from 'react'
import Loading from '@/app/component/guard/loading'
import { CAMPAIGN_FILTERABLE_ATTRIBUTES } from '@/app/module/campaigns/definitions'
import { useOrgSwitchGuard } from '@/app/module/campaigns/utils/campaign'
import ConfirmationDialog from './item/manage/subscriptions/confirmation-dialog'
import AbortConfirm from './list/abort-confirm'
import DownloadReportDialog from './list/download-report'
import CampaignsTable from './list/table'

export default function Campaigns(props) {
  const {
    loading,
    error,
    query,
    page,
    aborted,
    archived,
    launched,
    open,
    published,
    search,
    templates,
    getCampaigns,
    countriesTimezones,
    timezones,
    timezone,
    token,
    orgId,
    downloadCampaignReport,
    getCustomFields,
    personalizationList,
    unsubscribeAll,
  } = props

  const [state, updateState] = React.useState({
    abortConfirmId: null,
    abortConfirmName: '',
    downloadCampaignReportId: null,
    stopAllId: null,
  })

  useOrgSwitchGuard(orgId)

  React.useEffect(() => {
    if (orgId && token) {
      getCustomFields({ token, orgId })
    }
  }, [token, orgId, getCustomFields])

  const setState = React.useCallback(
    (payload) =>
      updateState((s) => ({
        ...s,
        ...payload,
      })),
    [],
  )

  const toggleAbortConfirm = (value) => {
    setState({ abortConfirmId: pathOr(null, ['id'], value), abortConfirmName: pathOr('', ['name'], value) })
  }

  const toggleDownloadCampaignReport = (id) => {
    setState({
      downloadCampaignReportId: id,
    })
  }

  const handleStopAllClick = (itemId) => {
    setState({
      stopAllId: itemId,
    })
  }

  const setAbort = (id) => {
    const { setAbortItem } = props
    setAbortItem({
      token,
      orgId,
      itemId: id,
    })
  }

  const navigate = (payload) => {
    const q = CAMPAIGN_FILTERABLE_ATTRIBUTES.reduce(
      (obj, key) => {
        if (!payload[key]) {
          return obj
        }
        return {
          ...obj,
          [key]: payload[key],
        }
      },
      {
        page: payload.page,
        size: payload.size,
      },
    )

    props.history.push(`/campaigns?${qs.stringify(q)}`)
  }

  const renderStopAllConfirm = React.useCallback(() => {
    if (!state.stopAllId) {
      return null
    }

    const toBeStopped = props.data[state.stopAllId]
    if (!toBeStopped) {
      return null
    }

    return (
      <ConfirmationDialog
        deleteButtonText="Confirm"
        isOpen={!!state.stopAllId}
        title={
          <>
            Unsubscribe all contacts in <b>"{toBeStopped.name}"</b>
          </>
        }
        text={
          <>
            Stopping all in progress subscriptions will end the subscription for all contacts who are in the middle of
            this campaign (this includes live and scheduled subscriptions).
            <br />
            They will not receive any more calls, messages, or topups. No more actions will execute.
            <br />
            However, ongoing calls will continue to their natural end (we won't hang up on contacts who are in the
            middle of a call).
            <br />
            <b>Please confirm that you want to do this.</b>
          </>
        }
        onConfirm={() => {
          unsubscribeAll({
            orgId,
            token,
            itemId: state.stopAllId,
          })
          setState({
            stopAllId: null,
          })
        }}
        onClose={() => {
          setState({
            stopAllId: null,
          })
        }}
      />
    )
  }, [orgId, props.data, state.stopAllId, token, setState, unsubscribeAll])

  const downloadReportCampaign = props.data?.[state.downloadCampaignReportId]

  return (
    <Loading isLoading={loading} error={error}>
      <AbortConfirm
        isOpen={!!state.abortConfirmId}
        name={state.abortConfirmName}
        onConfirm={() => {
          setAbort(state.abortConfirmId)
          toggleAbortConfirm(null)
        }}
        onClose={() => toggleAbortConfirm(null)}
      />
      {renderStopAllConfirm()}
      {downloadReportCampaign && (
        <DownloadReportDialog
          campaign={downloadReportCampaign}
          isOpen
          onConfirm={({ timezone: tz, fields, labels }) => {
            setState({
              downloadCampaignReportId: null,
            })
            downloadCampaignReport({
              token,
              orgId,
              itemId: state.downloadCampaignReportId,
              tz,
              ...(fields.length > 0 && {
                fields,
              }),
              labels,
            })
          }}
          onClose={() => {
            setState({
              downloadCampaignReportId: null,
            })
          }}
          timezones={timezones}
          countriesTimezones={countriesTimezones}
          timezone={timezone}
          personalizationList={personalizationList}
        />
      )}
      <CampaignsTable
        {...props}
        getData={getCampaigns}
        toggleAbortConfirm={(id) => toggleAbortConfirm(id)}
        toggleDownloadCampaignReport={(id) => toggleDownloadCampaignReport(id)}
        query={{
          ...query,
          page,
          aborted,
          archived,
          launched,
          open,
          published,
          search,
          templates,
        }}
        navigate={navigate}
        onStopAllClick={handleStopAllClick}
      />
    </Loading>
  )
}
