import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import { update } from 'ramda'
import React from 'react'
import { FunctionType } from '@/config/types'
import IconText from '@/app/component/layout/icon-text'

type Props = {
  isOpen: boolean
  onClose: FunctionType
  onConfirm: FunctionType
}

export default function InvoiceDetails({ isOpen, onClose, onConfirm }: Props) {
  const [lines, setLines] = React.useState(['', '', '', '', ''])
  return (
    <Dialog maxWidth="sm" fullWidth={true} id="delete-dialog" open={!!isOpen}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>input</Icon>
            <Typography className="subscription-delay-title" variant="h6">
              Set Usage Statement information
            </Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            marginBottom: '10px',
          }}
        >
          <Typography color="textPrimary" variant="subtitle2">
            Enter whatever organizational, legal, or project details you want to appear on the statement, such as your
            organization's legal name, address, tax ID, phone number, PO number, etc. You can add up to 5 lines of
            detail.
          </Typography>
        </div>
        <div>
          {lines.map((line: string, index) => (
            <div
              key={index}
              style={{
                marginBottom: '15px',
              }}
            >
              <FormControl key={index} fullWidth variant="outlined">
                <InputLabel htmlFor={`line-${index}`}>Line {index + 1}</InputLabel>
                <OutlinedInput
                  id={`line-${index}`}
                  value={line}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLines(update(index, event.target.value, lines))
                  }}
                />
              </FormControl>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" id="invoicedetails-dialog-cancel-btn" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="invoicedetails-dialog-continue-btn"
          variant="contained"
          color="primary"
          onClick={() => {
            onConfirm(lines)
          }}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  )
}
