import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import React from 'react'
import { styled } from '@mui/material'

type Props = {}

const LoadingMessagesRow: React.FC<Props> = () => (
  <StyledCard>
    <CardContent>
      <Grid container spacing={8}>
        <RowLoader item xs={12}>
          <CircularProgress color="primary" size={50} />
        </RowLoader>
      </Grid>
    </CardContent>
  </StyledCard>
)

const StyledCard = styled(Card)(() => ({
  padding: '5px',
}))

const RowLoader = styled(Grid)(() => ({
  textAlign: 'center',
}))

export default LoadingMessagesRow
