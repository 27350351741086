import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { isEmpty, pathOr } from 'ramda'
import React from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import ExternalLink from '@/app/component/atom/external-link'
import { Input } from '@/app/component/atom/form'
import Loading from '@/app/component/guard/loading'
import Fields from '@/app/component/wrapper/fields'
import validate, { hasEmailError, hasRequiredError } from '@/app/service/validate'

function Login({ token, orgId, loading, error, submit, clearLoginError, history }) {
  const { classes } = useStyles()
  const hasRequestError = !isEmpty(error)

  const handleSubmit = (verifyToken) => {
    setVerifyRecaptcha(false)
    submit({
      token,
      orgId,
      item: loginInfo,
      verifyToken,
    }).then(({ authToken, totpEnabled }) => {
      if (totpEnabled) {
        history.push('/2fa')
      }
      return authToken
    })
  }

  const [verifyRecaptcha, setVerifyRecaptcha] = React.useState('')

  const [loginInfo, setLoginInfo] = React.useState({ username: '', password: '' })

  return (
    <Loading isLoading={loading}>
      <Fields
        fields={{
          username: '',
          password: '',
        }}
        validation={{
          username: validate(hasRequiredError, hasEmailError),
          password: validate(hasRequiredError),
        }}
        onSubmit={({ item }) => {
          setVerifyRecaptcha(true)
          setLoginInfo(item)
        }}
      >
        {({ fields, changed, hasErrors, submitHandler, fieldChangeHandler, fieldBlurHandler }) => (
          <form
            onSubmit={(e) => {
              e.persist?.()
              e.preventDefault()
              submitHandler()
            }}
            noValidate
          >
            <Card className={classes.wrap}>
              <CardContent className={classes.content}>
                <Icon className={classes.icon}>account_circle</Icon>
                <Input
                  name="username"
                  type="email"
                  label="Email"
                  editable={true}
                  value={fields.username.value}
                  error={fields.username.error}
                  onChange={({ name, value }) => {
                    if (hasRequestError) {
                      clearLoginError()
                    }
                    fieldChangeHandler({ name, value })
                  }}
                  onBlur={fieldBlurHandler}
                />
                <Input
                  name="password"
                  type="password"
                  label="Password"
                  editable={true}
                  value={fields.password.value}
                  error={fields.password.error}
                  onChange={({ name, value }) => {
                    if (hasRequestError) {
                      clearLoginError()
                    }
                    fieldChangeHandler({ name, value })
                  }}
                  onBlur={fieldBlurHandler}
                />
                <Link to="/request-reset-password" className={classes.link}>
                  Did you forget your password?
                </Link>
              </CardContent>
              <CardActions style={{ justifyContent: 'flex-end', paddingBottom: '1rem' }}>
                <Button
                  type="submit"
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={!changed || loading || hasErrors}
                >
                  Log In
                </Button>
              </CardActions>
              <div style={{ padding: '5px 25px 10px' }}>
                <Typography color="textSecondary" variant="caption">
                  <strong>Tip:</strong> Enable two-factor authentication (2FA) for your engageSPARK account. Read more{' '}
                  <ExternalLink
                    href="https://www.engagespark.com/support/engagespark-two-factor-authentication-2fa"
                    target="_blank"
                  >
                    in this support article
                  </ExternalLink>
                  .
                </Typography>
              </div>
            </Card>
          </form>
        )}
      </Fields>
      {verifyRecaptcha && (
        <GoogleReCaptcha
          onVerify={(recaptchaToken) => {
            handleSubmit(recaptchaToken)
          }}
        />
      )}
    </Loading>
  )
}

const useStyles = makeStyles()((theme) => ({
  wrap: {
    maxWidth: '400px',
    margin: 'auto',
    marginTop: 'calc(60px + 5%)',
    marginBottom: '3rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2rem',
  },
  error: {
    height: '60px',
    background: pathOr('', ['palette', 'error', 'main'], theme),
    '& p': {
      color: pathOr('', ['palette', 'error', 'contrastText'], theme),
    },
  },
  icon: {
    margin: 'auto',
    fontSize: '4rem !important',
    color: pathOr('', ['palette', 'primary', 'main'], theme),
  },
  button: {
    alignSelf: 'flex-end',
  },
}))

export default Login
