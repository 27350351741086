import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { isEmpty, pathOr } from 'ramda'
import { makeStyles } from 'tss-react/mui'
import { Input } from '../../../component/atom/form'
import Loading from '../../../component/guard/loading'
import Fields from '../../../component/wrapper/fields'
import validate, { hasEmailError, hasRequiredError } from '../../../service/validate'

function RequestResetPassword({ token, orgId, loading, error, submit, clearResetPasswordError, history }) {
  const { classes } = useStyles()
  const hasRequestError = !isEmpty(error)

  return (
    <Loading isLoading={loading}>
      <Fields
        fields={{
          email: '',
        }}
        validation={{
          email: validate(hasRequiredError, hasEmailError),
        }}
        onSubmit={({ item }) =>
          submit({
            token,
            orgId,
            item,
          }).then((res) => {
            if (!(res instanceof Error)) {
              history.push('/login')
            }
          })
        }
      >
        {({ fields, changed, hasErrors, submitHandler, fieldChangeHandler, fieldBlurHandler }) => (
          <form
            onSubmit={(e) => {
              e.persist?.()
              e.preventDefault()
              submitHandler()
            }}
            noValidate
          >
            <Card className={classes.wrap}>
              <CardContent className={classes.content}>
                <Icon className={classes.icon}>account_circle</Icon>
                <Typography color="textSecondary" variant="caption">
                  Enter your email address below, and we’ll send you an email allowing you to reset it.
                </Typography>
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  editable={true}
                  value={fields.email.value}
                  error={fields.email.error}
                  onChange={({ name, value }) => {
                    if (hasRequestError) {
                      clearResetPasswordError()
                    }
                    fieldChangeHandler({ name, value })
                  }}
                  onBlur={fieldBlurHandler}
                />
              </CardContent>
              <CardActions style={{ justifyContent: 'flex-end', paddingBottom: '1rem' }}>
                <Button
                  type="submit"
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={!changed || loading || hasErrors}
                >
                  Reset Password
                </Button>
              </CardActions>
            </Card>
          </form>
        )}
      </Fields>
    </Loading>
  )
}

const useStyles = makeStyles()((theme) => ({
  wrap: {
    maxWidth: '400px',
    margin: 'auto',
    marginTop: 'calc(60px + 5%)',
    marginBottom: '3rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2rem',
  },
  error: {
    height: '60px',
    background: pathOr('', ['palette', 'error', 'main'], theme),
    '& p': {
      color: pathOr('', ['palette', 'error', 'contrastText'], theme),
    },
  },
  icon: {
    margin: 'auto',
    marginBottom: '15px',
    fontSize: '4rem !important',
    color: pathOr('', ['palette', 'primary', 'main'], theme),
  },
  button: {
    alignSelf: 'flex-end',
  },
}))

export default RequestResetPassword
