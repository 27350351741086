import { pathOr, pick } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createNested } from '@/app/service/util'
import { selectToken } from '@/app/module/auth/store/selectors'
import { getCustomFields } from '@/app/module/custom-fields/store/actions'
import { selectPersonalizationList } from '@/app/module/logs/store/selectors'
import { deleteSegmentsItem, getSegments, getSegmentsItem, saveSegmentsItem } from '@/app/module/segments/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import Item from './component/item'
import Segment from './component/segment'
import { deleteGroupsItem, getGroups, getGroupsItem, saveGroupsItem } from './store/actions'
import {
  selectGroup,
  selectGroupsItemConfig,
  selectHeaders,
  selectSegmentsHeaders,
  selectSegmentsItemConfig,
} from './store/selectors'

const itemSelector = ({ auth, user, contacts, customFields, groups }) => ({
  hydrateProps: ['token', 'orgId', 'itemId'],
  error: {
    ...customFields.error,
    ...groups.item.error,
  },
  item: selectGroup(groups),
  loading:
    contacts.item.loading ||
    contacts.list.loading ||
    customFields.loading ||
    groups.item.loading ||
    groups.list.loading,
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  specs: selectGroupsItemConfig({ user }),
  headers: selectHeaders({ customFields }),
  fieldErrors: pathOr({}, ['item', 'error', 'fields'], contacts),
  query: pick(['page', 'search'], pathOr({}, ['list', 'data'], groups)),
})

const itemDispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId, itemId, query }) =>
        () =>
          itemId
            ? Promise.all([
                dispatch(getCustomFields({ token, orgId })),
                dispatch(getGroupsItem({ itemId, token, orgId })),
                dispatch(getGroups({ token, orgId, query })),
              ])
            : dispatch(getCustomFields({ token, orgId })),
      getGroups,
      submit: ({ token, orgId, item }) =>
        saveGroupsItem({
          token,
          orgId,
          item: createNested(item),
        }),
      deleteGroupsItem,
    },
    dispatch,
  )

// eslint-disable-next-line import/prefer-default-export
export const GroupsItem = connect(itemSelector, itemDispatcher)(Item)

const SegmentsItemSelector = ({ auth, user, contacts, customFields, segments }) => ({
  hydrateProps: ['token', 'orgId', 'itemId'],
  error: {
    ...customFields.error,
    ...segments.item.error,
  },
  item: selectGroup(segments),
  loading:
    contacts.item.loading ||
    contacts.list.loading ||
    customFields.loading ||
    segments.item.loading ||
    segments.list.loading,
  personalizationList: selectPersonalizationList({ customFields }),
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  specs: selectSegmentsItemConfig({ user }),
  headers: selectSegmentsHeaders({ customFields }),
  fieldErrors: pathOr({}, ['item', 'error', 'fields'], segments),
  page: pathOr(1, ['list', 'data', 'page'], contacts),
})

const segementsItemDispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId, itemId }) =>
        () =>
          itemId
            ? Promise.all([
                dispatch(getCustomFields({ token, orgId })),
                dispatch(getSegmentsItem({ itemId, token, orgId })),
              ])
            : dispatch(getCustomFields({ token, orgId })),
      getSegments,
      submit: ({ token, orgId, item }) =>
        saveSegmentsItem({
          token,
          orgId,
          item: createNested(item),
        }),
      deleteSegmentsItem,
    },
    dispatch,
  )

// eslint-disable-next-line import/prefer-default-export
export const SegmentsItem = connect(SegmentsItemSelector, segementsItemDispatcher)(Segment)
