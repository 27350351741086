import React, { CSSProperties } from 'react'
import { Input } from '@/app/component/atom/form'
import { getNumberedInputError } from '@/app/module/campaigns/helpers'
import ErrorText from './error-text'

type Props = {
  afterText?: string
  beforeText?: string
  'data-testid'?: string
  disabledText?: string
  editable?: boolean
  id?: string
  label?: string
  max?: number
  min: number
  style?: CSSProperties
  value: string

  onChange: ({ value }: { value: string }) => void
}

export default function QuestionNumberInput({
  min = 1,
  max,
  value,
  style = {},
  editable = true,
  disabledText,
  onChange,
  label = '',
  beforeText = '',
  afterText = '',
  'data-testid': dataTestId,
}: Props) {
  const [state, setState] = React.useState({
    value,
    error: getNumberedInputError(value, min, max),
  })

  React.useEffect(() => {
    setState((s) => {
      if (value !== s.value) {
        return {
          ...s,
          value,
          error: getNumberedInputError(value, min, max),
        }
      }

      return s
    })
  }, [max, min, value])

  const changeHandler = ({ value: newValue, error }: { value: string; error: string }) => {
    setState({
      value: newValue,
      error,
    })
    onChange({ value: newValue })
  }

  return (
    <>
      {beforeText}{' '}
      <div
        style={{
          display: 'inline-block',
          position: 'relative',
          marginTop: '-3px',
          ...style,
        }}
      >
        <span style={{ visibility: 'hidden', padding: '2px 24px 2px 6px' }}>{state.value}</span>
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
          }}
        >
          <Input
            name="number-input"
            type="number"
            label={label}
            variant="outlined"
            editable={editable}
            disabledText={disabledText}
            inputProps={{
              min,
              max,
              'data-testid': dataTestId,
              style: {
                padding: '2px 6px',
              },
            }}
            InputProps={{
              style: {
                color: 'inherit',
                fontSize: 'inherit',
                width: 'auto',
                minWidth: '0',
              },
            }}
            value={state.value}
            error={state.error ? true : undefined}
            onChange={({ value: newValue }) => {
              changeHandler({
                value: newValue,
                error: getNumberedInputError(newValue, min, max),
              })
            }}
          />
        </div>
      </div>{' '}
      {afterText}
      {afterText ? ' ' : ''}
      <ErrorText>{state.error ? `(${state.error})` : ''}</ErrorText>
    </>
  )
}
