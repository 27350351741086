import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

export default function PlanList({ items }) {
  return (
    <List
      style={{
        maxHeight: '200px',
        overflowY: 'scroll',
      }}
    >
      {items.map(({ label, value, action, special }, index) => (
        <div key={index}>
          <ListItem>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography component="div" variant="body2">
                  {special ? <strong>{label}</strong> : label}
                </Typography>
                {action && action}
              </div>
              <div>
                <Typography component="div" variant="body2">
                  {special ? <strong>{value}</strong> : value}
                </Typography>
              </div>
            </div>
          </ListItem>
          {index < items.length - 1 && <Divider />}
        </div>
      ))}
    </List>
  )
}
