import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import { FixMeLater } from '@/config/types'
import { Input, Select } from '@/app/component/atom/form'
import Fields from '@/app/component/wrapper/fields'
import validate, { hasEmailError, hasRequiredError } from '@/app/service/validate'
import { InvitePayload } from '@/app/module/user/types'

type Props = {
  open: boolean

  onClose: () => void
  onSubmit: (item: InvitePayload) => Promise<void | Error>
}

const initialFields: InvitePayload = {
  email: '',
  firstName: '',
  lastName: '',
  role: 'manager',
}

const UserInviteDialog: React.FC<Props> = ({ open, onClose, onSubmit }) => {
  const [formFields, setFormFields] = React.useState(initialFields)
  const [loading, setLoading] = React.useState(false)

  const handleInviteUser = async (item: InvitePayload) => {
    setFormFields(item)
    setLoading(true)
    try {
      const res = await onSubmit(item)
      if (!(res instanceof Error)) {
        onClose()
        setFormFields(initialFields)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon>person_add</Icon>
            <Typography style={{ paddingLeft: '5px' }} variant="h6">
              Invite a user
            </Typography>
          </div>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <Fields
        fields={formFields}
        validation={{
          email: validate(hasRequiredError, hasEmailError),
        }}
        onSubmit={({ item }: { item: InvitePayload }) => handleInviteUser(item)}
      >
        {({ fields, changed, hasErrors, submitHandler, fieldChangeHandler, fieldBlurHandler }: FixMeLater) => (
          <form
            onSubmit={(e) => {
              e.persist?.()
              e.preventDefault()
              submitHandler()
            }}
            noValidate
          >
            <DialogContent>
              <Typography variant="caption" color="textSecondary">
                Invited users will receive an invitation email to this organization. Upon acceptance of the invitation,
                invited users will join with the selected role.
              </Typography>
              <Input
                name="email"
                type="email"
                label="Email"
                autoFocus
                editable={true}
                value={fields.email.value}
                error={fields.email.error}
                onChange={({ name, value }) => {
                  fieldChangeHandler({ name, value })
                }}
                onBlur={fieldBlurHandler}
              />
              <Input
                name="firstName"
                type="text"
                label="First name"
                editable={true}
                value={fields.firstName.value}
                error={fields.firstName.error}
                onChange={({ name, value }) => {
                  fieldChangeHandler({ name, value })
                }}
                onBlur={fieldBlurHandler}
              />
              <Input
                name="lastName"
                type="text"
                label="Last name"
                editable={true}
                value={fields.lastName.value}
                error={fields.lastName.error}
                onChange={({ name, value }) => {
                  fieldChangeHandler({ name, value })
                }}
                onBlur={fieldBlurHandler}
              />
              <Select
                name="role"
                label="Role"
                error={fields.role.error}
                value={fields.role.value}
                values={roleOptions}
                onChange={({ name, value }) => {
                  fieldChangeHandler({ name, value })
                }}
              />
            </DialogContent>
            <DialogActions style={{ paddingBottom: '1rem' }}>
              <Button type="submit" variant="contained" color="primary" disabled={!changed || hasErrors || loading}>
                Invite
              </Button>
            </DialogActions>
          </form>
        )}
      </Fields>
    </Dialog>
  )
}

const roleOptions = [
  {
    value: 'manager',
    label: 'Manager',
  },
  {
    value: 'collaborator',
    label: 'Collaborator',
  },
  {
    value: 'cati_agent',
    label: 'CATI Agent',
  },
]

export default UserInviteDialog
