import Button from '@mui/material/Button'
import { isEmpty, pathOr, pick } from 'ramda'
import { Component } from 'react'
import FormLayout from '../../../../component/layout/form'
import validate, { hasRequiredError } from '../../../../service/validate'

const defaultValidation = {
  firstName: validate(hasRequiredError),
  lastName: validate(hasRequiredError),
  country: validate(hasRequiredError),
  organization: validate(hasRequiredError),
  organizationType: validate(hasRequiredError),
}

const defaultHeaders = [
  {
    fields: ['firstName'],
  },
  {
    fields: ['lastName'],
  },
  {
    fields: ['organization'],
  },
  {
    fields: ['organizationType'],
  },
  {
    fields: ['country'],
  },
]

const defaultGetDefaultFields = (specs) => ({
  firstName: '',
  lastName: '',
  organization: '',
  organizationType: pathOr('', ['organizationType', 'values', 0, 'value'], specs),
  country: pathOr('', ['country', 'defaultValue'], specs),
})

export default class SignupProfile extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data) ||
      (isEmpty(this.props.specs.country.values) && !isEmpty(nextProps.specs.country.values)) ||
      (isEmpty(this.props.specs.country.defaultValue) && !isEmpty(nextProps.specs.country.defaultValue))
    )
  }

  render() {
    const {
      data,
      specs,
      onSubmit,
      headers = defaultHeaders,
      validation = defaultValidation,
      getDefaultFields = defaultGetDefaultFields,
    } = this.props

    const fields = pick(['firstName', 'lastName', 'organization', 'organizationType', 'country'], data)

    return (
      <FormLayout
        submitLabel="Next"
        loading={false}
        headers={headers}
        values={isEmpty(fields) ? getDefaultFields(specs) : fields}
        config={specs}
        validation={validation}
        onSubmit={({ item }) =>
          onSubmit({
            ...data,
            ...item,
          })
        }
        Actions={({ changed, hasErrors }) => (
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={(!changed && isEmpty(fields)) || hasErrors}
          >
            Next
          </Button>
        )}
      />
    )
  }
}
