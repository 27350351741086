import CopyBox from '@/app/component/atom/copy-box'
import DeleteDialog from '@/app/component/atom/delete-dialog'
import EditableText from '@/app/component/atom/form/editableText'
import CustomTooltip from '@/app/component/atom/tooltip'
import Loading from '@/app/component/guard/loading'
import FloatingSaveBarCont from '@/app/component/layout/floating-save-bar'
import IconText from '@/app/component/layout/icon-text'
import createHydrated from '@/app/component/wrapper/hydratable'
import { NO_SENDER_ID_TEXT, CAMPAIGN_NO_CONTENT, getCampaignType } from '@/app/module/campaigns/helpers'
import { getMessageTypesCount, hasVoice } from '@/app/module/campaigns/message-type-helpers'
import { isReminderCampaign } from '@/app/module/campaigns/models/classes/reminder-campaign.class'
import isCampaignClass from '@/app/module/campaigns/models/guard'
import { getCampaignTemplate, isSkippingLanguage } from '@/app/module/campaigns/template-helpers'
import { BackendCampaignTemplate, CampaignTemplateType } from '@/app/module/campaigns/types'
import TourContainer from '@/app/module/tour/tour.container'
import { checkCampaignForValidationErrors } from '@/app/module/campaigns/campaign-validation-helpers'
import { Box, IconButton } from '@mui/material'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import { equals, isEmpty, move, omit, path, pathOr } from 'ramda'
import { PureComponent } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { withStyles } from 'tss-react/mui'
import { generateCampaignName } from '@/app/module/campaigns/utils/transform'
import { fromCampaign } from '@/app/module/campaigns/utils/campaign-content'
import CampaignDrawer from './campaign-drawer'
import CampaignSidebar from './campaign-sidebar'
import { getCampaignItem } from './item-helpers'
import ItemProvider from './item-provider'
import ExitConfirm from './item/exit-confirm'
import ConfirmationDialog from './item/manage/subscriptions/confirmation-dialog'
import CallWindow from './item/steps/call-window/call-window'
import Confirm from './item/steps/confirm'
import Content from './item/steps/content'
import DefaultEventTimeContainer from './item/steps/event-time/default-event-time.container'
import LanguagesContainer from './item/steps/languages/language-configuration.container'
import PhoneNumberContainer from './item/steps/phone/phone-number.container'
import ReconnectContainer from './item/steps/reconnect/reconnect.container'
import RetryContainer from './item/steps/retry/retry.container'
import Subscriptions from './item/steps/subscriptions'
import PublishedSubscriptions from './item/steps/subscriptions/published'
import VoicemailContainer from './item/steps/voicemail/voicemail.container'
import PublishedBanner from './publishedBanner'

const styles = () => ({
  step: {
    '& > *': {
      cursor: 'pointer !important',
    },
  },
  pageTitle: {
    width: '100%',
    marginTop: '2rem',
  },
})

export const STEP_MAP = [
  'languages',
  'event-time',
  'content',
  'subscriptions',
  'number',
  'reconnect',
  'retry',
  'voicemail',
  'call-windows',
  'confirm',
]

const getContactsComponent = (published) => {
  if (published) {
    return PublishedSubscriptions
  }
  return Subscriptions
}

const STEPS = ({ hasLanguage, hasVoiceMessage, isImmediate, isReminder, published, type }) => {
  let phoneNumberName = 'Phone Numbers'
  if (type === 'smsblast') {
    phoneNumberName = 'Sender ID'
  } else if (type === 'ivr') {
    phoneNumberName = 'Caller IDs, etc.'
  } else if (['cati', 'drip'].includes(type)) {
    phoneNumberName = 'Sender IDs, etc.'
  }
  const ContactsComponent = getContactsComponent(published)
  const allSteps = {
    languages: {
      name: 'languages',
      title: 'Languages',
      icon: 'language',
      saveButtonLabel: 'Save',
      submitButtonLabel: 'Next',
      Component: LanguagesContainer,
      disabled: !hasLanguage,
    },
    content: {
      name: 'content',
      title: 'Content',
      icon: 'edit',
      saveButtonLabel: 'Save',
      submitButtonLabel: 'Next',
      Component: Content,
    },
    'event-time': {
      name: 'event-time',
      title: 'Event Time',
      icon: 'schedule',
      saveButtonLabel: 'Save',
      submitButtonLabel: 'Next',
      Component: DefaultEventTimeContainer,
      disabled: !isReminder,
    },
    subscriptions: {
      name: 'subscriptions',
      title: 'Contacts',
      icon: 'people',
      saveButtonLabel: 'Save',
      submitButtonLabel: 'Next',
      Component: ContactsComponent,
    },
    number: {
      name: 'number',
      title: phoneNumberName,
      icon: 'ad_units',
      saveButtonLabel: 'Save',
      submitButtonLabel: 'Next',
      Component: PhoneNumberContainer,
    },
    reconnect: {
      name: 'reconnect',
      title: 'Reconnect Calls',
      icon: 'phone',
      saveButtonLabel: 'Save',
      submitButtonLabel: 'Next',
      Component: ReconnectContainer,
      disabled: type !== 'ivr',
    },
    retry: {
      name: 'retry',
      title: 'Retry Calls',
      icon: 'phone',
      saveButtonLabel: 'Save',
      submitButtonLabel: 'Next',
      Component: RetryContainer,
      disabled: !hasVoiceMessage,
    },
    voicemail: {
      name: 'voicemail',
      title: 'Voicemail Detection',
      icon: 'phone',
      saveButtonLabel: 'Save',
      submitButtonLabel: 'Next',
      Component: VoicemailContainer,
      disabled: !hasVoiceMessage,
    },
    'call-windows': {
      name: 'call-windows',
      title: 'Call Windows',
      icon: 'phone',
      saveButtonLabel: 'Save',
      submitButtonLabel: 'Next',
      Component: CallWindow,
      disabled: !hasVoiceMessage,
    },
    confirm: {
      name: 'confirm',
      title: published ? 'Save Updates' : 'Schedule & Launch',
      icon: 'check',
      saveButtonLabel: `Save${published ? ' & Close' : ''}`,
      submitButtonLabel: isImmediate ? 'Launch Now' : 'Schedule for Later',
      Component: Confirm,
    },
  }

  return {
    stepsMap: STEP_MAP,
    steps: STEP_MAP.reduce(
      (acc, key, index) => ({
        ...acc,
        [index]: allSteps[key],
      }),
      {},
    ),
  }
}

class CampaignsItem extends PureComponent {
  state = {
    step: 0,
    item: getCampaignItem(this.props),
    changed: false,
    showExitConfirmDialog: false,
    loading: false,
    isTitleEditable: false,
    newItems: [],
    showValidationErrors: false,
    isValid: false,
    errorMessages: [],
    languageDialogOpen: false,
    drawerOpen: true,
    activePart: undefined,
    phoneNumberDialogOpen: null,
  }

  hasDrawer = () => this.state.item?.type !== 'smsblast'

  hasLanguage = () => !['smsblast', 'whatsappsurvey'].includes(this.state.item?.type)

  hasPhoneNumber = () => {
    const { messages } = this.props
    const { item } = this.state

    if (messages.loading) {
      return true
    }

    if (['smssurvey', 'whatsappsurvey'].includes(item.type)) {
      const { senderIds, whatsappSenderIds } = messages

      if (item.type === 'smssurvey') {
        return !!senderIds.length
      }
      return !!whatsappSenderIds.length
    }

    return true
  }

  getSteps = () => {
    const { item } = this.state
    const { item: propsItem, itemId } = this.props
    const type = itemId ? propsItem.type : item.type

    const hasLanguage = this.hasLanguage()
    const hasVoiceMessage = type === 'ivr' || (type === 'drip' && hasVoice(getMessageTypesCount(item)))
    const isImmediate = pathOr(true, ['launch', 'immediate'], item)
    const isReminder = isReminderCampaign(itemId ? propsItem : item)
    const published = pathOr(false, ['published'], item)

    return STEPS({ hasLanguage, hasVoiceMessage, isImmediate, isReminder, published, type })
  }

  getFirstStep = () => {
    const { steps } = this.getSteps()

    return Math.max(
      Object.keys(steps).findIndex((key) => !steps[key].disabled),
      0,
    )
  }

  getCurrentStep = () => {
    const { itemId } = this.props
    const { step } = this.state

    if (!itemId) {
      return step
    }

    const { steps } = this.getSteps()
    return Object.keys(steps).findIndex((key) => steps[key].name === this.props.step)
  }

  getPreviousStep = () => {
    const { steps } = this.getSteps()
    const step = this.getCurrentStep()

    let previousStep = step - 1
    if (!steps[previousStep]) {
      return step
    }
    while (steps[previousStep].disabled) {
      previousStep -= 1
      if (!steps[previousStep]) {
        return step
      }
    }
    return previousStep
  }

  getNextStep = (step = this.getCurrentStep()) => {
    const { steps, stepsMap } = this.getSteps()

    let nextStep = step + 1
    if (!stepsMap[nextStep]) {
      return step
    }
    while (steps[nextStep].disabled) {
      nextStep += 1
      if (!steps[nextStep]) {
        return step
      }
    }
    return nextStep
  }

  getLastStep = () => {
    const { steps } = this.getSteps()

    return Object.keys(steps).length - 1
  }

  isValidStep = (step) => {
    const { steps } = this.getSteps()

    return !!Object.values(steps).find((s) => !s.disabled && s.name === step)
  }

  componentDidMount() {
    const { step, itemId, history } = this.props

    if (this.state.step === this.getLastStep() || this.props.step === 'confirm') {
      this.checkForInvalidMessages(this.state.item)
    }

    const template = getCampaignTemplate(this.props.history)
    this.updateItemState({
      name: generateCampaignName(this.state.item, template),
    })

    let firstStep = this.getFirstStep()
    const skippingLanguage = isSkippingLanguage(this.state.item.type, template)
    if (skippingLanguage) {
      firstStep = this.getNextStep(firstStep)
    }

    if (!itemId) {
      this.setState({
        step: firstStep,
        languageDialogOpen: !skippingLanguage && this.hasLanguage(),
      })
    } else if (!step) {
      history.push(`/campaigns/edit/${itemId}/content`)
    }
  }

  componentDidUpdate(prevProps) {
    const { orgId, item, itemId, history, changed, onUpdate, step, type, loading } = this.props
    if (prevProps.orgId && !isEmpty(prevProps.orgId) && prevProps.orgId !== orgId) {
      history.push('/campaigns?page=1')
    }
    // for EDIT cases where the item's request gets received after mount
    if (path(['type'], item) && ((isEmpty(prevProps.item) && !isEmpty(item)) || prevProps.item.id !== item.id)) {
      this.setState({
        item,
      })
    }

    if (!this.state.activePart && this.state.item?.variables?.parts?.length > 0) {
      this.setState({
        activePart: this.state.item.variables.parts[0].id,
      })
    }

    if (
      step === 'confirm' &&
      path(['type'], item) &&
      ((isEmpty(prevProps.item) && !isEmpty(item)) || prevProps.item.id !== item.id)
    ) {
      this.checkForInvalidMessages(item)
    }

    if (prevProps.changed !== changed) {
      onUpdate(changed)
    }

    if (type && prevProps.type !== type) {
      this.updateItemState({
        type,
      })
    }

    if (!loading && step && !this.isValidStep(step)) {
      const firstStep = this.getFirstStep()
      if (!itemId) {
        this.setState({
          step: firstStep,
        })
      } else {
        history.push(`/campaigns/edit/${itemId}/content`)
      }
    }

    if (!itemId && this.state.step === this.getFirstStep() && !equals(this.props.messages, prevProps.messages)) {
      this.setState({
        phoneNumberDialogOpen: !this.hasPhoneNumber(),
      })
    }
  }

  componentWillUnmount() {
    this.props.clearItem()
  }

  checkForInvalidMessages = (item) => {
    const { senderIdsForDrip } = pathOr([], ['messages'], this.props)
    const { isValid, errorMessages } = checkCampaignForValidationErrors({
      item,
      contacts: this.props.contacts,
      senderIdsForDrip,
    })
    this.setState({
      isValid,
      showValidationErrors: !isValid,
      errorMessages,
    })
  }

  save({ item, toBePublished }) {
    const { token, orgId, saveCampaign } = this.props

    return saveCampaign({
      token,
      orgId,
      item,
      toBePublished,
    })
  }

  goToStep(index) {
    const { itemId, history } = this.props
    const { stepsMap } = this.getSteps()
    if (index === this.getLastStep()) {
      this.checkForInvalidMessages(this.state.item)
    }
    this.setState({
      step: index,
    })

    if (itemId) {
      history.push(`/campaigns/edit/${itemId}/${stepsMap[index]}`)
    }
  }

  setLoading = (loading) => {
    this.setState({
      loading,
    })
  }

  toggleTitleEdit = () => {
    this.setState({
      isTitleEditable: !this.state.isTitleEditable,
    })
  }

  addNewItem = ({ id }) => {
    this.setState({
      activePart: id,
      newItems: [...this.state.newItems, id],
    })
  }

  /**
   * Since spread operator (...) can only copy enumerable properties, we need to manually call campaign instance's update method
   * Otherwise, campaign class guard will fail
   */
  updateItemState = (partialItem) => {
    const { item } = this.state
    let newItem = item
    if (isCampaignClass(item)) {
      newItem = item.update(partialItem)
    } else {
      newItem = {
        ...item,
        ...partialItem,
      }
    }
    this.setState({
      item: newItem,
    })
  }

  transformItem = () => {
    const { item } = this.state
    return isCampaignClass(item) ? item : fromCampaign(item, getCampaignTemplate(this.props.history))
  }

  onSaveOrLaunch = (activeStep) => {
    const { setPublishItem, token, orgId, onFinish, onSave, setChanged } = this.props

    const { stepsMap } = this.getSteps()
    const lastStep = this.getLastStep()
    if (activeStep === lastStep) {
      const item = this.transformItem()
      this.save({
        item,
        toBePublished: true,
      })
        .then(async (res) => {
          if (res instanceof Error) {
            return res
          }
          setChanged(false)
          onSave({ id: res.id, tab: stepsMap[lastStep] })
          const response = await setPublishItem({
            token,
            orgId,
            itemId: res.id,
            isPublished: true,
            item,
          })
          if (response instanceof Error) {
            return response
          }
          return {
            ...res,
            published: true,
          }
        })
        .then((res) => {
          if (res.published) {
            onFinish()
          }
          if (!res.published && res.id) {
            onSave({ id: res.id })
          }
        })
    } else {
      this.goToStep(this.getNextStep())
    }
  }

  onItemChange = (newItem) => {
    const { changed, history, setChanged } = this.props
    if (
      newItem?.variables?.senderIdQuestions !== this.state.item?.variables?.senderIdQuestions ||
      newItem?.variables?.senderIdReplies !== this.state.item?.variables?.senderIdReplies ||
      newItem?.variables?.senderIdWhatsapp !== this.state.item?.variables?.senderIdWhatsapp ||
      !equals(newItem?.variables?.callerIds, this.state.item?.variables?.callerIds) ||
      newItem?.variables?.senderId !== this.state.item?.variables?.senderId
    ) {
      this.checkForInvalidMessages(newItem)
    }
    if (path(['id'], newItem) === path(['id'], this.state.item)) {
      if (!changed) {
        setChanged(true)
      }
      const type = this.state.item.type || this.props.type
      this.updateItemState({
        ...newItem,
        name: generateCampaignName({ name: this.state.item.name, type }, getCampaignTemplate(history)),
        type,
      })
    }
  }

  setActivePart = (partId) => {
    const { stepsMap } = this.getSteps()

    this.setState({
      step: stepsMap.indexOf('content'),
      activePart: partId,
    })
  }

  handleReorder = (fromIndex, toIndex) => {
    const { item } = this.state
    const { setChanged } = this.props
    const { parts } = item.variables
    this.updateItemState({
      variables: {
        ...item.variables,
        parts: move(fromIndex, toIndex, parts),
      },
    })
    if (!this.state.changed) {
      setChanged(true)
    }
  }

  onPhoneNumberDialogClose = () => {
    const nextStep = this.getNextStep()
    this.goToStep(nextStep)
    this.setState({
      phoneNumberDialogOpen: false,
    })
  }

  getDrawerRight = (name) => {
    const { contacts } = this.props
    const { item } = this.state
    const { senderIdsForDrip } = pathOr([], ['messages'], this.props)
    const { errorMessages } = checkCampaignForValidationErrors({
      item,
      contacts,
      senderIdsForDrip,
    })

    switch (name) {
      case 'content': {
        if (errorMessages.includes(CAMPAIGN_NO_CONTENT)) {
          return (
            <CustomTooltip title={CAMPAIGN_NO_CONTENT}>
              <div>
                <IconButton disabled>
                  <Icon color="warning">warning_amber</Icon>
                </IconButton>
              </div>
            </CustomTooltip>
          )
        }

        return (
          <CustomTooltip title="Toggle content navigator">
            <IconButton
              id="campaign-drawer-toggle"
              color={this.state.drawerOpen ? 'primary' : 'disabled'}
              onClick={() =>
                this.setState({
                  drawerOpen: !this.state.drawerOpen,
                })
              }
            >
              <Icon>segment</Icon>
            </IconButton>
          </CustomTooltip>
        )
      }

      case 'number': {
        if (errorMessages.includes(NO_SENDER_ID_TEXT)) {
          return (
            <CustomTooltip title={NO_SENDER_ID_TEXT}>
              <div>
                <IconButton disabled>
                  <Icon color="warning">warning_amber</Icon>
                </IconButton>
              </div>
            </CustomTooltip>
          )
        }

        break
      }

      default:
        break
    }

    return undefined
  }

  handleSave = () => {
    const { itemId, onFinish, onSave, setChanged, changed } = this.props
    const { stepsMap } = this.getSteps()

    this.setLoading(true)
    const item = this.transformItem()

    this.save({ item, toBePublished: false })
      .then((res) => {
        if (!(res instanceof Error)) {
          if (changed) {
            setChanged(false)
          }
          this.updateItemState({
            ...this.state.item,
          })
          this.setState({
            newItems: [],
          })
          if (!itemId && res.id) {
            onSave({ id: res.id, tab: stepsMap[this.state.step] })
          }
          if (res.published) {
            onFinish()
          }
        }
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  isCampaignTypeValid = () => {
    const { item, itemId, loading } = this.props
    const isDefaultItem = item && !item.type // if there's item but no type, the UI hasn't loaded yet since item was initialized as { type: undefined }
    const isTypeValid = item?.type && Object.values(CampaignTemplateType).includes(item.type) // for non-class campaigns, should be removed when all campaigns are classes
    const isTemplateValid = item?.template && Object.values(BackendCampaignTemplate).includes(item.template)

    return loading || !itemId || isDefaultItem || isTypeValid || isTemplateValid
  }

  backToCampaigns = () => this.props.history.push('/campaigns')

  render() {
    const classes = withStyles.getClasses(this.props)
    const { step, error, loading, itemId, onFinish, files } = this.props
    const { loadingItems } = files

    const { steps, stepsMap } = this.getSteps()
    const activeStep = itemId ? Math.max(0, stepsMap.indexOf(step)) : this.state.step

    const published = pathOr(false, ['published'], this.state.item)
    const { saveButtonLabel, submitButtonLabel, Component } = steps[activeStep]

    const isLoading = loading

    const campaignType = getCampaignType(this.state.item)
    const campaignName = pathOr('', ['name'], this.state.item)

    const isFirstStep = activeStep === this.getFirstStep()
    const isLastStep = activeStep === this.getLastStep()

    const { drawerOpen } = this.state

    const isCampaignTypeValid = this.isCampaignTypeValid()

    const isSidebarSaveDisabled = this.state.loading || loadingItems.length > 0 || published

    return (
      <Loading isLoading={isLoading} error={error}>
        {!isCampaignTypeValid && (
          <ConfirmationDialog
            isOpen
            cancelButtonText=""
            hideCancel
            deleteButtonText="Back to campaigns"
            text="This campaign type is not yet supported."
            title="Campaign not supported"
            onClose={this.backToCampaigns}
            onConfirm={this.backToCampaigns}
          />
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <CampaignSidebar
            campaignName={campaignName}
            footer={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: (theme) => theme.spacing(1),
                }}
              >
                {!(published && isLastStep) && (
                  <CustomTooltip arrow title={isSidebarSaveDisabled ? 'To save go to the Save Updates step' : ''}>
                    <span>
                      <Button
                        id="campaign-item-save"
                        variant="outlined"
                        color="primary"
                        disabled={isSidebarSaveDisabled}
                        startIcon={<Icon>save</Icon>}
                        onClick={this.handleSave}
                      >
                        {saveButtonLabel}
                      </Button>
                    </span>
                  </CustomTooltip>
                )}
              </Box>
            }
            options={Object.keys(steps)
              .filter((key) => !steps[key].disabled)
              .map((key) => {
                const i = Number(key)
                const { icon, name, title } = steps[i]
                return {
                  active: i === activeStep,
                  icon,
                  label: title,
                  name,
                  right: this.getDrawerRight(name),
                  onClick: () => this.goToStep(i),
                }
              })}
          />
          <Box
            id="scrollable-area"
            sx={{
              flex: 1,
              height: 'calc(100vh - 64px)',
              margin: 'auto',
              overflowY: 'auto',
              padding: '0 1rem',
              position: 'relative',

              transition: (theme) =>
                !drawerOpen
                  ? theme.transitions.create('margin', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                    })
                  : theme.transitions.create('margin', {
                      easing: theme.transitions.easing.easeOut,
                      duration: theme.transitions.duration.enteringScreen,
                    }),
              marginRight: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className={classes.pageTitle}>
                <Typography component="div" variant="h6" color="textSecondary">
                  <EditableText
                    maxLength={200}
                    classes={classes}
                    displayText={`${itemId ? 'Edit' : 'Create'} ${campaignType} "${campaignName}"`}
                    value={campaignName}
                    onChange={(name) => {
                      this.updateItemState({
                        name,
                      })
                    }}
                  />
                </Typography>
              </div>
              {itemId && <CopyBox text={itemId} title="Campaign ID" />}
            </div>
            <Card style={{ margin: '2rem 0' }}>
              <ExitConfirm
                isOpen={this.state.showExitConfirmDialog}
                onSave={() => {
                  this.setState({ showExitConfirmDialog: false })
                  const item = this.transformItem()
                  this.save({ item, toBePublished: false }).then((res) => {
                    if (!(res instanceof Error)) {
                      onFinish()
                    }
                  })
                }}
                onExit={() => {
                  this.setState({ showExitConfirmDialog: false })
                  onFinish()
                }}
                onClose={() => {
                  this.setState({ showExitConfirmDialog: false })
                }}
              />
              {published && <PublishedBanner />}
            </Card>
            <DndProvider backend={HTML5Backend}>
              <ItemProvider item={this.state.item} setItem={this.onItemChange}>
                <Component
                  {...omit(['classes'], this.props)}
                  activePart={this.state.activePart}
                  item={this.state.item}
                  newItems={this.state.newItems}
                  published={published}
                  template={getCampaignTemplate(this.props.history)}
                  type={this.state.item.type}
                  addNewItem={this.addNewItem}
                  onChange={this.onItemChange}
                  onDialogClose={this.onDialogClose}
                  setActivePart={this.setActivePart}
                  setLoading={this.setLoading}
                />
              </ItemProvider>
            </DndProvider>
            <FloatingSaveBarCont>
              <Box
                sx={{
                  display: 'flex',
                  flex: '1',
                  flexDirection: 'row-reverse',
                  justifyContent: 'space-between',
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
              >
                {!isLastStep && (
                  <Button
                    id="campaign-item-next"
                    data-testid="campaign-item-next"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.goToStep(this.getNextStep())
                    }}
                  >
                    <span>{submitButtonLabel}</span>
                  </Button>
                )}
                {isLastStep && (
                  <>
                    {!published && (
                      <label htmlFor="submit">
                        <Button
                          component="div"
                          name="submit"
                          id="campaign-item-confirm"
                          data-testid="campaign-item-confirm"
                          variant="contained"
                          color="primary"
                          disabled={!this.state.isValid || this.state.loading}
                          onClick={() => this.onSaveOrLaunch(activeStep)}
                        >
                          {submitButtonLabel}
                        </Button>
                      </label>
                    )}

                    {published && (
                      <CustomTooltip
                        title={`${!this.state.isValid ? 'You have to fix the errors before you can save' : ''}`}
                        open={!this.state.isValid}
                        arrow
                      >
                        <Button
                          id="campaign-item-save"
                          data-testid="campaign-item-save"
                          variant="outlined"
                          color="primary"
                          disabled={this.state.loading || !this.state.isValid || loadingItems.length > 0}
                          onClick={this.handleSave}
                        >
                          <IconText>
                            <Icon>save</Icon>
                            <span>{saveButtonLabel}</span>
                          </IconText>
                        </Button>
                      </CustomTooltip>
                    )}
                    {this.state.showValidationErrors && (
                      <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        className="campaign-validation-popup-box"
                        data-testid="campaign-validation-popup"
                        open={true}
                        onClose={(_, reason) => {
                          if (reason === 'clickaway') {
                            return
                          }
                          this.setState({ showValidationErrors: false })
                        }}
                      >
                        <Alert
                          className="campaign-validation-popup-cont"
                          onClose={() => this.setState({ showValidationErrors: false })}
                          severity="warning"
                          sx={{ width: '100%' }}
                        >
                          <p style={{ marginTop: 0 }}>
                            Your campaign has errors. You cannot launch it until they are fixed. These items have
                            errors:
                          </p>
                          <ul style={{ paddingLeft: 0 }}>
                            {this.state.errorMessages.map((message, i) => (
                              <li key={i}>
                                {message}
                                <br />
                              </li>
                            ))}
                          </ul>
                        </Alert>
                      </Snackbar>
                    )}
                  </>
                )}
                {!isFirstStep && (
                  <Button
                    id="campaign-item-back"
                    data-testid="campaign-item-back"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      this.goToStep(this.getPreviousStep())
                    }}
                  >
                    <span>Back</span>
                  </Button>
                )}
              </Box>
            </FloatingSaveBarCont>
          </Box>

          {this.hasDrawer() && (
            <CampaignDrawer
              activePart={this.state.activePart}
              canReorder={!published}
              open={drawerOpen}
              parts={this.state.item?.variables?.parts || []}
              onClose={() => this.setState({ drawerOpen: false })}
              onOpen={() => this.setState({ drawerOpen: true })}
              onPartClick={this.setActivePart}
              onPartReorder={this.handleReorder}
            />
          )}
        </Box>
        <DeleteDialog
          cancelButtonText="No, skip languages"
          deleteButtonText="Yes, configure languages"
          icon="language"
          id="language-dialog"
          isOpen={stepsMap[this.state.step] === 'languages' && this.state.languageDialogOpen}
          text={
            <>
              Will your messages be in <b>multiple languages</b> in this campaign?
            </>
          }
          title="Language Setup"
          onClose={() => {
            this.setState({ languageDialogOpen: false })
            const nextStep = this.getNextStep()
            this.goToStep(nextStep)
          }}
          onConfirm={() => this.setState({ languageDialogOpen: false })}
        />
        <DeleteDialog
          cancelButtonText="I’ll do this later"
          deleteButtonText="Chat with Support"
          icon="phone"
          isOpen={stepsMap[this.state.step] === 'number' && !!this.state.phoneNumberDialogOpen}
          text={
            <>
              To launch an {this.state.item.type === 'smssurvey' ? 'SMS' : 'WhatsApp'} survey, you need a two-way phone
              number. Please reach out to our support team to procure one for you.
            </>
          }
          title="Missing Phone Number"
          onClose={this.onPhoneNumberDialogClose}
          onConfirm={() => {
            window.Intercom?.('showNewMessage')
            this.onPhoneNumberDialogClose()
          }}
        />
        {!this.props.itemId && <TourContainer type={this.props.type} />}
      </Loading>
    )
  }
}

export default withStyles(createHydrated(CampaignsItem), styles)
