import { map, pathOr } from 'ramda'
import { createSelector } from 'reselect'

const partSelectCards = pathOr([], ['payments', 'cards', 'data'])

export const selectCards = createSelector(
  partSelectCards,
  map(({ id, label }) => ({
    label,
    value: id,
  })),
)

export const selectAutoRecharge = pathOr(false, ['payments', 'autoRecharge', 'data'])
