import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import CardLayout from '../component/layout/card'
import ErrorLayout from '../component/layout/error'
import Layout from '../component/layout/page'
import { AuthHeader } from '../module/auth'

export default function NotFoundPage() {
  return (
    <Layout title="Not Found - engageSPARK">
      <AuthHeader />
      <CardLayout>
        <ErrorLayout status={404}>
          <Typography>This page does not exist.</Typography>
          <Typography>
            We can help find your way back <Link to="/">home</Link>
          </Typography>
        </ErrorLayout>
      </CardLayout>
    </Layout>
  )
}
