import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import React from 'react'
import { FixMeLater } from '@/config/types'
import { Select, SelectMultiple } from '@/app/component/atom/form'
import WhatsappMessage from '@/app/component/atom/whatsapp'
import Loading from '@/app/component/guard/loading'
import { DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import { formatPhoneNumber } from '@/app/service/util'
import { ContentWhatsApp } from '@/app/module/campaigns/types'
import { DEFAULT_SENDER_ID_MESSAGE } from '@/app/module/logs/definitions'
import { FileType } from '@/app/types'

type Props = {
  recipients: []
  senderIds: []
  loading: boolean
  personalizationList: FixMeLater
  getFile: FixMeLater
  saveFile: FixMeLater
  totalRecipients: number
  hydrate: FixMeLater
  onMicAccessError: FixMeLater
  sendWhatsappMessage: FixMeLater
  closeHandler: FixMeLater
  isAllContacts: boolean
  contactIds: []
  excludeIds: []
  includedGroupIds: []
  search: string
  senderId: string
}

export default function SendWhatsapp(props: Props) {
  const {
    recipients,
    senderIds,
    loading = false,
    personalizationList,
    getFile,
    saveFile,
    totalRecipients,
    hydrate,
    onMicAccessError,
    sendWhatsappMessage,
    closeHandler,
    isAllContacts,
    contactIds,
    excludeIds,
    includedGroupIds,
    search,
  } = props

  const [senderId, setSenderId] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [image, setImage] = React.useState('')
  const [audio, setAudio] = React.useState('')
  const [video, setVideo] = React.useState('')
  const [document, setDocument] = React.useState('')
  const [playId, setPlaying] = React.useState('')
  const [files, setFiles] = React.useState({})

  React.useEffect(() => {
    hydrate()
  }, [hydrate])

  const sendHandler = () => {
    sendWhatsappMessage({
      message,
      image,
      video,
      document,
      audio,
      senderId: senderId === DEFAULT_SENDER_ID_VALUE ? '' : senderId,
      isAllContacts,
      contactIds,
      excludeIds,
      includedGroupIds,
      search,
    })
      .then(() => {
        closeHandler()
      })
      .catch(() => {
        closeHandler()
      })
  }

  const saveFileHandler = (file: FixMeLater) =>
    saveFile(file).then((res: FixMeLater) => {
      if (!(res instanceof Error)) {
        if (file.type === 'image') {
          setImage(res.filename)
        } else if (file.type === 'video') {
          setVideo(res.filename)
        } else if (file.type === 'document') {
          setDocument(res.filename)
        }
      }
    })

  const saveAudioHandler = (file: { file: FixMeLater; filename: string; filter: 'string' }) =>
    saveFile(file).then((res: any) => {
      if (!(res instanceof Error)) {
        setAudio(res.filename)
      }
      return res
    })

  const removeMedia = () => {
    setImage('')
    setAudio('')
    setVideo('')
    setDocument('')
  }

  const recipientsDisplay = recipients.slice(0, 4).map(({ label, value }: { label: string; value: string }) => ({
    label: formatPhoneNumber(`+${label}`),
    value,
  }))
  if (totalRecipients > 4) {
    recipientsDisplay.push({
      label: '...',
      value: 'more',
    })
  }
  const handleGetFile = React.useCallback(
    (item: string) => getFile(item).then((file: FileType) => setFiles({ [item]: file })),
    [getFile],
  )
  const handleWhatsAppChange = React.useCallback(
    ({ text: updatedText }: ContentWhatsApp) => setMessage(updatedText || ''),
    [],
  )

  return (
    <Loading isLoading={loading}>
      <form
        id="send-message-form"
        onSubmit={(e) => {
          e.preventDefault()
          if (
            !loading &&
            (!!message || !!audio || !!image || !!video || !!document) &&
            !!senderId &&
            recipients.length
          ) {
            sendHandler()
          }
        }}
      >
        <CardContent>
          <WhatsappMessage
            audio={audio}
            document={document}
            defaultLanguage=""
            files={files}
            image={image}
            info=""
            item={{
              message: {
                audio,
                document,
                image,
                text: message,
              },
            }}
            label="message"
            loading={loading}
            personalizationList={personalizationList}
            playId={playId}
            text={message}
            video={video}
            changeHandler={handleWhatsAppChange}
            saveHandler={saveFileHandler}
            saveFileHandler={saveAudioHandler}
            getFile={handleGetFile}
            onMicAccessError={onMicAccessError}
            removeMedia={removeMedia}
            setPlaying={setPlaying}
          />
          <br />
          <SelectMultiple
            label="To"
            name="recipients"
            value={recipientsDisplay.map(({ value }) => value)}
            values={recipientsDisplay}
            editable={false}
            message={
              totalRecipients !== 1
                ? `This message will be sent to ${totalRecipients} contacts`
                : `This message will be sent to ${totalRecipients} contact`
            }
          />
          <Select
            name="senderid"
            id="send-message-sender"
            label="From"
            value={senderId}
            values={senderIds}
            editable={true}
            onChange={({ value }: any) => {
              setSenderId(value)
            }}
            info={
              senderId === DEFAULT_SENDER_ID_VALUE && (senderIds as any).length === 1 ? DEFAULT_SENDER_ID_MESSAGE : ''
            }
          />
        </CardContent>
        <CardActions>
          <Grid container direction="row" alignItems="center" justifyContent="flex-end">
            <Button
              id="send-message-action"
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                loading ||
                (!message && !audio && !image && !video && !document) ||
                !senderId ||
                senderId === '0' ||
                !recipients.length
              }
            >
              Send
            </Button>
          </Grid>
        </CardActions>
      </form>
    </Loading>
  )
}
