import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { pathOr } from 'ramda'
import React from 'react'
import createHydrated from '@/app/component/wrapper/hydratable'
import Demos from '@/app/module/demos'
import ActivePlan from './active-plan'
import ActiveCampaigns from './campaigns'
import DeliveryCharts from './deliveryChart'
import MyOrgInfo from './myOrgInfo'
import PlanBlock from './planBlock'
import TOTPWarning from './totpWarning'
import Usage from './usage'

function Dashboard(props) {
  const {
    activeOrg = {},
    profile,
    campaigns = {},
    history,
    orgId,
    token,
    getCampaignSummary,
    summary,
    dashboard,
    userLoading,
    autoRecharge = {},
    showOrgWarning,
    showTotpWarning,
    totpEnabled,
  } = props
  const { stats, plan, usage, isLoading: dashboardLoading } = dashboard

  const { ordered = [] } = campaigns
  const [activeCampaign, setActiveCampaign] = React.useState({})
  const displayCampaigns = ordered.length > 0

  const prevCampaignId = React.useRef()

  React.useEffect(() => {
    if (activeCampaign.id && activeCampaign.launched && activeCampaign.id !== prevCampaignId.current) {
      prevCampaignId.current = activeCampaign.id
      getCampaignSummary({
        itemId: activeCampaign.id,
        orgId,
        token,
      })
    }
    // eslint-disable-next-line
  }, [activeCampaign, getCampaignSummary])

  React.useEffect(() => {
    if (ordered.length > 0) {
      const initialCampaignId = campaigns.ordered[0]
      setActiveCampaign({
        id: initialCampaignId,
        type: pathOr('', ['values', initialCampaignId, 'template'], campaigns),
        launched: pathOr('', ['values', initialCampaignId, 'launched'], campaigns),
        name: pathOr('', ['values', initialCampaignId, 'name'], campaigns),
      })
    }
    // eslint-disable-next-line
  }, [ordered])

  const {
    overallMessageStats = {},
    overallVoiceStats = {},
    overallWhatsappStats = {},
  } = pathOr({}, ['itemSummary'], summary)

  return (
    <>
      {showTotpWarning && <TOTPWarning showOrgWarning={showOrgWarning} totpEnabled={totpEnabled} />}
      <div
        style={{
          width: '100%',
          margin: 'auto',
          padding: '0 1rem',
          marginTop: '30px',
        }}
      >
        <Grid
          container
          spacing={2}
          style={{
            marginBottom: '15px',
          }}
        >
          <Grid item md={4} xs={12}>
            <MyOrgInfo
              isLoading={userLoading}
              autoRecharge={autoRecharge}
              activeOrg={activeOrg}
              profile={profile}
              onAction={() => history.push('/campaigns/create')}
            />
          </Grid>
          <Grid item md={4} xs={12} className="plan">
            <ActivePlan
              isLoading={dashboardLoading}
              setPlansToggle={() => history.push('/plans')}
              plan={plan}
              usage={usage}
              role={activeOrg.role}
            />
          </Grid>
          <Grid item md={4} xs={12} className="usage">
            <Usage isLoading={dashboardLoading} plan={plan} stats={stats} usage={usage} />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{
            marginBottom: '30px',
          }}
        >
          <Grid item md={4} xs={12}>
            <Demos />
          </Grid>
          {(campaigns.isLoading || displayCampaigns) && (
            <React.Fragment>
              <Grid item md={4} xs={12}>
                <ActiveCampaigns
                  campaigns={campaigns}
                  activeCampaign={activeCampaign}
                  history={history}
                  setActiveCampaign={setActiveCampaign}
                />
              </Grid>
              {activeCampaign.launched && (
                <Grid item md={4}>
                  {overallMessageStats.total > 0 || overallVoiceStats.total > 0 || overallWhatsappStats.total > 0 ? (
                    <DeliveryCharts
                      summary={pathOr(false, ['itemSummary'], summary)}
                      activeCampaign={activeCampaign}
                      isLoading={pathOr(false, ['isLoading'], summary)}
                    />
                  ) : (
                    <PlanBlock isLoading={summary.isLoading}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <Typography variant="h5" color="primary">
                          No data to show for this campaign
                        </Typography>
                      </div>
                    </PlanBlock>
                  )}
                </Grid>
              )}
            </React.Fragment>
          )}
        </Grid>
      </div>
    </>
  )
}

export default createHydrated(Dashboard)
