import { Checkbox, ListItemText, MenuItem, MenuItemProps } from '@mui/material'
import React from 'react'

type Props = MenuItemProps & {
  checked: boolean
  label: string
}

const SelectMultipleItem = React.forwardRef<HTMLLIElement, Props>(({ checked, label, value, ...props }, ref) => (
  <MenuItem ref={ref} value={value} {...props}>
    <Checkbox checked={checked} />
    <ListItemText primary={label} />
  </MenuItem>
))

export default React.memo(SelectMultipleItem)
