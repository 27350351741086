import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import IconText from '@/app/component/layout/icon-text'

type ExitConfirmDialogProps = {
  isOpen: boolean
  onSave: () => void
  onExit: () => void
  onClose: () => void
}

export default function ExitConfirmDialog({ isOpen, onSave, onExit, onClose }: ExitConfirmDialogProps) {
  return (
    <Dialog id="campaign-item-exit-dialog" open={!!isOpen} onClose={onClose}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <IconText>
          <Icon>warning</Icon>
          <Typography variant="h6">Warning</Typography>
        </IconText>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '24px' }}>
        <DialogContentText>Closing may result in losing all your unsaved data.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id="campaign-item-exit-cancel" variant="outlined" onClick={onClose}>
          Back
        </Button>
        <Button id="campaign-item-exit-cancel" variant="outlined" color="primary" onClick={onExit}>
          Exit without saving
        </Button>
        <Button id="campaign-item-exit-save" variant="contained" color="primary" onClick={onSave}>
          Save and exit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
