import React from 'react'
import { CountryTimezone, TimezoneWithCountryCodeType } from '@/app/types'
import { CampaignItemWithVoice } from '@/app/module/campaigns/types'
import Retry from './retry'

type Props = {
  countriesTimezones: CountryTimezone[]
  countryCode: string
  item: CampaignItemWithVoice
  timezone: string
  timezones: TimezoneWithCountryCodeType

  onChange: (item: CampaignItemWithVoice) => void
}

const RetryContainer: React.FC<Props> = (props) => <Retry {...props} />

export default RetryContainer
