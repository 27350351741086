import React from 'react'
import FormLayout from '@/app/component/layout/form'
import Modal from '@/app/component/layout/modal'
import { FormHeader, FormSpecs, Organization } from '@/app/types'
import { validation } from '@/app/module/user/organization-helpers'

type Props = {
  addNewSpecs: FormSpecs
  headers: FormHeader[]
  loading: boolean
  token: string

  addOrganization: (params: { token: string; item: Organization }) => Promise<void>
  onClose: () => void
}

const initialState: Partial<Organization> = {
  address: '',
  country: '',
  location: '',
  name: '',
  phone: '',
  website: '',
}

const AddOrganizationModal: React.FC<Props> = ({ addNewSpecs, headers, loading, token, addOrganization, onClose }) => {
  const [state, setState] = React.useState<Partial<Organization>>(initialState)

  const handleSubmit = async ({ item }: { item: Organization }) => {
    setState(item)
    try {
      await addOrganization({
        token,
        item,
      })
      onClose()
    } catch {
      // ignore as error is handled in action
    }
  }

  return (
    <Modal isOpen title="Add new Organization" onClose={onClose}>
      <FormLayout
        id="add-organization"
        config={addNewSpecs}
        headers={headers}
        loading={loading}
        submitLabel="Create"
        values={state}
        validation={validation}
        onSubmit={handleSubmit}
      />
    </Modal>
  )
}

export default AddOrganizationModal
