import { Icon, styled, Tab, Tabs } from '@mui/material'
import React from 'react'
import { WhatsAppTab } from '@/app/component/atom/whatsapp/helpers'
import CustomTooltip from '@/app/component/atom/tooltip'
import { AnswerType } from './question-text-props'

type Props = {
  answerType: AnswerType
  tab: WhatsAppTab

  onChange: (answerType: AnswerType) => void
}

const ResponseWhatsappTabs: React.FC<Props> = ({ answerType, tab, onChange }) => {
  const tabNodes = React.useMemo(() => {
    const tabs: React.ReactNode[] = []
    if (tab === WhatsAppTab.Text) {
      tabs.push(
        <StyledTab
          className="campaign-whatsapp-buttons-tab"
          icon={<Icon>list_alt</Icon>}
          key={AnswerType.WhatsAppButtons}
          label="WhatsApp Buttons"
          value={AnswerType.WhatsAppButtons}
          wrapped
        />,
      )
      tabs.push(
        <StyledTab
          className="campaign-whatsapp-list-tab"
          icon={<Icon>list</Icon>}
          key={AnswerType.WhatsAppList}
          label="WhatsApp List"
          value={AnswerType.WhatsAppList}
          wrapped
        />,
      )
    } else {
      tabs.push(
        <CustomTooltip key={AnswerType.WhatsAppButtons} title="Only text message can be used with WhatsApp Buttons.">
          <div>
            <StyledTab
              className="campaign-whatsapp-buttons-tab"
              disabled
              icon={<Icon>list_alt</Icon>}
              label="WhatsApp Buttons"
              value={AnswerType.WhatsAppButtons}
              wrapped
            />
          </div>
        </CustomTooltip>,
      )
      tabs.push(
        <CustomTooltip key={AnswerType.WhatsAppList} title="Only text message can be used with a WhatsApp List.">
          <div>
            <StyledTab
              className="campaign-whatsapp-list-tab"
              disabled
              icon={<Icon>list</Icon>}
              label="WhatsApp List"
              value={AnswerType.WhatsAppList}
              wrapped
            />
          </div>
        </CustomTooltip>,
      )
    }
    tabs.push(
      <Tab
        className="campaign-specific-tab"
        icon={<Icon>chat</Icon>}
        key={AnswerType.Text}
        label="Specific Response"
        value={AnswerType.Text}
        wrapped
      />,
    )
    tabs.push(
      <Tab
        className="campaign-open-ended-tab"
        icon={<Icon>textsms</Icon>}
        key={AnswerType.OpenEnded}
        label="Open Response"
        value={AnswerType.OpenEnded}
        wrapped
      />,
    )

    return tabs
  }, [tab])

  return (
    <Tabs value={answerType} indicatorColor="primary" onChange={(_, val) => onChange(val)}>
      {tabNodes.map((t) => t)}
    </Tabs>
  )
}

const StyledTab = styled(Tab)({
  zIndex: 2,
})

export default ResponseWhatsappTabs
