import { FilesType } from '@/app/types'
import { Theme } from '@mui/material'
import Chip from '@mui/material/Chip'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { isEmpty, path, pathOr } from 'ramda'
import React from 'react'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'

type Props = {
  accept?: string[]
  currentFile?: string | undefined
  customError?: React.ReactNode
  extensions?: string[]
  files?: FilesType
  maxFileSize?: React.ReactNode
  namespace?: string
  uploadContent?: React.ReactNode
  uploadRef?: React.RefObject<DropzoneRef>
  onAccept: (file: { file: File; filename: string }) => void
}

const defaultFile = {
  file: null,
  filename: '',
  error: ' ', // not truthy
}

const Upload: React.FC<Props> = ({
  accept = [],
  currentFile,
  customError = '',
  extensions = [],
  files = {},
  maxFileSize = '',
  namespace = '0',
  uploadContent,
  uploadRef,
  onAccept,
}) => {
  const { classes } = useStyles()
  const [state, setState] = React.useState<{
    file: File | null
    filename: string
    error: React.ReactNode
  }>(defaultFile)

  React.useEffect(() => {
    if (!files || isEmpty(files) || !currentFile) {
      setState(defaultFile)
    }
  }, [currentFile, files])

  return (
    <div
      id={`upload-${namespace}`}
      style={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
      }}
    >
      <Dropzone
        accept={accept}
        multiple={false}
        ref={uploadRef}
        onDrop={([fileDropped]) => {
          if (!fileDropped) {
            return
          }
          const filename = pathOr<string>('', ['name'], fileDropped)
          if (filename && extensions.indexOf(filename.split('.')?.pop()?.toLowerCase() || '') > -1) {
            setState({
              file: fileDropped,
              filename,
              error: '',
            })
            onAccept({
              file: fileDropped,
              filename,
            })
            return
          }
          setState({
            file: fileDropped,
            filename,
            error: customError || (
              <span>
                File type is invalid.
                <br />
                Currently we support files with an extension of{' '}
                {extensions.map((ext, i) => (
                  <span>
                    <b>{ext}</b>
                    {i === ext.length - 1 ? '.' : ', '}
                  </span>
                ))}
              </span>
            ),
          })
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={`${uploadContent ? classes.hide : ''} ${classes.drop} ${
              state.error ? classes.dropError : ''
            }`.trim()}
          >
            <input {...getInputProps()} style={{ display: 'none' }} />
            <Icon className={classes.dropIcon}>cloud_upload</Icon>
            {state.error && (
              <div className={classes.dropText}>
                <Typography gutterBottom={true}>{state.error}</Typography>
              </div>
            )}
            {!state.error && state.file && (
              <div className={classes.dropText}>
                <Typography gutterBottom={true}>{'File awaiting upload:'}</Typography>
                {state.filename && (
                  <Chip
                    label={state.filename}
                    onDelete={() => {
                      setState({
                        file: null,
                        filename: '',
                        error: '',
                      })
                    }}
                  />
                )}
              </div>
            )}
            {!state.file && (
              <React.Fragment>
                <div className={classes.dropText} id="to-upload-text">
                  <Typography>Drop a file here, or click here to select one</Typography>
                  <Typography color="textSecondary">Supported types: {extensions.join(', ')}</Typography>
                  {maxFileSize && <Typography color="textSecondary">Max file size: {maxFileSize}</Typography>}
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </Dropzone>
      <div className={classes.uploadButton}>{uploadContent}</div>
    </div>
  )
}

const useStyles = makeStyles()((theme: Theme) => ({
  drop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.white,
    borderRadius: '4px',
    border: `2px dashed ${path(['palette', 'grey', '300'], theme)}`,
    cursor: 'pointer',
    padding: '0px 15px',
  },
  dropError: {
    borderColor: theme.palette.error.main,
  },
  dropIcon: {
    position: 'absolute',
    fontSize: '5rem',
  },
  dropText: {
    zIndex: '1',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  dropIconText: {
    justifyContent: 'center',
  },
  radio: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  hide: {
    display: 'none',
  },
  uploadButton: {
    marginTop: '18px',
  },
}))

export default Upload
