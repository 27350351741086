import Chip from '@mui/material/Chip'
import Icon from '@mui/material/Icon'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { isEmpty, path, pathOr } from 'ramda'
import React from 'react'
import Dropzone from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'

const ACCEPTED_EXTENSIONS = ['xlsx', 'csv']

function ContactsUploadInitContent({ error, file, fileName, onAccept, onDelete }) {
  const { classes } = useStyles()
  const dropzoneRef = React.createRef()
  return (
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
      }}
    >
      <Dropzone
        ref={dropzoneRef}
        id="contact-upload-container"
        name="upload"
        accept={[
          '.csv',
          '.xlsx',
          'text/csv',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]}
        multiple={false}
        disablePreview={true}
        onDrop={([fileDropped]) => {
          const data = new FormData()
          const filename = pathOr('', ['name'], fileDropped)
          if (filename && ACCEPTED_EXTENSIONS.indexOf(filename.split('.').pop()) > -1) {
            data.append('file', fileDropped)
            return onAccept({
              file: data,
              fileName: fileDropped.name,
              error: {},
            })
          }
          return onAccept({
            file: {},
            fileName: '',
            error: {
              message: (
                <span>
                  File type is invalid.
                  <br />
                  Currently we support Excel documents with an extension of <b>.xlsx</b> or <b>.csv</b>.
                </span>
              ),
            },
          })
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={`${classes.drop} ${isEmpty(error) ? '' : classes.dropError}`.trim()}>
            <input {...getInputProps()} />
            <Icon className={classes.dropIcon}>cloud_upload</Icon>
            {file ? (
              <div className={classes.dropText}>
                <Typography gutterBottom={true}>{isEmpty(error) ? 'File added' : error.message}</Typography>
                {fileName && <Chip label={fileName} onDelete={onDelete} />}
              </div>
            ) : (
              <div className={classes.dropText}>
                <Typography variant="h6">Drop a file here, or click to select one</Typography>
                <Typography variant="caption" color="textSecondary">
                  Upload a csv or xlsx with one row per contact.
                  <br />
                  Your columns must have names/column headings. You can name your columns anything you want, and we'll
                  add them.
                  <br />
                  <strong>One of the columns must contain your contacts' phone numbers.</strong>
                </Typography>
              </div>
            )}
          </div>
        )}
      </Dropzone>
      <Link variant="h6" href="/static/contacts-upload-template.xlsx" target="_blank">
        Download Template
      </Link>
    </div>
  )
}

const useStyles = makeStyles()((theme) => ({
  drop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '200px',
    backgroundColor: path(['palette', 'grey', '100'], theme),
    color: path(['palette', 'common', 'white'], theme),
    borderRadius: '4px',
    border: `2px dashed ${path(['palette', 'grey', '300'], theme)}`,
    cursor: 'pointer',
    marginTop: '1.5rem',
  },
  dropError: {
    borderColor: path(['palette', 'error', 'main'], theme),
  },
  dropIcon: {
    position: 'absolute',
    fontSize: '7rem',
  },
  dropText: {
    zIndex: '1',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  dropIconText: {
    justifyContent: 'center',
  },
}))

export default ContactsUploadInitContent
