import React from 'react'
import { Button, DialogActions, DialogContent, DialogTitle, Icon, IconButton, styled } from '@mui/material'
import DateTimeRangePicker from '@/app/component/atom/date-time-range-picker'
import TimezonePicker from '@/app/component/atom/timezone-picker'
import { CountryTimezone, TimezoneWithCountryCodeType } from '@/app/types'
import { DateFilter } from '@/app/module/reports/types'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import moment from 'moment'
import { ZDateFilter } from '@/app/module/campaigns/types/schemas/reports/org-report'

type Props = {
  countriesTimezones: CountryTimezone[]
  countryCode: string
  dateFormat: string
  label?: string
  timezone: string
  timezones: TimezoneWithCountryCodeType
  value?: DateFilter

  onClose: () => void
  onSave: (filter: DateFilter) => void
}

const DateFilterDialog: React.FC<Props> = ({
  countriesTimezones,
  countryCode,
  dateFormat,
  timezone,
  timezones,
  value,
  onClose,
  onSave,
}) => {
  const [localValue, setLocalValue] = React.useState(getDefaultFilter(value, timezone, dateFormat))

  return (
    <CustomDialog open onClose={onClose}>
      <StyledDialogTitle>
        Select Dates
        <IconButton onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </StyledDialogTitle>
      <DialogContent>
        <Content>
          <DateTimeRangePicker
            disableFuture
            format={dateFormat}
            name="org-report-date"
            value={localValue}
            onChange={setLocalValue}
          />
          <TimezonePicker
            countriesTimezones={countriesTimezones}
            countryCode={countryCode}
            name="org-report"
            timezone={localValue?.tz || timezone}
            timezones={timezones}
            onChange={(tz) => setLocalValue((s) => ({ ...s, tz }))}
          />
        </Content>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!validate(localValue)}
          variant="contained"
          onClick={() => {
            if (!localValue) {
              return
            }
            onSave(localValue)
          }}
        >
          Save
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}

const getDefaultFilter = (value: DateFilter | undefined, timezone: string, dateFormat: string): DateFilter => {
  if (value && (value.from || value.until)) {
    return value
  }

  return {
    from: moment().subtract(1, 'month').format(dateFormat),
    until: moment().format(dateFormat),
    tz: timezone,
  }
}

const validate = (value: DateFilter): boolean => {
  try {
    ZDateFilter.parse(value)
    return true
  } catch {
    return false
  }
}

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const Content = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
}))

export default DateFilterDialog
