import { Theme } from '@mui/material'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { pick } from 'ramda'
import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Loader from '@/app/component/atom/loader'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { SelectOption } from '@/app/types'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { ContactGroup } from '@/app/module/contacts/types'
import { OrgPlan } from '@/app/module/plans/types'
import { ContactAccess, UserType } from '@/app/module/user/types'
import UserAccessContact from './user-access-contact'

type Props = {
  data: UserType
  groups: ContactGroup[]
  groupsLoading: boolean
  loading: boolean
  personalizationList: PersonalizationType
  plan?: OrgPlan

  onClose: () => void
  onGroupsSearch: (search: string) => void
  onSave: (data: UserType) => void
}

const roleOptions: SelectOption<string>[] = [
  {
    label: 'Manager',
    value: 'manager',
  },
  {
    label: 'Collaborator',
    value: 'collaborator',
  },
  {
    label: 'Report Viewer',
    value: 'report_viewer',
  },
  {
    label: 'CATI Agent',
    value: 'cati_agent',
  },
]

/**
 * Manage user role, granular permissions to contact fields, custom fields, and groups
 */
const UserAccessModal: React.FC<Props> = ({
  data,
  groups,
  groupsLoading,
  loading,
  personalizationList,
  plan,
  onClose,
  onGroupsSearch,
  onSave,
}) => {
  const { classes } = useStyles()
  const [notify, setNotify] = useState(data.notify)
  const [role, setRole] = useState<string>(data.role as string)
  const [access, setAccess] = useState<ContactAccess>(
    pick(
      ['fullAccess', 'readCustomFieldAll', 'readCustomFieldIds', 'readFields', 'readGroupAll', 'readGroupIds'],
      data,
    ),
  )

  const handleAccessChange = React.useCallback(
    (partial: Partial<ContactAccess>) =>
      setAccess((s) => ({
        ...s,
        ...partial,
      })),
    [],
  )
  const handleSave = () => {
    onSave({ ...data, ...access, notify, role })
  }

  const username = `${data.name} (${data.username})`

  return (
    <CustomDialog
      aria-labelledby="user-access-title"
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      open={true}
      onClose={onClose}
    >
      <DialogTitle className={classes.dialogTitleContainer}>
        <span className={classes.dialogTitle}>
          Manage user <b>{username}</b>
        </span>
        <IconButton onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.form}>
          <FormControl className={classes.formControl}>
            <FormLabel htmlFor="role">Role</FormLabel>
            <Select
              data-testid="role-select"
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value as string)}
            >
              {roleOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={<Checkbox />}
            checked={notify}
            data-testid="set-notify"
            label="This user should receive account notifications like recurring charge emails, low balance notifications, etc."
            name="set-notify"
            onChange={() => setNotify((s) => !s)}
          />
          <UserAccessContact
            access={access}
            groups={groups}
            groupsLoading={groupsLoading}
            personalizationList={personalizationList}
            plan={plan}
            username={username}
            onChange={handleAccessChange}
            onGroupsSearch={onGroupsSearch}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" disabled={loading} variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
      {loading && <Loader />}
    </CustomDialog>
  )
}

const useStyles = makeStyles()((theme: Theme) => ({
  dialog: {
    height: '100%',
  },
  dialogTitle: {
    flex: 1,
  },
  dialogTitleContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  form: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: '100%',
  },
}))

export default UserAccessModal
