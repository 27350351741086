import React from 'react'
import { FormHelperText, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import FormControl from './form-control'

type InputFieldProps = {
  'data-testid'?: string
  id?: string
  name: string
  type?: React.HTMLInputTypeAttribute
  label?: string
  placeholder?: string
  editable?: boolean
  disabledText?: string
  readOnly?: boolean
  maxLength?: number
  value?: string
  error?: string | true
  info?: string
  autoFocus?: boolean
  onChange?: (e: { name: string; value: string }) => void
  onBlur?: (e: { name: string; value: string }) => void
  variant?: React.ComponentProps<typeof TextField>['variant']
  inputRef?: React.RefObject<HTMLInputElement>
  multiline?: boolean
  InputProps?: React.ComponentProps<typeof TextField>['InputProps']
  inputProps?: React.ComponentProps<typeof TextField>['inputProps']
  showLabel?: boolean
  showInfoTop?: boolean
  style?: React.CSSProperties
}

export default function InputField({
  'data-testid': dataTestId = '',
  id,
  name,
  label,
  placeholder,
  autoFocus,
  type = 'text',
  editable = true,
  disabledText,
  readOnly = false,
  value,
  error = '',
  info = '',
  onChange = () => {},
  onBlur = () => {},
  variant = 'standard',
  inputRef,
  inputProps,
  multiline = false,
  InputProps = {},
  showLabel = false,
  showInfoTop = false,
  style,
}: InputFieldProps) {
  const errorMessage = error === true ? '' : error
  const disabled = !editable || readOnly
  const formControlInfo = showInfoTop ? '' : info
  const formControlInfoText = disabled ? disabledText : formControlInfo

  return (
    <FormControl error={errorMessage} info={formControlInfoText}>
      {showLabel && (
        <Typography component="label" variant="body2">
          {label}
        </Typography>
      )}
      {showInfoTop && <FormHelperText>{info}</FormHelperText>}
      <TextField
        id={id}
        inputRef={inputRef}
        fullWidth
        type={type}
        name={name}
        placeholder={placeholder}
        autoFocus={autoFocus}
        label={showLabel ? '' : label}
        error={!!error}
        variant={variant}
        disabled={!editable}
        value={value}
        autoComplete="off"
        multiline={multiline}
        style={style}
        InputProps={{
          ...InputProps,
          readOnly,
          fullWidth: true,
        }}
        inputProps={{
          'data-testid': dataTestId,
          ...inputProps,
        }}
        onChange={(e) => {
          e.persist?.()
          e.preventDefault()
          onChange({
            name,
            value: e.target.value,
          })
        }}
        onBlur={(e) => {
          e.persist?.()
          e.preventDefault()
          onBlur({
            name,
            value: e.target.value,
          })
        }}
      />
    </FormControl>
  )
}
