/* eslint-disable eqeqeq */
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { pathOr } from 'ramda'
import React, { PureComponent } from 'react'
import TimezonePicker from '@/app/component/atom/timezone-picker'
import { Input, Select, Time } from '@/app/component/atom/form'
import Tooltip from '@/app/component/atom/tooltip'
import { daysOfTheWeek, daysOfTheWeekOrder, getSchedule, processSchedule } from '@/app/service/util/schedule'
import { CAMPAIGN_CHANGE_DELAY } from '@/app/module/campaigns/definitions'
import { getDaysOfWeek, getDaysOfWeekValue } from '@/app/module/campaigns/helpers'
import { selectTimezones } from '@/app/module/campaigns/store/selectors'
import { styled } from '@mui/material'

const ERROR_DELAY = 'Delay has to be bigger than one minute.'

const getUnitList = (isFirst) => [
  {
    label: 'immediately',
    value: 'immediate',
    disabled: !isFirst,
  },
  {
    label: 'seconds',
    value: 'second',
  },
  {
    label: 'minutes',
    value: 'minute',
  },
  {
    label: 'hours',
    value: 'hour',
  },
  {
    label: 'days',
    value: 'day',
  },
  {
    label: 'weeks',
    value: 'week',
  },
]

const scheduleFirstList = (unit, value, schedule = false) => [
  {
    label: schedule ? 'same as initial call' : 'same as subscription time',
    value: 'same',
    disabled: !schedule && (unit === 'day' || unit === 'week') && value == 0,
  },
  {
    label: 'specific time of the day',
    value: 'specific',
  },
]

const scheduleList = (unit, value, schedule = false) => [
  {
    label: schedule ? 'same as initial call' : 'same as previous message',
    value: 'same',
    disabled: !schedule && (unit === 'day' || unit === 'week') && value == 0,
  },
  ...(schedule
    ? [
        {
          label: 'same as previous retry call',
          value: 'previous',
        },
      ]
    : []),
  {
    label: 'specific time of the day',
    value: 'specific',
  },
]

export default class DripDelay extends PureComponent {
  constructor(props) {
    super(props)
    const {
      delta = {
        value: 0,
        unit: 'day',
      },
    } = props.item
    const countryCode = delta.country ? delta.country : props.countryCode
    this.state = {
      value: delta.value,
      valueError: '',
      unit: delta.value === 0 && props.isFirst && delta.unit !== 'week' && !delta.time ? 'immediate' : delta.unit,
      time: delta.time ? delta.time : '09:00',
      schedule: getSchedule(delta),
      country: countryCode,
      timezone: delta.timezone ? delta.timezone : props.timezone,
      dayOfWeek: '',
      daysOfWeek: getDaysOfWeekValue(delta),
      timezones: selectTimezones(pathOr([], [countryCode, 'tzs'], props.timezones)),
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.timezones !== this.props.timezones) {
      const {
        delta = {
          value: 0,
          unit: 'day',
        },
      } = this.props.item
      const countryCode = delta.country ? delta.country : this.props.countryCode
      this.setState({
        timezones: selectTimezones(pathOr([], [countryCode, 'tzs'], this.props.timezones)),
      })
    }
  }

  changeHandler(item = {}) {
    const { changeHandler, setLoading } = this.props

    if (this.state.timeout) {
      clearTimeout(this.state.timeout)
    }
    this.props.setLoading(true)
    this.setState({
      timeout: setTimeout(() => {
        changeHandler(item)
        setLoading(false)
        this.setState((state) => ({
          timeout: clearTimeout(state.timeout),
        }))
      }, CAMPAIGN_CHANGE_DELAY),
    })
  }

  render() {
    const {
      item,
      isFirst,
      countriesTimezones,
      timezones,
      published = false,
      message = 'send this message',
      helpText = 'after the previous message',
      schedule = false,
    } = this.props

    const hasSpecific = this.state.unit === 'day' || this.state.unit === 'week'

    return (
      <div className="campaign-item-content-delay">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              {message}
              {published && (
                <div
                  style={{
                    float: 'right',
                  }}
                >
                  <Tooltip title="Changing this delay will not immediately affect current subscriptions. See the support article for details.">
                    <Icon color="error">info</Icon>
                  </Tooltip>
                </div>
              )}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Icon style={{ margin: '0.5rem 0.5rem 0 0', color: 'rgba(0, 0, 0, 0.54)' }}>timer</Icon>
              <div style={{ width: this.state.unit === 'immediate' ? '0' : '3rem' }}>
                {this.state.unit !== 'immediate' && (
                  <Input
                    type="number"
                    name="delay-value"
                    value={this.state.value}
                    error={this.state.valueError}
                    inputProps={{
                      min: hasSpecific ? 0 : 1,
                    }}
                    onChange={({ value }) => {
                      const min = hasSpecific ? 0 : 1
                      const error = parseInt(value, 10) < min ? ERROR_DELAY : ''
                      this.setState({
                        value: !error ? value : min,
                        schedule: parseInt(value, 10) === 0 ? 'specific' : this.state.schedule,
                      })
                      this.changeHandler({
                        ...item,
                        delta: {
                          ...item.delta,
                          ...(hasSpecific &&
                            parseInt(value, 10) === 0 && {
                              time: item.delta.time || this.state.time,
                              dayOfWeek: item.delta.dayOfWeek || this.state.dayOfWeek,
                              daysOfWeek: item.delta.daysOfWeek || getDaysOfWeek(this.state.daysOfWeek),
                              timezone: item.delta.timezone || this.state.timezone,
                            }),
                          value: !error ? value : min,
                        },
                      })
                    }}
                  />
                )}
              </div>
              <div style={{ width: this.state.unit === 'immediate' ? '9rem' : '6rem' }}>
                <Select
                  data-testid="delay-unit"
                  value={this.state.unit}
                  values={getUnitList(isFirst)}
                  name="delay-unit"
                  onChange={({ value }) => {
                    let newValue = item.delta.value
                    if (value === 'immediate') {
                      newValue = 0
                    } else if (value !== 'day' && value !== 'week') {
                      newValue = Math.max(1, item.delta.value)
                    }
                    this.setState({
                      unit: value,
                      value: newValue,
                    })
                    // if state.schedule is not set, set relative to item.delta.relative or 'initial' by default
                    const { relative } =
                      this.state.schedule !== undefined
                        ? processSchedule(item, this.state.schedule, value, schedule)
                        : {
                            relative: item.delta.relative || 'initial',
                          }

                    this.changeHandler({
                      ...item,
                      delta: {
                        ...item.delta,
                        unit: value === 'immediate' ? 'minute' : value,
                        value: newValue,
                        time: value !== 'week' && value !== 'day' ? '' : item.delta.time || '',
                        dayOfWeek: value !== 'week' ? '' : item.delta.dayOfWeek,
                        daysOfWeek: value !== 'week' ? {} : getDaysOfWeek(this.state.daysOfWeek),
                        relative,
                      },
                    })
                  }}
                />
              </div>
            </div>
            <div>
              <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                {isFirst && !schedule && 'after the contact is subscribed to the campaign'}
                {(schedule || !isFirst) && helpText}
              </Typography>
            </div>
          </div>
          {hasSpecific && (
            <div>
              <div>
                <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                  at this time of the day
                </Typography>
                <Select
                  data-testid="delay-schedule"
                  value={this.state.schedule}
                  name="delay-schedule"
                  values={
                    isFirst
                      ? scheduleFirstList(this.state.unit, this.state.value, schedule)
                      : scheduleList(this.state.unit, this.state.value, schedule)
                  }
                  onChange={({ value }) => {
                    this.setState({
                      schedule: value,
                    })

                    const { isRelative, canSetDaysOfWeek, relative } = processSchedule(
                      item,
                      value,
                      this.state.unit,
                      schedule,
                    )

                    this.changeHandler({
                      ...item,
                      delta: {
                        ...item.delta,
                        time: isRelative ? '' : this.state.time,
                        dayOfWeek: !canSetDaysOfWeek ? '' : this.state.dayOfWeek,
                        daysOfWeek: !canSetDaysOfWeek ? {} : getDaysOfWeek(this.state.daysOfWeek),
                        country: isRelative ? '' : this.state.country,
                        timezone: isRelative ? '' : this.state.timezone,
                        relative,
                      },
                    })
                  }}
                />
              </div>
              {this.state.schedule === 'specific' && (
                <Row>
                  {this.state.unit === 'week' && (
                    <div style={{ width: '6rem' }}>
                      <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        Days
                      </Typography>
                      <Select
                        data-testid="delay-days-of-week"
                        multiple={true}
                        value={this.state.daysOfWeek}
                        values={daysOfTheWeek}
                        onChange={({ value = [] }) => {
                          const sortedValue = value.length
                            ? value.sort((a, b) => daysOfTheWeekOrder[a] - daysOfTheWeekOrder[b])
                            : this.state.daysOfWeek

                          this.setState({
                            daysOfWeek: sortedValue,
                          })
                          this.changeHandler({
                            ...item,
                            delta: {
                              ...item.delta,
                              dayOfWeek: '',
                              daysOfWeek: sortedValue.reduce((obj, v) => ({ ...obj, [v]: true }), {}),
                            },
                          })
                        }}
                      />
                    </div>
                  )}
                  <div style={{ width: '4rem' }}>
                    <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                      Time
                    </Typography>
                    <Time
                      label=""
                      value={this.state.time}
                      onChange={({ value }) => {
                        this.setState({
                          time: value,
                        })
                        this.changeHandler({
                          ...item,
                          delta: {
                            ...item.delta,
                            time: value,
                          },
                        })
                      }}
                    />
                  </div>
                  <div style={{ width: '18rem' }}>
                    <TimezonePicker
                      countriesTimezones={countriesTimezones}
                      countryCode={this.state.country}
                      name="delay"
                      timezone={this.state.timezone}
                      timezones={timezones}
                      onChange={(timezone, country) => {
                        this.setState({
                          timezone,
                        })
                        this.changeHandler({
                          ...item,
                          delta: {
                            ...item.delta,
                            country,
                            timezone,
                          },
                        })
                      }}
                    />
                  </div>
                </Row>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(),
}))
