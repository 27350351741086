import Layout from '../../component/layout/page'
import { PAGE } from '../../definitions'
import { AuthGuard, AuthHeader } from '../../module/auth'
import Notifications from '../../module/notifications'
import PhoneNumbers from '../../module/phonenumbers'

export default function PhoneNumbersPage({ name, routes, match }) {
  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout title="Phone Numbers - engageSPARK">
        <AuthHeader currentPage={PAGE.PHONENUMBERS.INDEX} />
        <PhoneNumbers />
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}
