import Link from '@mui/material/Link'
import { History } from 'history'
import React from 'react'
import Note from './note'

type Props = {
  loading: boolean
  history: History
  itemId: string
}

const PublishedSubscriptions: React.FC<Props> = ({ loading, history, itemId }) => {
  const goToSummaryPage = () => history.push(`/campaigns/summary/${itemId}/subscriptions`)

  return (
    <Note loading={loading}>
      This campaign has already been launched. To add new contacts to this campaign,{' '}
      <Link
        onClick={goToSummaryPage}
        style={{
          cursor: 'pointer',
        }}
      >
        go to manage subscriptions
      </Link>
    </Note>
  )
}

export default PublishedSubscriptions
