import Button from '@mui/material/Button'
import { pathOr } from 'ramda'
import { Fragment } from 'react'

export const filterUnchecked = (config, specs, statuses) =>
  Object.keys(specs).reduce((acc, field) => {
    const index = pathOr(false, ['fieldsToColumns', field], acc)

    if (index && !statuses[index]) {
      return {
        ...acc,
        fieldsToColumns: {
          ...acc.fieldsToColumns,
          [field]: '',
        },
      }
    }
    const customField = field.replace('customFields.', '')
    const customIndex = pathOr(false, ['customFieldsToColumns', customField], acc)
    if (customIndex && !statuses[customIndex]) {
      return {
        ...acc,
        customFieldsToColumns: {
          ...acc.customFieldsToColumns,
          [customField]: '',
        },
      }
    }

    return acc
  }, config)

export default function ContactsUploadReviewActions({ specs, statuses, configs, backHandler, submitHandler }) {
  return (
    <Fragment>
      <Button variant="outlined" onClick={() => backHandler()}>
        Back
      </Button>
      <Button
        id="contacts-upload-review-button"
        variant="contained"
        color="primary"
        onClick={() => submitHandler(filterUnchecked(configs, specs, statuses))}
      >
        Confirm
      </Button>
    </Fragment>
  )
}
