import { TYPES } from '@/app/module/campaigns/definitions'
import createCampaignGuard from '@/app/module/campaigns/component/item/steps/guard'
import MultiMessage from './multimessage'

const mapping = {
  [TYPES.CATI.name]: MultiMessage,
  [TYPES.DRIP.name]: MultiMessage,
  [TYPES.IVR.name]: MultiMessage,
  [TYPES.REMINDER.name]: MultiMessage,
  [TYPES.SMSBLAST.name]: MultiMessage,
  [TYPES.SMSSURVEY.name]: MultiMessage,
  [TYPES.WHATSAPPSURVEY.name]: MultiMessage,
}

export default createCampaignGuard(mapping)
