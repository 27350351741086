import { createAsyncActions } from '@/app/service/util'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import {
  ACTION_GET_DEMOS_FAILURE,
  ACTION_GET_DEMOS_REQUEST,
  ACTION_GET_DEMOS_SUCCESS,
  ACTION_SELECT_DEMO_ITEM_FAILURE,
  ACTION_SELECT_DEMO_ITEM_REQUEST,
  ACTION_SELECT_DEMO_ITEM_SUCCESS,
  ERROR_GET_DEMOS_SERVER,
  ERROR_SELECT_DEMO_ITEM_SERVER,
  SELECT_DEMO_SUCCESS,
} from '@/app/module/demos/definitions'

const {
  request: getDemosRequest,
  success: getDemosSuccess,
  failure: getDemosFailure,
} = createAsyncActions({
  request: ACTION_GET_DEMOS_REQUEST,
  success: ACTION_GET_DEMOS_SUCCESS,
  failure: ACTION_GET_DEMOS_FAILURE,
})

const {
  request: selectDemoRequest,
  success: selectDemoSuccess,
  failure: selectDemoFailure,
} = createAsyncActions({
  request: ACTION_SELECT_DEMO_ITEM_REQUEST,
  success: ACTION_SELECT_DEMO_ITEM_SUCCESS,
  failure: ACTION_SELECT_DEMO_ITEM_FAILURE,
})

export { getDemosFailure, getDemosRequest, getDemosSuccess, selectDemoFailure, selectDemoRequest, selectDemoSuccess }

export const getDemos =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch(getDemosRequest())

    return request.demos
      .get({
        token,
        orgId,
        query: {},
      })
      .then(({ demos }) => {
        dispatch(
          getDemosSuccess({
            demos,
          }),
        )
      })
      .catch((err) => {
        dispatch(
          getDemosFailure({
            status: err.status,
            message: ERROR_GET_DEMOS_SERVER,
          }),
        )
        return err
      })
  }

export const selectDemo =
  ({ token, item, itemId }) =>
  (dispatch) => {
    dispatch(selectDemoRequest())

    return request.demos
      .select({
        token,
        itemId,
        item,
      })
      .then(() => {
        dispatch(selectDemoSuccess())
        dispatch(
          createNotification({
            'demo-success': {
              type: 'success',
              message: SELECT_DEMO_SUCCESS,
            },
          }),
        )
      })
      .catch(() => {
        dispatch(selectDemoFailure())
        dispatch(
          createNotification({
            'demo-error': {
              type: 'error',
              message: ERROR_SELECT_DEMO_ITEM_SERVER,
            },
          }),
        )
      })
  }
