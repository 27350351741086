import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import Loading from '../../../../component/guard/loading'
import FieldDynamic from '../../../../component/layout/field/dynamic'
import Fields from '../../../../component/wrapper/fields'
import validate, { hasPasswordError, hasRequiredError } from '../../../../service/validate'

const isPwMatching = (oldPw, newPw) => (newPw && oldPw && newPw === oldPw ? '' : 'Passwords have to match')

const validation = {
  currentPassword: validate(hasRequiredError),
  newPassword: validate(hasRequiredError, hasPasswordError, (value, fields) => {
    if (fields.currentPassword.value === value) {
      return 'New password must be different from the current password'
    }

    return fields.newPasswordConfirm.value ? isPwMatching(value, fields.newPasswordConfirm.value) : ''
  }),
  newPasswordConfirm: validate(
    hasRequiredError,
    (value, fields) => (fields.newPassword.value ? isPwMatching(value, fields.newPassword.value) : ''),
    hasPasswordError,
  ),
}

export default function PasswordReset({
  loading,
  values = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  },
  config = {},
  headers = [],
  onSubmit,
}) {
  return (
    <Loading isLoading={loading}>
      <Fields fields={values} validation={validation} onSubmit={onSubmit}>
        {({ fields, changed, hasErrors, submitHandler, fieldChangeHandler, fieldBlurHandler }) => (
          <form
            id="reset-password"
            onSubmit={(e) => {
              e.preventDefault()
              submitHandler()
            }}
          >
            <div>
              {headers.map((header, i) => (
                <div key={i}>
                  {header.fields.map((key) => (
                    <FieldDynamic
                      key={key}
                      name={key}
                      specs={config[key]}
                      field={fields[key]}
                      onChange={({ name, value }) => {
                        if (name === 'newPassword' && fields.newPasswordConfirm.error !== '') {
                          fieldChangeHandler({
                            name: 'newPasswordConfirm',
                            value: fields.newPasswordConfirm.value,
                            error: '',
                          })
                        }

                        if (name === 'newPasswordConfirm' && fields.newPassword.error !== '') {
                          fieldChangeHandler({
                            name: 'newPassword',
                            value: fields.newPassword.value,
                            error: '',
                          })
                        }

                        fieldChangeHandler({ name, value })
                      }}
                      onBlur={({ name, value }) => {
                        if (name === 'newPassword' && fields.newPasswordConfirm.error !== '') {
                          fieldChangeHandler({
                            name: 'newPasswordConfirm',
                            value: fields.newPasswordConfirm.value,
                            error: '',
                          })
                        }

                        if (name === 'newPasswordConfirm' && fields.newPassword.error !== '') {
                          fieldChangeHandler({
                            name: 'newPassword',
                            value: fields.newPassword.value,
                            error: '',
                          })
                        }

                        fieldBlurHandler({ name, value })
                      }}
                    />
                  ))}
                </div>
              ))}
            </div>
            <CardActions>
              <Button
                id="password-save"
                variant="contained"
                color="primary"
                type="submit"
                disabled={!changed || loading || hasErrors}
              >
                Save
              </Button>
            </CardActions>
          </form>
        )}
      </Fields>
    </Loading>
  )
}
