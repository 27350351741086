import { Box, Chip, Icon, IconButton, TableCell, TableRow, styled } from '@mui/material'
import React from 'react'
import CopyBox from '@/app/component/atom/copy-box'
import CustomTooltip from '@/app/component/atom/tooltip'
import { APIKey } from '@/app/module/user/types'
import { formatDT } from '@/app/service/util/format'
import config from '@/config'

type Props = {
  apiKey: APIKey

  onDelete: (id: number) => void
  onEditClick: (apiKey: APIKey) => void
}

const dateTimeFormat = config.display.format.dateTime

const ApiKeyRow: React.FC<Props> = ({ apiKey, onDelete, onEditClick }) => {
  const handleDeleteClick = React.useCallback(() => onDelete(apiKey.id), [apiKey.id, onDelete])
  const handleEditClick = React.useCallback(() => onEditClick(apiKey), [apiKey, onEditClick])

  const id = `api-key-row-${apiKey.id}`

  return (
    <TableRow data-testid={id} id={id}>
      <TableCell data-testid={`${id}-name`}>{apiKey.name}</TableCell>
      <TableCell data-testid={`${id}-key`}>
        <APIKeyCopyBox hideInput inputVariant="standard" text={apiKey.key} title="" />
      </TableCell>
      <TableCell align="center" data-testid={`${id}-created`}>
        {formatDT(apiKey.created, undefined, dateTimeFormat)}
      </TableCell>
      <TableCell align="center" data-testid={`${id}-expires`}>
        {apiKey.expires ? formatDT(apiKey.expires, undefined, dateTimeFormat) : ''}
      </TableCell>
      <TableCell align="center" data-testid={`${id}-currently-valid`}>
        <Chip
          color={apiKey.currentlyValid ? 'success' : 'error'}
          label={apiKey.currentlyValid ? 'YES' : 'NO'}
          sx={{ color: (theme) => theme.palette.common.white }}
        />
      </TableCell>
      <TableCell align="center" data-testid={`${id}-active`}>
        <Icon color={apiKey.active ? 'success' : 'error'}>{apiKey.active ? 'check' : 'close'}</Icon>
      </TableCell>
      <TableCell align="center" data-testid={`${id}-actions`}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CustomTooltip title="Manage token details">
            <IconButton data-testid={`${id}-edit`} onClick={handleEditClick}>
              <Icon>settings</Icon>
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title="Delete this token">
            <IconButton data-testid={`${id}-delete`} onClick={handleDeleteClick}>
              <Icon>delete</Icon>
            </IconButton>
          </CustomTooltip>
        </Box>
      </TableCell>
    </TableRow>
  )
}

const APIKeyCopyBox = styled(CopyBox)(() => ({
  '& .MuiFormControl-root': {
    width: '100%',
  },
}))

export default ApiKeyRow
