import React from 'react'
import { useStyles } from 'tss-react/mui'

export default function withTheme<T>(Component: React.ComponentType<T>) {
  function ComponentWithTheme(props: T) {
    const { theme } = useStyles()

    return <Component {...props} theme={theme} />
  }

  return ComponentWithTheme
}
