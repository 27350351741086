import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import FieldStatic, { DISPLAY_MAP as DEFAULT_DISPLAY_MAP } from '@/app/component/layout/field/static'
import IconText from '@/app/component/layout/icon-text'

const DISPLAY_MAP = {
  ...DEFAULT_DISPLAY_MAP,
  runs: ({ field: { totalCount = 0, doneCount, errorCount, unsubbedCount, activeId = 0 } = {} }) => (
    <IconText>
      <Icon>send</Icon>
      {activeId > 0 || totalCount > 0 ? (
        <Typography className="sub-status">
          {activeId === 0 && totalCount > 0 && 'Completed'}
          {activeId > 0 && 'In progress'}
          <br />
          <Typography className="sub-stats" color="textSecondary" component="span">
            {doneCount} finished, {unsubbedCount} unsubscribed, {errorCount} failure
          </Typography>
        </Typography>
      ) : (
        <Typography className="sub-status">Not subscribed</Typography>
      )}
    </IconText>
  ),
}

export default function CampaignsManageTableField({ name, specs, field, title }) {
  return <FieldStatic name={name} specs={specs} field={field} displayMap={DISPLAY_MAP} title={title} />
}
