import { Card, CardContent, CardHeader, Grid, Icon, Link, Typography } from '@mui/material'
import React from 'react'
import { Checkbox } from '@/app/component/atom/form'
import { CampaignItemWithVoice } from '@/app/module/campaigns/types'

type Props = {
  item: CampaignItemWithVoice

  onChange: (item: CampaignItemWithVoice) => void
}

const Voicemail: React.FC<Props> = ({ item, onChange }) => (
  <Card style={{ marginBottom: '2rem' }}>
    <CardHeader title="Voicemail Detection" style={{ padding: '12px 24px' }} />
    <CardContent>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography data-testid="amd-info" variant="body1" gutterBottom={true}>
            If this feature is turned on, our system will disconnect any calls that it believes are answered by a
            machine (e.g., voicemail, answering machine, or automatic message by the telco). We will also then treat
            such calls as though they were not answered, meaning that retry calls will still be made (if configured).
            <br />
            <b>Note</b>: Voicemail detection will make mistakes. You must test if it works for you and your scenario.
            Read more about Voicemail detection in this{' '}
            <Link href="https://www.engagespark.com/blog/voicemail-detection/" target="_blank">
              support article <Icon style={{ fontSize: '13px' }}>launch</Icon>
            </Link>
            .
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Checkbox
            editable
            label="Detect Voicemails"
            name="amd"
            value={item.amd?.toString() || 'false'}
            onChange={({ value }) => {
              onChange({
                ...item,
                amd: value === 'true',
              })
            }}
          />
        </Grid>
        {item.published && (
          <Grid item xs={12}>
            <Typography data-testid="amd-warning" variant="body1" gutterBottom={true}>
              <b>This campaign is already running!</b> Changing this setting will only affect new subscriptions, or
              subscriptions that are currently scheduled for the future. Calls that are already in the process of being
              made (queued) will NOT be affected.
            </Typography>
          </Grid>
        )}
      </Grid>
    </CardContent>
  </Card>
)

export default Voicemail
