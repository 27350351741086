import ExpandMore from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Icon from '@mui/material/Icon'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import camelCase from 'lodash.camelcase'
import snakeCase from 'lodash.snakecase'
import { pathOr } from 'ramda'
import React from 'react'
import ListSelect from '@/app/component/atom/list-select'
import IconText from '@/app/component/layout/icon-text'
import { PersonalizationType, SinglePersonalizationType } from '@/app/module/campaigns/types'
import { ContactGroup } from '@/app/module/contacts/types'
import { OrgPlan } from '@/app/module/plans/types'
import { ContactAccess } from '@/app/module/user/types'

type Props = {
  access: ContactAccess
  groups: ContactGroup[]
  groupsLoading: boolean
  personalizationList: PersonalizationType
  plan?: OrgPlan
  username: string

  onChange: (data: Partial<ContactAccess>) => void
  onGroupsSearch: (search: string) => void
}

/**
 * Manage user granular permissions to contact fields, custom fields, and groups
 */
const UserAccessContact: React.FC<Props> = ({
  access,
  groups,
  groupsLoading,
  personalizationList,
  plan,
  username,
  onChange,
  onGroupsSearch,
}) => {
  const canManageAccess = !!plan?.manageContactAccess

  const renderedContactFields = React.useMemo(() => {
    const contactPzList = pathOr<SinglePersonalizationType[]>([], ['contact'], personalizationList)
    return (
      <ListSelect
        disabled={!canManageAccess}
        name="contact-fields"
        options={contactPzList}
        selectedOptions={(access.readFields || []).map((f) => `contact.${camelCase(f)}`)} // readFields values are snake_case while personalizationList values are camelCase
        onChange={(selected) =>
          onChange({
            readFields: selected.map((v) => snakeCase(v.split('.')[1])),
          })
        }
      />
    )
  }, [access.readFields, canManageAccess, personalizationList, onChange])

  const renderedCustomFields = React.useMemo(() => {
    const customPzList = pathOr<SinglePersonalizationType[]>([], ['customfields'], personalizationList)
    return (
      <ListSelect
        disabled={!canManageAccess}
        name="custom-fields"
        options={customPzList}
        selectedOptions={(access.readCustomFieldIds || []).map((f) => `customfields.${f}`)}
        onChange={(selected) =>
          onChange({
            readCustomFieldIds: selected.map((v) => Number(v.split('.')[1])),
          })
        }
      />
    )
  }, [access.readCustomFieldIds, canManageAccess, personalizationList, onChange])

  const renderedGroups = React.useMemo(() => {
    const groupList = groups.map((g) => ({
      label: `${g.name}${g.type === 'SMART' ? ' (SEGMENT)' : ''}`,
      value: g.id.toString(),
    }))
    return (
      <ListSelect
        disabled={!canManageAccess}
        loading={groupsLoading}
        name="groups"
        options={groupList}
        selectedOptions={(access.readGroupIds || []).map((f) => `${f}`)}
        onChange={(selected) =>
          onChange({
            readGroupIds: selected.map((v) => Number(v)),
          })
        }
        onSearch={onGroupsSearch}
      />
    )
  }, [access.readGroupIds, canManageAccess, groups, groupsLoading, onChange, onGroupsSearch])

  return (
    <div>
      {!canManageAccess && (
        <>
          <IconText>
            <Icon>info</Icon>
            <Typography>
              User Permission Management is not available for your subscription plan.
              <br />
              <Link target="_blank" href="/plans">
                Upgrade now{' '}
              </Link>
              to manage user permissions.
            </Typography>
          </IconText>
        </>
      )}
      <FormControlLabel
        control={<Checkbox />}
        checked={access.fullAccess}
        data-testid="set-full-access"
        disabled={!canManageAccess}
        label="Full access to everything"
        name="set-full-access"
        onChange={() => {
          onChange({ fullAccess: !access.fullAccess })
        }}
      />
      {!access.fullAccess && (
        <>
          <FormControlLabel
            control={<Checkbox />}
            checked={access.readCustomFieldAll}
            data-testid="set-cf-all"
            disabled={!canManageAccess}
            label="Full access to all current and future custom fields"
            name="set-cf-all"
            onChange={() => {
              onChange({ readCustomFieldAll: !access.readCustomFieldAll })
            }}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={access.readGroupAll}
            data-testid="set-group-all"
            disabled={!canManageAccess}
            label="Full access to all current and future groups and segments"
            name="set-group-all"
            onChange={() => {
              onChange({ readGroupAll: !access.readGroupAll })
            }}
          />
          <Accordion data-testid="contact-fields-accordion" defaultExpanded={false}>
            <AccordionSummary
              aria-label="Expand"
              aria-controls="contact-fields-content"
              expandIcon={<ExpandMore />}
              id="contact-fields-header"
            >
              <Typography>Access to contact fields</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                {username} will be able to view and edit the values only in the contact fields you select.
              </Typography>
              {renderedContactFields}
            </AccordionDetails>
          </Accordion>
          {!access.readCustomFieldAll && (
            <Accordion data-testid="custom-fields-accordion" defaultExpanded={false}>
              <AccordionSummary
                aria-label="Expand"
                aria-controls="custom-fields-content"
                expandIcon={<ExpandMore />}
                id="custom-fields-header"
              >
                <Typography>Access to custom fields</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="textSecondary">
                  {username} will be able to view and edit the values only in the custom fields you select.
                </Typography>
                {renderedCustomFields}
              </AccordionDetails>
            </Accordion>
          )}
          {!access.readGroupAll && (
            <Accordion data-testid="groups-accordion" defaultExpanded={false}>
              <AccordionSummary
                aria-label="Expand"
                aria-controls="groups-content"
                expandIcon={<ExpandMore />}
                id="groups-header"
              >
                <Typography>Access to groups and segments</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="textSecondary">
                  {username} will be able to view only the contacts in the groups and segments you select.
                </Typography>
                {groups.length >= 1000 && (
                  <IconText>
                    <Icon>info</Icon>
                    <Typography>
                      We only show the 1000 most recent groups, contact customer support if you need to select other
                      groups
                    </Typography>
                  </IconText>
                )}
                {renderedGroups}
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
    </div>
  )
}

export default UserAccessContact
