import moment from 'moment'
import Button from '@mui/material/Button'
import { StaticDateTimePicker } from '@mui/x-date-pickers'
import React from 'react'

type Value = {
  from?: string
  to?: string
}

type DateTimeComponentProps = {
  name: string
  value?: Value
  disableFuture?: boolean
  onChange: (value: { name: string; value: Value }) => void
}

export default function DateTimeComponent({ name, value, disableFuture, onChange }: DateTimeComponentProps) {
  const [date, setDate] = React.useState<{
    from: moment.Moment | null
    to: moment.Moment | null
  }>({
    from: value && value.from ? moment(value.from) : moment().subtract(1, 'minute'),
    to: value && value.to ? moment(value.to) : moment(),
  })

  React.useEffect(() => {
    if (date.from?.isAfter(date.to)) {
      setDate({
        ...date,
        from: date.to,
      })
    }
  }, [date])

  return (
    <>
      <div style={{ display: 'flex' }}>
        <StaticDateTimePicker
          disableFuture={disableFuture}
          value={date.from}
          onChange={(val) => setDate({ ...date, from: val })}
          slots={{
            actionBar: () => null,
          }}
        />
        <StaticDateTimePicker
          disableFuture={disableFuture}
          value={date.to}
          onChange={(val) => setDate({ ...date, to: val })}
          slots={{
            actionBar: () => null,
          }}
        />
      </div>
      <div
        style={{
          textAlign: 'right',
          paddingRight: '15px',
          paddingBottom: '15px',
        }}
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() =>
            onChange({
              name,
              value: {
                from: date.from?.toISOString(),
                to: date.to?.toISOString(),
              },
            })
          }
        >
          Save
        </Button>
      </div>
    </>
  )
}
