export enum SubscriptionFilter {
  All = '',
  IsSubscribed = 'is',
  IsNotSubscribed = 'isnot',
  WasSubscribed = 'was',
}

export type SubscriptionQuery = {
  page: number
  searchTerm: string
  size: number
  subscriptionStatus: string
  subscriptionFilter: SubscriptionFilter
}
