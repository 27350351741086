import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { append, remove } from 'ramda'
import ConditionGroup from '../../../module/campaigns/component/item/steps/content/types/multimessage/conditions/condition-group'

const defaultData = [['and', ['', '', '']]]

export default function Conditions({
  value,
  segments,
  groups,
  personalizationList,
  operands,
  onChange,
  onBlur,
  title,
  error = false,
}) {
  const data = JSON.parse(value) || defaultData
  const submitChange = (item) => {
    onChange(item)
    onBlur(item)
  }
  return (
    <div
      style={{
        marginTop: '12px',
        width: '100%',
      }}
    >
      {title && <Typography variant="h6">{title}</Typography>}
      <div
        style={{
          width: '100%',
        }}
      >
        {data.map((conditionRow, rowIndex) => (
          <div
            key={`conditions-${rowIndex}`}
            style={{
              marginBottom: 18,
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                marginBottom: '10px',
              }}
            >
              {rowIndex === 0 ? (
                <Typography variant="subtitle2">
                  Add contacts to this segment if the following conditions apply
                </Typography>
              ) : (
                <Typography variant="subtitle2">
                  <strong style={{ color: '#2f80ac' }}>OR</strong> if the following conditions apply:
                </Typography>
              )}
            </div>
            <ConditionGroup
              error={!!error}
              groupedOperators={true}
              conditionRow={conditionRow}
              operands={operands}
              personalizationList={personalizationList}
              groups={groups}
              segments={segments}
              key={`row${rowIndex}`}
              isFirst={rowIndex === 0}
              isLast={rowIndex === data.length - 1}
              rowIndex={rowIndex}
              onAddConditionGroup={() => {
                submitChange({
                  name: 'conditions',
                  value: JSON.stringify(append(['and', ['', '', '']], data)),
                })
              }}
              onChangeCondition={({ updatedConditionGroup }) => {
                if (updatedConditionGroup.length === 1) {
                  submitChange({
                    name: 'conditions',
                    value: JSON.stringify(remove(rowIndex, 1, data)),
                  })
                } else {
                  data[rowIndex] = updatedConditionGroup
                  submitChange({
                    name: 'conditions',
                    value: JSON.stringify(data),
                  })
                }
              }}
            />
            <Divider />
          </div>
        ))}
      </div>
    </div>
  )
}
