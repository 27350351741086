import {
  CampaignTemplateType,
  LocalAction,
  LocalOnAnswerType,
  LocalOpenEnded,
  LocalWhatsAppButtons,
  LocalWhatsAppList,
  OnAnswerType,
  OpenEnded,
  PartFamily,
  TWhatsAppButtons,
  TWhatsAppList,
  WhatsAppQuestion,
} from '@/app/module/campaigns/types'
import { equals, isEmpty } from 'ramda'
import { emptyAction, unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'
import {
  CAMPAIGN_RETRIES,
  ERROR_WHATSAPP_LIST_BUTTON_LENGTH,
  WA_LIST_BUTTON_LENGTH,
} from '@/app/module/campaigns/definitions'
import validate, { hasMaxError, hasRequiredError } from '@/app/service/validate'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import {
  defaultOnAnswerText,
  generateDefaultOnButtons,
  generateDefaultOnList,
  hasOpenEndedResponse,
} from './types/multimessage/content'
import { AnswerType } from './question-text-props'

export type State = {
  answerType: AnswerType
  checkFailure: boolean
  checkInvalid: boolean
  checkTimeout: boolean
  failureActions: LocalAction[]
  invalidActions: LocalAction[]
  onAnswer: LocalOnAnswerType[]
  onButtons?: LocalWhatsAppButtons
  onList?: LocalWhatsAppList
  openEnded?: LocalOpenEnded
  registerTemplate: boolean
  retries: number
  timeoutActions: LocalAction[]
  timeoutDelay?: {
    unit: string
    value: number
  }
  transcribe: boolean
  useAi: boolean
}

const fromOnAnswer = (onAnswer: OnAnswerType[] | undefined, answerType: AnswerType): LocalOnAnswerType[] => {
  const defaultOnAnswer = answerType === AnswerType.Text ? defaultOnAnswerText : []

  return onAnswer && !isEmpty(onAnswer)
    ? onAnswer.map((answer) => ({
        ...answer,
        actions: unwrapActions(answer.actions || []),
      }))
    : defaultOnAnswer
}

const fromOpenEnded = (openEnded: OpenEnded | undefined): LocalOpenEnded | undefined => {
  if (!openEnded) {
    return undefined
  }

  const categories = openEnded.categories?.map(({ category, actions }) => ({
    category,
    actions: unwrapActions(actions || []),
  }))

  return {
    ...openEnded,
    actions: unwrapActions(openEnded.actions || []),
    categories,
  }
}

export const fromProps = ({
  message,
  onAnswer = [],
  onButtons,
  onInvalidReply,
  onList,
  onRetriesExhausted,
  onTimeout,
  openEnded,
  retries,
  transcribe,
  useAi,
}: WhatsAppQuestion): State => {
  const checkFailure = !!(onRetriesExhausted?.enabled || onRetriesExhausted?.actions?.length)
  const checkInvalid = !!(onInvalidReply?.enabled || onInvalidReply?.actions?.length)
  const checkTimeout = !!(onTimeout?.enabled || onTimeout?.actions?.length || onTimeout?.timeout?.value)
  const defaultRetries = retries === undefined ? CAMPAIGN_RETRIES : retries
  let answerType = openEnded ? AnswerType.OpenEnded : AnswerType.Text
  if (onButtons) {
    answerType = AnswerType.WhatsAppButtons
  } else if (onList) {
    answerType = AnswerType.WhatsAppList
  }

  return {
    retries: checkInvalid || checkTimeout ? defaultRetries : 0,
    checkFailure,
    checkInvalid,
    checkTimeout,
    answerType,
    openEnded: fromOpenEnded(openEnded),
    onAnswer: fromOnAnswer(onAnswer, answerType),
    onButtons: !onButtons
      ? undefined
      : {
          ...onButtons,
          buttons: onButtons.buttons.map((button) => ({
            ...button,
            actions: unwrapActions(button.actions || []),
          })),
        },
    onList: !onList
      ? undefined
      : {
          ...onList,
          items: onList.items.map((item) => ({
            ...item,
            actions: unwrapActions(item.actions || []),
          })),
        },
    invalidActions: unwrapActions(onInvalidReply?.actions || []),
    timeoutActions: unwrapActions(onTimeout?.actions || []),
    failureActions: unwrapActions(onRetriesExhausted?.actions || []),
    timeoutDelay: {
      unit: onTimeout?.timeout?.unit || 'minute',
      value: Number(onTimeout?.timeout?.value || 10),
    },
    transcribe,
    useAi,
    registerTemplate: message.registerTemplate || false,
  }
}

const toOnAnswer = (
  onAnswer: LocalOnAnswerType[],
  onButtons: LocalWhatsAppButtons | undefined,
  onList: LocalWhatsAppList | undefined,
  openEnded: LocalOpenEnded | undefined,
): OnAnswerType[] =>
  onButtons || onList || openEnded ? [] : onAnswer.map((item) => ({ ...item, actions: wrapActions(item.actions) }))

const toOnButtons = (onButtons: LocalWhatsAppButtons | undefined): TWhatsAppButtons | undefined => {
  if (!onButtons) {
    return undefined
  }

  return {
    ...onButtons,
    buttons: onButtons.buttons.map((button) => ({
      ...button,
      actions: wrapActions(button.actions || []),
    })),
  }
}

const toOnList = (onList: LocalWhatsAppList | undefined): TWhatsAppList | undefined => {
  if (!onList) {
    return undefined
  }

  return {
    ...onList,
    items: onList.items.map((item) => ({
      ...item,
      actions: wrapActions(item.actions || []),
    })),
  }
}

const toOpenEnded = (openEnded: LocalOpenEnded | undefined): OpenEnded | undefined => {
  if (!openEnded) {
    return undefined
  }

  const categories = openEnded.categories?.map(({ category, actions }) => ({
    category,
    actions: wrapActions(actions),
  }))

  return {
    ...openEnded,
    actions: wrapActions(openEnded.actions || []),
    categories,
  }
}

export const backToProps = ({
  checkFailure,
  checkInvalid,
  checkTimeout,
  failureActions,
  invalidActions,
  retries = 0,
  timeoutActions,
  timeoutDelay,
  transcribe,
  onAnswer,
  onButtons,
  onList,
  openEnded,
  useAi,
}: State): Partial<WhatsAppQuestion> => ({
  onAnswer: toOnAnswer(onAnswer, onButtons, onList, openEnded),
  onButtons: toOnButtons(onButtons),
  onInvalidReply: {
    enabled: checkInvalid,
    actions: checkInvalid && !openEnded ? wrapActions(invalidActions) : [],
  },
  onList: toOnList(onList),
  onRetriesExhausted: {
    enabled: checkFailure,
    actions: checkFailure ? wrapActions(failureActions) : [],
  },
  onTimeout: {
    enabled: checkTimeout,
    timeout: checkTimeout ? timeoutDelay : undefined,
    actions: checkTimeout ? wrapActions(timeoutActions) : [],
  },
  openEnded: toOpenEnded(openEnded),
  retries,
  transcribe,
  useAi,
})

type WhatsAppQuestionContent = {
  onAnswer: LocalOnAnswerType[]
  onButtons?: LocalWhatsAppButtons
  onList?: LocalWhatsAppList
  openEnded?: LocalOpenEnded
  transcribe: boolean
  useAi: boolean
}

export const generateContent = (answerType: AnswerType): WhatsAppQuestionContent => {
  switch (answerType) {
    case AnswerType.OpenEnded:
      return {
        onAnswer: [],
        onButtons: undefined,
        onList: undefined,
        openEnded: {
          actions: [emptyAction],
        },
        transcribe: false,
        useAi: false,
      }

    case AnswerType.WhatsAppButtons:
      return {
        openEnded: undefined,
        onAnswer: [],
        onList: undefined,
        onButtons: generateDefaultOnButtons(),
        transcribe: false,
        useAi: false,
      }

    case AnswerType.WhatsAppList:
      return {
        openEnded: undefined,
        onAnswer: [],
        onButtons: undefined,
        onList: generateDefaultOnList(),
        transcribe: false,
        useAi: false,
      }

    default:
      return {
        onAnswer: defaultOnAnswerText,
        onButtons: undefined,
        onList: undefined,
        openEnded: undefined,
        transcribe: false,
        useAi: false,
      }
  }
}

export const hasResponse = ({ answerType, onAnswer, onButtons, onList, openEnded, transcribe, useAi }: State) => {
  const defaultOnAnswer = defaultOnAnswerText
  const defaultOnButtons = generateDefaultOnButtons()
  defaultOnButtons.buttons = defaultOnButtons.buttons.map((button) => ({
    ...button,
    id: '',
  }))
  const defaultOnList = generateDefaultOnList()
  defaultOnList.items = defaultOnList.items.map((item) => ({
    ...item,
    id: '',
  }))

  const currentOnButtons = {
    ...onButtons,
  }
  const currentOnList = {
    ...onList,
  }
  if (onButtons) {
    currentOnButtons.buttons = currentOnButtons.buttons?.map((button) => ({
      ...button,
      id: '',
    }))
  }
  if (onList) {
    currentOnList.items = currentOnList.items?.map((item) => ({
      ...item,
      id: '',
    }))
  }

  const hasOnAnswer = answerType === AnswerType.Text && !equals(onAnswer, defaultOnAnswer)
  const hasOnButtons = answerType === AnswerType.WhatsAppButtons && !equals(currentOnButtons, defaultOnButtons)
  const hasOnList = answerType === AnswerType.WhatsAppList && !equals(currentOnList, defaultOnList)
  const hasOpenEnded = answerType === AnswerType.OpenEnded && hasOpenEndedResponse(openEnded)

  return hasOnAnswer || hasOnButtons || hasOnList || hasOpenEnded || transcribe || useAi
}

export type TabSwitchConfirmation = {
  tab: AnswerType
  text: string
  title: string
}

export const checkTabSwitchConfirmation = (state: State, nextTab: AnswerType): TabSwitchConfirmation | undefined => {
  if (hasResponse(state)) {
    return {
      tab: nextTab,
      text: 'Switching between response types will discard your current response config. Do you want to continue?',
      title: 'Change response type',
    }
  }
  if (nextTab === AnswerType.WhatsAppList && state.registerTemplate) {
    return {
      tab: nextTab,
      text: 'WhatsApp Lists cannot be used as templates. Do you want to disable template registration to continue?',
      title: 'Template registration will be disabled',
    }
  }
  return undefined
}

export const validateButton = validate(
  hasRequiredError,
  hasMaxError(WA_LIST_BUTTON_LENGTH, ERROR_WHATSAPP_LIST_BUTTON_LENGTH),
)

export const getPartActions = ({ campaignType }: { campaignType: CampaignTemplateType }) => {
  const partType = PartFamily.WhatsApp

  const buttonActions = getActionOptionsForCampaign({
    campaignType,
    partType,
    actionNamespace: ActionContext.OnButtons,
  })

  const timeoutActions = getActionOptionsForCampaign({
    campaignType,
    partType,
    actionNamespace: ActionContext.OnTimeout,
  })

  const invalidActions = getActionOptionsForCampaign({
    campaignType,
    partType,
    actionNamespace: ActionContext.OnInvalid,
  })

  const failureActions = getActionOptionsForCampaign({
    campaignType,
    partType,
    actionNamespace: ActionContext.OnFailure,
  })

  return {
    buttonActions,
    timeoutActions,
    invalidActions,
    failureActions,
  }
}
