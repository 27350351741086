import { Box, Icon, IconProps, SxProps, Theme } from '@mui/material'
import React, { PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
  color?: IconProps['color']
  sx?: SxProps<Theme>
}>

const WarningText: React.FC<Props> = ({ children, color = 'primary', sx }) => {
  return (
    <Box component="span" sx={{ alignItems: 'center', display: 'flex', gap: 0.5, ...sx }}>
      <Icon
        color={color}
        sx={{
          '&.MuiIcon-root': {
            fontSize: 32,
          },
        }}
      >
        priority_high
      </Icon>
      <Box component="span">{children}</Box>
    </Box>
  )
}

export default WarningText
