import React from 'react'
import { ChoiceValueSingle } from '@/app/module/campaigns/types'
import { CAMPAIGN_CHANGE_DELAY } from '@/app/module/campaigns/definitions'
import { useDebouncedChange } from '@/app/helpers'
import ChoiceContainer from './question/choice/choice-container'
import SingleChoice from './question/choice/single-choice'

type Props = {
  choiceLabel?: string
  disabled?: boolean
  value: ChoiceValueSingle

  onChange: (value: ChoiceValueSingle) => void
}

const ChoiceTagSingle: React.FC<Props> = ({ choiceLabel, disabled, value, onChange }) => {
  const [localValue, setLocalValue, handleChange] = useDebouncedChange(value, onChange, CAMPAIGN_CHANGE_DELAY)

  React.useEffect(() => {
    // update the local value if the value from the parent changes
    setLocalValue((s) => ({ ...s, reply: value.reply }))
  }, [value.reply, setLocalValue])

  return (
    <ChoiceContainer label={choiceLabel}>
      <SingleChoice disabled={disabled} tagList={localValue} onChange={handleChange} />
    </ChoiceContainer>
  )
}

export default React.memo(ChoiceTagSingle)
