import { SearchableSelect } from '@/app/component/atom/form'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { addCurlyBrackets, removeCurlyBrackets } from '@/app/module/utils/personalization'
import { SelectOption } from '@/app/types'
import { styled } from '@mui/material'
import React from 'react'

type Props = {
  personalizationList: PersonalizationType
  t0: string
  onChange: (t0: string) => void
}

const EventTimePzSelect: React.FC<Props> = ({ personalizationList, t0, onChange }) => {
  const groupedPzOptions = React.useMemo(() => {
    const { contact, customfields } = personalizationList

    return [
      {
        title: 'Contact fields',
        data: (contact || []).filter((pz) => pz.value !== 'contact.id'),
      },
      {
        title: 'Custom fields',
        data: customfields || [],
      },
    ]
  }, [personalizationList])

  return (
    <Container>
      <SearchableSelect
        formControlProps={{
          margin: 'none',
        }}
        grouped
        hideRemoveSelection
        labelId="t0-pz-label"
        id="t0-pz-select"
        label="Select field"
        showAll
        value={removeCurlyBrackets(t0)}
        keyPropFn={(option: SelectOption<string>) => option.value}
        valuePropFn={(option: SelectOption<string>) => option.label}
        onChange={(selectedPz: string) => onChange(addCurlyBrackets(selectedPz))}
        options={groupedPzOptions}
      />
    </Container>
  )
}

const Container = styled('div')(() => ({
  marginBottom: '0.5rem',
}))

export default React.memo(EventTimePzSelect)
