import { Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import { filter, find, keys, pathOr, propEq, where } from 'ramda'
import React from 'react'
import { Select } from '@/app/component/atom/form'
import { CountryTimezone, TimezoneWithCountryCodeType } from '@/app/types'
import {
  CallSchedule,
  CallWindow,
  CampaignItemWithVoice,
  IndexedCallSchedule,
  TimeUnit,
} from '@/app/module/campaigns/types'
import CustomSchedule from './custom-schedule'

type Props = {
  countriesTimezones: CountryTimezone[]
  countryCode: string
  item: CampaignItemWithVoice
  timezones: TimezoneWithCountryCodeType

  onChange: (item: CampaignItemWithVoice) => void
}

const Retry: React.FC<Props> = ({ countriesTimezones, countryCode, item, timezones, onChange }) => {
  const { callWindow = defaultWindow, callAgainMax = 0, callAgainCustom = initialSchedule } = item
  const customSchedule = prepareCustomSchedule(callAgainCustom)

  const [callAgain, setCallAgain] = React.useState(callAgainMax)
  const [country, setCountry] = React.useState(countryCode)
  const [timezone, setTimezone] = React.useState(callWindow.timezone)
  const [schedule, setSchedule] = React.useState(customSchedule)
  const [shouldCallAgain, setShouldCallAgain] = React.useState('never')

  const updateCallAgainMax = ({ value }: { value: string | number }) => {
    onChange({
      ...item,
      callAgainMax: Number(value),
    })
    setCallAgain(Number(value))
  }

  const updateCallAgainSchedule = (value: IndexedCallSchedule[]) => {
    onChange({
      ...item,
      callAgainCustom: value,
    })
    setSchedule(value)
  }

  const updateTimezone = () => {
    if (callWindow.timezone) {
      setTimezone(callWindow.timezone)
    } else {
      setTimezone(pathOr('', [country, 'tzs', 0, 'timezone'], timezones))
      onChange({
        ...item,
        callWindow: {
          ...item.callWindow,
          timezone: pathOr('', [country, 'tzs', 0, 'timezone'], timezones),
        },
      })
    }
  }

  React.useEffect(() => {
    if (callAgainMax === 0) {
      setShouldCallAgain('default')
      setCallAgain(0)
    } else if (callAgainMax === -1) {
      setShouldCallAgain('never')
      setCallAgain(-1)
    } else if (callAgainMax === -2) {
      setShouldCallAgain('schedule')
      updateCallAgainSchedule(customSchedule)
    } else {
      setShouldCallAgain('custom')
      setCallAgain(callAgainMax)
    }
    const propCountry = keys(
      filter(where({ tzs: find(propEq('timezone', callWindow.timezone)) }))(timezones),
    )[0] as string
    setCountry(propCountry || countryCode)
    updateTimezone()
    // eslint-disable-next-line
  }, [])

  const updateShouldAgain = ({ value }: { value: string }) => {
    if (value === 'default') {
      updateCallAgainMax({
        value: '0',
      })
    } else if (value === 'never') {
      updateCallAgainMax({
        value: '-1',
      })
    } else if (value === 'schedule') {
      updateCallAgainMax({
        value: '-2',
      })
    } else {
      updateCallAgainMax({
        value: '3',
      })
    }
    setShouldCallAgain(value)
  }

  return (
    <Card style={{ marginBottom: '2rem' }}>
      <CardHeader title="Configure your calls" style={{ padding: '12px 24px' }} />
      <CardContent>
        <div
          style={{
            marginBottom: '15px',
          }}
        >
          <Typography variant="h6">Retry Calls</Typography>
          <Typography component="div" variant="body1">
            {'If the contact does not pick up the phone, should we try calling them again? '}
            <Select
              style={{ height: '30px', width: 'auto', marginTop: '-10px', paddingLeft: '10px', paddingRight: '8px' }}
              value={shouldCallAgain}
              values={callAgainList}
              variant="outlined"
              name="call-again-dropdown"
              onChange={(payload: { value: string }) => {
                updateShouldAgain(payload)
              }}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Typography>
          {shouldCallAgain === 'custom' && (
            <div
              style={{
                marginTop: '15px',
                marginBottom: '5px',
              }}
            >
              <Typography component="div">
                {'Call again '}
                <TextField
                  id="call-again-custom-input"
                  type="number"
                  variant="outlined"
                  value={callAgain}
                  InputProps={{
                    style: {
                      color: 'inherit',
                      fontSize: 'inherit',
                      width: 'auto',
                      minWidth: '0',
                    },
                  }}
                  inputProps={{
                    min: '1',
                    max: '99',
                    style: {
                      padding: '2px 6px',
                    },
                  }}
                  onChange={(e) => {
                    updateCallAgainMax({
                      value: e.currentTarget.value,
                    })
                  }}
                />
                {` time${callAgain === 1 ? '' : 's'}`}
              </Typography>
            </div>
          )}
          <Typography color="textSecondary" variant="caption" gutterBottom={true}>
            {find(propEq('value', shouldCallAgain), callAgainList)?.helpText || ''}
          </Typography>
          {shouldCallAgain === 'schedule' && (
            <CustomSchedule
              countriesTimezones={countriesTimezones}
              countryCode={countryCode}
              schedule={schedule}
              timezone={timezone}
              timezones={timezones}
              onChange={updateCallAgainSchedule}
            />
          )}
        </div>
      </CardContent>
    </Card>
  )
}

const callAgainList = [
  {
    label: "No, don't call again",
    value: 'never',
    helpText: 'We will not call contacts again who do not pick up the call on the initial attempt.',
  },
  {
    label: 'Yes, use the default schedule',
    value: 'default',
    helpText:
      'Default: We will retry 3 times. The first call will happen 15 minutes after the initial attempt, including delays. The second attempt will happen 45 minutes after the first, again including delays. The third time will be scheduled for 24 hours after the initial call should have been made. Of course, we will only make calls during the call windows that you set, so retry calls might not happen exactly on that schedule.',
  },
  {
    label: 'Yes, use the default schedule, but I want to change the number of calls',
    value: 'custom',
    helpText:
      'The first call will happen 15 minutes after the initial call attempt was actually made, including delays. If more retries are wanted, the second call will happen 45 after the previous attempt. The third attempt will be scheduled for 24 hours after the initial call should have been made. All following attempts will be scheduled one day after the previous one. Of course, we will only make calls during the call windows that you set, so retry calls might not happen exactly on that schedule.',
  },
  {
    label: 'Yes, but I want to customize my own schedule',
    value: 'schedule',
    helpText: 'Specify when and how often we call again.',
  },
]

const initialSchedule = [
  {
    unit: TimeUnit.Minute,
    value: 15,
  },
  {
    unit: TimeUnit.Minute,
    value: 45,
  },
  {
    unit: TimeUnit.Hour,
    value: 23,
  },
]

const defaultWindow: CallWindow = {
  monday: undefined,
  tuesday: undefined,
  wednesday: undefined,
  thursday: undefined,
  friday: undefined,
  saturday: undefined,
  sunday: undefined,
}

const prepareCustomSchedule = (schedule: CallSchedule[] = []) =>
  schedule.map(
    (entry: CallSchedule, index: number) => ({
      ...entry,
      index,
    }),
    schedule,
  )

export default Retry
