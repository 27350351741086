import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import CountryFlag from '@/app/component/atom/flag'
import Tooltip from '@/app/component/atom/tooltip'
import { dateTime, formatPhoneNumber, parsePhoneNumber } from '@/app/service/util'
import { callProps, formatCost } from '@/app/module/logs/helpers'
import { getCallerNumber, getCallerReceiverNumber, isInbound } from '@/app/module/logs/utils'
import moment from 'moment'

const useStyle = makeStyles()({
  card: {
    padding: '5px',
  },
  messageType: {
    paddingTop: '3px',
  },
  dataCell: {
    paddingTop: '8px',
  },
  popover: {
    position: 'absolute',
    marginRight: '20px',
    top: '50px',
    left: '27px',
    zIndex: 1,
  },
  cardContent: {
    padding: '15px',
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rowLoader: {
    textAlign: 'center',
  },
})

const initialDate = new Date(2005, 1, 1)

const getCallStatus = ({ direction, isMissedCall, answer, finalError }: callProps): string => {
  if (direction === 'ingress') {
    if (isMissedCall) {
      return 'Missed Call'
    }
    return 'Answered'
  }
  if (moment(answer).isAfter(initialDate)) {
    return 'Answered'
  }
  return `Not Answered: ${finalError}`
}

const checkCallTime = ({ answer, finalErrorTime, created }: callProps): string => {
  if (moment(answer).isAfter(initialDate)) {
    return dateTime(answer)
  }
  if (moment(finalErrorTime).isAfter(initialDate)) {
    return dateTime(finalErrorTime)
  }
  return dateTime(created)
}

const processDuration = (duration: number): string => {
  if (duration === 1) {
    return '1 second'
  }
  return `${duration} seconds`
}

export default function CallRow(props: callProps) {
  const { contactId, direction, duration, isLoadingRow, viewContact, isMissedCall } = props
  const { classes } = useStyle()

  const isInboundType = isInbound(props)
  const contactNumber = getCallerNumber(props)
  const messageStatus = getCallStatus(props)

  const callTime = checkCallTime(props)

  return (
    <Card className={classes.card}>
      {isLoadingRow ? (
        <CardContent>
          <Grid container spacing={8}>
            <Grid item xs={12} className={classes.rowLoader}>
              <CircularProgress color="primary" size={50} />
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={5} md={4}>
              <Typography variant="subtitle1" color="textPrimary">
                <CountryFlag code={parsePhoneNumber(contactNumber).country || ''} />
                &nbsp;
                {formatPhoneNumber(`+${contactNumber}`)}
                &nbsp;
                {direction && (
                  <Tooltip title={isInboundType ? 'Inbound' : 'Outbound'}>
                    <Icon className={classes.messageType} color={isInboundType ? 'primary' : 'secondary'}>
                      {isInboundType ? 'call_received' : 'call_made'}
                    </Icon>
                  </Tooltip>
                )}
              </Typography>
            </Grid>
            <Grid className={classes.dataCell} item xs={4} sm={2} md={2}>
              <Typography color="textSecondary" align="center">
                <span>{messageStatus}</span>
              </Typography>
              <Typography color="primary" align="center" variant="body1">
                <b>({formatCost(props.chargedMicro)})</b>
              </Typography>
            </Grid>
            <Grid className={classes.dataCell} item xs={3} sm={2} md={3}>
              <Typography color="textSecondary" align="center">
                <Tooltip title={'Date and Time'}>
                  <span>&nbsp;{callTime}</span>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid className={classes.dataCell} item xs={3} sm={2} md={2}>
              <Typography color="textSecondary" align="center">
                <Tooltip title={'From/To'}>
                  <span>{getCallerReceiverNumber(props)}</span>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={2} sm={1} md={1}>
              <Tooltip title={'View Contact'}>
                <IconButton
                  aria-label="View Contact"
                  onClick={() => {
                    viewContact(contactId)
                  }}
                >
                  <Icon>person</Icon>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.truncate} variant="subtitle1" color="textPrimary">
                {isMissedCall ? '0 seconds' : processDuration(duration)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  )
}
