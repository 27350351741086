import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import MuiDialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import { TWhatsappButtonsContent } from '@/app/types'
import WhatsappButtons from './whatsapp-buttons'

type Props = Omit<DialogProps, 'content'> & {
  content: TWhatsappButtonsContent

  onClick?: () => void
}

const WhatsappButtonsDialog: React.FC<Props> = ({ content, onClick, ...props }) => (
  <Dialog {...props}>
    <DialogTitle data-testid="whatsapp-buttons-dialog">WhatsApp Buttons</DialogTitle>
    <DialogContent>
      <WhatsappButtons content={content} />
    </DialogContent>
    <DialogActions>
      <Button color="primary" variant="contained" onClick={onClick}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
)

const Dialog = styled(MuiDialog)(() => ({
  '& .MuiDialog-paper': {
    minWidth: 400,
  },
}))

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  paddingTop: `${theme.spacing(3)} !important`,
}))

export default WhatsappButtonsDialog
