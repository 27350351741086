import ListSelect from '@/app/component/atom/list-select'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { SelectOption } from '@/app/types'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
  debounce,
  styled,
} from '@mui/material'
import React from 'react'

type Props = {
  loading: boolean
  options: SelectOption<string>[]
  value: number[]
  onCampaignsSearch?: (search: string) => void
  onClose: () => void
  onSave: (value: number[]) => void
}

const CampaignsFilterDialog: React.FC<Props> = ({ loading, options, value, onCampaignsSearch, onClose, onSave }) => {
  const selectedCampaignIds = React.useMemo(() => value.map((id) => String(id)), [value])
  const [localValue, setLocalValue] = React.useState(selectedCampaignIds)

  const stringCampaignOptions = React.useMemo(
    () =>
      options.map((option) => ({
        ...option,
        value: String(option.value),
      })),
    [options],
  )

  return (
    <CustomDialog open onClose={onClose}>
      <StyledDialogTitle>
        Select Campaigns
        <IconButton onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </StyledDialogTitle>
      <DialogContent>
        <Content>
          <Typography variant="caption" color="textSecondary">
            Only the 100 most recent campaigns are shown. Use the search bar to find other launched campaigns.
          </Typography>
          <ListSelect
            name="campaign-ids"
            loading={loading}
            options={stringCampaignOptions}
            selectedOptions={localValue}
            onChange={setLocalValue}
            onSearch={onCampaignsSearch && debounce(onCampaignsSearch, 500)}
          />
        </Content>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!localValue.length}
          variant="contained"
          onClick={() => onSave(localValue.map((id) => Number(id)))}
        >
          Save
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const Content = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
}))

export default CampaignsFilterDialog
