import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Radio } from '../../../../component/atom/form'
import IconText from '../../../../component/layout/icon-text'

const values = [
  {
    label: '.xlsx',
    value: 'xlsx',
  },
  {
    label: '.csv',
    value: 'csv',
  },
]

export default function DownloadDialog({ isOpen, extension, onChange, onClose, onConfirm }) {
  return (
    <Dialog id="download-dialog" open={!!isOpen}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>cloud_download</Icon>
            <Typography variant="h6">Download contacts</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Choose the file format you wish to download the contacts in:</DialogContentText>
        <Radio value={extension} values={values} onChange={onChange} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" id="contacts-download-confirm" onClick={onConfirm}>
          Download
        </Button>
      </DialogActions>
    </Dialog>
  )
}
