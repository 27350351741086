import { Card, CardContent, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'

type TableData = {
  name: string
  value: string | number
}

type Props = {
  data: TableData[]
  title: string
}

const OverviewTable: React.FC<Props> = ({ data, title }) => {
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ padding: 0 }}>
        <Typography
          variant="h5"
          color="textSecondary"
          sx={{
            textAlign: 'center',
            padding: (theme) => theme.spacing(2.5),
          }}
        >
          {title}
        </Typography>
        <Table
          sx={{
            border: 'none',
          }}
        >
          <TableBody>
            {data.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default OverviewTable
