import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import { find, findIndex, has, keys, map, pathOr, propEq, reduce, update } from 'ramda'
import React from 'react'
import { getPartContentPreview } from '@/app/module/campaigns/utils/part-content'
import { MESSAGE_TYPES } from '@/app/module/campaigns/definitions'
import { mapIndexed } from '@/app/module/campaigns/helpers'
import MessageDetails from './components/messageDetails'

const iconMap = {
  smsMessage: 'sms',
  smsQuestion: 'question_answer',
  voiceMessage: 'phone',
  voiceQuestion: 'ring_volume',
  actionsOnly: 'account_tree',
  whatsappMessage: 'sms',
  whatsappQuestion: 'question_answer',
  catiMessage: 'sms',
  catiQuestion: 'question_answer',
}

const isQuestionMap = {
  smsMessage: false,
  smsQuestion: true,
  voiceMessage: false,
  voiceQuestion: true,
  whatsappMessage: false,
  whatsappQuestion: true,
  catiMessage: false,
  catiQuestion: true,
}

const processContentForPieChartWithLabels = (part) =>
  reduce(
    (accum, key) => {
      const label = pathOr(key, ['replies', 'answerLabels', key], part)
      const answerIndex = findIndex(propEq('key', label), accum)
      if (answerIndex > -1) {
        const updatedValue = accum[answerIndex].value + pathOr(0, ['replies', 'answers', key], part)
        const updatePercentage = (updatedValue / pathOr(1, ['replies', 'valid'], part)) * 100
        return update(
          answerIndex,
          {
            ...accum[answerIndex],
            value: updatedValue,
            percentage: `${updatePercentage.toFixed(0)}%`,
            legendLabel: `${label} (${updatedValue} - ${updatePercentage.toFixed(0)}%)`,
          },
          accum,
        )
      }
      const value = pathOr(0, ['replies', 'answers', key], part)
      const percentage = (value / pathOr(1, ['replies', 'valid'], part)) * 100
      return [
        ...accum,
        {
          key: label,
          value,
          percentage: `${percentage.toFixed(0)}%`,
          legendLabel: `${label} (${value} - ${percentage.toFixed(0)}%)`,
        },
      ]
    },
    [],
    keys(pathOr({}, ['replies', 'answers'], part)),
  )

const processContentForPieChart = (part) =>
  map(
    (key) => {
      const value = pathOr(0, ['replies', 'answers', key], part)
      const percentage = (value / pathOr(1, ['replies', 'valid'], part)) * 100
      return {
        key,
        value,
        percentage: `${percentage.toFixed(0)}%`,
        legendLabel: `${key} (${value} - ${percentage.toFixed(0)}%)`,
      }
    },
    keys(pathOr({}, ['replies', 'answers'], part)),
  )

const selectContent = (message, index, itemSummary, showLabels) =>
  reduce(
    (accum, type) => {
      if (has(type.key, message)) {
        const { partStats = [] } = itemSummary
        return {
          message: getPartContentPreview(message),
          icon: iconMap[type.key],
          isQuestion: isQuestionMap[type.key],
          id: message.id,
          name: `${index + 1}. ${message.label || type.label}`,
          answers: showLabels
            ? processContentForPieChartWithLabels(find(propEq('id', message.id), partStats))
            : processContentForPieChart(find(propEq('id', message.id), partStats)),
          total: pathOr(0, ['replies', 'valid'], find(propEq('id', message.id), partStats)),
        }
      }
      return accum
    },
    {},
    MESSAGE_TYPES,
  )

const processContentForSummary = ({ item, itemSummary }, showLabels) =>
  mapIndexed(
    (message, index) => selectContent(message, index, itemSummary, showLabels),
    pathOr([], ['variables', 'parts'], item),
  )

export default function AnalyticsTab(props) {
  const [showLabels, setShowLabels] = React.useState(true)
  const content = processContentForSummary(props, showLabels)
  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: '30px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <FormControlLabel
            control={<Checkbox checked={showLabels} onChange={() => setShowLabels(!showLabels)} />}
            label="Show answer labels where available"
          />
        </div>
        <Grid item md={12}>
          {content.map(({ name, message, icon, isQuestion, id, answers, total }, index) => (
            <MessageDetails
              name={name}
              message={message}
              icon={icon}
              isQuestion={isQuestion}
              id={id}
              index={index}
              chartData={answers}
              total={total}
            />
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
