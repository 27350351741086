import { isEmpty } from 'ramda'
import Linkify from 'react-linkify'
import { FunctionType } from '@/config/types'
import { processErrors } from '@/app/service/util/errorCodes'
import { BackendCampaignTemplate } from '@/app/module/campaigns/types'
import { CampaignType, ErrorType, RunType, SystemErrorType } from './types'

const MAX_CONTACT_LABEL_LENGTH = 10

export const processContactLabel = (contact: RunType | undefined) =>
  [contact?.firstName, contact?.lastName].filter(Boolean).join(' ')

export const getContactLabel = (contact: RunType): { shortLabel: string; longLabel: string } => {
  const label = processContactLabel(contact)
  if (label.length > MAX_CONTACT_LABEL_LENGTH) {
    return {
      shortLabel: `${label.substring(0, MAX_CONTACT_LABEL_LENGTH - 2)}...`,
      longLabel: label,
    }
  }
  return {
    shortLabel: label || '',
    longLabel: '',
  }
}

export const ERROR_DISPLAY_MAP: { [key: string]: ErrorType } = {
  'campaign.subnotfound': {
    icon: 'error',
    title: processErrors(
      { response: { errorCode: 'campaign.subnotfound', error: 'subscription not found' } },
      'Survey not found',
    ),
    subtitle: 'CATI Survey subscription not found.',
  },
  'campaign.notfound': {
    icon: 'error',
    title: processErrors(
      { response: { errorCode: 'campaign.notfound', error: 'campaign not found' } },
      'Survey not found',
    ),
    subtitle: "CATI Survey not found. Maybe you've logged in to the wrong organization?",
    link: '/campaigns',
    linkText: 'Go back to campaigns',
  },
}

export const CAMPAIGN_LABEL_MAP: { [key in BackendCampaignTemplate]: string } = {
  [BackendCampaignTemplate.CATI]: 'a CATI Survey',
  [BackendCampaignTemplate.Drip]: 'a Drip Campaign',
  [BackendCampaignTemplate.Reminder]: 'a Reminder Campaign',
  [BackendCampaignTemplate.SMSBlast]: 'an SMS Blast Campaign',
  [BackendCampaignTemplate.SMSSurvey]: 'an SMS Survey',
  [BackendCampaignTemplate.VoiceBlast]: 'a Voice Blast Campaign',
  [BackendCampaignTemplate.VoiceSurvey]: 'a Voice Survey',
  [BackendCampaignTemplate.WhatsAppSurvey]: 'a WhatsApp Survey',
}

export const generateError = (
  camp: CampaignType | {} | null,
  done: boolean,
  callback: FunctionType,
  error: SystemErrorType | any,
): ErrorType => {
  if (isEmpty(camp)) {
    return {
      icon: 'error',
      title: "You don't have access to this CATI Survey (anymore).",
      link: '/cati/contacts',
      linkText: 'Go back to CATI list',
    }
  }
  const campaign = camp as CampaignType
  if (campaign && campaign.template !== 'cati') {
    return {
      icon: 'error',
      title: `This is ${CAMPAIGN_LABEL_MAP[campaign.template]}, not a CATI Survey.`,
      link: `/campaigns/edit/${campaign.id}`,
      linkText: 'Go to campaign',
    }
  }
  if (done) {
    return {
      icon: 'error',
      title: 'This survey is now done.',
      linkText: 'Return to contacts',
      callback,
    }
  }
  if (!isEmpty(error)) {
    return (
      ERROR_DISPLAY_MAP[error?.response?.errorCode] || {
        icon: 'error',
        title: 'There was an error retrieving your survey',
        subtitle: 'Check if you entered the correct url and try again.',
      }
    )
  }
  return {}
}

export const renderText = (text: string) => {
  const textParts = text.split('\n')
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target="blank" href={decoratedHref} key={key}>
          {decoratedText}
        </a>
      )}
    >
      {textParts.map((item, index) => (
        <span key={index}>
          {item}
          {index !== textParts.length - 1 && <br />}
        </span>
      ))}
    </Linkify>
  )
}
