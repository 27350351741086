import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React from 'react'
import CopyBox from '@/app/component/atom/copy-box'
import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import RecoveryCodes from './recovery-codes'

type Props = {
  totpQRCode: string
  token: string
  totpSecret: string

  enableTotp: (payload: {
    token: string
    passcode: string
    secret: string
  }) => Promise<{ recoveryCodes?: string[] } | Error>
  removeRecoveryCodes: () => void
  setIsTotpModalOpen: (open: boolean) => void
}

export default function AuthenticationVerification(props: Props) {
  const { setIsTotpModalOpen, totpQRCode, enableTotp, token, totpSecret, removeRecoveryCodes } = props

  const [isLoading, setIsLoading] = React.useState(false)
  const [passcode, setPasscode] = React.useState('')
  const [recoveryCodes, setRecoveryCodes] = React.useState<string[]>([])
  const [showSecret, setShowSecret] = React.useState(false)

  const handleEnableTotp = async () => {
    if (isLoading) {
      return
    }
    try {
      setIsLoading(true)
      const res = await enableTotp({ token, passcode, secret: totpSecret })
      if (res instanceof Error) {
        return
      }
      setRecoveryCodes(res.recoveryCodes || [])
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <CustomDialog fullWidth id="enable-totp-modal" open={true} onClose={() => setIsTotpModalOpen(false)}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>lock</Icon>
            <Typography className="subscription-delay-title" variant="h6">
              Authentication verification
            </Typography>
          </IconText>
          <IconButton onClick={() => setIsTotpModalOpen(false)}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <>
          {totpQRCode && recoveryCodes.length === 0 && (
            <div>
              <StepTitle>Step 1: Setup your authentication app</StepTitle>
              {showSecret ? (
                <>
                  <Typography>Enter the below code in your authentication app</Typography>
                  <Typography>
                    If you prefer to scan a QR code,{' '}
                    <Link href="#" onClick={() => setShowSecret(false)}>
                      click here
                    </Link>
                    .{' '}
                  </Typography>
                  <div>
                    <CopyBox inputStyle={{ width: '400px' }} title="" text={totpSecret} />
                  </div>
                </>
              ) : (
                <>
                  <Typography>
                    Scan the image below with your two-factor authentication app, for example Google Authenticator or
                    Authy. If you can’t use a QR code,{' '}
                    <Link href="#" onClick={() => setShowSecret(true)}>
                      enter this code
                    </Link>{' '}
                    instead.
                  </Typography>
                  <div
                    style={{
                      textAlign: 'center',
                      margin: '10px 5px 20px',
                    }}
                  >
                    <div
                      style={{
                        width: 'fit-content',
                        padding: '15px',
                        borderRadius: '4px',
                        margin: 'auto',
                        border: '1px solid #a0a0a0',
                      }}
                    >
                      <img src={`data:image/png;base64,${totpQRCode}`} />
                    </div>
                  </div>
                </>
              )}
              <div></div>
              <StepTitle>Step 2: Enter the six-digit code from the application</StepTitle>
              <div
                style={{
                  marginTop: '10px',
                }}
              >
                <FormControl variant="standard">
                  <Typography>Enter the six-digit code.</Typography>
                  <TextField
                    autoFocus={true}
                    onKeyDown={(e) => {
                      if (e.key !== 'Enter') {
                        return
                      }
                      handleEnableTotp()
                    }}
                    label="Code"
                    type="number"
                    variant="outlined"
                    value={passcode}
                    onChange={(e: any) => setPasscode(e.currentTarget.value)}
                  />
                </FormControl>
              </div>
            </div>
          )}
          {recoveryCodes.length > 0 && (
            <div>
              <Typography>Save your recovery codes</Typography>
              <div>
                <RecoveryCodes recoveryCodes={recoveryCodes} />
              </div>
              <div style={{}}>
                <Typography>
                  If you lose access to your device, you can use recovery codes to access your account instead.
                </Typography>
              </div>
            </div>
          )}
        </>
        <DialogActions>
          {totpQRCode && recoveryCodes.length === 0 && (
            <Button
              disabled={passcode.length !== 6 || isLoading}
              variant="contained"
              color="primary"
              onClick={handleEnableTotp}
            >
              Verify
            </Button>
          )}
          {recoveryCodes.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIsTotpModalOpen(false)
                removeRecoveryCodes()
              }}
            >
              Close
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </CustomDialog>
  )
}

const StepTitle = ({ children }: { children: string }) => (
  <div
    style={{
      marginBottom: '15px',
    }}
  >
    <Typography variant="h5">{children}</Typography>
    <Divider />
  </div>
)
