import { props as multiProps } from 'ramda'
import React from 'react'

const hasAllPropsReady = (hydrateProps, props) => hydrateProps.reduce((acc, propKey) => acc && !!props[propKey], true)

export default function createHydrated(Wrapped) {
  return function Hydratable(props) {
    const { hydrateProps = [], hydrate = () => {}, campaignHydrateProps = [], campaignHydrate = () => {} } = props
    React.useEffect(() => {
      if (hydrateProps && hasAllPropsReady(hydrateProps, props)) {
        hydrate(props)
      }
      // eslint-disable-next-line
    }, [...multiProps(hydrateProps, props)])
    React.useEffect(() => {
      if (campaignHydrateProps && hasAllPropsReady(campaignHydrateProps, props)) {
        campaignHydrate(props)
      }
      // eslint-disable-next-line
    }, [...multiProps(campaignHydrateProps, props)])
    return <Wrapped {...props} />
  }
}
