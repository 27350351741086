import { GlobalStyles } from '@mui/material'
import { replace } from 'ramda'
import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { processInput } from '@/app/module/campaigns/helpers'
import { hasEmailError } from '@/app/service/validate'
import PzMenu from '@/app/component/atom/pz-menu'
import InputTag from './input-tag'

type Props = {
  label?: string
  personalizationList?: {
    [key: string]: Array<{ label: string; value: string }>
  }
  value: string[]
  onChange: (value: string[]) => void
}

const EmailAddressField: React.FC<Props> = ({
  label = 'Recipient email addresses',
  personalizationList = {},
  value: tags,
  onChange,
  ...restProps
}) => {
  const [blurTimeout, setBlurTimeout] = useState<any>(null)
  const [error, setError] = useState('')
  const [inputValue, setInputValue] = useState('')
  const { classes } = useStyles()

  const tagsMap = useMemo<{ [key: string]: string }>(
    () => tags.reduce((obj, tag) => ({ ...obj, [tag]: 1 }), {}),
    [tags],
  )

  const updateInput = (input: string) => {
    if (blurTimeout) {
      clearTimeout(blurTimeout)
    }
    const cleansedInput = replace(/(?:\r\n|\r|\n)/g, ',', input)
    const tagsValue = processInput(cleansedInput)

    for (let i = 0; i < tagsValue.length; i += 1) {
      const v = tagsValue[i]
      if (tagsMap[v]) {
        setError('You cannot enter the same email address twice.')
        return
      }

      if (!v.match(/{{(.*?)}}/) && hasEmailError(v)) {
        setError('That is not a valid email address.')
        return
      }
    }

    if (tagsValue.length > 0) {
      onChange([...tagsValue, ...tags])
      setInputValue('')
    } else {
      setError('Please enter at least one email address')
    }
  }
  const handleBlur = () => {
    setBlurTimeout((s: any) => {
      if (s) {
        clearTimeout(s)
      }
      return setTimeout(() => {
        if (inputValue) {
          setError('Press Enter, Space or a comma to save each choice')
        }
      }, 500)
    })
  }
  const handleChange = (val: string) => {
    setInputValue(val)
  }
  const handleDelete = (index: number) => {
    onChange([...tags.slice(0, index), ...tags.slice(index + 1)])
  }
  const handleFocus = () => {
    setError((s) => {
      if (s) {
        return 'Press Enter, Space or a comma to save each choice'
      }
      return s
    })
  }
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault()
      if (inputValue) {
        updateInput(inputValue)
      }
    }
  }
  const formatTag = (tag: string) => tag.replace(/\{\{(.*?)\}\}/g, (i, match) => match)

  const endAdornment = useMemo(
    () => (
      <div className={classes.menuButton}>
        <PzMenu
          id="email-address-field"
          personalizationList={personalizationList}
          testId={{
            button: 'email-address-field-button',
            menu: 'email-address-field-menu',
          }}
          getTitle={(item) => {
            const tag = `{{${item.label}}}`
            if (tagsMap[tag]) {
              return 'You cannot insert the same custom field twice.'
            }
            if (excludedPz[item.value]) {
              return "This field can't be used because it can't contain an email address."
            }
            return ''
          }}
          isItemDisabled={(item) => {
            const tag = `{{${item.label}}}`
            return !!tagsMap[tag] || !!excludedPz[item.value]
          }}
          onSelect={(item) => {
            const tag = `{{${item.label}}}`
            onChange([...tags, tag])
          }}
        />
      </div>
    ),
    [classes, personalizationList, tags, tagsMap, onChange],
  )

  useEffect(() => {
    if (!inputValue && error) {
      setError('')
    }
  }, [inputValue, error])

  return (
    <>
      <GlobalStyles styles={globalStyles} />
      <div className={classes.inputContainer} data-testid="email-address-field">
        <InputTag
          endAdornment={endAdornment}
          error={!!error}
          fullWidth
          helperText={<span>{error || 'Press Enter, Space or a comma to save each choice'}</span>}
          label={label}
          tags={tags}
          value={inputValue}
          variant="outlined"
          formatTag={formatTag}
          onBlur={handleBlur}
          onChange={handleChange}
          onDelete={handleDelete}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          {...restProps}
        />
      </div>
    </>
  )
}

const excludedPz: { [key: string]: number } = {
  'contact.fullPhoneNumber': 1,
  'contact.id': 1,
}

const useStyles = makeStyles()(() => ({
  inputContainer: {
    marginBottom: 25,
  },

  menuButton: {
    position: 'absolute',
    right: '2px',
    top: '4px',
    zIndex: 999,
  },
}))

const globalStyles = {
  '.MuiButtonBase-root.Mui-disabled': {
    pointerEvents: 'auto',
  },
}

export default EmailAddressField
