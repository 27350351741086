import { createAsyncActions } from '@/app/service/util'
import request from '@/app/module/request'
import {
  ACTION_GET_BILLING_FAILURE,
  ACTION_GET_BILLING_REQUEST,
  ACTION_GET_BILLING_SUCCESS,
  ACTION_GET_DASHBOARD_FAILURE,
  ACTION_GET_DASHBOARD_REQUEST,
  ACTION_GET_DASHBOARD_SUCCESS,
  ERROR_GET_BILLING_SERVER,
  ERROR_GET_DASHBOARD_SERVER,
} from '@/app/module/dashboard/definitions'

const {
  request: getDashboardRequest,
  success: getDashboardSuccess,
  failure: getDashboardFailure,
} = createAsyncActions({
  request: ACTION_GET_DASHBOARD_REQUEST,
  success: ACTION_GET_DASHBOARD_SUCCESS,
  failure: ACTION_GET_DASHBOARD_FAILURE,
})

const {
  request: getBillingRequest,
  success: getBillingSuccess,
  failure: getBillingFailure,
} = createAsyncActions({
  request: ACTION_GET_BILLING_REQUEST,
  success: ACTION_GET_BILLING_SUCCESS,
  failure: ACTION_GET_BILLING_FAILURE,
})

export {
  getBillingFailure,
  getBillingRequest,
  getBillingSuccess,
  getDashboardFailure,
  getDashboardRequest,
  getDashboardSuccess,
}

export const getDashboard =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch(getDashboardRequest())
    return request.dashboard
      .get({
        token,
        orgId,
        query: {},
      })
      .then((res) => {
        dispatch(getDashboardSuccess(res))
      })
      .catch((err) => {
        dispatch(
          getDashboardFailure({
            status: err.status,
            message: ERROR_GET_DASHBOARD_SERVER,
          }),
        )
        return err
      })
  }

export const getBilling =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch(getBillingRequest())
    return request.billing
      .get({
        token,
        orgId,
        query: {},
      })
      .then((res) => {
        dispatch(getBillingSuccess(res))
      })
      .catch((err) => {
        dispatch(
          getBillingFailure({
            status: err.status,
            message: ERROR_GET_BILLING_SERVER,
          }),
        )
        return err
      })
  }
