import { Divider, List, styled } from '@mui/material'
import React from 'react'
import DashboardListItem from './dashboard-list-item'

export type DasboardItem = {
  action?: React.ReactNode
  label: React.ReactNode
  special?: boolean
  value: React.ReactNode
}

type Props = {
  items: DasboardItem[]
}

const DashboardList: React.FC<Props> = ({ items }) => (
  <StyledList>
    {items.map((item, index) => (
      <div key={index}>
        <DashboardListItem {...item} />
        {index < items.length - 1 && <Divider />}
      </div>
    ))}
  </StyledList>
)

const StyledList = styled(List)({
  maxHeight: '200px',
  overflowY: 'scroll',
})

export default DashboardList
