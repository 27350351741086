import { SearchableSelect } from '@/app/component/atom/form'
import Loading from '@/app/component/guard/loading'
import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { Q_ACTIONS } from '@/app/module/campaigns/definitions'
import { CampaignQuery, LocalResumeSubscriptionAction } from '@/app/module/campaigns/types'
import ZSetPausedSubscriptionAction from '@/app/module/campaigns/types/schemas/actions/set-paused-subscription'
import { SelectOption } from '@/app/types'
import {
  Button,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
  styled,
} from '@mui/material'
import React from 'react'

type Props = {
  campaignIds: SelectOption<number>[]
  orgId: number
  token: string
  value?: LocalResumeSubscriptionAction | {}
  getCampaigns: (payload: { orgId: number; token: string; query: CampaignQuery }) => Promise<void>
  onClose: () => void
  onSave: (value: Partial<LocalResumeSubscriptionAction>) => void
}

const ResumeSubscription: React.FC<Props> = ({ campaignIds, orgId, token, value, getCampaigns, onClose, onSave }) => {
  const [campaignId, setCampaignId] = React.useState(getDefaultCampaignId(value))
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)
    getCampaigns({
      orgId,
      token,
      query: {
        page: 1,
        size: 100,
        aborted: 'false',
        archived: 'false',
      },
    }).then(() => setLoading(false))
  }, [orgId, token, getCampaigns])

  const stringCampaignOptions = React.useMemo(
    () =>
      campaignIds.map((c) => ({
        label: c.label,
        value: c.value.toString(),
      })),
    [campaignIds],
  )

  const campaignOptions = React.useMemo(() => {
    if (campaignId && stringCampaignOptions.length && !stringCampaignOptions.find((c) => c.value === campaignId)) {
      return [...stringCampaignOptions, { label: `Campaign ${campaignId}`, value: campaignId }]
    }

    return stringCampaignOptions
  }, [campaignId, stringCampaignOptions])

  return (
    <CustomDialog open onClose={onClose}>
      <Loading isLoading={loading}>
        <DialogTitle>
          <DialogTitleContent
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <IconText>
              <Icon>{Q_ACTIONS.resumeSubscription.icon}</Icon>
              <Typography variant="h6">{Q_ACTIONS.resumeSubscription.label}</Typography>
            </IconText>
            <IconButton onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </DialogTitleContent>
        </DialogTitle>
        <StyledDialogContent>
          <Content>
            <CardContent>
              <Typography variant="caption" color="textSecondary">
                Select the campaign to resume contact subscription. Note that the contact has to have a paused
                subscription in the selected campaign.
              </Typography>
              <SearchableSelect
                id="select-campaign"
                formControlProps={{
                  style: {
                    margin: 'none',
                    width: '100%',
                  },
                }}
                helperText="Only the 100 most recent campaigns are shown."
                hideRemoveSelection
                labelId="select-campaign-label"
                label="Select Campaign"
                options={campaignOptions}
                showAll
                value={campaignId || ''}
                keyPropFn={(option: SelectOption<string>) => option.value}
                onChange={setCampaignId}
                valuePropFn={(option: SelectOption<string>) => option.label}
              />
            </CardContent>
          </Content>
        </StyledDialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={!campaignId}
            onClick={() =>
              onSave({
                setPaused: {
                  campId: Number(campaignId),
                  paused: false,
                },
              })
            }
          >
            Save
          </Button>
        </DialogActions>
      </Loading>
    </CustomDialog>
  )
}

const DialogTitleContent = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const StyledDialogContent = styled(DialogContent)(() => ({
  paddingTop: 0,
}))

const Content = styled('div')(() => ({
  width: '80vw',
  minWidth: '300px',
  maxWidth: '100%',
}))

const isResumeSubscriptionAction = (value: unknown): value is LocalResumeSubscriptionAction => {
  try {
    ZSetPausedSubscriptionAction.parse(value)
    return true
  } catch {
    return false
  }
}

const getDefaultCampaignId = (action: unknown) => {
  if (!isResumeSubscriptionAction(action) || action.setPaused.paused) {
    return undefined
  }

  const { campId } = action.setPaused
  return campId ? campId.toString() : ''
}

export default ResumeSubscription
