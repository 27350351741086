import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { SelectOption } from '@/app/types'
import { CampaignTemplateType, Message as TMessage, ReminderMessage, SnippetType } from '@/app/module/campaigns/types'
import { Box, Divider } from '@mui/material'
import { AddFromLibraryButton } from './add-from-library-button'
import { useMessageSnippets } from './hooks/use-snippets'
import { getEmptyMessage } from './utils/empty-message'
import { ContentTypeIcon } from './content-type-icon'

type MessageListProps = Pick<NoMessageProps, 'campaignType' | 'timezone' | 'onCreateMessage'> & {
  types: SelectOption<string>[]
}

const MessageList = ({ campaignType, types, timezone, onCreateMessage }: MessageListProps) => {
  return (
    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', margin: '1rem 0' }}>
      {types.map((type) => (
        <Button
          sx={{ py: 2, display: 'flex', alignItems: 'center', gap: 1 }}
          key={type.value}
          startIcon={<ContentTypeIcon type={type.value} />}
          variant="outlined"
          onClick={() => {
            const message = getEmptyMessage(campaignType, type.value, timezone, 0)
            onCreateMessage(message)
          }}
        >
          {type.label}
        </Button>
      ))}
    </div>
  )
}

type NoMessageProps = {
  campaignType: CampaignTemplateType
  messageIds: string[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  supportedMessageTypes: SelectOption<string>[]
  timezone: string
  onCreateMessage: (message: TMessage | ReminderMessage) => void
}

export function NoMessage({
  campaignType,
  messageIds,
  snippets,
  supportedMessageTypes,
  timezone,
  onCreateMessage,
}: NoMessageProps) {
  const { messageSnippets, isSupported } = useMessageSnippets({ snippets, supportedMessageTypes })

  if (campaignType === CampaignTemplateType.Drip) {
    const smsTypes = supportedMessageTypes.filter((type) => type.value.startsWith('sms'))
    const voiceTypes = supportedMessageTypes.filter((type) => type.value.startsWith('voice'))
    const whatsappTypes = supportedMessageTypes.filter((type) => type.value.startsWith('whatsapp'))
    const otherTypes = supportedMessageTypes.filter(
      (type) => !type.value.startsWith('sms') && !type.value.startsWith('voice') && !type.value.startsWith('whatsapp'),
    )

    return (
      <Paper id="no-message" sx={{ p: 2, mb: 2 }}>
        <p>Add your first message!</p>
        {isSupported && (
          <>
            <Divider />
            <Box py={2}>
              <AddFromLibraryButton
                campaignType={campaignType}
                messageSnippets={messageSnippets}
                messageIds={messageIds}
                timezone={timezone}
                onMessageCreate={onCreateMessage}
              />
            </Box>
            <Divider />
          </>
        )}
        <MessageList
          types={smsTypes}
          campaignType={campaignType}
          timezone={timezone}
          onCreateMessage={onCreateMessage}
        />
        <MessageList
          types={voiceTypes}
          campaignType={campaignType}
          timezone={timezone}
          onCreateMessage={onCreateMessage}
        />
        <MessageList
          types={whatsappTypes}
          campaignType={campaignType}
          timezone={timezone}
          onCreateMessage={onCreateMessage}
        />
        <MessageList
          types={otherTypes}
          campaignType={campaignType}
          timezone={timezone}
          onCreateMessage={onCreateMessage}
        />
      </Paper>
    )
  }

  return (
    <Paper id="no-message" sx={{ p: 2, mb: 2 }}>
      <p>Add your first message!</p>
      {isSupported && (
        <>
          <Divider />
          <Box py={2}>
            <AddFromLibraryButton
              campaignType={campaignType}
              messageSnippets={messageSnippets}
              messageIds={messageIds}
              timezone={timezone}
              onMessageCreate={onCreateMessage}
            />
          </Box>
          <Divider />
        </>
      )}
      <MessageList
        types={supportedMessageTypes}
        campaignType={campaignType}
        timezone={timezone}
        onCreateMessage={onCreateMessage}
      />
    </Paper>
  )
}
