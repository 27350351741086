import Loading from '@/app/component/guard/loading'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { formatPhoneNumber } from '@/app/module/conversations/format-helpers'
import { ContactType } from '@/app/module/conversations/types'
import { FixMeLater, RouterHistory } from '@/config/types'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { find, pathOr, propEq } from 'ramda'
import ResizeObserver from 'rc-resize-observer'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import ContactInfo from './contactInfo'
import Contacts from './contacts'
import History from './history'
import NewMessageBadge from './newMessageBadge'
import Send from './send'

type Props = {
  getCustomFields: FixMeLater
  getSenderIdList: FixMeLater
  token: string
  orgId: number
  personalizationList: PersonalizationType
  whatsappSenderIds: []
  smsSenderIds: []
  sendMessage: FixMeLater
  getFilesItem: FixMeLater
  saveWhatsappFile: FixMeLater
  onMicAccessError: FixMeLater
  sendWhatsappMessage: FixMeLater
  getContacts: FixMeLater
  messages: FixMeLater
  smsMessages: []
  whatsappMessages: []
  calls: []
  reloadMessages: FixMeLater
  getCalls: FixMeLater
  subscribeLogs: FixMeLater
  getContactInfo: FixMeLater
  contactInfo: ContactType
  history: RouterHistory
  readAllContactMessages: FixMeLater
  unsubscribeLogs: FixMeLater
  loading: boolean
  conversations: ContactType[]
  initialized: boolean
  contactId: number
  nextPage: string

  getContactData: (contactId: number, nextpage?: string) => void
}

const emptyContact: ContactType = {
  contactId: 0,
  firstName: '',
  lastName: '',
  fullPhoneNumber: '',
  created: '',
  groups: [],
  subscriptions: [],
  countryCode: '',
  history: [],
  customFields: [],
  segments: [],
  unreadCount: 0,
}

export default function Conversations(props: Props) {
  const {
    getCustomFields,
    getSenderIdList,
    token,
    orgId,
    personalizationList,
    smsSenderIds,
    whatsappSenderIds,
    sendMessage,
    getFilesItem,
    saveWhatsappFile,
    onMicAccessError,
    sendWhatsappMessage,
    getContacts,
    conversations: contacts,
    subscribeLogs,
    getContactInfo,
    contactInfo,
    history,
    readAllContactMessages,
    unsubscribeLogs,
    getContactData,
    loading,
    initialized,
    contactId: activeContactId,
    nextPage,
  } = props

  const wrapper = React.useRef<HTMLDivElement>(null)
  const [scrolledHistory, setScrolledHistory] = React.useState(false)
  const [infoView, setInfoView] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [changed, setTextChange] = React.useState(false)
  const { classes } = useStyle({ infoView })

  const sendRef = React.useRef<HTMLDivElement>(null)
  const currentElement = sendRef.current
  const [height, setSendHeight] = React.useState(currentElement?.scrollHeight || 0)

  const setActiveContactId = React.useCallback(
    (contactId: number) => {
      history.push(`/conversations?contactId=${contactId}`)
    },
    [history],
  )

  React.useEffect(() => {
    const unlisten = history.listen((location: any) => {
      const { pathname = '' } = location
      if (pathname !== '/conversations') {
        unsubscribeLogs()
      }
    })
    return () => {
      unlisten()
    }
  }, [history, unsubscribeLogs])

  React.useEffect(() => {
    if (token && orgId) {
      getContacts({})
      getCustomFields({ token, orgId })
      getSenderIdList({ token, orgId })
      subscribeLogs()
    }
  }, [orgId, token, getContacts, getCustomFields, getSenderIdList, subscribeLogs])

  const activeContact: ContactType = React.useMemo(
    () => find(propEq('contactId', activeContactId), contacts) || emptyContact,
    [activeContactId, contacts],
  )

  React.useEffect(() => {
    if (initialized && !loading && !activeContact.contactId) {
      setActiveContactId(pathOr(0, [0, 'contactId'], contacts))
    }
  }, [activeContact.contactId, contacts, initialized, loading, setActiveContactId])

  React.useEffect(() => {
    if (initialized && activeContact.contactId) {
      getContactInfo(activeContact.contactId)
      getContactData(activeContact.contactId)
    }
  }, [activeContact.contactId, initialized, getContactData, getContactInfo])

  React.useEffect(() => {
    const delay = setTimeout(() => {
      if (changed) {
        getContacts({ search: searchTerm, nextPage: '' })
      }
    }, 500)
    return () => clearTimeout(delay)
  }, [changed, searchTerm, getContacts])

  const saveFile = (file: any) => {
    if (token && orgId) {
      return saveWhatsappFile({
        orgId,
        token,
        item: file,
      })
    }
    return Error
  }

  const scrollToBottom = React.useCallback(() => {
    if (wrapper && wrapper.current) {
      setTimeout(() => {
        if (wrapper && wrapper.current) {
          wrapper.current.scrollTop = wrapper.current.scrollHeight
          setScrolledHistory(false)
        }
      }, 100)
      if (activeContact.unreadCount > 0) {
        readAllContactMessages(activeContactId)
      }
    }
  }, [activeContact.unreadCount, activeContactId, readAllContactMessages])

  const handleGetFile = React.useCallback(
    (filename: string) => getFilesItem({ orgId, token, name: filename }),
    [orgId, token, getFilesItem],
  )

  const handleHistoryLoadMore = React.useCallback(() => {
    if (!activeContact?.nextPage) {
      return
    }
    getContactData(activeContact.contactId, activeContact.nextPage)
  }, [activeContact, getContactData])

  return (
    <>
      {initialized ? (
        <Loading isLoading={loading}>
          <div className={classes.conversationsWrapper}>
            <div className={classes.scrollable}>
              <div className={classes.toolbar}>
                <div className={classes.toolbarSection}></div>
                <Typography variant="h6" className={classes.conversationsTitle}>
                  Recent Conversations
                </Typography>
                <div className={`${classes.toolbarSection} ${classes.toolbarRightSection}`}></div>
              </div>
              <div className={classes.contactList}>
                <Tooltip title="Search conversations using contact fields">
                  <div className={classes.conversationsSearch}>
                    <TextField
                      id="conversations-search-input"
                      size="small"
                      variant="outlined"
                      placeholder="Search conversations"
                      value={searchTerm}
                      onChange={(e) => {
                        if (!changed) {
                          setTextChange(true)
                        }
                        setSearchTerm(e.currentTarget.value)
                      }}
                    />
                  </div>
                </Tooltip>
                <Contacts
                  loadMore={() => getContacts({ nextPage, search: searchTerm })}
                  nextPage={nextPage}
                  contacts={contacts}
                  activeContact={activeContact}
                  setActiveContact={setActiveContactId}
                />
              </div>
            </div>
            {contacts.length > 0 || searchTerm || loading ? (
              <div className={classes.scrollable}>
                <div className={classes.toolbar}>
                  <div className={classes.toolbarSection}>
                    <Typography>
                      {formatPhoneNumber(
                        activeContact.contactId,
                        activeContact.countryCode,
                        activeContact.fullPhoneNumber,
                      )}
                    </Typography>
                  </div>
                  <div>
                    {(activeContact.firstName || activeContact.lastName) && (
                      <Typography variant="h6" className={classes.conversationsTitle}>
                        {activeContact.firstName} {activeContact.lastName}
                      </Typography>
                    )}
                  </div>
                  <div className={`${classes.toolbarSection} ${classes.toolbarRightSection}`}>
                    <div>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setInfoView(!infoView)
                        }}
                      >
                        <Icon>info</Icon>
                      </IconButton>
                    </div>
                  </div>
                </div>
                <div className={classes.conversationsCont}>
                  <History
                    activeContact={activeContact}
                    height={height}
                    readAllContactMessages={readAllContactMessages}
                    scrolled={scrolledHistory}
                    wrapper={wrapper}
                    getFile={handleGetFile}
                    onLoadMore={handleHistoryLoadMore}
                    scrollToBottom={scrollToBottom}
                    setScrolled={setScrolledHistory}
                  />
                  {scrolledHistory && (
                    <div className={classes.scrollDownBtn}>
                      <NewMessageBadge
                        badgeContent={activeContact.unreadCount}
                        invisible={!activeContact.unreadCount}
                        color="primary"
                      >
                        <Fab size="small" color="default" aria-label="scroll-down" onClick={scrollToBottom}>
                          <Icon>expand_more</Icon>
                        </Fab>
                      </NewMessageBadge>
                    </div>
                  )}
                </div>
                <ResizeObserver onResize={(size) => setSendHeight(size.height)}>
                  <Send
                    disabled={false}
                    sendRef={sendRef}
                    personalizationList={personalizationList}
                    smsSenderIds={smsSenderIds}
                    whatsappSenderIds={whatsappSenderIds}
                    sendMessage={sendMessage}
                    token={token}
                    orgId={orgId}
                    contactId={activeContactId}
                    saveFile={saveFile}
                    getFile={handleGetFile}
                    onMicAccessError={onMicAccessError}
                    sendWhatsappMessage={sendWhatsappMessage}
                    setScrolledHistory={setScrolledHistory}
                  />
                </ResizeObserver>
              </div>
            ) : (
              <div className={classes.noDataWrap}>
                <Typography variant="h5">{initialized ? "You don't have any messages yet" : 'Loading...'}</Typography>
              </div>
            )}
            {infoView && (
              <div className={classes.scrollable}>
                <div className={classes.toolbar}>
                  <div className={classes.toolbarSection}></div>
                  <div>
                    {activeContact.firstName && (
                      <Typography variant="h6" className={classes.conversationsTitle}>
                        Contact Info
                      </Typography>
                    )}
                  </div>
                  <div className={`${classes.toolbarSection} ${classes.toolbarRightSection}`}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setInfoView(!infoView)
                      }}
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </div>
                </div>
                <div className={classes.contactInfoCont}>
                  <ContactInfo contactInfo={loading ? emptyContact : contactInfo} history={history} />
                </div>
              </div>
            )}
          </div>
        </Loading>
      ) : (
        <div
          style={{
            padding: '60px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  )
}

const useStyle = makeStyles<{ infoView: boolean }>()((theme, props) => ({
  conversationsWrapper: {
    display: 'grid',
    width: '100%',
    height: 'calc(100vh - 64px)',
    background: theme.palette.background.default,
    gridTemplateRows: '60px auto 60px',
    gridTemplateAreas: `"toolbar toolbar toolbar"
    "lSidebar main rSidebar"
    "lSidebar sendMessage rSidebar"`,
    gridTemplateColumns: props.infoView ? '350px calc(100vw - 700px) 350px' : '350px calc(100vw - 350px)',
  },
  conversationsTitle: {
    textAlign: 'center',
  },
  scrollable: {
    background: theme.palette.background.default,
    gridRowStart: 1,
    gridRowEnd: 'span 3',
    position: 'relative',
    borderRight: `1px solid ${theme.palette.border.main}`,
  },
  toolbar: {
    gridArea: 'toolbar',
    border: 'none',
    backgroundColor: theme.palette.highlight.light,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    position: 'sticky',
    top: 0,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    zIndex: 999,
    minHeight: '51px',
  },
  toolbarSection: {
    flex: '1 1',
    padding: '10px',
    display: 'flex',
  },
  toolbarRightSection: {
    flexDirection: 'row-reverse',
  },
  contactList: {
    gridArea: 'lSidebar',
    overflowY: 'scroll',
    height: 'calc(100vh - 115px)',
  },
  conversationsCont: {
    gridArea: 'main',
    position: 'relative',
  },
  conversationsSearch: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  contactInfoCont: {
    gridArea: 'rSidebar',
    overflowY: 'scroll',
    height: 'calc(100vh - 115px)',
  },
  noDataWrap: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '140px',
  },
  scrollDownBtn: {
    position: 'absolute',
    right: '20px',
    bottom: '20px',
  },
}))
