import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import React, { Component, Fragment } from 'react'
import DeleteDialog from '../../../../component/atom/delete-dialog'
import { Autocomplete, Input, Radio } from '../../../../component/atom/form'
import Loading from '../../../../component/guard/loading'
import IconText from '../../../../component/layout/icon-text'
import { financialNumber } from '../../../../service/util/format'
import validate, { hasEmailError, hasRequiredError } from '../../../../service/validate'
import StripeElementWrap from './util/stripe-element-wrapper'

const paymentMin = 10
const paymentMax = 2000

const checkError = (value) => {
  if (value < paymentMin) {
    return `Amount is less than minimum amount of $${financialNumber(paymentMin)}`
  }
  if (value > paymentMax) {
    return `Amount is more than maximum amount of $${financialNumber(paymentMax)}`
  }
  return ''
}

class PaymentForm extends Component {
  state = {
    addNewCard: this.props.cards.length === 0,
    cardSelectedId: this.props.cards.length > 0 ? this.props.cards[0].value : false,
    form: {
      name: {
        value: this.props.user.name,
        error: '',
      },
      email: {
        value: this.props.user.email,
        error: '',
      },
      card: {
        changed: false,
        empty: true,
        error: '',
      },
      expiry: {
        changed: false,
        empty: true,
        error: '',
      },
      ccv: {
        changed: false,
        empty: true,
        error: '',
      },
      amount: {
        value: this.props.amount || paymentMin,
      },
      line1: {
        value: '',
        error: '',
      },
      line2: {
        value: '',
        error: '',
      },
      city: {
        value: '',
        error: '',
      },
      country: {
        value: '',
        error: '',
      },
      postcode: {
        value: '',
        error: '',
      },
      chargeConfirm: false,
    },
    autoRechargeModal: false,
    warning: true,
  }

  static getDerivedStateFromProps(newProps) {
    return {
      addNewCard: newProps.cards.length === 0,
      cardSelectedId: newProps.cards.length === 0 || newProps.cards[0].value,
    }
  }

  saveCard() {
    const { token, orgId, saveCard, stripe, elements } = this.props

    const { name, email, line1, line2, city, country, postcode } = this.state.form

    const card = elements.getElement(CardNumberElement)

    return saveCard({
      stripe,
      token,
      orgId,
      card,
      item: {
        name: name.value,
        email: email.value,
        address: {
          line1: line1.value,
          line2: line2.value,
          city: city.value,
          country: country.value,
          postal_code: postcode.value,
        },
      },
    }).then((res) => {
      if (!(res instanceof Error)) {
        this.setState((prevState) => ({
          form: {
            ...prevState.form,
            card: {
              changed: false,
              empty: true,
              error: '',
            },
            expiry: {
              changed: false,
              empty: true,
              error: '',
            },
            ccv: {
              changed: false,
              empty: true,
              error: '',
            },
          },
        }))
      }
    })
  }

  chargeCard() {
    const id = this.state.cardSelectedId
    const amount = this.state.form.amount.value
    const { token, orgId, chargeCard } = this.props
    this.setState({
      chargeConfirm: false,
    })
    return chargeCard({
      token,
      orgId,
      itemId: id,
      amount,
    })
  }

  deleteCard(id) {
    const { token, orgId, deleteCard } = this.props

    return deleteCard({
      token,
      orgId,
      itemId: id,
    })
  }

  validate() {
    const { amount, name, email, card, expiry, ccv, line1, city, country, postcode } = this.state.form

    return (
      amount.value &&
      name.value &&
      email.value &&
      line1.value &&
      city.value &&
      country.value &&
      postcode.value &&
      !card.empty &&
      !expiry.empty &&
      !ccv.empty &&
      !card.error &&
      !expiry.error &&
      !ccv.error &&
      !line1.error &&
      !city.error &&
      !country.error &&
      !postcode.error
    )
  }

  render() {
    const { initialized, loading, error, cards, countries = [] } = this.props

    return (
      <Loading isLoading={loading} error={error}>
        <DeleteDialog
          text={`Are you sure you want to charge your card with $${this.state.form.amount.value}?`}
          isOpen={this.state.chargeConfirm}
          onClose={() =>
            this.setState({
              chargeConfirm: false,
            })
          }
          deleteButtonText="Confirm"
          cancelButtonText="Cancel"
          icon="credit_card"
          onConfirm={() => this.chargeCard()}
          title="Confirm charge"
        />
        <Typography variant="h5" color="primary">
          <strong>{this.state.addNewCard ? 'Add Credit Card' : 'Add Funds'}</strong>
        </Typography>
        <IconText>
          <Icon
            fontSize="small"
            style={{
              color: '#5BC236',
            }}
          >
            lock_outline
          </Icon>
          <Typography variant="caption">{"This is a secure 256-bit SSL Encrypted payment. You're safe."}</Typography>
        </IconText>
        <Divider style={{ margin: '1rem -24px' }} />
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault()
            if (this.state.addNewCard) {
              this.saveCard()
            } else {
              this.setState({
                chargeConfirm: true,
              })
            }
          }}
        >
          {initialized && !this.state.addNewCard && (
            <Fragment>
              <Typography color="textSecondary" variant="caption">
                Minimum amount is ${financialNumber(paymentMin)} and Maximum amount is ${financialNumber(paymentMax)}
              </Typography>
              <Input
                name="amount"
                label="Amount"
                type="number"
                value={this.state.form.amount.value}
                error={this.state.form.amount.error}
                info={this.state.form.amount.error}
                inputProps={{
                  min: this.props.minimum || paymentMin,
                  max: paymentMax,
                  step: 1,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$&nbsp;</InputAdornment>,
                }}
                onChange={({ value }) => {
                  this.setState({
                    form: {
                      ...this.state.form,
                      amount: {
                        // ugly code to remove leading zeros, and disable 2+ decimals
                        value:
                          value && (Math.round(parseFloat(value.toString().replace(/^0+/, '')) * 100) / 100).toString(),
                        error: checkError(value),
                      },
                    },
                  })
                }}
                onBlur={({ value }) => {
                  this.setState({
                    form: {
                      ...this.state.form,
                      amount: {
                        // disable values smaller than 1
                        value: Math.max(this.props.minimum || 1, Math.round(value * 100) / 100),
                        error: checkError(value),
                      },
                    },
                  })
                }}
              />
              <Radio
                name="cards"
                label="Saved cards"
                editable={!loading && cards.length > 0}
                value={this.state.cardSelectedId}
                values={cards}
                onChange={({ value }) => {
                  this.setState({
                    cardSelectedId: value,
                  })
                }}
              />
            </Fragment>
          )}
          {initialized && this.state.addNewCard && (
            <React.Fragment>
              <div>
                <Input
                  name="name"
                  label="Card holder's name"
                  value={this.state.form.name.value}
                  error={this.state.form.name.error}
                  onChange={({ value }) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        name: {
                          value,
                          error: '',
                        },
                      },
                    })
                  }}
                  onBlur={({ value }) => {
                    const validationError = validate(hasRequiredError)(value)
                    if (error !== '') {
                      this.setState({
                        form: {
                          ...this.state.form,
                          name: {
                            value,
                            error: validationError,
                          },
                        },
                      })
                    }
                  }}
                />
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  value={this.state.form.email.value}
                  error={this.state.form.email.error}
                  onChange={({ value }) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        email: {
                          value,
                          error: '',
                        },
                      },
                    })
                  }}
                  onBlur={({ value }) => {
                    const validationError = validate(hasRequiredError, hasEmailError)(value)
                    if (error !== '') {
                      this.setState({
                        form: {
                          ...this.state.form,
                          email: {
                            value,
                            error: validationError,
                          },
                        },
                      })
                    }
                  }}
                />
                <StripeElementWrap
                  name="card"
                  label="Card number"
                  error={this.state.form.card.error}
                  empty={this.state.form.card.empty}
                  focused={this.state.form.card.focused}
                  component={CardNumberElement}
                  onChange={({ empty: inputEmpty, error: inputError, focused }) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        card: {
                          changed: true,
                          empty: inputEmpty,
                          error: inputError,
                          focused,
                        },
                      },
                    })
                  }}
                />

                <StripeElementWrap
                  name="expiry"
                  label="Expiry (MM/YY)"
                  error={this.state.form.expiry.error}
                  empty={this.state.form.expiry.empty}
                  focused={this.state.form.expiry.focused}
                  component={CardExpiryElement}
                  onChange={({ empty: inputEmpty, error: inputError, focused }) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        expiry: {
                          changed: true,
                          empty: inputEmpty,
                          error: inputError,
                          focused,
                        },
                      },
                    })
                  }}
                />

                <StripeElementWrap
                  name="ccv"
                  label="CCV (***)"
                  error={this.state.form.ccv.error}
                  empty={this.state.form.ccv.empty}
                  focused={this.state.form.ccv.focused}
                  component={CardCvcElement}
                  onChange={({ empty: inputEmpty, error: inputError, focused }) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        ccv: {
                          changed: true,
                          empty: inputEmpty,
                          error: inputError,
                          focused,
                        },
                      },
                    })
                  }}
                />
              </div>

              <Divider style={{ margin: '1rem -24px' }} />

              <div>
                <Typography>Address:</Typography>
                <Input
                  name="line1"
                  label="Address 1"
                  value={this.state.form.line1.value}
                  error={this.state.form.line1.error}
                  onChange={({ value }) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        line1: {
                          changed: true,
                          value,
                          error: '',
                        },
                      },
                    })
                  }}
                  onBlur={({ value }) => {
                    const validationError = validate(hasRequiredError)(value)
                    if (error !== '') {
                      this.setState({
                        form: {
                          ...this.state.form,
                          line1: {
                            value,
                            error: validationError,
                          },
                        },
                      })
                    }
                  }}
                />
                <Input
                  name="line2"
                  label="Address 2"
                  value={this.state.form.line2.value}
                  error={this.state.form.line2.error}
                  onChange={({ value }) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        line2: {
                          changed: true,
                          value,
                          error: '',
                        },
                      },
                    })
                  }}
                />
                <Input
                  name="city"
                  label="City"
                  value={this.state.form.city.value}
                  error={this.state.form.city.error}
                  onChange={({ value }) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        city: {
                          changed: true,
                          value,
                          error: '',
                        },
                      },
                    })
                  }}
                  onBlur={({ value }) => {
                    const validationError = validate(hasRequiredError)(value)
                    if (error !== '') {
                      this.setState({
                        form: {
                          ...this.state.form,
                          city: {
                            value,
                            error: validationError,
                          },
                        },
                      })
                    }
                  }}
                />
                <Autocomplete
                  name="country"
                  label="Country"
                  value={this.state.form.country.value}
                  values={countries}
                  error={this.state.form.country.error}
                  onChange={({ value }) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        country: {
                          changed: true,
                          value,
                          error: '',
                        },
                      },
                    })
                  }}
                  onBlur={({ value }) => {
                    const validationError = validate(hasRequiredError)(value)
                    if (error !== '') {
                      this.setState({
                        form: {
                          ...this.state.form,
                          country: {
                            value,
                            error: validationError,
                          },
                        },
                      })
                    }
                  }}
                />
                <Input
                  name="postcode"
                  label="Zip / Postal code"
                  value={this.state.form.postcode.value}
                  error={this.state.form.postcode.error}
                  onChange={({ value }) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        postcode: {
                          changed: true,
                          value,
                          error: '',
                        },
                      },
                    })
                  }}
                  onBlur={({ value }) => {
                    const validationError = validate(hasRequiredError)(value)
                    if (error !== '') {
                      this.setState({
                        form: {
                          ...this.state.form,
                          postcode: {
                            value,
                            error: validationError,
                          },
                        },
                      })
                    }
                  }}
                />
              </div>
            </React.Fragment>
          )}
          {initialized && <Divider style={{ margin: '1rem -24px' }} />}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '2rem',
              alignSelf: 'flex-end',
            }}
          >
            {initialized && this.state.addNewCard && (
              <Button color="primary" variant="contained" disabled={!this.validate()} type="submit">
                Add Card
              </Button>
            )}
            {initialized && !this.state.addNewCard && (
              <Fragment>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={cards.length === 0}
                  style={{ marginRight: '1rem' }}
                  onClick={() => this.deleteCard(this.state.cardSelectedId)}
                >
                  Delete card
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={!this.state.cardSelectedId || !this.state.form.amount.value || this.state.form.amount.error}
                >
                  Pay
                </Button>
              </Fragment>
            )}
          </div>
        </form>
      </Loading>
    )
  }
}

export default PaymentForm
