import { compose, keys, path, pathOr, reduce } from 'ramda'

export const getFormValues = (target, values = []) => {
  const data = new FormData(target)
  return values.reduce(
    (acc, key) => ({
      ...acc,
      [key]: data.get(key),
    }),
    {},
  )
}

export const toDerivedFields =
  (data = {}, errors = {}) =>
  (acc, name) => ({
    ...acc,
    [name]: {
      value: pathOr('', [name], data),
      error: pathOr('', [name], errors),
    },
  })

export const toValueFields = (data) => (acc, name) => ({
  ...acc,
  [name]: path([name, 'value'], data),
})

export const toValidationError = (data) => (acc, name) => acc || pathOr(acc, [name, 'error'], data)

export const getFields =
  (reducer, defaultValue = {}) =>
  (data, errors) =>
    compose(reduce(reducer(data, errors), defaultValue), keys)(data)

const addValidationError =
  (validation) =>
  ({ hasError, fields }, name) => {
    const error = validation[name] ? validation[name](fields[name].value, fields) : fields[name].error
    return {
      hasError: !!error || hasError,
      fields: {
        ...fields,
        [name]: {
          ...fields[name],
          error,
        },
      },
    }
  }

export const validateFields = (validation = {}, stateFields = {}) =>
  reduce(addValidationError(validation), {
    hasError: false,
    fields: stateFields,
  })(keys(stateFields))
