import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import { isNil } from 'ramda'
import React, { forwardRef, useCallback, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

type Option = {
  label: string
  value: string
}

type Props = {
  name: string
  options: Option[]
  value: string
  onChange: (value: string) => void
}

const SingleChoice = forwardRef<HTMLDivElement, Props>(({ name, options, value, onChange }, ref) => {
  const { classes } = useStyles()

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    },
    [onChange],
  )

  useEffect(() => {
    if (isNil(value) && options.length) {
      onChange(options[0].value)
    }
  }, [options, value, onChange])

  return (
    <div className={classes.container} data-testid={`single-choice-${name}`} ref={ref}>
      {options &&
        options.map((option) => (
          <MenuItem className={classes.menuItem} disableGutters key={option.value} value={option.value}>
            <FormControlLabel
              className={classes.label}
              control={
                <Radio checked={value === option.value} name={name} value={option.value} onChange={handleChange} />
              }
              label={option.label}
            />
          </MenuItem>
        ))}
    </div>
  )
})

const useStyles = makeStyles()({
  container: {},
  label: {
    margin: 0,
    padding: '6px 16px 6px 5px',
    width: '100%',
  },
  menuItem: {
    padding: 0,
  },
})

export default SingleChoice
