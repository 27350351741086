import { CampaignTemplateType } from '@/app/module/campaigns/types'

export function getDefaultMessageType(campaignType: CampaignTemplateType, template?: string) {
  switch (campaignType) {
    case CampaignTemplateType.SMSBlast:
      return 'sms-m'
    case CampaignTemplateType.VoiceSurvey: {
      if (template === 'voiceblast') {
        return 'voice-m'
      }
      return null
    }
    case CampaignTemplateType.WhatsAppSurvey:
      return null
    case CampaignTemplateType.VoiceBlast:
      return 'voice-m'
    case CampaignTemplateType.SMSSurvey:
      return null
    case CampaignTemplateType.Drip: {
      if (template === 'topup') {
        return 'topup-q'
      }
      return null
    }
    case CampaignTemplateType.CATI:
      return null
    case CampaignTemplateType.Reminder:
      return null
    default:
      throw new Error(`Unknown campaign type: ${campaignType}`)
  }
}
