import React from 'react'
import { CountryTimezone, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'
import { DRAGTYPE } from '@/app/module/campaigns/definitions'
import {
  CampaignQuery,
  ImageProps,
  Language,
  LocalAction,
  LocalWhatsAppButtonItem,
  PersonalizationType,
  SnippetType,
  VoiceProps,
} from '@/app/module/campaigns/types'
import CampaignContentQuestionBox from './question'
import WhatsappButtonChoice from './question/choice/whatsapp-button-choice'

type Props = {
  actionsValues: SelectOption<string>[]
  campaignIds: SelectOption<number>[]
  countriesTimezones: CountryTimezone[]
  countryCode: string
  defaultLanguage?: string
  defaultSenderId?: SelectOption<string>
  imageId?: string
  index: number
  isCloseDisabled: boolean
  label?: string
  languages: Language[]
  orgId: number
  personalizationList: PersonalizationType
  senderIds: SelectOption<string>[]
  timezone: string
  timezones: TimezoneWithCountryCodeType
  token: string
  voiceId?: string
  value: LocalWhatsAppButtonItem

  getCampaigns: (payload: { orgId: number; token: string; query: CampaignQuery }) => Promise<void>
  getImageProps: (qid: string) => ImageProps
  getVoiceProps: (qid: string) => VoiceProps
  onChange: (value: LocalWhatsAppButtonItem) => void
  onClose: () => void
  saveSnippet: (payload: {
    orgId: number
    token: string
    item: Pick<SnippetType, 'category' | 'snippet'>
  }) => Promise<SnippetType>
}

const WhatsappButtonItem: React.FC<Props> = ({ onChange, ...props }) => (
  <CampaignContentQuestionBox
    {...props}
    ChoiceTag={WhatsappButtonChoice}
    actionsList={props.value.actions || []}
    actionsValues={props.actionsValues}
    choiceLabel="Button"
    choiceList={props.value}
    dragContext={`${DRAGTYPE}-choice-${props.index}`}
    label={props.value.label || ''}
    question={true}
    changeHandler={({
      actionsList,
      choiceList,
      label,
    }: {
      actionsList?: LocalAction[]
      choiceList?: LocalWhatsAppButtonItem
      label?: string
    }) => {
      const newValue = {
        ...props.value,
        ...choiceList,
      }

      if (actionsList) {
        newValue.actions = actionsList
      }

      if (label) {
        newValue.label = label
      }

      return onChange(newValue)
    }}
  />
)

export default WhatsappButtonItem
