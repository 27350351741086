import Layout from '../../component/layout/page'
import { PAGE } from '../../definitions'
import { AuthGuard, AuthHeader } from '../../module/auth'
import Dashboard from '../../module/dashboard'
import Notifications from '../../module/notifications'

export default function MessagesPage({ name, routes, match }) {
  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout title="Dashboard - engageSPARK">
        <AuthHeader currentPage={PAGE.DASHBOARD.INDEX} />
        <Dashboard />
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}
