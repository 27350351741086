import React from 'react'
import { SubscriptionStatus } from '@/app/module/campaigns/types'
import { cannot } from '@/app/helpers'
import ActionButton from './action-button'
import { ConfirmationDialogState, DelaySubscriptionDialogState } from './helpers'
import ActionMenu, { ActionMenuOption } from './action-menu'

type Props = {
  actionDisabled: boolean
  activeGroupId: number
  activeId: number
  contactId: number
  root: string
  subStatus: SubscriptionStatus

  onResume: (payload: { subId: number }) => Promise<void>
  onUnsubscribe: (payload: { includedContactIds?: number[]; includedGroupIds?: number[] }) => Promise<void>
  setActiveGroupId: (payload: number) => void
  setConfirmationDialog: (payload: ConfirmationDialogState) => void
  setDelaySubscriptionDialog: (payload: DelaySubscriptionDialogState) => void
}

const RowActions: React.FC<Props> = ({
  actionDisabled,
  activeGroupId,
  activeId,
  contactId,
  root,
  subStatus,
  onResume,
  onUnsubscribe,
  setActiveGroupId,
  setConfirmationDialog,
  setDelaySubscriptionDialog,
}) => {
  const [openManageSubscription, setOpenManageSubscription] = React.useState(false)

  if (root === 'contacts') {
    if (subStatus !== 'paused') {
      return (
        <ActionButton
          color={activeId ? 'inherit' : 'primary'}
          disabled={actionDisabled}
          icon={getButtonIcon(subStatus)}
          label={getButtonLabel(subStatus)}
          onClick={() => {
            setOpenManageSubscription(false)
            if (subStatus === 'subscribed') {
              onUnsubscribe({
                includedContactIds: [contactId],
              })
            } else {
              setDelaySubscriptionDialog({
                type: 'subscribe',
                isOpen: true,
                group: false,
                id: contactId,
              })
            }
          }}
        />
      )
    }

    const options: ActionMenuOption[] = [
      {
        disabled: actionDisabled,
        icon: 'play_circle_filled',
        label: 'Resume',
        onSelect: () => {
          setOpenManageSubscription(false)
          onResume({ subId: activeId })
        },
        value: 'resume',
      },
      {
        disabled: actionDisabled,
        icon: 'remove_circle',
        label: 'Unsubscribe',
        onSelect: () => {
          setOpenManageSubscription(false)
          onUnsubscribe({
            includedContactIds: [contactId],
          })
        },
        value: 'unsubscribe',
      },
    ]

    return (
      <ActionMenu
        buttonLabel="Manage subscription"
        disabled={actionDisabled}
        open={openManageSubscription}
        options={options}
        onClose={() => setOpenManageSubscription(false)}
        onOpen={() => setOpenManageSubscription(true)}
      />
    )
  }

  const options: ActionMenuOption[] = [
    {
      disabled: actionDisabled,
      icon: getButtonIcon(subStatus),
      label: getButtonLabel(subStatus),
      onSelect: () => {
        setOpenManageSubscription(false)
        setDelaySubscriptionDialog({
          type: 'subscribe',
          isOpen: true,
          group: true,
        })
      },
      value: 'subscribe',
    },
    {
      disabled: actionDisabled,
      icon: 'remove_circle',
      label: 'Unsubscribe',
      onSelect: () => {
        setOpenManageSubscription(false)
        setConfirmationDialog({
          type: 'unsubscribe',
          isOpen: true,
          id: activeGroupId,
        })
      },
      value: 'unsubscribe',
    },
  ]

  return (
    <ActionMenu
      buttonLabel="Manage subscription"
      disabled={actionDisabled}
      open={openManageSubscription}
      options={options}
      onClose={() => setOpenManageSubscription(false)}
      onOpen={() => {
        setOpenManageSubscription(true)
        setActiveGroupId(contactId)
      }}
    />
  )
}

const getButtonIcon = (subStatus: SubscriptionStatus) => {
  switch (subStatus) {
    case 'done':
      return 'loop'
    case 'non-subscribed':
      return 'input'
    case 'paused':
      return 'play_circle_filled'
    case 'subscribed':
      return 'remove_circle'

    default:
      return cannot(subStatus)
  }
}

const getButtonLabel = (subStatus: SubscriptionStatus) => {
  switch (subStatus) {
    case 'done':
      return 'Re-subscribe'
    case 'non-subscribed':
      return 'Subscribe'
    case 'paused':
      return 'Resume'
    case 'subscribed':
      return 'Unsubscribe'

    default:
      return cannot(subStatus)
  }
}

export default RowActions
