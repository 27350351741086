import { FormControlLabel, styled, Switch } from '@mui/material'
import React from 'react'
import { SearchableSelect } from '@/app/component/atom/form'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { SelectOption } from '@/app/types'
import { concatAll } from '@/app/module/campaigns/component/item/steps/content/types/multimessage/content'
import PzInput from '@/app/component/atom/pz-input'
import { removeCurlyBrackets } from '@/app/module/utils/personalization'

type GroupedOption = {
  title: string
  data: SelectOption<string>[]
}

type Props = {
  error?: boolean
  field: string
  fieldError?: boolean
  options: GroupedOption[]
  personalizationList: PersonalizationType

  onChange: (field: string) => void
  onSelect: (field: string) => void
}

const ConditionField: React.FC<Props> = ({
  error,
  field,
  fieldError,
  options,
  personalizationList,
  onChange,
  onSelect,
}) => {
  const [advanced, setAdvanced] = React.useState(isAdvanced(removeCurlyBrackets(field), personalizationList))

  const renderInput = () => {
    if (advanced) {
      return (
        <PzInput
          label="Enter value"
          name="condition-field-advanced"
          personalizationList={personalizationList}
          value={field}
          onChange={onChange}
        />
      )
    }

    return (
      <SearchableSelect
        id="conditions-field-select"
        error={!field && error}
        formControlProps={selectFormControlProps}
        grouped
        helperText={fieldError ? 'You must select a value' : ''}
        hideRemoveSelection
        label={field ? ' ' : 'Select field'}
        options={options}
        showAll
        value={field}
        keyPropFn={keyPropFn}
        onChange={onSelect}
        valuePropFn={valuePropFn}
      />
    )
  }

  const handleToggle = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target
      if (field && !checked && isAdvanced(removeCurlyBrackets(field), personalizationList)) {
        // if advanced is getting disabled and the field is not a single personalization, reset field
        onChange('')
      }
      setAdvanced(checked)
    },
    [field, personalizationList, onChange],
  )

  return (
    <Container>
      {renderInput()}
      <FormControlLabel
        control={<Switch checked={!!advanced} onChange={handleToggle} />}
        label="Advanced"
        name="condition-field-advanced-toggle"
        sx={{
          width: 'fit-content',
        }}
      />
    </Container>
  )
}

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}))

const selectFormControlProps = {
  margin: 'none',
  style: {
    width: '100%',
  },
}

const isAdvanced = (field: string, personalizationList: PersonalizationType) => {
  if (!field) {
    return false
  }
  const allPz = concatAll(Object.values(personalizationList))
  return !allPz.some((pz) => pz.value === field)
}
const keyPropFn = (option: SelectOption<string>) => option.value
const valuePropFn = (option: SelectOption<string>) => option.label

export default React.memo(ConditionField)
