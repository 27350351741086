import React from 'react'
import { styled } from '@mui/material/styles'

const Container = styled('div')({
  position: 'sticky',
  bottom: '5px',
  width: '100%',
  zIndex: 9,
})

const Content = styled('div')({
  display: 'flex',
  padding: '1rem 2rem',
  width: '100%',
  bottom: '0px',
  margin: 'auto',
  background: 'white',
  boxShadow: '0px 0px 0px 1px rgb(224 224 224)',
  borderRadius: '4px',
})

type Props = {
  children: React.ReactNode
}

export default function FloatingSaveBar({ children }: Props) {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  )
}
