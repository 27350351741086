import Layout from '../../component/layout/page'
import { PAGE } from '../../definitions'
import { AuthGuard, AuthHeader } from '../../module/auth'
import Notifications from '../../module/notifications'
import Plans from '../../module/plans'

export default function MessagesPage({ name, routes, match }) {
  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout title="Plans - engageSPARK">
        <AuthHeader currentPage={PAGE.PLANS.INDEX} />
        <Plans />
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}
