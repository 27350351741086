import RecaptchaLayout from '@/app/component/layout/recaptcha-page'
import { AuthGuard, AuthHeader, CheckInvitation } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'

export default function CheckInvitationPage(props: any) {
  const { name, match, history, location } = props
  const params = new URLSearchParams(location.search)
  const id = params.get('id')
  return (
    <AuthGuard url={match.url} path={match.path}>
      <RecaptchaLayout title="Invitation - engageSPARK">
        <AuthHeader currentPage={name} />
        <CheckInvitation invitationToken={id} history={history} url={match.url} />
        <Notifications />
      </RecaptchaLayout>
    </AuthGuard>
  )
}
