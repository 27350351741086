import { FixMeLater } from '@/config/types'

export type callProps = {
  windowWidth: number
  id: string
  direction: string
  created: string
  callerId: string
  contactId: string
  engagementFlowId: number
  engagementRunId: number
  engagementPartId: number
  done: boolean
  isMissedCall: boolean
  start: string
  ringing: string
  answer: string
  hangup: string
  chargedHuman: string
  chargedMicro: number
  chargedPriceUnits: number
  pricePerUnit: number
  chargedSystemFee: number
  finalError: string
  finalErrorTime: string
  firstName: string
  lastName: string
  fullPhonenumber: number
  duration: number
  history: FixMeLater
  viewContact: FixMeLater
  isLoadingRow: boolean
}

export type tabElement = {
  downloadData: FixMeLater
  getData: FixMeLater
  reloadData: FixMeLater
  data: []
}

export type tabMap = {
  [key: string]: tabElement
}

export type logProps = {
  downloadMessages: FixMeLater
  downloadCalls: FixMeLater
  downloadWhatsapp: FixMeLater
  downloadTopups: FixMeLater
  getMessages: FixMeLater
  getCalls: FixMeLater
  getWhatsapp: FixMeLater
  getTopups: FixMeLater
  reloadMessages: FixMeLater
  reloadCalls: FixMeLater
  reloadWhatsapp: FixMeLater
  reloadTopups: FixMeLater
  messages: []
  calls: []
  whatsapp: []
  topups: []
  tab: 'messages' | 'calls' | 'whatsapp' | 'topups'
  history: FixMeLater
  setSendMessage: FixMeLater
  endCursor: string
  hasNextPage: boolean
  token: string
  orgId: number
  status: string
  type: string
  to: string
  from: string
  pageSize: number
  search: string
  getSenderIdList: FixMeLater
  senderIds: []
  contactIds: []
  msgSenderId: string
  personalizationList: []
  sendMessage: FixMeLater
  messageFilters: FixMeLater
  callFilters: FixMeLater
  navigateLogs: FixMeLater
  error: string
  recipients: []
  getFilesItem: FixMeLater
  getCustomFields: FixMeLater
  sendWhatsappMessage: FixMeLater
  whatsappSenderIds: []
  setSendWhatsappMessage: FixMeLater
  saveWhatsappFile: FixMeLater
  onMicAccessError: FixMeLater
  clearFilters: FixMeLater
  timezone: string
}

const fmtr = new Intl.NumberFormat('us-us', {
  style: 'decimal',
  useGrouping: false,
  minimumFractionDigits: 3,
  maximumFractionDigits: 6,
})

export const formatCost = (micro: number): string => `$ ${fmtr.format(micro / 1000000)}`
