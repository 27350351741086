import { WhatsAppMessage, WhatsAppQuestion } from '@/app/module/campaigns/types'

export const getRegisterTemplateDisabledReason = (item: WhatsAppMessage | WhatsAppQuestion) => {
  if (item.message.text && item.message.video) {
    return 'When both video and caption are present, the template must be registered with WhatsApp in order to send the message.'
  }

  if (item.hasQuestion && item.onList) {
    return 'WhatsApp List messages cannot be registered with WhatsApp. Please use a different response type.'
  }

  return ''
}
