import { Link, Button, Typography, styled } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import React from 'react'

type Props = {
  isLoading: boolean

  onCreate: (date: string) => Promise<boolean>
}

const MIN_DATE = moment('2022-01-01T00:00:00Z')

const SmsReport: React.FC<Props> = ({ isLoading, onCreate }) => {
  const [date, setDate] = React.useState<moment.Moment>(moment())
  const [lastSubmitted, setLastSubmitted] = React.useState<moment.Moment | null>(null)

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault()

    if (!date) {
      return
    }

    const success = await onCreate(date.format('YYYY-MM'))
    if (success) {
      setLastSubmitted(date)
    }
  }

  const isBeforeMinDate = date && date.isBefore(MIN_DATE)
  const isAfterNow = date && date.isAfter(moment())
  const isSameAsLastSubmitted = !!lastSubmitted && lastSubmitted.isSame(date)

  const disabled = isLoading || !date || isBeforeMinDate || isAfterNow || isSameAsLastSubmitted

  return (
    <Container>
      <Typography variant="h6">SMS Deliverability Report</Typography>
      <Typography>
        Create a report showing daily SMS delivery rates per country for any month. Access the report in{' '}
        <Link href="/exports">Exports</Link>
      </Typography>
      <Divider />
      <FormContainer onSubmit={handleSubmit}>
        <DesktopDatePicker
          className="sms-report-date-picker"
          disableFuture
          format="MM/YYYY"
          label="Month and Year"
          minDate={MIN_DATE}
          value={date}
          views={['year', 'month']}
          onChange={(v) => v && setDate(v)}
        />
        <Button type="submit" variant="contained" color="primary" disabled={disabled}>
          Create Report
        </Button>
      </FormContainer>
    </Container>
  )
}

const Container = styled('div')({
  padding: '15px',
})

const Divider = styled('div')({
  height: '20px',
  width: '100%',
})

const FormContainer = styled('form')({
  alignItems: 'center',
  display: 'flex',
  gap: '20px',
})

export default SmsReport
