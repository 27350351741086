import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { FC, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import FormLayout from '@/app/component/layout/form'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import validate, { hasRequiredError } from '@/app/service/validate'
import { Balance, Organization } from '@/app/types'

type Props = {
  activeOrg: Organization
  balance: Balance
  loading: boolean
  organizations: Organization[]

  onClose: () => void
  onSubmit: (data: { amountUSDcent: number; toOrgId: number }) => void
}

const TransferFundsModal: FC<Props> = ({ activeOrg, balance, loading, organizations, onClose, onSubmit }) => {
  const orgOptions = useMemo(
    () =>
      organizations
        .filter((org) => org.orgId !== activeOrg.orgId)
        .map((org) => ({ label: `${org.name} (${org.orgId})`, value: `${org.orgId}` })),
    [activeOrg, organizations],
  )
  const { classes } = useStyles()

  return (
    <CustomDialog aria-labelledby="transfer-funds-title" fullWidth={true} open={true} onClose={onClose}>
      <DialogTitle id="transfer-funds-title">
        <span>Transfer funds to another organization</span>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              margin: 'auto',
              justifyContent: 'center',
            }}
          >
            <FormLayout
              id="transfer-funds-form"
              config={{
                toOrgId: {
                  type: 'select',
                  label: 'To organization',
                  values: orgOptions,
                },
                amount: {
                  type: 'number',
                  editable: true,
                  label: 'Amount',
                  InputProps: {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  },
                },
              }}
              headers={[
                {
                  fields: ['toOrgId'],
                },
                {
                  fields: ['amount'],
                },
              ]}
              loading={loading}
              submitLabel="Transfer funds"
              validation={{
                toOrgId: validate(hasRequiredError),
                amount: validate(hasRequiredError, (value: number | string) => {
                  const amount = Number(value)
                  if (amount <= 0) {
                    return 'Amount must be greater than 0'
                  }

                  if (amount > balance.balance) {
                    return 'Insufficient funds'
                  }

                  return ''
                }),
              }}
              values={{ amount: 0, toOrgId: orgOptions?.[0]?.value || '' }}
              onSubmit={({ item }: { item: { amount: string; toOrgId: string } }) =>
                onSubmit({
                  amountUSDcent: Math.floor(Number(item.amount) * 100), // since it's cents
                  toOrgId: Number(item.toOrgId),
                })
              }
            ></FormLayout>
          </div>
        </div>
      </DialogContent>
    </CustomDialog>
  )
}

const useStyles = makeStyles()(() => ({
  closeButton: {
    float: 'right',
  },
}))

export default TransferFundsModal
