import { Condition, CountryTimezone } from '@/app/types'
import moment, { Moment } from 'moment-timezone'
import { addIndex, find, has, isEmpty, keys, map, pathOr, propEq, reduce, replace, split, test, trim } from 'ramda'
import { messageTypeTextMap } from './definitions'
import {
  CampaignDefinition,
  CampaignType,
  ChoiceListType,
  ContentProperty,
  dayOfWeekMap,
  DelayState,
  EstimateResponseType,
  PartFamily,
  ReminderMessage,
  TimezoneType,
  Message as TMessage,
} from './types'

export const CAMPAIGN_NO_CONTENT = 'The campaign cannot be launched without content'
export const SMSBLAST_NO_CONTENT_TEXT = 'The SMS text message is empty'
export const NO_SENDER_ID_TEXT = 'You need to set all required Sender & Caller IDs'

export const mapIndexed = addIndex(map)

export const reduceIndexed = addIndex(reduce) as <ACC, CURR>(
  arg: (arg0: ACC, arg1: CURR, arg2: number) => ACC,
  arg1: ACC,
  arg2: CURR[],
) => ACC

const getSelectedTimezone = (timezone: string, currentTimezones: CountryTimezone[]): TimezoneType => {
  const foundTimezone = reduce<CountryTimezone, CountryTimezone | undefined>(
    (accum, elem) => {
      if (!accum) {
        const { value } = elem
        if (value === timezone) {
          return elem
        }
      }
      return accum
    },
    undefined,
    currentTimezones,
  )
  if (foundTimezone) {
    return foundTimezone
  }
  return {
    label: '',
  }
}

export const capitalizeFirstLetter = (label: string): string => label.charAt(0).toUpperCase() + label.slice(1)

export const capitalizeTheFirstLetterOfEachWord = (words: string) => {
  const separateWord = words.toLowerCase().split(' ')
  const capitalizedWord = map((word: string) => capitalizeFirstLetter(word), separateWord)
  return capitalizedWord.join(' ')
}

const formatTime = (value: number, unit: 'day' | 'hour' | 'minute', allowZero = false): string => {
  if (!allowZero && !value) {
    return ''
  }

  const plural = value === 1 ? '' : 's'
  return `${value} ${unit}${plural}`
}

/**
 *
 * @param earlier The earlier date
 * @param later The later date
 * @returns The time difference between the two dates in days, hours and minutes string format
 */
export const calculateTimeDifference = (earlier: Moment, later: Moment): string => {
  const duration = moment.duration(later.diff(earlier))

  const arr = [formatTime(duration.days(), 'day'), formatTime(duration.hours(), 'hour')].filter(Boolean)
  const minuteText = formatTime(duration.minutes(), 'minute', true)

  return ` (In ${arr.length ? `${arr.join(', ')} and ${minuteText}` : minuteText})`
}

export const estimateSubscription = (currentState: DelayState, now: Date): EstimateResponseType => {
  const { unit, time, value, daysOfWeek, timezone, timezones: currentTimezones } = currentState
  if (unit === 'immediate') {
    return {
      message: 'The contact(s) will be subscribed right away',
      protip: '',
    }
  }
  const selectedTimezone: TimezoneType = getSelectedTimezone(timezone, currentTimezones)
  const message: string = 'If you submit this subscription now, the contact(s) will be subscribed to the campaign on'
  const newDate = moment.tz(now, timezone)
  const currentDate = moment.tz(now, timezone)

  if (unit === 'at') {
    const specificDate = moment(currentState.at)
      .tz(currentState.timezone)
      .format('dddd, MMMM Do, YYYY [at] h:mmA [GMT]ZZ')
    return {
      message: `${message} ${specificDate}`,
      protip: '',
    }
  }
  if (time) {
    const [hours, minutes] = time.split(':')
    newDate.set('hour', Number(hours))
    newDate.set('minute', Number(minutes))
    if (unit === 'day') {
      let remainingTime = ''
      let protip = ''
      if (value === 0) {
        protip = "Pro Tip: If this doesn't seem right, try changing the number of days from 0 to 1"
        if (newDate.isBefore(currentDate)) {
          newDate.add(1, 'day')
        }
        remainingTime = calculateTimeDifference(currentDate, newDate)
      } else {
        newDate.add(value, 'day')
        if (value === 1) {
          remainingTime = calculateTimeDifference(currentDate, newDate)
          protip = "Pro Tip: If this doesn't seem right, try changing the number of days from 1 to 0"
        }
      }
      return {
        message: `${message} ${newDate.format('dddd MMMM Do, YYYY')} at ${time} ${
          selectedTimezone.label
        }${remainingTime}`,
        protip,
      }
    }
    if (unit === 'week') {
      let remainingTime = ''
      let protip = ''
      const days = Object.keys(daysOfWeek)
      let nextDay = days[0]

      for (let i = 0; i < days.length; i += 1) {
        const weekDay = days[i]

        if (dayOfWeekMap[weekDay] - newDate.isoWeekday() > 0) {
          nextDay = weekDay
          break
        }
      }

      let difference: number = dayOfWeekMap[nextDay] - newDate.isoWeekday()
      const weeksToAdd: number = value
      if (weeksToAdd === 0) {
        if ((difference === 0 && newDate.isBefore(currentDate)) || difference < 0) {
          difference += 7
        }
        protip = "Pro Tip: If this doesn't seem right, try changing the number of weeks from 0 to 1"
      }
      difference += weeksToAdd * 7
      newDate.add(difference, 'day')
      if (weeksToAdd === 1) {
        protip = "Pro Tip: If this doesn't seem right, try changing the number of weeks from 1 to 0"
      }
      if (weeksToAdd <= 1) {
        remainingTime = calculateTimeDifference(currentDate, newDate)
      }
      return {
        message: `${message} ${capitalizeFirstLetter(nextDay)} ${newDate.format('MMMM Do, YYYY')} at ${time} ${
          selectedTimezone.label
        }${remainingTime}`,
        protip,
      }
    }
  }

  newDate.add(value, unit)

  return {
    message: `${message} ${newDate.format('dddd MMMM Do, YYYY')} at ${newDate.format('HH:mm')} ${
      selectedTimezone.label
    }`,
    protip: '',
  }
}

export type BaseLanguageType = {
  name: string
  value: string
}

export type SpeechLanguageType = BaseLanguageType & {
  default?: boolean
}

export type LanguageType = BaseLanguageType & {
  checked?: boolean
  speechLanguage?: SpeechLanguageType
}

export type LanguageListItem = {
  label: string
  value: string
}

export type TranslationsType = {
  [key: string]: {
    text?: string
    image?: string
    playfile?: string
    say?: string
    voice?: string
  }
}

export type MessageType = {
  text?: string
  image?: string
  say?: string
  translations?: TranslationsType
  voice?: string
}

export type AudioType = {
  playfile?: string
  translations?: TranslationsType
}

export type ContactSelectionType = {
  allContactsAreSelected: boolean
  includedContactIds: string[]
}

export type SenderIdType = {
  name?: string
  phonenumber?: string
  sender?: string
}

export type SenderIdsForDripType = {
  sms: SenderIdType[]
  voice: SenderIdType[]
  whatsapp: SenderIdType[]
}

export type ItemType = { [key: string]: any }

export const getMessageContent = (
  {
    message,
    languages = [],
    defaultLanguage,
  }: { message: MessageType | AudioType | undefined | null; languages: LanguageType[]; defaultLanguage: string },
  activeLanguage: string,
  content: string,
) => {
  const multilingual = languages.length > 0
  const messageContent = pathOr('', [content], message)
  const translations = pathOr({}, ['translations'], message)
  const found = find(propEq('value', activeLanguage), languages)
  if (!multilingual || !found || found.value === defaultLanguage) {
    return messageContent
  }
  if (has(activeLanguage, translations)) {
    return pathOr('', [activeLanguage, content], translations)
  }
  return ''
}

export const hasContent = (
  item: ItemType | undefined,
  contentProp: ContentProperty,
  checkTranslations = true,
): boolean => {
  if (!item) {
    return false
  }
  if (item[contentProp]) {
    return true
  }
  if (!contentProp) {
    if (item.playfile || item.text || item.image || item.video || item.document) {
      return true
    }
  }
  if (checkTranslations && item.translations) {
    return reduce(
      (accum: boolean, translation: any): boolean => {
        if (item.translations && item.translations[translation] && !isEmpty(item.translations[translation])) {
          if (
            item.translations[translation][contentProp] ||
            item.translations[translation].playfile ||
            item.translations[translation].text ||
            item.translations[translation].image
          ) {
            return true
          }
        }
        return accum
      },
      false,
      keys(item.translations),
    )
  }
  return false
}

export const getCampaignType = (item: CampaignType) => {
  switch (pathOr('', ['type'], item) as string) {
    case 'drip':
      return 'Drip'
    case 'smsblast':
      return 'SMS Blast'
    case 'smsdrip':
      return 'SMS Drip'
    case 'voiceblast':
      return 'Voice Blast'
    case 'voicedrip':
      return 'Voice Drip'
    case 'ivr':
      return 'Voice Survey'
    case 'smssurvey':
      return 'SMS Survey'
    case 'whatsappsurvey':
      return 'WhatsApp Survey'
    case 'cati':
      return 'CATI Survey'
    case 'reminder':
      return 'Reminder'
    default:
      return ''
  }
}

export const getValueForTagInputInConditions = (textValue: string | string[], condition: Condition) => {
  if (typeof textValue === 'string') {
    if (condition[2]) {
      return condition.slice(2)
    }
    return []
  }
  return textValue
}

export const getNumberedInputError = (value: string, min: number | undefined, max: number | undefined) => {
  if (max !== undefined && Number(value) > max) {
    return `Value is higher than the max allowed (${max})`
  }
  if (min !== undefined && Number(value) < min) {
    return `Number should be minimum ${min}`
  }
  return ''
}

export const getRange = (input: string) => {
  if (test(/^[0-9]+-[0-9]+$/, input)) {
    const [min, max] = split('-', input)
    if (Number(min) <= Number(max) && Number(min) >= 0) {
      return [
        {
          min: Number(min),
          max: Number(max),
        },
      ]
    }
  }
  return []
}

const getReply = (input: string) => (test(/^[0-9]+-[0-9]+$/, input) ? [] : [trim(replace(/"/g, '', input))])
const getVoiceReply = (input: string) => (test(/^[0-9]+$/, input) ? [trim(replace(/"/g, '', input))] : [])

export const processInputs = (input: string, rangesAvailable: boolean) =>
  reduce(
    ({ ranges, replies }: ChoiceListType, entry): any => ({
      ...(rangesAvailable
        ? {
            ranges: [...(ranges || []), ...getRange(entry)],
          }
        : {}),
      replies: [...(replies || []), ...(rangesAvailable ? getReply(entry) : getVoiceReply(entry))],
    }),
    {},
    input.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g) as string[],
  )

export const helperTextForChoiceInput = (rangesAvailable: boolean, isSpeech = false) => {
  if (isSpeech) {
    return 'Press Enter or the + button to save each choice. The actions below will trigger if any of these choices is recognized.'
  }
  if (rangesAvailable) {
    return 'Press Enter or the + button to save each choice. Put a "-" between two numbers to create a range, e.g., 1-3 means that 1, 2, and 3 are all valid choices.'
  }
  return 'Press Enter or the + button to save each choice. You can only enter numbers as voice replies.'
}

export const checkIfNoChoiceInInput = (tagList: ChoiceListType): boolean => {
  const noReply = tagList.replies?.length === 0
  const noRange = tagList.ranges?.length === 0

  return !tagList || isEmpty(tagList) || (noReply && noRange)
}

export const changeTimezoneAndKeepDate = (datetime: moment.Moment, timezone: string) => datetime.tz(timezone, true)

export const getTags = (input: string) => (test(/^[0-9]+-[0-9]+$/, input) ? [] : [trim(replace(/"/g, '', input))])

export const processInput = (input: string) =>
  reduce(
    (tags: string[], entry: string) => [...tags, ...getTags(entry)],
    [],
    input.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g) || [],
  )

export const welcomeHasContent = (campaignContent?: CampaignDefinition, item?: any) => {
  if (
    !campaignContent ||
    !campaignContent.welcome ||
    !campaignContent.welcomeType ||
    !campaignContent.welcomeTypeProperties?.length
  ) {
    return false
  }

  for (let i = 0; i < campaignContent.welcomeTypeProperties.length; i += 1) {
    const welcomeProp = campaignContent.welcomeTypeProperties[i]
    if (hasContent(pathOr({}, [campaignContent.welcomeType], item), welcomeProp)) {
      return true
    }
  }
  return false
}

export const getDaysOfWeekValue = (delta: DelayState) => {
  const daysOfWeek = Object.keys(delta?.daysOfWeek || {}).filter((key) => delta?.daysOfWeek?.[key])
  if (!delta?.dayOfWeek && !daysOfWeek.length) {
    return ['monday']
  }

  if (delta?.dayOfWeek) {
    return [delta.dayOfWeek]
  }

  return daysOfWeek
}

export const getDaysOfWeek = (sortedValue: string[] = []) =>
  sortedValue.reduce(
    (obj, item) => ({
      ...obj,
      [item]: true,
    }),
    {},
  )

type MessagePartType = TMessage | ReminderMessage

export const getMessageContentType = (item: MessagePartType): keyof typeof messageTypeTextMap => {
  switch (item.type) {
    case PartFamily.SMS: {
      return item.hasQuestion ? 'sms-q' : 'sms-m'
    }
    case PartFamily.Voice: {
      return item.hasQuestion ? 'voice-q' : 'voice-m'
    }
    case PartFamily.WhatsApp: {
      return item.hasQuestion ? 'whatsapp-q' : 'whatsapp-m'
    }
    case PartFamily.CATI: {
      return item.hasQuestion ? 'cati-q' : 'cati-m'
    }
    case PartFamily.Topup: {
      return 'topup-q'
    }
    case PartFamily.API: {
      return 'api-q'
    }
    case PartFamily.Action: {
      return item.hasQuestion ? 'action-q' : 'action-m'
    }
    default: {
      throw new Error('Invalid type')
    }
  }
}
