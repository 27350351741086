import { pathOr } from 'ramda'
import envConfig from '@/config'
import { createAsyncActions, preventParallel, toQuery } from '@/app/service/util'
import {
  defaultPageSize as defaultContactsPageSize,
  getContactsFailure,
  getContactsRequest,
  getContactsSuccess,
} from '@/app/module/contacts/store/actions'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import {
  ACTION_DELETE_SEGMENTS_ITEM_FAILURE,
  ACTION_DELETE_SEGMENTS_ITEM_REQUEST,
  ACTION_DELETE_SEGMENTS_ITEM_SUCCESS,
  ACTION_GET_SEGMENTS_FAILURE,
  ACTION_GET_SEGMENTS_ITEM_FAILURE,
  ACTION_GET_SEGMENTS_ITEM_REQUEST,
  ACTION_GET_SEGMENTS_ITEM_SUCCESS,
  ACTION_GET_SEGMENTS_REQUEST,
  ACTION_GET_SEGMENTS_SUCCESS,
  ACTION_RESET_SEGMENTS_QUERY,
  ACTION_SAVE_SEGMENTS_ITEM_FAILURE,
  ACTION_SAVE_SEGMENTS_ITEM_REQUEST,
  ACTION_SAVE_SEGMENTS_ITEM_SUCCESS,
  ACTION_SEGMENTS_NAVIGATE,
  ERROR_DELETE_SEGMENTS_ITEM_SERVER,
  ERROR_GET_SEGMENTS_ITEM_CONTACTS_SERVER,
  ERROR_GET_SEGMENTS_SERVER,
  ERROR_SAVE_SEGMENTS_ITEM_SERVER,
} from '@/app/module/segments/definitions'

const SEGMENT_TYPE = pathOr({}, ['modules', 'segments', 'type'], envConfig)

const {
  request: getSegmentsRequest,
  success: getSegmentsSuccess,
  failure: getSegmentsFailure,
} = createAsyncActions({
  request: ACTION_GET_SEGMENTS_REQUEST,
  success: ACTION_GET_SEGMENTS_SUCCESS,
  failure: ACTION_GET_SEGMENTS_FAILURE,
})

const {
  request: getSegmentsItemRequest,
  success: getSegmentsItemSuccess,
  failure: getSegmentsItemFailure,
} = createAsyncActions({
  request: ACTION_GET_SEGMENTS_ITEM_REQUEST,
  success: ACTION_GET_SEGMENTS_ITEM_SUCCESS,
  failure: ACTION_GET_SEGMENTS_ITEM_FAILURE,
})

const {
  request: saveSegmentsItemRequest,
  success: saveSegmentsItemSuccess,
  failure: saveSegmentsItemFailure,
} = createAsyncActions({
  request: ACTION_SAVE_SEGMENTS_ITEM_REQUEST,
  success: ACTION_SAVE_SEGMENTS_ITEM_SUCCESS,
  failure: ACTION_SAVE_SEGMENTS_ITEM_FAILURE,
})

const {
  request: deleteSegmentsItemRequest,
  success: deleteSegmentsItemSuccess,
  failure: deleteSegmentsItemFailure,
} = createAsyncActions({
  request: ACTION_DELETE_SEGMENTS_ITEM_REQUEST,
  success: ACTION_DELETE_SEGMENTS_ITEM_SUCCESS,
  failure: ACTION_DELETE_SEGMENTS_ITEM_FAILURE,
})

export {
  getSegmentsFailure,
  getSegmentsRequest,
  getSegmentsSuccess,
  saveSegmentsItemFailure,
  saveSegmentsItemRequest,
  saveSegmentsItemSuccess,
}

export const getSegments = preventParallel(
  ({ orgId, query = {} }) => `${orgId}-${toQuery(query)}`,
  ({ token, orgId, query = {} }) =>
    (dispatch) => {
      const search = query.searchTerm || query.search
      const q = {
        ...query,
        search,
        type: SEGMENT_TYPE.type,
      }
      dispatch(getSegmentsRequest(q))

      const { page = 1, size } = q

      return request.groups
        .getList({ token, orgId, query: q })
        .then(({ groups = [], total = 0 } = {}) => {
          dispatch(
            getSegmentsSuccess({
              values: groups,
              page,
              search,
              size: size || total,
              total,
            }),
          )
        })
        .catch((err) => {
          dispatch(
            getSegmentsFailure({
              status: err.status,
              error: err.message,
              message: ERROR_GET_SEGMENTS_SERVER,
            }),
          )
          return err
        })
    },
)

export const resetSegmentsQuery = () => ({
  type: ACTION_RESET_SEGMENTS_QUERY,
})

export const getSegmentsItem =
  ({ itemId, token, orgId }) =>
  (dispatch) => {
    dispatch(getSegmentsItemRequest(itemId))
    if (!token || !orgId || !itemId) {
      return {}
    }
    return request.groups
      .getItem({ token, orgId, query: { itemId } })
      .then((res) => {
        dispatch(getSegmentsItemSuccess(res))
      })
      .catch((err) => {
        dispatch(
          getSegmentsItemFailure({
            status: err.status,
            error: err.message,
            message: ERROR_GET_SEGMENTS_SERVER,
          }),
        )
      })
  }

export const saveSegmentsItem =
  ({ token, orgId, item }) =>
  (dispatch) => {
    const itemId = pathOr('', ['id'], item)
    const segment = {
      ...item,
      type: SEGMENT_TYPE.type,
    }
    const actionType = itemId ? 'saveItem' : 'addItem'
    dispatch(saveSegmentsItemRequest(segment))

    return request.groups[actionType]({ token, orgId, item: segment, itemId })
      .then(({ id }) => {
        dispatch(saveSegmentsItemSuccess({ ...segment, id }))
        dispatch(
          createNotification({
            'save-segment': {
              type: 'success',
              message: `Segment successfully ${itemId ? 'updated' : 'saved'}!`,
            },
          }),
        )
      })
      .catch((err) => {
        const message = pathOr('', ['response', 'message'], err)
        dispatch(saveSegmentsItemFailure({ status: err.status, message }))
        dispatch(
          createNotification({
            'save-segment': {
              type: 'error',
              message: ERROR_SAVE_SEGMENTS_ITEM_SERVER || message,
            },
          }),
        )
        return err
      })
  }

export const deleteSegmentsItem =
  ({ token, orgId, itemId }) =>
  (dispatch) => {
    dispatch(deleteSegmentsItemRequest({ id: itemId }))
    return request.groups
      .delete({ token, orgId, itemId })
      .then(() => {
        dispatch(deleteSegmentsItemSuccess({ id: itemId }))
        dispatch(
          createNotification({
            [`delete-segments-${itemId}`]: {
              type: 'success',
              message: 'Segment successfully deleted.',
            },
          }),
        )
        return itemId
      })
      .catch((err) => {
        dispatch(deleteSegmentsItemFailure({ id: itemId }))
        dispatch(
          createNotification({
            [`delete-segments-${itemId}`]: {
              type: 'error',
              message: ERROR_DELETE_SEGMENTS_ITEM_SERVER,
            },
          }),
        )
        return err
      })
  }

export const getSegmentContacts =
  ({ token, orgId, segmentId, query }) =>
  (dispatch) => {
    const q = {
      ...query,
      type: SEGMENT_TYPE.type,
    }
    dispatch(getContactsRequest(q))

    const { page = 1, size = defaultContactsPageSize } = q

    return request.groups
      .getContacts({ token, orgId, groupId: segmentId, query: q })
      .then(({ contacts = [], total, orgTotal }) => {
        dispatch(
          getContactsSuccess({
            values: contacts,
            page: parseInt(page || q.page, 10),
            size: size || defaultContactsPageSize,
            total,
            orgTotal,
            searchTerm: '',
            filter: q.filter,
          }),
        )
      })
      .catch((err) => {
        dispatch(
          getContactsFailure({
            status: err.status,
            error: err.message,
            message: ERROR_GET_SEGMENTS_ITEM_CONTACTS_SERVER,
          }),
        )
      })
  }

export const navigateSegments = ({ page = 1, searchTerm }) => ({
  type: ACTION_SEGMENTS_NAVIGATE,
  value: { page, searchTerm },
})
