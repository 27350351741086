import Alert from '@mui/material/Alert'
import Link from '@mui/material/Link'
import React from 'react'

type Props = {
  showOrgWarning?: boolean
  totpEnabled: boolean
}

const TOTPWarning: React.FC<Props> = ({ showOrgWarning, totpEnabled }) => {
  if (!showOrgWarning && totpEnabled) {
    return null
  }

  return (
    <div style={{ margin: 16 }}>
      {!totpEnabled && (
        <Alert severity="error">
          You do not have two-factor authentication (2FA) enabled.{' '}
          <strong>
            This puts your organization at serious risk, including your contact database being accessed, your balance
            being depleted, your credit card being charged, and even potential legal troubles
          </strong>{' '}
          <Link href="https://www.engagespark.com/support/engagespark-two-factor-authentication-2fa/" target="_blank">
            (learn more)
          </Link>
          . These risks are especially high if your engageSPARK password is the same or similar to your password on any
          other website. 2FA will significantly reduce such risks.{' '}
          <Link href="/account/profile/security">Please set up 2FA now!</Link>
        </Alert>
      )}
      {totpEnabled && showOrgWarning && (
        <Alert severity="warning">
          Some of your colleagues do not have two-factor authentication (2FA) enabled.{' '}
          <strong>
            This puts your organization at serious risk, including your contact database being accessed, your balance
            being depleted, your credit card being charged, and even potential legal troubles
          </strong>{' '}
          <Link href="https://www.engagespark.com/support/engagespark-two-factor-authentication-2fa/" target="_blank">
            (learn more)
          </Link>
          . These risks are especially high if your colleague's engageSPARK password is the same or similar to their
          password on any other website. 2FA will significantly reduce such risks. Please push them to set up 2FA as
          soon as possible!
        </Alert>
      )}
    </div>
  )
}

export default TOTPWarning
