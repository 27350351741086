import FormControl, { FormControlProps } from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import React from 'react'

type Props = Omit<FormControlProps, 'error'> & {
  error?: string
  info?: React.ReactNode
  className?: string
  fullWidth?: boolean
}

const Control: React.FC<Props> = ({
  error = '',
  info,
  className = '',
  children,
  fullWidth = true,
  variant = 'standard',
  ...rest
}) => {
  return (
    <FormControl
      variant={variant}
      error={!!error.length}
      fullWidth={fullWidth}
      className={className}
      style={{ position: 'relative', zIndex: '1' }}
      {...rest}
    >
      <div style={{ marginBottom: '0.5rem' }}>{children}</div>
      <FormHelperText>{error || info}</FormHelperText>
    </FormControl>
  )
}

export default Control
