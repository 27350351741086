import React from 'react'
import { Button, Typography } from '@mui/material'
import { CountryTimezone, TimezoneWithCountryCodeType } from '@/app/types'
import { DateFilter } from '@/app/module/reports/types'
import config from '@/config'
import { formatDT } from '@/app/service/util/format'
import DateFilterDialog from './date-filter-dialog'

type Props = {
  countriesTimezones: CountryTimezone[]
  countryCode: string
  disabled?: boolean
  timezone: string
  timezones: TimezoneWithCountryCodeType
  value?: DateFilter

  onSave: (value?: DateFilter) => void
}

const dateFormat = config.display.format.orgReportFilter

const hasDateFilter = (value?: DateFilter): boolean => !!value?.from || !!value?.until

const OrganizationReportDateFilter: React.FC<Props> = ({
  countriesTimezones,
  countryCode,
  disabled,
  timezone,
  timezones,
  value,
  onSave,
}) => {
  const [open, setOpen] = React.useState(false)

  const datesText = React.useMemo(() => {
    if (!value) {
      return 'Include spending from any time.'
    }
    const { from, until, tz } = value
    const timezoneText = tz ? ` (${tz})` : ''

    if (from && until) {
      return `Only include spending from ${formatDT(from, undefined, dateFormat)} until ${formatDT(until, undefined, dateFormat)}${timezoneText}`
    }
    if (from) {
      return `Only include spending from ${formatDT(from, undefined, dateFormat)}${timezoneText} until now`
    }
    if (until) {
      return `Only include spending until ${formatDT(until, undefined, dateFormat)}${timezoneText}`
    }
    return 'Include spending from any time.'
  }, [value])

  const handleClose = () => setOpen(false)
  const handleSave = (newValue: DateFilter) => {
    onSave(newValue)
    setOpen(false)
  }

  return (
    <div>
      <Typography variant="subtitle2">
        Dates: {datesText}
        {!disabled && (
          <Button color="primary" onClick={() => setOpen(true)} size="small">
            Edit
          </Button>
        )}
        {!disabled && hasDateFilter(value) && (
          <Button color="primary" onClick={() => onSave(undefined)} size="small">
            Clear
          </Button>
        )}
      </Typography>
      {!disabled && open && (
        <DateFilterDialog
          countriesTimezones={countriesTimezones}
          countryCode={countryCode}
          dateFormat={dateFormat}
          timezone={timezone}
          timezones={timezones}
          value={value}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
    </div>
  )
}

export default OrganizationReportDateFilter
