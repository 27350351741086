import { DialogContentText, Typography, styled } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { DateTime } from '@/app/component/atom/form'
import { noTimezoneDateTimeFormat } from '@/app/service/util/format'
import { CountryTimezone, TimezoneWithCountryCodeType } from '@/app/types'
import EventTimezonePicker from '@/app/module/campaigns/component/item/steps/event-time/event-timezone-picker'

type Props = {
  countriesTimezones: CountryTimezone[]
  countryCode: string
  t0: string
  t0timezone: string
  timezones: TimezoneWithCountryCodeType

  onChange: (t0: string, t0timezone: string) => void
}

const ReminderEventTime: React.FC<Props> = ({
  countriesTimezones,
  countryCode,
  t0,
  t0timezone,
  timezones,
  onChange,
}) => {
  const handleChangeT0 = React.useCallback(
    ({ value }: { name: string; value: moment.Moment | null }) => {
      if (!value) {
        return
      }
      onChange(value.tz(t0timezone).format(noTimezoneDateTimeFormat), t0timezone)
    },
    [t0timezone, onChange],
  )
  const handleChangeT0Timezone = React.useCallback((value: string) => onChange(t0, value), [t0, onChange])

  return (
    <Container>
      <DialogContentText>
        Select the event time for the campaign's message sequence, e.g. the date of birth, or the start of the workshop.{' '}
      </DialogContentText>
      <DateTime
        disablePast={false}
        editable
        label=""
        name="event-time"
        timezone={t0timezone}
        value={t0}
        onChange={handleChangeT0}
      />
      <Typography color="rgba(0, 0, 0, 0.54)" variant="caption">
        Messages will be sent out relative to this event time, as configured in the campaign. The event time can be in
        the past.
      </Typography>
      <EventTimezonePicker
        countriesTimezones={countriesTimezones}
        countryCode={countryCode}
        t0timezone={t0timezone}
        timezones={timezones}
        onChange={handleChangeT0Timezone}
      />
    </Container>
  )
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
}))

export default ReminderEventTime
