import Typography from '@mui/material/Typography'
import CountryFlag from '@/app/component/atom/flag'
import { formatPhoneNumber, parsePhoneNumber } from '@/app/service/util'
import React from 'react'

type Props = {
  countryCode?: string
  phoneNumber?: string
}

const Phonenumber: React.FC<Props> = ({ countryCode = '', phoneNumber = '' }) => {
  const { country, phone } = parsePhoneNumber(phoneNumber)

  const phoneNumberValue = !country ? phone : formatPhoneNumber(`+${phone}`)

  return (
    <Typography>
      {countryCode && (
        <span
          style={{
            marginRight: '10px',
          }}
        >
          <CountryFlag code={countryCode} />
        </span>
      )}
      <span>{phoneNumberValue}</span>
    </Typography>
  )
}

export default Phonenumber
