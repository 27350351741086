import { Icon } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import TopupIcon from '@mui/icons-material/AccountBalanceWallet'

type ContentTypeIconProps = {
  type: string
}

export function ContentTypeIcon({ type }: ContentTypeIconProps) {
  switch (type) {
    case 'sms-m':
    case 'sms-q':
      return <Icon>sms</Icon>
    case 'voice-m':
    case 'voice-q':
      return <Icon>mic</Icon>
    case 'cati-m':
    case 'cati-q':
      return <Icon>headset_mic</Icon>
    case 'whatsapp-m':
    case 'whatsapp-q':
      return <WhatsAppIcon />
    case 'action-q':
      return <Icon>arrow_circle_up</Icon>
    case 'topup-q':
      return <TopupIcon />
    case 'api-q':
      return <Icon>share</Icon>
    default: {
      return null
    }
  }
}
