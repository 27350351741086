import { reduce, pathOr } from 'ramda'
import { Action } from '@/app/module/campaigns/types/schemas/actions/all-actions'
import { CampaignTemplateType, LocalActionType, PartFamily } from '@/app/module/campaigns/types'
import { ActionContext } from './types'

type ActionsList = {
  actions?: Action[]
}[]

export const countActionsInCallResult = (callResultActions: ActionsList) =>
  reduce((accum, actions) => pathOr([], ['actions'], actions).length + accum, 0, callResultActions)

const baseActions = [
  LocalActionType.SendSMS,
  LocalActionType.UpdateContact,
  LocalActionType.SubscribeToCampaign,
  LocalActionType.API,
  LocalActionType.SendSMSToPhoneNumbers,
  LocalActionType.SendEmail,
  LocalActionType.Topup,
  LocalActionType.ResumeSubscription,
  LocalActionType.FinishFlow,
]

function getSMSPartActions(actionNamespace: string, extraActions: LocalActionType[] = []) {
  const smsBaseActions = [...baseActions, ...extraActions, LocalActionType.Jump]

  switch (actionNamespace) {
    case ActionContext.OpenEnded:
    case ActionContext.OpenEndedCategory:
    case ActionContext.OnAnswer:
    case ActionContext.OnFailure: {
      return [...baseActions, ...extraActions, LocalActionType.Jump]
    }
    case ActionContext.OnTimeout:
    case ActionContext.OnInvalid: {
      const actions = [...smsBaseActions, LocalActionType.Resend]
      return actions
    }
    default: {
      return []
    }
  }
}

function getVoicePartActions(actionNamespace: string, extraActions: LocalActionType[] = []) {
  const voiceBaseActions = [
    ...baseActions,
    ...extraActions,
    LocalActionType.Jump,
    LocalActionType.PlayAudio,
    LocalActionType.Hangup,
    LocalActionType.Transfer,
  ]

  switch (actionNamespace) {
    case ActionContext.OnAnswer:
    case ActionContext.OnFailure: {
      return voiceBaseActions
    }
    case ActionContext.OnTimeout:
    case ActionContext.OnInvalid: {
      return [...voiceBaseActions, LocalActionType.Replay]
    }
    case ActionContext.CallError:
    case ActionContext.CallAnswered:
    case ActionContext.CallNotAnswered: {
      return [...baseActions, ...extraActions]
    }
    default: {
      return []
    }
  }
}

function getWhatsappPartActions(actionNamespace: string, extraActions: LocalActionType[] = []) {
  const wBaseActions = [...baseActions, ...extraActions, LocalActionType.Jump, LocalActionType.SendWhatsApp]
  switch (actionNamespace) {
    case ActionContext.OnAnswer:
    case ActionContext.OpenEnded:
    case ActionContext.OpenEndedCategory:
    case ActionContext.OnFailure:
    case ActionContext.OnButtons:
    case ActionContext.OnList: {
      return wBaseActions
    }
    case ActionContext.OnTimeout:
    case ActionContext.OnInvalid: {
      return [...wBaseActions, LocalActionType.Resend]
    }
    default: {
      return []
    }
  }
}

function getActionOnlyPartActions(actionNamespace: string, extraActions: LocalActionType[] = []) {
  switch (actionNamespace) {
    case ActionContext.ActionOnly: {
      return [...baseActions, ...extraActions, LocalActionType.Jump]
    }
    default: {
      return []
    }
  }
}

function getAPIPartActions(actionNamespace: string, extraActions: LocalActionType[] = []) {
  switch (actionNamespace) {
    case ActionContext.ApiSuccess:
    case ActionContext.ApiFailure: {
      return [...baseActions, ...extraActions, LocalActionType.Jump]
    }
    default: {
      return []
    }
  }
}

function getTopupPartActions(actionNamespace: string, extraActions: LocalActionType[] = []) {
  switch (actionNamespace) {
    case ActionContext.TopupSuccess:
    case ActionContext.TopupFailure: {
      return [...baseActions, ...extraActions].filter((action) => action !== LocalActionType.Topup)
    }
    default: {
      return []
    }
  }
}

function getCATIPartActions(actionNamespace: string, extraActions: LocalActionType[] = []) {
  switch (actionNamespace) {
    case ActionContext.OnAnswer:
    case ActionContext.OpenEnded: {
      return [...baseActions, ...extraActions, LocalActionType.Jump]
    }
    default: {
      return []
    }
  }
}

function getCampaignSpecificActions(campaignType: CampaignTemplateType, actionNamespace: string) {
  switch (campaignType) {
    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey: {
      const vBaseActions = [...baseActions, LocalActionType.PauseSubscription].filter(
        (action) => action !== LocalActionType.FinishFlow,
      )
      switch (actionNamespace) {
        case ActionContext.CallAnswered:
        case ActionContext.CallNotAnswered:
        case ActionContext.CallError: {
          return vBaseActions
        }
        case ActionContext.Reconnect: {
          return [...vBaseActions, LocalActionType.PlayAudio]
        }
        case ActionContext.OnTransferFailed: {
          return [...vBaseActions, LocalActionType.PlayAudio, LocalActionType.Hangup]
        }
        default: {
          return []
        }
      }
    }
    case CampaignTemplateType.Drip: {
      switch (actionNamespace) {
        case ActionContext.OnTransferFailed: {
          return baseActions
        }
        default: {
          return []
        }
      }
    }
    default: {
      return []
    }
  }
}

export type ActionListContext =
  | {
      campaignType: CampaignTemplateType
      partType: PartFamily
      actionNamespace:
        | ActionContext.OnAnswer
        | ActionContext.OnTimeout
        | ActionContext.OnInvalid
        | ActionContext.OnFailure
        | ActionContext.OnButtons
        | ActionContext.OnList
        | ActionContext.OpenEnded
        | ActionContext.OpenEndedCategory
        | ActionContext.ApiSuccess
        | ActionContext.ApiFailure
        | ActionContext.TopupSuccess
        | ActionContext.TopupFailure
        | ActionContext.ActionOnly
        | ActionContext.CallError
        | ActionContext.CallAnswered
        | ActionContext.CallNotAnswered
    }
  | {
      campaignType: CampaignTemplateType
      actionNamespace: ActionContext.Reconnect | ActionContext.OnTransferFailed
    }

function getUnsortedActionsForCampaignType(ctx: ActionListContext): LocalActionType[] {
  // for non-part specific campaign actions
  if (!('partType' in ctx)) {
    return getCampaignSpecificActions(ctx.campaignType, ctx.actionNamespace)
  }

  switch (ctx.campaignType) {
    case CampaignTemplateType.SMSSurvey: {
      switch (ctx.partType) {
        case PartFamily.SMS: {
          return getSMSPartActions(ctx.actionNamespace)
        }
        case PartFamily.Action: {
          return getActionOnlyPartActions(ctx.actionNamespace)
        }
        case PartFamily.API: {
          return getAPIPartActions(ctx.actionNamespace)
        }
        default: {
          return []
        }
      }
    }
    case CampaignTemplateType.WhatsAppSurvey: {
      switch (ctx.partType) {
        case PartFamily.WhatsApp: {
          return getWhatsappPartActions(ctx.actionNamespace)
        }
        case PartFamily.Action: {
          return getActionOnlyPartActions(ctx.actionNamespace, [LocalActionType.SendWhatsApp])
        }
        case PartFamily.API: {
          return getAPIPartActions(ctx.actionNamespace, [LocalActionType.SendWhatsApp])
        }
        default: {
          return []
        }
      }
    }
    case CampaignTemplateType.CATI: {
      switch (ctx.partType) {
        case PartFamily.CATI: {
          return getCATIPartActions(ctx.actionNamespace)
        }
        case PartFamily.Action: {
          return getActionOnlyPartActions(ctx.actionNamespace)
        }
        default: {
          return []
        }
      }
    }
    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey: {
      switch (ctx.partType) {
        case PartFamily.Voice: {
          const actions = getVoicePartActions(ctx.actionNamespace, [LocalActionType.PauseSubscription])
          return actions.filter((action) => action !== LocalActionType.FinishFlow)
        }
        case PartFamily.Action: {
          const actions = getActionOnlyPartActions(ctx.actionNamespace, [
            LocalActionType.PlayAudio,
            LocalActionType.Transfer,
            LocalActionType.Hangup,
            LocalActionType.PauseSubscription,
          ])
          return actions.filter((action) => action !== LocalActionType.FinishFlow)
        }
        default: {
          return []
        }
      }
    }
    case CampaignTemplateType.Drip: {
      switch (ctx.partType) {
        case PartFamily.SMS: {
          const smsActions = getSMSPartActions(ctx.actionNamespace, [LocalActionType.SendWhatsApp])
          return smsActions.filter((a) => a !== LocalActionType.Jump)
        }
        case PartFamily.WhatsApp: {
          const whatsappActions = getWhatsappPartActions(ctx.actionNamespace)
          return whatsappActions.filter((a) => a !== LocalActionType.Jump)
        }
        case PartFamily.Voice: {
          const voiceActions = getVoicePartActions(ctx.actionNamespace, [LocalActionType.SendWhatsApp])
          return voiceActions.filter((a) => a !== LocalActionType.Jump)
        }
        case PartFamily.Action: {
          const actionActions = getActionOnlyPartActions(ctx.actionNamespace, [LocalActionType.SendWhatsApp])
          return actionActions.filter((a) => a !== LocalActionType.Jump)
        }
        case PartFamily.API: {
          const apiActions = getAPIPartActions(ctx.actionNamespace, [LocalActionType.SendWhatsApp])
          return apiActions.filter((a) => a !== LocalActionType.Jump)
        }
        case PartFamily.Topup: {
          const topupActions = getTopupPartActions(ctx.actionNamespace, [LocalActionType.SendWhatsApp])
          return topupActions.filter((a) => a !== LocalActionType.Jump)
        }
        default: {
          return []
        }
      }
    }
    case CampaignTemplateType.Reminder: {
      switch (ctx.partType) {
        case PartFamily.SMS: {
          const actions = getSMSPartActions(ctx.actionNamespace)
          return actions.filter((action) => action !== LocalActionType.Jump)
        }
        case PartFamily.Action: {
          const actions = getActionOnlyPartActions(ctx.actionNamespace)
          return actions.filter((action) => action !== LocalActionType.Jump)
        }
        default: {
          return []
        }
      }
    }
    default: {
      return []
    }
  }
}

const actionsOrderArr: LocalActionType[] = [
  // repeat
  LocalActionType.Replay,
  LocalActionType.Resend,

  // interact with and manage contact - primary
  LocalActionType.PlayAudio,
  LocalActionType.SendWhatsApp,
  LocalActionType.SendSMS,
  LocalActionType.UpdateContact,

  // internal campaign flow
  LocalActionType.Jump,

  // intereact with and manage contact - secondary
  LocalActionType.SubscribeToCampaign,
  LocalActionType.Topup,

  // send to others
  LocalActionType.SendSMSToPhoneNumbers,
  LocalActionType.SendEmail,

  // tools
  LocalActionType.API,
  LocalActionType.Transfer,

  // manage subscription
  LocalActionType.PauseSubscription,
  LocalActionType.ResumeSubscription,
  LocalActionType.Hangup,
  LocalActionType.FinishFlow,
  LocalActionType.Unsubscribe,
  LocalActionType.UnsubscribeAll,
]
const actionsOrder = actionsOrderArr.reduce(
  (acc, action, index) => ({
    ...acc,
    [action]: index,
  }),
  {} as Record<LocalActionType, number>,
)

export const getValidActionsForCampaignType = (ctx: ActionListContext): LocalActionType[] => {
  const actions = getUnsortedActionsForCampaignType(ctx)
  return actions.sort((a, b) => actionsOrder[a] - actionsOrder[b])
}
