import { Box, Icon, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import CustomTooltip from '@/app/component/atom/tooltip'
import { formatMessageLabel, getMessagePlaceholderLabel } from '@/app/module/campaigns/label-helpers'
import { Part } from '@/app/module/campaigns/types'
import { isPartQuestion, getPartFamily } from '@/app/module/campaigns/utils/part-content'

type Props = {
  active: boolean
  canReorder: boolean
  dragDisabled: boolean
  index: number
  jumps?: JumpInfo[]
  part: Part

  onClick: () => void
}

export type JumpInfo = {
  id: string
  label: string
  valid: boolean
}

const CampaignNavigatorItem: React.FC<Props> = ({ active, canReorder, dragDisabled, index, jumps, part, onClick }) => {
  const label = React.useMemo<string>(() => {
    const hasQuestion = isPartQuestion(part)
    const type = getPartFamily(part)
    const { label: placeholderLabel } = getMessagePlaceholderLabel(type, hasQuestion)

    return formatMessageLabel(index, part.label || placeholderLabel)
  }, [index, part])

  const hasJump = !!jumps?.length
  const hasOnlyValidJumps = React.useMemo(() => jumps?.every((jump) => jump.valid), [jumps])

  return (
    <Draggable draggableId={part.id} index={index} isDragDisabled={dragDisabled}>
      {(provided, snapshot) => {
        let dragCursor = 'grab'
        if (dragDisabled) {
          dragCursor = 'not-allowed'
        } else if (snapshot.isDragging) {
          dragCursor = 'grabbing'
        }

        return (
          <ListItem
            {...provided.draggableProps}
            data-testid={`campaign-navigator-item-${index}`}
            disablePadding
            divider
            ref={provided.innerRef}
            secondaryAction={
              hasJump && (
                <CustomTooltip
                  title={
                    hasOnlyValidJumps
                      ? 'This question has valid Jump to question action'
                      : 'The jump action is invalid (the target part is before the current question)'
                  }
                >
                  <Icon color={hasOnlyValidJumps ? 'primary' : 'warning'}>account_tree</Icon>
                </CustomTooltip>
              )
            }
          >
            <Box sx={{ width: '100%' }}>
              <ListItemButton
                selected={active}
                sx={{
                  ...(snapshot.isDragging && {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    opacity: 0.7,
                  }),
                }}
                onClick={onClick}
              >
                {canReorder && (
                  <div
                    {...provided.dragHandleProps}
                    data-testid={`campaign-navigator-item-${index}-drag-handle`}
                    style={{
                      borderRadius: '0',
                      cursor: dragCursor,
                      height: '100%',
                      padding: '0 6px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon color={dragDisabled ? 'disabled' : 'action'}>drag_indicator</Icon>
                  </div>
                )}
                <ListItemText
                  primaryTypographyProps={{
                    color: active ? 'primary' : 'initial',
                  }}
                  primary={label}
                />
              </ListItemButton>
            </Box>
          </ListItem>
        )
      }}
    </Draggable>
  )
}

export default CampaignNavigatorItem
