import { styled } from '@mui/material'
import React from 'react'

type Props = {
  label: string
}

const PzPill: React.FC<Props> = ({ label }) => (
  <Container>
    <HiddenSpan style={{ visibility: 'hidden' }}>{'{{'}</HiddenSpan>
    {label}
    <HiddenSpan style={{ visibility: 'hidden' }}>{'}}'}</HiddenSpan>
  </Container>
)

const Container = styled('span')(() => ({
  position: 'relative',
  zIndex: 9999,
  color: 'white',
  backgroundColor: '#4E98BB',
  borderRadius: '3px',
  padding: '2px 0px',
}))

const HiddenSpan = styled('span')(() => ({
  visibility: 'hidden',
}))

export default React.memo(PzPill)
