import Loading from '@/app/component/guard/loading'
import createHydrated from '@/app/component/wrapper/hydratable'
import Pricing from './pricing'

function Plan(props) {
  const { isLoading = false } = props

  return (
    <div>
      <Loading isLoading={isLoading}>
        <Pricing {...props} />
      </Loading>
    </div>
  )
}

export default createHydrated(Plan)
