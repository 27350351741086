import { TopupOverview } from '@/app/module/campaigns/types'
import React from 'react'
import { getPercentage } from '@/app/service/util/format'
import OverviewTable from '@/app/component/atom/overview-table'

type Props = {
  data: TopupOverview
}

const TopupOverviewTable: React.FC<Props> = ({ data }) => {
  const tableData = React.useMemo(
    () => [
      {
        name: 'Topups made',
        value: data.total,
      },
      {
        name: 'Success (% of made)',
        value: `${data.success} (${getPercentage(data.success, data.total)})`,
      },
      {
        name: 'Failed (% of made)',
        value: `${data.total - data.success} (${getPercentage(data.total - data.success, data.total)})`,
      },
    ],
    [data],
  )

  return <OverviewTable data={tableData} title="Airtime Topup" />
}

export default TopupOverviewTable
