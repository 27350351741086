import styled from '@emotion/styled'
import { ListItem, Typography } from '@mui/material'
import React from 'react'

type Props = {
  action?: React.ReactNode
  label: React.ReactNode
  special?: boolean
  value: React.ReactNode
}

const DashboardListItem: React.FC<Props> = ({ action, label, special, value }) => {
  return (
    <ListItem>
      <Content>
        <Label>
          <Typography component="div" variant="body2">
            {special ? <strong>{label}</strong> : label}
          </Typography>
          {action && action}
        </Label>
        <div>
          <Typography component="div" variant="body2">
            {special ? <strong>{value}</strong> : value}
          </Typography>
        </div>
      </Content>
    </ListItem>
  )
}

const Content = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

const Label = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
})

export default DashboardListItem
