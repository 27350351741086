import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { find, propEq } from 'ramda'
import React from 'react'
import SearchableSelect from '../../../component/atom/form/searchable-select'
import Loading from '../../../component/guard/loading'

export default function Campaigns({
  classes,
  campaignId,
  campaignData,
  updatePhoneNumber,
  orgId,
  token,
  phoneNumberId,
  campaignIds,
  getCampaigns,
}) {
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  React.useEffect(() => {
    if (token && orgId) {
      setLoading(true)
      getCampaigns({
        token,
        orgId,
        query: {
          templates: 'ivr',
          page: 1,
          size: 100,
          aborted: 'false',
          archived: 'false',
        },
      }).then(() => setLoading(false))
    }
    // eslint-disable-next-line
  }, [token, orgId])

  React.useEffect(() => {
    if (campaignId && campaignData && campaignIds.length > 0 && !find(propEq('value', campaignId), campaignIds)) {
      setData([
        {
          label: `${campaignData.name} - ${campaignId}`,
          value: campaignId,
        },
        ...campaignIds,
      ])
    } else {
      setData(campaignIds)
    }
  }, [campaignIds, campaignData, campaignId])
  return (
    <Loading isLoading={loading}>
      <CardContent>
        <Typography variant="h6" className={classes.title}>
          Incoming Voice Campaign
        </Typography>
        <Typography variant="caption">
          Would you like us to pick up if someone is calling this number? If yes, choose a running and open Voice
          Campaign.
        </Typography>
        <div
          style={{
            margin: 'auto',
          }}
        >
          {!loading && (
            <div
              style={{
                display: 'flex',
                marginTop: '10px',
              }}
            >
              <div
                style={{
                  width: '400px',
                }}
              >
                <SearchableSelect
                  formControlProps={{
                    margin: 'none',
                  }}
                  labelId="select-campaign-label"
                  value={campaignId}
                  keyPropFn={(option) => option.value}
                  valuePropFn={(option) => option.label}
                  onChange={(selectedId) => {
                    updatePhoneNumber({
                      orgId,
                      token,
                      phoneNumberId,
                      item: {
                        inboundCallCampId: selectedId || 0,
                      },
                    })
                  }}
                  id="phonenumbers-select-campaign"
                  label="Select Campaign"
                  options={data}
                  helperText="Only the 100 most recent campaigns are shown."
                />
              </div>
              <Tooltip id="reload-campaigns" title="Reload Campaign List">
                <IconButton
                  className={classes.reloadButton}
                  onClick={() => {
                    if (token && orgId) {
                      setLoading(true)
                      getCampaigns({
                        token,
                        orgId,
                        query: {
                          templates: 'ivr',
                          page: 1,
                          size: 100,
                          aborted: 'false',
                          archived: 'false',
                        },
                      }).then(() => setLoading(false))
                    }
                  }}
                >
                  <Icon>refresh</Icon>
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </CardContent>
    </Loading>
  )
}
