import Divider from '@mui/material/Divider'
import React from 'react'

type Props = {}

const AudioEmpty: React.FC<Props> = () => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      padding: '37px 0',
    }}
  >
    <Divider />
  </div>
)

export default AudioEmpty
