import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import React, { forwardRef, useCallback } from 'react'
import { makeStyles } from 'tss-react/mui'

type Option = {
  label: string
  value: string
}

type Props = {
  clearable?: boolean
  name?: string
  options: Option[]
  value: string[]
  onChange: (value: string[]) => void
}

const MultipleChoice = forwardRef<HTMLDivElement, Props>(
  ({ clearable = true, name, options, value, onChange }, ref) => {
    const { classes } = useStyles()

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value
        const index = value.indexOf(v)
        if (index === -1) {
          return onChange([...value, v])
        }
        return onChange(value.filter((item) => item !== v))
      },
      [value, onChange],
    )

    return (
      <div className={classes.container} data-testid={`multiple-choice-${name}`} ref={ref}>
        {options &&
          options.map((option) => (
            <MenuItem className={classes.menuItem} disableGutters key={option.value} value={option.value}>
              <FormControlLabel
                className={classes.label}
                control={
                  <Checkbox checked={value.indexOf(option.value) !== -1} value={option.value} onChange={handleChange} />
                }
                label={option.label}
              />
            </MenuItem>
          ))}
        {clearable && (
          <div className={classes.clear}>
            <Link component="button" variant="body2" onClick={() => onChange([])}>
              Clear selected
            </Link>
          </div>
        )}
      </div>
    )
  },
)

const useStyles = makeStyles()({
  container: {},
  clear: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    margin: 0,
    padding: '6px 16px 6px 5px',
    width: '100%',
  },
  menuItem: {
    padding: 0,
  },
})

export default MultipleChoice
