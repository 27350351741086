import { Icon, ListItemText } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { keys, pathOr } from 'ramda'
import { Select } from '@/app/component/atom/form'
import IconText from '@/app/component/layout/icon-text'

export default function CampaignContentQuestionSelect({ action = {}, values, onChange, displayMap }) {
  return (
    <Select
      id="question-action-select"
      style={{ width: keys(displayMap).indexOf(action.type) === -1 ? '100%' : '50%' }}
      fullWidth={true}
      name="actions-select"
      label={action.type ? undefined : 'Choose an action'}
      value={pathOr('', ['type'], action)}
      values={values}
      firstChoice={({ selectItemStyle }) => (
        <MenuItem
          style={selectItemStyle}
          divider
          key="select-value-actions-addFromLibrary"
          name="actions-select-item"
          value="addFromLibrary"
        >
          <ListItemText
            primaryTypographyProps={{
              sx: {
                fontWeight: 600,
              },
            }}
            primary={
              <IconText>
                <Icon
                  color="action"
                  style={{
                    fontSize: 16,
                  }}
                >
                  library_add
                </Icon>
                <span className="select-item-text">Add an action from your library</span>
              </IconText>
            }
            secondary="Add an action you previously saved to your library."
          />
        </MenuItem>
      )}
      onChange={onChange}
    />
  )
}
