import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { find } from 'ramda'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import CustomTooltip from '@/app/component/atom/tooltip'
import Loading from '@/app/component/guard/loading'
import { formatDate } from '@/app/service/util'
import { Permissions, UserRole } from '@/app/types'
import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import { CommonUserType, InvitePayload, InviteType, UserType } from '@/app/module/user/types'
import UserAccessContainer from './user-access.container'
import UserInviteDialog from './user-invite-dialog'

type Props = {
  formattedInvitations: CommonUserType[]
  formattedUsers: CommonUserType[]
  invitations: {
    loading: boolean
    error: string
    data: InviteType[]
    init: boolean
  }
  orgId: number
  permissions: Permissions
  role: UserRole
  token: string
  userId: number
  users: {
    loading: boolean
    error: string
    data: UserType[]
    init: boolean
  }

  deleteInvitation: (payload: { token: string; orgId: number; inviteId: number }) => Promise<void>
  deleteUser: (payload: { token: string; orgId: number; userId: number }) => Promise<void>
  getInvitations: (payload: { token: string; orgId: number }) => Promise<void>
  getUsers: (payload: { token: string; orgId: number }) => Promise<void>
  inviteUser: (payload: { token: string; orgId: number; item: InvitePayload }) => Promise<void>
}

const useStyle = makeStyles()((theme) => ({
  successChip: {
    backgroundColor: theme.palette.success.main,
  },
  errorChip: {
    backgroundColor: theme.palette.error.main,
  },
  evenRow: {
    backgroundColor: '#fafafa',
  },
  oddRow: {
    backgroundColor: 'initial',
  },
  statusTextActive: {
    fontWeight: 'bold',
    color: theme.palette.success.dark,
  },
  statusTextPending: {
    fontWeight: 'bold',
    color: theme.palette.warning.main,
  },
}))

const Users: React.FC<Props> = ({
  formattedInvitations,
  formattedUsers,
  invitations,
  orgId,
  permissions,
  token,
  userId,
  users,
  deleteInvitation,
  deleteUser,
  getInvitations,
  getUsers,
  inviteUser,
}) => {
  const { classes } = useStyle()
  const [isInviteDialogOpen, setInviteDialogOpen] = React.useState(false)
  const [isDeleteInvitationModalOpen, setIsDeleteInvitationModalOpen] = React.useState(0)
  const [currentUser, setCurrentUser] = React.useState<UserType | undefined>(undefined)
  const [toBeRemovedUser, setToBeRemovedUser] = React.useState<CommonUserType | undefined>()
  const handleDeleteInvitation = (inviteId: number) => {
    deleteInvitation({
      inviteId,
      token,
      orgId,
    }).then((res: any) => {
      setIsDeleteInvitationModalOpen(0)
      if (!(res instanceof Error)) {
        getInvitations({ token, orgId })
      }
    })
  }

  const handleInviteUser = (item: InvitePayload) => {
    return inviteUser({
      token,
      orgId,
      item,
    }).then((res: any) => {
      if (!(res instanceof Error)) {
        getInvitations({ token, orgId })
      }
      return res
    })
  }

  const { data: invitationsData, loading: loadingInvitationsData, init: initialInvitations } = invitations
  const { data: usersData = [], loading: loadingUsersData, init: initialUsers } = users

  const [loading, setLoading] = React.useState(loadingUsersData || loadingInvitationsData)
  const [init, setInit] = React.useState({})

  React.useEffect(() => {
    if (token && orgId) {
      getInvitations({ token, orgId })
      getUsers({ token, orgId })
    }
  }, [orgId, token, getInvitations, getUsers])

  const data: CommonUserType[] = React.useMemo(() => {
    if (invitationsData && usersData) {
      let allUsers = formattedInvitations.concat(formattedUsers)
      allUsers = allUsers.sort((a, b) => {
        if (a.inviteId) {
          return 1
        }
        if (b.inviteId) {
          return -1
        }
        if (!a.role) {
          return 1
        }
        if (!b.role) {
          return -1
        }
        if (a.role === 'Owner' || b.role === 'Customer Support') {
          return -1
        }
        if (b.role === 'Owner' || a.role === 'Customer Support') {
          return 1
        }
        if (a.role === 'Manager') {
          return -1
        }
        return 1
      })
      return allUsers
    }
    return []
  }, [formattedInvitations, formattedUsers, invitationsData, usersData])

  React.useEffect(() => {
    if (invitationsData && usersData) {
      setLoading(loadingInvitationsData || loadingUsersData)
    }
  }, [invitationsData, loadingInvitationsData, loadingUsersData, usersData])

  React.useEffect(() => {
    setInit(initialInvitations && initialUsers)
  }, [initialInvitations, initialUsers])

  const clearToBeRemovedUser = () => setToBeRemovedUser(undefined)

  return (
    <>
      <ConfirmationDialog
        text="Are you sure you want to delete the open invitation?"
        onClose={() => setIsDeleteInvitationModalOpen(0)}
        onConfirm={() => handleDeleteInvitation(isDeleteInvitationModalOpen)}
        isOpen={!!isDeleteInvitationModalOpen}
        deleteButtonText="Delete"
        title="Delete open invitation"
      />
      {toBeRemovedUser && (
        <ConfirmationDialog
          id="remove-user-dialog"
          title="Remove user from organization"
          text={`Please confirm you want to remove user ${toBeRemovedUser.name} (${toBeRemovedUser.email}) from the organization.`}
          deleteButtonText="Remove"
          isOpen
          onClose={clearToBeRemovedUser}
          onConfirm={async () => {
            if (toBeRemovedUser) {
              try {
                await deleteUser({
                  token,
                  orgId,
                  userId: toBeRemovedUser.id,
                })
              } finally {
                clearToBeRemovedUser()
              }
            }
          }}
        />
      )}
      {!!currentUser && <UserAccessContainer data={currentUser} onClose={() => setCurrentUser(undefined)} />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Loading style={{ flex: 1 }} isLoading={loading}>
          {data.length === 0 && init && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '90px',
                width: '100%',
              }}
            >
              <Typography variant="h6">No users.</Typography>
            </div>
          )}
          {data.length > 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '15px',
                }}
              >
                <Typography variant="h5" color="primary">
                  All Users
                </Typography>
                <div>
                  <Button size="small" variant="contained" onClick={() => setInviteDialogOpen(true)}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Icon>person_add</Icon>
                      <Typography style={{ paddingLeft: '5px' }}>Invite a user</Typography>
                    </div>
                  </Button>
                </div>
              </div>
              <TableContainer component="div">
                <Table size="small" className="invitations-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Invite Date</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell align="center">2FA</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell align="center">Full access</TableCell>
                      <TableCell align="center">Email notifications</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, index) => {
                      const isInvitation = !!row.inviteId
                      const isDeleteVisible =
                        permissions.manageUsers &&
                        !isInvitation &&
                        row.role !== 'Owner' &&
                        row.role !== 'Customer Support' &&
                        row.id !== userId
                      const isRoleEditable =
                        permissions.manageUsers && !isInvitation && row.id !== userId && row.role !== 'Owner'

                      return (
                        <TableRow key={row.id} className={index % 2 === 0 ? classes.evenRow : classes.oddRow}>
                          <TableCell>{formatDate(row.created)}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell className={row.inviteId ? classes.statusTextPending : classes.statusTextActive}>
                            {row.inviteId ? 'INVITE SENT' : 'ACTIVE'}
                          </TableCell>
                          <TableCell align="center">
                            {row.twoFactorAuthenticated && (
                              <Chip
                                className={
                                  row.twoFactorAuthenticated === 'YES' ? classes.successChip : classes.errorChip
                                }
                                label={row.twoFactorAuthenticated}
                                color="primary"
                              />
                            )}
                          </TableCell>
                          <TableCell>{row.role}</TableCell>
                          <TableCell align="center">
                            {row.fullAccess && (
                              <Chip
                                className={row.fullAccess === 'YES' ? classes.successChip : classes.errorChip}
                                label={row.fullAccess}
                                color="primary"
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {row.notify && (
                              <Chip
                                className={row.notify === 'YES' ? classes.successChip : classes.errorChip}
                                label={row.notify}
                                color="primary"
                              />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {isRoleEditable && (
                              <CustomTooltip title="Manage user role & permissions">
                                <IconButton
                                  onClick={() => setCurrentUser(find((user) => user.id === row.id, usersData))}
                                >
                                  <Icon>settings</Icon>
                                </IconButton>
                              </CustomTooltip>
                            )}
                            {isInvitation && (
                              <CustomTooltip title="Delete invitation">
                                <IconButton onClick={() => setIsDeleteInvitationModalOpen(row.id)}>
                                  <Icon>delete</Icon>
                                </IconButton>
                              </CustomTooltip>
                            )}
                            {isDeleteVisible && (
                              <CustomTooltip title="Remove user">
                                <IconButton data-testid="remove-user" onClick={() => setToBeRemovedUser(row)}>
                                  <Icon>delete</Icon>
                                </IconButton>
                              </CustomTooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Loading>
        <UserInviteDialog
          open={isInviteDialogOpen}
          onClose={() => setInviteDialogOpen(false)}
          onSubmit={handleInviteUser}
        />
      </div>
    </>
  )
}

export default Users
