import { usePrevious } from '@/app/helpers'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const useOrgSwitchGuard = (orgId: number) => {
  const history = useHistory()
  const previousOrgId = usePrevious(orgId)

  useEffect(() => {
    if (previousOrgId && previousOrgId !== orgId) {
      history.push(`/campaigns?page=1`)
    }
  }, [history, orgId, previousOrgId])
}
