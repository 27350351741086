import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import { FunctionType } from '@/config/types'
import CountryFlag from '@/app/component/atom/flag'
import Tooltip from '@/app/component/atom/tooltip'
import { dateTime, formatPhoneNumber, parsePhoneNumber } from '@/app/service/util'
import { financialNumber } from '@/app/service/util/format'
import { formatCost } from '@/app/module/logs/helpers'

type Props = {
  contactId: number
  isLoadingRow: boolean
  viewContact: FunctionType
  phoneNumber: number
  done: boolean
  success: boolean
  error: string
  priceCharged: number
  topupReceived: number
  topupRequested: number
  topupCurrency: string
  created: string
}

const useStyle = makeStyles()({
  card: {
    padding: '5px',
  },
  messageType: {
    paddingTop: '3px',
  },
  dataCell: {
    paddingTop: '8px',
  },
  popover: {
    position: 'absolute',
    marginRight: '20px',
    top: '50px',
    left: '27px',
    zIndex: 1,
  },
  cardContent: {
    padding: '15px',
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rowLoader: {
    textAlign: 'center',
  },
})

const processStatus = (done: boolean, success: boolean, error: string): string => {
  if (!done) {
    return 'Pending'
  }
  if (success) {
    return 'Success'
  }
  if (error) {
    return `Failed: ${error}`
  }
  return 'Unknown'
}

const processTopupValue = (topupReceived: number, topupCurrency: string, topupRequested: number): string => {
  if (topupReceived) {
    return `(${topupCurrency} ${financialNumber(topupReceived)})`
  }
  if (topupRequested) {
    if (topupCurrency) {
      return `(${topupCurrency} ${financialNumber(topupRequested)} requested)`
    }
    return `(${financialNumber(topupRequested)} requested, currency unknown )`
  }
  return '-'
}

export default function TopupRow(props: Props) {
  const {
    contactId,
    isLoadingRow,
    viewContact,
    phoneNumber,
    done,
    success,
    error,
    priceCharged,
    topupReceived,
    topupCurrency,
    topupRequested,
    created,
  } = props
  const { classes } = useStyle()

  const messageStatus = processStatus(done, success, error)
  const topupValue = processTopupValue(topupReceived, topupCurrency, topupRequested)

  return (
    <Card className={classes.card}>
      {isLoadingRow ? (
        <CardContent>
          <Grid container spacing={8}>
            <Grid item xs={12} className={classes.rowLoader}>
              <CircularProgress color="primary" size={50} />
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <CardContent>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle1" color="textPrimary">
                <CountryFlag code={parsePhoneNumber(String(phoneNumber)).country || ''} />
                &nbsp;
                {formatPhoneNumber(`+${phoneNumber}`)}
              </Typography>
            </Grid>

            <Grid className={classes.dataCell} item xs={4} sm={2} md={2}>
              <Typography color="textSecondary" align="center">
                <span>{messageStatus}</span>
              </Typography>
              <Typography color="primary" align="center" variant="body1">
                <b>({formatCost(priceCharged)})</b>
              </Typography>
            </Grid>

            <Grid className={classes.dataCell} item xs={4} sm={2} md={2}>
              <Typography color="textSecondary" align="center">
                <span>Topup amount</span>
              </Typography>
              <Typography color="primary" align="center" variant="body1">
                <b>{topupValue}</b>
              </Typography>
            </Grid>

            <Grid
              className={classes.dataCell}
              item
              xs={3}
              sm={2}
              md={3}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography color="textSecondary" align="center">
                <Tooltip title={'Date and Time'}>
                  <span>&nbsp;{dateTime(created)}</span>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={1}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Tooltip title={'View Contact'}>
                <IconButton
                  aria-label="View Contact"
                  onClick={() => {
                    viewContact(contactId)
                  }}
                >
                  <Icon>person</Icon>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  )
}
