import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Tabs from '@mui/material/Tabs'
import moment from 'moment'
import { path, pathOr } from 'ramda'
import React, { PureComponent } from 'react'
import config from '@/config'
import AudioPlayer from '@/app/component/atom/audio/player'
import AudioRecorder from '@/app/component/atom/audio/recorder'
import AudioUploader from '@/app/component/atom/audio/uploader'
import Tooltip from '@/app/component/atom/tooltip'
import UploadButton from '@/app/component/atom/upload/upload-button'
import { downloadFile } from '@/app/service/util/download'
import { BaseLanguageType } from '@/app/module/campaigns/helpers'
import {
  Language,
  PersonalizationType,
  TVoicePreview,
  VoiceMessage,
  VoiceProps,
  VoiceQuestion,
} from '@/app/module/campaigns/types'
import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import TooltipTab from '@/app/component/atom/tooltip-tab'
import LanguageWrapper from './language-wrapper'
import TextToSpeech from './text-to-speech'

type Props = Pick<
  VoiceProps,
  | 'defaultUpload'
  | 'files'
  | 'filterType'
  | 'loading'
  | 'playId'
  | 'getFile'
  | 'onMicAccessError'
  | 'saveFileHandler'
  | 'setDefaultUpload'
  | 'setPlaying'
> & {
  debounceChange?: boolean
  defaultLanguage: string
  enableTTS?: boolean
  info: string
  item: Partial<VoiceMessage | VoiceQuestion>
  languages?: Language[]
  personalizationList: PersonalizationType
  voiceList?: BaseLanguageType[]
  voicePreview?: TVoicePreview

  changeHandler: (item: any) => void
  onVoicePreviewRequest?: (say: string, voice: string) => void
  setPreviewLanguage?: (language: string) => void
}

enum VoiceTab {
  Audio = 'playfile',
  TTS = 'say',
}

type State = {
  activeLanguage?: string
  confirmationDialog: {
    display: boolean
    tab: VoiceTab | ''
  }
  downloading: boolean
  filter: string
  newFile: any
  playing: boolean
  recording: boolean
  selectedVoice: string
  tab: VoiceTab
  uploading: boolean
}

export default class CampaignContentVoice extends PureComponent<Props, State> {
  state = {
    activeLanguage: '',
    confirmationDialog: {
      display: false,
      tab: '' as '',
    },
    downloading: false,
    filter: 'optimize',
    newFile: null,
    playing: false,
    recording: false,
    selectedVoice: '',
    tab: VoiceTab.Audio,
    uploading: false,
  }

  uploadRef: React.RefObject<any>

  constructor(props: Props) {
    super(props)
    this.uploadRef = React.createRef()

    this.state.selectedVoice = props.item.audio?.voice || ''
    this.state.tab = isTTS(props.item, props.languages) ? VoiceTab.TTS : VoiceTab.Audio
  }

  downloadAudioFile = async (url: string, file: string) => {
    this.setState({
      downloading: true,
    })
    try {
      await downloadFile(url, file, true)
    } finally {
      this.setState({
        downloading: false,
      })
    }
  }

  setDefaultContent = (tab: string) => {
    const { item, changeHandler } = this.props

    const defaultPlayfile = {
      playfile: '',
      translations: {},
    }
    const defaultSay = {
      say: '',
      translations: {},
    }

    changeHandler({
      ...item,
      audio: tab === VoiceTab.Audio ? defaultPlayfile : defaultSay,
    })
  }

  handleTabChange = (tab: VoiceTab) => {
    const { item, languages } = this.props

    switch (tab) {
      case VoiceTab.Audio: {
        if (item.audio?.say || item.audio?.voice) {
          this.setState({
            confirmationDialog: {
              display: true,
              tab,
            },
          })
          return
        }
        if (languages?.length) {
          for (let i = 0; i < languages.length; i += 1) {
            const language = languages[i]
            const translation = item.audio?.translations?.[language.value]
            if (translation?.say || translation?.voice) {
              this.setState({
                confirmationDialog: {
                  display: true,
                  tab,
                },
              })
              return
            }
          }
        }
        break
      }

      case VoiceTab.TTS: {
        if (item.audio?.playfile) {
          this.setState({
            confirmationDialog: {
              display: true,
              tab,
            },
          })
          return
        }
        if (languages?.length) {
          for (let i = 0; i < languages.length; i += 1) {
            const language = languages[i]
            const translation = item.audio?.translations?.[language.value]
            if (translation?.playfile) {
              this.setState({
                confirmationDialog: {
                  display: true,
                  tab,
                },
              })
              return
            }
          }
        }
        break
      }

      default:
        break
    }

    this.setState(
      {
        tab,
      },
      () => {
        this.setDefaultContent(tab)
      },
    )
  }

  handleMessageTypeSwitch = (tab: VoiceTab) => {
    this.setState(
      {
        confirmationDialog: {
          display: false,
          tab: '',
        },
        selectedVoice: '',
        tab,
      },
      () => {
        this.setDefaultContent(tab)
      },
    )
  }

  renderAudio = (audio: any, onAudioChange: any) => {
    const {
      defaultUpload,
      enableTTS,
      files,
      filterType,
      item,
      loading,
      playId,
      getFile,
      onMicAccessError,
      saveFileHandler,
      setPlaying,
      setDefaultUpload,
    } = this.props
    const { downloading, recording, uploading } = this.state

    const isLoading = loading || downloading || uploading

    const handleRecordFinish = async (newFile: File) => {
      this.setState({ uploading: true })
      try {
        const res = await saveFileHandler({
          file: newFile,
          filename: `recording-${moment(moment.now()).format('YYYY-MM-DD')}.mp3`,
          filter: filterMap('record', filterType),
        })
        if (res instanceof Error) {
          return
        }
        onAudioChange({ message: res.filename })
      } finally {
        this.setState({ uploading: false })
      }
    }
    const handleUpload = async (newFile: File) => {
      this.setState({
        uploading: true,
      })
      try {
        const res = await saveFileHandler({
          file: newFile,
          filename: newFile.name,
          filter: filterMap(this.state.filter, filterType),
        })
        if (res instanceof Error) {
          return
        }
        onAudioChange({ message: res.filename })
      } finally {
        this.setState({
          uploading: false,
        })
      }
    }

    return (
      <div data-testid="campaign-content-audio">
        <div>
          <div
            style={{
              display: !audio && !recording ? 'initial' : 'none',
            }}
          >
            <AudioUploader
              id="audio-uploader"
              loading={isLoading}
              uploadContent={
                <UploadButton
                  setDefaultUpload={setDefaultUpload}
                  defaultUpload={defaultUpload}
                  startRecording={() => this.setState({ recording: true })}
                  startUpload={(currentFilters: any) => {
                    this.setState({
                      filter: currentFilters,
                    })
                    this.uploadRef.current?.open()
                  }}
                />
              }
              uploadRef={this.uploadRef}
              onUpload={handleUpload}
            />
          </div>
          <div
            style={{
              display: audio && !recording ? 'initial' : 'none',
            }}
          >
            <AudioPlayer
              id={item.id || ''}
              playId={playId || ''}
              loading={isLoading}
              name={path([audio, 'title'], files) || audio}
              source={path([audio], files)}
              onStop={() => {
                this.setState({
                  playing: false,
                })
              }}
              onPlayToggle={(val: any) => {
                setPlaying(val ? item.id || '' : '')
                this.setState({
                  playing: val,
                })
                if (val && !path([audio, 'url'], files)) {
                  getFile(audio)
                }
              }}
            />
          </div>
          <div
            style={{
              display: recording ? 'initial' : 'none',
            }}
          >
            <AudioRecorder
              converterPath={config.mp3convert.url}
              isRecording={recording}
              onDisabled={() => {
                this.setState({
                  recording: false,
                })
                onMicAccessError()
              }}
              onFinish={handleRecordFinish}
            />
          </div>
        </div>
        <div style={{ display: 'flex', position: 'relative' }}>
          <div style={{ marginRight: '8px' }}>
            <Tooltip title={recording ? 'Finish recording' : 'Record'}>
              <div>
                <Button
                  color="primary"
                  data-testid="voice-record-button"
                  variant="outlined"
                  disabled={isLoading}
                  style={{
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    minWidth: '0',
                  }}
                  onClick={() =>
                    this.setState((s) => ({
                      recording: !s.recording,
                    }))
                  }
                >
                  <Icon>{recording ? 'stop' : 'mic'}</Icon>
                </Button>
              </div>
            </Tooltip>
          </div>
          <div style={{ marginRight: '8px' }}>
            <Tooltip title="Download audio file">
              <div>
                <Button
                  className="voice-download-button"
                  color="primary"
                  data-testid="voice-download-button"
                  variant="outlined"
                  disabled={isLoading || recording || !audio}
                  onClick={() => {
                    getFile(audio).then(() => {
                      const url = path(['files', audio, 'url'], this.props) as string
                      if (url) {
                        this.downloadAudioFile(url, audio)
                      }
                    })
                  }}
                  style={{
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    minWidth: '0',
                  }}
                >
                  <Icon>cloud_download</Icon>
                </Button>
              </div>
            </Tooltip>
          </div>
          <div style={{ marginRight: '8px' }}>
            <Tooltip title="Remove audio">
              <div>
                <Button
                  className="voice-delete-button"
                  color="primary"
                  data-testid="voice-delete-button"
                  variant="outlined"
                  disabled={isLoading || recording || !audio}
                  onClick={() => {
                    onAudioChange({ message: '' })
                  }}
                  style={{
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    minWidth: '0',
                  }}
                >
                  <Icon>delete</Icon>
                </Button>
              </div>
            </Tooltip>
          </div>
          {!enableTTS && (
            <div style={{ marginRight: '8px' }}>
              <Tooltip title="Text to speech - coming soon">
                <div>
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={true}
                    style={{
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      minWidth: '0',
                    }}
                  >
                    <Icon>text_fields</Icon>
                  </Button>
                </div>
              </Tooltip>
            </div>
          )}
          {audio && !this.state.recording && (
            <div style={{ right: '0', position: 'absolute' }}>
              <UploadButton
                setDefaultUpload={setDefaultUpload}
                defaultUpload={defaultUpload}
                options={reuploadOptions}
                startRecording={() => this.setState({ recording: true })}
                startUpload={(currentFilters: any) => {
                  this.setState({
                    filter: currentFilters,
                  })
                  this.uploadRef.current.open()
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  handleLanguageChange = (language: string) => {
    const { defaultLanguage, item, setPreviewLanguage = () => {} } = this.props

    const isDefault = language === defaultLanguage
    if (isDefault) {
      this.setState({ selectedVoice: item.audio?.voice || '' })
    } else {
      this.setState({ selectedVoice: item.audio?.translations?.[language]?.voice || '' })
    }
    setPreviewLanguage(isDefault ? '' : language)
  }

  handleVoiceChange = (voice: string) => {
    this.setState({
      selectedVoice: voice,
    })
  }

  getTTSTabTooltip = () => {
    const { downloading, recording, playing, uploading } = this.state

    if (recording) {
      return 'Please stop recording before switching to Text-to-Speech.'
    }
    if (playing) {
      return 'Please stop playing the audio before switching to Text-to-Speech.'
    }
    if (downloading) {
      return 'Please wait for the download to finish before switching to Text-to-Speech.'
    }
    if (uploading) {
      return 'Please wait for the upload to finish before switching to Text-to-Speech.'
    }
    return ''
  }

  render() {
    const {
      debounceChange,
      defaultLanguage,
      enableTTS,
      info,
      item,
      languages,
      personalizationList,
      voiceList,
      voicePreview,
      changeHandler,
      onVoicePreviewRequest = () => {},
    } = this.props
    const { confirmationDialog, downloading, playing, recording, selectedVoice, tab, uploading } = this.state
    const activeLanguage = pathOr('', ['query', 'activeLanguage'], voicePreview)

    return (
      <LanguageWrapper
        content={tab as any}
        disabled={recording || playing}
        info={info}
        defaultLanguage={defaultLanguage}
        item={item}
        languages={languages}
        selectedVoice={selectedVoice}
        type="audio"
        onChange={changeHandler}
        onLanguageChange={this.handleLanguageChange}
      >
        {(content: any, onContentChange: any) => {
          if (!enableTTS) {
            return this.renderAudio(content, onContentChange)
          }

          return (
            <div data-testid="campaign-content-voice-tabs">
              <Tabs indicatorColor="primary" value={tab} onChange={(_, t) => this.handleTabChange(t)}>
                <TooltipTab label="Audio" style={{ zIndex: 2 }} value="playfile" wrapped />
                <TooltipTab
                  disabled={downloading || recording || playing || uploading}
                  label="Text-to-Speech"
                  style={{ zIndex: 2 }}
                  tooltip={this.getTTSTabTooltip()}
                  value="say"
                />
              </Tabs>
              {tab === VoiceTab.Audio && <>{this.renderAudio(content, onContentChange)}</>}
              {tab === VoiceTab.TTS && (
                <TextToSpeech
                  activeLanguage={activeLanguage}
                  autoFocus={item.autoFocus}
                  debounceChange={debounceChange}
                  message={content}
                  personalizationList={personalizationList}
                  selectedVoice={selectedVoice}
                  voiceList={voiceList || []}
                  voicePreview={voicePreview || { data: '', loading: false }}
                  onChange={onContentChange}
                  onVoiceChange={this.handleVoiceChange}
                  onVoicePreviewRequest={onVoicePreviewRequest}
                />
              )}
              <ConfirmationDialog
                id="voice-message-type-switch-dialog"
                text="Switching message types will discard your existing content. Do you want to continue?"
                onClose={() =>
                  this.setState({
                    confirmationDialog: {
                      display: false,
                      tab: '',
                    },
                  })
                }
                onConfirm={() =>
                  confirmationDialog.tab && this.handleMessageTypeSwitch(confirmationDialog.tab as VoiceTab)
                }
                isOpen={confirmationDialog.display}
                deleteButtonText="Confirm"
                title="Change Voice message type"
                icon="sms"
              />
            </div>
          )
        }}
      </LanguageWrapper>
    )
  }
}

const reuploadOptions = [
  {
    label: 'Replace Audio (optimize volume)',
    tooltip:
      "We'll improve the volume, and clean up some technical things so your file sounds better. (Please listen to the optimized version after upload to make sure you are happy with it.)",
    filter: 'optimizeVolume',
  },
  {
    label: 'Replace Audio (optimize volume & trim silence)',
    tooltip:
      "We'll remove unnecessary silences, improve the volume, and clean up some technical things so your file sounds better. (Please listen to the optimized version after upload to make sure you are happy with it.)",
    filter: 'optimizeAndTrim',
  },
  {
    label: 'Replace Audio (as is)',
    tooltip:
      "We won't make any changes to your audio file. We recommend against this option unless you have optimized the files outside the platform.",
    filter: 'asIs',
  },
  {
    label: 'Record Audio to Replace',
    tooltip: '',
    filter: 'record',
  },
]

const whatsappFilterMap: Record<string, string> = {
  optimizeVolume: 'opus,mono,norm',
  optimizeAndTrim: 'opus,mono,norm,trim',
  asIs: 'opus,mono',
  record: 'opus,mono,norm',
}
const voiceFilterMap: Record<string, string> = {
  optimizeVolume: 'ogg,mono,norm',
  optimizeAndTrim: 'ogg,mono,norm,trim',
  asIs: 'ogg,mono',
  record: 'ogg,mono,norm',
}

const filterMap = (filter: string, filterType: string) => {
  if (filterType === 'whatsapp') {
    return whatsappFilterMap[filter]
  }
  return voiceFilterMap[filter]
}

export const isTTS = (item: Partial<VoiceMessage | VoiceQuestion>, languages?: Language[]) => {
  if (item.audio?.playfile) {
    // if the default language has playfile attribute, then it's not TTS
    return false
  }

  const hasTTSAttribute = item.audio?.say !== undefined || item.audio?.voice !== undefined
  if (!languages?.length) {
    // if there are no languages, then it's TTS if the default language has say or voice attribute
    return hasTTSAttribute
  }

  for (let i = 0; i < languages.length; i += 1) {
    const language = languages[i]

    // if any of the languages has playfile attribute, then it's not TTS
    if (item.audio?.translations?.[language.value]?.playfile) {
      return false
    }

    if (
      item.audio?.translations?.[language.value]?.say !== undefined ||
      item.audio?.translations?.[language.value]?.voice !== undefined
    ) {
      return true
    }
  }

  return hasTTSAttribute
}
