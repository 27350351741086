import Icon from '@mui/material/Icon'
import CustomTooltip from '@/app/component/atom/tooltip'
import { numberWithCommas } from '@/app/service/util/format'
import { usageLevel } from '@/app/module/dashboard/store/selectors'
import ExternalLink from '@/app/component/atom/external-link'
import React from 'react'
import { OrgPlan } from '@/app/module/plans/types'
import { isPlanLegacy } from '@/app/module/plans/utils'
import numbersRow from './numbersRow'
import PlanBlock from './planBlock'
import DashboardList from './dashboard-list'

type Props = {
  isLoading: boolean
  plan?: OrgPlan
  stats: {
    start: string
    end: string
    smsSent: number
    smsReceived: number
    voiceSent: number
    voiceReceived: number
    whatsappSent: number
    whatsappReceived: number
  }
  usage: { contacts: string }
}

const Usage: React.FC<Props> = ({ isLoading, plan, stats, usage }) => {
  const items = React.useMemo(() => {
    if (!plan) {
      return []
    }

    return [
      getContactsDashboardItem(plan, usage.contacts),
      {
        label: 'User accounts',
        value: `${plan.usersNow} / ${plan.usersIncluded}`,
      },
      {
        label: 'SMS sent / received',
        value: `${stats.smsSent} / ${stats.smsReceived}`,
      },
      {
        label: 'Calls sent / received',
        value: `${stats.voiceSent} / ${stats.voiceReceived}`,
      },
      {
        label: 'WhatsApp messages sent / received',
        value: `${stats.whatsappSent} / ${stats.whatsappReceived}`,
      },
    ]
  }, [plan, usage.contacts, stats])

  return (
    <PlanBlock action={null} isLoading={isLoading} title={`Usage (${stats.start} to ${stats.end})`}>
      <DashboardList items={items} />
    </PlanBlock>
  )
}

const getContactsDashboardItem = (
  plan: OrgPlan,
  contactsUsage: string,
): { label: React.ReactNode; value: React.ReactNode } => {
  if (isPlanLegacy(plan)) {
    return {
      label: (
        <>
          Monthly active contacts &nbsp;
          <CustomTooltip
            interactive
            title={
              <>
                You can store an unlimited number of contacts in your account. This number is how many contacts you can
                interact with (send SMS, Voice Calls, Airtime Topups, WhatsApp messages, phone number validations, etc.)
                during your monthly plan period. There is no limit on the number of times you can interact with the
                included monthly active contacts.{' '}
                <ExternalLink
                  color="surface"
                  target="_blank"
                  href="https://www.engagespark.com/support/monthly-active-contacts/"
                >
                  Learn more
                </ExternalLink>{' '}
              </>
            }
          >
            <Icon style={{ verticalAlign: 'middle', fontSize: '120%', color: 'rgba(0, 0, 0, 0.54)' }}>info</Icon>
          </CustomTooltip>
        </>
      ),
      value: getContactsLegacyLimit(plan, contactsUsage),
    }
  }

  return {
    label: 'Stored contacts',
    value: numbersRow(
      usageLevel(plan.storedContactsNow.toString(), plan.storedContactsMax),
      `${numberWithCommas(plan.storedContactsNow)} / ${numberWithCommas(plan.storedContactsMax)}`,
    ),
  }
}

const getContactsLegacyLimit = (plan: OrgPlan, contactsUsage: string) => {
  if (plan.contactsIncluded > 10000000) {
    return numbersRow(usageLevel(contactsUsage, plan.contactsIncluded), `${contactsUsage} (unlimted)`)
  }

  return numbersRow(
    usageLevel(contactsUsage, plan.contactsIncluded),
    `${contactsUsage} (limit ${numberWithCommas(plan.contactsIncluded)})`,
  )
}

export default Usage
