import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grid from '@mui/material/Grid'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import React from 'react'
import { noop } from '@/app/definitions'
import Tooltip from '@/app/component/atom/tooltip'

type Props = {
  defaultUpload?: number
  options?: UploadOptions[]
  setDefaultUpload?: (index: number) => void
  startRecording?: () => void
  startUpload?: (filter: string) => void
}

type UploadOptions = {
  filter: string
  label: string
  tooltip: string
}

const defaultOptions = [
  {
    label: 'Upload Audio (optimize volume)',
    tooltip:
      "We'll improve the volume, and clean up some technical things so your file sounds better. (Please listen to the optimized version after upload to make sure you are happy with it.)",
    filter: 'optimizeVolume',
  },
  {
    label: 'Upload Audio (optimize volume & trim silence)',
    tooltip:
      "We'll remove unnecessary silences, improve the volume, and clean up some technical things so your file sounds better. (Please listen to the optimized version after upload to make sure you are happy with it.)",
    filter: 'optimizeAndTrim',
  },
  {
    label: 'Upload Audio (as is)',
    tooltip:
      "We won't make any changes to your audio file. We recommend against this option unless you have optimized the files outside the platform.",
    filter: 'asIs',
  },
  {
    label: 'Record Audio',
    tooltip: '',
    filter: 'record',
  },
]

const UploadButton: React.FC<Props> = (props) => {
  const { defaultUpload = 0, setDefaultUpload = noop } = props
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(defaultUpload)

  const options = props.options || defaultOptions

  const handleClick = () => {
    const { startUpload = noop, startRecording = noop } = props
    if (options[selectedIndex].filter === 'record') {
      startRecording()
    } else startUpload(options[selectedIndex].filter)
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement>, index: number) => {
    setDefaultUpload(index)
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return
    }
    setOpen(false)
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          data-testid="upload-audio-button"
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button onClick={handleClick}>{options[selectedIndex].label}</Button>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper style={{ zIndex: 1300 }} open={open} anchorEl={anchorRef.current} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <Tooltip key={index} title={option.tooltip}>
                        <MenuItem
                          key={option.label}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option.label}
                        </MenuItem>
                      </Tooltip>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  )
}

export default UploadButton
