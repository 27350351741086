import { pathOr, type } from 'ramda'
import { Component } from 'react'
import { withStyles } from 'tss-react/mui'
import CopyBox from '../../atom/copy-box'
import { Autocomplete, Checkbox, Date, DateTime, Input, Phone, Radio, Select, SelectMultiple } from '../../atom/form'
import Conditions from '../../atom/form/conditions'

const styles = () => ({
  wrap: {
    display: 'inline-flex',
    minWidth: '5rem',
  },
})

export const displayMap = {
  hidden: ({ name, field }) => <input name={name} type="hidden" value={field.value} />,
  text: ({ ...rest }) => <Input {...rest} />,
  number: ({ ...rest }) => <Input {...rest} />,
  audio: ({ ...rest }) => <Input {...rest} />,
  date: (props) => <Date {...props} />,
  datetime: (props) => <DateTime {...props} />,
  checkbox: ({ ...rest }) => <Checkbox {...rest} />,
  radio: ({ ...rest }) => <Radio {...rest} />,
  select: (props) => <Select {...props} />,
  selectMultiple: (props) => <SelectMultiple {...props} />,
  phone: (props) => <Phone {...props} />,
  autocomplete: (props) => <Autocomplete {...props} />,
  conditions: (props) => <Conditions {...props} />,
  copyBox: (props) => {
    const copyProps = {
      title: props.label,
      text: props.value,
      inputStyle: {
        width: '400px',
      },
    }
    return <CopyBox {...copyProps} />
  },
}

class FieldDynamic extends Component {
  shouldComponentUpdate(nextProps) {
    const { field = {} } = this.props
    if (type(nextProps.field.value) === 'Array') {
      return true
    }
    return field.value !== nextProps.field.value || field.error !== nextProps.field.error
  }

  render() {
    const {
      name,
      specs = {},
      field = {},
      onChange,
      onBlur,
      onOpen = () => {},
      onClose = () => {},
      customProps = {},
    } = this.props
    const Content = pathOr(() => '', [specs.type], displayMap)
    return (
      <Content
        name={name}
        {...specs}
        {...field}
        onChange={onChange}
        onBlur={onBlur}
        onOpen={onOpen}
        onClose={onClose}
        {...customProps}
      />
    )
  }
}

export default withStyles(FieldDynamic, styles)
