import TextField from '@mui/material/TextField'
import { DateTimePicker, DateTimePickerTabs } from '@mui/x-date-pickers'
import moment from 'moment-timezone'
import React from 'react'
import { noop } from '@/app/definitions'
import { formatDate } from '@/app/service/util'
import FormControl from './form-control'
import Input from './input'

const dateFormat = 'YYYY-MMM-DD HH:mm A'

const getDateValue = (value: string, timezone?: string) => {
  const dateValue = timezone ? moment.tz(value, timezone) : moment(value)
  return !dateValue.isValid() ? undefined : dateValue
}

type DateTimeFieldProps = {
  name: string
  label?: string
  editable?: boolean
  value?: string
  error?: string
  info?: string
  onChange: (p: { name: string; value: moment.Moment | null }) => void
  onOpen?: () => void
  onClose?: () => void
  timezone?: string
  disablePast?: boolean
}

export default function DateTimeField({
  name,
  label,
  editable = true,
  value = new Date().toString(),
  error = '',
  info = '',
  onChange,
  onOpen = noop,
  onClose = noop,
  timezone,
  disablePast = true,
}: DateTimeFieldProps) {
  const ref = React.useRef(null)
  const [open, setOpen] = React.useState(false)
  const dateValue = getDateValue(value, timezone)
  if (!editable) {
    return (
      <Input
        type="text"
        name={name}
        label={label}
        editable={false}
        value={formatDate(value, timezone)}
        onChange={() => {}}
      />
    )
  }

  return (
    <FormControl error={error} info={info}>
      <div ref={ref}>
        <DateTimePicker
          disablePast={disablePast}
          format={dateFormat}
          label={label}
          open={open}
          value={dateValue}
          slots={{
            tabs: (props) => <DateTimePickerTabs {...props} />,
            textField: (props) => (
              <TextField
                {...props}
                name={name}
                InputProps={{
                  ...props.InputProps,
                  endAdornment: null,
                  name,
                }}
                style={{ width: '100%' }}
                variant="standard"
                onChange={noop}
                onClick={() => setOpen(true)}
              />
            ),
          }}
          slotProps={{
            popper: {
              anchorEl: ref.current,
            },
            tabs: {
              hidden: false,
            },
          }}
          onChange={(e) => {
            onChange({
              name,
              value: e,
            })
          }}
          onClose={() => {
            setOpen(false)
            onClose?.()
          }}
          onOpen={onOpen}
        />
      </div>
    </FormControl>
  )
}
