import { alpha, useTheme } from '@mui/material/styles'
import React, { useImperativeHandle, useRef } from 'react'

const StripeInput = React.forwardRef(({ component: Component, ...other }, ref) => {
  const theme = useTheme()

  const elementRef = useRef()
  useImperativeHandle(ref, () => ({
    focus: () => elementRef.current.focus,
  }))

  return (
    <Component
      onReady={(element) => {
        elementRef.current = element
      }}
      option={{
        style: {
          base: {
            color: theme.palette.text.primary,
            fontSize: `${theme.typography.fontSize}px`,
            fontFamily: theme.typography.fontFamily,
            '::placeholder': {
              color: alpha(theme.palette.text.primary, 0.42),
            },
          },
          invalid: {
            color: theme.palette.text.primary,
          },
        },
      }}
      {...other}
    />
  )
})

export default StripeInput
