import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import MUITableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import { History } from 'history'
import qs from 'qs'
import { map } from 'ramda'
import React, { Component, Fragment } from 'react'
import TableLayout from '@/app/component/layout/table'
import TableCell from '@/app/component/layout/table/cell'
import TableRow from '@/app/component/layout/table/row'
import createHydrated from '@/app/component/wrapper/hydratable'
import createPaginatedTable from '@/app/component/wrapper/table/paginated'
import { CampaignQuery } from '@/app/module/campaigns/types'
import CampaignTableRowActions from './actions'
import CampaignsTableRow from './row'
import CampaignsTableToolbar from './toolbar'

type Props = {
  history: History
  token: string
  orgId: number
  query: CampaignQuery
  loading: boolean
  loadingItems: string[]
  data: Record<string, unknown>
  specs: any
  headers: any
  ordered: any
  pagination: any
  page: number

  copyItem: (payload: { token: string; orgId: number; itemId: string }) => Promise<{ id: string } | Error>
  createNotification: (data: Record<string, { type: string; message: string }>) => void
  getCampaigns: (payload: { token: string; orgId: number; query: CampaignQuery }) => Promise<void>
  getData: (payload: { token: string; orgId: number; query: CampaignQuery }) => Promise<void>
  setArchivedItem: (payload: { token: string; orgId: number; itemId: string; isArchived: boolean }) => Promise<void>
  setOpenItem: (payload: { token: string; orgId: number; itemId: string; isOpen: boolean }) => void
  setPublishItem: (payload: {
    token: string
    orgId: number
    itemId: string
    isPublished: boolean
    item: {
      id: string
    }
  }) => Promise<{ id: string }>
  toggleAbortConfirm: (data: any) => void
  toggleDownloadCampaignReport: (itemId: string) => void
  onStopAllClick: (itemId: string) => void
}

type State = {}

class CampaignsTable extends Component<Props, State> {
  copyText: React.RefObject<HTMLTextAreaElement>

  constructor(props: Props) {
    super(props)
    this.copyText = React.createRef()
  }

  state = {
    toCopy: null,
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { loading, orgId, query, token, getData } = this.props
    if (loading) {
      return
    }

    const prevQuery = qs.stringify(prevProps.query)
    const newQuery = qs.stringify(query)

    if (prevQuery !== newQuery) {
      getData({
        token,
        orgId,
        query,
      })
    }
  }

  onLoad(filters: any) {
    const { token, orgId, query, getData } = this.props
    const f = map((filter) => (filter ? false : 'false'), filters)
    getData({
      token,
      orgId,
      query,
      ...f,
    })
  }

  onCreateCampaignsClick = () => {
    const { history } = this.props
    history.push('/campaigns/create')
  }

  onSetFilters = ({ filters }: { filters: CampaignQuery }) => {
    const { history } = this.props
    const q = Object.keys(filters).reduce(
      (obj: CampaignQuery, key: string) => {
        if (!filters[key as keyof CampaignQuery] || key === 'page') {
          return obj
        }
        return {
          ...obj,
          [key]: filters[key as keyof CampaignQuery],
        }
      },
      {
        page: 1, // reset page every time a new filter is set
        size: filters.size,
      },
    )

    history.push(`/campaigns?${qs.stringify(q)}`)
    const { token, orgId, getData } = this.props
    getData({
      token,
      orgId,
      query: {
        ...filters,
        page: 1,
      },
    })
  }

  async setPublish(id: string, value: boolean, isEdit: boolean) {
    const { token, orgId, setPublishItem } = this.props
    const response = await setPublishItem({
      token,
      orgId,
      itemId: id,
      isPublished: value,
      item: {
        id,
      },
    })
    if (isEdit) {
      const { history } = this.props
      history.push(`/campaigns/edit/${response.id}`)
    }
  }

  setOpen(id: string, value: boolean) {
    const { token, orgId, setOpenItem } = this.props
    setOpenItem({
      token,
      orgId,
      itemId: id,
      isOpen: value,
    })
  }

  setArchived(id: string, value: boolean) {
    const { token, orgId, setArchivedItem, query, getCampaigns } = this.props
    setArchivedItem({
      token,
      orgId,
      itemId: id,
      isArchived: value,
    }).then(() => {
      getCampaigns({ token, orgId, query })
    })
  }

  copy(id: string) {
    const { token, orgId, query, history, copyItem, getData } = this.props

    copyItem({
      token,
      orgId,
      itemId: id,
    }).then((res) => {
      if (!(res instanceof Error)) {
        getData({ token, orgId, query })
        history.push(`/campaigns/edit/${res.id}`)
      }
    })
  }

  copyId(id: string) {
    this.setState(
      {
        toCopy: id,
      },
      () => {
        this.copyText.current?.select()
        navigator.clipboard.writeText(id).then(() => {
          this.props.createNotification({
            'copy-id': {
              type: 'success',
              message: 'Campaign ID copied!',
            },
          })
          this.setState({
            toCopy: null,
          })
        })
      },
    )
  }

  render() {
    const {
      data,
      headers,
      history,
      loading,
      loadingItems = [],
      ordered,
      page,
      pagination,
      query,
      specs,
      toggleAbortConfirm,
      toggleDownloadCampaignReport,
      onStopAllClick,
    } = this.props
    return (
      <Fragment>
        <CampaignsTableToolbar
          filters={query}
          loading={loading}
          page={page}
          onCreateCampaignsClick={this.onCreateCampaignsClick}
          onLoad={(f: any) => this.onLoad(f)}
          onSetFilters={this.onSetFilters}
        />
        <TableLayout
          loading={loading}
          pageSize={ordered.length}
          pagination={pagination}
          emptyMessage="No campaigns were found."
          style={{
            paddingRight: '128px',
          }}
        >
          <Table className="campaigns-table">
            <TableHead>
              <TableRow>
                {headers.map(({ title }: any, i: number) => (
                  <TableCell key={i} index={i}>
                    {title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ordered.map((itemId: string) => (
                <CampaignsTableRow
                  key={itemId}
                  loading={loadingItems.indexOf(itemId) > -1}
                  id={itemId}
                  headers={headers}
                  data={data[itemId]}
                  specs={specs}
                />
              ))}
            </TableBody>
          </Table>
          <Table
            className="campaign-actions-table"
            style={{
              position: 'absolute',
              background: 'white',
              top: '0px',
              right: '0px',
              width: '50px',
            }}
          >
            <TableHead>
              <TableRow>
                <MUITableCell
                  style={{
                    height: '69px',
                  }}
                >
                  Actions
                </MUITableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordered.map((itemId: string) => (
                <TableRow isLoading={loading} key={itemId}>
                  <MUITableCell
                    style={{
                      height: '69px',
                      boxShadow: '-1px 0px 1px 0px rgba(224,224,224,1)',
                    }}
                  >
                    <div style={{ display: 'flex', maxWidth: '96px' }}>
                      <CampaignTableRowActions
                        data={data[itemId]}
                        actions={{
                          publish: () => this.setPublish(itemId, true, false),
                          unpublish: () => this.setPublish(itemId, false, false),
                          unpublishThenEdit: () => this.setPublish(itemId, false, true),
                          archive: () => this.setArchived(itemId, true),
                          unarchive: () => this.setArchived(itemId, false),
                          abort: () => toggleAbortConfirm(data[itemId]),
                          open: () => this.setOpen(itemId, true),
                          close: () => this.setOpen(itemId, false),
                          downloadCampaignReport: () => toggleDownloadCampaignReport(itemId),
                          copy: () => this.copy(itemId),
                          copyId: () => this.copyId(itemId),
                          manage: () => history.push(`/campaigns/manage/${itemId}`),
                          summary: () => history.push(`/campaigns/summary/${itemId}/overview`),
                          edit: () => history.push(`/campaigns/edit/${itemId}/content`),
                          stopAll: () => onStopAllClick(itemId),
                        }}
                      />
                    </div>
                  </MUITableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableLayout>
        {this.state.toCopy && <textarea value={this.state.toCopy} ref={this.copyText} readOnly />}
      </Fragment>
    )
  }
}

export default createPaginatedTable(createHydrated(CampaignsTable))
