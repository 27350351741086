import { pathOr } from 'ramda'
import { PureComponent } from 'react'
import MessageInput from '@/app/module/logs/component/send/message'
import { CAMPAIGN_CHANGE_DELAY } from '@/app/module/campaigns/definitions'
import { LanguageType } from '@/app/module/campaigns/helpers'
import { Message, PersonalizationType } from '@/app/module/campaigns/types'
import LanguageWrapper from './language-wrapper'

type Props = {
  defaultLanguage: string
  info: string
  item: Message
  label?: string
  languages: LanguageType[]
  personalizationList: PersonalizationType

  changeHandler: (item: Partial<Message>, changed?: boolean) => void
}

type State = {
  error: string
  text: string
  timeout: any
}

export default class CampaignContentSMS extends PureComponent<Props, State> {
  state = {
    timeout: null,
    text: pathOr('', ['item', 'message', 'text'], this.props),
    error: '',
  }

  item: Partial<Message> | null = null

  componentWillUnmount() {
    const { timeout } = this.state
    if (timeout) {
      clearTimeout(timeout)
    }
    if (this.item) {
      this.props.changeHandler(this.item)
    }
  }

  changeHandler = (item: Partial<Message> = {}) => {
    const { changeHandler } = this.props

    if (this.state.timeout) {
      clearTimeout(this.state.timeout)
    }

    this.item = item
    this.setState({
      error: this.state.text ? '' : this.state.error,
      timeout: setTimeout(() => {
        changeHandler(item)
        this.item = null
        this.setState((state) => ({
          timeout: clearTimeout(state.timeout),
        }))
      }, CAMPAIGN_CHANGE_DELAY),
    })
  }

  render() {
    const { item, label, personalizationList, info, languages, defaultLanguage } = this.props

    return (
      <div>
        <div>
          <div className="campaign-item-content">
            <LanguageWrapper
              item={item}
              languages={languages}
              defaultLanguage={defaultLanguage}
              info={info}
              onChange={this.changeHandler}
              type="message"
              content="text"
            >
              {(text: string, onTextChange: (payload: { message: string }) => void, error: string) => (
                <MessageInput
                  autoFocus={item.autoFocus}
                  label={label}
                  message={text}
                  error={error || this.state.error}
                  personalizationList={personalizationList}
                  onChange={onTextChange}
                />
              )}
            </LanguageWrapper>
          </div>
        </div>
      </div>
    )
  }
}
