import React from 'react'
import { useSelector } from 'react-redux'
import { selectUserTimezone } from '@/app/module/user/store/selectors'
import { selectCountriesTimezones, selectDefaultCountryCode } from '@/app/module/utils/store/selectors'
import { pathOr } from 'ramda'
import { OrgReportFilter } from '@/app/module/reports/types'
import { noop } from '@/app/definitions'
import OrganizationReportFilter from './organization-report-filter'

type Props = {
  filter: OrgReportFilter
}

const ReportFileFilterContainer: React.FC<Props> = ({ filter }) => {
  const countriesTimezones = useSelector(selectCountriesTimezones)
  const countryCode = useSelector(selectDefaultCountryCode)
  const timezone = useSelector(selectUserTimezone)
  const timezones = useSelector(pathOr({}, ['utils', 'countriesTimezonesList', 'data']))

  return (
    <OrganizationReportFilter
      campaignOptions={[]}
      campaignsLoading={false}
      countriesTimezones={countriesTimezones}
      countryCode={countryCode}
      disabled
      filter={filter}
      timezone={timezone}
      timezones={timezones}
      onCampaignsSearch={noop}
    />
  )
}

export default ReportFileFilterContainer
