import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import cookies from 'js-cookie'
import { pick } from 'ramda'
import React from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { FixMeLater, FunctionType, HistoryType } from '@/config/types'
import Loading from '@/app/component/guard/loading'
import validate, { hasRequiredError } from '@/app/service/validate'
import SignupAccount from '@/app/module/auth/component/signup/account'
import SignupProfile from '@/app/module/auth/component/signup/profile'

type Props = {
  initialized: boolean
  invitationToken: string | null
  token: string
  checkInvitation: FunctionType
  acceptInvitation: FunctionType
  acceptInvitationWithSignup: FunctionType
  loading: boolean
  logout: FunctionType
  history: HistoryType
  url: string
  specs: {
    profile: FixMeLater
    account: FixMeLater
  }
}

type InvitationResponse = {
  email: string
  firstName: string
  lastName: string
  created: string
  orgId: number
  orgName: string
  existingUser: boolean
  loggedIn: boolean
  loggedInSame: boolean
}

const profileValidation = {
  firstName: validate(hasRequiredError),
  lastName: validate(hasRequiredError),
  country: validate(hasRequiredError),
}

const profileHeaders = [
  {
    fields: ['firstName'],
  },
  {
    fields: ['lastName'],
  },
  {
    fields: ['country'],
  },
]

const ComponentMap = {
  LoggedInSame: ({ props, invitationResponse }: { props: Props; invitationResponse: InvitationResponse }) => (
    <div
      style={{
        padding: '90px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <MessageText>
          You have been invited to the organization {invitationResponse.orgName} ({invitationResponse.orgId}). If you
          want to join that organization, click accept below.
        </MessageText>
        <div style={{ paddingTop: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              props
                .acceptInvitation({ token: props.token, invitationToken: props.invitationToken })
                .then(() => props.history.push('/dashboard'))
            }
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  ),
  LoggedIn: ({ props }: { props: Props; invitationResponse: InvitationResponse }) => (
    <div
      style={{
        padding: '90px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <MessageText>
          You are logged in with a different account than the invited account. Please log out first and then open the
          invite link again.
        </MessageText>
        <div style={{ paddingTop: '15px' }}>
          <Button variant="contained" color="primary" onClick={props.logout}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  ),
  LoggedOutExisting: ({ props, invitationResponse }: { props: Props; invitationResponse: InvitationResponse }) => {
    cookies.set('redirectURL', props.url, { sameSite: 'strict', secure: true })
    return (
      <div
        style={{
          padding: '90px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <MessageText>
            You have been invited to the organization {invitationResponse.orgName} ({invitationResponse.orgId}). If you
            want to join that organization, you have to login first.
          </MessageText>
          <div style={{ paddingTop: '15px' }}>
            <Button variant="contained" color="primary" onClick={() => props.history.push('/login')}>
              Login
            </Button>
          </div>
        </div>
      </div>
    )
  },
  LoggedOutNew: ({ props, invitationResponse }: { props: Props; invitationResponse: InvitationResponse }) => {
    const [step, setStep] = React.useState(1)
    const [data, setData] = React.useState({})
    const { executeRecaptcha } = useGoogleReCaptcha()

    const handleReCaptchaVerify = React.useCallback(async () => {
      const token = await executeRecaptcha?.('signup')
      setData((s) => ({
        ...s,
        verify_token: token,
      }))
    }, [executeRecaptcha])

    React.useEffect(() => {
      handleReCaptchaVerify()
    }, [handleReCaptchaVerify])

    return (
      <div
        style={{
          padding: '90px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <MessageText>
            You have been invited to the organization {invitationResponse.orgName} ({invitationResponse.orgId}). If you
            want to join that organization, you have to sign up first.
          </MessageText>
          <div style={{ paddingTop: '15px' }}>
            {step === 1 && (
              <SignupProfile
                data={{ ...pick(['firstName', 'lastName'], invitationResponse), ...data }}
                validation={profileValidation}
                specs={props.specs.profile}
                headers={profileHeaders}
                onSubmit={(newData: any) => {
                  setData({
                    first_name: newData.firstName,
                    last_name: newData.lastName,
                    country: newData.country,
                  })
                  setStep(2)
                }}
              />
            )}
            {step === 2 && (
              <SignupAccount
                data={pick(['email'], invitationResponse)}
                specs={{
                  ...props.specs.account,
                  email: {
                    type: 'text',
                    editable: false,
                    inputProps: {
                      type: 'email',
                    },
                    label: 'Email',
                  },
                }}
                onBack={() => {
                  setStep(1)
                }}
                onSubmit={(newData: any) => {
                  setData({
                    ...data,
                    ...newData,
                  })
                  props
                    .acceptInvitationWithSignup({
                      item: {
                        ...data,
                        ...newData,
                        token: props.invitationToken,
                      },
                    })
                    .then(() => {
                      props.history.push('/dashboard')
                    })
                }}
              />
            )}
          </div>
        </div>
      </div>
    )
  },
  Invalid: () => (
    <div
      style={{
        padding: '90px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <MessageText>
          The invitation is invalid. Make sure you're opening a valid active invitation. If you need further assistance,
          kindly contact customer support.
        </MessageText>
      </div>
    </div>
  ),
}

type ComponentKey = keyof typeof ComponentMap

export default function Invitation(props: Props) {
  const { invitationToken, token, initialized, checkInvitation, loading } = props

  const [state, setState] = React.useState('')
  const [statusChecked, setStatusChecked] = React.useState(false)
  const [invitationResponse, setInvitationResponse] = React.useState<InvitationResponse | {}>({})

  React.useEffect(() => {
    if (invitationToken && initialized && (!statusChecked || !token)) {
      setStatusChecked(true)
      checkInvitation({ invitationToken, token }).then((res: InvitationResponse) => {
        if (!(res instanceof Error)) {
          setInvitationResponse(res)
          if (res.loggedInSame) {
            setState('LoggedInSame')
          } else if (res.loggedIn) {
            setState('LoggedIn')
          } else if (res.existingUser) {
            setState('LoggedOutExisting')
          } else {
            setState('LoggedOutNew')
          }
        } else {
          setState('Invalid')
        }
      })
    }
  }, [invitationToken, initialized, statusChecked, token, checkInvitation])

  const Component: any = ComponentMap[state as ComponentKey]

  return (
    <Loading isLoading={loading}>
      {state && <Component props={props} invitationResponse={invitationResponse} />}
    </Loading>
  )
}

const MessageText = ({ children }: any) => (
  <Typography className="invitation-message-text" variant="h5">
    {children}
  </Typography>
)
