import { Drawer, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { CAMPAIGN_SIDEBAR_WIDTH } from '@/app/module/campaigns/definitions'

type CampaignSidebarOption = {
  active?: boolean
  icon?: string
  label: string
  name: string
  right?: React.ReactNode
  onClick: () => void
}

type Props = {
  campaignName: string
  options: CampaignSidebarOption[]
  footer?: React.ReactNode
}

const CampaignSidebar: React.FC<Props> = ({ campaignName, footer, options }) => (
  <Drawer
    anchor="left"
    sx={{
      flexShrink: 0,
      justifyContent: 'center',
      width: CAMPAIGN_SIDEBAR_WIDTH,

      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        paddingTop: '64px',
        width: CAMPAIGN_SIDEBAR_WIDTH,
      },
    }}
    variant="permanent"
  >
    <List id="campaign-editor-sidebar">
      <ListItem disablePadding divider>
        <ListItemButton disabled disableRipple>
          <Typography className="campaignTitle" sx={{}} variant="subtitle1">
            {campaignName}
          </Typography>
        </ListItemButton>
      </ListItem>
      {options.map((option, i) => (
        <ListItem key={i} disablePadding divider secondaryAction={option.right}>
          <ListItemButton
            className={`campaign-sidebar-option option-${i}`}
            data-testid={`campaign-sidebar-option-${option.name}`}
            selected={option.active}
            onClick={option.onClick}
          >
            {!!option.icon && (
              <ListItemIcon>
                <Icon>{option.icon}</Icon>
              </ListItemIcon>
            )}
            <ListItemText primary={option.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
    {footer}
  </Drawer>
)

export default CampaignSidebar
