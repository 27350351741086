import CallIcon from '@mui/icons-material/Call'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import { pathOr } from 'ramda'
import React from 'react'
import { FunctionType } from '@/config/types'
import Tooltip from '@/app/component/atom/tooltip'
import Loading from '@/app/component/guard/loading'
import FormLayout from '@/app/component/layout/form'
import validate, { hasPhoneError, hasRequiredError } from '@/app/service/validate'
import { LoadingError } from '@/app/types'
import { ContactValues } from '@/app/module/contacts/types'

type Props = {
  token?: string
  orgId: number
  itemId?: number
  submit: FunctionType
  onExit: FunctionType
  loading: boolean
  error?: LoadingError
  headers: {
    label: string
    key: string
    visible: boolean
    fields: string[]
    editable: boolean
    id: number
  }[]
  specs: any // left at any because the structure is dyanamic. It's based on the contact's custom fields
  data: ContactValues
  toggleCallDialog: FunctionType
  getCustomFields: FunctionType
  getContactsItem: FunctionType
  activeFilter?: { name: string; value: string }
  page: number
  searchTerm: string
}

function ContactsItem({
  loading,
  error,
  headers,
  specs,
  data,
  toggleCallDialog,
  itemId,
  token,
  orgId,
  getCustomFields,
  getContactsItem,
  activeFilter,
  submit,
  onExit,
}: Props) {
  const [addToGroupAfterSaving, setAddToGroupAfterSaving] = React.useState(activeFilter?.name === 'groups')

  React.useEffect(() => {
    if (token && orgId) {
      if (itemId) {
        getContactsItem({ token, orgId, itemId })
      }
      getCustomFields({ token, orgId })
    }
  }, [itemId, orgId, token, getContactsItem, getCustomFields])

  const submitHandler = ({ item }: any) => {
    const phone = pathOr('', ['fullPhoneNumber'])
    let synthPhone = phone(item)
    synthPhone = synthPhone.replace(/\s/g, '')
    const synthItem = { ...item }
    synthItem.fullPhoneNumber = synthPhone

    submit({
      token,
      orgId,
      item: {
        id: itemId,
        ...synthItem,
      },
      // eslint-disable-next-line consistent-return
    }).then((res: any) => {
      if (!(res instanceof Error)) {
        if (itemId || !addToGroupAfterSaving) {
          onExit()
        } else {
          onExit(res.id)
        }
      }
    })
  }

  return (
    <Loading isLoading={loading} error={error} testId="contact-item">
      {itemId && data ? (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            textAlign: 'right',
            marginTop: '8px',
            paddingRight: '20px',
          }}
        >
          <Button
            id="item-contact-call-button"
            style={{ zIndex: 999 }}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() =>
              toggleCallDialog({
                contactId: itemId,
                contactName: data.firstName || `contact (${itemId})`,
                isOpen: true,
              })
            }
            startIcon={<CallIcon />}
          >
            <span
              style={{
                maxWidth: '150px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Call {data.firstName || data.fullPhoneNumber}
            </span>
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginBottom: '-30px',
            paddingRight: '10px',
          }}
        >
          <Tooltip title="You'll be able to select the group(s) after saving the contact.">
            <FormControlLabel
              control={
                <Checkbox
                  id="add-edit-contact-add-to-group-checkbox"
                  checked={addToGroupAfterSaving}
                  onChange={(e) => setAddToGroupAfterSaving(e.currentTarget.checked)}
                />
              }
              label="Also add this contact to a group(s)"
            />
          </Tooltip>
        </div>
      )}
      <FormLayout
        loading={loading}
        headers={headers}
        config={specs}
        values={data}
        submitLabel="Save"
        validation={{
          fullPhoneNumber: validate(hasRequiredError, hasPhoneError),
        }}
        id="contact-item-form"
        onSubmit={({ item }: any) => submitHandler({ item })}
        Actions={({ changed, hasErrors }: any) => (
          <Box
            sx={{
              position: 'fixed',
              gap: 2,
              bottom: 0,
              left: 0,
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              zIndex: 2,
              bgcolor: 'background.paper',
              py: 1,
              px: 2,
            }}
          >
            <div style={{ maxWidth: '850px', margin: 'auto' }}>
              <Button variant="outlined" id="contact-back-button" onClick={() => onExit()}>
                Back
              </Button>
              <Button
                id="contact-save-button"
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading || !changed || hasErrors}
              >
                Save
              </Button>
            </div>
          </Box>
        )}
      />
      <div style={{ width: '100%', height: '2rem' }} />
    </Loading>
  )
}

export default ContactsItem
