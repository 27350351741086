import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import React from 'react'
import { FunctionType } from '@/config/types'
import Tooltip from '@/app/component/atom/tooltip'
import { PersonalizationType, SinglePersonalizationType } from '@/app/module/campaigns/types'

type AddButtonProps = {
  disabled?: boolean
  icon: string
  text: string
  tooltip: string
  onClick: FunctionType
}

export const CampaignAddButton: React.FC<AddButtonProps> = ({ disabled, icon, text, tooltip, onClick }) => (
  <Tooltip title={<span style={{ display: 'block', textAlign: 'center' }}>{tooltip}</span>}>
    <div>
      <Button
        className="campaign-item-content-add-button"
        color={'primary'}
        disabled={disabled}
        style={{
          minWidth: '0',
          padding: '8px',
          margin: 'auto',
        }}
        startIcon={<Icon>{icon}</Icon>}
        variant="contained"
        onClick={onClick}
      >
        {text}
      </Button>
    </div>
  </Tooltip>
)

export const SUPPORTED_ACTION_TYPES = [
  'sms',
  'sendSMSToPhoneNumbers',
  'transfer',
  'api',
  'topup',
  'updateContact',
  'sendEmail',
  'sendWhatsapp',
  'voice',
  'subscribeToCampaign',
]

export const mapPZBy = (personalizationList: PersonalizationType, key: keyof SinglePersonalizationType) => {
  const keys = Object.keys(personalizationList)

  return keys.reduce<Record<string, SinglePersonalizationType>>(
    (map, k: string) => ({
      ...map,
      ...personalizationList[k].reduce(
        (obj, pzItem) => ({
          ...obj,
          [pzItem[key]]: pzItem,
        }),
        {},
      ),
    }),
    {},
  )
}
