export const ACTION_AUTH_LOGIN_REQUEST = '@action/AUTH/LOGIN_REQUEST'
export const ACTION_AUTH_LOGIN_SUCCESS = '@action/AUTH/LOGIN_SUCCESS'
export const ACTION_AUTH_LOGIN_FAILURE = '@action/AUTH/LOGIN_FAILURE'

export const ACTION_AUTH_SIGNUP_REQUEST = '@action/AUTH/SIGNUP_REQUEST'
export const ACTION_AUTH_SIGNUP_SUCCESS = '@action/AUTH/SIGNUP_SUCCESS'
export const ACTION_AUTH_SIGNUP_FAILURE = '@action/AUTH/SIGNUP_FAILURE'

export const ACTION_AUTH_SIGNUP_EMAIL_REQUEST = '@action/AUTH/SIGNUP_EMAIL_REQUEST'
export const ACTION_AUTH_SIGNUP_EMAIL_SUCCESS = '@action/AUTH/SIGNUP_EMAIL_SUCCESS'
export const ACTION_AUTH_SIGNUP_EMAIL_FAILURE = '@action/AUTH/SIGNUP_EMAIL_FAILURE'

export const ACTION_AUTH_SIGNIN_REQUEST = '@action/AUTH/SIGNIN_REQUEST'
export const ACTION_AUTH_SIGNIN_SUCCESS = '@action/AUTH/SIGNIN_SUCCESS'
export const ACTION_AUTH_SIGNIN_FAILURE = '@action/AUTH/SIGNIN_FAILURE'

export const ACTION_AUTH_RESET_PASSWORD_REQUEST = '@action/AUTH/RESET_PASSWORD_REQUEST'
export const ACTION_AUTH_RESET_PASSWORD_SUCCESS = '@action/AUTH/RESET_PASSWORD_SUCCESS'
export const ACTION_AUTH_RESET_PASSWORD_FAILURE = '@action/AUTH/RESET_PASSWORD_FAILURE'

export const ACTION_AUTH_REQUEST_RESET_PASSWORD_REQUEST = '@action/AUTH/REQUEST_RESET_PASSWORD_REQUEST'
export const ACTION_AUTH_REQUEST_RESET_PASSWORD_SUCCESS = '@action/AUTH/REQUEST_RESET_PASSWORD_SUCCESS'
export const ACTION_AUTH_REQUEST_RESET_PASSWORD_FAILURE = '@action/AUTH/REQUEST_RESET_PASSWORD_FAILURE'

export const ACTION_AUTH_SIGNUP_EMAIL_CHECK_REQUEST = '@action/AUTH/SIGNUP_EMAIL_CHECK_REQUEST'
export const ACTION_AUTH_SIGNUP_EMAIL_CHECK_SUCCESS = '@action/AUTH/SIGNUP_EMAIL_CHECK_SUCCESS'
export const ACTION_AUTH_SIGNUP_EMAIL_CHECK_FAILURE = '@action/AUTH/SIGNUP_EMAIL_CHECK_FAILURE'

export const ACTION_AUTH_VERIFY_OTP_REQUEST = '@action/AUTH/VERIFY_OTP_REQUEST'
export const ACTION_AUTH_VERIFY_OTP_SUCCESS = '@action/AUTH/VERIFY_OTP_SUCCESS'
export const ACTION_AUTH_VERIFY_OTP_FAILURE = '@action/AUTH/VERIFY_OTP_FAILURE'

export const ACTION_AUTH_VERIFY_RECOVERY_CODE_REQUEST = '@action/AUTH/VERIFY_RECOVERY_CODE_REQUEST'
export const ACTION_AUTH_VERIFY_RECOVERY_CODE_SUCCESS = '@action/AUTH/VERIFY_RECOVERY_CODE_SUCCESS'
export const ACTION_AUTH_VERIFY_RECOVERY_CODE_FAILURE = '@action/AUTH/VERIFY_RECOVERY_CODE_FAILURE'

export const ACTION_AUTH_CHECK_INVITATION_REQUEST = '@action/AUTH/CHECK_INVITATION_REQUEST'
export const ACTION_AUTH_CHECK_INVITATION_SUCCESS = '@action/AUTH/CHECK_INVITATION_SUCCESS'
export const ACTION_AUTH_CHECK_INVITATION_FAILURE = '@action/AUTH/CHECK_INVITATION_FAILURE'

export const ACTION_AUTH_ACCEPT_INVITATION_REQUEST = '@action/AUTH/ACCEPT_INVITATION_REQUEST'
export const ACTION_AUTH_ACCEPT_INVITATION_SUCCESS = '@action/AUTH/ACCEPT_INVITATION_SUCCESS'
export const ACTION_AUTH_ACCEPT_INVITATION_FAILURE = '@action/AUTH/ACCEPT_INVITATION_FAILURE'

export const ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_REQUEST = '@action/AUTH/ACCEPT_INVITATION_WITH_SIGNUP_REQUEST'
export const ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_SUCCESS = '@action/AUTH/ACCEPT_INVITATION_WITH_SIGNUP_SUCCESS'
export const ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_FAILURE = '@action/AUTH/ACCEPT_INVITATION_WITH_SIGNUP_FAILURE'

export const ACTION_SET_TRIAL_MODE = '@action/AUTH/SET_TRIAL_MODE'

export const ACTION_AUTH_LOGOUT = '@action/AUTH/LOGOUT'
export const ACTION_AUTH_SIGNUP_DATA_MOD = '@action/AUTH/SIGNUP_DATA_MOD'
export const ACTION_SET_OTP = '@action/AUTH/SET_OTP'

export const REQUEST_RESET_PASSWORD_SUCCESS =
  'We have sent you an e-mail with your password reset url. Please contact us if you do not receive it within a few minutes.'
export const RESET_PASSWORD_SUCCESS = 'New password set successfully. Login now using your email and new password.'

export const ERROR_GET_AUTH_SERVER = 'Authentication failed. Please try logging in again.'
export const ERROR_GET_AUTH_CLIENT = 'Invalid credentials. Please try again.'
export const ERROR_AUTH_SIGNUP = 'We could not process your request for signing up. Please try again later.'
export const SUCCESS_AUTH_SIGNUP =
  'Your account has been successfully created. Please login to start using our platform.'
export const ERROR_AUTH_SIGNUP_CAPTCHA =
  'Google captcha thinks you might be a bot. Please turn off any adblockers and try again (or try in a browser without any extensions installed). If the problem persists, please reach out for help.'
export const ERROR_AUTH_SIGNUP_EMAIL_FAILURE = 'The email address is already registered.'
export const ERROR_RESET_PASSWORD_FAILURE =
  'Resetting password failed. Please try again. If the problem persists, please reach out for help.'
export const ERROR_REQUEST_RESET_PASSWORD_FAILURE =
  'Resetting password request failed. Please try again. If the problem persists, please reach out for help.'
export const ERROR_SERVER_AUTH_SIGNUP_EMAIL = 'We could not verify your details. Please try again.'
export const ERROR_AUTH_LOAD_VERIFY = 'Could not load captcha verification library. Please try again later.'
export const ERROR_AUTH_VERIFY_OTP = 'Two-factor authentication failed. Please check your code or try again later.'
export const ERROR_AUTH_VERIFY_RECOVERY_CODE =
  'Recovery code authentication failed. Please check your code or try again later.'
export const ERROR_SET_TRIAL_MODE = 'Error setting trial mode. Please try again later.'
export const ERROR_CHECK_INVITATION = 'Error verifying your invitation. Please try again later.'
export const ERROR_ACCEPT_INVITATION = 'Error accepting your invitation. Please try again later.'
export const ERROR_ACCEPT_INVITATION_WITH_SIGNUP =
  'Error singing up and accepting your invitation. Please try again later.'
