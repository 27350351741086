import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { ClassesType, FixMeLater, RouterHistory } from '@/config/types'
import { formatPhoneNumber } from '@/app/module/conversations/format-helpers'
import { ContactType, CustomFieldType, GroupType, SubscriptionType } from '@/app/module/conversations/types'

const useStyle = makeStyles()((theme) => ({
  infoWrapper: {
    backgroundColor: theme.palette.highlight.light,
    paddingBottom: '1px',
  },
  infoCard: {
    backgroundColor: theme.palette.background.default,
    padding: '10px 20px',
    marginBottom: '5px',
  },
  infoTitle: {
    textAlign: 'center',
  },
  cardTitle: {
    textAlign: 'left',
    marginBottom: '5px',
  },
  dataList: {
    maxHeight: '200px',
    overflowY: 'scroll',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 2px',
    borderBottom: `1px solid ${theme.palette.border.main}`,
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.highlight.light,
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}))

type Props = {
  contactInfo: ContactType
  history: RouterHistory
}

export default function ContactInfo(props: Props) {
  const { classes } = useStyle()
  const { contactInfo, history } = props
  const {
    contactId,
    firstName,
    lastName,
    countryCode,
    fullPhoneNumber,
    groups,
    segments,
    customFields,
    subscriptions,
  } = contactInfo
  return (
    <div className={classes.infoWrapper}>
      <div className={classes.infoCard}>
        <div className={classes.infoTitle}>
          <Typography variant="subtitle2">
            {firstName} {lastName}
          </Typography>
          <Typography variant="h6">{formatPhoneNumber(contactId, countryCode, fullPhoneNumber)}</Typography>
        </div>
      </div>
      <Section
        history={history}
        title="Custom Fields"
        data={customFields}
        isCustomField={true}
        classes={classes}
        url={null}
      />
      <Section
        history={history}
        title="Groups"
        data={groups}
        isCustomField={false}
        classes={classes}
        url={(id: number) => `/contacts/?filterType=groups&filterId=${id}&page=1`}
      />
      <Section
        history={history}
        title="Segments"
        data={segments}
        isCustomField={false}
        classes={classes}
        url={(id: number) => `/contacts/?filterType=segments&filterId=${id}&page=1`}
      />
      <Section
        history={history}
        title="Active Campaigns"
        data={subscriptions}
        isCustomField={false}
        classes={classes}
        url={(id: number) => `campaigns/summary/${id}/overview`}
      />
    </div>
  )
}

type SectionProps = {
  data: GroupType[] | CustomFieldType[] | SubscriptionType[]
  title: string
  isCustomField: boolean
  classes: ClassesType
  url: FixMeLater
  history: RouterHistory
}

const Section = ({ data, title, isCustomField, classes, url, history }: SectionProps) => (
  <>
    {data && data.length > 0 ? (
      <div className={classes.infoCard}>
        <div className={classes.cardTitle}>
          <Typography variant="h6" color="primary">
            {title} ({data.length})
          </Typography>
        </div>
        <div className={classes.dataList}>
          {(data as Array<GroupType | CustomFieldType | SubscriptionType>).map((element: any, index) => (
            <React.Fragment key={index}>
              {isCustomField ? (
                <div>
                  <Typography variant="subtitle2" color="textSecondary">
                    <strong>{element.name}:</strong> {element.value}
                  </Typography>
                </div>
              ) : (
                <div
                  className={classes.listItem}
                  onClick={() => {
                    history.push(url(element.id))
                  }}
                >
                  <Typography variant="subtitle2" color="textSecondary">
                    {element.name}
                  </Typography>
                  <IconButton size="small">
                    <Icon>chevron_right</Icon>
                  </IconButton>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    ) : (
      <div className={classes.infoCard}>
        <div className={classes.cardTitle}>
          <Typography variant="h6" color="primary">
            {title} (0)
          </Typography>
        </div>
      </div>
    )}
  </>
)
