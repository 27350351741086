/* eslint-disable import/prefer-default-export */
import { cannot } from '@/app/helpers'
import { PartFamily } from '@/app/module/campaigns/types'

export const getTimePrefixLabel = (messageType: PartFamily) => {
  switch (messageType) {
    case PartFamily.API:
      return 'make this API request'
    case PartFamily.Action:
      return 'do actions'
    case PartFamily.CATI:
      return 'send this message'
    case PartFamily.SMS:
      return 'send this message'
    case PartFamily.Topup:
      return 'do this topup'
    case PartFamily.Voice:
      return 'send this message'
    case PartFamily.WhatsApp:
      return 'send this message'
    default:
      return cannot(messageType)
  }
}
