import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import CustomTooltip from '@/app/component/atom/tooltip'
import { formatDT, numberWithCommas } from '@/app/service/util/format'
import { usageLevel } from '@/app/module/dashboard/store/selectors'
import ExternalLink from '@/app/component/atom/external-link'
import { UserRole } from '@/app/types'
import React from 'react'
import { OrgPlan } from '@/app/module/plans/types'
import { getPlanCountries, isPlanLegacy } from '@/app/module/plans/utils'
import { green } from '@mui/material/colors'
import numbersRow from './numbersRow'
import PlanBlock from './planBlock'
import DashboardList, { DasboardItem } from './dashboard-list'

type Props = {
  isLoading: boolean
  maxContactsIncluded: number
  plan?: OrgPlan
  role: UserRole
  usage: { contacts: string }
  setPlansToggle: (toggle: boolean) => void
}

const ActivePlan: React.FC<Props> = ({ setPlansToggle, plan, usage, isLoading = false, role }) => {
  const trialDaysLeft = plan?.trialDaysLeft || 0
  const isLegacy = plan && isPlanLegacy(plan)
  const isTrial = !plan?.planId

  const items = React.useMemo<DasboardItem[]>(() => {
    if (!plan) {
      return []
    }

    return [
      getContactsDashboardItem(plan, usage.contacts),
      {
        label: 'User accounts included',
        value: plan.usersIncluded || 1,
      },
      {
        label: 'Countries included',
        value: getPlanCountries(plan.countriesIncluded).value,
      },
    ]
  }, [plan, usage.contacts])

  return (
    <PlanBlock
      isLoading={isLoading}
      title={`Active Plan: ${plan?.label || ''}`}
      subtitle={!isTrial && isLegacy ? `resets on ${formatDT(plan?.nextBillingDate || '')}` : ''}
      action={
        <CustomTooltip title={role !== 'owner' ? 'Only the owner of an organization can do this.' : ''}>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={role !== 'owner'}
              onClick={() => {
                setPlansToggle(true)
              }}
            >
              {isTrial ? 'Select' : 'Change'} Plan
            </Button>
          </div>
        </CustomTooltip>
      }
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: !isTrial ? 'flex-start' : 'space-between',
      }}
    >
      {!isTrial && <DashboardList items={items} />}
      {isTrial && (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            padding: 15,
          }}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Typography variant="h5" color="secondary">
              {trialDaysLeft > -1 ? (
                <>
                  Your trial will expire in {trialDaysLeft} day{trialDaysLeft !== 1 ? 's' : ''}
                </>
              ) : (
                <>You do not have an active subscription. Choose one now to engage your contacts.</>
              )}
            </Typography>
            <CustomTooltip title={role !== 'owner' ? 'Only the owner of an organization can do this.' : ''}>
              <div>
                <Button
                  style={{ marginTop: '15px' }}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    setPlansToggle(true)
                  }}
                >
                  Select a Plan Now
                </Button>
              </div>
            </CustomTooltip>
          </div>
        </div>
      )}
    </PlanBlock>
  )
}

const getContactsDashboardItem = (
  plan: OrgPlan,
  contactsUsage: string,
): { label: React.ReactNode; value: React.ReactNode } => {
  const isLegacy = isPlanLegacy(plan)

  if (isLegacy) {
    return {
      label: (
        <>
          Monthly active contacts&nbsp;
          <CustomTooltip
            interactive
            title={
              <>
                You can store an unlimited number of contacts in your account. This number is how many contacts you can
                interact with (send SMS, Voice Calls, Airtime Topups, WhatsApp messages, phone number validations, etc.)
                during your monthly plan period. There is no limit on the number of times you can interact with the
                included monthly active contacts.{' '}
                <ExternalLink
                  color="surface"
                  target="_blank"
                  href="https://www.engagespark.com/support/monthly-active-contacts/"
                >
                  Learn more
                </ExternalLink>{' '}
              </>
            }
          >
            <Icon style={{ verticalAlign: 'middle', fontSize: '120%', color: 'rgba(0, 0, 0, 0.54)' }}>info</Icon>
          </CustomTooltip>
        </>
      ),
      value: numbersRow(
        usageLevel(contactsUsage, plan.contactsIncluded),
        `${numberWithCommas(contactsUsage)} (limit ${numberWithCommas(plan.contactsIncluded)})`,
      ),
    }
  }
  return {
    label: (
      <>
        Stored contacts included&nbsp;
        <CustomTooltip interactive title="The maximum number of contacts that can be stored in the account.">
          <Icon style={{ verticalAlign: 'middle', fontSize: '120%', color: 'rgba(0, 0, 0, 0.54)' }}>info</Icon>
        </CustomTooltip>
      </>
    ),
    value: numbersRow(green.A700, numberWithCommas(plan.storedContactsMax)),
  }
}

export default ActivePlan
