import moment from 'moment'
import { getMp3MediaRecorder } from 'mp3-mediarecorder'
import { Component } from 'react'
import AudioAnalyser from './analyser'
import AudioCounter from './counter'
import AudioWrapper from './wrapper'

let Recorder
let initialized = false

export default class AudioRecorder extends Component {
  state = {
    current: moment.duration(0, 'seconds'),
    audioContext: null,
    mediaStream: null,
    recorder: null,
  }

  constructor(props) {
    super(props)

    if (!Recorder && !initialized) {
      initialized = true
      getMp3MediaRecorder({ wasmURL: props.converterPath }).then((Mp3Recorder) => {
        Recorder = Mp3Recorder
      })
    }
  }

  componentDidMount() {
    if (this.props.isRecording) {
      this.startRecording()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isRecording && this.props.isRecording && Recorder) {
      this.startRecording()
    }
    if (prevProps.isRecording && !this.props.isRecording) {
      this.stopRecording()
    }
  }

  startRecording() {
    const { onFinish, onDisabled } = this.props

    const context = new AudioContext()
    this.analyser = context.createAnalyser()

    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false,
      })
      .then((stream) => {
        let blob
        const ref = setInterval(() => {
          this.setState((state) => ({
            current: state.current.add(1, 'seconds'),
          }))
        }, 1000)

        const src = context.createMediaStreamSource(stream)
        src.connect(this.analyser)

        const recorder = new Recorder(stream)
        recorder.ondataavailable = (event) => {
          blob = event.data
        }

        recorder.onstop = () => {
          stream.getTracks().forEach((track) => track.stop())
          src.disconnect()
          onFinish(blob)
          clearInterval(ref)
          this.setState({
            current: moment.duration(0, 'seconds'),
          })
        }

        recorder.start()

        this.setState({
          recorder,
        })
      })
      .catch(() => {
        onDisabled()
      })
  }

  stopRecording() {
    if (this.state.recorder) {
      this.state.recorder.stop()
    }
    if (this.analyser) {
      this.analyser.disconnect()
    }
  }

  render() {
    const { isRecording } = this.props

    return (
      <AudioWrapper>
        <div data-testid="recorder-container">
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
            }}
          >
            <AudioAnalyser isPlaying={this.state.recorder && isRecording} analyser={this.analyser} />
          </div>
        </div>
        <AudioCounter current={this.state.current.format('mm:ss', { trim: false })} />
      </AudioWrapper>
    )
  }
}
