import TableRowActions from '@/app/component/layout/table/actions'

const getPublishConfig = (data, actions) => {
  if (!data.state.launched && !data.state.aborted) {
    return {
      icon: 'power_settings_new',
      label: !data.state.published ? 'Publish' : 'Stop campaign',
      onClick: !data.state.published ? actions.publish : actions.unpublish,
    }
  }

  if (data.state.launched && !data.state.aborted) {
    return {
      icon: 'power_off',
      label: 'Stop',
      onClick: actions.abort,
    }
  }

  return {
    icon: 'power_settings_new',
    label: 'Stopped',
    disabled: true,
  }
}

const getArchiveConfig = (data, actions) => {
  if (!data.state.archived) {
    return {
      icon: 'archive',
      label: 'Archive',
      onClick: actions.archive,
    }
  }
  return {
    icon: 'unarchive',
    label: 'Unarchive',
    onClick: actions.unarchive,
  }
}

const getOpenConfig = (data, actions) => {
  if (data.open) {
    return {
      icon: 'lock',
      label: 'Disable new subscriptions',
      disabled: data.state.aborted || !data.state.launched,
      onClick: actions.close,
    }
  }

  return {
    icon: 'lock_open',
    label: 'Enable new subscriptions',
    disabled: data.state.aborted || !data.state.launched,
    onClick: actions.open,
  }
}

const getCopyConfig = (data, actions) => ({
  icon: 'content_copy',
  label: 'Duplicate',
  onClick: actions.copy,
})

const getCopyIdConfig = (data, actions) => ({
  icon: 'money',
  label: `Copy ID (${data.id})`,
  onClick: actions.copyId,
})

const getEditConfig = (data, actions) => {
  if (data.state.launched) {
    return [
      {
        icon: 'input',
        label: 'Manage subscriptions',
        disabled: data.state.aborted,
        onClick: actions.manage,
      },
      {
        icon: 'stop',
        label: 'Unsubscribe all',
        disabled: data.state.aborted,
        onClick: actions.stopAll,
      },
      {
        icon: 'create',
        label: 'Edit',
        disabled: data.state.aborted,
        onClick: actions.edit,
      },
    ]
  }

  if (data.state.published && !data.state.aborted) {
    return [
      {
        icon: 'create',
        label: 'Cancel launch & return to draft',
        onClick: actions.unpublishThenEdit,
      },
    ]
  }

  return [
    {
      icon: 'create',
      label: 'Edit',
      disabled: data.state.aborted,
      onClick: actions.edit,
    },
  ]
}

const getCampaignReportConfig = (data, actions) => ({
  icon: 'bar_chart',
  label: 'Download report (Excel)',
  disabled: !data.state.launched,
  onClick: actions.downloadCampaignReport,
})

const getCampaignSummaryConfig = (data, actions) => ({
  icon: 'pie_chart',
  label: 'View analytics',
  disabled: !data.state.launched,
  onClick: actions.summary,
})

export default function DripRowActions({ data, actions }) {
  return (
    <TableRowActions
      config={[
        ...getEditConfig(data, actions),
        getCampaignReportConfig(data, actions),
        getCampaignSummaryConfig(data, actions),
        getCopyConfig(data, actions),
        getOpenConfig(data, actions),
        getCopyIdConfig(data, actions),
        getPublishConfig(data, actions),
        getArchiveConfig(data, actions),
      ]}
    />
  )
}
