import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import MuiDialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import MuiListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'
import { TWhatsappListContent } from '@/app/types'

type Props = DialogProps & {
  list: TWhatsappListContent

  onClick?: () => void
}

const WhatsappListDialog: React.FC<Props> = ({ list, onClick, ...props }) => (
  <Dialog {...props}>
    <DialogTitle data-testid="whatsapp-list-button">List: {list.button}</DialogTitle>
    <DialogContent>
      <DialogContentText>WhatsApp List options:</DialogContentText>
      <List>
        {list.items.map((item) => (
          <ListItem data-testid={`whatsapp-list-item-${item.id}`} key={item.id} disablePadding>
            <ListItemButton>
              <ListItemText primary={item.item} secondary={item.description || '-'} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </DialogContent>
    <DialogActions>
      <Button color="primary" variant="contained" onClick={onClick}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
)

const Dialog = styled(MuiDialog)(() => ({
  '& .MuiDialog-paper': {
    minWidth: 400,
  },
}))

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  paddingTop: `${theme.spacing(3)} !important`,
}))

const ListItem = styled(MuiListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

export default WhatsappListDialog
