import { Button } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { History } from 'history'
import { values } from 'ramda'
import React, { Component } from 'react'
import { withStyles } from 'tss-react/mui'
import CopyBox from '@/app/component/atom/copy-box'
import { Select } from '@/app/component/atom/form'
import Loading from '@/app/component/guard/loading'
import ContentLayout from '@/app/component/layout/content'
import ErrorLayout from '@/app/component/layout/error'
import FormLayout from '@/app/component/layout/form'
import PageTitle from '@/app/component/layout/page-title'
import createHydrated from '@/app/component/wrapper/hydratable'
import { usePrevious } from '@/app/helpers'
import { FormHeader, FormSpecs, LoadingError, Organization, Permissions, SelectOption, UserRole } from '@/app/types'
import { validation } from '@/app/module/user/organization-helpers'
import { CommonUserType, InvitePayload, InviteType, UserType } from '@/app/module/user/types'
import AddOrganizationModal from './add-organization-modal'
import AuditLog from './audit-log'
import SMSReportContainer from './sms-report.container'
import Users from './users'

type Props = {
  activeOrgId: number
  addNewSpecs: FormSpecs
  apiToken: string
  classes: Record<string, string>
  error?: LoadingError
  formattedInvitations: CommonUserType[]
  formattedUsers: CommonUserType[]
  headers: FormHeader[]
  invitations: {
    loading: boolean
    error: string
    data: InviteType[]
    init: boolean
  }
  history: History
  loading: boolean
  orgId: number
  organization: Organization
  permissions: Permissions
  role: UserRole
  selectedOrgId: number
  specs: FormSpecs
  tabName: string
  token: string
  userId: number
  userOrganizations: SelectOption<number>[]
  users: {
    loading: boolean
    error: string
    data: UserType[]
    init: boolean
  }

  addOrganization: (params: { token: string; item: Organization }) => Promise<void>
  deleteInvitation: (payload: { token: string; orgId: number; inviteId: number }) => Promise<void>
  deleteUser: (payload: { token: string; orgId: number; userId: number }) => Promise<void>
  downloadAuditLog: (params: { orgId: number; token: string }) => Promise<void>
  getInvitations: (payload: { token: string; orgId: number }) => Promise<void>
  getUsers: (payload: { token: string; orgId: number }) => Promise<void>
  hydrate: (params: { token: string; orgId: number }) => void
  inviteUser: (payload: { token: string; orgId: number; item: InvitePayload }) => Promise<void>
  saveOrganization: (params: { token: string; orgId: number; item: Organization }) => Promise<void>
  selectOrg: (orgId: number) => void
}

type State = {
  addNewModal: boolean
}

export const TABS = {
  INFO: 'info',
  AUDIT: 'audit',
  USERS: 'users',
  REPORT: 'report',
}

class OrganizationComponent extends Component<Props, State> {
  tabNames = values(TABS)

  tabs = {
    [TABS.INFO]: {
      name: TABS.INFO,
      title: 'Info',
      Content: ({ props }: { props: Props }) => {
        const orgValues = React.useMemo(
          () =>
            props.userOrganizations.map((option) => ({
              label: option.label,
              value: String(option.value),
            })),
          [props.userOrganizations],
        )

        const prevOrg = usePrevious(props.activeOrgId)

        React.useEffect(() => {
          if (prevOrg && prevOrg !== props.activeOrgId) {
            props.history.push('/account/organization')
          }
        }, [prevOrg, props.activeOrgId, props.history])

        return (
          <div>
            <CardContent>
              <Grid container spacing={8}>
                <Grid item md={8} xs={12}>
                  <Select
                    id="select-organization"
                    name="select-organization"
                    label="Select organization"
                    value={String(props.selectedOrgId)}
                    values={orgValues}
                    onChange={({ value }) => props.history.push(`/account/organization/info/${value}`)}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <CopyBox style={{ float: 'right' }} text={props.selectedOrgId.toString()} title="Organization ID" />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <FormLayout
              id="edit-organization"
              config={props.specs}
              headers={props.headers}
              loading={props.loading}
              submitLabel="Save"
              values={props.organization}
              validation={validation}
              onSubmit={({ item }: { item: Organization }) => {
                props.saveOrganization({
                  token: props.token,
                  orgId: props.selectedOrgId,
                  item,
                })
              }}
            />
          </div>
        )
      },
    },
    [TABS.AUDIT]: {
      name: TABS.AUDIT,
      title: 'Audit Log',
      Content: ({ props }: { props: Props }) => <AuditLog {...props} />,
    },
    [TABS.USERS]: {
      name: TABS.USERS,
      title: 'Users',
      Content: ({ props }: { props: Props }) => <Users {...props} />,
    },
    [TABS.REPORT]: {
      name: TABS.REPORT,
      title: 'SMS Deliverability Report',
      Content: () => <SMSReportContainer />,
    },
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      addNewModal: false,
    }
  }

  componentDidMount() {
    const { tabName, selectedOrgId, selectOrg } = this.props

    if (tabName === TABS.INFO) {
      selectOrg(selectedOrgId)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { selectedOrgId, selectOrg } = this.props

    if (prevProps.selectedOrgId !== selectedOrgId) {
      selectOrg(selectedOrgId)
    }
  }

  render() {
    const classes = withStyles.getClasses(this.props)
    const { loading, error, tabName, history } = this.props

    const { Content } = this.tabs[tabName] || {}

    return (
      <Loading isLoading={loading} error={error}>
        <div
          style={{
            paddingBottom: '1px',
          }}
        >
          <ContentLayout>
            <div className={classes.header}>
              <PageTitle>Organization</PageTitle>
              <div className={classes.headerButtons}>
                <div className={classes.headerButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      this.setState({
                        addNewModal: true,
                      })
                    }
                  >
                    Add new Organization
                  </Button>
                </div>
              </div>
            </div>
            <Card style={{ marginBottom: '90px' }}>
              <Toolbar style={{ backgroundColor: '#f5f5f5', minHeight: '0' }}>
                <Tabs
                  value={tabName}
                  indicatorColor="primary"
                  onChange={(e, tab) => {
                    history.push(`/account/organization/${tab}`)
                  }}
                >
                  {this.tabNames.map((name, index) => (
                    <Tab key={name} id={`org-tab-${index}`} value={name} label={this.tabs[name].title} />
                  ))}
                </Tabs>
              </Toolbar>
              {this.tabNames.indexOf(tabName) > -1 && (
                <div id={`org-content-${tabName}`}>
                  <Content props={this.props} />
                </div>
              )}
              {this.tabNames.indexOf(tabName) === -1 && (
                <div id="org-content-error">
                  <ErrorLayout status={404}>
                    <Typography>Page not found</Typography>
                  </ErrorLayout>
                </div>
              )}
            </Card>
          </ContentLayout>
        </div>
        {this.state.addNewModal && (
          <AddOrganizationModal
            {...this.props}
            onClose={() =>
              this.setState({
                addNewModal: false,
              })
            }
          />
        )}
      </Loading>
    )
  }
}

const styles = () => ({
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerButtons: {
    display: 'flex',
  },
})

export default withStyles(createHydrated(OrganizationComponent), styles)
