import { Autocomplete, Chip, TextField } from '@mui/material'
import React from 'react'
import { FunctionType } from '@/config/types'

type Props = {
  onChange: FunctionType
  value: any
  label: string
}

export default function TagInput(props: Props) {
  const { onChange, value, label } = props

  const [text, setText] = React.useState('')

  return (
    <Autocomplete
      multiple
      id="tag-input"
      options={[]}
      open={false}
      openOnFocus={false}
      onBlur={() => {
        if (text) {
          onChange({
            value: [...value, text],
          })
        }
      }}
      onChange={(_, tags) => {
        onChange({
          value: tags,
        })
        setText('')
      }}
      value={value}
      filterSelectedOptions
      freeSolo
      renderTags={(tags: readonly string[], getTagProps) =>
        tags.map((option: string, index: number) => (
          <Chip size="small" variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          value={text}
          onChange={(e) => setText(e.currentTarget.value)}
          variant="standard"
          label={label}
          placeholder="Add values"
        />
      )}
    />
  )
}
