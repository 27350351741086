import AppBar from '@mui/material/AppBar'
import DialogContent from '@mui/material/DialogContent'
import Icon from '@mui/material/Icon'
import ExternalLink from '@mui/material/Link'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React from 'react'
import Draggable from 'react-draggable'
import { makeStyles } from 'tss-react/mui'
import ContentLayout from '@/app/component/layout/content'
import AuthHeader from '@/app/component/section/header'
import CATIHeader from '@/app/component/section/header/cati'
import { PAGE } from '@/app/definitions'
import { CATI_USER_ROLE } from '@/app/module/cati/definitions'
import Plans from '@/app/module/plans'

const useStyle = makeStyles()({
  container: {
    color: '#fff',
    width: '100vw',
    maxWidth: '100%',
    left: 0,
  },
  pricingDialogCont: {
    position: 'absolute',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.57)',
    zIndex: 999,
    height: '100%',
    overflowY: 'scroll',
  },
  pricingDialogSubCont: {
    background: 'white',
    width: '90%',
    margin: 'auto',
    marginTop: '80px',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px rgb(224 224 224)',
  },
  warningCont: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: '5px',
    marginBottom: '-10px',
    position: 'sticky',
    width: '100%',
    top: '90vh',
    zIndex: 1000,
    height: 0,
  },
  trialSubCont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
    height: '40px',
    background: '#fff',
  },
  iconCont: {
    height: '100%',
    justifySelf: 'center',
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    padding: '7px',
    color: '#fff',
    background: '#ef6c00',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
  },
  trialTextCont: {
    border: '1px solid rgba(0, 0, 0, 0.44)',
    padding: '0 15px',
    borderRadius: '3px',
    borderLeft: 'none',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  trialText: {},
  cxSubCont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
    height: '40px',
    background: '#ef6c00',
    cursor: 'move',
  },
  cxTextCont: {
    borderLeft: '1px solid #fff',
    padding: '0 15px',
    borderRadius: '3px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  cxText: {
    color: '#fff',
  },
})

const plansExcluded = [
  PAGE.ACCOUNT.BILLING.INDEX,
  PAGE.ACCOUNT.PROFILE.INDEX,
  PAGE.DASHBOARD.INDEX,
  PAGE.INVITATION,
  PAGE.PLANS.INDEX,
]

export default function Header(props: any) {
  const { trialMode, planId, plan = {}, role, currentPage, activeOrg } = props

  const { classes } = useStyle()

  const displayPlans = !plansExcluded.includes(currentPage) && (plan.trialDaysLeft < 0 || (planId === 0 && !trialMode))

  React.useEffect(() => {
    if (displayPlans) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [displayPlans, planId, trialMode, currentPage])

  return (
    <>
      {displayPlans && (
        <div className={classes.pricingDialogCont} id="plans-dialog">
          <div className={classes.pricingDialogSubCont}>
            <DialogContent>
              <Plans showTrialMode={true} />
            </DialogContent>
          </div>
        </div>
      )}
      <AppBar
        position="sticky"
        className={classes.container}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <ContentLayout>
          <Toolbar>{role === CATI_USER_ROLE ? <CATIHeader {...props} /> : <AuthHeader {...props} />}</Toolbar>
        </ContentLayout>
      </AppBar>
      {planId === 0 && trialMode && currentPage !== PAGE.PLANS.INDEX && (
        <div className={classes.warningCont}>
          <div className={classes.trialSubCont}>
            <div className={classes.iconCont}>
              <Icon>notification_important</Icon>
            </div>
            <div className={classes.trialTextCont}>
              <Typography variant="body1" color="textSecondary" className={classes.trialText}>
                You are currently in trial mode. Some platform features are restricted. To select a plan and learn more,{' '}
                <ExternalLink href="/plans">click here.</ExternalLink>
              </Typography>
            </div>
          </div>
        </div>
      )}
      {role === 'customer_service' && (
        <div className={classes.warningCont}>
          <Draggable axis="y">
            <div className={classes.cxSubCont}>
              <div className={classes.iconCont}>
                <Icon>notification_important</Icon>
              </div>
              <div className={classes.cxTextCont}>
                <Typography variant="h6" color="textPrimary" className={classes.cxText}>
                  You are in{' '}
                  <strong>
                    {activeOrg.name} ({activeOrg.orgId})
                  </strong>{' '}
                  with the <strong>CUSTOMER SUCCESS</strong> role. BE CAREFUL
                </Typography>
              </div>
            </div>
          </Draggable>
        </div>
      )}
    </>
  )
}
