import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import UseCategoriesDialog from './use-categories-dialog'

type Props = {
  useCategories: boolean
  onChange: (value: boolean) => void
}

const UseCategoriesToggle: React.FC<Props> = ({ useCategories, onChange }) => {
  const [useCategoriesOpen, setUseCategoriesOpen] = React.useState(false)

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target
      if (checked) {
        setUseCategoriesOpen(true)
      } else {
        onChange(e.target.checked)
      }
    },
    [onChange],
  )
  const handleConfirm = React.useCallback(() => {
    setUseCategoriesOpen(false)
    onChange(true)
  }, [onChange])
  const handleClose = React.useCallback(() => {
    setUseCategoriesOpen(false)
  }, [])

  return (
    <div>
      <FormControlLabel
        id="use-categories-toggle"
        control={<Switch checked={!!useCategories} onChange={handleChange} />}
        label="AI enhanced answer categorization"
      />
      <UseCategoriesDialog open={useCategoriesOpen} onClose={handleClose} onConfirm={handleConfirm} />
    </div>
  )
}

export default UseCategoriesToggle
