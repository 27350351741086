import { CampaignTemplateType, RangeType } from '@/app/module/campaigns/types'

export const contentTypeList: Record<
  Exclude<CampaignTemplateType, CampaignTemplateType.Reminder>,
  Array<{ label: string; value: string }>
> = {
  [CampaignTemplateType.CATI]: [
    {
      label: 'CATI Message',
      value: 'cati-m',
    },
    {
      label: 'CATI Question',
      value: 'cati-q',
    },
    {
      label: 'Actions Only',
      value: 'action-q',
    },
  ],
  [CampaignTemplateType.Drip]: [
    {
      label: 'SMS Message',
      value: 'sms-m',
    },
    {
      label: 'SMS Question',
      value: 'sms-q',
    },
    {
      label: 'Voice Message',
      value: 'voice-m',
    },
    {
      label: 'Voice Question',
      value: 'voice-q',
    },
    {
      label: 'WhatsApp Message',
      value: 'whatsapp-m',
    },
    {
      label: 'WhatsApp Question',
      value: 'whatsapp-q',
    },
    {
      label: 'Actions Only',
      value: 'action-q',
    },
    {
      label: 'Airtime Topup',
      value: 'topup-q',
    },
    {
      label: 'API Request',
      value: 'api-q',
    },
  ],
  [CampaignTemplateType.SMSBlast]: [
    {
      label: 'SMS Message',
      value: 'sms-m',
    },
  ],
  [CampaignTemplateType.SMSSurvey]: [
    {
      label: 'SMS Message',
      value: 'sms-m',
    },
    {
      label: 'SMS Question',
      value: 'sms-q',
    },
    {
      label: 'API Request',
      value: 'api-q',
    },
    {
      label: 'Actions Only',
      value: 'action-q',
    },
  ],
  [CampaignTemplateType.VoiceBlast]: [
    {
      label: 'Voice Message',
      value: 'voice-m',
    },
    {
      label: 'Voice Question',
      value: 'voice-q',
    },
    {
      label: 'Actions Only',
      value: 'action-q',
    },
  ],
  [CampaignTemplateType.VoiceSurvey]: [
    {
      label: 'Voice Message',
      value: 'voice-m',
    },
    {
      label: 'Voice Question',
      value: 'voice-q',
    },
    {
      label: 'Actions Only',
      value: 'action-q',
    },
  ],
  [CampaignTemplateType.WhatsAppSurvey]: [
    {
      label: 'WhatsApp Message',
      value: 'whatsapp-m',
    },
    {
      label: 'WhatsApp Question',
      value: 'whatsapp-q',
    },
    {
      label: 'API Request',
      value: 'api-q',
    },
    {
      label: 'Actions Only',
      value: 'action-q',
    },
  ],
}

export const pageSizeValue = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
]

export const emptyRanges: RangeType[] = []
export const emptyReplies: string[] = []
