import { values } from 'ramda'
import { connect } from 'react-redux'
import Layout from '../../component/layout/page'
import { PAGE } from '../../definitions'
import { AuthGuard, AuthHeader } from '../../module/auth'
import Notifications from '../../module/notifications'
import { UserOrganization } from '../../module/user'
import { TABS } from '../../module/user/component/organization'
import { selectOrgId } from '../../module/user/store/selectors'
import NotFoundPage from '../not-found'

function OrganizationPage({ name, selectedStateId, routes, match, history }) {
  if (values(TABS).indexOf(match.params.tabName) === -1) {
    return <NotFoundPage />
  }

  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.url}>
      <Layout title="Organization - engageSPARK">
        <AuthHeader currentPage={PAGE.ACCOUNT.ORGANIZATION.INDEX} />
        <UserOrganization
          history={history}
          selectedOrgId={parseInt(match.params.selectedOrgId, 10) || selectedStateId}
          // ^ because apparently NaN !== NaN
          tabName={match.params.tabName}
        />
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}

export default connect(({ user }) => ({
  selectedStateId: selectOrgId({ user }),
}))(OrganizationPage)
