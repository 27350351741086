import { Dialog, DialogClasses } from '@mui/material'
import React from 'react'

type Props = React.PropsWithChildren<{
  classes?: Partial<DialogClasses>
  fullWidth?: boolean
  id?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  open: boolean
  style?: React.CSSProperties
  onClose: () => void
}>

export default function CustomDialog({
  classes,
  children,
  fullWidth = false,
  id,
  maxWidth = 'sm',
  open,
  style,
  onClose,
}: Props) {
  return (
    <Dialog
      classes={classes}
      fullWidth={fullWidth}
      id={id}
      maxWidth={maxWidth}
      open={open}
      style={style}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
    >
      {children}
    </Dialog>
  )
}
