import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { pathOr, replace } from 'ramda'
import React from 'react'
import Topup from '../../../../../../../../component/atom/topup'
import IconText from '../../../../../../../../component/layout/icon-text'
import CustomDialog from '../../../../../../../../component/wrapper/custom-dialog'
import { Q_ACTIONS, displayMap } from '../../../../../../definitions'

const unwrapValue = (value) =>
  pathOr(
    {
      maxAmount: '',
      maxAmountTemplated: '',
      desiredAmountTemplated: '',
    },
    ['topup'],
    value,
  )

const TopupDialog = (props) => {
  const { onClose, onSave, value = {}, personalizationList = {} } = props
  const { contact = [], customfields = [] } = personalizationList
  const { maxAmount, maxAmountTemplated, desiredAmountTemplated } = unwrapValue(value)
  const [disabled, setDisabled] = React.useState(false)
  const [topupType, setTopupType] = React.useState(maxAmountTemplated ? 'dynamic' : 'fixed')
  const [topup, setTopup] = React.useState({})

  return (
    <CustomDialog open={true} onClose={onClose}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>{displayMap.topup.icon}</Icon>
            <Typography variant="h6">{Q_ACTIONS.topup.label}</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '24px' }}>
        <div
          style={{
            width: '80vw',
            minWidth: '300px',
            maxWidth: '100%',
          }}
        >
          <CardContent
            style={{
              paddingTop: 0,
            }}
          >
            <Topup
              maxAmount={`${maxAmount}`}
              maxAmountTemplated={maxAmountTemplated ? replace(/{|}/g, '', maxAmountTemplated) : ''}
              desiredAmountTemplated={`${desiredAmountTemplated}`}
              contact={contact}
              customfields={customfields}
              setDisabled={setDisabled}
              topupType={topupType}
              setTopupType={setTopupType}
              setTopup={setTopup}
            />
          </CardContent>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={() =>
            onSave({
              topup,
            })
          }
        >
          Save
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}

export default TopupDialog
