import { MessageOverview } from '@/app/module/campaigns/types'
import React from 'react'
import { getPercentage } from '@/app/service/util/format'
import OverviewTable from '@/app/component/atom/overview-table'

type Props = {
  data: MessageOverview
}

const MessageOverviewTable: React.FC<Props> = ({ data }) => {
  const tableData = React.useMemo(
    () => [
      {
        name: 'SMS sent',
        value: data.totalEgress,
      },
      {
        name: 'Contacts engaged (% of sent)',
        value: `${data.deliveredToPhoneConfirmed + data.deliveredToPhoneUnknown} (${getPercentage(data.deliveredToPhoneConfirmed + data.deliveredToPhoneUnknown, data.totalEgress)})`,
      },
      {
        name: 'Contacts not engaged (% of sent)',
        value: `${data.deliveredToPhoneFailed} (${getPercentage(data.deliveredToPhoneFailed, data.totalEgress)})`,
      },
      {
        name: 'Error (% of sent)',
        value: `${data.deliveredToTelcoFailed} (${getPercentage(data.deliveredToTelcoFailed, data.totalEgress)})`,
      },
    ],
    [data],
  )

  return <OverviewTable data={tableData} title="SMS" />
}

export default MessageOverviewTable
