import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { FixMeLater } from '@/config/types'
import AudioPlayer from '@/app/component/atom/audio-player'
import VideoPlayer from '@/app/component/atom/video-player'
import WhatsappButtons from '@/app/component/atom/whatsapp/whatsapp-buttons'
import WhatsappListDialog from '@/app/component/atom/whatsapp/whatsapp-list-dialog'
import { cannot } from '@/app/helpers'
import { downloadFile } from '@/app/service/util/download'
import { WhatsAppLog } from '@/app/types/log'
import { extractWhatsappContentFromPart } from '@/app/module/common/whatsapp/whatsapp-content'
import { TGetFileFunction } from '@/app/module/conversations/types'
import WhatsappDocument from './whatsapp-document'

type Props = {
  part: WhatsAppLog
  getFile: TGetFileFunction
}

const WhatsappConversationPart: React.FC<Props> = ({ part, getFile }) => {
  const { classes } = useStyle()
  const [files, setFiles] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState(false)
  const [file, setFile] = React.useState('')
  const [listOpen, setListOpen] = React.useState(false)
  const [downloading, setDownloading] = React.useState(false)

  const messageContent = React.useMemo(() => extractWhatsappContentFromPart(part), [part])

  const downloadAttachment = React.useCallback(
    async (url: string, filename: string) => {
      if (downloading) {
        return
      }

      setDownloading(true)
      try {
        await downloadFile(url, filename, true)
      } finally {
        setDownloading(false)
      }
    },
    [downloading],
  )
  const handleListClose = React.useCallback(() => setListOpen(false), [])

  const renderMessage = (message: string) => (
    <Typography
      style={{
        whiteSpace: 'pre-line',
      }}
    >
      {message}
    </Typography>
  )

  const renderContent = () => {
    const { type, content } = messageContent
    switch (type) {
      case 'message': {
        return renderMessage(content.message)
      }

      case 'image': {
        return (
          <>
            {!!content.files?.length && (
              <>
                {loading && (
                  <div className={classes.loadingCont}>
                    <CircularProgress />
                  </div>
                )}
                {!loading && (
                  <>
                    {content.files.map((image: string, index: number) => {
                      if (files[index]) {
                        return <img key={index} width="300" src={files[index]} />
                      }
                      return (
                        <div
                          key={index}
                          style={{
                            position: 'relative',
                          }}
                        >
                          <Skeleton variant="rectangular" width={300} height={200} />
                          <div className={classes.imageDownloadCont}>
                            <Button
                              variant="contained"
                              disableElevation
                              color="primary"
                              onClick={() => {
                                setLoading(true)
                                getFile(image).then((res: FixMeLater) => {
                                  const updatedFiles: string[] = [...files]
                                  updatedFiles[index] = res.url
                                  setFiles(updatedFiles)
                                  setLoading(false)
                                })
                              }}
                            >
                              Preview Image
                            </Button>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}
              </>
            )}
            {renderMessage(content.message)}
          </>
        )
      }

      case 'audio': {
        const audioUrl = content.files?.[0]
        return (
          <>
            {audioUrl && (
              <div className={classes.audioPlayerCont}>
                <AudioPlayer
                  loading={loading}
                  playId={part.id}
                  source={file}
                  onPlayToggle={(val: string) => {
                    if (val && !file) {
                      setLoading(true)
                      getFile(audioUrl).then((res: any) => {
                        setFile(res.url)
                        setLoading(false)
                      })
                    }
                  }}
                />
              </div>
            )}
            {renderMessage(content.message)}
          </>
        )
      }

      case 'document': {
        return (
          <>
            {content.files.length &&
              content.files.map((document: string, index: number) => (
                <WhatsappDocument
                  key={index}
                  document={document}
                  onClick={async () => {
                    if (loading) {
                      return
                    }
                    const res = await getFile(document)
                    await downloadAttachment(res.url, document)
                  }}
                />
              ))}
            {renderMessage(content.message)}
          </>
        )
      }

      case 'video': {
        const videoFile = content.files?.[0]
        return (
          <>
            {videoFile && <VideoPlayer filename={videoFile} getFile={getFile} />}
            {renderMessage(content.message)}
          </>
        )
      }

      case 'buttons': {
        return <WhatsappButtons content={content} />
      }

      case 'list': {
        return (
          <>
            <Typography
              style={{
                whiteSpace: 'pre-line',
              }}
            >
              {content.message}
            </Typography>
            <Button variant="contained" onClick={() => setListOpen(true)}>
              {content.button}
            </Button>
            <WhatsappListDialog
              closeAfterTransition
              list={content}
              open={listOpen}
              onClick={handleListClose}
              onClose={handleListClose}
            />
          </>
        )
      }

      default:
        return cannot(type)
    }
  }

  const hasImage = messageContent.type === 'image' && messageContent.content.files?.length

  return (
    <div
      style={{
        ...(hasImage && {
          width: '300px',
          textAlign: 'left',
          minHeight: '200px',
        }),
      }}
    >
      {renderContent()}
    </div>
  )
}

const useStyle = makeStyles()({
  loadingCont: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
    minWidth: '300px',
  },
  imageDownloadCont: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  audioPlayerCont: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  playOverlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
  },
  playCont: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  playBtn: {
    color: '#fff',
    backgroundColor: '#2f80ac',
    '&:hover': {
      backgroundColor: 'rgba(47, 128, 172, 0.85)',
    },
  },
  previewImage: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
})

export default WhatsappConversationPart
