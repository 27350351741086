import { has, pathOr, reduce, reverse } from 'ramda'
import { currencyFormat } from '@/app/service/util/format'
import { mapIndexed } from '@/app/module/campaigns/helpers'

type Content = {
  key: string
  label: string
  data: (data: any) => number
}

type DataStructure = {
  key: string
  content: Content[]
}

export const dataStructure: DataStructure[] = [
  {
    key: 'message',
    content: [
      {
        key: 'engaged',
        label: 'Contacts Engaged',
        data: ({ deliveredToPhoneConfirmed, deliveredToPhoneUnknown }) =>
          deliveredToPhoneConfirmed + deliveredToPhoneUnknown,
      },
      {
        key: 'notEngaged',
        label: 'Contacts Not Engaged',
        data: ({ deliveredToPhoneFailed }) => deliveredToPhoneFailed,
      },
      {
        key: 'error',
        label: 'Error',
        data: ({ deliveredToTelcoFailed }) => deliveredToTelcoFailed,
      },
    ],
  },
  {
    key: 'voice',
    content: [
      {
        key: 'engaged',
        label: 'Picked Up',
        data: ({ answered }) => answered,
      },
      {
        key: 'notEngaged',
        label: 'Not Picked Up',
        data: ({ notAnswered }) => notAnswered,
      },
      {
        key: 'error',
        label: 'Failed',
        data: ({ failedToRing }) => failedToRing,
      },
    ],
  },
  {
    key: 'whatsapp',
    content: [
      {
        key: 'engaged',
        label: 'Contacts Engaged',
        data: ({ success }) => success,
      },
      {
        key: 'notEngaged',
        label: 'In Progress',
        data: ({ inProgress }) => inProgress,
      },
      {
        key: 'error',
        label: 'Error',
        data: ({ failed }) => failed,
      },
    ],
  },
]

const selectEngagements = (message: any) =>
  reduce(
    (typeAccum, messageType) => {
      if (has(messageType.key, message)) {
        return reduce(
          (contentAccum, { key, data }) => ({
            ...contentAccum,
            [key]: data(message[messageType.key]),
          }),
          {},
          messageType.content,
        )
      }
      return typeAccum
    },
    {},
    dataStructure,
  )

export const processPartsData = (partStats: any[] = []) =>
  reverse(
    mapIndexed((message: any, i) => {
      const messageData = {
        name: `${i + 1}. ${message.name}`,
        id: message.id,
        ...selectEngagements(message),
      }
      return messageData
    }, partStats),
  )

export const processStatsForSummary = ({ itemSummary, item }: any) => {
  const { runs } = item
  const { overallMessageStats, overallVoiceStats } = itemSummary
  return {
    subscriptions: runs,
    engaged: pathOr(0, ['total'], overallMessageStats) + pathOr(0, ['total'], overallVoiceStats),
    calls: pathOr(0, ['total'], overallVoiceStats),
    messages: pathOr(0, ['total'], overallMessageStats),
    cost: {
      message: currencyFormat(pathOr('0', ['overallMessageStats', 'costHuman'], itemSummary)),
      topup: currencyFormat(pathOr('0', ['overallAirtimeStats', 'costHuman'], itemSummary)),
      voice: currencyFormat(pathOr('0', ['overallVoiceStats', 'costHuman'], itemSummary)),
      whatsapp: currencyFormat(pathOr('0', ['overallWhatsappStats', 'costHuman'], itemSummary)),
      total: currencyFormat(pathOr('0', ['costHuman'], itemSummary)),
    },
  }
}
