import { selectTimezones } from '@/app/module/campaigns/store/selectors'
import { toCountry } from '@/app/service/util/timezone'
import { CountryTimezone, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'
import { Typography, styled } from '@mui/material'
import { pathOr } from 'ramda'
import React from 'react'
import { SearchableSelect } from './form'

type Props = {
  countriesTimezones: CountryTimezone[]
  countryCode: string
  disabled?: boolean
  name: string
  timezone: string
  timezones: TimezoneWithCountryCodeType
  onChange: (timezone: string, country: string) => void
}

const keyPropFn = (option: SelectOption<string>) => option.value
const valuePropFn = (option: SelectOption<string>) => option.label

const TimezonePicker: React.FC<Props> = ({
  countriesTimezones,
  countryCode,
  disabled,
  name,
  timezone,
  timezones,
  onChange,
}) => {
  const [country, setCountry] = React.useState(toCountry(timezone, countryCode, timezones))

  return (
    <Container>
      <Label color="textSecondary" variant="caption">
        Timezone
      </Label>
      <Items>
        <Item>
          <SearchableSelect
            data-testid={`${name}-country`}
            disabled={disabled}
            hideRemoveSelection
            id={`${name}-country`}
            options={countriesTimezones}
            showAll
            value={country}
            keyPropFn={keyPropFn}
            onChange={(value: string) => {
              if (value !== country) {
                setCountry(value)
                const firstTz = selectTimezones(pathOr([], [value, 'tzs'], timezones))?.[0]?.value || ''
                onChange(firstTz, value)
              }
            }}
            valuePropFn={valuePropFn}
          />
        </Item>
        <Item>
          <Label color="textSecondary" variant="caption"></Label>
          <SearchableSelect
            data-testid={`${name}-timezone`}
            disabled={disabled}
            hideRemoveSelection
            id={`${name}-timezone`}
            options={selectTimezones(pathOr([], [country, 'tzs'], timezones))}
            showAll
            value={timezone}
            keyPropFn={keyPropFn}
            onChange={(value: string) => onChange(value, country)}
            valuePropFn={valuePropFn}
          />
        </Item>
      </Items>
    </Container>
  )
}

const Container = styled('div')(() => ({
  width: '100%',
}))

const Items = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(),
  width: '100%',
}))

const Item = styled('div')(() => ({
  flex: 1,
}))

const Label = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(10),
}))

export default TimezonePicker
