import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { hasPreview } from '@/app/module/campaigns/utils/actions/helpers'
import ActionDrag from './drag'
import ActionDrop from './drop'
import ActionPreview from './preview'
import ActionSelect from './select'
import AddToLibraryButton, { editableActions } from './add-to-library-button'

export default function CampaignContentQuestionAction(props) {
  const {
    index,
    dragContext,
    action = {},
    isLast,
    values,
    onChange,
    onRemove,
    onMove,
    parts,
    personalizationList,
    files,
    onCopy,
  } = props
  return (
    <ActionDrop index={index} dragContext={dragContext} onMove={onMove}>
      <ActionDrag index={index} dragContext={dragContext}>
        <div
          className="campaign-action-item"
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <InputAdornment position="start" style={{ alignSelf: 'center', height: '33px', maxHeight: 'none' }}>
            <IconButton
              disabled={isLast}
              style={{ cursor: 'move', padding: '0 6px', borderRadius: '0', height: '100%' }}
            >
              <Icon>drag_indicator</Icon>
            </IconButton>
          </InputAdornment>
          <ActionSelect action={action} values={values} onChange={onChange} />
          {hasPreview(action) && (
            <div
              style={{
                marginBottom: 'calc(0.5rem + 6px)',
                width: '100%',
              }}
            >
              <ActionPreview
                action={action}
                parts={parts}
                files={files}
                personalizationList={personalizationList}
                onEdit={() => onChange({ value: action.type })}
              />
            </div>
          )}
          <InputAdornment position="end" style={{ height: '38px', marginBottom: '0.5rem', maxHeight: 'none' }}>
            <IconButton
              disabled={editableActions.indexOf(action.type) === -1}
              onClick={() => onChange({ value: action.type })}
            >
              <Icon>create</Icon>
            </IconButton>
            <IconButton disabled={isLast} onClick={onRemove}>
              <Icon>delete</Icon>
            </IconButton>
            {onCopy && <AddToLibraryButton action={action} onCopy={onCopy} />}
          </InputAdornment>
        </div>
      </ActionDrag>
    </ActionDrop>
  )
}
