import React from 'react'
import TableCell from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'

const Cell = styled(TableCell)({
  '&:not(:first-of-type) > div': {
    minWidth: '7rem',
  },
})

const InnerCell = styled('div')(({ padLeft }: { padLeft?: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '60px',
  paddingLeft: padLeft ? '24px' : '0',
}))

type TableCellLayoutProps = {
  children: React.ReactNode
  className?: string
  index: number
  style?: React.CSSProperties
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export default function TableCellLayout({
  children,
  className,
  index,
  style = {},
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}: TableCellLayoutProps) {
  return (
    <Cell className={className} style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <InnerCell padLeft={index === 0} onClick={onClick}>
        {children}
      </InnerCell>
    </Cell>
  )
}
