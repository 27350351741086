import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import WhatsappIcon from '@mui/icons-material/WhatsApp'
import { makeStyles } from 'tss-react/mui'
import { TGetFileFunction } from '@/app/module/conversations/types'
import { formatDate } from '@/app/service/util'
import { SMSLog, WhatsAppLog } from '@/app/types/log'
import { DataMap } from '@/app/module/campaigns/types'
import React from 'react'
import SMS from './sms'
import Voice from './voice'
import WhatsappConversationPart from './whatsapp'

const useStyle = makeStyles()({
  partWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    padding: '10px',
  },
  arrow: {
    width: 0,
    height: 0,
    borderTop: '7px solid transparent',
    alignSelf: 'flex-end',
  },
  card: {
    maxWidth: '65%',
    textAlign: 'left',
    borderRadius: '4px',
    borderBottomLeftRadius: '0px',
  },
  captionTextCont: {
    fontSize: '0.60rem',
  },
  captionText: {
    fontSize: '0.65rem',
  },
})

type Props = {
  part: WhatsAppLog | SMSLog
  type: string
  getFile: TGetFileFunction
}

const componentMap: DataMap = {
  sms: SMS,
  voice: Voice,
  whatsapp: WhatsappConversationPart,
}

const colorMap: DataMap = {
  sms: {
    egress: {
      backgroundColor: 'rgba(47, 172, 91, 0.3)',
      color: 'rgba(0,0,0,0.75)',
    },
    ingress: {
      backgroundColor: 'rgba(47, 128, 172, 0.3)',
      color: 'rgba(0,0,0,0.75)',
    },
  },
  whatsapp: {
    egress: {
      backgroundColor: 'rgba(47, 172, 91, 0.3)',
      color: 'rgba(0,0,0,0.75)',
    },
    ingress: {
      backgroundColor: 'rgba(47, 128, 172, 0.3)',
      color: 'rgba(0,0,0,0.75)',
    },
  },
  voice: {
    egress: {
      backgroundColor: 'rgba(47, 172, 91, 0.3)',
      color: 'rgba(0,0,0,0.75)',
    },
    ingress: {
      backgroundColor: 'rgba(47, 128, 172, 0.3)',
      color: 'rgba(0,0,0,0.75)',
    },
  },
}

const iconMap: DataMap = {
  sms: <Icon fontSize="small">sms</Icon>,
  whatsapp: <WhatsappIcon sx={{ width: '20px', height: '20px' }} />,
  voice: <Icon fontSize="small">phone_enabled</Icon>,
}

const Part: React.FC<Props> = (props) => {
  const { classes } = useStyle()
  const { part, type, getFile } = props
  const Component: any = componentMap[type]
  const senderId = part.direction === 'egress' ? part.sender : part.recipient
  return (
    <div
      className={`${classes.partWrapper} conversations-part-wrapper ${
        part.direction === 'egress' ? 'conversations-part-egress' : 'conversations-part-ingress'
      }`}
    >
      <div
        style={{
          fontSize: '14px',
          display: 'flex',
          marginBottom: '10px',
          ...(part.direction === 'egress' && {
            flexDirection: 'row-reverse',
          }),
        }}
      >
        <div
          style={{
            alignSelf: 'flex-end',
            ...(part.direction === 'ingress' && {
              transform: 'scaleX(-1)',
              paddingLeft: '2px',
            }),
          }}
        >
          {iconMap[type]}
        </div>
        <div
          className={classes.arrow}
          style={{
            borderLeft: `3px solid ${colorMap[type][part.direction].backgroundColor}`,
            ...(part.direction === 'ingress' && {
              transform: 'scaleX(-1)',
            }),
          }}
        ></div>
        <div
          className={classes.card}
          style={{
            ...colorMap[type][part.direction],
            ...(part.direction === 'egress' && {
              textAlign: 'right',
              borderBottomRightRadius: '0px',
              borderBottomLeftRadius: '4px',
            }),
          }}
        >
          <div className={classes.cardContent}>
            <Component getFile={getFile} part={part} />
            <div
              style={{
                display: 'flex',
                justifyContent: part.direction === 'egress' ? 'flex-end' : 'flex-start',
              }}
            >
              <Typography className={classes.captionTextCont} variant="caption" color="textSecondary">
                {part.direction === 'egress' ? 'From' : 'To'} <span className={classes.captionText}>{senderId}</span> at{' '}
                <span className={classes.captionText}>{formatDate(part.created)}</span>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Part)
