import { DelayState, SubscriptionStats, SubscriptionStatus } from '@/app/module/campaigns/types'

export type DelaySubscriptionDialogState = {
  id?: number
  isOpen: boolean
  group?: boolean
  type: string
  action?: () => void
}

export type ConfirmationDialogState = {
  delay?: DelayState | {}
  id?: number
  isOpen: boolean
  startAt?: string
  t0?: string
  t0timezone?: string
  type: string
  action?: () => void
}

export const getSubscriptionStatus = (stats?: SubscriptionStats): SubscriptionStatus => {
  if (!stats?.totalCount) {
    return 'non-subscribed'
  }
  const { activeId, pausedCount } = stats
  if (activeId) {
    return pausedCount ? 'paused' : 'subscribed'
  }

  return 'done'
}
