import Toolbar from '@mui/material/Toolbar'
import { styled } from '@mui/material/styles'

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0 24px',
  minHeight: '60px',
})

export default StyledToolbar
