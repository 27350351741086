import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { isEmpty, pathOr } from 'ramda'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { FixMeLater } from '@/config/types'
import { Input } from '@/app/component/atom/form'
import Loading from '@/app/component/guard/loading'
import Fields from '@/app/component/wrapper/fields'
import validate, { hasRequiredError } from '@/app/service/validate'

const useStyles = makeStyles()((theme) => ({
  wrap: {
    maxWidth: '400px',
    margin: 'auto',
    marginTop: 'calc(60px + 5%)',
    marginBottom: '3rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2rem',
  },
  error: {
    height: '60px',
    background: pathOr('', ['palette', 'error', 'main'], theme),
    '& p': {
      color: pathOr('', ['palette', 'error', 'contrastText'], theme),
    },
  },
  icon: {
    margin: 'auto',
    marginBottom: '15px',
    fontSize: '4rem !important',
    color: pathOr('', ['palette', 'primary', 'main'], theme),
  },
  button: {
    alignSelf: 'flex-end',
  },
}))

function VerifyOTP(props: FixMeLater) {
  const { token, orgId, loading, error, submit, clearOTPError, history } = props
  const hasRequestError = !isEmpty(error)
  const { classes } = useStyles()

  return (
    <Loading isLoading={loading}>
      <Fields
        fields={{
          otp: '',
        }}
        validation={{
          otp: validate(hasRequiredError),
        }}
        onSubmit={({ item }: FixMeLater) =>
          submit({
            token,
            orgId,
            item,
          }).then((res: FixMeLater) => {
            if (!(res instanceof Error)) {
              history.push('/')
            }
          })
        }
      >
        {({ fields, changed, hasErrors, submitHandler, fieldChangeHandler, fieldBlurHandler }: FixMeLater) => (
          <form
            onSubmit={(e) => {
              e.persist?.()
              e.preventDefault()
              submitHandler()
            }}
            noValidate
          >
            <Card className={classes.wrap}>
              <CardContent className={classes.content}>
                <Icon className={classes.icon}>account_circle</Icon>
                <Typography color="textSecondary" variant="caption">
                  Please enter your two-factor token
                </Typography>
                <Input
                  name="otp"
                  type="text"
                  label="Code"
                  editable={true}
                  value={fields.otp.value}
                  autoFocus={true}
                  error={fields.otp.error}
                  onChange={({ name, value }: FixMeLater) => {
                    if (hasRequestError) {
                      clearOTPError()
                    }
                    fieldChangeHandler({ name, value })
                  }}
                  onBlur={fieldBlurHandler}
                />
                <Typography color="textSecondary" variant="caption">
                  You have enabled two-factor authentication for this account. Please open your authenticator app
                  (Google Authenticator, Authy, etc) to retrieve a code.
                </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: 'space-between', paddingBottom: '1rem' }}>
                <Link to="/recovery-code">
                  <Button size="small" color="primary">
                    Verify using a recovery code instead
                  </Button>
                </Link>
                <Button
                  type="submit"
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={!changed || loading || hasErrors}
                >
                  Verify
                </Button>
              </CardActions>
            </Card>
          </form>
        )}
      </Fields>
    </Loading>
  )
}

export default VerifyOTP
