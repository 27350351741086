import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'

const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    top: 3,
    right: -20,
    fontSize: 11,
    height: 15,
    minWidth: 15,
  },
})

export default StyledBadge
