import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { pathOr } from 'ramda'
import React, { PropsWithChildren, useCallback } from 'react'
import { DropzoneRef } from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'
import { noop } from '@/app/definitions'
import { usePrevious } from '@/app/helpers'
import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import Voice from '@/app/module/campaigns/component/item/steps/content/voice'
import {
  ContentWhatsApp,
  PersonalizationType,
  VoiceProps,
  WhatsAppMessage,
  WhatsAppQuestion,
} from '@/app/module/campaigns/types'
import Message from '@/app/module/logs/component/send/message'
import IconText from '@/app/component/layout/icon-text'
import Loader from '@/app/component/atom/loader'
import Uploader from '@/app/component/atom/upload'
import { FileUploadType } from '@/app/types'
import { WhatsAppTab, loadDefaultTab, shouldDisplayConfirmation } from './helpers'

type Props = Pick<
  VoiceProps,
  | 'defaultUpload'
  | 'files'
  | 'loading'
  | 'playId'
  | 'getFile'
  | 'onMicAccessError'
  | 'saveFileHandler'
  | 'setDefaultUpload'
  | 'setPlaying'
> & {
  audio?: string | null
  autoFocus?: boolean
  defaultLanguage: string
  document?: string | null
  editable?: boolean
  enableMediaCaption?: boolean
  /**
   * Indicates if the whatsapp message uses whatsapp buttons so we can show the confirmation message accordingly
   */
  hasButtons?: boolean
  /**
   * Indicates if the whatsapp message uses whatsapp list so we can show the confirmation message accordingly
   */
  hasList?: boolean
  image?: string | null
  info: string
  item: Pick<WhatsAppMessage | WhatsAppQuestion, 'message'>
  label: string
  personalizationList: PersonalizationType
  required?: boolean
  text?: string | null
  video?: string | null
  changeHandler: (value: ContentWhatsApp) => void
  onTabChange?: (tab: WhatsAppTab) => void
  removeMedia: () => void
  saveHandler: (file: FileUploadType) => Promise<void>
}

const WhatsApp: React.FC<Props> = (props) => {
  const {
    autoFocus,
    editable,
    enableMediaCaption,
    files = {},
    hasButtons,
    hasList,
    info,
    label,
    loading = false,
    personalizationList,
    required = true,
    changeHandler,
    getFile,
    onTabChange,
    removeMedia = noop,
    saveHandler,
  } = props
  const { classes } = useStyles()
  const imageUploadRef = React.useRef<DropzoneRef>(null)
  const documentUploadRef = React.useRef<DropzoneRef>(null)
  const videoUploadRef = React.useRef<DropzoneRef>(null)
  const videoPlayerRef = React.useRef<HTMLVideoElement>(null)
  const [image, setImage] = React.useState(pathOr('', ['image'], props))
  const [video, setVideo] = React.useState(pathOr('', ['video'], props))
  const [audio, setAudio] = React.useState(pathOr('', ['audio'], props))
  const [document, setDocument] = React.useState(pathOr('', ['document'], props))
  const [tab, setTab] = React.useState(loadDefaultTab(props))
  const [text, setText] = React.useState(pathOr('', ['text'], props))
  const [error, setError] = React.useState('')
  const [videoLoading, setVideoLoading] = React.useState(false)
  const [videoFile, setVideoFile] = React.useState('')
  const [videoError, setVideoError] = React.useState(false)
  const [confirmationDialog, setConfirmationDialog] = React.useState<{
    display: boolean
    tempTab?: WhatsAppTab
  }>({
    display: false,
  })

  useGetFile(props, 'image')

  const message = pathOr<ContentWhatsApp>({}, ['item', 'message'], props)

  React.useEffect(() => {
    setAudio((s) => {
      if (message.audio !== undefined && message.audio !== s) {
        return message.audio || ''
      }
      return s
    })
  }, [message.audio])
  React.useEffect(() => {
    setDocument((s) => {
      if (message.document !== undefined && message.document !== s) {
        return message.document || ''
      }
      return s
    })
  }, [message.document])
  React.useEffect(() => {
    setImage((s) => {
      if (message.image !== undefined && message.image !== s) {
        return message.image || ''
      }
      return s
    })
  }, [message.image])
  React.useEffect(() => {
    setText((s) => {
      if (message.text !== undefined && message.text !== s) {
        return message.text || ''
      }
      return s
    })
  }, [message.text])
  React.useEffect(() => {
    setVideo((s) => {
      if (message.video !== undefined && message.video !== s) {
        return message.video || ''
      }
      return s
    })
  }, [message.video])

  React.useEffect(() => {
    setAudio((s) => {
      if (props.audio !== undefined && props.audio !== s) {
        return props.audio || ''
      }
      return s
    })
  }, [props.audio])

  React.useEffect(() => {
    setDocument((s) => {
      if (props.document !== undefined && props.document !== s) {
        return props.document || ''
      }
      return s
    })
  }, [props.document])

  React.useEffect(() => {
    setImage((s) => {
      if (props.image !== undefined && props.image !== s) {
        return props.image || ''
      }
      return s
    })
  }, [props.image])

  React.useEffect(() => {
    setVideo((s) => {
      if (props.video !== undefined && props.video !== s) {
        return props.video || ''
      }
      return s
    })
  }, [props.video])

  React.useEffect(() => {
    if (tab === WhatsAppTab.Audio) {
      setText('')
    }
  }, [tab])

  React.useEffect(() => {
    if (videoError) {
      setTimeout(() => {
        setVideoError(false)
      }, 3000)
    }
  }, [videoError])

  const videoPath = pathOr('', ['video'], props)
  const downloadVideo = React.useCallback(async () => {
    setVideoLoading(true)
    const videoURL = await getFile(videoPath)
    if (videoURL instanceof Error) {
      setVideoError(true)
    } else {
      setVideoFile(videoURL.url)
      setTimeout(() => {
        if (videoPlayerRef && videoPlayerRef.current) {
          videoPlayerRef.current.play()
        }
      }, 500)
    }
    setVideoLoading(false)
  }, [videoPath, getFile])

  const handleMessageTypeSwitch = (selectedTab?: WhatsAppTab) => {
    if (!selectedTab) {
      return
    }

    setImage('')
    setAudio('')
    setVideo('')
    setDocument('')
    removeMedia()
    setTab(selectedTab)
    if (onTabChange) {
      onTabChange(selectedTab)
    }
    setConfirmationDialog({
      display: false,
    })
  }

  const imageFile = image ? pathOr('', [image, 'url'], files) : ''

  const confirmationText = React.useMemo(() => {
    if (!hasButtons && !hasList) {
      return 'Switching message types will discard your existing content. Do you want to continue?'
    }
    if (hasButtons) {
      return 'Only text message can be used with WhatsApp Buttons, switching message types will discard your existing content, remove the buttons and change the message response to Specific Response. Do you want to continue?'
    }
    return 'Only text message can be used with a WhatsApp List, switching message types will discard your existing content, remove the list and change the message response to Specific Response. Do you want to continue?'
  }, [hasButtons, hasList])

  const renderCaption = useCallback(() => {
    if (!enableMediaCaption) {
      return null
    }
    return (
      <Message
        label="Caption"
        message={text || ''}
        whatsapp={true}
        personalizationList={personalizationList}
        onChange={({ message: updatedValue }) => {
          setText(updatedValue)
          changeHandler({
            text: updatedValue,
          })
        }}
      />
    )
  }, [enableMediaCaption, personalizationList, text, changeHandler])

  const renderText = useCallback(
    () => (
      <>
        {info && (
          <div
            style={{
              marginTop: '5px',
            }}
          >
            <IconText>
              <Icon>info</Icon>
              <Typography variant="caption" color="textSecondary">
                {info}
              </Typography>
            </IconText>
          </div>
        )}
        <Message
          autoFocus={autoFocus}
          editable={editable}
          label={`WhatsApp ${label}`}
          message={text || ''}
          whatsapp={true}
          error={error}
          personalizationList={personalizationList}
          onChange={({ message: updatedValue }) => {
            setText(updatedValue)
            setError(updatedValue ? '' : error)
            changeHandler({
              text: updatedValue,
            })
          }}
          onBlur={({ message: value }) => {
            if (!value && required) {
              setError('WhatsApp message needs to have text added')
            }
          }}
        />
      </>
    ),
    [autoFocus, editable, error, info, label, personalizationList, required, text, changeHandler],
  )
  const renderImage = useCallback(
    () => (
      <>
        <div className={classes.uploadContainer} style={{ display: imageFile ? 'none' : 'flex' }}>
          <UploadWrapper loading={loading}>
            <Uploader
              accept={imageAcceptList}
              currentFile={imageFile}
              extensions={imageExtensionList}
              files={files}
              namespace="whatsapp-image"
              uploadRef={imageUploadRef}
              onAccept={({ file }: { file: File }) => {
                saveHandler({
                  file,
                  filename: file.name,
                  type: 'image',
                })
              }}
            />
          </UploadWrapper>
        </div>
        <div
          className={classes.uploadContainer}
          style={{ display: imageFile ? 'flex' : 'none' }}
          onClick={() => imageUploadRef.current?.open()}
        >
          <UploadWrapper loading={loading}>
            <img className={classes.previewImage} alt="WhatsApp" src={imageFile} />
            <div className={classes.editOverlay}>
              <Button variant="contained" size="small" color="secondary" startIcon={<Icon>edit</Icon>}>
                Edit
              </Button>
            </div>
          </UploadWrapper>
        </div>
        {renderCaption()}
      </>
    ),
    [
      classes.editOverlay,
      classes.previewImage,
      classes.uploadContainer,
      files,
      imageFile,
      loading,
      renderCaption,
      saveHandler,
    ],
  )
  const renderAudio = useCallback(
    () => (
      <Voice
        {...props}
        filterType="whatsapp"
        item={{
          id: 'whatsapp-audio',
          audio: {
            playfile: audio,
            translations: {},
          },
          changed: false,
        }}
        changeHandler={({ audio: updatedAudio }) => {
          // only audio is updated, so it won't change the type of the message
          if (!updatedAudio) {
            removeMedia()
          }
          changeHandler({
            text: null,
            image: null,
            video: null,
            audio: updatedAudio ? updatedAudio.playfile : '',
          })
        }}
      />
    ),
    [audio, props, changeHandler, removeMedia],
  )
  const renderVideo = useCallback(
    () => (
      <>
        <div className={classes.uploadContainer} style={{ display: video ? 'none' : 'flex' }}>
          <UploadWrapper loading={loading}>
            <Uploader
              accept={videoAcceptList}
              currentFile={videoFile}
              extensions={videoExtensionList}
              files={files}
              maxFileSize="16mb"
              namespace="whatsapp-video"
              uploadRef={videoUploadRef}
              onAccept={({ file }: { file: File }) => {
                setVideoFile('')
                saveHandler({
                  file,
                  filename: file.name,
                  type: 'video',
                })
              }}
            />
          </UploadWrapper>
        </div>
        <div className={classes.uploadContainer} style={{ display: video ? 'flex' : 'none' }}>
          <UploadWrapper loading={loading}>
            {videoLoading ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              <>
                {videoFile ? (
                  <video ref={videoPlayerRef} width="400" height="200" controls src={videoFile} />
                ) : (
                  <>
                    <img
                      className={classes.previewImage}
                      src={pathOr('', [`thumb/${props.video}`, 'url'], files)}
                      onError={(res) => {
                        const { currentTarget } = res
                        currentTarget.onerror = null
                        currentTarget.src = '/static/no-preview-available.svg'
                      }}
                    />
                    <div className={classes.playOverlay}>
                      <div className={classes.playCont}>
                        <IconButton id="whatsapp-video-play-btn" className={classes.playBtn} onClick={downloadVideo}>
                          <Icon>play_arrow</Icon>
                        </IconButton>
                      </div>
                    </div>
                  </>
                )}
                <div className={classes.editOverlay}>
                  <Button
                    id="whatsapp-edit-btn"
                    className={classes.editBtn}
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => videoUploadRef.current?.open()}
                    startIcon={<Icon>edit</Icon>}
                  >
                    Replace
                  </Button>
                </div>
                {videoError && <Alert severity="warning">Couldn't load the video. Please try again later.</Alert>}
              </>
            )}
          </UploadWrapper>
        </div>
        {renderCaption()}
      </>
    ),
    [
      classes.editBtn,
      classes.editOverlay,
      classes.playBtn,
      classes.playCont,
      classes.playOverlay,
      classes.previewImage,
      classes.uploadContainer,
      files,
      loading,
      props.video,
      video,
      videoError,
      videoFile,
      videoLoading,
      downloadVideo,
      renderCaption,
      saveHandler,
    ],
  )
  const renderDocument = useCallback(
    () => (
      <>
        <div className={classes.uploadContainer} style={{ display: document ? 'none' : 'flex' }}>
          <UploadWrapper loading={loading}>
            <Uploader
              accept={documentAcceptList}
              currentFile={document}
              extensions={documentExtensionList}
              files={files}
              namespace="whatsapp-document"
              uploadRef={documentUploadRef}
              onAccept={({ file }: { file: File }) => {
                saveHandler({
                  file,
                  filename: file.name,
                  type: 'document',
                })
              }}
            />
          </UploadWrapper>
        </div>
        <div
          className={classes.uploadContainer}
          style={{ display: document ? 'flex' : 'none' }}
          onClick={() => documentUploadRef.current?.open()}
        >
          <UploadWrapper loading={loading}>
            <img className={classes.documentPlaceholder} alt="WhatsApp PDF" src="/static/pdf-icon.png" />
            <div style={{ textAlign: 'center' }}>
              <Typography>{document}</Typography>
            </div>
            <div className={classes.editOverlay}>
              <Button variant="contained" size="small" color="secondary" startIcon={<Icon>edit</Icon>}>
                Edit
              </Button>
            </div>
          </UploadWrapper>
        </div>
        {renderCaption()}
      </>
    ),
    [
      classes.documentPlaceholder,
      classes.editOverlay,
      classes.uploadContainer,
      document,
      files,
      loading,
      renderCaption,
      saveHandler,
    ],
  )

  return (
    <div className="whatsapp-cont">
      <ConfirmationDialog
        id="whatsapp-message-type-switch-dialog"
        text={confirmationText}
        onClose={() => setConfirmationDialog({ display: false })}
        onConfirm={() => handleMessageTypeSwitch(confirmationDialog.tempTab)}
        isOpen={confirmationDialog.display}
        deleteButtonText="Confirm"
        title="Change WhatsApp message type"
        icon="sms"
      />
      <Tabs
        value={tab}
        indicatorColor="primary"
        onChange={(e, selectedTab: WhatsAppTab) => {
          if (image || text || video || audio || document) {
            const showDialog = shouldDisplayConfirmation(
              selectedTab,
              {
                audio,
                document,
                image,
                text,
                video,
              },
              !!(hasButtons || hasList),
            )

            if (showDialog) {
              setConfirmationDialog({
                display: true,
                tempTab: selectedTab,
              })
              return
            }
          }
          handleMessageTypeSwitch(selectedTab)
        }}
      >
        {tabNames.map(({ label: tabLabel, value }, index) => (
          <Tab label={tabLabel} key={`tab-${index}`} id={`tab-${index}`} value={value} />
        ))}
      </Tabs>
      {tab === WhatsAppTab.Text && renderText()}
      {tab === WhatsAppTab.Image && renderImage()}
      {tab === WhatsAppTab.Audio && renderAudio()}
      {tab === WhatsAppTab.Video && renderVideo()}
      {tab === WhatsAppTab.Document && renderDocument()}
    </div>
  )
}

const UploadWrapper: React.FC<PropsWithChildren<{ loading: boolean }>> = ({ loading, children }) => (
  <div
    style={{
      position: 'relative',
    }}
  >
    {children}
    {loading && <Loader />}
  </div>
)

export const tabNames = [
  {
    label: 'Text Message',
    value: 'text',
  },
  {
    label: 'Image',
    value: 'image',
  },
  {
    label: 'Voice',
    value: 'audio',
  },
  {
    label: 'Video',
    value: 'video',
  },
  {
    label: 'PDF',
    value: 'document',
  },
]

export const imageAcceptList = ['jpg', 'jpeg', 'png', 'image/jpeg', 'image/png']

export const documentAcceptList = ['pdf', 'application/pdf']

export const imageExtensionList = ['jpg', 'jpeg', 'png']

export const documentExtensionList = ['pdf']

export const voiceAcceptList = ['.mp3', '.wav', '.ogg', 'audio/ogg', 'audio/wav', 'audio/webm']

export const voiceExtensionList = ['wav', 'mp3', 'ogg']
export const videoAcceptList = ['video/mp4', 'mp4']

export const videoExtensionList = ['mp4']

const useStyles = makeStyles()(() => ({
  uploadContainer: {
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: '20px',
    cursor: 'pointer',
  },
  previewImage: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  documentPlaceholder: {
    maxHeight: '120px',
  },
  previewContainer: {
    position: 'relative',
  },
  editOverlay: {
    position: 'absolute',
    top: '2px',
    right: '2px',
  },
  editBtn: {
    boxShadow: 'unset',
    backgroundColor: '#fff',
    border: 'none',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
    },
  },
  playOverlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
  },
  playCont: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  playBtn: {
    color: '#fff',
    backgroundColor: '#2f80ac',
    '&:hover': {
      backgroundColor: 'rgba(47, 128, 172, 0.85)',
    },
  },
}))

export const useGetFile = (props: Props, type: 'audio' | 'document' | 'image' | 'video') => {
  const { getFile } = props

  const prev = usePrevious(props[type])
  const current = props[type]

  React.useEffect(() => {
    // prevent infinite loop since getFile is not memorized in the parent component
    if (current && current !== prev) {
      getFile(current)
    }
  }, [current, prev, getFile])
}

export default WhatsApp
