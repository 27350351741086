import IconText from '@/app/component/layout/icon-text'
import {
  ChoiceListType,
  LocalAction,
  LocalActionType,
  LocalNonEmptyAction,
  LocalSpoken,
} from '@/app/module/campaigns/types'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import { append, compose, equals, identity, ifElse, insert, insertAll, length, move, pick, remove, update } from 'ramda'
import React from 'react'
import { v1 as uuid } from 'uuid'
import { cannot } from '@/app/helpers'
import { generateActionFormValue } from '@/app/module/campaigns/utils/actions/form'
import ChoiceLabel from '@/app/module/campaigns/component/item/steps/content/question/choice/choice-label'
import AddFromLibrary from '@/app/module/campaigns/component/item/steps/content/question/dialog/add-from-library'
import ActionList from './action-list'
import ActionDialogWrapper from './action-dialog-wrapper'
import { ActionsBoxProps } from './actions-box-props'

export type QuestionBoxValue = {
  label?: string
  choiceList?: ChoiceListType
  actionsList?: LocalAction[]
  speech?: boolean
  spoken?: LocalSpoken
}

type State = {
  addFromLibrarySource?: number
  dialogActive?: LocalActionType
  dialogSource?: number
  label?: string
}

export class ActionsBox extends React.PureComponent<ActionsBoxProps, State> {
  constructor(props: ActionsBoxProps) {
    super(props)

    this.state = {
      addFromLibrarySource: undefined,
      dialogActive: undefined,
      dialogSource: undefined,
      label: props.label,
    }
  }

  addToLibrary = async (action: LocalNonEmptyAction) => {
    const { saveSnippet, orgId, token } = this.props
    await saveSnippet({
      orgId,
      token,
      item: {
        category: 'action',
        snippet: action,
      },
    })
  }

  handleActionChange = (value: 'addFromLibrary' | LocalActionType, index: number) => {
    if (value === 'addFromLibrary') {
      this.setState({
        addFromLibrarySource: index,
      })

      return
    }

    const { actions, onChange } = this.props

    switch (value) {
      case LocalActionType.API:
      case LocalActionType.Jump:
      case LocalActionType.PlayAudio:
      case LocalActionType.ResumeSubscription:
      case LocalActionType.SendEmail:
      case LocalActionType.SendSMS:
      case LocalActionType.SendSMSToPhoneNumbers:
      case LocalActionType.SendWhatsApp:
      case LocalActionType.SubscribeToCampaign:
      case LocalActionType.Topup:
      case LocalActionType.Transfer:
      case LocalActionType.UpdateContact:
        this.setState({
          dialogSource: index,
          dialogActive: value,
        })
        break

      case LocalActionType.FinishFlow:
      case LocalActionType.Hangup:
      case LocalActionType.PauseSubscription:
      case LocalActionType.Replay:
      case LocalActionType.Resend:
      case LocalActionType.UnsubscribeAll: {
        onChange(updateActionsAtIndex(actions, index, generateActionFormValue(value)))
        break
      }

      case LocalActionType.Unsubscribe: {
        // do nothing as it's not supported yet
        break
      }

      default:
        cannot(value)
        break
    }
  }

  handleActionMove = (from: number, to: number) => {
    const { actions, onChange } = this.props

    // last action is empty action and cannot be moved
    const last = actions.length - 1
    if (from !== last && to !== last) {
      onChange(move(from, to, actions))
    }
  }

  handleActionRemove = (index: number) => {
    const { actions, onChange } = this.props

    // last action is empty action and cannot be removed
    const isLast = index === actions.length - 1
    if (actions[index].type && !isLast) {
      onChange(remove(index, 1, actions))
    }
  }

  handleLabelChange = (label: string) => {
    this.setState({ label })
    this.props.onLabelChange?.(label)
  }

  renderAddFromLibraryDialog = () => {
    const { actionOptions, actions, orgId, personalizationList, snippets, token, getSnippets, onChange } = this.props
    const { addFromLibrarySource } = this.state

    if (typeof addFromLibrarySource !== 'number') {
      return null
    }

    return (
      <AddFromLibrary
        orgId={orgId}
        personalizationList={personalizationList}
        snippets={snippets}
        token={token}
        valuesList={actionOptions}
        getSnippets={getSnippets}
        onClose={() => {
          this.setState({
            addFromLibrarySource: undefined,
          })
        }}
        onSave={(value = []) => {
          const newValue = value.map((item) => ({
            ...item,
            id: uuid(),
          }))
          this.setState(
            {
              addFromLibrarySource: undefined,
            },
            () => {
              const newActions = compose(
                ifElse(compose(equals((addFromLibrarySource || 0) + 1), length), append({ type: '' }), identity),
                insertAll(addFromLibrarySource || 0, newValue),
              )(actions)

              onChange(newActions)
            },
          )
        }}
      />
    )
  }

  renderActionDialog = () => {
    const { actions, onChange } = this.props
    const { dialogActive, dialogSource } = this.state
    if (!dialogActive || typeof dialogSource !== 'number') {
      return null
    }
    const action = actions[dialogSource]
    if (!action) {
      return null
    }
    // if current action type is different from selected dialog type, generate a new action with the dialog type
    const actionValue = action.type === dialogActive ? action : generateActionFormValue(dialogActive)

    const dialogProps = pick(
      [
        'campaignIds',
        'countriesTimezones',
        'countryCode',
        'defaultLanguage',
        'defaultSenderId',
        'hasTranscribe',
        'imageId',
        'index',
        'languages',
        'orgId',
        'personalizationList',
        'phoneNumber',
        'senderIds',
        'timezone',
        'timezones',
        'token',
        'transcribeEnabled',
        'updateContactConfig',
        'voiceId',
        'getCampaigns',
        'getImageProps',
        'getVoiceProps',
      ],
      this.props,
    )

    return (
      <ActionDialogWrapper
        {...dialogProps}
        action={actionValue}
        onClose={() =>
          this.setState({
            dialogActive: undefined,
            dialogSource: undefined,
          })
        }
        onSave={(value: Omit<LocalNonEmptyAction, 'id' | 'type'>) => {
          const newAction = {
            id: actionValue.id,
            type: dialogActive,
            ...value,
          } as LocalNonEmptyAction
          this.setState(
            {
              dialogActive: undefined,
              dialogSource: undefined,
            },
            () => {
              const newActions = updateActionsAtIndex(actions, dialogSource, newAction)
              onChange(newActions)
            },
          )
        }}
      />
    )
  }

  render() {
    const {
      actionOptions,
      actions,
      children,
      disableLabel,
      dragContext,
      files,
      info,
      isCloseDisabled,
      personalizationList,
      onClose,
    } = this.props
    const { label } = this.state

    const isClosable = typeof onClose === 'function'
    const hasLabel = !disableLabel

    return (
      <div
        style={{
          backgroundColor: 'rgb(245, 245, 245)',
          padding: '12px',
          margin: '12px 0',
        }}
      >
        {this.renderAddFromLibraryDialog()}
        {this.renderActionDialog()}
        {isClosable && (
          <Grid container justifyContent={hasLabel ? 'space-between' : 'flex-end'}>
            {hasLabel && (
              <Grid
                item
                style={{
                  height: '32px',
                }}
              >
                <ChoiceLabel label={label || ''} onChange={this.handleLabelChange} />
              </Grid>
            )}
            <Grid item>
              <IconButton
                className="campaign-question-onAnswer-choice-delete-btn"
                data-testid="campaign-question-answer-delete-btn"
                onClick={onClose}
                disabled={isCloseDisabled}
                size="small"
              >
                <Icon fontSize="small">close</Icon>
              </IconButton>
            </Grid>
          </Grid>
        )}
        {children}
        <ActionList
          actionOptions={actionOptions}
          actions={actions}
          dragContext={dragContext}
          files={files}
          personalizationList={personalizationList}
          onActionAddToLibrary={this.addToLibrary}
          onActionChange={this.handleActionChange}
          onActionMove={this.handleActionMove}
          onActionRemove={this.handleActionRemove}
        />
        {info && (
          <div
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              padding: '0.35rem 0',
            }}
          >
            <Divider style={{ margin: '1rem -12px' }} />
            <IconText>
              <Icon>info</Icon>
              {info}
            </IconText>
          </div>
        )}
      </div>
    )
  }
}

const updateActionsAtIndex = (actions: LocalAction[], index: number, action: LocalNonEmptyAction) => {
  if (index === actions.length - 1) {
    // last action is the placeholder action, so we need to insert the new action before it
    return insert(index, action, actions)
  }

  return update(index, action, actions)
}

export default ActionsBox
