import Typography from '@mui/material/Typography'
import { append, path, pathOr, reduce, update } from 'ramda'
import React from 'react'
import { Checkbox } from '@/app/component/atom/form'
import { SMS_ACTION_DEFAULT_SENDERID } from '@/app/module/campaigns/definitions'
import { reduceIndexed } from '@/app/module/campaigns/helpers'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'
import QuestionBox from '@/app/module/campaigns/component/item/steps/content/question'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { CountryTimezone, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'
import {
  CampaignTemplateType,
  SnippetType,
  PersonalizationType,
  SenderIdOption,
  CampaignQuery,
  VoiceProps,
  LocalAction,
  ConditionActions,
  PartFamily,
} from '@/app/module/campaigns/types'
import { Action } from '@/app/module/campaigns/types/schemas/actions/all-actions'

type CallResultKey = 'answered' | 'notAnswered' | 'error'

type CallResultActionsContext = ActionContext.CallAnswered | ActionContext.CallNotAnswered | ActionContext.CallError

const resultMap: Record<CallResultKey, string> = {
  answered: 'Answered',
  notAnswered: 'Not answered',
  error: 'Error',
}

const actionContextMap: Record<CallResultKey, CallResultActionsContext> = {
  answered: ActionContext.CallAnswered,
  notAnswered: ActionContext.CallNotAnswered,
  error: ActionContext.CallError,
}

const findMatchingCallResultActions = ({ result, actions }: { result: CallResultKey; actions: ConditionActions[] }) =>
  reduce<ConditionActions, ConditionActions | null>(
    (accum, elem) => {
      if (path(['when', 2], elem) === result) {
        return elem
      }
      return accum
    },
    null,
    actions,
  )

const updateCallResultActions = ({
  result,
  value,
  actions,
}: {
  result: CallResultKey
  value: Action[]
  actions: ConditionActions[]
}) => {
  const actionTemplate: ConditionActions = {
    when: ['==', '{{call.result}}', result],
    actions: value,
  }
  const callResult = reduceIndexed<number | null, ConditionActions>(
    (accum, elem, index) => {
      if (path(['when', 2], elem) === result) {
        return index
      }
      return accum
    },
    null,
    actions,
  )
  if (callResult !== null) {
    return update(callResult, actionTemplate, actions)
  }
  return append(actionTemplate, actions)
}

type CallResultActionsProps = {
  campaignType: CampaignTemplateType
  changeHandler: (p: { value: ConditionActions[] }) => void
  personalizationList: PersonalizationType
  senderIds: SenderIdOption[]
  getCampaigns: (payload: { token: string; orgId: number; query: CampaignQuery }) => Promise<void>
  orgId: number
  token: string
  campaignIds: SelectOption<number>[]
  getVoiceProps: (qid: string, enableTTS?: boolean) => VoiceProps
  actions: ConditionActions[]
  getSnippets: (payload: { token: string; orgId: number }) => Promise<void>
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  saveSnippet: (payload: {
    orgId: number
    token: string
    item: Pick<SnippetType, 'category' | 'snippet'>
  }) => Promise<SnippetType>
  timezone: string
  timezones: TimezoneWithCountryCodeType
  countriesTimezones: CountryTimezone[]
  countryCode: string
}

export default function CallResultActions(props: CallResultActionsProps) {
  const { actions } = props
  const changeHandler = ({ result, value }: { result: CallResultKey; value: Action[] }) => {
    props.changeHandler({
      value: updateCallResultActions({
        result,
        value,
        actions,
      }),
    })
  }
  return (
    <div>
      <Typography variant="subtitle1" color="textSecondary">
        Specify what we should do if the call is (not) answered or cannot be made. We will take these actions only after
        all call retries are done.
      </Typography>
      <CallResult
        result="answered"
        {...props}
        actions={actions}
        changeHandler={({ result, value }) =>
          changeHandler({
            result,
            value,
          })
        }
      />
      <CallResult
        result="notAnswered"
        {...props}
        actions={actions}
        changeHandler={({ result, value }) =>
          changeHandler({
            result,
            value,
          })
        }
      />
      <CallResult
        result="error"
        {...props}
        actions={actions}
        changeHandler={({ result, value }) =>
          changeHandler({
            result,
            value,
          })
        }
      />
    </div>
  )
}

type CallResultProps = Omit<CallResultActionsProps, 'changeHandler'> & {
  result: CallResultKey
  changeHandler: (p: { value: Action[]; result: CallResultKey }) => void
}

function CallResult({
  result,
  campaignType,
  changeHandler,
  personalizationList = {},
  senderIds = [],
  getCampaigns,
  orgId,
  token,
  campaignIds = [],
  getVoiceProps,
  actions: propActions = [],
  getSnippets,
  snippets,
  saveSnippet,
  timezones,
  timezone,
  countriesTimezones,
  countryCode,
}: CallResultProps) {
  const actions = unwrapActions(
    pathOr([], ['actions'], findMatchingCallResultActions({ result, actions: propActions })),
  )
  const [selected, setSelected] = React.useState('false')

  const actionValues = React.useMemo(() => {
    return getActionOptionsForCampaign({
      campaignType,
      partType: PartFamily.Voice,
      actionNamespace: actionContextMap[result],
    })
  }, [campaignType, result])

  React.useEffect(() => {
    if (actions.length > 1) {
      setSelected('true')
    }
  }, [actions])

  return (
    <React.Fragment>
      <Checkbox
        label={resultMap[result]}
        name={result}
        value={selected}
        editable={true}
        onChange={({ value }) => {
          if (value !== 'true') {
            changeHandler({ value: [], result })
          }
          setSelected(value)
        }}
      />
      {selected === 'true' && (
        <QuestionBox
          dragContext="call-result-actions"
          actionsList={actions}
          actionsValues={actionValues}
          changeHandler={({ actionsList }: { actionsList: LocalAction[] }) => {
            changeHandler({ value: wrapActions(actionsList || []), result })
          }}
          personalizationList={personalizationList}
          senderIds={senderIds}
          defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
          getCampaigns={getCampaigns}
          orgId={orgId}
          token={token}
          campaignIds={campaignIds}
          getVoiceProps={getVoiceProps}
          getSnippets={getSnippets}
          snippets={snippets}
          saveSnippet={saveSnippet}
          timezones={timezones}
          timezone={timezone}
          countriesTimezones={countriesTimezones}
          countryCode={countryCode}
        />
      )}
    </React.Fragment>
  )
}
