import LinearProgress from '@mui/material/LinearProgress'
import { makeStyles } from 'tss-react/mui'

type Props = {
  autoHideDuration: number
}

export default function LinearProgressWithTimer(props: Props) {
  const { classes } = useStyles(props)

  return (
    <div className={classes.root}>
      <LinearProgress
        classes={{
          bar1Indeterminate: classes.bar1Indeterminate,
          bar2Indeterminate: classes.bar2Indeterminate,
        }}
        variant="indeterminate"
      />
    </div>
  )
}

const useStyles = makeStyles<Props>()((_, { autoHideDuration }) => ({
  root: {
    width: '100%',
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  bar1Indeterminate: {
    width: 'auto',
    animation: '$indeterminate1 linear forwards',
    animationDuration: `${autoHideDuration - 500}ms`,
  },
  bar2Indeterminate: {
    display: 'none',
  },
  '@keyframes indeterminate1': {
    '0%': {
      left: '-35%',
      right: '100%',
    },
    '100%': {
      left: '0%',
      right: '0%',
    },
  },
}))
