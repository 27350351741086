import { mergeAll, pathOr } from 'ramda'

export const selectHooks = (phoneNumbers) => pathOr([], ['item', 'data', 'hooks'], phoneNumbers).map((hook) => hook.id)

export const selectHooksAsObject = (phoneNumbers) =>
  mergeAll(
    pathOr([], ['item', 'data', 'hooks'], phoneNumbers).map((hook) => ({
      [hook.id]: hook,
    })),
  )

export const selectWhatsappHooks = (phoneNumbers) =>
  pathOr([], ['item', 'data', 'whatsappHooks'], phoneNumbers).map((hook) => hook.id)

export const selectWhatsappHooksAsObject = (phoneNumbers) =>
  mergeAll(
    pathOr([], ['item', 'data', 'whatsappHooks'], phoneNumbers).map((hook) => ({
      [hook.id]: hook,
    })),
  )

export const selectCallHook = (phoneNumbers) => pathOr({}, ['item', 'data', 'callHooks', 0], phoneNumbers)

export const selectPhoneNumber = (phoneNumbers) => pathOr('', ['item', 'data'], phoneNumbers)

export const selectActions = (rules) => pathOr([], ['actions'], rules)

export const selectLoading = ({ phoneNumbers, campaigns }) =>
  pathOr(false, ['item', 'loading'], phoneNumbers) || pathOr(false, ['list', 'loading'], campaigns)
