import React, { Component } from 'react'

type Props = {
  audioData: Uint8Array
  height: number
  width: number
}

export default class AudioVisualiser extends Component<Props> {
  canvas: React.RefObject<HTMLCanvasElement>

  constructor(props: Props) {
    super(props)
    this.canvas = React.createRef()
  }

  componentDidUpdate() {
    this.draw()
  }

  draw() {
    if (!this.canvas.current) {
      return
    }

    const { audioData } = this.props
    const canvas = this.canvas.current
    const { height, width } = canvas
    const context = canvas.getContext('2d')

    if (!context) {
      return
    }
    let x = 0
    const sliceWidth = (width * 1.0) / audioData.length

    context.lineWidth = 1
    context.strokeStyle = 'rgba(0, 0, 0, 0.12)'
    context.clearRect(0, 0, width, height)

    context.beginPath()
    context.moveTo(0, height / 2)
    audioData.forEach((item) => {
      const y = (item / 255.0) * height
      context.lineTo(x, y)
      x += sliceWidth
    })
    context.lineTo(x, height / 2)
    context.stroke()
  }

  render() {
    const { width, height } = this.props

    return <canvas ref={this.canvas} width={width} height={height} />
  }
}
