import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import { makeStyles } from 'tss-react/mui'
import { FunctionType } from '@/config/types'
import HookForm, { GroupedFieldType } from '@/app/component/atom/hook-form'
import CustomDialog from '@/app/component/wrapper/custom-dialog'

const useStyles = makeStyles()(() => ({
  autoRecharge: {
    margin: '10px 0px 5px',
  },
  closeButton: {
    float: 'right',
  },
}))

type Props = {
  onClose: FunctionType
  onSave: FunctionType
  loading: boolean
}

type FormData = {
  name: string
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  country: string
  amount: number
  date: string
  line1: string
  line2: string
}

const groupedFields: GroupedFieldType[] = [
  {
    groupLabel: 'Basic information',
    groupFields: [
      {
        key: 'name',
        label: 'Name',
        defaultValue: '',
        required: true,
        type: 'text',
        placeholder: 'John Doe',
      },
      {
        key: 'address1',
        label: 'Address 1',
        defaultValue: '',
        required: true,
        type: 'text',
        placeholder: '1234 Main Street',
      },
      {
        key: 'address2',
        label: 'Address 2',
        defaultValue: '',
        required: false,
        type: 'text',
        placeholder: 'Apt 6, 4th floor',
      },
      {
        key: 'city',
        label: 'City',
        defaultValue: '',
        required: true,
        type: 'text',
        placeholder: 'Metropolis',
      },
      {
        key: 'state',
        label: 'State',
        defaultValue: '',
        required: true,
        type: 'text',
        placeholder: 'Kansas',
      },
      {
        key: 'postalCode',
        label: 'Postal Code',
        defaultValue: '',
        required: true,
        type: 'text',
        placeholder: '10345',
      },
      {
        key: 'country',
        label: 'Country',
        defaultValue: '',
        required: true,
        type: 'text',
        placeholder: 'USA',
      },
      {
        key: 'amount',
        label: 'Amount',
        defaultValue: 0,
        required: true,
        type: 'currency',
        rules: {
          min: 1,
        },
      },
      {
        key: 'date',
        label: 'Date',
        defaultValue: new Date().toISOString(),
        required: true,
        type: 'date',
      },
    ],
  },
  {
    groupLabel: 'Extra information',
    groupFields: [
      {
        key: 'line1',
        label: 'Title',
        defaultValue: '',
        required: false,
        type: 'text',
        placeholder: 'Purchase Order #',
      },
      {
        key: 'line2',
        label: 'Value',
        defaultValue: '',
        required: false,
        type: 'text',
        placeholder: '883',
      },
    ],
  },
]

export default function ProformaInvoice(props: Props) {
  const { onClose, onSave, loading } = props
  const { classes } = useStyles()
  const submitData = (data: FormData) => {
    onSave(data)
  }
  return (
    <CustomDialog open={true} onClose={onClose} aria-labelledby="proforma-invoice-title" fullWidth={true}>
      <DialogTitle id="proforma-invoice-title">
        <span>Proforma Invoice</span>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              margin: 'auto',
              justifyContent: 'center',
            }}
          >
            <HookForm
              loading={loading}
              submitLabel="Generate invoice"
              groupedFields={groupedFields}
              onSubmit={submitData}
            />
          </div>
        </div>
      </DialogContent>
    </CustomDialog>
  )
}
