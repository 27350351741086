import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import React from 'react'
import { CONSENT_COOKIE } from '@/app/definitions'

type Props = {
  cookieName?: string
  cookieOptions?: Cookies.CookieAttributes
  onAccept?: () => void
}

const defaultCookieOptions: Cookies.CookieAttributes = {
  domain: window.location.hostname,
  expires: 365,
  secure: true,
}

const CookieConsent: React.FC<Props> = ({
  cookieName = CONSENT_COOKIE,
  cookieOptions = defaultCookieOptions,
  onAccept,
}) => {
  const [open, setOpen] = React.useState<boolean>(false)

  const handleAccept = () => {
    try {
      Cookies.set(cookieName, 'true', cookieOptions)
      setOpen(false)
      onAccept?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  React.useEffect(() => {
    try {
      const cookie = Cookies.get(cookieName)
      setOpen(!cookie)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }, [cookieName])

  return (
    <Snackbar
      action={
        <Button color="secondary" onClick={handleAccept}>
          Accept & Close
        </Button>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      data-testid="cookie-consent-banner"
      message={
        <Typography>
          This web app uses only essential cookies to provide its service. Read more about this topic in our{' '}
          <Link color="secondary" href="https://www.engagespark.com/cookie-policy/" target="_blank">
            Cookie Policy
          </Link>
          .
        </Typography>
      }
      open={open}
    />
  )
}

export default CookieConsent
