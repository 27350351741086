import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import { styled } from '@mui/material/styles'
import Checkbox from '@/app/component/atom/form/checkbox'
import Radio from '@/app/component/atom/form/radio'
import DateTimeRange from '@/app/component/atom/form/date-time-range'
import { RadioProps, CheckboxProps, DateTimeRangeProps, FilterOption, FieldType } from './types'

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '100%',
})

const FilterContainer = styled('div')({
  display: 'flex',
})

const FilterItem = styled('div')({
  marginRight: '5px',
})

const RadioComponent: React.FC<RadioProps> = ({ name, value, values, onChange }) => (
  <MenuItem>
    <Radio
      name={name}
      none={{ label: 'All', value: '', editable: true }}
      value={value || ''}
      values={values}
      onChange={onChange}
    />
  </MenuItem>
)

const CheckboxComponent: React.FC<CheckboxProps> = ({ name, values, onChange }) => (
  <>
    {values.map(({ id, name: label, value, editable }) => (
      <MenuItem key={`filter-checkbox-${name}-${id}`}>
        <Checkbox
          label={label}
          name={`${name}-${id}`}
          value={value || 'true'}
          editable={editable || true}
          onChange={onChange}
        />
      </MenuItem>
    ))}
  </>
)

const DateTimeRangeComponent: React.FC<DateTimeRangeProps> = ({ name, value, onChange }) => (
  <DateTimeRange name={name} value={value} disableFuture={true} onChange={onChange} />
)

function isValidFieldType(type: string): type is FieldType {
  return ['radio', 'checkbox', 'datetimerange'].includes(type)
}

const FieldComponent: React.FC<FilterOption> = (props) => {
  switch (props.type) {
    case 'radio':
      return <RadioComponent {...props} />
    case 'checkbox':
      return <CheckboxComponent {...props} />
    case 'datetimerange':
      return <DateTimeRangeComponent {...props} />
    default:
      return null
  }
}

type Anchor = {
  name: string
  target: HTMLButtonElement
}

type FiltersProps = {
  data: Record<string, FilterOption>
  onChange: (key: string, filter: Parameters<FilterOption['onChange']>[0]) => void
}

export default function Filters({ data, onChange }: FiltersProps) {
  const [filterMenuAnchor, setFilterMenuAnchor] = React.useState<Anchor | null>()

  return (
    <FilterContainer>
      {Object.keys(data).map((key) => {
        const { name, type } = data[key]
        return (
          <FilterItem key={key}>
            <Button
              variant="outlined"
              onClick={(e) =>
                setFilterMenuAnchor({
                  name,
                  target: e.currentTarget,
                })
              }
              endIcon={<Icon>arrow_drop_down</Icon>}
              startIcon={<Icon>filter_list</Icon>}
            >
              {name}
            </Button>
            <Menu
              MenuListProps={{
                sx: { p: 0 },
              }}
              id="filter-menu"
              open={filterMenuAnchor?.name === name}
              onClose={() => setFilterMenuAnchor(null)}
              anchorEl={filterMenuAnchor?.name === name ? filterMenuAnchor.target : undefined}
            >
              <Form>
                {isValidFieldType(type) && (
                  <FieldComponent
                    {...data[key]}
                    onChange={(filter: Parameters<FilterOption['onChange']>[0]) => {
                      onChange(key, filter)
                      setFilterMenuAnchor(null)
                    }}
                  />
                )}
              </Form>
            </Menu>
          </FilterItem>
        )
      })}
    </FilterContainer>
  )
}
