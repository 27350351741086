import { CampaignSummary } from '@/app/module/campaigns/types'
import { currencyFormat } from '@/app/service/util/format'
import React from 'react'
import OverviewTable from '@/app/component/atom/overview-table'

type Props = {
  data: CampaignSummary
}

const CostOverviewTable: React.FC<Props> = ({ data: campaignSummary }) => {
  const tableData = React.useMemo(() => {
    const data: Array<{ name: string; value: string }> = []

    if (campaignSummary.overallMessageStats.total) {
      data.push({ name: 'SMS cost', value: currencyFormat(campaignSummary.overallMessageStats.costHuman) })
    }
    if (campaignSummary.overallVoiceStats.total) {
      data.push({ name: 'Voice cost', value: currencyFormat(campaignSummary.overallVoiceStats.costHuman) })
    }
    if (campaignSummary.overallWhatsappStats.total) {
      data.push({ name: 'WhatsApp cost', value: currencyFormat(campaignSummary.overallWhatsappStats.costHuman) })
    }
    if (campaignSummary.overallAirtimeStats.total) {
      data.push({ name: 'Topup cost', value: currencyFormat(campaignSummary.overallAirtimeStats.costHuman) })
    }
    data.push({ name: 'Total cost', value: currencyFormat(campaignSummary.costHuman) })

    return data
  }, [campaignSummary])

  return <OverviewTable data={tableData} title="Cost" />
}

export default CostOverviewTable
