import { pathOr } from 'ramda'
import React from 'react'
import { Checkbox } from '@/app/component/atom/form'
import SearchableSelect from '@/app/component/atom/form/searchable-select'
import CustomTooltip from '@/app/component/atom/tooltip'
import { getMessageLabel } from '@/app/module/campaigns/definitions'
import { PartTypes } from '@/app/module/campaigns/types'

type Message = {
  id: string
  label?: string
  index: number
  type: PartTypes
}

type Props = {
  messages: Message[]
  selectedMessage: string
  selectMessage: (messageId: string) => void
}

type UpdateMessageInput = {
  newDelay: boolean
  newMessage: string
}

export default function StartAt(props: Props) {
  const { messages, selectMessage, selectedMessage } = props

  const firstMessage = pathOr('', [0, 'id'], messages)
  const [delay, setDelay] = React.useState(true)

  const updateMessage = ({ newDelay, newMessage }: UpdateMessageInput) => {
    if ((newDelay && newMessage === firstMessage) || (newDelay && newMessage === '')) {
      selectMessage('')
    } else if (newMessage === '') {
      selectMessage(firstMessage)
    } else {
      selectMessage(newMessage)
    }
  }

  return (
    <div>
      <SearchableSelect
        formControlProps={{
          style: {
            margin: 'none',
            width: '100%',
          },
        }}
        labelId="select-message-label"
        value={selectedMessage || firstMessage}
        keyPropFn={(option: any) => option.id}
        valuePropFn={generateLabel}
        onChange={(selectedId: string) => {
          updateMessage({
            newDelay: delay,
            newMessage: selectedId,
          })
        }}
        id="select-message"
        label="Select Message"
        options={messages}
        showAll
        hideRemoveSelection={true}
      />
      <CustomTooltip
        title={
          firstMessage !== selectedMessage && selectedMessage !== '' ? (
            <>
              The campaign will start at the selected message <strong>immediately</strong> unless you add a delay in the
              "When should the subscription start?" section below.
            </>
          ) : (
            ''
          )
        }
      >
        <div>
          <Checkbox
            checkedIcon={undefined}
            name="no-delay-checkbox"
            label="I want the delay in the first message of the Drip Campaign to apply."
            value={delay ? 'true' : 'false'}
            editable={firstMessage === selectedMessage || !selectedMessage}
            onChange={({ value }: any) => {
              setDelay(value === 'true')
              updateMessage({
                newDelay: value === 'true',
                newMessage: selectedMessage,
              })
            }}
            error=""
          />
        </div>
      </CustomTooltip>
    </div>
  )
}

const generateLabel = (option: Message) => {
  const { label, index, type } = option
  if (label) {
    return `${index}. ${label}`
  }
  return `${index}. ${getMessageLabel(type)}`
}
