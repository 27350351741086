import React from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignIds, selectCampaignListLoading } from '@/app/module/campaigns/store/selectors'
import { selectOrgId, selectUserTimezone } from '@/app/module/user/store/selectors'
import { selectToken } from '@/app/module/auth/store/selectors'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { getCampaigns } from '@/app/module/campaigns/store/actions'
import { selectCountriesTimezones, selectDefaultCountryCode } from '@/app/module/utils/store/selectors'
import { pathOr } from 'ramda'
import { OrgReportFilter } from '@/app/module/reports/types'
import OrganizationReportFilter from './organization-report-filter'

type Props = {
  disabled?: boolean
  filter: OrgReportFilter

  onChange?: (filter: OrgReportFilter) => void
}

const OrganizationReportFilterContainer: React.FC<Props> = ({ disabled, filter, onChange }) => {
  const dispatch = useAppDispatch()
  const campaignOptions = useSelector(selectCampaignIds)
  const campaignsLoading = useSelector(selectCampaignListLoading)
  const countriesTimezones = useSelector(selectCountriesTimezones)
  const countryCode = useSelector(selectDefaultCountryCode)
  const orgId = useSelector(selectOrgId)
  const timezone = useSelector(selectUserTimezone)
  const timezones = useSelector(pathOr({}, ['utils', 'countriesTimezonesList', 'data']))
  const token = useSelector(selectToken)

  const [campaignsSearch, setCampaignsSearch] = React.useState<string>('')
  React.useEffect(() => {
    if (campaignsLoading || !orgId || !token) {
      return
    }
    getCampaigns({
      token,
      orgId,
      query: {
        search: campaignsSearch,
        archived: 'true',
        launched: true,
      },
    })(dispatch)
  }, [campaignsLoading, campaignsSearch, orgId, token, dispatch])

  return (
    <OrganizationReportFilter
      campaignOptions={campaignOptions}
      campaignsLoading={campaignsLoading}
      countriesTimezones={countriesTimezones}
      countryCode={countryCode}
      disabled={disabled}
      filter={filter}
      timezone={timezone}
      timezones={timezones}
      onCampaignsSearch={setCampaignsSearch}
      onChange={onChange}
    />
  )
}

export default OrganizationReportFilterContainer
