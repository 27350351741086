import { GlobalStyles } from '@mui/material'
import { replace } from 'ramda'
import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { processInput } from '@/app/module/campaigns/helpers'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { addCurlyBrackets, pzListToArray } from '@/app/module/utils/personalization'
import { hasPhoneError } from '@/app/service/validate'
import PzMenu from '@/app/component/atom/pz-menu'
import InputTag from './input-tag'

type Props = {
  label?: string
  personalizationList?: PersonalizationType
  value: string[]
  onChange: (value: string[]) => void
}

const helpText =
  'Please enter each phone number in international format, e.g., 13105551212, not 3105551212. Press Enter or comma to save each choice'

const PhoneNumberField: React.FC<Props> = ({
  label = 'To',
  personalizationList = {},
  value,
  onChange,
  ...restProps
}) => {
  const [error, setError] = useState('')
  const [inputValue, setInputValue] = useState('')
  const { classes } = useStyles()

  const tags = useMemo(() => fromTags(value, personalizationList), [value, personalizationList]) // use for display only

  const tagsMap = useMemo<{ [key: string]: string }>(
    () => tags.reduce((obj, tag) => ({ ...obj, [tag]: 1 }), {}),
    [tags],
  )

  const updateInput = (input: string) => {
    const tagsValue = processInputValue(input)
    const e = validateInput(tagsValue, tagsMap)

    setError(e)
    if (e) {
      return
    }

    onChange([...tagsValue, ...value])
    setInputValue('')
  }
  const handleChange = (val: string) => {
    setInputValue(val)
    setError(validateInput(processInputValue(val), tagsMap))
  }
  const handleDelete = (index: number) => {
    onChange([...value.slice(0, index), ...value.slice(index + 1)])
  }
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault()
      if (inputValue) {
        updateInput(inputValue)
      }
    }
  }
  const formatTag = (tag: string) => tag.replace(/\{\{(.*?)\}\}/g, (i, match) => match)

  useEffect(() => {
    if (!inputValue && error) {
      setError('')
    }
  }, [inputValue, error])

  return (
    <>
      <GlobalStyles styles={globalStyles} />
      <div className={classes.inputContainer} data-testid="phone-number-field">
        <InputTag
          error={!!error}
          fullWidth
          helperText={<span>{error || helpText}</span>}
          label={label}
          tags={tags}
          value={inputValue}
          formatTag={formatTag}
          onChange={handleChange}
          onDelete={handleDelete}
          onKeyDown={handleKeyDown}
          {...restProps}
        />

        <PzMenu
          id="phone-number-field"
          containerSx={{
            paddingTop: (theme) => theme.spacing(2),
          }}
          personalizationList={personalizationList}
          testId={{
            button: 'phone-number-field-pz-button',
            menu: 'phone-number-field-menu',
          }}
          variant="outlined"
          getTitle={(item) => {
            const selected = !!tagsMap[item.label]
            if (selected) {
              return 'You cannot insert the same custom field twice.'
            }
            if (excludedPz[item.value]) {
              return "This field can't be used because it can't contain a phone number."
            }

            return ''
          }}
          isItemDisabled={(item) => {
            const selected = !!tagsMap[item.label]
            return selected || !!excludedPz[item.value]
          }}
          onSelect={(item) => {
            onChange([...value, addCurlyBrackets(item.value)])
          }}
        />
      </div>
    </>
  )
}

const excludedPz: { [key: string]: number } = {
  'contact.id': 1,
}

const processInputValue = (input: string) => {
  const cleansedInput = replace(/\+/g, '', replace(/(?:\r\n|\r|\n)/g, ',', input))
  return processInput(cleansedInput)
}
const validateInput = (tagsValue: string[], tagsMap: Record<string, string>) => {
  if (!tagsValue.length) {
    return 'Please enter at least one phone number.'
  }

  for (let i = 0; i < tagsValue.length; i += 1) {
    const v = tagsValue[i]
    if (tagsMap[v]) {
      return 'You cannot enter the same phone number twice.'
    }

    if (!v.match(/{{(.*?)}}/) && hasPhoneError(v)) {
      return 'That is not a valid international phone number.'
    }
  }

  return ''
}

const fromTags = (value: string[], pzList: PersonalizationType) => {
  const pzMap: Record<string, string> = pzListToArray(pzList).reduce(
    (obj, pz) => ({ ...obj, [addCurlyBrackets(pz.value)]: pz.label }),
    {},
  )

  return value.map((tag) => {
    const label = pzMap[tag]
    if (label) {
      return label
    }

    return tag
  })
}

const useStyles = makeStyles()(() => ({
  inputContainer: {
    marginBottom: 25,
  },

  menuButton: {
    minWidth: '0',
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
}))

const globalStyles = {
  '.MuiButtonBase-root.Mui-disabled': {
    pointerEvents: 'auto',
  },
}

export default PhoneNumberField
