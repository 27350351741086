/* eslint-disable import/prefer-default-export */
import Cookies from 'js-cookie'
import moment from 'moment'

export const setToken = (authToken: string, validUntil: string) => {
  const expiryDate = validUntil ? moment(validUntil).toDate() : moment().add('7', 'day').toDate()
  Cookies.set('authToken', authToken, { expires: expiryDate, sameSite: 'strict', secure: true })
  Cookies.set('authTokenExpiresAt', expiryDate.toISOString(), { expires: expiryDate, sameSite: 'strict', secure: true })
}

/**
 * return true if the token is 1 day from expiring
 */
export const isTokenAboutToExpire = () => {
  const expiryDate = Cookies.get('authTokenExpiresAt')
  if (!expiryDate) {
    return true
  }
  return moment(expiryDate).subtract(1, 'day').diff(moment()) <= 0
}
