import { Typography } from '@mui/material'
import React from 'react'

type Props = {}

const NoReportPermission: React.FC<Props> = () => {
  return (
    <Typography
      align="center"
      sx={{
        padding: (theme) => theme.spacing(2),
      }}
      variant="h6"
    >
      You don't have permissions to see reports. Please contact a manager of the organization.
    </Typography>
  )
}

export default NoReportPermission
