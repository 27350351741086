import { Grid, Icon, IconButton, InputLabel, Typography } from '@mui/material'
import { reduce } from 'ramda'
import React, { useEffect } from 'react'
import ReactAce from 'react-ace/lib/ace'
import { makeStyles } from 'tss-react/mui'
import { DynamicInputs, Input, InputCode, Select } from '@/app/component/atom/form'
import PzMenu from '@/app/component/atom/pz-menu'
import IconText from '@/app/component/layout/icon-text'
import { useDebouncedFn } from '@/app/helpers'
import validate, { hasPzURLError, hasRequiredError } from '@/app/service/validate'
import { SelectOption } from '@/app/types'
import { addCurlyBrackets } from '@/app/module/utils/personalization'
import { hasBody, validateJSON } from '@/app/module/campaigns/api-helpers'
import { APIMessage, Message, PersonalizationType, SinglePersonalizationType } from '@/app/module/campaigns/types'
import { HTTPMethod, Header, Request } from '@/app/module/campaigns/types-api'

type Props = {
  editable?: boolean
  info?: string
  item: APIMessage
  personalizationList: PersonalizationType

  changeHandler: (item: Partial<Message>, changed?: boolean) => void
}

const CampaignContentAPI: React.FC<Props> = ({ editable = true, info, item, personalizationList, changeHandler }) => {
  const bodyInputRef = React.useRef<ReactAce | null>(null)
  const urlInputRef = React.useRef<HTMLInputElement | null>(null)

  const [errors, setErrors] = React.useState<Record<string, boolean>>(errorsFromProps(item))
  const [headerErrors, setHeaderErrors] = React.useState<Record<string, boolean>[]>(headerErrorsFromProps(item))
  const [request, setRequestState] = React.useState<Request>(requestFromProps(item))
  const { classes } = useStyles()

  React.useEffect(() => {
    setRequestState(requestFromProps(item))
    setErrors(errorsFromProps(item))
    setHeaderErrors(headerErrorsFromProps(item))
  }, [item])

  const itemRef = React.useRef<Partial<Message> | null>(null)
  const changeHandlerRef = React.useRef(changeHandler)

  useEffect(
    () => () => {
      if (itemRef.current) {
        changeHandlerRef.current(itemRef.current)
      }
    },
    [],
  )

  const debouncedChangeHandler = useDebouncedFn((v: Partial<Message>, changed?: boolean) => {
    changeHandler(v, changed)
    itemRef.current = null
  }, 500)
  const setRequest = (value: Partial<Request>) => {
    const newRequest = {
      ...request,
      ...value,
    }
    setRequestState(newRequest)
    const newItem = {
      ...item,
      apiCall: {
        ...item.apiCall,
        ...requestToProps(newRequest),
      },
    }
    itemRef.current = newItem
    debouncedChangeHandler(newItem)
  }

  const addHeader = () => {
    setRequest({ headers: [...(request.headers || []), { key: '', value: '' }] })
    setHeaderErrors((s) => [...s, { key: true, value: true }])
  }
  const deleteHeader = (position: number) => setRequest({ headers: request.headers?.filter((_, i) => i !== position) })
  const setBody = (value?: string) => setRequest({ body: value })
  const setContentType = (value: string) => setRequest({ contentType: value })
  const setHeader = (position: number, field: keyof Header, value: string) => {
    const headers = [...(request.headers || [])]
    const errs = [...headerErrors]
    headers[position][field] = value
    errs[position][field] = value === ''
    setRequest({ headers })
    setHeaderErrors(errs)
  }
  const setMethod = (value: HTTPMethod) => {
    const bodyEnabled = hasBody(value)
    if (!bodyEnabled) {
      setRequest({ body: undefined, contentType: undefined, method: value })
    } else {
      setRequest({ contentType: request.contentType || 'application/json', method: value })
    }
  }
  const setUrl = (value: string) => setRequest({ url: value })

  const bodyEnabled = hasBody(request.method)

  const handleBodyPzSelect = (pz: SinglePersonalizationType) => {
    if (!bodyInputRef.current) {
      return // should not happen, but just in case
    }
    const toBeInserted = addCurlyBrackets(pz.value)

    const { editor } = bodyInputRef.current
    const cursor = editor.getCursorPosition()
    editor.session.insert(cursor, toBeInserted)
    setTimeout(() => {
      editor.focus()
      editor.moveCursorTo(cursor.row, cursor.column + toBeInserted.length)
    }, 0)
  }
  const handleUrlPzSelect = (pz: SinglePersonalizationType) => {
    if (!urlInputRef.current) {
      return // should not happen, but just in case
    }
    const toBeInserted = addCurlyBrackets(pz.value)

    const input = urlInputRef.current
    const selectionStart = input.selectionStart || 0
    const newUrl = [request.url.slice(0, selectionStart), toBeInserted, request.url.slice(selectionStart)].join('')
    setRequest({
      url: newUrl,
    })
    setTimeout(() => {
      input.focus()
      input.setSelectionRange(selectionStart + toBeInserted.length, selectionStart + toBeInserted.length)
    }, 500)
  }

  return (
    <div className={classes.container}>
      {info && (
        <IconText>
          <Icon>info</Icon>
          <Typography variant="caption" color="textSecondary">
            {info}
          </Typography>
        </IconText>
      )}
      <div className={classes.wrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Select
              label="Method"
              name="method"
              value={request.method}
              values={apiMethods}
              onChange={({ value }: { value: HTTPMethod }) => setMethod(value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {bodyEnabled && (
              <Select
                label="Content Type"
                name="content-type"
                value={request.contentType}
                values={contentTypes}
                onChange={({ value }: { value: string }) => setContentType(value)}
              />
            )}
          </Grid>
        </Grid>
        <Input
          error={errors.url ? 'Invalid URL' : ''}
          label="API URL"
          name="url"
          placeholder="https://example.com/"
          inputRef={urlInputRef as any}
          value={request.url}
          onBlur={
            (({ value }: { value: string }) => {
              const error = validateURL(value)
              if (error) {
                setErrors((s) => ({
                  ...s,
                  url: !!error,
                }))
              }
            }) as any
          }
          onChange={({ value }: { value: string }) => {
            setUrl(value)
            setErrors((s) => ({
              ...s,
              url: false,
            }))
          }}
        />
        <PzMenu
          id="url"
          color="primary"
          disabled={!editable}
          personalizationList={personalizationList}
          variant="outlined"
          onSelect={handleUrlPzSelect}
        />
      </div>
      <div className={classes.headersContainer}>
        <InputLabel error={errors.headers}>Headers</InputLabel>
        {!request.headers?.length && (
          <IconButton id="headers-add-row-btn" onClick={addHeader}>
            <Icon>add_circle</Icon>
          </IconButton>
        )}
        <DynamicInputs
          errors={headerErrors}
          fields={['key', 'value']}
          name="headers"
          values={request.headers}
          addRow={addHeader}
          onChange={
            (({ position, field, value }: { position: number; field: keyof Header; value: string }) =>
              setHeader(position, field as keyof Header, value)) as any
          }
          removeRow={(({ position }: { position: number }) => deleteHeader(position)) as any}
        />
      </div>
      {bodyEnabled && (
        <>
          <div
            id="api-content-body"
            className={classes.wrapper}
            style={{
              marginTop: '10px',
              paddingBottom: '10px',
            }}
          >
            <InputLabel
              error={!!errors.body}
              style={{
                marginBottom: '6px',
              }}
            >
              Body
            </InputLabel>
            <InputCode
              height={'300px'}
              info={
                request.contentType !== 'application/json' ? (
                  ''
                ) : (
                  <>
                    {
                      "Use the Personalization button to add personalization, the personalization will be replaced by the actual value when the message is sent. Only string values can have personalization. We'll escape any special characters for you."
                    }
                    <br />
                    For example:
                    <pre>
                      <i>{'{"name": "{{Contact first name}} {{Contact last name}}"}'}</i>
                    </pre>
                  </>
                )
              }
              inputLanguage={request.contentType !== 'application/json' ? 'text' : 'json'}
              name="api-request-action-post-body-textarea"
              placeholder={request.contentType !== 'application/json' ? 'Add Plain Text Body' : 'Add JSON Body'}
              ref={bodyInputRef}
              value={request.body || ''}
              onChange={setBody}
              onValidate={(ev) => {
                setErrors((s) => ({
                  ...s,
                  body: ev.length > 0,
                }))
              }}
            />

            <PzMenu
              id="body"
              color="primary"
              disabled={!editable}
              personalizationList={personalizationList}
              variant="outlined"
              onSelect={handleBodyPzSelect}
            />
          </div>
        </>
      )}
    </div>
  )
}

export const fromItemHeaders = (itemHeaders: Record<string, string[]> = {}) => {
  const headers: Header[] = []
  const keys = Object.keys(itemHeaders)
  keys.forEach((key) => {
    if (!itemHeaders[key]) return
    if (!itemHeaders[key].length) {
      headers.push({ key, value: '' })
      return
    }
    itemHeaders[key].forEach((value) => {
      headers.push({ key, value })
    })
  })

  return headers
}

export const toItemHeaders = (headers: Header[] = []) =>
  reduce<Header, Record<string, string[]>>(
    (acc, { key, value }) => ({
      ...acc,
      [key]: [...(acc[key] || []), value],
    }),
    {},
    headers,
  )

export const requestFromProps = (item: APIMessage): Request => ({
  contentType: item.apiCall.contentType,
  method: item.apiCall.method || 'GET',
  url: item.apiCall.url,
  body: item.apiCall.body,
  headers: fromItemHeaders(item.apiCall.headers),
})

export const requestToProps = (request: Request): Partial<APIMessage['apiCall']> => ({
  ...request,
  body: request.body,
  headers: toItemHeaders(request.headers),
  url: request.url,
})

export const errorsFromProps = (item: APIMessage) => ({
  body:
    hasBody(item.apiCall.method) &&
    (!item.apiCall.body || (item.apiCall.contentType === 'application/json' && !validateJSON(item.apiCall.body))),
  url: !!validateURL(item.apiCall.url),
})

export const headerErrorsFromProps = (item: APIMessage) =>
  fromItemHeaders(item.apiCall.headers).map(({ key, value }) => ({
    key: !key,
    value: !value,
  }))

export const validateURL = validate(hasRequiredError, hasPzURLError)

const apiMethods: SelectOption<HTTPMethod>[] = [
  {
    label: 'GET',
    value: 'GET',
  },
  {
    label: 'POST',
    value: 'POST',
  },
  {
    label: 'PUT',
    value: 'PUT',
  },
  {
    label: 'PATCH',
    value: 'PATCH',
  },
  {
    label: 'DELETE',
    value: 'DELETE',
  },
]

const contentTypes = [
  {
    label: 'application/json',
    value: 'application/json',
  },
  {
    label: 'text/plain',
    value: 'text/plain; charset=utf-8',
  },
]

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(0.6125),
  },
  footer: {
    padding: theme.spacing(1, 0),
    width: 40,
  },
  headersContainer: {
    marginTop: theme.spacing(2.5),
  },
  personalizationMenu: {
    borderRadius: '50%',
    height: 40,
    width: 40,
    minWidth: 40,
  },
  wrapper: {
    maxWidth: '100%',
    minWidth: '300px',
    width: '70vw',
  },
}))

export default CampaignContentAPI
