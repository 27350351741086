import { Checkbox } from '@/app/component/atom/form'
import { cannot, useDebouncedFn } from '@/app/helpers'
import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import {
  CAMPAIGN_CHANGE_DELAY,
  CAMPAIGN_RETRIES,
  DRAGTYPE,
  MESSAGE_PERSONALIZATION,
  SMS_ACTION_DEFAULT_SENDERID,
} from '@/app/module/campaigns/definitions'
import {
  CampaignTemplateType,
  CATIQuestion,
  LocalAction,
  LocalOnAnswerType,
  LocalOpenEnded,
  OnAnswerType,
  OpenEnded,
  PartFamily,
  SMSQuestion,
} from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { emptyAction, unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { equals, isEmpty } from 'ramda'
import React from 'react'
import QuestionBox from './question'
import { AnswerType, QuestionTextProps } from './question-text-props'
import QuestionNumberInput from './question/number'
import QuestionTimerInput from './question/timer'
import ResponseTextOpenEnded from './response-text-open-ended'
import ResponseSpecific from './response-text-specific'
import { defaultOnAnswerText, hasOpenEndedResponse } from './types/multimessage/content'

type MessageType = CATIQuestion | SMSQuestion

type Props = QuestionTextProps & {
  item: MessageType
  questionType: PartFamily
  type: CampaignTemplateType // campaign type

  changeHandler: (item: MessageType) => void
}

export default function CampaignContentQuestionText(props: Props) {
  const {
    item,
    id,
    personalizationList,
    senderIds,
    whatsappSenderIds,
    questionType,
    getCampaigns,
    orgId,
    token,
    campaignIds,
    getImageProps,
    getVoiceProps,
    files = {},
    timezone,
    timezones,
    countryCode,
    countriesTimezones,
    getSnippets,
    saveSnippet,
    snippets,
    languages,
    defaultLanguage,
    transcribe,
    useAi,
    changeHandler,
  } = props

  const [state, setState] = React.useState(fromProps(item))
  const [nextTab, setNextTab] = React.useState<AnswerType | undefined>()

  const itemRef = React.useRef<MessageType | null>()
  const changeHandlerRef = React.useRef(changeHandler)

  const { timeoutActions, invalidActions, failureActions } = React.useMemo(
    () => getPartActions({ campaignType: props.type, partType: item.type }),
    [props.type, item.type],
  )

  React.useEffect(
    () => () => {
      if (itemRef.current) {
        changeHandlerRef.current?.(itemRef.current)
      }
    },
    [],
  )

  React.useEffect(() => {
    setState(fromProps(item))
  }, [item])

  const textPersonalization = React.useMemo(() => {
    if (questionType === PartFamily.CATI) {
      return personalizationList
    }
    return {
      ...MESSAGE_PERSONALIZATION,
      ...personalizationList,
    }
  }, [personalizationList, questionType])

  const handleChange = (newItem: Partial<State>) => {
    const newState = {
      ...state,
      ...newItem,
    }

    const returnToProps = backToProps(newState)
    const updatedItem = {
      ...item,
      ...returnToProps,
    } as MessageType

    changeHandler(updatedItem)
    setState(newState)
  }

  const debounced = useDebouncedFn((updatedItem: MessageType) => {
    changeHandler(updatedItem)
    itemRef.current = null
  }, CAMPAIGN_CHANGE_DELAY)
  const debouncedHandleChange = (newItem: Partial<State>) => {
    const returnToProps = backToProps({
      ...state,
      ...newItem,
    })
    const updatedItem = {
      ...item,
      ...returnToProps,
    } as MessageType

    itemRef.current = updatedItem
    debounced(updatedItem)
  }

  const renderTimeoutNote = () => {
    if (props.type === 'cati') {
      return null
    }

    return (
      <>
        <Divider
          style={{
            margin: '1rem -24px',
          }}
        />
        <div style={{ display: 'flex' }}>
          <Typography color="secondary" component="div" variant="subtitle1" gutterBottom={true}>
            <b>Important:</b> If there's no reply within 24 hours, the question will be marked as done, and the survey
            will proceed to the next question (or execute "Failure" actions you specify below). Adjust the timeout in
            the "Timeout / no reply" section below.
          </Typography>
        </div>
      </>
    )
  }

  const actionsProps = React.useMemo(
    () => ({
      campaignIds,
      countriesTimezones,
      countryCode,
      defaultLanguage,
      files,
      hasTranscribe: false,
      index: props.index,
      languages,
      personalizationList: textPersonalization,
      senderIds,
      timezone,
      timezones,
      transcribeEnabled: false,
      whatsappSenderIds,
      getCampaigns,
      getImageProps,
      getVoiceProps,
    }),
    [
      campaignIds,
      countriesTimezones,
      countryCode,
      defaultLanguage,
      files,
      languages,
      props.index,
      senderIds,
      textPersonalization,
      timezone,
      timezones,
      whatsappSenderIds,
      getCampaigns,
      getImageProps,
      getVoiceProps,
    ],
  )
  const apiProps = React.useMemo(() => ({ orgId, token }), [orgId, token])
  const snippetProps = React.useMemo(
    () => ({ snippets, getSnippets, saveSnippet }),
    [snippets, getSnippets, saveSnippet],
  )

  return (
    <div
      className="text-question-block"
      style={{
        marginTop: '2rem',
      }}
    >
      <ConfirmationDialog
        deleteButtonText="Confirm"
        icon="sms"
        isOpen={!!nextTab}
        text="Switching between response types will discard your current response config. Do you want to continue?"
        title="Change response type"
        onClose={() => setNextTab(undefined)}
        onConfirm={() => {
          if (!nextTab) {
            return
          }
          handleChange({
            ...generateContent(nextTab),
            answerType: nextTab,
          })
          setNextTab(undefined)
        }}
      />
      <Divider
        style={{
          margin: '1rem -24px',
        }}
      />
      <Tabs
        value={state.answerType}
        indicatorColor="primary"
        onChange={(_, val) => {
          if (hasResponse(state)) {
            setNextTab(val)
          } else {
            handleChange({
              ...generateContent(val),
              answerType: val,
            })
          }
        }}
      >
        <Tab
          value={AnswerType.Text}
          label="Specific Response"
          style={{ zIndex: 2 }}
          icon={<Icon>chat</Icon>}
          wrapped
          className="campaign-specific-tab"
        />

        <Tab
          value={AnswerType.OpenEnded}
          label="Open Response"
          style={{ zIndex: 2 }}
          icon={<Icon>textsms</Icon>}
          wrapped
          className="campaign-open-ended-tab"
        />
      </Tabs>
      <Divider
        style={{
          margin: '-1px -24px 1rem',
        }}
      />
      {state.answerType === AnswerType.OpenEnded && state.openEnded && (
        <ResponseTextOpenEnded
          actionProps={actionsProps}
          apiProps={apiProps}
          campaignType={props.type}
          id={id}
          openEnded={state.openEnded}
          partType={item.type}
          snippetProps={snippetProps}
          transcribe={transcribe || false}
          onChange={handleChange}
        />
      )}
      {state.answerType === AnswerType.Text && (
        <ResponseSpecific
          actionProps={actionsProps}
          apiProps={apiProps}
          campaignType={props.type}
          id={id}
          onAnswer={state.onAnswer}
          partType={item.type}
          snippetProps={snippetProps}
          transcribe={transcribe || false}
          useAi={useAi}
          onChange={handleChange}
        />
      )}
      {renderTimeoutNote()}
      {state.answerType === AnswerType.Text && props.type !== 'cati' && (
        <>
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <div className="campaign-question-invalid-box">
            <Checkbox
              name="invalid-reply"
              label="Invalid reply"
              value={state.checkInvalid.toString()}
              editable={true}
              onChange={({ value }: { value: string }) => {
                const changeRetries = (value !== 'true' && !state.checkTimeout) || !state.retries
                handleChange({
                  checkInvalid: value === 'true',
                  ...(changeRetries && {
                    retries: value === 'true' || state.checkTimeout ? CAMPAIGN_RETRIES : 0,
                  }),
                })
              }}
            />
            <div
              style={{
                marginLeft: '24px',
              }}
            >
              <Typography color="textSecondary" variant="caption" gutterBottom={true}>
                Specify what we should do if the contact's reply is not one of the choices. Afterwards, we will wait for
                the contact to reply with a valid choice—until they run out of chances.
              </Typography>
              {state.checkInvalid && (
                <QuestionBox
                  dragContext={`${DRAGTYPE}-invalid`}
                  actionsList={state.invalidActions}
                  actionsValues={invalidActions}
                  changeHandler={({ actionsList }: { actionsList: LocalAction[] }) =>
                    handleChange({
                      invalidActions: actionsList,
                    })
                  }
                  personalizationList={textPersonalization}
                  question={false}
                  invalid={true}
                  senderIds={senderIds}
                  whatsappSenderIds={whatsappSenderIds}
                  defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.smsQuestion}
                  getCampaigns={getCampaigns}
                  imageId={`${id}-invalid`}
                  index={props.index}
                  voiceId={`${id}-invalid`}
                  getImageProps={getImageProps}
                  getVoiceProps={getVoiceProps}
                  files={files}
                  orgId={orgId}
                  token={token}
                  campaignIds={campaignIds}
                  timezones={timezones}
                  timezone={timezone}
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  getSnippets={getSnippets}
                  snippets={snippets}
                  saveSnippet={saveSnippet}
                  languages={languages}
                  defaultLanguage={defaultLanguage}
                />
              )}
            </div>
          </div>
        </>
      )}
      {props.type !== 'cati' && (
        <>
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <div className="campaign-question-timeout-box">
            <Checkbox
              name="timeout-no-reply"
              label="Timeout / no reply"
              value={state.checkTimeout.toString()}
              editable={true}
              onChange={({ value }: { value: string }) => {
                const shouldCheckTimeout = value === 'true'
                const shouldChangeRetries = (!shouldCheckTimeout && !state.checkInvalid) || !state.retries
                const shouldSetDefault = shouldCheckTimeout && !state.timeoutDelay?.value
                handleChange({
                  checkTimeout: shouldCheckTimeout,
                  ...(shouldChangeRetries && {
                    retries: shouldCheckTimeout || state.checkInvalid ? CAMPAIGN_RETRIES : 0,
                  }),
                  ...(shouldSetDefault && {
                    timeoutDelay: {
                      unit: 'minute',
                      value: 10,
                    },
                  }),
                })
              }}
            />
            <div
              style={{
                marginLeft: '24px',
              }}
            >
              <Typography color="textSecondary" variant="caption" gutterBottom={true}>
                Specify what we should do if the contact does not reply within a period of time that you specify.
                Afterwards, we will wait for the contact to reply—until they run out of chances.
              </Typography>
              {state.checkTimeout && (
                <QuestionBox
                  dragContext={`${DRAGTYPE}-timeout`}
                  actionsList={state.timeoutActions}
                  actionsValues={timeoutActions}
                  questionType={questionType}
                  changeHandler={({ actionsList }: { actionsList: LocalAction[] }) =>
                    handleChange({
                      timeoutActions: actionsList,
                    })
                  }
                  personalizationList={personalizationList}
                  senderIds={senderIds}
                  whatsappSenderIds={whatsappSenderIds}
                  defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.smsQuestion}
                  getCampaigns={getCampaigns}
                  imageId={`${id}-timeout`}
                  index={props.index}
                  voiceId={`${id}-timeout`}
                  getImageProps={getImageProps}
                  getVoiceProps={getVoiceProps}
                  files={files}
                  orgId={orgId}
                  token={token}
                  question={false}
                  campaignIds={campaignIds}
                  timezones={timezones}
                  timezone={timezone}
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  getSnippets={getSnippets}
                  snippets={snippets}
                  saveSnippet={saveSnippet}
                  languages={languages}
                  defaultLanguage={defaultLanguage}
                  info={
                    <Typography color="inherit" component="div" style={{ display: 'flex', alignItems: 'center' }}>
                      {'Take the above actions if the contact does not reply to this question after'}
                      <div style={{ width: 4 }} />
                      <QuestionTimerInput
                        {...state.timeoutDelay}
                        min={1}
                        values={timeoutValues}
                        onChange={(timeoutDelay) =>
                          debouncedHandleChange({
                            timeoutDelay,
                          })
                        }
                      />
                    </Typography>
                  }
                />
              )}
            </div>
          </div>
        </>
      )}
      {props.type !== 'cati' && (
        <>
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <React.Fragment>
            <div className="campaign-question-failure-retries">
              <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
                <QuestionNumberInput
                  min={0}
                  beforeText="Specify the number of times that we should allow the Contact to reply invalidly or time out before we
                  take the Failure actions you specify below:"
                  afterText="additional chances."
                  editable={state.checkInvalid || state.checkTimeout}
                  value={state.retries.toString()}
                  onChange={({ value }: { value: string }) => {
                    debouncedHandleChange({
                      retries: Number(value),
                    })
                  }}
                />
              </Typography>
              {!(state.checkInvalid || state.checkTimeout) && (
                <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
                  Note: To set the number of chances, please first select and specify Actions for Invalid Reply or
                  Timeout.
                </Typography>
              )}
              {(state.checkInvalid || state.checkTimeout) && (
                <Typography color="textSecondary" component="div" variant="caption" gutterBottom={true}>
                  Note: after we take the Failure actions you specify, the survey will continue, unless you add an "End
                  Campaign for Contact" action in the Failure section.
                </Typography>
              )}
            </div>
          </React.Fragment>
          <Divider
            style={{
              margin: '1rem -24px',
            }}
          />
          <div className="campaign-question-failure-box">
            <Checkbox
              name="failure"
              label="Failure"
              value={state.checkFailure.toString()}
              editable={true}
              onChange={({ value }: { value: string }) => {
                handleChange({
                  checkFailure: value === 'true',
                })
              }}
            />
            <div
              style={{
                marginLeft: '24px',
              }}
            >
              <Typography color="textSecondary" variant="caption" gutterBottom={true}>
                Specify what we should do after the contact runs out of chances.
              </Typography>
              {state.checkFailure && (
                <QuestionBox
                  dragContext={`${DRAGTYPE}-failure`}
                  actionsList={state.failureActions}
                  actionsValues={failureActions}
                  changeHandler={({ actionsList }: { actionsList: LocalAction[] }) =>
                    handleChange({
                      failureActions: actionsList,
                    })
                  }
                  personalizationList={textPersonalization}
                  senderIds={senderIds}
                  whatsappSenderIds={whatsappSenderIds}
                  defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.smsQuestion}
                  getCampaigns={getCampaigns}
                  imageId={`${id}-failure`}
                  voiceId={`${id}-failure`}
                  getImageProps={getImageProps}
                  getVoiceProps={getVoiceProps}
                  files={files}
                  orgId={orgId}
                  token={token}
                  question={false}
                  campaignIds={campaignIds}
                  index={props.index}
                  timezones={timezones}
                  timezone={timezone}
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  getSnippets={getSnippets}
                  snippets={snippets}
                  saveSnippet={saveSnippet}
                  languages={languages}
                  defaultLanguage={defaultLanguage}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const timeoutValues: { label: string; value: string }[] = [
  {
    label: 'seconds',
    value: 'second',
  },
  {
    label: 'minutes',
    value: 'minute',
  },
  {
    label: 'hours',
    value: 'hour',
  },
  {
    label: 'days',
    value: 'day',
  },
]

const fromOnAnswer = (onAnswer: OnAnswerType[] | undefined, answerType: AnswerType): LocalOnAnswerType[] => {
  const defaultOnAnswer = answerType === AnswerType.Text ? defaultOnAnswerText : []

  return onAnswer && !isEmpty(onAnswer)
    ? onAnswer.map((answer) => ({
        ...answer,
        actions: unwrapActions(answer.actions || []),
      }))
    : defaultOnAnswer
}

const fromOpenEnded = (openEnded?: OpenEnded | CATIQuestion['openEnded']): LocalOpenEnded | undefined => {
  if (!openEnded) {
    return undefined
  }

  const categories =
    'categories' in openEnded
      ? openEnded.categories?.map(({ category, actions }) => ({
          category,
          actions: unwrapActions(actions || []),
        }))
      : undefined

  return {
    ...openEnded,
    actions: unwrapActions(openEnded.actions || []),
    categories,
  }
}

export type State = {
  answerType: AnswerType
  checkFailure: boolean
  checkInvalid: boolean
  checkTimeout: boolean
  failureActions: LocalAction[]
  invalidActions: LocalAction[]
  onAnswer: LocalOnAnswerType[]
  openEnded: LocalOpenEnded | undefined
  retries: number
  timeoutActions: LocalAction[]
  timeoutDelay: {
    unit: string
    value: number
  }
  type: PartFamily.CATI | PartFamily.SMS
  useAi: boolean
}

export const fromProps = (item: MessageType): State => {
  const { onAnswer, openEnded, type } = item
  const showOpenEnded = !!openEnded
  const answerType = showOpenEnded ? AnswerType.OpenEnded : AnswerType.Text
  if (type === PartFamily.CATI) {
    return {
      answerType,
      checkFailure: false,
      checkInvalid: false,
      checkTimeout: false,
      failureActions: [],
      invalidActions: [],
      onAnswer: fromOnAnswer(onAnswer, answerType),
      openEnded: fromOpenEnded(openEnded),
      retries: 0,
      timeoutActions: [],
      timeoutDelay: {
        unit: 'minute',
        value: 10,
      },
      type,
      useAi: false,
    }
  }

  const { onRetriesExhausted, onInvalidReply, onTimeout, retries, useAi } = item

  const checkFailure = !!(onRetriesExhausted?.enabled || onRetriesExhausted?.actions?.length)
  const checkInvalid = !!(onInvalidReply?.enabled || onInvalidReply?.actions?.length)
  const checkTimeout = !!(onTimeout?.enabled || onTimeout?.actions?.length || onTimeout?.timeout?.value)
  const defaultRetries = retries === undefined ? CAMPAIGN_RETRIES : retries

  return {
    answerType,
    checkFailure,
    checkInvalid,
    checkTimeout,
    failureActions: unwrapActions(onRetriesExhausted?.actions || []),
    invalidActions: unwrapActions(onInvalidReply?.actions || []),
    onAnswer: fromOnAnswer(onAnswer, answerType),
    openEnded: fromOpenEnded(openEnded),
    retries: checkInvalid || checkTimeout ? defaultRetries : 0,
    timeoutActions: unwrapActions(onTimeout?.actions || []),
    timeoutDelay: {
      unit: onTimeout?.timeout?.unit || 'minute',
      value: Number(onTimeout?.timeout?.value || 10),
    },
    type,
    useAi,
  }
}

const toOnAnswer = (onAnswer: LocalOnAnswerType[], openEnded: LocalOpenEnded | undefined): OnAnswerType[] =>
  openEnded ? [] : onAnswer.map((item) => ({ ...item, actions: wrapActions(item.actions) }))

const toOpenEnded = (openEnded: LocalOpenEnded | undefined): OpenEnded | CATIQuestion['openEnded'] => {
  if (!openEnded) {
    return undefined
  }

  const categories =
    'categories' in openEnded
      ? openEnded.categories?.map(({ category, actions }) => ({
          category,
          actions: wrapActions(actions),
        }))
      : undefined

  return {
    ...openEnded,
    actions: wrapActions(openEnded.actions || []),
    categories,
  }
}

export const backToProps = ({
  checkFailure,
  checkInvalid,
  checkTimeout,
  failureActions,
  invalidActions,
  onAnswer,
  openEnded,
  retries = 0,
  timeoutActions,
  timeoutDelay,
  type,
  useAi,
}: State): Partial<CATIQuestion> | Partial<SMSQuestion> => {
  switch (type) {
    case PartFamily.CATI:
      return {
        onAnswer: toOnAnswer(onAnswer, openEnded),
        openEnded: toOpenEnded(openEnded),
      }

    case PartFamily.SMS:
      return {
        onAnswer: toOnAnswer(onAnswer, openEnded),
        onInvalidReply: {
          enabled: checkInvalid,
          actions: checkInvalid && !openEnded ? wrapActions(invalidActions) : [],
        },
        onRetriesExhausted: {
          enabled: checkFailure,
          actions: checkFailure ? wrapActions(failureActions) : [],
        },
        onTimeout: {
          enabled: checkTimeout,
          timeout: checkTimeout ? timeoutDelay : undefined,
          actions: checkTimeout ? wrapActions(timeoutActions) : [],
        },
        openEnded: toOpenEnded(openEnded),
        retries,
        useAi,
      }

    default:
      return cannot(type)
  }
}

type SMSQuestionContent = {
  onAnswer: LocalOnAnswerType[]
  openEnded: LocalOpenEnded | undefined
  useAi: boolean
}

type QuestionContent = SMSQuestionContent

export const generateContent = (answerType: AnswerType): QuestionContent => {
  switch (answerType) {
    case AnswerType.OpenEnded:
      return {
        onAnswer: [],
        openEnded: {
          actions: [emptyAction],
        },
        useAi: false,
      }

    default:
      return {
        onAnswer: defaultOnAnswerText,
        openEnded: undefined,
        useAi: false,
      }
  }
}

const hasResponse = ({ answerType, onAnswer, openEnded, useAi }: State) => {
  const defaultOnAnswer = defaultOnAnswerText

  const hasOnAnswer = answerType === AnswerType.Text && !equals(onAnswer, defaultOnAnswer)
  const hasOpenEnded = answerType === AnswerType.OpenEnded && hasOpenEndedResponse(openEnded)

  return hasOnAnswer || hasOpenEnded || useAi
}

const getPartActions = ({ campaignType, partType }: { campaignType: CampaignTemplateType; partType: PartFamily }) => {
  const buttonActions = getActionOptionsForCampaign({
    campaignType,
    partType,
    actionNamespace: ActionContext.OnButtons,
  })

  const timeoutActions = getActionOptionsForCampaign({
    campaignType,
    partType,
    actionNamespace: ActionContext.OnTimeout,
  })

  const invalidActions = getActionOptionsForCampaign({
    campaignType,
    partType,
    actionNamespace: ActionContext.OnInvalid,
  })

  const failureActions = getActionOptionsForCampaign({
    campaignType,
    partType,
    actionNamespace: ActionContext.OnFailure,
  })

  return {
    buttonActions,
    timeoutActions,
    invalidActions,
    failureActions,
  }
}
