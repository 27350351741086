import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { path } from 'ramda'
import { Fragment } from 'react'
import { makeStyles } from 'tss-react/mui'
import TablePagination from '@/app/component/atom/table-pagination'
import ContentLayout from '@/app/component/layout/content'

function TableLayout({ loading, pageSize, pagination, emptyMessage, children, style = {} }) {
  const { classes } = useStyles()
  if (!loading && pageSize === 0) {
    return (
      <Paper>
        <div className={classes.emptyWrap}>
          <Typography color="textSecondary" variant="h6" align="center">
            {emptyMessage}
          </Typography>
        </div>
      </Paper>
    )
  }
  return (
    <Fragment>
      <div style={style} className={classes.container}>
        <div className={classes.wrap}>
          <Paper style={{ boxShadow: 'none', overflowX: 'scroll' }}>{children}</Paper>
        </div>
      </div>
      {pagination && pageSize > 0 && (
        <ContentLayout>
          <div className={classes.pagination}>
            <TablePagination {...pagination} />
          </div>
        </ContentLayout>
      )}
    </Fragment>
  )
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
  },
  wrap: {
    width: '100%',
    '& th, & td': {
      fontSize: '1rem',
    },
    '& tbody tr:hover': {
      cursor: 'pointer',
      backgroundColor: path(['palette', 'action', 'hover'], theme),
    },
  },
  emptyWrap: {
    padding: '3.05rem 1rem',
  },
  pagination: {
    paddingRight: '3rem',
  },
}))

export default TableLayout
