import Paper from '@mui/material/Paper'
import { processSummary } from '../../campaigns/component/item/summary/overviewTab'
import OverviewChart from '../../campaigns/component/item/summary/overviewTab/components/overviewBarChart'
import OverviewPieChart from '../../campaigns/component/item/summary/overviewTab/components/overviewPieChart'
import PlanBlock from './planBlock'

const DeliveryCharts = ({ isLoading, summary, activeCampaign }) => {
  const data = processSummary(summary, activeCampaign.type)

  return (
    <PlanBlock isLoading={isLoading}>
      {activeCampaign.type !== 'ivr' ? (
        <OverviewChart data={data} size="250" />
      ) : (
        <Paper>
          <OverviewPieChart data={data} size="250" />
        </Paper>
      )}
    </PlanBlock>
  )
}

export default DeliveryCharts
