import {
  Box,
  Button,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import Loader from '@/app/component/atom/loader'
import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import { APIKey, UpdateAPIKeyPayload } from '@/app/module/user/types'
import ExternalLink from '@/app/component/atom/external-link'
import ApiKeyModal from './api-key-modal'
import ApiKeyRow from './api-key-row'

type Props = {
  apiKeys: APIKey[]
  loading: boolean

  onCreate: () => Promise<void>
  onDelete: (id: number) => Promise<void>
  onEdit: (tokenId: number, item: UpdateAPIKeyPayload) => Promise<void>
}

const ApiKeys: React.FC<Props> = ({ apiKeys, loading, onCreate, onDelete, onEdit }) => {
  const [currentAPIKey, setCurrentAPIKey] = React.useState<APIKey | null>(null)
  const [toBeDelete, setToBeDelete] = React.useState<APIKey['id'] | null>(null)

  const handleEdit = React.useCallback(
    (apiKey: APIKey, values: UpdateAPIKeyPayload) => {
      const { active, expires, name } = values
      const cleanValues: UpdateAPIKeyPayload = {
        active,
        name,
      }
      if (expires) {
        cleanValues.expires = expires
      }

      return onEdit(apiKey.id, cleanValues)
    },
    [onEdit],
  )
  const handleDeleteClick = React.useCallback((id: number) => setToBeDelete(id), [])

  return (
    <CardContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant="h5">Personal Access Tokens (PAT)</Typography>
          <Typography>
            You can use our APIs to integrate engageSPARK securely with 3rd-party systems, such as your CRM, or to
            automate tasks.
            <br />
            For example, you might create contacts, subscribe them to campaigns, or send airtime top-ups. To use our
            APIs, you'll need a personal access token, which you can find below.{' '}
            <ExternalLink href="https://www.engagespark.com/support/how-can-i-use-your-api/" target="_blank">
              Learn more about our APIs here.
            </ExternalLink>
          </Typography>
          <Typography>
            <b>Important:</b> These personal access tokens grant access to all your engageSPARK organizations and all
            their data under your name and with all your access rights. Keep these personal access tokens as safe and
            private as your login password.
          </Typography>
        </Box>
        <Box>
          <Button disabled={loading} variant="contained" onClick={onCreate}>
            Add a new PAT
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="20%">Name</TableCell>
              <TableCell width="20%">Key</TableCell>
              <TableCell align="center" width="15%">
                Created
              </TableCell>
              <TableCell align="center" width="15%">
                Expires
              </TableCell>
              <TableCell align="center" width="10%">
                Live
              </TableCell>
              <TableCell align="center" width="10%">
                Enabled
              </TableCell>
              <TableCell align="center" width="10%">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiKeys.map((apiKey) => (
              <ApiKeyRow key={apiKey.id} apiKey={apiKey} onDelete={handleDeleteClick} onEditClick={setCurrentAPIKey} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <Loader />}
      {toBeDelete !== null && (
        <ConfirmationDialog
          isOpen
          text="Are you sure you want to delete this token?"
          title="Delete Token"
          onClose={() => setToBeDelete(null)}
          onConfirm={() => {
            onDelete(toBeDelete)
            setToBeDelete(null)
          }}
        />
      )}
      {currentAPIKey && (
        <ApiKeyModal
          apiKey={currentAPIKey}
          loading={loading}
          onClose={() => setCurrentAPIKey(null)}
          onSubmit={handleEdit}
        />
      )}
    </CardContent>
  )
}

export default ApiKeys
