import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

export default function PublishedBanner() {
  return (
    <Paper
      elevation={3}
      style={{
        backgroundColor: '#FFECB3',
        padding: '30px',
      }}
    >
      <Typography
        style={{
          textAlign: 'center',
          fontSize: '1.1rem',
        }}
      >
        You are editing a running campaign. Some changes are no longer possible.{' '}
        <Link target="_blank" rel="noopener" href="https://www.engagespark.com/support/edit-live-campaigns/">
          Read this support article for more info
        </Link>
        .
      </Typography>
    </Paper>
  )
}
