/* eslint-disable import/prefer-default-export */
import { cannot } from '@/app/helpers'
import { createReminderInstance } from '@/app/module/campaigns/models/classes/reminder-campaign.class'
import CampaignInterface from '@/app/module/campaigns/models/interfaces/campaign.interface'
import { LegacyCampaignItem, CampaignTemplateType } from '@/app/module/campaigns/types'

/**
 * This function initializes a campaign item object/instance based on the itemId and campaign type.
 * There are three possible scenarios:
 * 1. If itemId is provided, return the item. The item can be undefined (e.g.: it's being fetched) but we don't need to worry about that
 * since this function is only used when initializing a campaign item component. Any changes to item in store will be handled by the campaign item component.
 * 2. If itemId is not provided and the type is not a reminder, return a default campaign object with the type.
 * 3. If itemId is not provided and the type is a reminder, return a reminder campaign instance.
 */
export const getCampaignItem = (config: {
  item?: LegacyCampaignItem | CampaignInterface
  itemId?: number
  type: CampaignTemplateType
}): any => {
  const { item, itemId, type } = config
  // if itemId is provided, return the item
  if (itemId) {
    return item
  }

  switch (type) {
    case CampaignTemplateType.SMSBlast:
      return {
        type,
      }

    case CampaignTemplateType.CATI:
    case CampaignTemplateType.Drip:
    case CampaignTemplateType.SMSSurvey:
    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey:
    case CampaignTemplateType.WhatsAppSurvey:
      return {
        type,
        variables: {
          parts: [],
        }, // initialize variables
      }

    case CampaignTemplateType.Reminder:
      return createReminderInstance()
    default:
      return cannot(type)
  }
}
