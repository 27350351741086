import Layout from '../component/layout/page'
import { PAGE } from '../definitions'
import { AuthGuard, AuthHeader, RequestResetPasswordForm, ResetPasswordForm } from '../module/auth'
import Notifications from '../module/notifications'

export default function ResetPasswordPage(props) {
  const { name, routes, match, history } = props
  const params = new URLSearchParams(props.location.search)
  return (
    <AuthGuard
      routes={routes}
      url={match.url}
      name={name}
      path={name === PAGE.ACCOUNT.RESET_PASSWORD && !params.has('id') ? '' : match.path}
      params={match.params}
    >
      <Layout title="Reset Password - engageSPARK">
        <AuthHeader currentPage={name} />
        {name === PAGE.ACCOUNT.REQUEST_RESET_PASSWORD ? (
          <RequestResetPasswordForm history={history} />
        ) : (
          <ResetPasswordForm params={params} history={history} />
        )}
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}
