import { FormControl, FormControlLabel, Icon, Link, Switch, Typography } from '@mui/material'
import React from 'react'

type Props = {
  disabled?: boolean
  transcribe: boolean
  onChange: (value: boolean) => void
}

const TranscribeToggle: React.FC<Props> = ({ disabled, transcribe, onChange }) => {
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked),
    [onChange],
  )

  return (
    <FormControl>
      <FormControlLabel
        id="transcribe-toggle"
        control={<Switch checked={!!transcribe} onChange={handleChange} />}
        disabled={disabled}
        label="Transcribe answer"
      />
      <Typography
        color="textSecondary"
        component="div"
        sx={{
          display: 'flex',
        }}
        variant="caption"
      >
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 4 }}>
          <Icon style={{ fontSize: '20px' }}>assistant</Icon>&nbsp;AI Inside.
        </div>
        <Link
          href="https://www.engagespark.com/support/transcribe-audio-responses/"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          target="_blank"
        >
          Learn more&nbsp;<Icon style={{ fontSize: '13px' }}>launch</Icon>
        </Link>
      </Typography>
    </FormControl>
  )
}

export default TranscribeToggle
