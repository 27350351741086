import RecaptchaLayout from '../component/layout/recaptcha-page'
import { PAGE } from '../definitions'
import { AuthGuard, AuthHeader, LoginForm } from '../module/auth'
import Notifications from '../module/notifications'

export default function LoginPage({ name, routes, match, history }) {
  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <RecaptchaLayout title="Login - engageSPARK">
        <AuthHeader currentPage={PAGE.ACCOUNT.LOGIN} />
        <LoginForm history={history} />
        <Notifications />
      </RecaptchaLayout>
    </AuthGuard>
  )
}
