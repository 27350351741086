import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import React from 'react'
import { FunctionType } from '@/config/types'
import Tooltip from '@/app/component/atom/tooltip'

type Config = {
  icon: string
  label: string
  disabled: boolean
  onClick: FunctionType
}

type Props = {
  config: Config[]
  itemId: string
}

const TableRowActions = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { config, itemId } = props

  return (
    <div>
      <div
        onClick={(e: any) => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        }}
      >
        <Tooltip title="Actions">
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={handleClick}
            variant="outlined"
            endIcon={<ArrowDropDownIcon />}
            startIcon={<MoreVertIcon />}
            size="small"
          >
            Actions
          </Button>
        </Tooltip>
      </div>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {config.map(({ icon, label, disabled = false, onClick }, i) => (
          <MenuItem
            className="power-table-action"
            dense
            key={i}
            disabled={!anchorEl || disabled}
            onClick={() => {
              if (anchorEl) {
                onClick(itemId)
              }
              setAnchorEl(null)
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <span
                style={{
                  paddingRight: '5px',
                }}
              >
                {icon}
              </span>
              <Typography>{label}</Typography>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default TableRowActions
