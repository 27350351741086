import React, { useCallback } from 'react'
import { CallBackProps } from 'react-joyride'

import { cannot } from '@/app/helpers'
import { CampaignTemplateType } from '@/app/module/campaigns/types'
import { skippedSteps } from './definitions'
import DripTour, { preDripSteps } from './drip-tour'
import { enrichSteps } from './helpers'
import { useTourContext } from './tour.context'
import { TourStep, TourType } from './types'

type Props = {
  skippingTours: Record<TourType, boolean>
  type: CampaignTemplateType

  onTourEnd: (tourType: TourType) => void
  onTourStart: (tourType: TourType) => void
}

const Tour: React.FC<Props> = ({ skippingTours, type, onTourEnd, onTourStart }) => {
  const [initialized, setInitialized] = React.useState(false)
  const [tourType, setTourType] = React.useState<TourType | undefined>()
  const { setTour } = useTourContext()

  const callback = useCallback(
    (data: CallBackProps) => {
      const currentType = getTourType(type)
      switch (data.action) {
        case 'close':
        case 'skip': {
          if (currentType && data.action === 'skip') {
            onTourEnd(currentType)
          }
          setTour(undefined)
          setTimeout(() => {
            setTour(() => ({
              disableScrolling: true,
              disableScrollParentFix: true,
              hideBackButton: true,
              stepIndex: 0,
              steps: enrichSteps(skippedSteps),
              callback: (d) => {
                switch (d.action) {
                  case 'close':
                  case 'skip':
                  case 'stop':
                    setTour(undefined)
                    break

                  default:
                    break
                }
              },
            }))
          }, 0)

          break
        }

        case 'next': {
          // start the tour
          setTour(undefined)
          setTourType(currentType)
          if (currentType) {
            onTourStart(currentType)
          }
          break
        }

        default:
          // do nothing
          break
      }
    },
    [type, onTourEnd, onTourStart, setTour],
  )

  React.useEffect(() => {
    if (initialized) {
      return
    }

    const currentTourType = getTourType(type)
    if (!currentTourType || skippingTours[currentTourType]) {
      return
    }

    let tourSteps: TourStep[] = []

    switch (currentTourType) {
      case TourType.Drip:
        tourSteps = preDripSteps
        break
      default:
        cannot(currentTourType)
        return
    }

    setInitialized(true)
    setTour(() => ({
      continuous: true,
      disableScrolling: true,
      disableScrollParentFix: true,
      hideBackButton: true,
      stepIndex: 0,
      steps: enrichSteps(tourSteps),
      callback,
    }))
  }, [initialized, skippingTours, type, callback, setTour])

  if (!tourType) {
    return null
  }
  return getTourComponent(tourType)
}

const getTourComponent = (type: TourType) => {
  switch (type) {
    case TourType.Drip:
      return <DripTour />

    default:
      return cannot(type)
  }
}

const getTourType = (type: CampaignTemplateType) => {
  switch (type) {
    case CampaignTemplateType.Drip:
      return TourType.Drip
    case CampaignTemplateType.CATI:
    case CampaignTemplateType.Reminder:
    case CampaignTemplateType.SMSBlast:
    case CampaignTemplateType.SMSSurvey:
    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey:
    case CampaignTemplateType.WhatsAppSurvey:
      return undefined

    default:
      return cannot(type)
  }
}

export default Tour
