import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import React from 'react'
import { List } from 'react-virtualized'
import { makeStyles } from 'tss-react/mui'
import { FixMeLater } from '@/config/types'
import Filters from '@/app/component/atom/filters'
import FilterTags from '@/app/component/atom/filters/tags'
import SearchBar from '@/app/component/atom/search'
import Loading from '@/app/component/guard/loading'
import ContentLayout from '@/app/component/layout/content'
import Modal from '@/app/component/layout/modal'
import TableToolbar from '@/app/component/layout/table/toolbar'
import {
  ALL,
  CALL_STATUS_FILTER,
  STATUS_FILTER,
  TOPUP_STATUS_FILTER,
  TYPE_FILTER,
  WHATSAPP_STATUS_FILTER,
} from '@/app/module/logs/definitions'
import CallRow from './call-row'
import MessageRow from './message-row'
import SendMessage from './send'
import Whatsapp from './sendWhatsapp'
import TopupRow from './topupRow'
import WhatsappRow from './whatsapp-row'
import LoadingMessagesRow from './loading-message-row'

const tabLabels: { [key: string]: string } = {
  whatsapp: 'WhatsApp messages',
  messages: 'messages',
  calls: 'calls',
  topups: 'top ups',
}

const searchTooltipMap: { [key: string]: string } = {
  messages: 'Search messages & phone numbers',
  calls: 'Search by phone number prefixes',
  whatsapp: 'Search messages & phone numbers',
  topups: 'Search top ups & phone numbers',
}

const searchLabelMap: { [key: string]: string } = {
  messages: 'Search',
  calls: 'Phone number prefixes',
  whatsapp: 'Search',
  topups: 'Search',
}

const useStyle = makeStyles()({
  toolbar: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0.5rem 24px',
  },
  cardSize: {
    margin: 'auto',
    left: '0',
    right: '0',
  },
  filterTagsWrap: {
    flex: '1 auto',
  },
  filtersWrap: {
    flex: '0',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    paddingRight: '15px',
  },
  downloadWrap: {
    paddingRight: '15px',
    minWidth: 'fit-content',
  },
  downloadCallLogWrap: {
    display: 'flex',
    padding: '10% 0px',
    justifyContent: 'center',
  },
})

type RowType = {
  key: string
  index: number
  style: FixMeLater
  isScrolling?: boolean
  isVisible?: boolean
}

type ScrollEvent = {
  clientHeight: number
  scrollHeight: number
  scrollTop: number
}

type Props = {
  tab: 'messages' | 'calls' | 'whatsapp' | 'topups'
  data: FixMeLater
  history: FixMeLater
  setSendMessage: FixMeLater
  endCursor: FixMeLater
  hasNextPage: Boolean
  token: string
  orgId: number
  status: FixMeLater
  type: FixMeLater
  to: string
  from: string
  pageSize: number
  getData: FixMeLater
  search: string
  downloadData: FixMeLater
  getSenderIdList: FixMeLater
  senderIds: FixMeLater
  contactIds: FixMeLater
  recipients: FixMeLater
  msgSenderId: FixMeLater
  personalizationList: FixMeLater
  sendMessage: FixMeLater
  navigateLogs: FixMeLater
  reloadData: FixMeLater
  error: FixMeLater
  getFilesItem: FixMeLater
  sendWhatsappMessage: FixMeLater
  getCustomFields: FixMeLater
  setSendWhatsappMessage: FixMeLater
  whatsappSenderIds: []
  saveWhatsappFile: FixMeLater
  onMicAccessError: FixMeLater
  timezone: string
}

const filterLabels = {
  messages: STATUS_FILTER,
  calls: CALL_STATUS_FILTER,
  whatsapp: WHATSAPP_STATUS_FILTER,
  topups: TOPUP_STATUS_FILTER,
}

const getFilters = (props: Props): FixMeLater => {
  const { type, status, from, to, tab, timezone } = props
  const statusFilter = filterLabels[tab]
  return {
    ...(tab !== 'topups' && {
      type: {
        type: 'radio',
        name: 'Type',
        value: type,
        values: TYPE_FILTER.map(({ name, value }) => ({
          label: name,
          id: name,
          value: value.toString() || ALL,
          editable: true,
        })),
      },
    }),
    status: {
      type: 'radio',
      name: 'Status',
      value: status,
      values: statusFilter.map(({ name, value }) => ({
        label: name,
        id: name,
        value: value.toString() || ALL,
        editable: true,
      })),
    },
    fromto: {
      type: 'datetimerange',
      name: 'Date',
      value: {
        from: from && new Date(from).toISOString(),
        to: to && new Date(to).toISOString(),
      },
      timezone,
      values: [],
    },
  }
}

export default function LogList(props: Props) {
  const {
    data,
    history,
    setSendMessage,
    endCursor,
    hasNextPage,
    token,
    orgId,
    status,
    type,
    pageSize,
    getData,
    search: initialSearch = '',
    downloadData,
    getSenderIdList,
    senderIds,
    contactIds,
    recipients,
    msgSenderId,
    personalizationList,
    sendMessage,
    navigateLogs,
    tab,
    error,
    getFilesItem,
    getCustomFields,
    sendWhatsappMessage,
    setSendWhatsappMessage,
    whatsappSenderIds,
    saveWhatsappFile,
    onMicAccessError,
    from,
    to,
    reloadData: reloadDataProps,
  } = props
  const filters: FixMeLater = getFilters(props)
  const [windowWidth, setWindowWidth] = React.useState(0)
  const [windowHeight, setWindowHeight] = React.useState(0)
  const [sendMessageModal, setSendMessageModal] = React.useState(false)
  const [sendWhatsappMessageModal, setSendWhatsappMessageModal] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [search, setSearch] = React.useState(initialSearch)

  const updateWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }

  React.useEffect(() => {
    let ref: number = 0
    updateWindowDimensions()
    window.addEventListener('resize', () => {
      if (ref === 0) {
        ref = requestAnimationFrame(() => {
          updateWindowDimensions()
          ref = 0
        })
      }
    })
  }, [])

  React.useEffect(() => {
    if (orgId && token) {
      getCustomFields({ orgId, token })
    }
  }, [orgId, token, getCustomFields])

  React.useEffect(() => {
    if (token && orgId) {
      setLoading(true)
      reloadDataProps()
      getData({
        token,
        orgId,
        query: {
          filters: {
            type,
            status,
            to,
            from,
          },
          pageSize,
          search,
        },
      }).then(() => {
        setLoading(false)
        setSearch(search)
      })
    }
  }, [from, orgId, pageSize, search, status, to, token, type, getData, reloadDataProps])

  const { classes } = useStyle()

  const navigateTo = () => {
    if (token && orgId) {
      setLoading(true)
      getData({
        token,
        orgId,
        query: {
          filters: {
            type,
            status,
            to,
            from,
          },
          endCursor,
          pageSize,
          search,
        },
      }).then(() => setLoading(false))
    }
  }

  const onScroll = ({ clientHeight, scrollHeight, scrollTop }: ScrollEvent) => {
    const fetchHeight: number = scrollHeight - clientHeight - 500
    if (scrollTop > fetchHeight && !loading && hasNextPage) {
      navigateTo()
    }
  }

  const reloadData = () => {
    setLoading(true)
    props.reloadData()
    if (token && orgId) {
      getData({
        token,
        orgId,
        query: {
          filters: {
            type,
            status,
            to,
            from,
          },
          pageSize,
          search,
        },
      }).then(() => setLoading(false))
    }
  }

  const download = () => {
    if (token && orgId) {
      downloadData({
        token,
        orgId,
        query: {
          filters: {
            type,
            status,
            to,
            from,
          },
          search,
        },
      })
    }
  }

  const saveFile = (file: any) => {
    if (token && orgId) {
      return saveWhatsappFile({
        orgId,
        token,
        item: file,
      })
    }
    return Error
  }

  const rowRenderer = (row: RowType) => {
    const { key, index, style } = row
    const isLoadingRow = !data[index]

    const viewContact = (contactId: number) =>
      history.push(`/contacts/edit/${contactId}`, {
        fromUrl: window.location.pathname + window.location.search,
      })

    return (
      <div className={`logs-list-row ${classes.cardSize}`} key={key} style={style}>
        {isLoadingRow ? (
          <LoadingMessagesRow />
        ) : (
          <>
            {tab === 'messages' && (
              <MessageRow
                windowWidth={windowWidth}
                {...data[index]}
                history={history}
                setSendMessage={setSendMessage}
                openSendMessageModalHandler={() => setSendMessageModal(true)}
                viewContact={viewContact}
              />
            )}
            {tab === 'calls' && (
              <CallRow
                windowWidth={windowWidth}
                {...data[index]}
                history={history}
                viewContact={viewContact}
                isLoadingRow={isLoadingRow}
              />
            )}
            {tab === 'whatsapp' && (
              <WhatsappRow
                {...data[index]}
                history={history}
                isLoadingRow={isLoadingRow}
                orgId={orgId}
                part={data[index]}
                windowWidth={windowWidth}
                getFilesItem={getFilesItem}
                openSendWhatsappMessageModalHandler={() => setSendWhatsappMessageModal(true)}
                setSendWhatsappMessage={setSendWhatsappMessage}
                token={token}
                viewContact={viewContact}
              />
            )}
            {tab === 'topups' && (
              <TopupRow
                windowWidth={windowWidth}
                {...data[index]}
                history={history}
                viewContact={viewContact}
                isLoadingRow={isLoadingRow}
              />
            )}
          </>
        )}
      </div>
    )
  }

  const hydrate = React.useCallback(() => getSenderIdList({ token, orgId }), [orgId, token, getSenderIdList])

  return (
    <div>
      <Loading isLoading={loading} error={error}>
        <Modal isOpen={sendMessageModal} icon="sms" title="Send message" onClose={() => setSendMessageModal(false)}>
          <SendMessage
            hydrate={hydrate}
            error={{}}
            senderIds={senderIds}
            totalRecipients={contactIds.length}
            recipients={recipients}
            contactIds={contactIds}
            senderId={msgSenderId}
            personalizationList={personalizationList}
            closeHandler={() => {
              setSendMessageModal(false)
              navigateTo()
            }}
            sendMessage={(messageProps: any) =>
              sendMessage({
                token,
                orgId,
                ...messageProps,
              })
            }
          />
        </Modal>
        <Modal
          isOpen={sendWhatsappMessageModal}
          icon="whatsapp"
          title="Send WhatsApp message"
          onClose={() => setSendWhatsappMessageModal(false)}
        >
          <Whatsapp
            search={''}
            loading={loading}
            isAllContacts={false}
            excludeIds={[]}
            includedGroupIds={[]}
            hydrate={hydrate}
            senderIds={whatsappSenderIds}
            totalRecipients={contactIds.length}
            recipients={recipients}
            contactIds={contactIds}
            senderId={msgSenderId}
            personalizationList={personalizationList}
            getFile={(item: any) => getFilesItem({ orgId, token, name: item })}
            onMicAccessError={onMicAccessError}
            closeHandler={() => {
              setSendWhatsappMessageModal(false)
              navigateTo()
            }}
            sendWhatsappMessage={(messageProps: any) =>
              sendWhatsappMessage({
                token,
                orgId,
                ...messageProps,
              })
            }
            saveFile={saveFile}
          />
        </Modal>
        <ContentLayout>
          <TableToolbar>
            <div className={classes.filterTagsWrap}>
              <FilterTags
                filters={filters}
                onChange={({ name, value }: FixMeLater) => {
                  props.reloadData()
                  navigateLogs({
                    search,
                    history,
                    tab,
                    filters: {
                      ...filters,
                      [name]: {
                        ...filters[name],
                        value,
                      },
                    },
                  })
                }}
              />
            </div>
            <div>
              <SearchBar
                label={searchLabelMap[tab]}
                namespace="logs"
                tooltipTitle={searchTooltipMap[tab]}
                disabled={loading}
                searchTerm={search}
                onSubmit={(searchTerm: string) => {
                  setSearch(searchTerm)
                  navigateLogs({
                    tab,
                    history,
                    search: searchTerm,
                    filters,
                  })
                }}
              />
            </div>
            <div id={`${tab}-filter`} className={classes.filtersWrap}>
              <Filters
                data={filters}
                onChange={(key, { value }) => {
                  navigateLogs({
                    search,
                    history,
                    tab,
                    filters: {
                      ...filters,
                      [key]: {
                        ...filters[key],
                        value,
                      },
                    },
                  })
                }}
              />
            </div>
            <div className={classes.downloadWrap}>
              <Tooltip id={`download-${tab}`} title={`Download the ${tab} list.`}>
                <Button
                  className={`download-${tab}-btn`}
                  variant="outlined"
                  onClick={download}
                  startIcon={<Icon>cloud_download</Icon>}
                >
                  Download {tabLabels[tab]}
                </Button>
              </Tooltip>
            </div>
            <div>
              <Tooltip id={`reload-${tab}`} title={`Reload ${tab}`}>
                <IconButton onClick={reloadData}>
                  <Icon>refresh</Icon>
                </IconButton>
              </Tooltip>
            </div>
          </TableToolbar>
        </ContentLayout>
        {!!data.length && (
          <Paper>
            <List
              className="log-list"
              height={windowHeight - 2 * 64 - 48}
              width={windowWidth}
              style={{
                paddingTop: '16px',
                outline: 'none',
              }}
              rowCount={data.length + (loading ? 1 : 0)}
              rowHeight={getRowHeight(tab)}
              rowRenderer={rowRenderer}
              onScroll={onScroll}
            />
          </Paper>
        )}
        {!data.length && !loading && (
          <Typography variant="h6" color="textSecondary" align="center">
            No {tabLabels[tab]} were found.
          </Typography>
        )}
      </Loading>
    </div>
  )
}

const getRowHeight = (tab: string) => {
  switch (tab) {
    case 'topups':
      return 120
    case 'whatsapp':
      return 220
    default:
      return 160
  }
}
