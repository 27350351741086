import { createSelector } from 'reselect'

import { CommonUserType, UserDetailsState } from '@/app/module/user/types'
import { selectUserDetails } from '@/app/module/user/store/selectors'

export const selectUserDetailsState = (state: { user: { details: UserDetailsState } }) => state.user.details

export const selectUserInit = createSelector(selectUserDetailsState, (details: UserDetailsState) => details.init)

export const selectUserLoading = createSelector(selectUserDetailsState, (details: UserDetailsState) => details.loading)

export const selectSkippingTours = createSelector(selectUserDetails, (user: CommonUserType | string) => {
  if (typeof user === 'string') {
    return {}
  }

  return user.uiStore?.skippingTours || {}
})
