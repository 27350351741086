import React from 'react'
import Icon from '@mui/material/Icon'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { SelectOption } from '@/app/types'
import { RUNNING_CAMPAIGNS_DISABLED_TOOLTIP } from '@/app/module/campaigns/definitions'
import { CampaignTemplateType, SnippetType, Message as TMessage, ReminderMessage } from '@/app/module/campaigns/types'
import { ContentTypeIcon } from './content-type-icon'
import { useMessageSnippets } from './hooks/use-snippets'
import { getEmptyMessage } from './utils/empty-message'
import { AddFromLibraryButton } from './add-from-library-button'

const buttonStyle = {
  gap: 2,
  minWidth: '0',
  padding: '8px',
}

type MessageActionsProps = {
  index: number
  campaignType: CampaignTemplateType
  total: number
  published: boolean
  messageIds: string[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  supportedMessageTypes: SelectOption<string>[]
  timezone: string

  onMessageDuplicate: () => void
  onMessageCreate: (message: TMessage | ReminderMessage) => void
}

export function MessageActions({
  index,
  campaignType,
  total,
  published,
  timezone,
  messageIds,
  snippets,
  supportedMessageTypes,
  onMessageCreate,
  onMessageDuplicate,
}: MessageActionsProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { messageSnippets, isSupported } = useMessageSnippets({ snippets, supportedMessageTypes })
  const open = Boolean(anchorEl)
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      style={{
        display: 'flex',
        gap: 4,
        marginLeft: 'auto',
        textAlign: 'right',
      }}
    >
      {isSupported && (
        <AddFromLibraryButton
          campaignType={campaignType}
          messageSnippets={messageSnippets}
          messageIds={messageIds}
          index={index}
          style={buttonStyle}
          timezone={timezone}
          onMessageCreate={onMessageCreate}
        />
      )}
      <Tooltip title={published && index < total - 1 ? RUNNING_CAMPAIGNS_DISABLED_TOOLTIP : undefined}>
        <span>
          <Button
            id={`duplicate-part-${index}`}
            className="campaign-item-content-dup-button"
            disabled={published && index < total - 1}
            variant="contained"
            color="primary"
            style={buttonStyle}
            startIcon={<Icon>content_copy</Icon>}
            onClick={() => onMessageDuplicate()}
          >
            {campaignType === 'ivr' || campaignType === 'smssurvey' || campaignType === 'whatsappsurvey'
              ? 'Duplicate question'
              : 'Duplicate message'}
          </Button>
        </span>
      </Tooltip>
      <Tooltip title={published && index < total - 1 ? RUNNING_CAMPAIGNS_DISABLED_TOOLTIP : undefined}>
        <span>
          <Button
            id={`message-add-button-${index}`}
            className="campaign-item-content-add-button"
            disabled={published && index < total - 1}
            variant="contained"
            color="primary"
            style={buttonStyle}
            startIcon={<Icon>add</Icon>}
            endIcon={<Icon>arrow_drop_down</Icon>}
            onClick={handleMenuOpen}
          >
            {campaignType === 'ivr' || campaignType === 'smssurvey' || campaignType === 'whatsappsurvey'
              ? 'Add question'
              : 'Add message'}
          </Button>
          <Menu
            id={`message-add-menu-${index}`}
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': `message-add-button-${index}`,
            }}
          >
            {supportedMessageTypes.map((type) => (
              <MenuItem
                key={type.value}
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                onClick={() => {
                  const message = getEmptyMessage(campaignType, type.value, timezone, index + 1)
                  onMessageCreate(message)
                  handleMenuClose()
                }}
              >
                <ContentTypeIcon type={type.value} />
                <span>{type.label}</span>
              </MenuItem>
            ))}
          </Menu>
        </span>
      </Tooltip>
    </div>
  )
}
