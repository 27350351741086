import ExternalLink from '@/app/component/atom/external-link'
import { currencyFormat } from '@/app/service/util/format'
import { styled, Typography } from '@mui/material'
import React from 'react'

type Props = {
  chargeYearly: boolean
  priceMonthly: string
  priceYearly: string
}

const PlanPrice: React.FC<Props> = ({ chargeYearly, priceMonthly, priceYearly }) => {
  const priceYearlyNumber = parseFloat(priceYearly)
  const priceMonthlyNumber = parseFloat(priceMonthly)

  const discount = priceMonthlyNumber ? ((1 - priceYearlyNumber / 12 / priceMonthlyNumber) * 100).toFixed(0) : ''

  return (
    <Container>
      {priceMonthlyNumber > 0 ? (
        <>
          {chargeYearly ? (
            <Discount>
              <OldPrice color="textSecondary">{currencyFormat(priceMonthly, '$', 0)}</OldPrice>
              <Typography color="secondary">({discount}% off)</Typography>
            </Discount>
          ) : (
            <Discount>
              <SwitchText color="textSecondary">Switch to yearly and save</SwitchText>
              <Typography color="secondary">{discount}%</Typography>
            </Discount>
          )}
        </>
      ) : (
        <Typography variant="caption" color="textSecondary">
          &nbsp;&nbsp;
        </Typography>
      )}
      <ActualPrice>
        {priceMonthlyNumber > 0 ? (
          <>
            <Typography component="h4" data-testid="plan-price" variant="h4" color="primary">
              {currencyFormat(chargeYearly ? (priceYearlyNumber / 12).toFixed(0) : priceMonthly, '$', 0)}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              /mo
            </Typography>
          </>
        ) : (
          <Typography component="h4" variant="h4" color="primary">
            FREE
          </Typography>
        )}
      </ActualPrice>
      <div>
        {priceMonthlyNumber > 0 ? (
          <Typography color="textSecondary" data-testid="plan-total-price">
            {chargeYearly && (
              <>
                {currencyFormat(priceYearly, '$', 0)} billed <b>yearly</b>
              </>
            )}
            {!chargeYearly && (
              <>
                billed <b>monthly</b>
              </>
            )}
          </Typography>
        ) : (
          <Typography color="textSecondary">forever</Typography>
        )}
        <br />
        <ExternalLink
          color="secondary"
          href="https://www.engagespark.com/pricing/"
          style={{ fontWeight: 'bold' }}
          target="_blank"
        >
          plus sms, voice, and other usage fees
        </ExternalLink>
      </div>
    </Container>
  )
}

const Container = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  color: 'rgba(0, 0, 0, 0.54)',
}))

const Discount = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'baseline',
}))

const ActualPrice = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'baseline',
}))

const OldPrice = styled(Typography)(({ theme }) => ({
  textDecoration: 'line-through',
  marginRight: theme.spacing(0.5),
}))

const SwitchText = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}))

export default PlanPrice
