import React from 'react'
import { Part } from '@/app/module/campaigns/types'
import { useItemContext } from '@/app/module/campaigns/component/item-provider'

const defaultParts: Part[] = []

/* This hook can only be used in component that are wrapped in ItemProvider.
 * It returns the parts of the current campaign item.
 */
export const useCampaignParts = () => {
  const { item } = useItemContext()
  return React.useMemo(() => {
    if (!item || !('parts' in item.variables)) {
      return defaultParts
    }

    return item.variables.parts
  }, [item])
}
