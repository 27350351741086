import { Theme } from '@mui/material'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import cx from 'classnames'
import React, { CSSProperties, Component, ElementType, KeyboardEvent, MutableRefObject, ReactNode } from 'react'
import { withStyles } from 'tss-react/mui'
import FormControl from './form-control'

type Classes = {
  wrap?: string
  largeWrap?: string
  smallWrap?: string
  wrapRTL?: string
  lnBreak?: string
  input?: string
  inpurtRTL?: string
  largeInput?: string
  highlightContainer?: string
  highlightContainerInput?: string
  highlightContainerTextarea?: string
}

type Props = {
  autoFocus?: boolean
  classes?: Classes
  className?: string
  editable?: boolean
  error?: string
  fullWidth?: boolean
  HighlightComponent: ElementType<{ index: number; match: string }>
  highlightContainerClass?: string
  highlightContainerRef?: MutableRefObject<HTMLDivElement | undefined>
  highlightPattern: string
  id?: string
  info?: string
  inputRef: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | undefined>
  label?: string
  multiline?: boolean
  name: string
  rows?: number
  StandardComponent?: ElementType
  scrollable?: boolean
  size?: string
  style?: CSSProperties
  textDirection?: string
  value: string
  variant?: 'filled' | 'outlined' | 'standard'

  getInputRef?: (el: HTMLInputElement | HTMLTextAreaElement) => void
  isMatch?: (match: string) => boolean
  onBlur?: (target: { name: string; value: string }) => void
  onChange?: (target: { name: string; value: string }) => void
  onKeyDown?: (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    chunks: { index: number; startIndex: number; value: string }[],
  ) => void
  onSelect?: () => void
}

type State = {
  dir: string
  dirLock: boolean
  dirType: string
  infoTextHeight: number | null
  text: string
  scrollWidth?: number
}

class InputHighlight extends Component<Props, State> {
  state = {
    infoTextHeight: null,
    dirType: 'auto',
    dir: 'ltr',
    dirLock: false,
    text: '',
    scrollWidth: 0,
  }

  highlight: HTMLDivElement | undefined

  input: HTMLInputElement | HTMLTextAreaElement | undefined

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      this.props.value !== nextProps.value ||
      this.props.error !== nextProps.error ||
      this.props.info !== nextProps.info ||
      this.state.infoTextHeight !== nextState.infoTextHeight ||
      this.props.textDirection !== nextProps.textDirection ||
      this.state.scrollWidth !== nextState.scrollWidth ||
      this.props.highlightPattern !== nextProps.highlightPattern ||
      this.props.editable !== nextProps.editable
    )
  }

  handleInputScroll = () => {
    this.highlight?.scrollTo({
      left: this.input?.scrollLeft,
      top: this.input?.scrollTop,
    })
  }

  updateHighlight = () => {
    if (!this.highlight || !this.input) {
      return
    }

    this.highlight.scrollTo({
      left: this.input?.scrollLeft,
      top: this.input?.scrollTop,
    })
    this.setState({
      infoTextHeight: this.highlight.getBoundingClientRect().height,
    })

    if (this.props.multiline && this.props.scrollable) {
      this.setState({ scrollWidth: this.input.offsetWidth - this.input.clientWidth })
    }
  }

  componentDidMount() {
    this.input?.addEventListener('scroll', this.handleInputScroll)

    if (this.props.value) {
      this.updateHighlight()
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.value !== this.props.value) {
      this.updateHighlight()
    }
  }

  componentWillUnmount() {
    this.input?.removeEventListener('scroll', this.handleInputScroll)
  }

  createChunkComponent =
    (classes: Record<string, string>) =>
    ({ key, match, startIndex }: { key: number; match: string; startIndex: number }) => {
      const {
        isMatch = () => false,
        StandardComponent = ({ match: standardMatch }) =>
          standardMatch ? <span>{standardMatch}</span> : <span>&zwnj;</span>,
        HighlightComponent,
      } = this.props

      if (match === '\n') {
        return (
          <span key={key} className={classes.lnBreak}>
            <br />
          </span>
        )
      }

      if (isMatch(match)) {
        return <HighlightComponent key={key} index={startIndex} match={match} />
      }

      return <StandardComponent key={key} index={startIndex} match={match} />
    }

  lastSelectionStart?: number

  handleSelect = (chunks: { index: number; startIndex: number; value: string }[]) => {
    const { inputRef, onSelect } = this.props

    if (!inputRef.current || !onSelect) {
      return
    }

    const { selectionStart, selectionEnd } = inputRef.current

    if (selectionStart === null || selectionStart !== selectionEnd) {
      return
    }

    let snapIndex

    const movingForward = typeof this.lastSelectionStart === 'number' && this.lastSelectionStart < selectionStart

    for (let i = 0; i < chunks.length; i += 1) {
      const { startIndex, value } = chunks[i]

      if (value.match(this.props.highlightPattern)) {
        const endIndex = startIndex + value.length
        if (selectionStart > startIndex && selectionStart < endIndex) {
          snapIndex = movingForward ? endIndex : startIndex
          break
        }
      }
    }

    if (snapIndex !== undefined) {
      this.lastSelectionStart = snapIndex
      inputRef.current.setSelectionRange(snapIndex, snapIndex)
    } else {
      this.lastSelectionStart = selectionStart
    }

    onSelect()
  }

  render() {
    const classes = withStyles.getClasses(this.props)
    const {
      name,
      label,
      multiline = false,
      rows = 1,
      textDirection = 'ltr',
      highlightPattern,
      highlightContainerRef,
      editable = true,
      value = '',
      error = '',
      info = '',
      autoFocus,
      variant,
      onChange = () => {},
      onBlur = () => {},
      onKeyDown = () => {},
      getInputRef = () => {},
      inputRef,
      style = {},
      size = 'large',
      id,
    } = this.props
    const { scrollWidth } = this.state
    const { chunks, displayComponents } = processMessage(value, highlightPattern, this.createChunkComponent(classes))

    const highlightContainerStyle: CSSProperties = {}
    if (!multiline) {
      switch (variant) {
        case 'outlined':
          highlightContainerStyle.height = size === 'large' ? 'calc(100% - 20px - 5px)' : 'calc(100% - 16.5px - 5px)'
          highlightContainerStyle.marginBottom = 5
          break

        case 'standard':
          highlightContainerStyle.paddingTop = 5
          break
        default:
          highlightContainerStyle.marginBottom = 12
          break
      }
    }

    const marginHorizontal = variant === 'outlined' ? 14 : 0

    return (
      <FormControl error={error} info={info}>
        <div className={this.props.className} style={{ position: 'relative' }}>
          <Typography
            variant="body1"
            className={cx(classes.wrap, classes.highlightContainer, {
              [classes.largeWrap]: size === 'large',
              [classes.smallWrap]: size !== 'large',
              [classes.wrapRTL]: textDirection === 'rtl',
            })}
            component={({ className, children }: { className: string; children: React.ReactNode }) => (
              <div
                className={className}
                ref={(el) => {
                  if (el) {
                    this.highlight = el
                    if (highlightContainerRef) {
                      highlightContainerRef.current = el
                    }
                    this.updateHighlight() // update highlight whenever the container is rendered
                  }
                }}
                style={{
                  ...highlightContainerStyle,
                  marginRight: marginHorizontal + scrollWidth,
                  width: `calc(100% - ${marginHorizontal}px - ${marginHorizontal}px${
                    scrollWidth ? ` - ${scrollWidth}px` : ''
                  })`,
                }}
                onClick={() => {
                  if (!inputRef.current) {
                    return
                  }

                  inputRef.current.focus()
                }}
              >
                <div
                  className={cx({
                    [classes.highlightContainerInput]: !multiline,
                    [classes.highlightContainerTextarea]: !!multiline,
                  })}
                >
                  {children}
                </div>
              </div>
            )}
          >
            {displayComponents}
          </Typography>
          <TextField
            id={id}
            fullWidth
            inputRef={inputRef}
            autoFocus={autoFocus}
            name={name}
            error={!!error}
            disabled={!editable}
            multiline={multiline}
            rows={rows}
            variant={variant}
            label={label}
            onSelect={() => this.handleSelect(chunks)}
            value={value}
            onChange={(e) => {
              onChange({
                name,
                value: e.target.value,
              })
            }}
            onBlur={(e) => {
              onBlur({
                name,
                value: e.target.value,
              })
            }}
            style={{
              right: 0,
              ...style,
            }}
            inputProps={{
              ref: (el: HTMLInputElement | HTMLTextAreaElement | null) => {
                if (!el) {
                  // for some reasons, the ref here can be null
                  return
                }
                if (getInputRef) {
                  getInputRef(el)
                }
                this.input = el
              },
              spellCheck: false,
              className: `${classes.input} ${size === 'large' && classes.largeInput} ${
                textDirection === 'rtl' && classes.inpurtRTL
              }`,
              style: {
                height: `${size === 'large' ? `${this.state.infoTextHeight || 80}px` : 'auto'}`,
                right: 0,
                fontSize: '14px',
              },
              onKeyDown: (e) => {
                onKeyDown(e, chunks)
              },
            }}
          />
        </div>
      </FormControl>
    )
  }
}

const processMessage = (
  message: string,
  pattern: string,
  createComponent: ({ key, match, startIndex }: { key: number; match: string; startIndex: number }) => ReactNode,
) =>
  message
    .split(new RegExp(pattern ? `(${pattern}|\n)` : '(\n)', 'g'))
    .filter((p) => typeof p === 'string')
    .reduce(
      (acc, result, key) => ({
        currentLen: acc.currentLen + result.length,
        displayComponents: [
          ...acc.displayComponents,
          createComponent({
            key,
            match: result,
            startIndex: acc.currentLen,
          }),
        ],
        chunks: [
          ...acc.chunks,
          {
            index: key,
            startIndex: acc.currentLen,
            value: result,
          },
        ],
      }),
      {
        currentLen: 0,
        displayComponents: [],
        chunks: [],
      } as {
        currentLen: number
        displayComponents: ReactNode[]
        chunks: { index: number; startIndex: number; value: string }[]
      },
    )

export default React.memo(
  withStyles(InputHighlight, (_: Theme, props: Partial<Props>) => {
    const isOutlined = props.variant === 'outlined'

    return {
      wrap: {
        width: '100%',
        position: 'absolute',
        lineHeight: '1.8em',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
      },
      largeWrap: {
        marginTop: isOutlined ? 20 : 0,
        marginBottom: isOutlined ? 20 : 0,
        paddingBottom: 5,
        paddingTop: isOutlined ? 0 : 20,
        marginLeft: isOutlined ? 14 : 0,
        marginRight: isOutlined ? 14 : 0,
        minHeight: '80px',
        height: isOutlined ? 'calc(100% - 20px - 20px)' : 'auto',
        width: isOutlined ? 'calc(100% - 14px - 14px)' : '100%',
      },
      smallWrap: {
        marginTop: isOutlined ? 16.5 : 0,
        marginBottom: isOutlined ? 16.5 : 0,
        paddingBottom: 5,
        marginLeft: isOutlined ? 14 : 0,
        marginRight: isOutlined ? 14 : 0,
        height: isOutlined ? 'calc(100% - 16.5px - 16.5px)' : 'auto',
        width: isOutlined ? 'calc(100% - 14px - 14px)' : '100%',
      },
      wrapRTL: {
        textAlign: 'right',
        direction: 'rtl',
      },
      lnBreak: {
        minHeight: '28px',
      },
      input: {
        WebkitTextFillColor: 'transparent',
        color: 'transparent',
        caretColor: 'black',
        lineHeight: '1.8em',
        paddingBottom: '5px',
      },
      inpurtRTL: {
        textAlign: 'right',
        direction: 'rtl',
      },
      largeInput: {
        minHeight: '80px',
      },
      highlightContainer: {
        overflowX: 'hidden',
        overflowY: 'hidden',
      },
      highlightContainerInput: {
        height: '100%',
        whiteSpace: 'pre',
      },
      highlightContainerTextarea: {},
    } as Record<string, CSSProperties>
  }),
)
