import Layout from '@/app/component/layout/page'
import { AuthGuard, AuthHeader, VerifyOTPForm } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'

export default function VerifyOTPPage(props: any) {
  const { name, match, history } = props
  return (
    <AuthGuard url={match.url} path={match.path}>
      <Layout title="Two-factor Authentication - engageSPARK">
        <AuthHeader currentPage={name} />
        <VerifyOTPForm history={history} />
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}
