import ExternalLink from '@/app/component/atom/external-link'
import IconText from '@/app/component/layout/icon-text'
import { Grid, Icon, styled, Typography } from '@mui/material'
import React from 'react'

type Props = {}

type PlanFeature = {
  title: string
  subtitle: string
  features: React.ReactNode[]
}

const sharedFeatures: PlanFeature[] = [
  {
    title: 'Exceptional Customer Support',
    subtitle: 'A global support team that goes above and beyond to help you succeed.',
    features: ['Email & Website Chat', 'Calls', 'Live Training & Campaign Review'],
  },
  {
    title: 'Powerful, Intuitive Campaigns',
    subtitle: 'Automate your campaigns without writing a single line of code using our workflows:',
    features: [
      'SMS Surveys',
      'IVR Surveys (Robocall Surveys)',
      'WhatsApp Surveys',
      'CATI Surveys',
      'SMS Blasts',
      'Call Blasts',
      'Airtime Top-Up Broadcasts',
      'Drip Campaigns',
      'Reminders',
    ],
  },
  {
    title: 'Artificial Intelligence (AI)',
    subtitle:
      "Our AI features are designed to make your surveys more cost-effective. And we're improving them all the time!",
    features: [
      'Increase survey completion rates by understanding replies',
      'Let participants speak—on calls and WhatsApp',
      'Play personalized voice replies',
    ],
  },
  {
    title: 'Integrations & APIs',
    subtitle:
      'Easy-to-use but powerful: Our HTTP APIs and webhooks help integrate your case management system, reporting tools, or other external systems.',
    features: [
      'Send SMS',
      'Get notified of SMS deliveries',
      'Send Airtime Top-ups',
      'Trigger campaigns',
      'Forward data of completed surveys',
      <>
        And much more, see&nbsp;
        <ExternalLink href="https://www.engagespark.com/support/how-can-i-use-your-api/" target="_blank">
          our docs
        </ExternalLink>
      </>,
    ],
  },
  {
    title: 'User Permission Management',
    subtitle: 'Ensure your users have access to everything they need—but not more.',
    features: [
      'Invite & remove users with different roles',
      'Add fine-grained restrictions on contact fields to satisfy privacy / GDPR requirements',
    ],
  },
]

const PlansFeatures: React.FC<Props> = () => {
  return (
    <Container>
      <PlansTitle color="primary" variant="h3">
        Included in all plans
      </PlansTitle>
      <PlansFeaturesContainer>
        {sharedFeatures.map(({ title, subtitle, features }, index) => (
          <Grid key={index} container>
            <PlanFeatureCol item xs={12} sm={6}>
              <Typography sx={{ textAlign: 'right' }} variant="h6">
                {title}
              </Typography>
            </PlanFeatureCol>
            <PlanFeatureCol item xs={12} sm={6}>
              {!!subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
              {features.map((feature, j) => (
                <IconText key={j}>
                  <Icon color="primary">check</Icon>
                  {feature}
                </IconText>
              ))}
            </PlanFeatureCol>
          </Grid>
        ))}
      </PlansFeaturesContainer>
    </Container>
  )
}

const Container = styled('div')(() => ({}))

const PlansTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  margin: theme.spacing(6, 0, 2.5, 0),
  textAlign: 'center',
}))

const PlansFeaturesContainer = styled('div')(() => ({}))

const PlanFeatureCol = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}))

export default PlansFeatures
