import CreateIcon from '@mui/icons-material/Create'
import { FixMeLater, TableColumn } from '@/config/types'

type Props = {
  column: TableColumn
  editAction: FixMeLater
  classes: FixMeLater
}

export default function ColumnActions(props: Props) {
  const { column, editAction, classes } = props
  return (
    <div className={classes.powerTableColumnActions}>
      {column.editable && editAction && (
        <span
          className={classes.powerTableColumnActionsIcons}
          onClick={() => {
            editAction({ id: column.accessor, title: column.header })
          }}
        >
          <CreateIcon className={classes.powerTableColumnActionsIcons} />
        </span>
      )}
    </div>
  )
}
