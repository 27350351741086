import { Box, Button, Icon, Link } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { TYPES } from '@/app/module/campaigns/definitions'
import { BackendCampaignTemplate, CampaignTemplate } from '@/app/module/campaigns/types'
import CreateCampaigns, { CampaignConfig } from './create-campaigns'

type Props = {}

const CreateCampaignsContainer: React.FC<Props> = () => {
  const history = useHistory()

  const handleSelect = React.useCallback(
    (template: CampaignTemplate) => {
      const types = Object.values(TYPES)

      switch (template) {
        case CampaignTemplate.AirtimeTopup: {
          const drip = types.find((t) => t.template === BackendCampaignTemplate.Drip)
          if (drip) {
            history.push(`/campaigns/create/${drip.url}?template=${template}`)
          }
          break
        }

        case CampaignTemplate.VoiceBlast: {
          const ivr = types.find((t) => t.template === BackendCampaignTemplate.VoiceSurvey)
          if (ivr) {
            history.push(`/campaigns/create/${ivr.url}?template=${template}`)
          }
          break
        }

        default: {
          const type = types.find((t) => (t.template as string) === template)
          if (type) {
            history.push(`/campaigns/create/${type.url}`)
          }

          break
        }
      }
    },
    [history],
  )

  return (
    <>
      <CreateCampaigns campaignTypes={campaignTypes} onSelect={handleSelect} />
      <Box
        sx={{
          padding: (theme) => theme.spacing(2),
          textAlign: 'left',
        }}
      >
        <Link
          component={Button}
          href="/account/profile/api-tokens"
          rel="noopener"
          sx={{
            fontSize: 18,
          }}
        >
          Use our APIs <Icon style={{ fontSize: '18px', marginLeft: 2 }}>launch</Icon>
        </Link>
      </Box>
    </>
  )
}

const campaignTypes: CampaignConfig[] = [
  {
    name: 'Call Blast',
    template: CampaignTemplate.VoiceBlast,
    icon: TYPES.IVR.icon,
    description: 'Send a voice message to thousands, either pre-recorded or using text-to-speech.',
    example: 'critical alerts, educational messages, or reminders.',
    category: 'blast',
  },
  {
    name: 'IVR Survey',
    template: CampaignTemplate.VoiceSurvey,
    icon: TYPES.IVR.icon,
    description: 'Automated phone surveys, your go-to survey tool in low- and middle-income countries (LMICs).',
    example: 'MEAL studies, or to collect demographic data using random digit dialing (RDD).',
    category: 'survey',
  },
  {
    name: 'SMS Blast',
    template: CampaignTemplate.SMSBlast,
    icon: TYPES.SMSBLAST.icon,
    description: 'Send an SMS to many phone numbers—simple, fast, and supports personalization (e.g., "Hi Jane").',
    example: 'quick reminders, emergency alerts.',
    category: 'blast',
  },
  {
    name: 'SMS Survey',
    template: CampaignTemplate.SMSSurvey,
    icon: TYPES.SMSSURVEY.icon,
    description: 'Survey your audience with 2-way text messages.',
    example: 'Monitoring and Evaluation (M&E), research, quick feedback polls.',
    category: 'survey',
  },
  {
    name: 'WhatsApp Survey',
    template: CampaignTemplate.WhatsAppSurvey,
    icon: TYPES.WHATSAPPSURVEY.icon,
    description:
      'Send survey questions directly in WhatsApp, reducing messy data with buttons and lists—supports images, PDFs, videos, and more.',
    example: 'post-intervention study, or as a 24/7 hotline.',
    category: 'survey',
  },
  {
    name: 'Drip Campaign',
    template: CampaignTemplate.Drip,
    icon: TYPES.DRIP.icon,
    description: 'Send out a series of scheduled messages, using SMS, WhatsApp, or Voice.',
    example: 'post-workshop reminders, or educational messages.',
    category: 'time',
  },
  {
    name: 'Airtime Topup',
    template: CampaignTemplate.AirtimeTopup,
    icon: 'account_balance_wallet',
    description: 'Send mobile credit to phones, in bulk or individually with personalized amounts per recipient.',
    example: 'rewards/incentives for completing any engageSPARK survey.',
    category: 'blast',
  },
  {
    name: 'CATI Survey',
    template: CampaignTemplate.CATI,
    icon: TYPES.CATI.icon,
    description:
      'A questionnaire that guides your call center agents through the interview, ensuring high data quality.',
    example: 'post-intervention studies.',
    category: 'survey',
  },
  {
    name: 'Reminder',
    template: CampaignTemplate.Reminder,
    icon: 'alarm',
    description: 'Send reminders about an event via SMS (and soon WhatsApp, or Voice).',
    example: 'maternal health education & reminders, or workshop marketing.',
    category: 'time',
  },
]

export default CreateCampaignsContainer
