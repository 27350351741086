import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { isEmpty, pathOr } from 'ramda'
import { makeStyles } from 'tss-react/mui'
import { Input } from '@/app/component/atom/form'
import Loading from '@/app/component/guard/loading'
import Fields from '@/app/component/wrapper/fields'
import validate, { hasPasswordError, hasRequiredError } from '@/app/service/validate'

function ResetPassword({ token, orgId, loading, error, submit, clearResetPasswordError, params, history }) {
  const { classes } = useStyles()
  const id = params.get('id')
  const key = params.get('key')
  const hasRequestError = !isEmpty(error)
  return (
    <Loading isLoading={loading}>
      <Fields
        fields={{
          password1: '',
          password2: '',
        }}
        validation={{
          password1: validate(hasRequiredError, hasPasswordError, (value, fields) =>
            fields.password2.value ? isPwMatching(value, fields.password2.value) : '',
          ),
          password2: validate(hasRequiredError, hasPasswordError, (value, fields) =>
            fields.password1.value ? isPwMatching(value, fields.password1.value) : '',
          ),
        }}
        onSubmit={({ item }) =>
          submit({
            token,
            orgId,
            item,
            id,
            key,
          }).then((res) => {
            if (!(res instanceof Error)) {
              history.push('/login')
            }
          })
        }
      >
        {({ fields, changed, hasErrors, submitHandler, fieldChangeHandler, fieldBlurHandler }) => (
          <form
            onSubmit={(e) => {
              e.persist?.()
              e.preventDefault()
              submitHandler()
            }}
            noValidate
          >
            <Card className={classes.wrap}>
              <CardContent className={classes.content}>
                <Icon className={classes.icon}>account_circle</Icon>
                <Typography color="textSecondary" variant="caption">
                  Please enter your new password.
                </Typography>
                <Input
                  name="password1"
                  type="password"
                  label="New Password"
                  editable={true}
                  value={fields.password1.value}
                  error={fields.password1.error}
                  onChange={({ name, value }) => {
                    if (hasRequestError) {
                      clearResetPasswordError()
                    }
                    fieldChangeHandler({ name, value })
                    fieldChangeHandler({
                      name: 'password2',
                      value: fields.password2.value,
                    })
                  }}
                  onBlur={fieldBlurHandler}
                />
                <Input
                  name="password2"
                  type="password"
                  label="Confirm New Password"
                  editable={true}
                  value={fields.password2.value}
                  error={fields.password2.error}
                  info="Use at least 15 characters."
                  onChange={({ name, value }) => {
                    if (hasRequestError) {
                      clearResetPasswordError()
                    }
                    fieldChangeHandler({ name, value })
                    fieldChangeHandler({
                      name: 'password1',
                      value: fields.password1.value,
                    })
                  }}
                  onBlur={fieldBlurHandler}
                />
              </CardContent>
              <CardActions style={{ justifyContent: 'flex-end', paddingBottom: '1rem' }}>
                <Button
                  type="submit"
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={!changed || loading || hasErrors || fields.password1.value !== fields.password2.value}
                >
                  Reset Password
                </Button>
              </CardActions>
            </Card>
          </form>
        )}
      </Fields>
    </Loading>
  )
}

const useStyles = makeStyles()((theme) => ({
  wrap: {
    maxWidth: '400px',
    margin: 'auto',
    marginTop: 'calc(60px + 5%)',
    marginBottom: '3rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2rem',
  },
  error: {
    height: '60px',
    background: pathOr('', ['palette', 'error', 'main'], theme),
    '& p': {
      color: pathOr('', ['palette', 'error', 'contrastText'], theme),
    },
  },
  icon: {
    margin: 'auto',
    marginBottom: '15px',
    fontSize: '4rem !important',
    color: pathOr('', ['palette', 'primary', 'main'], theme),
  },
  button: {
    alignSelf: 'flex-end',
  },
}))

const isPwMatching = (oldPw, newPw) => (newPw && oldPw && newPw === oldPw ? '' : 'Passwords have to match')

export default ResetPassword
