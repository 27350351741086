import { useDrop } from 'react-dnd'

export default function CampaignContentQuestionActionDrop({ dragContext, onMove, children }) {
  const [, dropRef] = useDrop({
    accept: dragContext,
    drop: ({ index: fromIndex }) => {
      onMove(fromIndex)
    },
  })

  return <div ref={dropRef}>{children}</div>
}
