import { styled } from '@mui/material'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { isEmpty } from 'ramda'
import React from 'react'
import CustomTooltip from '@/app/component/atom/tooltip'
import FieldStatic, { DISPLAY_MAP as DEFAULT_DISPLAY_MAP } from '@/app/component/layout/field/static'
import IconText from '@/app/component/layout/icon-text'
import { TYPES } from '@/app/module/campaigns/definitions'

const DISPLAY_MAP = {
  ...DEFAULT_DISPLAY_MAP,
  type: ({ field }) => {
    const typeConfig = TYPES[field.toUpperCase()]
    if (!typeConfig) {
      return null
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {typeof typeConfig.icon === 'string' && (
          <Icon color="action" fontSize="small">
            {typeConfig.icon}
          </Icon>
        )}
        {typeof typeConfig.icon === 'function' && <typeConfig.icon />}
        <Typography component="span" sx={{ marginLeft: 0.5 }}>
          {!isEmpty(field) ? typeConfig.title : ''}
        </Typography>
      </div>
    )
  },
  state: ({ field }) => {
    const { aborted, archived, published, launched, open } = field

    const archivedText = archived ? ' (archived)' : null

    if (aborted) {
      return (
        <IconText>
          <Icon>block</Icon>
          <Typography>stopped {archivedText}</Typography>
        </IconText>
      )
    }
    if (published && !launched) {
      return (
        <IconText>
          <Icon>schedule</Icon>
          <Typography>scheduled {archivedText}</Typography>
        </IconText>
      )
    }
    if (published && launched) {
      return (
        <IconText>
          <CustomTooltip title={`${open ? 'Open' : 'Closed'} for new subscriptions. Change in Actions menu.`}>
            <Icon color={open ? 'success' : 'error'}>{open ? 'lock_open' : 'lock'}</Icon>
          </CustomTooltip>
          <Typography>live {archivedText}</Typography>
        </IconText>
      )
    }
    return (
      <IconText>
        <Icon>create</Icon>
        <Typography>draft {archivedText}</Typography>
      </IconText>
    )
  },
  runs: ({
    field: {
      started,
      inProgress,
      live,
      scheduled,
      completed,
      endpointReached,
      unsubscribed,
      runsWithFailures,
      paused,
      error,
    } = {},
    classes,
  }) => (
    <React.Fragment>
      <Typography className="runs-stats">
        {started}
        <SubStatsWrapper>
          <CustomTooltip
            title={
              <Typography>
                <span>{live} live</span>
                <br />
                <span>{paused} paused</span>
                <br />
                <span>{scheduled} scheduled</span>
              </Typography>
            }
          >
            <span className="runs-in-progress">({inProgress} in progress</span>
          </CustomTooltip>
          ,{' '}
          <CustomTooltip
            title={
              <Typography>
                <span>{endpointReached} completed</span>
                <br />
                <span>{unsubscribed} unsubscribed</span>
                <br />
                <span>{error} failed</span>
              </Typography>
            }
          >
            <span className="runs-done">{completed} done)</span>
          </CustomTooltip>
        </SubStatsWrapper>
      </Typography>
      {runsWithFailures > 0 && (
        <CustomTooltip
          title={`${runsWithFailures} subscription(s) with errors.
See the report for details!`}
        >
          <Icon className={`${classes.error} ${classes.errorIcon} `} fontSize="small">
            error
          </Icon>
        </CustomTooltip>
      )}
    </React.Fragment>
  ),
}

export default function CampaignsTableField({ name, specs, field }) {
  return (
    <FieldStatic
      name={name}
      specs={specs}
      title={typeof field === 'string' ? field : ''}
      field={typeof field === 'string' && field.length > 100 ? `${field.substring(0, 98)}[...]` : field}
      displayMap={DISPLAY_MAP}
    />
  )
}

const SubStatsWrapper = styled('span')(() => ({
  marginLeft: '0.35rem',
  opacity: '0.45',
  borderBottom: '2.5px dashed grey',
}))
