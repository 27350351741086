import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import LanguageIcon from '@mui/icons-material/Language'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { equals, find, pathOr, propEq } from 'ramda'
import React from 'react'
import { useDeepState } from '@/app/helpers'
import { ItemType, LanguageType, hasContent } from '@/app/module/campaigns/helpers'

type Content = 'text' | 'playfile' | 'image' | 'say'
type Type = 'message' | 'audio'

type Props = {
  activeLanguage: string
  content: Content
  defaultLanguage?: string
  disabled: boolean
  item: ItemType
  languages?: LanguageType[]
  multilingual: boolean
  type: Type
  setActiveLanguage: (language: string) => void
}

export default function LanguageSelector(props: Props) {
  const { multilingual, setActiveLanguage, item, type, content, disabled } = props

  const previousLanguages = React.useRef<LanguageType[]>([])
  const previousDefaultLanguage = React.useRef<string>('')

  const [activeLanguage] = useDeepState(props.activeLanguage || '')
  const [defaultLanguage] = useDeepState(props.defaultLanguage || '')
  const [languages] = useDeepState(props.languages || [])

  const updateLanguages = React.useCallback(() => {
    if (languages?.length === 0 || !defaultLanguage) {
      setActiveLanguage('')
    } else if (previousLanguages.current.length === 0 && languages?.length > 0) {
      setActiveLanguage(defaultLanguage)
    } else {
      const oldActiveLanguage = find(propEq('value', activeLanguage), languages)
      if (!oldActiveLanguage) {
        setActiveLanguage(defaultLanguage)
      }
    }
    previousLanguages.current = languages
    previousDefaultLanguage.current = defaultLanguage
  }, [activeLanguage, defaultLanguage, languages, setActiveLanguage])

  React.useEffect(() => {
    previousLanguages.current = languages
    previousDefaultLanguage.current = defaultLanguage
  }, [defaultLanguage, languages])

  React.useEffect(() => {
    if (!equals(previousLanguages.current, languages) || !equals(previousDefaultLanguage.current, defaultLanguage)) {
      updateLanguages()
    } else if (!activeLanguage && languages.length > 0) {
      setActiveLanguage(defaultLanguage)
    }
  }, [activeLanguage, defaultLanguage, languages, setActiveLanguage, updateLanguages])

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <LanguageIcon fontSize="small" color={hasContent(pathOr({}, [type], item), content) ? 'primary' : 'inherit'} />
      {multilingual && (
        <Select
          variant="standard"
          id="language-selector"
          style={{
            marginLeft: '10px',
            width: '125px',
          }}
          disabled={!multilingual || disabled}
          value={activeLanguage}
          renderValue={(selected) => {
            const found = find(propEq('value', selected), languages)
            if (found) {
              return (
                <Typography>
                  {found.name}
                  {found.value === defaultLanguage && ' (default)'}
                </Typography>
              )
            }
            return <Typography>Select a language</Typography>
          }}
          onChange={(e) => {
            setActiveLanguage(e.target.value as string)
          }}
        >
          {languages.map(({ value, name, checked }) => (
            <MenuItem
              className="language-selector-list-item"
              key={value}
              value={value}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                style={{
                  marginRight: '5px',
                }}
              >
                {name}
                {defaultLanguage === value && ' (default)'}
              </Typography>
              {(checked || isDone(item, type, content, value, defaultLanguage)) && (
                <CheckRoundedIcon
                  fontSize="small"
                  style={{
                    color: 'green',
                  }}
                />
              )}
            </MenuItem>
          ))}
        </Select>
      )}
    </div>
  )
}

export const isDone = (item: ItemType, type: Type, content: Content, language: string, defaultLanguage: string) => {
  const isDefaultLanguage = defaultLanguage === language
  if (content !== 'say') {
    return (
      !!pathOr('', [type, 'translations', language, content], item) ||
      !!(isDefaultLanguage && pathOr('', [type, content], item))
    )
  }

  if (isDefaultLanguage) {
    return !!pathOr('', [type, content], item) && !!pathOr('', [type, 'voice'], item)
  }

  return (
    !!pathOr('', [type, 'translations', language, content], item) &&
    !!pathOr('', [type, 'translations', language, 'voice'], item)
  )
}
