import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import Typography from '@mui/material/Typography'
import React from 'react'
import TableLayout from '@/app/component/layout/table'
import TableRow from '@/app/component/layout/table/row'
import { styled } from '@mui/material'
import Phonenumber from './phone-number'

type Props = {
  classes: any
  loading: boolean
  phoneNumbers: any[]
  pagination?: boolean
  configurePhoneNumber: (id: string) => void
}

const PhoneNumbersTable: React.FC<Props> = ({
  loading,
  phoneNumbers,
  pagination = false,
  configurePhoneNumber,
}: Props) => {
  return (
    <div>
      <TableLayout
        loading={loading}
        pageSize={phoneNumbers.length}
        pagination={pagination}
        emptyMessage={'Please contact support to order a phone number.'}
      >
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell
                style={{
                  textAlign: 'center',
                }}
              >
                # of SMS Rules
              </StyledTableCell>
              <StyledTableCell
                style={{
                  textAlign: 'center',
                }}
              >
                # of WhatsApp Rules
              </StyledTableCell>
              <StyledTableCell
                style={{
                  textAlign: 'center',
                }}
              >
                # of Call Rules
              </StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {phoneNumbers.map(
              ({ phoneNumber, id, name, hookCount = 0, whatsappHookCount = 0, callHookCount = 0, countryCode }) => (
                <StyledTableRow key={id} isLoading={loading}>
                  <StyledTableCell>
                    <Phonenumber phoneNumber={phoneNumber} countryCode={countryCode} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>{name}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {hookCount}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {whatsappHookCount}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {callHookCount}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableActions>
                    <Button color="primary" size="small" variant="contained" onClick={() => configurePhoneNumber(id)}>
                      Configure Phone Number
                    </Button>
                  </StyledTableActions>
                </StyledTableRow>
              ),
            )}
          </TableBody>
        </StyledTable>
      </TableLayout>
      <Divider />
    </div>
  )
}

const StyledTable = styled(Table)(() => ({
  border: '1px solid rgba(224, 224, 224, 1)',
}))

const StyledTableRow = styled(TableRow)(() => ({
  padding: '15px 0px',
}))

const StyledTableCell = styled(TableCell)(() => ({
  padding: '16px',
}))

const StyledTableActions = styled(StyledTableCell)(() => ({
  textAlign: 'right',
}))

export default PhoneNumbersTable
