import { propOr } from 'ramda'
import { SinglePersonalizationType } from './types'

export const transformFromPersonalization = (
  value: string,
  pzMap: Record<string, SinglePersonalizationType>,
  transformMatch?: (match: string) => string,
) => transform(value, pzMap, 'label', transformMatch)

export const transformToPersonalization = (
  value: string,
  pzMap: Record<string, SinglePersonalizationType>,
  transformMatch?: (match: string) => string,
) => transform(value, pzMap, 'value', transformMatch)

const transform = (
  value: string,
  pzMap: Record<string, SinglePersonalizationType>,
  attribute: string,
  transformMatch?: (match: string) => string,
) =>
  value.replace(/\{\{(.*?)\}\}/g, (_, match) => {
    const matchString: string = transformMatch ? transformMatch(match) : match
    const foundPersonalization = propOr({}, matchString, pzMap)
    return `{{${propOr(match, attribute, foundPersonalization)}}}`
  })
