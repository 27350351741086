import { pathOr } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLanguages, selectSpeechLanguages } from '@/app/module/utils/store/selectors'
import { LanguageType } from '@/app/module/campaigns/helpers'
import {
  hasSpeechLanguage,
  processActionsLanguageChange,
  processReconnectLanguageChange,
} from '@/app/module/campaigns/language-helpers'
import { processPartsForLanguageChange, processWelcomeForLanguageChange } from '@/app/module/campaigns/store/selectors'
import { useItemContext } from '@/app/module/campaigns/component/item-provider'
import { selectCampaignTypeContent } from '@/app/module/campaigns/component/item/steps/content/types/multimessage/content'
import { isReminderCampaign } from '@/app/module/campaigns/models/classes/reminder-campaign.class'
import LanguageConfiguration from './language-configuration'

type Props = {}

const LanguagesContainer: React.FC<Props> = () => {
  const { item, setItem } = useItemContext()
  const languageList = useSelector(selectLanguages)
  const speechLanguageList = useSelector(selectSpeechLanguages)

  const languages: LanguageType[] = React.useMemo(() => pathOr([], ['uiStore', 'languages'], item), [item])
  const defaultLanguage: string = React.useMemo(() => pathOr('', ['uiStore', 'defaultLanguage'], item), [item])
  const speechEnabled = React.useMemo(() => hasSpeechLanguage(languages), [languages])

  const handleLanguagesChange = React.useCallback(
    (updatedLanguages: LanguageType[], updatedDefaultLanguage: string) => {
      if (!item || !setItem) {
        // should never happen but just in case
        return
      }

      if (isReminderCampaign(item)) {
        const newItem = item.changeLanguage(updatedLanguages, updatedDefaultLanguage)
        setItem(newItem)

        return
      }

      const updatedParts = processPartsForLanguageChange(
        {
          ...item,
          template: item.type === 'drip' ? 'multidrip' : item.type || '',
        },
        updatedLanguages,
        updatedDefaultLanguage,
        processActionsLanguageChange,
      )

      const newItem = processReconnectLanguageChange(item, {
        defaultLanguage,
        languages,
        newDefaultLanguage: updatedDefaultLanguage,
        newLanguages: updatedLanguages,
      })

      const newVariables: any = {
        ...newItem.variables,
        parts: updatedParts,
      }
      const campaignContent = selectCampaignTypeContent({ item }) as any
      if (campaignContent.welcome) {
        const newWelcome = processWelcomeForLanguageChange(item, updatedLanguages, updatedDefaultLanguage)
        newVariables.welcome = newWelcome
      }

      setItem({
        ...newItem,
        variables: newVariables,
        uiStore: {
          ...newItem.uiStore,
          languages: updatedLanguages,
          defaultLanguage: updatedDefaultLanguage,
        },
      })
    },
    [defaultLanguage, item, languages, setItem],
  )

  return (
    <LanguageConfiguration
      defaultLanguage={defaultLanguage}
      languages={languages}
      languageList={languageList}
      speechEnabled={speechEnabled}
      speechLanguageList={speechLanguageList}
      speechSupported={item?.type === 'ivr'}
      onSave={handleLanguagesChange}
    />
  )
}

export default LanguagesContainer
