import Button from '@mui/material/Button'
import { compose, pick } from 'ramda'
import { Component, Fragment } from 'react'
import DeleteDialog from '../../../component/atom/delete-dialog'
import Loading from '../../../component/guard/loading'
import FormLayout from '../../../component/layout/form'
import createHydrated from '../../../component/wrapper/hydratable'
import validate, { hasPhoneError, hasRequiredError } from '../../../service/validate'

class GroupsItem extends Component {
  state = {
    loading: false,
    deleteConfirm: false,
  }

  submitHandler({ item }) {
    const { token, orgId, query, itemId, submit, getGroups, onExit } = this.props
    this.setState({
      loading: true,
    })

    submit({
      token,
      orgId,
      item: {
        id: itemId,
        ...item,
      },
      // eslint-disable-next-line consistent-return
    }).then((res) => {
      this.setState({
        loading: false,
      })
      if (!(res instanceof Error)) {
        onExit()
        return getGroups({
          token,
          orgId,
          query,
        })
      }
    })
  }

  deleteGroup = () => {
    const { token, orgId, itemId, deleteGroupsItem, getGroups, onExit, query } = this.props

    this.setState({
      loading: true,
    })
    deleteGroupsItem({
      token,
      orgId,
      itemId,
      // eslint-disable-next-line consistent-return
    }).then((res) => {
      this.setState({
        loading: false,
      })
      if (!(res instanceof Error)) {
        onExit()
        return getGroups({
          token,
          orgId,
          query,
        })
      }
    })
  }

  render() {
    const { error, headers, specs, item: data = {}, itemId } = this.props

    const { loading } = this.state

    const groupData = itemId ? data : {}

    return (
      <Loading isLoading={loading} error={error} testId="group-item">
        <DeleteDialog
          text="Are you sure you want to delete the selected group? (Contacts inside the group will not be deleted)"
          isOpen={this.state.deleteConfirm}
          onClose={() => {
            this.setState({
              deleteConfirm: false,
            })
          }}
          onConfirm={() => {
            this.setState({
              deleteConfirm: false,
            })
            this.deleteGroup()
          }}
        />
        <FormLayout
          loading={loading}
          headers={headers}
          config={specs}
          values={pick(['id', 'name', 'description'], groupData)}
          validation={{
            fullPhoneNumber: validate(hasRequiredError, hasPhoneError),
          }}
          onSubmit={({ item }) => this.submitHandler({ item })}
          Actions={({ changed, hasErrors }) => (
            <Fragment>
              <Button color="primary" variant="contained" type="submit" disabled={loading || !changed || hasErrors}>
                Save
              </Button>
            </Fragment>
          )}
        />
        {itemId && (
          <div
            style={{
              paddingLeft: '20px',
            }}
          >
            <Button
              style={{
                marginTop: '-90px',
              }}
              size="small"
              color="secondary"
              onClick={() =>
                this.setState({
                  deleteConfirm: true,
                })
              }
            >
              Delete Group
            </Button>
          </div>
        )}
      </Loading>
    )
  }
}

export default compose(createHydrated)(GroupsItem)
