import { values } from 'ramda'
import Layout from '../../component/layout/page'
import { PAGE } from '../../definitions'
import { AuthGuard, AuthHeader } from '../../module/auth'
import Notifications from '../../module/notifications'
import { UserProfile } from '../../module/user'
import { TABS } from '../../module/user/component/profile'
import NotFoundPage from '../not-found'

export default function ProfilePage({ name, routes, match, history }) {
  if (values(TABS).indexOf(match.params.tabName) === -1) {
    return <NotFoundPage />
  }

  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout title="Profile - engageSPARK">
        <AuthHeader currentPage={PAGE.ACCOUNT.PROFILE.INDEX} />
        <UserProfile history={history} tabName={match.params.tabName} />
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}
