import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { omit } from 'ramda'
import { Component } from 'react'
import { getFields, toDerivedFields, toValidationError, toValueFields, validateFields } from '../../../service/util'

export default class EditFields extends Component {
  state = {
    _changed: false,
    ...getFields(toDerivedFields)(this.props.fields || {}),
  }

  getStateFields() {
    return omit(['_changed'], this.state)
  }

  componentDidUpdate(prevProps) {
    const { fields } = this.props

    if (JSON.stringify(prevProps.fields) !== JSON.stringify(fields)) {
      this.setState({
        ...getFields(toDerivedFields)(fields),
      })
    }
  }

  fieldChangeHandler = ({ name, value, error = '' }) => {
    this.setState({
      _changed: true,
      [name]: {
        value,
        error,
      },
    })
  }

  fieldBlurHandler = ({ name, value, error = '' }) => {
    const { validation = {} } = this.props
    const validationFn = validation[name]
    if (name === 'fullPhoneNumber') {
      const phoneNumber = parsePhoneNumberFromString(
        `${value.length > 0 && value.charAt(0) !== '+' ? '+' : ''}${value}`,
      )
      const parsedPhoneNumber = phoneNumber ? `${phoneNumber.countryCallingCode}${phoneNumber.nationalNumber}` : value
      const blurError = error || (validationFn ? validationFn(parsedPhoneNumber, this.getStateFields()) : '')
      this.setState({
        [name]: {
          value: parsedPhoneNumber,
          error: blurError,
        },
      })
    } else {
      const blurError = error || (validationFn ? validationFn(value, this.getStateFields()) : '')
      this.setState({
        [name]: {
          value,
          error: blurError,
        },
      })
    }
  }

  submitHandler = () => {
    const { onSubmit, validation = {} } = this.props

    const { fields, hasError } = validateFields(validation, this.getStateFields())

    if (hasError) {
      this.setState({
        ...fields,
      })
      return Promise.resolve()
    }

    const values = getFields(toValueFields, {})(fields)

    return onSubmit({
      item: values,
    })
  }

  render() {
    const Content = this.props.children
    const fields = this.getStateFields()
    const hasValidationError = getFields(toValidationError, '')(fields) !== ''
    return (
      <Content
        {...this.props}
        // eslint-disable-next-line
        changed={this.state._changed}
        hasErrors={hasValidationError}
        fields={fields}
        submitHandler={this.submitHandler}
        fieldChangeHandler={this.fieldChangeHandler}
        fieldBlurHandler={this.fieldBlurHandler}
      />
    )
  }
}
