import Cookies from 'js-cookie'
import { map, omit, pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { selectOrganizationTypes } from '@/app/module/user/store/selectors'
import { selectCountries, selectDefaultCountry, selectDefaultCountryCode } from '@/app/module/utils/store/selectors'

export const selectInvitationProfileConfig = ({ utils }) =>
  omit(['organization', 'organizationType'], selectProfileConfig({ utils }))

export const selectProfileConfig = createSelector(
  selectCountries,
  selectDefaultCountry,
  selectOrganizationTypes,
  (countriesList, defaultCountry, organizationTypesList) => ({
    firstName: {
      type: 'text',
      editable: true,
      label: 'First name',
    },
    lastName: {
      type: 'text',
      editable: true,
      label: 'Last name',
    },
    country: {
      type: 'autocomplete',
      editable: true,
      restricted: true,
      label: 'Country',
      values: countriesList,
      defaultValue: defaultCountry,
    },
    organization: {
      type: 'text',
      editable: true,
      label: 'Organization name',
    },
    organizationType: {
      type: 'select',
      editable: 'true',
      values: organizationTypesList,
      label: 'Organization type',
    },
  }),
)

export const selectAccountConfig = createSelector(
  selectCountries,
  selectDefaultCountryCode,
  (countriesList, defaultCountryCode) => ({
    email: {
      type: 'text',
      editable: true,
      inputProps: {
        type: 'email',
      },
      label: 'Email',
    },
    phone: {
      type: 'phone',
      editable: true,
      defaultCountry: defaultCountryCode,
      countries: countriesList,
      label: 'Phone number',
    },
    password: {
      type: 'text',
      editable: true,
      inputProps: {
        type: 'password',
      },
      info: 'Use at least 15 characters.',
      label: 'Password',
    },
    terms: {
      type: 'checkbox',
      editable: true,
    },
  }),
)

const partSelectCallerIds = pathOr([], ['logs', 'data', 'senderIds', 'voice'])

export const selectCallerIdValues = createSelector(partSelectCallerIds, (callerIds) => [
  ...map(
    ({ phonenumber, sender, name }) => ({
      label: name ? `${name} (${sender || phonenumber})` : sender || phonenumber,
      value: sender || phonenumber,
    }),
    callerIds,
  ),
])

export const selectToken = () => Cookies.get('authToken')
