import { compose, concat, keys, reduce } from 'ramda'
import { PersonalizationType, SinglePersonalizationType } from '@/app/module/campaigns/types'

export const wrapBrackets = (text: string) => `[${text}]`

export const wrapEachBrackets = (text: string = '') => text.split('').map(wrapBrackets).join('')

export const generatePattern = (pzMap: SinglePersonalizationType[], escape = false) =>
  pzMap.map(({ label }) => `${escape ? '\\{\\{' : '{{'}${wrapEachBrackets(label)}${escape ? '\\}\\}' : '}}'}`).join('|')

export const pzListToArray = (pzList: PersonalizationType) =>
  compose(
    reduce((acc: SinglePersonalizationType[], key) => concat(acc, pzList[key]), []),
    keys,
  )(pzList)

export const addCurlyBrackets = (text: string) => `{{${text}}}`

export const removeCurlyBrackets = (text: string) => text.replace(/{{|}}/g, '')
