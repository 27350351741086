import { bindActionCreators, Dispatch } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import { createNested } from '@/app/service/util'
import { pathOr } from 'ramda'
import { selectToken } from '@/app/module/auth/store/selectors'
import { selectOrgId, selectUserId } from '@/app/module/user/store/selectors'
import { getCustomFields } from '@/app/module/custom-fields/store/actions'
import { toggleCallDialog } from '@/app/module/user/store/actions'
import { RootState } from '@/app/store'
import { getContactsItem, getContactsTableHeaders, saveContactsItem } from './store/actions'
import { selectContactsItemConfig, selectHeaders } from './store/selectors'
import ContactsItem from './component/item'
import { ContactValues } from './types'

const initialContact: ContactValues = {
  firstName: '',
  lastName: '',
  fullPhoneNumber: '',
}

const itemSelector = ({ user, contacts, customFields, utils }: RootState) => ({
  data: initialContact,
  error: {
    ...customFields.error,
    ...contacts.item.error,
  },
  searchTerm: pathOr('', ['list', 'data', 'searches', 'active'], contacts),
  loading: contacts.item.loading || customFields.loading,
  token: selectToken(),
  orgId: selectOrgId({ user }),
  userId: selectUserId({ user }),
  specs: selectContactsItemConfig({ contacts, user, customFields, utils }),
  headers: selectHeaders({ customFields, contacts }),
  fieldErrors: pathOr({}, ['item', 'error', 'fields'], contacts),
  page: pathOr(1, ['list', 'data', 'page'], contacts),
})

const itemDispatcher = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getContactsTableHeaders,
      getContactsItem,
      getCustomFields,
      toggleCallDialog,
      submit: ({ token, orgId, item }) =>
        saveContactsItem({
          token,
          orgId,
          item: createNested(item),
        }),
    },
    dispatch,
  )

export const AddContact = connect(itemSelector, itemDispatcher)(ContactsItem)
