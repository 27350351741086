import Button from '@mui/material/Button'
import { Fragment } from 'react'

export default function ContactsUploadInitActions({ submitHandler, isSubmitDisabled }) {
  return (
    <Fragment>
      <Button
        id="contact-upload-button"
        variant="contained"
        color="primary"
        disabled={isSubmitDisabled}
        onClick={() => submitHandler()}
      >
        Upload
      </Button>
    </Fragment>
  )
}
