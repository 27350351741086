/* eslint-disable max-len */
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { isEmpty, omit, pathOr } from 'ramda'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { makeStyles } from 'tss-react/mui'
import DeleteDialog from '@/app/component/atom/delete-dialog'
import StyledBadge from '@/app/component/atom/styled-badge'
import Loading from '@/app/component/guard/loading'
import ContentLayout from '@/app/component/layout/content'
import PageTitle from '@/app/component/layout/page-title'
import Campaigns from './campaigns'
import Hook from './hook'
import AddRuleDialog from './add-rule-dialog'

export const tabNames = [
  {
    label: 'Incoming SMS Rules',
    value: 'sms',
  },
  {
    label: 'Incoming WhatsApp Rules',
    value: 'whatsapp',
  },
  {
    label: 'Incoming Voice Rules',
    value: 'voice',
  },
  {
    label: 'Incoming Voice Campaign',
    value: 'campaign',
  },
]

const newHookDefault = {
  senderId: '',
  conditions: {
    equals: [],
    notEquals: [],
    unexpected: false,
    conditions: null,
  },
  actions: [],
  id: 0,
  label: '',
}

const getCallHooksCount = (callHooks) => (isEmpty(callHooks) ? 0 : 1)

function PhoneNumberRules(props) {
  const { classes } = useStyles()
  const {
    orgId,
    token,
    phoneNumberId,
    isLoading,
    hydrate,
    phoneNumber,
    personalizationList,
    history,
    tabName,
    getCampaigns,
    updatePhoneNumber,
    error,
    timezone,
    timezones,
    countriesTimezones,
    countryCode,
    getSnippets,
    snippets,
    saveSnippet,
  } = props

  const [expanded, setExpanded] = React.useState(-1) // -1 means all panels are collapsed
  const [titleEditable, setTitleEditable] = React.useState(false)
  const [newHookObject, setNewHookObject] = React.useState(newHookDefault)
  const [hooks, setHooks] = React.useState(props.hooks)
  const [hooksOrdered, setHooksOrdered] = React.useState(props.hooksOrdered)
  const [whatsappHooks, setWhatsappHooks] = React.useState(props.whatsappHooks)
  const [whatsappHooksOrdered, setWhatsappHooksOrdered] = React.useState(props.whatsappHooksOrdered)
  const [callHook, setCallHook] = React.useState(props.callHook)
  const [deleteConfirm, setDeleteConfirm] = React.useState(false)
  const [senderIds, setSenderIds] = React.useState(props.senderIds)
  const [deleteId, setDeleteId] = React.useState('')
  const [tab, setTab] = React.useState(tabName)
  const [name, setName] = React.useState(pathOr('', ['name'], phoneNumber))
  const [addRuleOpen, setAddRuleOpen] = React.useState(false)

  React.useEffect(() => {
    if (token && orgId && phoneNumberId) {
      hydrate({
        token,
        orgId,
        phoneNumberId,
      })
    }
  }, [phoneNumberId, orgId, token, hydrate])

  React.useEffect(() => {
    if (error.status === 404) {
      history.push('/phonenumbers')
    }
    // eslint-disable-next-line
  }, [error])

  React.useEffect(() => {
    setName(pathOr('', ['name'], phoneNumber))
  }, [phoneNumber])

  React.useEffect(() => {
    setHooks(props.hooks)
  }, [props.hooks])

  React.useEffect(() => {
    setWhatsappHooks(props.whatsappHooks)
  }, [props.whatsappHooks])

  React.useEffect(() => {
    setHooksOrdered(props.hooksOrdered)
    if (!expanded && props.hooksOrdered.length > 0) {
      setExpanded(props.hooksOrdered[0])
    }
    // eslint-disable-next-line
  }, [props.hooksOrdered])

  React.useEffect(() => {
    setWhatsappHooksOrdered(props.whatsappHooksOrdered)
    if (!expanded && props.whatsappHooksOrdered.length > 0) {
      setExpanded(props.whatsappHooksOrdered[0])
    }
    // eslint-disable-next-line
  }, [props.whatsappHooksOrdered])

  React.useEffect(() => {
    setCallHook(props.callHook)
  }, [props.callHook])

  React.useEffect(() => {
    setSenderIds(props.senderIds)
  }, [props.senderIds])

  React.useEffect(() => {
    setTab(props.tabName)
  }, [props.tabName])

  React.useEffect(() => {
    if (tab === 'voice') {
      if (isEmpty(props.callHook)) {
        setExpanded(newHookDefault.id)
      } else {
        setExpanded(props.callHook.id)
      }
    } else if (tab === 'sms') {
      if (props.hooksOrdered.length > 0) {
        setExpanded(props.hooksOrdered[0])
      }
    } else if (tab === 'whatsapp') {
      if (props.whatsappHooksOrdered.length > 0) {
        setExpanded(props.whatsappHooksOrdered[0])
      }
    }
    // eslint-disable-next-line
  }, [tab])

  const generateTabCount = (index) => {
    if (index === 0) {
      return hooksOrdered.length
    }
    if (index === 1) {
      return whatsappHooksOrdered.length
    }
    if (index === 2) {
      return getCallHooksCount(callHook)
    }
    if (phoneNumber.inboundCallCampId > 0) {
      return 1
    }
    return 0
  }

  const openAddRuleDialog = () => setAddRuleOpen(true)
  const closeAddRuleDialog = () => setAddRuleOpen(false)

  return (
    <DndProvider backend={HTML5Backend}>
      <DeleteDialog
        text="Are you sure you want to delete the selected rule?"
        isOpen={deleteConfirm}
        onClose={() => {
          setDeleteConfirm(false)
          setDeleteId('')
        }}
        onConfirm={() => {
          setDeleteConfirm(false)
          if (tab === 'sms') {
            props.deleteHook({
              orgId,
              token,
              itemId: deleteId,
              phoneNumberId,
            })
          } else if (tab === 'whatsapp') {
            props.deleteWhatsappHook({
              orgId,
              token,
              itemId: deleteId,
              phoneNumberId,
            })
          } else {
            props.deleteCallHook({
              orgId,
              token,
              itemId: deleteId,
              phoneNumberId,
            })
          }
        }}
      />
      <Loading isLoading={isLoading}>
        <ContentLayout>
          <PageTitle>
            {titleEditable ? (
              <TextField
                variant="standard"
                autoFocus
                id="outlined-helperText"
                margin="normal"
                className={classes.pageTitle}
                onKeyDown={async (e) => {
                  if (e.keyCode === 13) {
                    const response = await updatePhoneNumber({
                      orgId,
                      token,
                      phoneNumberId,
                      item: {
                        name,
                      },
                    })
                    if (!(response instanceof Error)) {
                      setTitleEditable(false)
                    }
                  }
                }}
                inputProps={{
                  className: classes.editablePageTitle,
                }}
                InputProps={{
                  readOnly: !titleEditable,
                  className: classes.editablePageTitle,
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={async () => {
                          const response = await updatePhoneNumber({
                            orgId,
                            token,
                            phoneNumberId,
                            item: {
                              name,
                            },
                          })
                          if (!(response instanceof Error)) {
                            setTitleEditable(false)
                          }
                        }}
                        edge="start"
                      >
                        <Icon>check</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onEnter
                onChange={(event) => {
                  setName(event.target.value)
                }}
                value={name}
              />
            ) : (
              <React.Fragment>
                Configure Rules for{' '}
                <span className="phonenumber-title">
                  "{name}" ({pathOr('', ['phoneNumber'], phoneNumber)})
                </span>
                <div
                  style={{
                    display: 'inline-block',
                    marginLeft: '25px',
                  }}
                >
                  <Button size="small" onClick={() => setTitleEditable(true)} color="primary" variant="contained">
                    <Icon fontSize="small" className={classes.editIcon}>
                      edit
                    </Icon>{' '}
                    Edit Name
                  </Button>
                </div>
              </React.Fragment>
            )}
          </PageTitle>
          <Card>
            <Toolbar style={{ backgroundColor: '#f5f5f5', minHeight: '0' }}>
              <Tabs
                value={tab}
                indicatorColor="primary"
                onChange={(e, selectedTab) => {
                  setTab(selectedTab)
                  history.push(`/phonenumbers/${phoneNumberId}/${selectedTab}`)
                }}
              >
                {tabNames.map(({ label, value }, index) => (
                  <Tab
                    key={`tab-${index}`}
                    id={`tab-${index}`}
                    value={value}
                    className={classes.tab}
                    label={
                      <StyledBadge color="primary" badgeContent={generateTabCount(index)} showZero>
                        {label}
                      </StyledBadge>
                    }
                  />
                ))}
              </Tabs>
            </Toolbar>
            {tab === 'sms' && (
              <CardContent>
                <Toolbar className={classes.toolbar}>
                  <Typography variant="h6" color="textSecondary" className={classes.title}>
                    Incoming SMS Rules
                  </Typography>
                  <div>
                    <Tooltip id="reload-hooks" title="Reload Rules">
                      <IconButton
                        onClick={() => {
                          if (token && orgId && phoneNumberId) {
                            props.hydrate({
                              token,
                              orgId,
                              phoneNumberId,
                            })
                          }
                        }}
                      >
                        <Icon>refresh</Icon>
                      </IconButton>
                    </Tooltip>
                  </div>
                </Toolbar>
                <div
                  style={{
                    margin: 'auto',
                  }}
                >
                  {!isLoading && (
                    <div className={classes.root}>
                      {hooksOrdered.length === 0 && (
                        <Card>
                          <CardContent>
                            <Typography component="div" color="textSecondary" variant="h6">
                              Add rules here to let us know what to do with incoming SMS. For example,
                              <ul
                                style={{
                                  marginTop: '5px',
                                }}
                              >
                                <li>configure keywords for your contacts to text in to opt out of your campaigns</li>
                                <li>configure a webhook to be notified about every incoming SMS</li>
                                <li>configure which SMS should automatically be forwarded to you via SMS</li>
                              </ul>
                            </Typography>
                          </CardContent>
                        </Card>
                      )}
                      {hooksOrdered.map((hookId, i) => {
                        const hook = hooks[hookId]
                        return (
                          <Hook
                            classes={classes}
                            phoneNumber={phoneNumber}
                            senderIds={senderIds}
                            key={hookId}
                            {...props}
                            hook={hook}
                            onChange={setExpanded}
                            expanded={expanded}
                            index={i}
                            personalizationList={personalizationList}
                            setDeleteId={setDeleteId}
                            setDeleteConfirm={setDeleteConfirm}
                            saveHook={(updatedHook) => {
                              props.updateHook({
                                orgId,
                                token,
                                itemId: hookId,
                                phoneNumberId,
                                item: {
                                  ...updatedHook,
                                },
                              })
                            }}
                            timezones={timezones}
                            timezone={timezone}
                            countriesTimezones={countriesTimezones}
                            countryCode={countryCode}
                            getSnippets={getSnippets}
                            snippets={snippets}
                            saveSnippet={saveSnippet}
                          />
                        )
                      })}
                    </div>
                  )}
                  <Divider
                    style={{
                      margin: '30px 0px',
                    }}
                  />
                  {addRuleOpen && (
                    <AddRuleDialog
                      classes={classes}
                      {...props}
                      hook={newHookObject}
                      handleChange={() => {}}
                      expanded={expanded}
                      index={-1}
                      personalizationList={personalizationList}
                      snippets={snippets}
                      type="sms"
                      getSnippets={getSnippets}
                      onClose={closeAddRuleDialog}
                      saveSnippet={saveSnippet}
                      saveHook={(newHook) =>
                        props
                          .addHook({
                            orgId,
                            token,
                            phoneNumberId,
                            item: {
                              ...omit(['id'], newHook),
                            },
                          })
                          .then((newItemId) => {
                            if (!(newItemId instanceof Error)) {
                              setNewHookObject(newHookDefault)
                              setExpanded(newItemId)
                              closeAddRuleDialog()
                            }
                          })
                      }
                    />
                  )}

                  <div className={classes.newMessageContainer}>
                    <Button variant="contained" color="primary" onClick={openAddRuleDialog}>
                      Add a new Rule
                    </Button>
                  </div>
                </div>
              </CardContent>
            )}
            {tab === 'whatsapp' && (
              <CardContent>
                <Toolbar className={classes.toolbar}>
                  <Typography variant="h6" color="textSecondary" className={classes.title}>
                    Incoming WhatsApp Rules
                  </Typography>
                  <div>
                    <Tooltip id="reload-hooks" title="Reload Rules">
                      <IconButton
                        onClick={() => {
                          if (token && orgId && phoneNumberId) {
                            props.hydrate({
                              token,
                              orgId,
                              phoneNumberId,
                            })
                          }
                        }}
                      >
                        <Icon>refresh</Icon>
                      </IconButton>
                    </Tooltip>
                  </div>
                </Toolbar>
                <div
                  style={{
                    margin: 'auto',
                  }}
                >
                  {!isLoading && (
                    <div className={classes.root}>
                      {whatsappHooksOrdered.length === 0 && (
                        <Card>
                          <CardContent>
                            <Typography color="textSecondary" variant="h6">
                              Add rules here to let us know what to do with incoming WhatsApp messages. For example,
                              <ul
                                style={{
                                  marginTop: '5px',
                                }}
                              >
                                <li>
                                  configure keywords for your contacts to text in WhatsApp to opt into one of your
                                  WhatsApp campaigns
                                </li>
                                <li>
                                  configure keywords for your contacts to text in WhatsApp to opt out of your campaigns
                                </li>
                                <li>configure a webhook to be notified about every incoming WhatsApp message</li>
                              </ul>
                            </Typography>
                          </CardContent>
                        </Card>
                      )}
                      {whatsappHooksOrdered.map((hookId, i) => {
                        const hook = whatsappHooks[hookId]
                        return (
                          <Hook
                            classes={classes}
                            phoneNumber={phoneNumber}
                            senderIds={senderIds}
                            key={hookId}
                            {...props}
                            hook={hook}
                            onChange={setExpanded}
                            expanded={expanded}
                            index={i}
                            type="whatsapp"
                            personalizationList={personalizationList}
                            setDeleteId={setDeleteId}
                            setDeleteConfirm={setDeleteConfirm}
                            getSnippets={getSnippets}
                            snippets={snippets}
                            saveSnippet={saveSnippet}
                            saveHook={(updatedHook) => {
                              props.updateWhatsappHook({
                                orgId,
                                token,
                                itemId: hookId,
                                phoneNumberId,
                                item: {
                                  ...updatedHook,
                                },
                              })
                            }}
                          />
                        )
                      })}
                    </div>
                  )}
                  <Divider
                    style={{
                      margin: '30px 0px',
                    }}
                  />
                  {addRuleOpen && (
                    <AddRuleDialog
                      classes={classes}
                      {...props}
                      hook={newHookObject}
                      expanded={expanded}
                      index={-1}
                      personalizationList={personalizationList}
                      snippets={snippets}
                      type="whatsapp"
                      getSnippets={getSnippets}
                      onClose={closeAddRuleDialog}
                      saveSnippet={saveSnippet}
                      saveHook={(newHook) =>
                        props
                          .addWhatsappHook({
                            orgId,
                            token,
                            phoneNumberId,
                            item: {
                              ...omit(['id'], newHook),
                            },
                          })
                          .then((newItemId) => {
                            if (!(newItemId instanceof Error)) {
                              setNewHookObject(newHookDefault)
                              setExpanded(newItemId)
                              closeAddRuleDialog(false)
                            }
                          })
                      }
                    />
                  )}

                  <div className={classes.newMessageContainer}>
                    <Button variant="contained" color="primary" onClick={openAddRuleDialog}>
                      Add a new Rule
                    </Button>
                  </div>
                </div>
              </CardContent>
            )}
            {tab === 'voice' && (
              <CardContent>
                <Toolbar className={classes.toolbar}>
                  <Typography variant="h6" color="textSecondary" className={classes.title}>
                    Incoming Voice Rules
                  </Typography>
                  <div>
                    <Tooltip id="reload-hooks" title="Reload Rules">
                      <IconButton
                        onClick={() => {
                          if (token && orgId && phoneNumberId) {
                            props.hydrate({
                              token,
                              orgId,
                              phoneNumberId,
                            })
                          }
                        }}
                      >
                        <Icon>refresh</Icon>
                      </IconButton>
                    </Tooltip>
                  </div>
                </Toolbar>
                <div
                  style={{
                    margin: 'auto',
                  }}
                >
                  {!isLoading && (
                    <div className={classes.root}>
                      <Hook
                        classes={classes}
                        phoneNumber={phoneNumber}
                        senderIds={senderIds}
                        {...props}
                        hook={!isEmpty(callHook) ? callHook : newHookDefault}
                        type="voice"
                        onChange={setExpanded}
                        expanded={expanded}
                        index={0}
                        personalizationList={personalizationList}
                        setDeleteId={setDeleteId}
                        setDeleteConfirm={setDeleteConfirm}
                        getSnippets={getSnippets}
                        snippets={snippets}
                        saveSnippet={saveSnippet}
                        saveHook={(updatedHook) => {
                          if (isEmpty(callHook)) {
                            props.addCallHook({
                              orgId,
                              token,
                              phoneNumberId,
                              item: {
                                ...omit(['id'], updatedHook),
                              },
                            })
                          } else {
                            props.updateCallHook({
                              orgId,
                              token,
                              itemId: pathOr(0, ['id'], updatedHook),
                              phoneNumberId,
                              item: {
                                ...updatedHook,
                              },
                            })
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </CardContent>
            )}
            {tab === 'campaign' && (
              <Campaigns
                campaignId={phoneNumber.inboundCallCampId}
                campaignData={phoneNumber.inboundCallCamp}
                classes={classes}
                isLoading={isLoading}
                updatePhoneNumber={updatePhoneNumber}
                orgId={orgId}
                token={token}
                phoneNumberId={phoneNumberId}
                campaignIds={props.campaignIds}
                getCampaigns={getCampaigns}
              />
            )}
          </Card>
        </ContentLayout>
      </Loading>
    </DndProvider>
  )
}

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
  },
  panel: {
    '&::before': {
      opacity: 0,
    },
    borderRadius: '2px',
  },
  heading: {
    fontSize: 15,
    flexBasis: '6%',
    color: 'rgba(0, 0, 0, 0.77)',
    flexShrink: 0,
  },
  pageTitle: {
    width: '60%',
    margin: 0,
  },
  editablePageTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1.25rem',
    fontWeight: '500',
    lineHeight: '1.6',
    padding: 0,
  },
  editIcon: {
    fontSize: '16px',
    marginRight: '5px',
  },
  secondaryHeading: {
    fontSize: 15,
    flexBasis: '90%',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  ifLabel: {
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.54)',
    paddingTop: '10px',
  },
  tagInputLabel: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.54)',
    paddingTop: '10px',
  },
  orLabel: {
    paddingTop: '4px',
  },
  toolbar: {
    justifyContent: 'space-between',
    alignItems: 'space-between',
  },
  cardSize: {
    margin: 'auto',
    left: '0',
    right: '0',
  },
  content: {
    padding: 0,
  },
  deleteButton: {
    color: '#ff5252',
  },
  title: {
    marginTop: '10px',
  },
  drawer: {
    flexShrink: 0,
  },
  addMessageDrawer: {
    width: '100%',
  },
  addNewHeader: {
    flexBasis: '100%',
    textAlign: 'center',
  },
  newMessageContainer: {
    textAlign: 'center',
    width: '100%',
  },
  selectMenuItem: {
    fontSize: '14px',
  },
  reloadButton: {
    marginTop: '15px',
    height: '40px',
  },
  tab: {
    paddingRight: '30px',
  },
}))

export default PhoneNumberRules
