import { path } from 'ramda'
import config from '@/config'
import { setPageSize } from '@/app/service/storage'
import { createAsyncActions } from '@/app/service/util'
import { processErrors } from '@/app/service/util/errorCodes'
import { SortOrder } from '@/app/types'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import {
  ACTION_CLOSE_CATI_SURVEY_FAILURE,
  ACTION_CLOSE_CATI_SURVEY_REQUEST,
  ACTION_CLOSE_CATI_SURVEY_SUCCESS,
  ACTION_GET_CATI_FAILURE,
  ACTION_GET_CATI_REQUEST,
  ACTION_GET_CATI_STEP_FAILURE,
  ACTION_GET_CATI_STEP_REQUEST,
  ACTION_GET_CATI_STEP_SUCCESS,
  ACTION_GET_CATI_SUCCESS,
  ACTION_GET_ORG_SUBS_FAILURE,
  ACTION_GET_ORG_SUBS_REQUEST,
  ACTION_GET_ORG_SUBS_SUCCESS,
  ACTION_GET_SUB_DETAILS_FAILURE,
  ACTION_GET_SUB_DETAILS_REQUEST,
  ACTION_GET_SUB_DETAILS_SUCCESS,
  ACTION_ORG_SUBS_NAVIGATE,
  ACTION_ORG_SUBS_PAGE_SIZE,
  ACTION_SET_CATI_STATUS_FAILURE,
  ACTION_SET_CATI_STATUS_REQUEST,
  ACTION_SET_CATI_STATUS_SUCCESS,
  ERROR_CLOSE_CATI_SURVEY,
  ERROR_GET_CATI,
  ERROR_GET_ORG_SUBS,
  ERROR_GET_SUB_DETAILS,
  ERROR_SET_CATI_STATUS,
} from '@/app/module/cati/definitions'

const {
  request: getCATIItemRequest,
  success: getCATIItemSuccess,
  failure: getCATIItemFailure,
} = createAsyncActions({
  request: ACTION_GET_CATI_REQUEST,
  success: ACTION_GET_CATI_SUCCESS,
  failure: ACTION_GET_CATI_FAILURE,
})

const {
  request: getCATIStepRequest,
  success: getCATIStepSuccess,
  failure: getCATIStepFailure,
} = createAsyncActions({
  request: ACTION_GET_CATI_STEP_REQUEST,
  success: ACTION_GET_CATI_STEP_SUCCESS,
  failure: ACTION_GET_CATI_STEP_FAILURE,
})

const {
  request: getOrgSubsRequest,
  success: getOrgSubsSuccess,
  failure: getOrgSubsFailure,
} = createAsyncActions({
  request: ACTION_GET_ORG_SUBS_REQUEST,
  success: ACTION_GET_ORG_SUBS_SUCCESS,
  failure: ACTION_GET_ORG_SUBS_FAILURE,
})

const {
  request: getSubDetailsRequest,
  success: getSubDetailsSuccess,
  failure: getSubDetailsFailure,
} = createAsyncActions({
  request: ACTION_GET_SUB_DETAILS_REQUEST,
  success: ACTION_GET_SUB_DETAILS_SUCCESS,
  failure: ACTION_GET_SUB_DETAILS_FAILURE,
})

const {
  request: setCATIStatusRequest,
  success: setCATIStatusSuccess,
  failure: setCATIStatusFailure,
} = createAsyncActions({
  request: ACTION_SET_CATI_STATUS_REQUEST,
  success: ACTION_SET_CATI_STATUS_SUCCESS,
  failure: ACTION_SET_CATI_STATUS_FAILURE,
})

const {
  request: closeCATISurveyRequest,
  success: closeCATISurveySuccess,
  failure: closeCATISurveyFailure,
} = createAsyncActions({
  request: ACTION_CLOSE_CATI_SURVEY_REQUEST,
  success: ACTION_CLOSE_CATI_SURVEY_SUCCESS,
  failure: ACTION_CLOSE_CATI_SURVEY_FAILURE,
})

const pageSizeDefault = path(['modules', 'cati', 'pageSize', 'default'], config) as any

export const getCATIItem =
  ({ token, orgId, itemId, runId }: { token: string; orgId: number; itemId: number; runId: number }) =>
  (dispatch: any) => {
    dispatch(getCATIItemRequest())

    return (request as any).cati
      .getItem({ token, orgId, engId: itemId, runId })
      .then((data = {}) => {
        dispatch(getCATIItemSuccess(data))
        return data
      })
      .catch((err: any) => {
        dispatch(getCATIItemFailure(err))
        const errorMessage = processErrors(err, ERROR_GET_CATI)
        dispatch(
          createNotification({
            'get-cati-campaign': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const getCATIStep =
  ({
    token,
    orgId,
    itemId,
    runId,
    stepId,
    answer,
  }: {
    token: string
    orgId: number
    itemId: number
    runId: number
    stepId: string
    answer: string
  }) =>
  (dispatch: any) => {
    dispatch(getCATIStepRequest())

    return (request as any).cati
      .step({
        token,
        orgId,
        engId: itemId,
        runId,
        item: { response: { finishStepId: stepId, ...(answer && { answer }) } },
      })
      .then((data = {}) => {
        dispatch(getCATIStepSuccess(data))
        return data
      })
      .catch((err: any) => {
        dispatch(getCATIStepFailure(err))
        const errorMessage = processErrors(err, ERROR_GET_CATI)
        dispatch(
          createNotification({
            'get-cati-step-campaign': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const getOrgSubs =
  ({
    token,
    orgId,
    query,
  }: {
    token: string
    orgId: number
    query: {
      direction?: SortOrder
      page: number
      size: number
      searchTerm: string
      template: string
      subdone: string
      skipdeletedcontacts?: string
    }
  }) =>
  (dispatch: any) => {
    dispatch(getOrgSubsRequest())

    return (request as any).organizations
      .getSubscriptions({ token, orgId, query })
      .then((data = {}) => {
        dispatch(getOrgSubsSuccess(data))
        return data
      })
      .catch((err: any) => {
        dispatch(getOrgSubsFailure(err))
        const errorMessage = processErrors(err, ERROR_GET_ORG_SUBS)
        dispatch(
          createNotification({
            'get-org-subs-campaign': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const setCATISubsPageSize =
  (size: number = pageSizeDefault) =>
  (dispatch: any) => {
    setPageSize({ namespace: 'cati', value: size })
    dispatch({
      type: ACTION_ORG_SUBS_PAGE_SIZE,
      value: size,
    })
  }

export const navigateOrgSubs = ({
  history,
  page = 1,
  searchTerm = '',
}: {
  history?: any
  page?: number
  searchTerm?: string
}) => {
  if (history) {
    let url = '/cati/contacts/'
    if (searchTerm) {
      const encodedSearchTerm = encodeURIComponent(searchTerm)
      url += `?search=${encodedSearchTerm}&page=${page}`
    } else {
      url += `?page=${page}`
    }
    history.push(url)
  }

  return {
    type: ACTION_ORG_SUBS_NAVIGATE,
    value: {
      page,
      searchTerm,
    },
  }
}

export const getSubDetails =
  ({ token, orgId, subId }: { token: string; orgId: number; subId: number }) =>
  (dispatch: any) => {
    dispatch(getSubDetailsRequest())

    return (request as any).cati
      .sub({ token, orgId, subId })
      .then((data = { sub: {} }) => {
        dispatch(getSubDetailsSuccess(data.sub))
        return data
      })
      .catch((err: any) => {
        dispatch(getSubDetailsFailure(err))
        const errorMessage = processErrors(err, ERROR_GET_SUB_DETAILS)
        dispatch(
          createNotification({
            'get-cati-sub': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const setSubStatus =
  ({ token, orgId, subId, status }: { token: string; orgId: number; subId: number; status: string }) =>
  (dispatch: any) => {
    dispatch(setCATIStatusRequest())
    return (request as any).cati
      .setStatus({ token, orgId, subId, item: { userStatus: status } })
      .then((data = { sub: {} }) => {
        dispatch(setCATIStatusSuccess(data.sub))
      })
      .catch((err: any) => {
        dispatch(setCATIStatusFailure(err))
        const errorMessage = processErrors(err, ERROR_SET_CATI_STATUS)
        dispatch(
          createNotification({
            'set-cati-status': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const closeCATISurvey =
  ({ token, orgId, itemId, runId }: { token: string; orgId: number; itemId: number; runId: number }) =>
  (dispatch: any) => {
    dispatch(closeCATISurveyRequest())

    return (request as any).cati
      .step({ token, orgId, engId: itemId, runId, item: { response: { finishSurvey: true } } })
      .then((data = {}) => {
        dispatch(closeCATISurveySuccess(data))
        return data
      })
      .catch((err: any) => {
        dispatch(closeCATISurveyFailure(err))
        const errorMessage = processErrors(err, ERROR_CLOSE_CATI_SURVEY)
        dispatch(
          createNotification({
            'close-cati-campaign': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }
