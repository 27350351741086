import { Contact } from '@/app/types/contact'
import { formatName } from '@/app/service/util/format'
import { RootState } from '@/app/store'

export const selectContactsHeaders = () => [
  {
    title: 'Name',
    fields: ['name'],
    icon: 'person_outline',
  },
  {
    title: 'Phone number',
    fields: ['fullPhoneNumber'],
  },
]

export const selectContactsTableData = (state: RootState) =>
  (state.contacts.list.data.values || []).map((contact: Contact) => ({
    ...contact,
    name: formatName(contact.firstName, contact.lastName),
  }))

export const selectManageContactsHeaders = () => [
  {
    title: 'Name',
    fields: ['name'],
    icon: 'person_outline',
  },
  {
    title: 'Phone number',
    fields: ['fullPhoneNumber'],
  },
  {
    title: 'Subscription',
    fields: ['subscription'],
  },
]
