import { path } from 'ramda'
import http from '@/config/http'
import { createAsyncActions } from '@/app/service/util'
import { getFilesItem, saveFile } from '@/app/module/files/store/actions'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import {
  ACTION_ADD_PHONENUMBER_CALLHOOK_FAILURE,
  // Call hooks
  ACTION_ADD_PHONENUMBER_CALLHOOK_REQUEST,
  ACTION_ADD_PHONENUMBER_CALLHOOK_SUCCESS,
  ACTION_ADD_PHONENUMBER_HOOK_FAILURE,
  ACTION_ADD_PHONENUMBER_HOOK_REQUEST,
  ACTION_ADD_PHONENUMBER_HOOK_SUCCESS,
  ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_FAILURE,
  ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_REQUEST,
  ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_SUCCESS,
  ACTION_CLEAR_PHONENUMBERS,
  ACTION_DELETE_PHONENUMBER_CALLHOOK_FAILURE,
  ACTION_DELETE_PHONENUMBER_CALLHOOK_REQUEST,
  ACTION_DELETE_PHONENUMBER_CALLHOOK_SUCCESS,
  ACTION_DELETE_PHONENUMBER_HOOK_FAILURE,
  ACTION_DELETE_PHONENUMBER_HOOK_REQUEST,
  ACTION_DELETE_PHONENUMBER_HOOK_SUCCESS,
  ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_FAILURE,
  ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_REQUEST,
  ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_SUCCESS,
  ACTION_FILE_RESET,
  ACTION_GET_FILE_FAILURE,
  // Files
  ACTION_GET_FILE_REQUEST,
  ACTION_GET_FILE_SUCCESS,
  ACTION_GET_PHONENUMBERS_FAILURE,
  // Phone numbers
  ACTION_GET_PHONENUMBERS_REQUEST,
  ACTION_GET_PHONENUMBERS_SUCCESS,
  ACTION_GET_PHONENUMBER_FAILURE,
  // Hooks
  ACTION_GET_PHONENUMBER_REQUEST,
  ACTION_GET_PHONENUMBER_SUCCESS,
  ACTION_POST_PHONENUMBER_FAILURE,
  ACTION_POST_PHONENUMBER_REQUEST,
  ACTION_POST_PHONENUMBER_SUCCESS,
  ACTION_SAVE_FILE_FAILURE,
  ACTION_SAVE_FILE_REQUEST,
  ACTION_SAVE_FILE_SUCCESS,
  ACTION_UPDATE_PHONENUMBER_CALLHOOK_FAILURE,
  ACTION_UPDATE_PHONENUMBER_CALLHOOK_REQUEST,
  ACTION_UPDATE_PHONENUMBER_CALLHOOK_SUCCESS,
  ACTION_UPDATE_PHONENUMBER_HOOK_FAILURE,
  ACTION_UPDATE_PHONENUMBER_HOOK_REQUEST,
  ACTION_UPDATE_PHONENUMBER_HOOK_SUCCESS,
  ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_FAILURE,
  ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_REQUEST,
  ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_SUCCESS,
  ERROR_ADD_PHONENUMBER_CALLHOOK_SERVER,
  ERROR_ADD_PHONENUMBER_HOOK_SERVER,
  ERROR_DELETE_PHONENUMBER_CALLHOOK_SERVER,
  ERROR_DELETE_PHONENUMBER_HOOK_SERVER,
  // Errors
  ERROR_GET_PHONENUMBERS_SERVER,
  ERROR_GET_PHONENUMBER_SERVER,
  ERROR_UPDATE_PHONENUMBER_CALLHOOK_SERVER,
  ERROR_UPDATE_PHONENUMBER_HOOK_SERVER,
} from '@/app/module/phonenumbers/definitions'

const {
  request: getPhoneNumbersRequest,
  success: getPhoneNumbersSuccess,
  failure: getPhoneNumbersFailure,
} = createAsyncActions({
  request: ACTION_GET_PHONENUMBERS_REQUEST,
  success: ACTION_GET_PHONENUMBERS_SUCCESS,
  failure: ACTION_GET_PHONENUMBERS_FAILURE,
})

const {
  request: getPhoneNumberRequest,
  success: getPhoneNumberSuccess,
  failure: getPhoneNumberFailure,
} = createAsyncActions({
  request: ACTION_GET_PHONENUMBER_REQUEST,
  success: ACTION_GET_PHONENUMBER_SUCCESS,
  failure: ACTION_GET_PHONENUMBER_FAILURE,
})

const {
  request: addPhoneNumberHookRequest,
  success: addPhoneNumberHookSuccess,
  failure: addPhoneNumberHookFailure,
} = createAsyncActions({
  request: ACTION_ADD_PHONENUMBER_HOOK_REQUEST,
  success: ACTION_ADD_PHONENUMBER_HOOK_SUCCESS,
  failure: ACTION_ADD_PHONENUMBER_HOOK_FAILURE,
})

const {
  request: updatePhoneNumberHookRequest,
  success: updatePhoneNumberHookSuccess,
  failure: updatePhoneNumberHookFailure,
} = createAsyncActions({
  request: ACTION_UPDATE_PHONENUMBER_HOOK_REQUEST,
  success: ACTION_UPDATE_PHONENUMBER_HOOK_SUCCESS,
  failure: ACTION_UPDATE_PHONENUMBER_HOOK_FAILURE,
})

const {
  request: deletePhoneNumberWhatsappHookRequest,
  success: deletePhoneNumberWhatsappHookSuccess,
  failure: deletePhoneNumberWhatsappHookFailure,
} = createAsyncActions({
  request: ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_REQUEST,
  success: ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_SUCCESS,
  failure: ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_FAILURE,
})

const {
  request: addPhoneNumberWhatsappHookRequest,
  success: addPhoneNumberWhatsappHookSuccess,
  failure: addPhoneNumberWhatsappHookFailure,
} = createAsyncActions({
  request: ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_REQUEST,
  success: ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_SUCCESS,
  failure: ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_FAILURE,
})

const {
  request: updatePhoneNumberWhatsappHookRequest,
  success: updatePhoneNumberWhatsappHookSuccess,
  failure: updatePhoneNumberWhatsappHookFailure,
} = createAsyncActions({
  request: ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_REQUEST,
  success: ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_SUCCESS,
  failure: ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_FAILURE,
})

const {
  request: deletePhoneNumberHookRequest,
  success: deletePhoneNumberHookSuccess,
  failure: deletePhoneNumberHookFailure,
} = createAsyncActions({
  request: ACTION_DELETE_PHONENUMBER_HOOK_REQUEST,
  success: ACTION_DELETE_PHONENUMBER_HOOK_SUCCESS,
  failure: ACTION_DELETE_PHONENUMBER_HOOK_FAILURE,
})

const {
  request: addPhoneNumberCallHookRequest,
  success: addPhoneNumberCallHookSuccess,
  failure: addPhoneNumberCallHookFailure,
} = createAsyncActions({
  request: ACTION_ADD_PHONENUMBER_CALLHOOK_REQUEST,
  success: ACTION_ADD_PHONENUMBER_CALLHOOK_SUCCESS,
  failure: ACTION_ADD_PHONENUMBER_CALLHOOK_FAILURE,
})

const {
  request: updatePhoneNumberCallHookRequest,
  success: updatePhoneNumberCallHookSuccess,
  failure: updatePhoneNumberCallHookFailure,
} = createAsyncActions({
  request: ACTION_UPDATE_PHONENUMBER_CALLHOOK_REQUEST,
  success: ACTION_UPDATE_PHONENUMBER_CALLHOOK_SUCCESS,
  failure: ACTION_UPDATE_PHONENUMBER_CALLHOOK_FAILURE,
})

const {
  request: deletePhoneNumberCallHookRequest,
  success: deletePhoneNumberCallHookSuccess,
  failure: deletePhoneNumberCallHookFailure,
} = createAsyncActions({
  request: ACTION_DELETE_PHONENUMBER_CALLHOOK_REQUEST,
  success: ACTION_DELETE_PHONENUMBER_CALLHOOK_SUCCESS,
  failure: ACTION_DELETE_PHONENUMBER_CALLHOOK_FAILURE,
})

const {
  request: postPhoneNumberRequest,
  success: postPhoneNumberSuccess,
  failure: postPhoneNumberFailure,
} = createAsyncActions({
  request: ACTION_POST_PHONENUMBER_REQUEST,
  success: ACTION_POST_PHONENUMBER_SUCCESS,
  failure: ACTION_POST_PHONENUMBER_FAILURE,
})

export { getPhoneNumbersFailure, getPhoneNumbersRequest, getPhoneNumbersSuccess }

const {
  request: getFileRequest,
  success: getFileSuccess,
  failure: getFileFailure,
} = createAsyncActions({
  request: ACTION_GET_FILE_REQUEST,
  success: ACTION_GET_FILE_SUCCESS,
  failure: ACTION_GET_FILE_FAILURE,
})

const {
  request: saveFileRequest,
  success: saveFileSuccess,
  failure: saveFileFailure,
} = createAsyncActions({
  request: ACTION_SAVE_FILE_REQUEST,
  success: ACTION_SAVE_FILE_SUCCESS,
  failure: ACTION_SAVE_FILE_FAILURE,
})

export const getPhoneNumbers =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch({ type: ACTION_CLEAR_PHONENUMBERS })
    dispatch(getPhoneNumbersRequest())

    return request.phoneNumbers
      .getList({
        token,
        orgId,
      })
      .then(({ phoneNumbers } = {}) => {
        dispatch(
          getPhoneNumbersSuccess({
            phoneNumbers,
          }),
        )
      })
      .catch((err) => {
        dispatch(
          getPhoneNumbersFailure({
            status: err.status,
            message: ERROR_GET_PHONENUMBERS_SERVER,
          }),
        )
        return err
      })
  }

export const updatePhoneNumber =
  ({ token, orgId, phoneNumberId, item }) =>
  (dispatch) => {
    dispatch(postPhoneNumberRequest())
    return request.phoneNumbers
      .post({ token, orgId, phoneNumberId, item })
      .then((response = {}) => {
        dispatch(
          createNotification({
            'update-phone-number': {
              type: response.error ? 'warning' : 'success',
              message: response.error ? 'Updating phone number was unsuccessful' : 'Phone number updated successfully.',
            },
          }),
        )
        dispatch(postPhoneNumberSuccess(item))
      })
      .catch((err) => {
        dispatch(postPhoneNumberFailure)
        dispatch(
          createNotification({
            'update-phone-number': {
              type: 'error',
              message: path(['response', 'error'], err) || 'Updating phone number was unsuccessful',
            },
          }),
        )
        return err
      })
  }

export const reloadPhoneNumbers = () => (dispatch) => dispatch({ type: ACTION_CLEAR_PHONENUMBERS })

export const getPhoneNumber =
  ({ token, orgId, phoneNumberId }) =>
  (dispatch) => {
    dispatch(getPhoneNumberRequest())

    return request.phoneNumbers
      .getItem({
        token,
        orgId,
        phoneNumberId,
      })
      .then((item = {}) => {
        dispatch(getPhoneNumberSuccess(item))
      })
      .catch((err) => {
        dispatch(
          getPhoneNumberFailure({
            status: err.status,
            message: ERROR_GET_PHONENUMBER_SERVER,
          }),
        )
        return err
      })
  }

export const addHook =
  ({ orgId, token, phoneNumberId, item }) =>
  (dispatch) => {
    dispatch(addPhoneNumberHookRequest(item))
    return request.phoneNumbers
      .addHook({ token, orgId, phoneNumberId, item })
      .then(({ id }) => {
        dispatch(
          createNotification({
            'add-hook': {
              type: 'success',
              message: 'Rule added successfully.',
            },
          }),
        )
        const newItem = {
          ...item,
          id,
        }
        dispatch(addPhoneNumberHookSuccess(newItem))
        return id
      })
      .catch((err) => {
        dispatch(
          addPhoneNumberHookFailure({
            status: err.status,
            message: ERROR_ADD_PHONENUMBER_HOOK_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'add-hook': {
              type: 'error',
              message: ERROR_ADD_PHONENUMBER_HOOK_SERVER,
            },
          }),
        )
        return err
      })
  }

export const updateHook =
  ({ orgId, token, phoneNumberId, item, itemId }) =>
  (dispatch) => {
    dispatch(updatePhoneNumberHookRequest(item))
    return request.phoneNumbers
      .updateHook({ token, orgId, phoneNumberId, item, itemId })
      .then(() => {
        dispatch(
          createNotification({
            'update-hook': {
              type: 'success',
              message: 'Rule updated successfully.',
            },
          }),
        )
        dispatch(updatePhoneNumberHookSuccess(item))
        return item
      })
      .catch((err) => {
        dispatch(
          updatePhoneNumberHookFailure({
            status: err.status,
            message: ERROR_UPDATE_PHONENUMBER_HOOK_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'update-hook': {
              type: 'error',
              message: ERROR_UPDATE_PHONENUMBER_HOOK_SERVER,
            },
          }),
        )
        return err
      })
  }

export const addWhatsappHook =
  ({ orgId, token, phoneNumberId, item }) =>
  (dispatch) => {
    dispatch(addPhoneNumberWhatsappHookRequest(item))
    return request.phoneNumbers
      .addWhatsappHook({ token, orgId, phoneNumberId, item })
      .then(({ id }) => {
        dispatch(
          createNotification({
            'add-hook': {
              type: 'success',
              message: 'Rule added successfully.',
            },
          }),
        )
        const newItem = {
          ...item,
          id,
        }
        dispatch(addPhoneNumberWhatsappHookSuccess(newItem))
        return id
      })
      .catch((err) => {
        dispatch(
          addPhoneNumberWhatsappHookFailure({
            status: err.status,
            message: ERROR_ADD_PHONENUMBER_HOOK_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'add-hook': {
              type: 'error',
              message: ERROR_ADD_PHONENUMBER_HOOK_SERVER,
            },
          }),
        )
        return err
      })
  }

export const updateWhatsappHook =
  ({ orgId, token, phoneNumberId, item, itemId }) =>
  (dispatch) => {
    dispatch(updatePhoneNumberWhatsappHookRequest(item))
    return request.phoneNumbers
      .updateWhatsappHook({ token, orgId, phoneNumberId, item, itemId })
      .then(() => {
        dispatch(
          createNotification({
            'update-hook': {
              type: 'success',
              message: 'Rule updated successfully.',
            },
          }),
        )
        dispatch(updatePhoneNumberWhatsappHookSuccess(item))
        return item
      })
      .catch((err) => {
        dispatch(
          updatePhoneNumberWhatsappHookFailure({
            status: err.status,
            message: ERROR_UPDATE_PHONENUMBER_HOOK_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'update-hook': {
              type: 'error',
              message: ERROR_UPDATE_PHONENUMBER_HOOK_SERVER,
            },
          }),
        )
        return err
      })
  }

export const deleteHook =
  ({ orgId, token, phoneNumberId, itemId }) =>
  (dispatch) => {
    dispatch(deletePhoneNumberHookRequest(itemId))
    return request.phoneNumbers
      .deleteHook({ token, orgId, phoneNumberId, itemId })
      .then(() => {
        dispatch(
          createNotification({
            'delete-hook': {
              type: 'success',
              message: 'Rule deleted successfully.',
            },
          }),
        )
        dispatch(deletePhoneNumberHookSuccess(itemId))
      })
      .catch((err) => {
        dispatch(
          deletePhoneNumberHookFailure({
            status: err.status,
            message: ERROR_DELETE_PHONENUMBER_HOOK_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'delete-hook': {
              type: 'error',
              message: ERROR_DELETE_PHONENUMBER_HOOK_SERVER,
            },
          }),
        )
        return err
      })
  }

export const deleteWhatsappHook =
  ({ orgId, token, phoneNumberId, itemId }) =>
  (dispatch) => {
    dispatch(deletePhoneNumberWhatsappHookRequest(itemId))
    return request.phoneNumbers
      .deleteWhatsappHook({ token, orgId, phoneNumberId, itemId })
      .then(() => {
        dispatch(
          createNotification({
            'delete-hook': {
              type: 'success',
              message: 'Rule deleted successfully.',
            },
          }),
        )
        dispatch(deletePhoneNumberWhatsappHookSuccess(itemId))
      })
      .catch((err) => {
        dispatch(
          deletePhoneNumberWhatsappHookFailure({
            status: err.status,
            message: ERROR_DELETE_PHONENUMBER_HOOK_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'delete-hook': {
              type: 'error',
              message: ERROR_DELETE_PHONENUMBER_HOOK_SERVER,
            },
          }),
        )
        return err
      })
  }

export const onError =
  ({ message }) =>
  (dispatch) => {
    dispatch(
      createNotification({
        'save-hooks': {
          type: 'error',
          message,
        },
      }),
    )
  }

export const addCallHook =
  ({ orgId, token, phoneNumberId, item }) =>
  (dispatch) => {
    dispatch(addPhoneNumberCallHookRequest(item))
    return request.phoneNumbers
      .addCallHook({ token, orgId, phoneNumberId, item })
      .then(({ id }) => {
        dispatch(
          createNotification({
            'add-call-hook': {
              type: 'success',
              message: 'Rule added successfully.',
            },
          }),
        )
        const newItem = {
          ...item,
          id,
        }
        dispatch(addPhoneNumberCallHookSuccess(newItem))
        return id
      })
      .catch((err) => {
        dispatch(
          addPhoneNumberCallHookFailure({
            status: err.status,
            message: ERROR_ADD_PHONENUMBER_CALLHOOK_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'add-call-hook': {
              type: 'error',
              message: ERROR_ADD_PHONENUMBER_CALLHOOK_SERVER,
            },
          }),
        )
        return err
      })
  }

export const updateCallHook =
  ({ orgId, token, phoneNumberId, item, itemId }) =>
  (dispatch) => {
    dispatch(updatePhoneNumberCallHookRequest(item))
    return request.phoneNumbers
      .updateCallHook({ token, orgId, phoneNumberId, item, itemId })
      .then(() => {
        dispatch(
          createNotification({
            'update-call-hook': {
              type: 'success',
              message: 'Rule updated successfully.',
            },
          }),
        )
        dispatch(updatePhoneNumberCallHookSuccess(item))
        return item
      })
      .catch((err) => {
        dispatch(
          updatePhoneNumberCallHookFailure({
            status: err.status,
            message: ERROR_UPDATE_PHONENUMBER_CALLHOOK_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'update-call-hook': {
              type: 'error',
              message: ERROR_UPDATE_PHONENUMBER_CALLHOOK_SERVER,
            },
          }),
        )
        return err
      })
  }

export const deleteCallHook =
  ({ orgId, token, phoneNumberId, itemId }) =>
  (dispatch) => {
    dispatch(deletePhoneNumberCallHookRequest(itemId))
    return request.phoneNumbers
      .deleteCallHook({ token, orgId, phoneNumberId, itemId })
      .then(() => {
        dispatch(
          createNotification({
            'delete-call-hook': {
              type: 'success',
              message: 'Rule deleted successfully.',
            },
          }),
        )
        dispatch(deletePhoneNumberCallHookSuccess(itemId))
      })
      .catch((err) => {
        dispatch(
          deletePhoneNumberCallHookFailure({
            status: err.status,
            message: ERROR_DELETE_PHONENUMBER_CALLHOOK_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'delete-call-hook': {
              type: 'error',
              message: ERROR_DELETE_PHONENUMBER_CALLHOOK_SERVER,
            },
          }),
        )
        return err
      })
  }

export const getPhonenumbersFile =
  ({ token, orgId, msgId, file }) =>
  (dispatch) => {
    dispatch(getFileRequest({ id: msgId, file }))

    return getFilesItem({ token, orgId, name: file })(dispatch)
      .then((res) => {
        if (res instanceof Error) {
          throw res
        }
        dispatch(
          getFileSuccess({
            id: msgId,
            filename: file,
            url: res.url,
          }),
        )
        return res
      })
      .catch((err) => {
        getFileFailure({
          id: msgId,
          status: err.status,
          message: err.message,
        })
        return err
      })
  }

export const savePhonenumbersFile =
  ({ token, orgId, msgId, item }) =>
  (dispatch) => {
    dispatch(saveFileRequest({ id: msgId }))

    return saveFile({
      token,
      orgId,
      item: {
        ...item,
      },
    })(dispatch)
      .then((res = {}) => {
        if (res instanceof Error) {
          throw res
        }
        const dlPath = http.files.get.getSignablePath({ orgId, name: res.filename })
        const data = {
          paths: [dlPath],
        }
        return Promise.all([res, dlPath, request.auth.sign({ token, item: data })])
      })
      .then(([{ filename, title }, signedPath, signedRes]) => {
        const result = {
          id: msgId,
          filename,
          title,
          url: http.files.get.getPath({
            signedPath,
            urlToken: signedRes[signedPath],
          }),
        }

        dispatch(saveFileSuccess(result))
        return result
      })
      .catch((err) => {
        dispatch(
          saveFileFailure({
            id: msgId,
            status: err.status,
            message: err.message,
          }),
        )
        return err
      })
  }

export const clearFiles = (id) => ({
  type: ACTION_FILE_RESET,
  value: { id },
})
