import React from 'react'
import Typography from '@mui/material/Typography'
import cookies from 'js-cookie'
import { Redirect, matchPath } from 'react-router-dom'
import CookieConsent from '@/app/module/dashboard/component/cookieConsent'
import { clearCookies } from '@/app/service/util/cookie'
import CardLayout from '@/app/component/layout/card'
import ErrorLayout from '@/app/component/layout/error'
import Layout from '@/app/component/layout/page'
import AccessWrapper from '@/app/component/wrapper/access'

const NOAUTH = [
  '/login',
  '/signup',
  '/reset-password',
  '/request-reset-password',
  '/terms-of-service',
  '/2fa',
  '/recovery-code',
  '/invite',
]

const CATIAUTH = ['/cati/:itemId/sub/:runId', '/cati/contacts']

const LOGIN = '/login'
const SIGNUP = '/signup'
const RESETPASSWORD = '/reset-password'
const REQUESTRESETPASSWORD = '/request-reset-password'
const VERIFYOTP = '/2fa'
const VERIFYRECOVERYCODE = '/recovery-code'
const HOME = '/'
const CATIHOME = '/cati/contacts'
const INVITATION = '/invite'
const NOORG = '/no-org'

const NOORGPATHS = [
  '/account',
  '/account/profile',
  '/account/profile/info',
  '/account/profile/preferences',
  '/account/profile/security',
  '/account/profile/api-tokens',
  INVITATION,
  NOORG,
]

const DefaultFallback = (
  <div style={{ padding: 24, textAlign: 'center' }}>
    <Typography variant="h5">
      You don't have permission to access this page. Contact your organization's manager or owner to gain access.
    </Typography>
  </div>
)

type AuthGuardProps = {
  activeOrg: any
  isAuthorized: boolean
  loading: boolean
  error?: {
    status: number
    message: string
  }
  url: string
  children: React.ReactNode
  path: string
  isCATIUser?: boolean
  fallback?: React.ReactNode
  role: string
  requiredRoles?: string[]
}

export default function AuthGuard({
  activeOrg,
  isAuthorized,
  loading,
  error,
  url,
  children,
  path,
  isCATIUser = false,
  fallback = DefaultFallback,
  role,
  requiredRoles = [],
}: AuthGuardProps) {
  if (
    path !== LOGIN &&
    !cookies.get('logout') &&
    !isAuthorized &&
    path !== VERIFYOTP &&
    path !== VERIFYRECOVERYCODE &&
    path !== INVITATION
  ) {
    cookies.set('redirectURL', url, { sameSite: 'strict', secure: true })
  } else if (path !== LOGIN && path !== VERIFYOTP && path !== VERIFYRECOVERYCODE) {
    cookies.remove('redirectURL')
  }
  cookies.remove('logout')
  if (error?.status) {
    // handle expired or invalid token login attempts
    if (error.status === 403) {
      clearCookies()
      if (url !== LOGIN) {
        return <Redirect to={LOGIN} />
      }
      return children
    }

    return (
      <Layout title="Error - engageSPARK">
        <CardLayout>
          <ErrorLayout status={error.status}>
            <Typography>{error.message}</Typography>
          </ErrorLayout>
        </CardLayout>
      </Layout>
    )
  }

  if (!loading && !isAuthorized && NOAUTH.indexOf(path) === -1) {
    return <Redirect to={LOGIN} />
  }

  const noOrgExcluded = NOORGPATHS.some((p) => matchPath(p, path))
  if (!loading && isAuthorized && !activeOrg && !noOrgExcluded) {
    return <Redirect to={NOORG} />
  }

  if (isAuthorized && activeOrg && path === NOORG) {
    return <Redirect to={HOME} />
  }

  if (
    !loading &&
    isAuthorized &&
    (path === LOGIN ||
      path === SIGNUP ||
      path === RESETPASSWORD ||
      path === REQUESTRESETPASSWORD ||
      !path ||
      path === VERIFYOTP ||
      path === VERIFYRECOVERYCODE)
  ) {
    return <Redirect to={HOME} />
  }

  if (isAuthorized && isCATIUser && CATIAUTH.indexOf(path) === -1) {
    return <Redirect to={CATIHOME} />
  }

  const hasAccess = !requiredRoles.length || requiredRoles.includes(role)

  return (
    <>
      <AccessWrapper fallback={fallback} hasAccess={hasAccess}>
        {children}
      </AccessWrapper>
      {isAuthorized && <CookieConsent />}
    </>
  )
}
