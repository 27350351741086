import Button from '@mui/material/Button'
import { compose, pathOr, prepend, reduce, remove, replace } from 'ramda'
import React, { Fragment } from 'react'
import DeleteDialog from '../../../component/atom/delete-dialog'
import Loading from '../../../component/guard/loading'
import FormLayout from '../../../component/layout/form'
import createHydrated from '../../../component/wrapper/hydratable'
import validate, { hasRequiredError } from '../../../service/validate'
import { operands } from '../definitions'

const getErrors = (value) =>
  reduce(
    (accum, conditionRow) =>
      accum ||
      reduce(
        (conditionAccum, condition) =>
          conditionAccum || !pathOr('', [0], condition) || !replace(/{|}/g, '', pathOr('', [1], condition)),
        accum,
        remove(0, 1, conditionRow),
      ),
    false,
    value,
  )

const checkValidConditions = ({ value }) => (getErrors(JSON.parse(value)) ? 'Required field' : '')

const defaultData = ['or', ['and', ['', '', '']]]

function SegmentsItem({
  loading,
  error,
  onExit,
  getSegments,
  personalizationList,
  orgId,
  token,
  submit,
  groups,
  segments,
  itemId,
  headers,
  specs,
  item: initialData = {},
  deleteSegmentsItem,
}) {
  const data = itemId
    ? {
        name: initialData.name || '',
        conditions: JSON.stringify(remove(0, 1, pathOr(defaultData, ['conditions'], initialData))),
      }
    : {
        name: '',
        conditions: JSON.stringify(remove(0, 1, defaultData)),
      }
  const [deleteConfirm, setDeleteConfirm] = React.useState(false)
  const submitHandler = ({ item }) => {
    const { conditions } = item
    submit({
      token,
      orgId,
      item: {
        id: itemId,
        ...item,
        type: 'SMART',
        conditions: prepend('or', JSON.parse(conditions)),
      },
      // eslint-disable-next-line consistent-return
    }).then((res) => {
      if (!(res instanceof Error)) {
        getSegments({
          token,
          orgId,
        })
        onExit()
      }
    })
  }

  const deleteSegment = () => {
    deleteSegmentsItem({
      token,
      orgId,
      itemId,
      // eslint-disable-next-line consistent-return
    }).then((res) => {
      if (!(res instanceof Error)) {
        onExit()
        return getSegments({
          token,
          orgId,
        })
      }
    })
  }

  return (
    <Loading isLoading={loading} error={error} testId="segment-item">
      <DeleteDialog
        text="Are you sure you want to delete the selected segment? (Contacts inside the segment will not be deleted)"
        isOpen={deleteConfirm}
        onClose={() => {
          setDeleteConfirm(false)
        }}
        onConfirm={() => {
          setDeleteConfirm(false)
          deleteSegment()
        }}
      />
      <FormLayout
        loading={loading}
        headers={headers}
        config={specs}
        values={data}
        validation={{
          conditions: (value, fields) => checkValidConditions({ value, fields }),
          name: validate(hasRequiredError),
        }}
        customProps={{
          groups,
          segments,
          personalizationList,
          operands,
          title: 'Clauses',
        }}
        onSubmit={({ item }) => submitHandler({ item })}
        Actions={({ changed, hasErrors }) => (
          <Fragment>
            <Button color="primary" variant="contained" type="submit" disabled={loading || !changed || hasErrors}>
              Save
            </Button>
          </Fragment>
        )}
      />
      {itemId && (
        <div
          style={{
            paddingLeft: '20px',
          }}
        >
          <Button
            style={{
              marginTop: '-90px',
            }}
            size="small"
            color="secondary"
            onClick={() => setDeleteConfirm(true)}
          >
            Delete Segment
          </Button>
        </div>
      )}
    </Loading>
  )
}

export default compose(createHydrated)(SegmentsItem)
