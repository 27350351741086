import Button from '@mui/material/Button'
import MuiCheckbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { isEmpty, keys, mergeDeepRight } from 'ramda'
import { Component } from 'react'
import { withStyles } from 'tss-react/mui'
import { Select } from '../../../../../../component/atom/form'
import IconText from '../../../../../../component/layout/icon-text'
import CustomFieldsItem from '../../../../../custom-fields/component/item'

const styles = () => ({
  topWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1rem 0',
  },
  mainWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  mainRight: {
    flex: '1 1 auto',
  },
  mainLeft: {
    flex: '0 0 auto',
  },
  title: {
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 8,
  },
})

const resetWhereMatch = (configs, value) => ({
  ...configs,
  fieldsToColumns: Object.keys(configs.fieldsToColumns).reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldName]: configs.fieldsToColumns[fieldName] === value ? '' : configs.fieldsToColumns[fieldName],
    }),
    {},
  ),
  customFieldsToColumns: Object.keys(configs.customFieldsToColumns).reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldName]: configs.customFieldsToColumns[fieldName] === value ? '' : configs.customFieldsToColumns[fieldName],
    }),
    {},
  ),
})

const getValue = (config, index) => {
  const matchedField = keys(config.fieldsToColumns).find((field) => config.fieldsToColumns[field] === index)

  const matchedCustomField = keys(config.customFieldsToColumns).find(
    (field) => config.customFieldsToColumns[field] === index,
  )

  return matchedField || (matchedCustomField && `customFields.${matchedCustomField}`) || ''
}

const getInfo = (config, colIndex) => {
  const sampleDisplay = config.sampleData
    .filter((samples) => samples[colIndex] !== '')
    .map((samples) => `"${samples[colIndex]}"`)

  if (!sampleDisplay.length) {
    return 'no samples available'
  }

  return `samples: ${sampleDisplay.join(', ')}`
}

const getCheckTooltipTitle = (isChecked) => (isChecked ? 'Disable column' : 'Enable column')

class ContactsUploadConfigureContent extends Component {
  state = {
    isDialogOpen: false,
  }

  closeHandler = () => {
    this.setState({
      isDialogOpen: false,
    })
  }

  render() {
    const classes = withStyles.getClasses(this.props)
    const {
      token,
      orgId,
      customFieldsLoading,
      specs,
      configs,
      statuses,
      errors,
      submit,
      onConfigChange,
      onStatusChange,
    } = this.props

    const values = Object.keys(specs)
      .filter((fieldId) => !specs[fieldId].length)
      .map((fieldId) => ({
        label: specs[fieldId].label,
        value: fieldId.toString(),
        disabled: fieldId === 'createdAt',
      }))

    return (
      <div>
        <Dialog open={this.state.isDialogOpen}>
          <DialogTitle className={classes.title}>
            <IconText>
              <Icon>add_circle</Icon>
              <Typography variant="h6">Add new fields</Typography>
            </IconText>
            <IconButton id="modal-close-btn" className={classes.closeIcon} onClick={this.closeHandler}>
              <Icon>cancel</Icon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div style={{ minWidth: '300px' }}>
              <CustomFieldsItem
                loading={customFieldsLoading}
                orgId={orgId}
                token={token}
                onExit={this.closeHandler}
                submit={submit}
              />
            </div>
          </DialogContent>
        </Dialog>

        <div className={classes.topWrap}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() =>
              this.setState({
                isDialogOpen: true,
              })
            }
          >
            Add new fields
          </Button>
        </div>
        {!isEmpty(configs) &&
          configs.titlesFromFile.map((name, colIndex) => (
            <div key={name} className={classes.mainWrap}>
              <div className={classes.mainLeft}>
                <Tooltip title={getCheckTooltipTitle(statuses[colIndex])} arrow>
                  <MuiCheckbox
                    color="primary"
                    checked={statuses[colIndex]}
                    onChange={(e) => {
                      onStatusChange({
                        errors: errors.map((err, i) => (colIndex === i ? '' : err)),
                        statuses: statuses.map((currentValue, i) => (colIndex === i ? e.target.checked : currentValue)),
                      })
                    }}
                  />
                </Tooltip>
              </div>
              <div className={classes.mainRight}>
                <Select
                  name={name}
                  label={name}
                  error={errors[colIndex]}
                  values={[{ label: 'None', value: '', disabled: true }, ...values]}
                  value={getValue(configs, colIndex.toString())}
                  editable={statuses[colIndex]}
                  info={getInfo(configs, colIndex)}
                  onChange={({ value }) => {
                    const type = value.indexOf('customFields.') === 0 ? 'customFieldsToColumns' : 'fieldsToColumns'
                    const fieldName = value.replace('customFields.', '')
                    const valueStr = colIndex.toString()

                    onConfigChange({
                      errors: errors.map((err, i) => (i === colIndex ? '' : err)),
                      configs: mergeDeepRight(resetWhereMatch(configs, valueStr), {
                        [type]: {
                          [fieldName]: valueStr,
                        },
                      }),
                    })
                  }}
                />
              </div>
            </div>
          ))}
      </div>
    )
  }
}

export default withStyles(ContactsUploadConfigureContent, styles)
