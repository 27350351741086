export default {
  item: {
    loading: false,
    error: {},
    data: undefined,
  },
  list: {
    loading: false,
    error: {},
    data: [],
  },
}
