import { Tab, TabProps, Tooltip } from '@mui/material'
import React from 'react'

type Props = TabProps & {
  tooltip?: string
}

const TooltipTab = React.forwardRef<HTMLDivElement | null, Props>(({ tooltip, ...props }, ref) => {
  return (
    <Tooltip title={tooltip}>
      <div>
        <Tab ref={ref} {...props} />
      </div>
    </Tooltip>
  )
})

export default TooltipTab
