import { ChoiceValueSingle, LocalAction, LocalAnswerCategory } from '@/app/module/campaigns/types'
import React from 'react'
import { SMS_ACTION_DEFAULT_SENDERID } from '@/app/module/campaigns/definitions'
import { ActionOption } from '@/app/module/campaigns/utils/actions/options'
import ActionsBox from '@/app/module/campaigns/component/actions/actions-box'
import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import ChoiceTagSingle from './choice-tag-single'

type Props = {
  actionOptions: ActionOption[]
  actionProps: ActionProps
  apiProps: ApiProps
  category: LocalAnswerCategory
  categoryIndex: number
  id: string
  isCloseDisabled: boolean
  snippetProps: SnippetProps

  onChange: (category: Partial<LocalAnswerCategory>, categoryIndex: number) => void
  onRemove: (categoryIndex: number) => void
}

const UseCategoriesInput: React.FC<Props> = ({
  actionOptions,
  actionProps,
  apiProps,
  category: categoryItem,
  categoryIndex: index,
  id,
  isCloseDisabled,
  snippetProps,
  onChange,
  onRemove,
}) => {
  const { category, actions } = categoryItem

  const handleActionsChange = React.useCallback(
    (actionsList: LocalAction[]) => {
      onChange({ actions: actionsList }, index)
    },
    [index, onChange],
  )
  const handleCategoryChange = React.useCallback(
    (choiceList: ChoiceValueSingle) => {
      if (typeof choiceList.reply !== 'string') {
        return // nothing to update
      }

      onChange(
        {
          category: choiceList.reply,
        },
        index,
      )
    },
    [index, onChange],
  )

  const handleRemove = React.useCallback(() => onRemove(index), [index, onRemove])
  const choiceValue = React.useMemo(() => ({ reply: category }), [category])

  return (
    <div className="categories-input" key={`text-${index}`}>
      <ActionsBox
        actionOptions={actionOptions}
        actions={actions}
        defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.smsQuestion}
        disableLabel
        dragContext={`categories-choice-${index}`}
        imageId={`${id}-${index}`}
        isCloseDisabled={isCloseDisabled}
        updateContactConfig={{
          invalid: false,
          openResponse: false,
          question: true,
        }}
        voiceId={`${id}-${index}`}
        onChange={handleActionsChange}
        onClose={handleRemove}
        {...actionProps}
        {...apiProps}
        {...snippetProps}
      >
        <ChoiceTagSingle choiceLabel="Category" value={choiceValue} onChange={handleCategoryChange} />
      </ActionsBox>
    </div>
  )
}

export default React.memo(UseCategoriesInput)
