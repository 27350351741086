import { ACTION_WEBSOCKET_NEW_MESSAGE } from '@/app/definitions'
import {
  ACTION_CLEAR_LOGS,
  ACTION_GET_CONTACTS,
  ACTION_GET_CONTACT_LOGS,
  ACTION_READ_ACTIVE_CONTACT_MESSAGE,
  CONVERSATIONS_CONTACT_PAGE_SIZE,
  CONVERSATIONS_MESSAGE_PAGE_SIZE,
} from '@/app/module/conversations/definitions'
import { Dispatch } from '@reduxjs/toolkit'

export const subscribeLogs = () => (dispatch: Dispatch) => {
  dispatch({
    type: ACTION_WEBSOCKET_NEW_MESSAGE,
    payload: {
      action: 'subscribeLogs',
    },
  })
}

export const unsubscribeLogs = () => (dispatch: Dispatch) => {
  dispatch({
    type: ACTION_WEBSOCKET_NEW_MESSAGE,
    payload: {
      action: 'unsubscribeLogs',
    },
  })
}

export const getContactInfo = (activeContactId: number) => (dispatch: Dispatch) => {
  dispatch({
    type: ACTION_WEBSOCKET_NEW_MESSAGE,
    payload: {
      action: 'getContact',
      contactId: activeContactId,
    },
  })
}

export const readAllContactMessages = (activeContactId: number) => (dispatch: Dispatch) => {
  dispatch({
    type: ACTION_READ_ACTIVE_CONTACT_MESSAGE,
    value: {
      activeContactId,
    },
  })
}

export const getContactData = (activeContactId: number, nextPage?: string) => (dispatch: Dispatch) => {
  dispatch({
    type: ACTION_GET_CONTACT_LOGS,
    value: {
      activeContactId,
    },
  })
  dispatch({
    type: ACTION_WEBSOCKET_NEW_MESSAGE,
    payload: {
      action: 'contactLogs',
      contactId: activeContactId,
      limit: CONVERSATIONS_MESSAGE_PAGE_SIZE,
      page: nextPage,
    },
  })
}

export const getContacts =
  ({ search, nextPage }: { search: string; nextPage: string }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: ACTION_GET_CONTACTS,
    })
    if (search && !nextPage) {
      dispatch({
        type: ACTION_CLEAR_LOGS,
      })
    }
    dispatch({
      type: ACTION_WEBSOCKET_NEW_MESSAGE,
      payload: {
        action: 'getLogs',
        limit: CONVERSATIONS_CONTACT_PAGE_SIZE,
        keyword: search,
        ...(nextPage && {
          page: nextPage,
        }),
      },
    })
  }
