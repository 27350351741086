import React, { PropsWithChildren } from 'react'
import Loader from '@/app/component/atom/loader'

type Props = PropsWithChildren<{
  border?: boolean
  loading?: boolean
}>

const AudioWrapper: React.FC<Props> = ({ border = true, children, loading }) => (
  <div
    style={{
      background: '#fafafa',
      borderRadius: '4px',
      boxShadow: border ? '0px 0px 0px 1px rgba(224, 224, 224, 1)' : 'none',
      height: '75px',
      position: 'relative',
      margin: '24px 0',
    }}
  >
    {children}
    {loading && <Loader />}
  </div>
)

export default AudioWrapper
