import React, { PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
  fallback?: React.ReactNode
  hasAccess: boolean
}>

const AccessWrapperFC: React.FC<Props> = ({ children, fallback, hasAccess }) => {
  if (!hasAccess) {
    return <>{fallback}</>
  }

  return <>{children}</>
}

const AccessWrapper = React.memo(AccessWrapperFC)

export default AccessWrapper
