import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import React, { ReactNode, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'

type Props = {
  autoFocus?: boolean
  endAdornment?: ReactNode
  error?: boolean
  fullWidth?: boolean
  helperText?: ReactNode
  label?: string
  startAdornment?: ReactNode
  tags: string[]
  value: string
  variant?: 'filled' | 'outlined'
  formatTag?: (tag: string) => string
  onBlur?: () => void
  onChange: (value: string) => void
  onDelete: (index: number) => void
  onFocus?: () => void
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
  onKeyPress?: React.KeyboardEventHandler<HTMLDivElement>
}

const InputTag: React.FC<Props> = ({
  endAdornment,
  error,
  startAdornment,
  tags = [],
  value,
  formatTag,
  onChange,
  onDelete,
  ...restProps
}) => {
  const { classes } = useStyles()

  const formattedTags = useMemo(() => {
    if (!formatTag) {
      return tags
    }

    return tags.map((tag) => formatTag(tag))
  }, [tags, formatTag])

  return (
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        <TextField
          variant="standard"
          data-testid="input-tag-text-field"
          className={classes.inputWrapper}
          error={error}
          multiline={true}
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
          InputProps={{
            className: classes.input,
            endAdornment,
            startAdornment,
          }}
          {...restProps}
        />
      </div>
      <div className={classes.tagsContainer} data-testid="input-tag-tags-container">
        {formattedTags.map((input, index) => (
          <Chip
            key={`${index}-${input}`}
            className={classes.tag}
            color="primary"
            variant="outlined"
            size="small"
            label={input}
            onDelete={() => onDelete(index)}
          />
        ))}
      </div>
    </div>
  )
}

const useStyles = makeStyles()({
  container: {},
  inputContainer: {
    display: 'flex',
  },
  inputWrapper: {
    height: 'auto',
    maxHeight: 'none',
    minHeight: '42px',
    whiteSpace: 'unset',
    flex: 1,
  },
  input: {
    lineHeight: '1.8em',
    paddingBottom: 5,
  },
  tagsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: '5px',
  },
  tag: {
    marginRight: '5px',
  },
})

export default InputTag
