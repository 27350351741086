import InputField from '@/app/component/atom/form/input'
import { trimContent } from '@/app/module/campaigns/component/item/steps/content/types/multimessage/content'
import { WA_LIST_DESCRIPTION_LENGTH, WA_LIST_TITLE_LENGTH } from '@/app/module/campaigns/definitions'
import { LocalWhatsAppListItem } from '@/app/module/campaigns/types'
import validate, { hasMaxError, hasRequiredError } from '@/app/service/validate'
import { ChangeEvent } from '@/app/types'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

type Props = {
  disabled?: boolean
  tagList: LocalWhatsAppListItem
  onChange: (value: Partial<LocalWhatsAppListItem>) => void
}

const validators = {
  description: validate(hasMaxError(WA_LIST_DESCRIPTION_LENGTH)),
  title: validate(hasRequiredError, hasMaxError(WA_LIST_TITLE_LENGTH)),
}

const WhatsappListChoice: React.FC<Props> = ({ disabled, tagList, onChange }) => {
  const { description, title } = tagList
  const [error, setError] = React.useState<Record<string, string>>({})
  const { classes } = useStyles()

  const handleDescriptionBlur = () => {
    const trimmed = trimContent(description)

    setError((s) => ({
      ...s,
      description: validators.description(trimmed),
    }))

    if (trimmed !== description) {
      onChange({ ...tagList, description: trimmed })
    }
  }
  const handleTitleBlur = () => {
    const trimmed = trimContent(title)

    setError((s) => ({
      ...s,
      title: validators.title(trimmed),
    }))

    if (trimmed !== title) {
      onChange({ ...tagList, title: trimmed })
    }
  }

  React.useEffect(() => {
    if (tagList.title) {
      setError((s) => ({
        ...s,
        title: '',
      }))
    }
  }, [tagList.title])

  return (
    <div className={classes.container}>
      <InputField
        data-testid={`wa-list-title-${tagList.id}`}
        editable={!disabled}
        error={error.title}
        label="Title"
        name={`title-${tagList.id}`}
        style={{
          height: 'auto',
          maxHeight: 'none',
          minHeight: '42px',
          whiteSpace: 'unset',
          marginRight: '5px',
          flex: 1,
        }}
        value={title || ''}
        variant="standard"
        onBlur={handleTitleBlur}
        onChange={({ value }: ChangeEvent) => onChange({ ...tagList, title: value })}
      />

      <InputField
        data-testid={`wa-list-description-${tagList.id}`}
        editable={!disabled}
        error={error.description}
        label="Description"
        name={`description-${tagList.id}`}
        style={{
          height: 'auto',
          maxHeight: 'none',
          minHeight: '42px',
          whiteSpace: 'unset',
          marginRight: '5px',
          flex: 1,
        }}
        value={description || ''}
        variant="standard"
        onBlur={handleDescriptionBlur}
        onChange={({ value }: ChangeEvent) => onChange({ ...tagList, description: value })}
      />
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 5,
  },
}))

export default WhatsappListChoice
