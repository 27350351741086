import { History } from 'history'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { noop } from '@/app/definitions'
import { selectToken } from '@/app/module/auth/store/selectors'
import { CampaignTemplateType } from '@/app/module/campaigns/types'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { selectUserDetails } from '@/app/module/user/store/selectors'
import { saveUser } from '@/app/module/user/store/ts/actions'
import { selectSkippingTours, selectUserInit } from '@/app/module/user/store/ts/selectors'
import { CommonUserType } from '@/app/module/user/types'
import { REPEAT_TOUR_KEY } from './definitions'
import Tour from './tour'
import { TourType } from './types'

type Props = {
  type: CampaignTemplateType
}

const TourContainer: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch()
  const history = useHistory() as History
  const initialized = useSelector(selectUserInit)
  const skippingTours = useSelector(selectSkippingTours) as Record<TourType, boolean>
  const token = useSelector(selectToken)
  const userDetails = useSelector(selectUserDetails) as CommonUserType | ''

  const params = React.useMemo(() => new URLSearchParams(history.location.search), [history.location.search])
  const isRepeat = React.useRef(params.has(REPEAT_TOUR_KEY, 'true')).current

  React.useEffect(() => {
    if (!isRepeat) {
      return
    }
    history.replace(history.location.pathname)
  }, [history, isRepeat])

  const skipTour = React.useCallback(
    (tourType: TourType) => {
      if (typeof userDetails === 'string' || !token) {
        return
      }

      saveUser({
        token,
        item: {
          ...userDetails,
          uiStore: {
            ...userDetails.uiStore,
            skippingTours: {
              ...skippingTours,
              [tourType]: true,
            },
          },
        },
      })(dispatch)
    },
    [skippingTours, token, userDetails, dispatch],
  )

  if (!initialized && !isRepeat) {
    return null // don't show tours until user is loaded for the first time
  }

  return (
    <Tour
      {...props}
      skippingTours={!isRepeat ? skippingTours : ({} as Record<TourType, boolean>)}
      onTourEnd={skipTour}
      onTourStart={noop}
    />
  )
}

export default TourContainer
