import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { compose, equals, includes, mergeDeepRight, omit, pick } from 'ramda'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox } from '../../../../component/atom/form'
import FieldDynamic from '../../../../component/layout/field/dynamic'
import Fields from '../../../../component/wrapper/fields'
import validate, {
  hasEmailError,
  hasPasswordError,
  hasPhoneError,
  hasRequiredError,
} from '../../../../service/validate'

function doesPasswordHasEmail(value, fields) {
  const { email, password } = fields

  if (!email.error && email.value && !password.error && password.value && includes(email.value, password.value)) {
    return 'Password should not contain your email address.'
  }
  return ''
}

const validation = {
  email: validate(hasRequiredError, hasEmailError),
  phone: validate(hasRequiredError, hasPhoneError),
  password: validate(hasRequiredError, hasPasswordError, doesPasswordHasEmail),
  terms: validate((value) => (value === 'true' ? '' : 'Terms of service need to be accepted in order to proceed.')),
}

const headers = [
  {
    field: 'email',
  },
  {
    field: 'phone',
  },
  {
    field: 'password',
  },
]

const getDefaultFields = () => ({
  email: '',
  phone: '',
  password: '',
  terms: 'false',
})

export default class SignupAccount extends Component {
  shouldComponentUpdate(nextProps) {
    return JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)
  }

  render() {
    const { data, email, specs, onBack, onSubmit } = this.props

    const defaultValues = getDefaultFields(specs)
    const values = compose(mergeDeepRight(defaultValues), pick(['email', 'phone', 'password', 'terms']))(data)
    if (email) {
      values.email = email
    }

    return (
      <Fields
        fields={values}
        validation={validation}
        onSubmit={({ item }) =>
          onSubmit({
            ...data,
            ...omit(['terms'], item),
          })
        }
      >
        {({ fields, changed, hasErrors, submitHandler, fieldChangeHandler, fieldBlurHandler }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              submitHandler()
            }}
            noValidate
          >
            <CardContent>
              {headers.map(({ field }) => (
                <FieldDynamic
                  key={field}
                  name={field}
                  specs={specs[field]}
                  field={fields[field]}
                  customProps={{ editable: field !== 'email' }}
                  onChange={fieldChangeHandler}
                  onBlur={fieldBlurHandler}
                />
              ))}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'inline-block' }}>
                  <Checkbox
                    name="terms"
                    {...specs.terms}
                    {...fields.terms}
                    onChange={fieldChangeHandler}
                    onBlur={fieldBlurHandler}
                  />
                </div>
                <Typography component="span" style={{ display: 'inline-block', marginBottom: '0.5rem' }}>
                  <span>I read and accept </span>
                  <Typography
                    component={Link}
                    color="primary"
                    to="/terms-of-service"
                    target="_blank"
                    style={{ display: 'inline' }}
                  >
                    terms of service
                  </Typography>
                </Typography>
              </div>
            </CardContent>
            <CardActions>
              <Button variant="outlined" onClick={onBack}>
                Back
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={(!changed && equals(values, defaultValues)) || hasErrors}
              >
                Create Account
              </Button>
            </CardActions>
          </form>
        )}
      </Fields>
    )
  }
}
