import { LocalAction, LocalActionType } from '@/app/module/campaigns/types'
import { keys } from 'ramda'

export const postActionDisableRules = {
  [LocalActionType.PauseSubscription]: [
    LocalActionType.FinishFlow,
    LocalActionType.Hangup,
    LocalActionType.Jump,
    LocalActionType.PauseSubscription,
    LocalActionType.PlayAudio,
  ],
  [LocalActionType.FinishFlow]: [
    LocalActionType.FinishFlow,
    LocalActionType.Hangup,
    LocalActionType.Jump,
    LocalActionType.PauseSubscription,
    LocalActionType.PlayAudio,
  ],
  [LocalActionType.Hangup]: [
    LocalActionType.FinishFlow,
    LocalActionType.Hangup,
    LocalActionType.Jump,
    LocalActionType.PauseSubscription,
    LocalActionType.PlayAudio,
  ],
  [LocalActionType.Replay]: [LocalActionType.Replay],
  [LocalActionType.Resend]: [LocalActionType.Resend],
  [LocalActionType.Jump]: [
    LocalActionType.FinishFlow,
    LocalActionType.Hangup,
    LocalActionType.Jump,
    LocalActionType.PauseSubscription,
  ],
}

export type ActionDisableRuleKey = keyof typeof postActionDisableRules

export type DisabledActionIndexes = Partial<Record<LocalActionType, number>>

export const getDisabledIndexesFrom = (actions: LocalAction[]): Record<string, number> => {
  const actionsLastIndexMap = actions.reduce<Record<string, number>>((acc, action, index) => {
    if (!action.type) {
      return acc
    }

    acc[action.type] = index
    return acc
  }, {})

  return keys(postActionDisableRules).reduce<DisabledActionIndexes>((acc, key) => {
    const index = actionsLastIndexMap[key]
    if (index === undefined) {
      return acc
    }
    const actionsToDisable = postActionDisableRules[key]
    return {
      ...acc,
      ...actionsToDisable.reduce<DisabledActionIndexes>((accum, action) => ({ ...accum, [action]: index }), {}),
    }
  }, {})
}
