import { Button, Card, CardContent, CardHeader, Typography, styled } from '@mui/material'
import React from 'react'
import { Radio } from '@/app/component/atom/form'
import { SelectOption } from '@/app/types'
import { OrgReportFilter, OrgReportGrouping, OrgReportQuery } from '@/app/module/reports/types'
import OrganizationReportFilterContainer from './organization-report-filter.container'

type Props = {
  isLoading: boolean
  query?: OrgReportQuery
  onSubmit: (grouping: OrgReportGrouping, filter?: OrgReportFilter) => Promise<void>
}

const groupingOptions: SelectOption<OrgReportGrouping>[] = [
  {
    label: 'Show spend per month as well as total (default)',
    value: OrgReportGrouping.Month,
  },
  {
    label: 'Show only total',
    value: OrgReportGrouping.Total,
  },
]

const OrganizationReport: React.FC<Props> = ({ isLoading, query, onSubmit }) => {
  const [grouping, setGrouping] = React.useState<OrgReportGrouping>(
    typeof query?.grouping === 'string' ? query.grouping : OrgReportGrouping.Month,
  )
  const [filter, setFilter] = React.useState<OrgReportFilter>(query?.filter || {})

  return (
    <Card>
      <CardHeader title="SMS, Voice, WhatsApp, and Airtime Spend" />
      <CardContent>
        <Container>
          <div>
            <Typography variant="subtitle1" gutterBottom={true}>
              What do you want to see in the rows?
            </Typography>
            <Radio
              name="org-report-grouping"
              value={grouping}
              values={groupingOptions}
              onChange={({ value }) => {
                setGrouping(value as OrgReportGrouping)
              }}
            />
          </div>
          <div>
            <OrganizationReportFilterContainer filter={filter} onChange={setFilter} />
          </div>
        </Container>
        <Footer>
          <Button disabled={isLoading} variant="contained" onClick={() => onSubmit(grouping, filter)}>
            Generate
          </Button>
        </Footer>
      </CardContent>
    </Card>
  )
}

const Container = styled('div')(() => ({
  minWidth: '400px',
}))

const Footer = styled('div')(() => ({
  marginTop: '1rem',
}))

export default OrganizationReport
