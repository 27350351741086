import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Campaigns from '@/app/module/campaigns'
import Notifications from '@/app/module/notifications'

function CampaignsPage({ name, routes, match, history, location }) {
  const params = new URLSearchParams(location.search)
  const aborted = params.get('aborted') || undefined
  const archived = params.get('archived') || 'false'
  const launched = params.get('launched') || undefined
  const open = params.get('open') || undefined
  const published = params.get('published') || undefined
  const templates = params.get('templates') || 'smsblast,multidrip,smssurvey,whatsappsurvey,ivr,cati,reminder'
  const search = params.get('search') || ''
  const page = Number(params.get('page')) || 1
  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout title="Campaigns - engageSPARK">
        <AuthHeader currentPage={PAGE.CAMPAIGNS.INDEX} />
        <Campaigns
          history={history}
          page={page}
          aborted={aborted}
          archived={archived}
          launched={launched}
          open={open}
          published={published}
          search={search}
          templates={templates}
        />
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}

export default CampaignsPage
