import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Toolbar from '@mui/material/Toolbar'
import { compose, equals, path, pathOr, uniq, values } from 'ramda'
import { Component } from 'react'
import StyledBadge from '@/app/component/atom/styled-badge'
import Loading from '@/app/component/guard/loading'
import createPaginatedTable from '@/app/component/wrapper/table/paginated'
import createSearchTable from '@/app/component/wrapper/table/searchable'
import createSelectTable from '@/app/component/wrapper/table/selectable'
import { CAMPAIGN_CHANGE_DELAY, TEMPLATES_TO_TYPE } from '@/app/module/campaigns/definitions'
import SubscribeTable from './table'

const TABS = ['contacts', 'groups', 'segments']

const ContactsTable = compose(createSelectTable, createSearchTable, createPaginatedTable)(SubscribeTable)

const GroupsTable = compose(createSelectTable, createSearchTable, createPaginatedTable)(SubscribeTable)

const SegmentsTable = compose(createSelectTable, createSearchTable, createPaginatedTable)(SubscribeTable)

const getSelection = ({ all, listPro, listEx }, obj = {}) => ({
  all: obj[all] || false,
  list: (obj[all] ? obj[listEx] : obj[listPro]) || [],
})

const getContactsToProps = ({ all = false, list = [] }, groupIds, segmentIds) => ({
  allContactsAreSelected: all || false,
  includedContactIds: all ? [] : list,
  excludedContactIds: all ? list : [],
  includedGroupIds: uniq([...groupIds, ...segmentIds]),
})

const getGroupsToProps = ({ all = false, list = [] }) => ({
  allGroupsAreSelected: all || false,
  includedGroupIds: all ? [] : list,
  excludedGroupIds: all ? list : [],
})

const getSegmentsToProps = ({ all = false, list = [] }) => ({
  allGroupsAreSelected: all,
  includedGroupIds: all ? [] : list,
  excludedGroupIds: all ? list : [],
})

export default class CampaignSubscriptions extends Component {
  state = {
    tab: TABS[0],
    contacts: getSelection(
      {
        all: 'allContactsAreSelected',
        listPro: 'includedContactIds',
        listEx: 'excludedContactIds',
      },
      path(['item', 'contacts'], this.props),
    ),
    groups: getSelection(
      {
        all: 'all',
        listPro: 'list',
        listEx: 'excludedGroupIds',
      },
      path(['item', 'uiStore', 'groups'], this.props),
    ),
    segments: getSelection(
      {
        all: 'all',
        listPro: 'list',
        listEx: 'excludedGroupIds',
      },
      path(['item', 'uiStore', 'segments'], this.props),
    ),
  }

  static validate({ item }) {
    return values(TEMPLATES_TO_TYPE).indexOf(item.type) > -1
  }

  componentDidUpdate(prevProps) {
    if (this.props.itemId && !equals(path(['item', 'contacts'], this.props), path(['item', 'contacts'], prevProps))) {
      this.setState({
        contacts: getSelection(
          {
            all: 'allContactsAreSelected',
            listPro: 'includedContactIds',
            listEx: 'excludedContactIds',
          },
          path(['item', 'contacts'], this.props),
        ),
      })
    }
    if (
      this.props.itemId &&
      !equals(path(['item', 'uiStore', 'groups'], this.props), path(['item', 'uiStore', 'groups'], prevProps))
    ) {
      this.setState({
        groups: getSelection(
          {
            all: 'all',
            listPro: 'list',
            listEx: 'excludedGroupIds',
          },
          path(['item', 'uiStore', 'groups'], this.props),
        ),
      })
    }
    if (
      this.props.itemId &&
      !equals(path(['item', 'uiStore', 'segments'], this.props), path(['item', 'uiStore', 'segments'], prevProps))
    ) {
      this.setState({
        segments: getSelection(
          {
            all: 'all',
            listPro: 'list',
            listEx: 'excludedGroupIds',
          },
          path(['item', 'uiStore', 'segments'], this.props),
        ),
      })
    }
  }

  componentWillUnmount() {
    const { item, onChange } = this.props

    if (this.state.timeout) {
      clearTimeout(this.state.timeout)
      onChange({
        ...item,
        contacts: getContactsToProps(
          this.state.contacts,
          pathOr([], ['groups', 'list'], this.state),
          pathOr([], ['segments', 'list'], this.state),
        ),
        uiStore: {
          ...pathOr({}, ['uiStore'], item),
          groups: getGroupsToProps(this.state.groups),
          segments: getSegmentsToProps(this.state.segments),
        },
      })
    }
  }

  changeHandler(key, selection) {
    const { item, onChange } = this.props
    if (this.state.timeout) {
      clearTimeout(this.state.timeout)
    }

    this.setState({
      [key]: selection,
      timeout: setTimeout(() => {
        onChange({
          ...item,
          contacts: getContactsToProps(
            this.state.contacts,
            pathOr([], ['groups', 'list'], this.state),
            pathOr([], ['segments', 'list'], this.state),
          ),
          uiStore: {
            ...pathOr({}, ['uiStore'], item),
            [key]: selection,
          },
        })
        this.setState((state) => ({
          timeout: clearTimeout(state.timeout),
        }))
      }, CAMPAIGN_CHANGE_DELAY),
    })
  }

  tabs = {
    contacts: {
      title: 'Contacts',
      Content: ({ token, orgId, item, contacts, getContacts, navigateContacts }) => (
        <ContactsTable
          root="contacts"
          token={token}
          orgId={orgId}
          {...contacts}
          ordered={contacts.data.map((contact) => contact.id)}
          selectionValues={getSelection(
            {
              all: 'allContactsAreSelected',
              listPro: 'includedContactIds',
              listEx: 'excludedContactIds',
            },
            path(['contacts'], item),
          )}
          getData={getContacts}
          navigate={navigateContacts}
          searchLabel="Search contacts"
          emptyMessageDefault="No contacts found."
          onSelect={({ isAll, list }) =>
            this.changeHandler('contacts', {
              all: isAll,
              list,
            })
          }
        />
      ),
    },
    groups: {
      title: 'Groups',
      Content: ({ token, orgId, item, groups, getGroups, navigateGroups }) => (
        <GroupsTable
          root="groups"
          token={token}
          orgId={orgId}
          {...groups}
          selectionValues={getSelection(
            {
              all: 'all',
              listPro: 'list',
              listEx: 'excludedGroupIds',
            },
            path(['uiStore', 'groups'], item),
          )}
          hydrateProps={['token', 'orgId']}
          hydrate={getGroups}
          searchLabel="Search groups"
          getData={getGroups}
          navigate={navigateGroups}
          emptyMessageDefault="No groups found."
          onSelect={({ isAll, list }) =>
            this.changeHandler('groups', {
              all: isAll,
              list,
            })
          }
        />
      ),
    },
    segments: {
      title: 'Segments',
      Content: ({ token, orgId, item, segments, getSegments, navigateSegments }) => (
        <SegmentsTable
          root="Segments"
          token={token}
          orgId={orgId}
          {...segments}
          selectionValues={getSelection(
            {
              all: 'all',
              listPro: 'list',
              listEx: 'excludedGroupIds',
            },
            path(['uiStore', 'segments'], item),
          )}
          hydrateProps={['token', 'orgId']}
          hydrate={getSegments}
          getData={getSegments}
          searchLabel="Search segments"
          navigate={navigateSegments}
          emptyMessageDefault="No segments found."
          onSelect={({ isAll, list }) =>
            this.changeHandler('segments', {
              all: isAll,
              list,
            })
          }
        />
      ),
    },
  }

  render() {
    const { loading } = this.props

    const { Content } = this.tabs[this.state.tab]
    return (
      <Loading isLoading={loading}>
        <Card data-testid="campaign-subscriptions" style={{ marginBottom: '2rem' }}>
          <CardContent>
            <Toolbar
              style={{
                justifyContent: 'space-between',
                margin: '-24px -24px 0',
                backgroundColor: '#f5f5f5',
              }}
            >
              <Tabs value={this.state.tab} indicatorColor="primary" onChange={(e, val) => this.setState({ tab: val })}>
                {TABS.map((tab) => (
                  <Tab
                    key={tab}
                    value={tab}
                    label={
                      <StyledBadge
                        color="primary"
                        badgeContent={pathOr(0, [tab, 'list', 'length'], this.state)}
                        showZero
                      >
                        {this.tabs[tab].title}
                      </StyledBadge>
                    }
                    style={{ zIndex: '2' }}
                  />
                ))}
              </Tabs>
            </Toolbar>
            <div style={{ margin: '-64px -24px -24px' }}>
              <Content {...this.props} />
            </div>
          </CardContent>
        </Card>
      </Loading>
    )
  }
}
