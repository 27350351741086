import { useDrag } from 'react-dnd'

export default function CampaignContentQuestionActionDrag({ index, dragContext, children }) {
  const [{ opacity }, dragRef] = useDrag({
    type: dragContext,
    item: { index },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  })

  return (
    <div
      ref={dragRef}
      style={{
        opacity,
      }}
    >
      {children}
    </div>
  )
}
