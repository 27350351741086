import {
  CampaignQuery,
  ImageProps,
  Language,
  PersonalizationType,
  SenderIdOption,
  SnippetType,
  VoiceProps,
} from '@/app/module/campaigns/types'
import { CountryTimezone, FilesType, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'

export type QuestionTextProps = {
  campaignIds: SelectOption<number>[]
  countriesTimezones: CountryTimezone[]
  countryCode: string
  defaultLanguage: string
  files: FilesType
  id: string
  index: number
  languages: Language[]
  orgId: number
  personalizationList: PersonalizationType
  senderIds: SenderIdOption[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  timezones: TimezoneWithCountryCodeType
  timezone: string
  token: string
  transcribe?: boolean
  useAi: boolean
  whatsappSenderIds: SenderIdOption[]

  getCampaigns: (payload: { orgId: number; token: string; query: CampaignQuery }) => Promise<void>
  getImageProps: (qid: string) => ImageProps
  getVoiceProps: (qid: string) => VoiceProps
  getSnippets: (payload: { orgId: number; token: string }) => Promise<void>
  saveSnippet: (payload: {
    orgId: number
    token: string
    item: Pick<SnippetType, 'category' | 'snippet'>
  }) => Promise<SnippetType>
}

export enum AnswerType {
  OpenEnded = 'openEnded',
  Text = 'text',
  WhatsAppButtons = 'whatsappButtons',
  WhatsAppList = 'whatsappList',
}
