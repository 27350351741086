import React from 'react'
import { Button, CardHeader, styled } from '@mui/material'
import { FileDownloadOutlined } from '@mui/icons-material'
import { formatExportFunction } from '@/app/module/exports/helpers'
import { SchemaExport } from '@/__generated/es-api'

type Props = {
  data: SchemaExport
  onCreateClick: () => void
  onDownloadClick: () => Promise<void>
}

const ReportInfo: React.FC<Props> = ({ data, onCreateClick, onDownloadClick }) => {
  return (
    <>
      <CardHeader title={formatExportFunction(data.function)} />
      <Row>
        <Button endIcon={<FileDownloadOutlined />} variant="text" onClick={onDownloadClick}>
          Download as Excel
        </Button>
        <Button variant="text" onClick={onCreateClick}>
          Create new report
        </Button>
      </Row>
    </>
  )
}

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 2),
}))

export default ReportInfo
