import React from 'react'
import { LocalAnswerCategory, LocalOpenEnded } from '@/app/module/campaigns/types'
import { emptyAction } from '@/app/module/campaigns/utils/actions/wrap'
import { omit } from 'ramda'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import UseCategoriesInputs from './use-categories-inputs'
import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import UseCategoriesToggle from './use-categories-toggle'

type Props = {
  actionOptions: ReturnType<typeof getActionOptionsForCampaign>
  actionProps: ActionProps
  apiProps: ApiProps
  id: string
  openEnded: LocalOpenEnded
  snippetProps: SnippetProps

  onChange: (value: { useAi?: boolean; openEnded?: LocalOpenEnded }) => void
}

const ResponseTextCategories: React.FC<Props> = ({
  actionOptions,
  actionProps,
  apiProps,
  id,
  openEnded,
  snippetProps,
  onChange,
}) => {
  const handleToggle = React.useCallback(
    (useCategories: boolean) => {
      if (useCategories) {
        onChange({
          useAi: true,
          openEnded: {
            ...openEnded,
            categories: [
              { category: 'category A - replace me', actions: [emptyAction] },
              { category: 'category B - replace me', actions: [emptyAction] },
              { category: 'Other', actions: [emptyAction] },
            ],
          },
        })
      } else {
        onChange({
          useAi: false,
          openEnded: omit(['categories'], openEnded),
        })
      }
    },
    [openEnded, onChange],
  )

  const handleCategoryChange = React.useCallback(
    (categories: LocalAnswerCategory[]) => {
      onChange({
        openEnded: {
          ...openEnded,
          categories,
        },
      })
    },
    [openEnded, onChange],
  )

  return (
    <>
      <UseCategoriesToggle useCategories={!!openEnded?.categories?.length} onChange={handleToggle} />
      {!!openEnded?.categories?.length && (
        <UseCategoriesInputs
          actionOptions={actionOptions}
          actionProps={actionProps}
          apiProps={apiProps}
          categories={openEnded.categories}
          id={id}
          snippetProps={snippetProps}
          onChange={handleCategoryChange}
        />
      )}
    </>
  )
}

export default React.memo(ResponseTextCategories)
