import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { FixMeLater } from '@/config/types'
import TablePagination from '@/app/component/atom/table-pagination'
import Loading from '@/app/component/guard/loading'
import { getMonthName } from '@/app/service/util/format'
import { reduceIndexed } from '@/app/module/campaigns/helpers'
import { LoadingError } from '@/app/types'
import InvoiceDetails from './details'

type Props = {
  loading: boolean
  invoices: InvoiceType[]
  error?: LoadingError
  token: string
  orgId: number
  downloadInvoice: FixMeLater
  role: string
}

type InvoiceType = {
  year: number
  month: number
  date: string
  pdf: string
  xlsx: string
}

const pageSizeValues = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '15',
    value: 15,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
]

const parseLines = (lines: string[]) =>
  reduceIndexed(
    (accum: any, line: any, index: number) => (line ? `${accum}&line${index + 1}=${encodeURIComponent(line)}` : accum),
    '',
    lines,
  )

function Invoices({ loading = false, invoices = [], error, token, downloadInvoice, orgId, role }: Props) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const [isInvoiceDetailsOpen, setIsInvoiceDetailsOpen] = React.useState<boolean>(false)
  const [activeInvoiceName, setActiveInvoiceName] = React.useState<string>('')

  const isAuthorized = React.useMemo(() => role === 'owner' || role === 'manager', [role])

  const handlePageChange = (newPage: number) => {
    setPage(newPage - 1)
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage)
  }

  const download = (file: string, lines: string[] = []) => {
    downloadInvoice({ orgId, token, data: {}, file }).then((data: string) => {
      const a = document.createElement('a')
      a.href = `${data}${parseLines(lines)}`
      a.download = file
      document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click()
      a.remove()
      setActiveInvoiceName('')
    })
  }

  const handlePDFDownload = (filename: string) => {
    setActiveInvoiceName(filename)
    setIsInvoiceDetailsOpen(true)
  }

  return (
    <Loading style={{ paddingTop: '5px' }} isLoading={loading} error={error}>
      <InvoiceDetails
        isOpen={isInvoiceDetailsOpen}
        onClose={() => setIsInvoiceDetailsOpen(false)}
        onConfirm={(updatedLines: string[]) => {
          setIsInvoiceDetailsOpen(false)
          download(activeInvoiceName, updatedLines)
        }}
      />
      <TableContainer component="div">
        <Table size="small" className="invoices-table">
          <TableHead>
            <TableRow>
              <TableCell>Year</TableCell>
              <TableCell align="left">Month</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: InvoiceType) => (
              <TableRow key={row.date}>
                <TableCell>{row.year}</TableCell>
                <TableCell align="left">{getMonthName[row.month - 1]}</TableCell>
                <TableCell align="right">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Tooltip
                      title={
                        isAuthorized
                          ? 'Download this Usage Statement as PDF'
                          : 'You are not authorized to download usage statements.'
                      }
                    >
                      <span>
                        <Button
                          disabled={!isAuthorized}
                          size="small"
                          onClick={() => handlePDFDownload(row.pdf)}
                          variant="contained"
                          color="primary"
                        >
                          Download PDF
                        </Button>
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={
                        isAuthorized
                          ? 'Download this Usage Statement as XSLX'
                          : 'You are not authorized to download usage statements.'
                      }
                    >
                      <span>
                        <Button
                          disabled={!isAuthorized}
                          size="small"
                          onClick={() => download(row.xlsx)}
                          variant="contained"
                          color="primary"
                        >
                          Download XLSX
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        namespace="Invoices"
        page={page + 1}
        size={rowsPerPage}
        total={invoices.length}
        pageSizeValues={pageSizeValues}
        onPageSizeChange={handleRowsPerPageChange}
        onPageChange={handlePageChange}
      />
    </Loading>
  )
}

export default Invoices
