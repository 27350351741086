import React from 'react'
import { useSelector } from 'react-redux'
import { useItemContext } from '@/app/module/campaigns/component/item-provider'
import { selectPersonalizationList } from '@/app/module/logs/store/selectors'
import { isReminderCampaign } from '@/app/module/campaigns/models/classes/reminder-campaign.class'
import {
  selectCountriesTimezones,
  selectDefaultCountryCode,
  selectDefaultTimezone,
} from '@/app/module/utils/store/selectors'
import { pathOr } from 'ramda'
import DefaultEventTime from './default-event-time'

type Props = {}

const DefaultEventTimeContainer: React.FC<Props> = () => {
  const { item, setItem } = useItemContext()
  const countriesTimezones = useSelector(selectCountriesTimezones)
  const countryCode = useSelector(selectDefaultCountryCode)
  const personalizationList = useSelector(selectPersonalizationList)
  const timezone = useSelector(selectDefaultTimezone)
  const timezones = useSelector(pathOr({}, ['utils', 'countriesTimezonesList', 'data']))

  const isReminder = isReminderCampaign(item)

  const handleChange = React.useCallback(
    (t0: string, t0timezone: string) => {
      if (!item || !setItem || !isReminder) {
        return
      }

      const newItem = item.updateDefaultArgs({
        t0,
        t0timezone,
      })

      setItem(newItem)
    },
    [isReminder, item, setItem],
  )

  if (!isReminder) {
    return null
  }

  return (
    <DefaultEventTime
      countriesTimezones={countriesTimezones}
      countryCode={countryCode}
      personalizationList={personalizationList}
      t0={item?.variables?.defaultArgs?.t0 || ''}
      t0timezone={item?.variables?.defaultArgs?.t0timezone || timezone}
      timezones={timezones}
      onChange={handleChange}
    />
  )
}

export default DefaultEventTimeContainer
