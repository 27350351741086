import { RootState } from '@/app/store'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCallerIdValues, selectToken } from '@/app/module/auth/store/selectors'
import { getSenderIdList } from '@/app/module/logs/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { useAppDispatch } from '@/app/store/redux-hooks'
import CallDialog from '@/app/component/atom/call-dialog'
import { toggleCallDialog } from '@/app/module/user/store/actions'

type Props = {}

const CallDialogContainer: React.FC<Props> = () => {
  const dispatch = useAppDispatch()

  const callDialog = useSelector((state: RootState) => state.user.callDialog)
  const callerIds = useSelector(selectCallerIdValues)
  const orgId = useSelector(selectOrgId)
  const token = useSelector(selectToken)

  const closeDialog = React.useCallback(
    () => dispatch(toggleCallDialog({ isOpen: false, contactName: '' })),
    [dispatch],
  )
  const getSenderIds = React.useCallback(() => {
    if (!orgId || !token) {
      return
    }
    dispatch(getSenderIdList({ orgId, token }))
  }, [orgId, token, dispatch])

  React.useEffect(() => {
    if (callerIds.length === 0) {
      getSenderIds()
    }
  }, [callerIds.length, getSenderIds])

  if (!callDialog?.contactId || !callDialog?.isOpen) {
    return null
  }

  return (
    <CallDialog
      callerIds={callerIds}
      contactId={callDialog.contactId}
      contactName={callDialog.contactName}
      orgId={orgId}
      token={token || ''}
      onClose={closeDialog}
    />
  )
}

export default CallDialogContainer
